import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getListClusters} from "../../../../services/api/ClustersServices"
import AsyncSelect from 'react-select/async'

class ListClusters extends Component {
    state = {
        error: "",
        selectedCluster: this.props.currentCluster,
    }

    componentDidMount(){
        this._handleChangeSelect({
            value:this.state.selectedCluster._id,
            label:this.state.selectedCluster.name,
        })
    }

    _fetchListClusters = async (term) => {
        try {
            const {data, success, message} = await getListClusters({
                limit: 100,
                name: term,
            })

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            const {clusters} = data
            const formatCluster = clusters.map(cluster => {
                return {
                    value: cluster._id,
                    label: cluster.name,
                }
            })

            this.setState({
                error: "",
            })

            return Promise.resolve(formatCluster)
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _handleChangeSelect = selectedOption => {
        this.props.onUpdateSelectedCluster(selectedOption)
    }

    _loadOption = inputValue => {
        return this._fetchListClusters(inputValue)
    }

    render() {
        const {currentCluster} = this.props

        return (
            <div className="ListClusters">
                <AsyncSelect cacheOptions defaultOptions defaultInputValue={currentCluster.name}
                             loadOptions={this._loadOption} onChange={this._handleChangeSelect}/>
            </div>
        )
    }
}

ListClusters.propTypes = {
    currentCluster: PropTypes.object.isRequired,
    onUpdateSelectedCluster: PropTypes.func.isRequired,
}

export default ListClusters
