import React, {Component} from 'react'
import InputSelectStores from './InputSelectStores'
import InputNotificationContent from './InputNotificationContent'
import InputNotificationTitle from './InputNotificationTitle'
import {pushNotifications} from '../../../../../../services/api/StoreServices'
import MessageActions from '../../../../../shared/MessageActions'
import PropTypes from 'prop-types'
import {toaster} from '../../../../../shared/PFToast'
import InputNotificationType from './InputNotificationType'
import DOMPurify from 'dompurify'

class FormContainer extends Component {
    constructor(props) {
        super(props)
        this.editorContent = React.createRef()
    }
    state = {
        loading: false,
        message: {},
        errors: [],
        formData: {
            all: true,
            notification: {
                content: '',
                title: '',
                type: 'order',
            },
            domains: '',
            preferred_language: '',
        },
        messageNull: '',
    }

    _setEditorRefs = (key, editor) => {
        this[key] = editor
    }

    _handleChangeFormData = (field, value) => {
        let {formData} = this.state
        formData = {
            ...formData,
            [field]: value,
        }
        if (field !== 'preferred_language') {
            formData.preferred_language = ''
        }
        this.setState({formData})
    }

    _handleChangeType = (field, value) => {
        const all = value === 'all'
        this.setState({
            formData: {
                ...this.state.formData,
                all: all,
            },
            [field]: value,
        })
    }

    _handleChangeNotificationData = (field, value) => {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                notification: {
                    ...this.state.formData.notification,
                    [field]: value,
                },
            },
        })
    }

    _handleSubmit = (e) => {
        e.preventDefault()
        const {formData} = this.state
        const {preferred_language} = formData
        const {title} = formData.notification
        const content = this.editorContent.getContent()
        const cleanContent = DOMPurify.sanitize(content, {ALLOWED_TAGS: ['']})
        const contentFormat = cleanContent.replace(/&nbsp;/g, ' ').trim()
        const validValue = title.trim()
        
        if (validValue === '' || !contentFormat || contentFormat === '') {
            return this.setState({
                ...this.state,
                formData: {
                    ...this.state.formData,
                    notification: {
                        ...this.state.formData.notification,
                        content: content,
                    },
                },
                messageNull: 'This field is required.',
            })
        }

        formData.notification.content = content
        if (preferred_language) {
            formData.all = false
        } else {
            formData.preferred_language = null
        }

        return this._pushNotifications(formData)
    }

    _pushNotifications = async (item) => {
        try {
            this.setState({
                loading: true,
                message: {},
            })
            const dataValid = this._validateData(item)

            const {data, success, message} = await pushNotifications(dataValid)

            if (!success) {
                return this.setState({
                    loading: false,
                    message: {
                        type: 'error',
                        content: message,
                    },
                })
            }

            const {errors, process_id, retries, delayTime, status, message: error} = data

            if (status === 'processing') {
                setTimeout(async () => {
                    await this._pushNotifications({
                        ...item,
                        retries: retries + 1,
                        process_id
                    })
                }, delayTime*1000)
            } else if (status === 'error') {
                this.setState({
                    loading: false,
                    message: {
                        type: 'error',
                        content: error,
                    },
                })
            } else {
                const domain = errors.map((error) => error.domain)

                if (errors.length === 0) {
                    this.setState(
                        {
                            loading: false,
                        },
                        () => this.props.onToggleModal()
                    )

                    toaster({message: 'Submitted successfully', type: 'success', duration: 5000})
                }

                this.setState({
                    loading: false,
                    errors: errors,
                    message: {
                        type: 'error',
                        content: domain.join(', '),
                    },
                })
            }
        } catch (e) {
            this.setState({
                loading: false,
                message: {
                    type: 'error',
                    content: e.message,
                },
            })
        }
    }

    _handleClickRetry = () => {
        const {errors} = this.state
        const errorIds = errors.map((error) => error._id)

        const sendData = {
            ...this.state.formData,
            storeIds: errorIds,
            all: false,
        }
        this._pushNotifications(sendData)
    }

    _validateData = (data) => {
        const result = data
        const {type} = Object.assign({}, this.state)

        if (type === 'special') delete result.storeIds
        if (type === 'storeIds') delete result.domains

        if (!result.notification.link) {
            delete result.notification.link
        }

        return result
    }

    render() {
        const {message, loading, errors, formData, messageNull, type} = this.state
        const {content} = formData.notification

        return (
            <form className="FormCreateNotificationContainer" onSubmit={this._handleSubmit}>
                {message.hasOwnProperty('type') && (
                    <MessageActions type={message.type} border={false} close={false} className="mb-3">
                        {message.content}
                    </MessageActions>
                )}

                <InputSelectStores
                    onChangeFormData={this._handleChangeFormData}
                    formData={formData}
                    type={type}
                    handleChangeType={this._handleChangeType}
                />
                <InputNotificationType
                    formData={formData}
                    onChangeNotificationData={this._handleChangeNotificationData}
                />
                <InputNotificationTitle
                    message={messageNull}
                    formData={formData}
                    onChangeNotificationData={this._handleChangeNotificationData}
                />
                <InputNotificationContent
                    message={messageNull}
                    content={content}
                    onChangeNotificationData={this._handleChangeNotificationData}
                    setEditorRefs={this._setEditorRefs}
                />

                {errors.length > 0 ? (
                    <div className="text-right">
                        <button className="btn btn-primary" onClick={this._handleClickRetry} type="button">
                            {loading ? 'Retrying...' : 'Retry'}
                        </button>
                    </div>
                ) : (
                    <div className="text-right">
                        <button className="btn btn-primary" type="submit">
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                )}
            </form>
        )
    }
}

FormContainer.propTypes = {
    onToggleModal: PropTypes.func.isRequired,
}

export default FormContainer

