import React, {Component} from 'react'
import PropTypes from 'prop-types'
import DnsRecordHead from "./DnsRecordHead"
import DnsRecordBody from "./DnsRecordBody"

class DnsRecordTable extends Component {
    render() {
        const {Arecord} = this.props
        const {Crecord} = this.props
        return (
            <table className="DnsRecordTable table">
                <DnsRecordHead/>
                <DnsRecordBody Crecord={Crecord} Arecord={Arecord}/>
            </table>
        )
    }
}

DnsRecordTable.propTypes = {
    Arecord: PropTypes.object.isRequired,
    Crecord: PropTypes.object.isRequired,
}

export default DnsRecordTable
