import React from 'react'
import PropTypes from 'prop-types'

function SettingSectionDetails(props) {
    const {title, description} = props

    return (
        <div className="SectionDetails">
            <p className="font-bold">{title}</p>
            <small className="text-muted">{description}</small>
        </div>
    )
}

SettingSectionDetails.defaultProps = {
    description: '',
}

SettingSectionDetails.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
}

export default SettingSectionDetails
