import createAPIServices from './createApiServices'
import queryString from 'query-string'

const baseUrl =
    process.env.NODE_ENV === 'production' ? '/api/cluster' : 'https://admin-staging.merchize.com/api/cluster'
const api = createAPIServices({baseUrl})

export const getListClusters = (data) =>
    api.makeAuthRequest({
        url: `/clusters/search`,
        method: 'POST',
        data,
    })

export const createNewCluster = (data) =>
    api.makeAuthRequest({
        url: `/clusters`,
        method: 'POST',
        data,
    })

export const updateCluster = (data) =>
    api.makeAuthRequest({
        url: `/clusters/update`,
        method: 'POST',
        data,
    })

export const getClusterDetail = (clusterId) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}`,
        method: 'GET',
    })
}

export const getGroupClusterDetail = (groupId) => {
    return api.makeAuthRequest({
        url: `/group/${groupId}`,
        method: 'GET',
    })
}

export const getClusterSettings = (clusterId) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/settings`,
        method: 'GET',
    })
}

export const deleteClusterSetting = (clusterId, key) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/settings`,
        method: 'DELETE',
        data: {key},
    })
}

export const updateClusterSetting = (clusterId, key, value) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/settings`,
        method: 'POST',
        data: {
            key,
            value,
        },
    })
}

export const getDnsRecord = (clusterId) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/get-dr`,
        method: 'GET',
    })
}

export const createDnsRecord = (clusterId) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/create-dr`,
        method: 'POST',
    })
}

export const updateDnsRecord = (clusterId) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/update-dr`,
        method: 'POST',
    })
}

export const getClusterEnvs = (clusterId, key, value) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/envs?${key}=${value}`,
        method: 'GET',
    })
}

export const updateClusterEnv = (clusterId, data) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/envs`,
        method: 'POST',
        data,
    })
}

export const deleteClusterEnv = (clusterId, key) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/envs`,
        method: 'DELETE',
        data: {
            key,
        },
    })
}

export const getTotalActiveStoresByCluster = (clusterId) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/total-active-stores`,
        method: 'GET',
    })
}

export const getListGroups = (clusterId) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/groups`,
        method: 'GET',
    })
}

export const createNewGroup = ({clusterId, clusterType}) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/new-group`,
        method: 'POST',
        data: {
            type: clusterType,
        },
    })
}

export const getAllGroups = (payload) => {
    return api.makeAuthRequest({
        url: `/groups?${queryString.stringify(payload)}`,
        method: 'GET',
    })
}

export const getListGroupConfigs = (clusterId, groupId) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/groups/${groupId}/eks-config`,
        method: 'GET',
    })
}

export const updateListGroupConfigs = (clusterId, groupId, data) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/groups/${groupId}/eks-config`,
        method: 'POST',
        data,
    })
}

export const getListPipelineGroup = (group, params) => {
    return api.makeAuthRequest({
        url: `/group/${group}/jobs`,
        method: 'GET',
        params,
    })
}

export const applyGroupConfigs = (clusterId, groupId, data) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/groups/${groupId}/apply-eks-config`,
        method: 'POST',
        data,
    })
}

export const bulkUnLinkStoreGroup = (groupId, payload) => {
    return api.makeAuthRequest({
        url: `/group/${groupId}/unlink-stores`,
        method: 'PATCH',
        data: payload,
    })
}

export const inActiveGroup = (groupId, payload) => {
    return api.makeAuthRequest({
        url: `/group/${groupId}/inactive`,
        method: 'PATCH',
        data: payload,
    })
}

export const suggestConfigGroup = (clusterId, data) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/envs/get-by-key`,
        method: 'POST',
        data,
    })
}

export const deleteGroupConfig = (clusterId, groupId, data) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/groups/${groupId}/eks-config`,
        method: 'DELETE',
        data,
    })
}

export const searchItemGroupConfigs = (clusterId, groupId, data) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/groups/${groupId}/eks-config/search`,
        method: 'POST',
        data,
    })
}

export const syncGroupConfig = (clusterId, groupId) => {
    return api.makeAuthRequest({
        url: `/clusters/${clusterId}/groups/${groupId}/config-map/sync`,
        method: 'POST',
    })
}
