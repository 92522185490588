/**
 * TODO: finish this:
 * this only have Read and Update, implement Delete
 * update pagination options and disableRows
 */

import React, {useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'
import {CustomInput} from 'reactstrap'
import './style.scss'

PFTableV3.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    totalItems: PropTypes.number,
    loading: PropTypes.bool,
    hasCheckbox: PropTypes.bool,
    allowSelectAll: PropTypes.object,
    disableRows: PropTypes.array,
    paginationOpts: PropTypes.object, // TODO:
}

function PFTableV3({
    columns,
    data,
    loading = false,
    hasCheckbox = true,
    allowSelectAll = true,
    onSubmit = () => {},
    disableRows = [],
    totalItems,
    paginationOpts = {
        page: 1,
        totalPage: 1,
    },
}) {
    const [allSelected, setAllSelected] = useState(false)
    const [rowSelected, setRowSelected] = useState({})

    useEffect(() => {
        onSubmit(rowSelected)
    }, [rowSelected, onSubmit])

    useEffect(() => {
        const prevData = data
        return () => {
            const currentData = data
            if (
                prevData.length !== currentData.length ||
                !prevData.every((item, index) => item._id === currentData[index]._id)
            ) {
                _clearSelected(data)
            }
        }
    }, [data])

    const _selectRow = (e) => {
        const {value: rowId, checked} = e.target

        if (allowSelectAll) {
            setRowSelected((prevState) => {
                return {
                    ...prevState,
                    [rowId]: checked,
                }
            })
            if (numberItemsSelected === data.length - 1) {
                setAllSelected(!allSelected)
            }
        } else {
            setRowSelected({[rowId]: checked})
        }
    }

    const _selectAll = () => {
        setAllSelected(!allSelected)
        setRowSelected({...data.reduce((acc, item) => ({...acc, [item._id]: !allSelected}), {})})
    }

    const _clearSelected = (data) => {
        setAllSelected(false)
        setRowSelected({
            ...data.reduce((acc, item) => {
                return {...acc, [item._id]: false}
            }, {}),
        })
    }

    const tableHeader = () => {
        return (
            <>
                {hasCheckbox && (
                    <th className="SelectorHead">
                        <CustomInput
                            id="allStoreSelector"
                            type="checkbox"
                            checked={allSelected}
                            onChange={_selectAll}
                            disabled={!allowSelectAll}
                        />
                    </th>
                )}

                {columns.map((col) => (
                    <th className={col.key === 'personalize' ? 'text-center' : ''} key={col.key}>
                        {col.title}
                    </th>
                ))}
            </>
        )
    }

    const tableBody = () => {
        return (
            <>
                {loading ? (
                    [1, 2, 3].map((row, index) => (
                        <tr key={`placeholderRow_${index}`}>
                            {hasCheckbox && (
                                <td>
                                    <div className="PlaceholderCell" />
                                </td>
                            )}
                            {columns.map((col, index) => (
                                <td key={`placeholderCell_${index}`}>
                                    <div className="PlaceholderCell" />
                                </td>
                            ))}
                        </tr>
                    ))
                ) : data.length < 1 ? (
                    <tr>
                        <td colSpan={99}>
                            <i>No results</i>
                        </td>
                    </tr>
                ) : (
                    data.map((item, rowIndex) => {
                        const disabled = disableRows.find((id) => String(id) === String(item._id))
                        return (
                            <tr key={item._id || rowIndex}>
                                {hasCheckbox && (
                                    <td>
                                        <CustomInput
                                            id={`selector_${item._id}`}
                                            type="checkbox"
                                            value={item._id}
                                            checked={rowSelected[item._id]}
                                            onChange={_selectRow}
                                            disabled={!!disabled}
                                        />
                                    </td>
                                )}

                                {columns.map((col) => {
                                    const rawContent = item[col.dataIndex]

                                    return (
                                        <td className={col.className} key={col.key}>
                                            {String(rawContent)}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })
                )}
            </>
        )
    }

    const numberItemsSelected = useMemo(() => Object.values(rowSelected).filter(Boolean).length, [rowSelected])
    return (
        <div className="PFTableV3">
            <table className="table">
                <thead className="thead-light">
                    <tr>{tableHeader()}</tr>
                </thead>
                <tbody>{tableBody()}</tbody>
            </table>
        </div>
    )
}

export default PFTableV3
