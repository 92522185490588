import React, {Component} from 'react'
import PropTypes from 'prop-types'
import EnsureLoggedIn from '../../shared/EnsureLoggedIn'
import WithStoreSettings from '../../shared/WithStoreSettings'
import Dashboard from './Dashboard'
import {matchPath} from 'react-router-dom'
import {Redirect} from 'react-router'
import {getOnlyRoles} from '../../../services/AuthServices'

class DashboardContainer extends Component {

    forAccountantOnly = () => {
        return matchPath(this.props.location.pathname, {
                path: '/a/accounting-report/:id',
                exact: true,
                strict: false
            }) || matchPath(this.props.location.pathname, {
                path: '/a/payout-approval/:payout_id',
                exact: true,
                strict: false
            }) ||
            this.props.location.pathname === '/a/payout-approval'
    }

    forOperatorOnly = () => {
        return matchPath(this.props.location.pathname, {
            path: '/a/shipment-report'
        })
    }

    render() {
        const isUrlForAccountant = this.forAccountantOnly()
        const isUrlForOperator = this.forOperatorOnly()
        const role = getOnlyRoles()

        if (role === 'accountant' && !isUrlForAccountant) {
            return <Redirect to="/a/accounting-report/all-store-report"/>
        }

        if (role === 'operator' && !isUrlForOperator) {
            return <Redirect to="/a/shipment-report"/>
        }

        return (
            <EnsureLoggedIn>
                <Dashboard {...this.props} basePath="/a"/>
            </EnsureLoggedIn>
        )
    }
}

DashboardContainer.propTypes = {
    store: PropTypes.object.isRequired
}

export default function (props) {
    return (
        <WithStoreSettings>
            {store => (
                <DashboardContainer {...props} store={store}/>
            )}
        </WithStoreSettings>
    )
}
