import React from 'react'
import PropTypes from 'prop-types'

const VariantTable = ({renderRow, renderHeader, headerConfigs, filter}) => {
    return (
        <div className="BaseCostTable table-responsive">
            <table className="table">
                <thead className={'thead-light'}>
                    <tr>{renderHeader(headerConfigs)}</tr>
                </thead>
                <tbody>
                    {filter && <tr className="ProductVariantsRow">{filter}</tr>}
                    {renderRow}
                </tbody>
            </table>
        </div>
    )
}

export default VariantTable

VariantTable.propTypes = {
    renderHeader: PropTypes.node.isRequired,
    renderRow: PropTypes.node.isRequired,
    headerConfigs: PropTypes.array.isRequired,
    filter: PropTypes.node,
}
