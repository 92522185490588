import React from 'react'
import DesignTable from './DesignTable'

const DesignContainer = (props) => {
    return (
        <div className="DesignContainer mt-3">
            <DesignTable {...props} />
        </div>
    )
}

export default DesignContainer

