import React, {Component} from 'react'
import PropTypes from 'prop-types'
import VariantTable from './variant-edit/VariantTable'
import Pagination from '../../shared/Pagination'
import BulkVariantActions from './bulk-variant-actions/BulkVariantActions'
import VariantLimitSelector from './variant-edit/VariantLimitSelector'

class VariantEdit extends Component {
    state = {
        bulkEdit: {
            active: false,
            selected: [],
            value: {},
            backupVariant: [],
        },
    }

    componentDidMount() {
        this._backupVariants()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.isUpdated && prevProps.isUpdated) this._cancelBulkPrice()

        const {variants: oldVariants} = prevProps.product
        const {variants} = this.props.product

        if ((!oldVariants && variants) || (variants && oldVariants.length !== variants.length)) this._backupVariants()
    }

    _backupVariants = () => {
        const {variants} = this.props.product
        if (!variants) return
        const {bulkEdit} = this.state

        this.setState({
            bulkEdit: {
                ...bulkEdit,
                backupVariant: [...variants],
            },
        })
    }

    _changeVariants = (variants) => {
        this.props.onChange('variants', variants)
    }

    _switchPage = (page) => () => {
        this.props.switchPage(page)
    }

    _openBulkEditVariant = (key) => {
        const {product} = this.props
        if (!product || !product.variants) return

        this.setState(({bulkEdit}) => ({bulkEdit: {...bulkEdit, active: key}}))
    }

    _onChangeBulkEditValue = (additionalValue) => {
        const {bulkEdit} = this.state
        const {value} = bulkEdit
        this.setState({
            bulkEdit: {
                ...bulkEdit,
                value: {...value, ...additionalValue},
            },
        }, () => this._updateBulkVariants())
    }

    _onCancelBulkVariant = () => {
        const {bulkEdit} = this.state
        this.setState({
            bulkEdit: {
                ...bulkEdit,
                active: false,
                selected: [],
                value: '',
            },
        }, () => {
            this._recoverVariants()
        })
    }

    _recoverVariants = () => {
        const {bulkEdit} = this.state
        const {backupVariant} = bulkEdit
        this.props.onChange('variants', backupVariant)
    }

    _updateBulkVariants = () => {
        const {variants} = this.props.product
        const {bulkEdit} = this.state
        const {backupVariant, value, active} = bulkEdit

        if (!active) return

        const updatedVariants = variants.map((variant) => {
            const {_id: variantId} = variant
            if (bulkEdit.selected.includes((variantId))) return {
                ...variant,
                ...value,
            }

            return backupVariant.find(item => item._id === variant._id)
        })

        this.props.onChange('variants', updatedVariants)
    }

    _cancelBulkPrice = () => {
        const {bulkEdit} = this.state
        this.setState({
            bulkEdit: {
                ...bulkEdit,
                active: false,
                selected: [],
                value: '',
            },
        })
    }

    _toggleSelectBulk = (variant, isSelect) => {
        const {bulkEdit} = this.state
        const selected = (variant === 'ALL') ? this._mapAllVariants(isSelect) : this._toggleVariant({variant, isSelect})

        this.setState({bulkEdit: {...bulkEdit, selected}}, () =>
            this._updateBulkVariants(),
        )
    }

    _toggleVariant = ({variant, isSelect}) => {
        const {selected} = this.state.bulkEdit

        if (!isSelect) return selected.filter((item) => item !== variant)
        return [...selected, variant]
    }

    _mapAllVariants = (isSelect) => {
        if (!isSelect) return []
        return this.props.product.variants.map((item) => item._id)
    }

    _onSubmitBulkVariant = async () => {
        const {backupVariant} = this.state.bulkEdit
        const result = await this.props.submitVariants()

        if (!result) this.props.onChange('variants', backupVariant)
        this._backupVariants()
        this.setState({
            bulkEdit: {
                active: false,
                selected: [],
                value: '',
                backupVariant: [],
            },
        })

        return result
    }

    render() {
        const {product, variantPerPage, onGetVariants, onChangeVariantsLimit} = this.props
        const {bulkEdit} = this.state
        const pageAmount = Math.ceil(product.variantEntity / variantPerPage)
        return (
            <div className='VariantEdit VariantEditProductApproval'>
                <div className='SectionInner'>
                    <div className='d-flex justify-content-between'>
                        <h3 className='Title fs-20'>Variants</h3>
                        <BulkVariantActions openBulkEditVariant={this._openBulkEditVariant}
                                            bulkEditActive={bulkEdit.active}
                                            onChangeBulkEditValue={this._onChangeBulkEditValue}
                                            onSubmit={this._onSubmitBulkVariant}
                                            onCancel={this._onCancelBulkVariant}
                        />
                    </div>

                    <VariantTable
                        {...this.props}
                        bulkEdit={bulkEdit.active}
                        onCheckBulkEdit={this._toggleSelectBulk}
                        variants={product.variants}
                        selectedVariant={bulkEdit.selected}
                        toggleBulkPrice={this._openBulkEditVariant}
                        onChange={this._changeVariants}
                        attributes={product.attributes || []}
                        onGetVariants={onGetVariants}
                        variantPage={product.variantPage}
                        onCancelBulkUpdate={this._cancelBulkPrice}
                    />

                    <div className='border-top d-flex pt-3 justify-content-end align-items-center'>
                        <Pagination
                            amount={pageAmount || 0}
                            current={product.variantPage || 0}
                            onSwitchPage={this._switchPage}
                        />

                        <VariantLimitSelector onChangeVariantsLimit={onChangeVariantsLimit}/>
                    </div>
                </div>
            </div>
        )
    }
}

VariantEdit.propTypes = {
    product: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    switchPage: PropTypes.func.isRequired,
    variantPerPage: PropTypes.number.isRequired,
    submitVariants: PropTypes.func.isRequired,
    isUpdated: PropTypes.bool.isRequired,
    onSubmitVariantsFilter: PropTypes.func.isRequired,
    onChangeVariantsLimit: PropTypes.func.isRequired,
}

export default VariantEdit
