import React from 'react'
import RelatedProductTable from './RelatedProductTable'

const RelatedProductContainer = (props) => {

    return (
        <div className="RelatedProductContainer">
            <div className="d-flex justify-content-end my-3">
                <div className="d-flex align-items-center">
                    <span>
                        Currency: <b>USD</b>
                    </span>
                </div>
            </div>
            <RelatedProductTable {...props} />
        </div>
    )
}

export default RelatedProductContainer

