import React from 'react'
import RelatedProductTableRow from './RelatedProductTableRow'
import {randomID} from '../../../../../helpers/common/randomID'

const RelatedProductTable = (props) => {
    const {variants, attributes} = props

    if (!variants.length) return null

    return (
        <div className="RelatedProductTable table-responsive">
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        {!!attributes.length &&
                            attributes.map((attribute) => {
                                const _id = randomID()
                                return (
                                    <th key={_id} className="text-capitalize">
                                        {attribute.name}
                                    </th>
                                )
                            })}
                        <th className="table-col-10">SKU</th>
                        <th className="table-col-40">Related Product</th>
                    </tr>
                </thead>
                <RelatedProductTableRow variants={variants} {...props} />
            </table>
        </div>
    )
}

export default RelatedProductTable

