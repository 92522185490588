import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import CountryItem from './CountryItem'
import className from 'classnames'

class GroupCountries extends Component {
    constructor(props) {
        super(props)

        const {groupCountries, selectedCountries} = this.props

        if (!Array.isArray(selectedCountries)) {
            this.state = {
                groupSelected: ''
            }

            return
        }

        const {group: currentGroup, items} = groupCountries

        const selectedByGroup = selectedCountries.filter(country => country.group === currentGroup)
        const totalSelected = selectedByGroup.length

        const totalNotAvailable = items.filter(item => !item.added && !item.available).length

        const totalCountries = items.length

        if (totalNotAvailable === totalCountries) {
            this.state = {
                groupSelected: 'disabled'
            }

            return
        }

        let groupSelected = ''

        if (totalCountries === totalSelected + totalNotAvailable) {
            groupSelected = true
        }

        this.state = {
            groupSelected: groupSelected
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.restSelected !== this.props.restSelected && this.props.restSelected) {

            this.setState({
                groupSelected: false
            })
        }
    }

    _handleChangeInput = e => {
        const {checked} = e.target

        if (checked) {
            this._selectAllCountries()
            this.props.onUncheckInputRest()
        } else {
            this._deselectAllCountries()
        }
    }

    _handleUncheckGroupCountries = () => {
        this.setState({
            groupSelected: false
        })
    }

    _selectAllCountries = () => {
        const {groupCountries, onUpdateTempSelectedCountries} = this.props
        const {items} = groupCountries

        for (let i = 0; i < items.length; i++) {
            const {country, available, added} = items[i]

            if (available || added) {
                onUpdateTempSelectedCountries(country, 'add')
            }
        }

        this.setState({
            groupSelected: true
        })
    }

    _deselectAllCountries = () => {
        const {groupCountries, onUpdateTempSelectedCountries} = this.props
        const {items} = groupCountries

        for (let i = 0; i < items.length; i++) {
            const {country} = items[i]

            onUpdateTempSelectedCountries(country, 'remove')
        }

        this.setState({
            groupSelected: false
        })
    }

    render() {
        const {
            groupCountries,
            restSelected,
            search,
            onUpdateTempSelectedCountries,
            onUncheckInputRest,
            onGetTempSelectedCountries
        } = this.props
        const {group, items} = groupCountries

        const templateSelectedCountries = onGetTempSelectedCountries()

        const {groupSelected} = this.state

        const filteredItems = items.filter(item => {
            const {country} = item
            const {name, code} = Object.assign({}, country)
            const term = search.toLowerCase()
            const nameLowerCase = name.toLowerCase()
            const codeLowerCase = code.toLowerCase()

            return nameLowerCase.includes(term) || codeLowerCase.includes(term)
        })

        return (
            <li className="GroupCountries border-top">
                <div className={className('CountryItem', {Disabled: groupSelected === 'disabled'})}>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" id={`group${group}`} className="custom-control-input"
                               onChange={this._handleChangeInput} checked={groupSelected === true || groupSelected === 'disabled'}/>
                        <label htmlFor={`group${group}`} className="custom-control-label">{group}</label>
                    </div>
                </div>

                <ul className="ml-3">
                    {
                        filteredItems.map(countryParam => {
                            const {available, country, added} = countryParam

                            const isAvailable = added ? true : available

                            let isSelected = !restSelected ? !!templateSelectedCountries.find(countryObj => countryObj.code === country.code) : false

                            // if (groupSelected !== "") {
                            //     isSelected = groupSelected
                            // }

                            if (!available && !added) {
                                isSelected = false
                            }

                            return <CountryItem key={`${country.name}_${country.code}`} restSelected={restSelected}
                                                country={country} isChecked={isSelected} isAvailable={isAvailable}
                                                onUpdateTempSelectedCountries={onUpdateTempSelectedCountries}
                                                onUncheckInputRest={onUncheckInputRest}
                                                onUnCheckGroupCountries={this._handleUncheckGroupCountries}/>
                        })
                    }
                </ul>
            </li>
        )
    }
}

// GroupCountries.defaultProps = {}

// GroupCountries.propTypes = {}

export default GroupCountries
