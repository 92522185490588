import React, {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'
import {
    getSettingProfilesShippingDetail,
    updateSettingProfilesShipping,
} from '../../../../services/api/ShippingSettingsServices'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import SettingSearchProductLine from '../components/product-line/SettingSearchProductLine'
import {toaster} from '../../../shared/PFToast'
import SettingShippingZoneItem from '../../components/SettingShippingZoneItem'
import getHistory from '../../../../store/getHistory'
import ReturnPage from '../../../shared/ReturnPage'
import SettingTableLoading from '../../components/SettingTableLoading'

class ShippingProfilesEditPage extends Component {

    state = {
        error: '',
        is_general: false,
        name: '',
        titleProfile: '',
        skus: [],
        zones: [],
        errValidateName: '',
        loading: true,
    }

    componentDidMount() {
        this._getShippingProfilesDetail()
    }

    _getShippingProfilesDetail = async () => {
        const {profilesId} = this.props

        try {
            const {data, message, success} = await getSettingProfilesShippingDetail(profilesId)

            if (!success) throw new Error(message)

            return this.setState({
                is_general: data.is_general || false,
                name: data.name || '',
                titleProfile: data.name || '',
                skus: data.skus || [],
                zones: data.zones || [],
                loading: false,
            })

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            this.setState({
                error: message ? message : e.message,
                loading: false,
            })
        }
    }

    _validateInput = () => {
        const {name} = this.state
        const formatName = name.trim()
        if (!formatName) {
            return this.setState({
                errValidateName: 'This field is required.',
            })
        }
        this._updateShippingProfilesDetail()
    }

    _updateShippingProfilesDetail = async () => {
        const {profilesId} = this.props
        const {name} = this.state
        const formatName = name.trim()
        try {
            const {message, success} = await updateSettingProfilesShipping(formatName, profilesId)

            if (!success) throw new Error(message)

            toaster({message: 'Updated shipping profiles successfully!', type: 'success', duration: 5000})

            const history = getHistory()
            history.push('/a/settings-shipping')

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data

            toaster({message: message ? message : e.message, type: 'error', duration: 5000})
        }
    }


    _handleChangeInput = e => {
        const {name, value} = e.target
        this.setState({
            [name]: value,
            errValidateName: '',
        })
    }

    _handleInputChange = options => {
        const valueSelect = Array.isArray(options) && options.map(item => item.value)
        this.setState({
            skus: valueSelect,
        })
    }

    _handleChange = (propety, value) => {
        this.setState({
            [propety]: value,
        })
    }

    render() {
        const {error, name, skus, is_general, zones, errValidateName, titleProfile, loading} = this.state
        const {profilesId} = this.props

        return (
            <div className="ShippingProfilesEditPage">
                <ReturnPage url='/a/settings-shipping' title='Shipping Profiles'/>
                <div className="MenuTitle">
                    {titleProfile}
                    {is_general &&
                    <p className='text-muted fs-14 m-0 font-weight-normal'>All products not in other profiles.</p>}
                </div>

                {
                    loading ?
                        <SettingTableLoading columnQuantity={3}/>
                        :
                        error ?
                            <p className='text-danger'>{error}</p>
                            :
                            <Fragment>
                                <div className="SectionInner mt-3">
                                    {
                                        error &&
                                        <p className='text-danger'>{error}</p>
                                    }
                                    <div className="form-group">
                                        <h6 className="TitleShippingProfiles">Title</h6>
                                        <input type="text"
                                               className={classnames('form-control', {'border-danger': errValidateName})}
                                               id="TitleShippingProfiles" name="name"
                                               value={name}
                                               disabled={is_general}
                                               onChange={this._handleChangeInput}/>
                                        {
                                            errValidateName &&
                                            <i className='text-danger fs-14'>{errValidateName}</i>
                                        }
                                    </div>

                                    {
                                        !is_general &&
                                        <SettingSearchProductLine profilesId={profilesId} skus={skus}
                                                                  onChange={this._getShippingProfilesDetail}/>
                                    }

                                    <div
                                        className='ShippingPageTop align-items-center d-flex flex-wrap justify-content-between mb-2 mt-4'>
                                        <h6 className='mb-0'>Shipping zone</h6>
                                        <Link to={`/a/settings-shipping/${profilesId}/zone-new`}
                                              className="btn btn-primary btn-sm">Add shipping zone</Link>
                                    </div>
                                    {
                                        (Array.isArray(zones) && zones.length > 0 && zones)
                                        &&
                                        <div className='ShippingZone'>
                                            {
                                                zones.map((item, index) => {
                                                    const {
                                                        rest_of_world = false,
                                                        countries = [],
                                                        name,
                                                        _id: zoneId,
                                                        rates,
                                                    } = item
                                                    return (
                                                        <SettingShippingZoneItem
                                                            key={`SettingShippingZoneItem_${index}`}
                                                            rates={rates}
                                                            zoneId={zoneId} name={name}
                                                            profilesId={profilesId}
                                                            onGetShippingProfilesDetail={this._getShippingProfilesDetail}
                                                            countries={countries}
                                                            rest_of_world={rest_of_world}/>
                                                    )
                                                })
                                            }
                                        </div>
                                    }

                                </div>
                                {
                                    !is_general &&
                                    <div className='ButtonSave d-flex mt-2'>
                                        <button className='btn btn-primary ml-auto' onClick={this._validateInput}>Update
                                            profiles
                                        </button>
                                    </div>
                                }
                            </Fragment>
                }


            </div>
        )
    }

}

ShippingProfilesEditPage.propTypes = {
    profilesId: PropTypes.string.isRequired,
}

export default ShippingProfilesEditPage
