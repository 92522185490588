import React, {Component} from 'react'
import BackNavigationButton from '../../shared/BackNavigationButton'
import StoreWithdrawalsTable from './table/StoreWithdrawalsTable'

class StoreWithdrawals extends Component{
    constructor(props) {
        super(props)
        window.currentStore = Object.assign({}, this.props.store)
    }

    componentWillUnmount() {
        window.currentStore = null
    }

    render() {
        const {store} = this.props
        const {_id, name} = store

        return (
            <div className="StoreWithdrawals StoreDetails">
                <BackNavigationButton to={`/a/stores/${_id}`} text={name}/>
                <div className="text--right">
                    <h1 className='PageTitle mb-4'>Withdrawals</h1>
                </div>
                <div className="bg-white p-3 border rounded shadow-sm">
                    <StoreWithdrawalsTable {...this.props}/>
                </div>
            </div>
        )
    }

}

StoreWithdrawals.propTypes = {}

export default StoreWithdrawals