import React, {Component} from 'react'
import Modal from 'reactstrap/es/Modal'
import ModalHeader from 'reactstrap/es/ModalHeader'
import ModalBody from 'reactstrap/es/ModalBody'
import ModalFooter from 'reactstrap/es/ModalFooter'
import {Spinner} from 'reactstrap'
import {getListGroupConfigs, suggestConfigGroup} from '../../../../services/api/ClustersServices'
import {debounce} from 'lodash'
import AutoComplete from './AutoComplete'

class ModalConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoadingButton: false,
            listSuggest: [],
            isLoading: false,
            listKey: [],
            selectedKey: this.props?.itemConfig?.key ? this.props?.itemConfig?.key : '',
            isKeyError: false,
            isValueError: false,
            limit: 10,
            page: 1,
            totalSuggestions: 0,
        }
        this.inputRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {
        const {isModalOpen} = this.props
        if (prevProps.isModalOpen !== isModalOpen && isModalOpen) {
            this._handleGetSuggestions()
        }
    }

    _handleAddConfig = async (e) => {
        e.preventDefault()
        const {selectedKey} = this.state

        const {updateGroupConfigs, handleToggleModal} = this.props

        const {value} = e.target

        if (!selectedKey || !value.value) {
            return this.setState({
                isKeyError: !selectedKey ? true : false,
                isValueError: !value.value ? true : false,
            })
        }
        this.setState({
            isLoadingButton: true,
        })
        const dataConfigs = {
            type: 'config_map',
            key: selectedKey,
            value: value.value,
        }
        await updateGroupConfigs(dataConfigs, 'update')
        this.setState({
            isLoadingButton: false,
            isKeyError: false,
            isValueError: false,
        })
        handleToggleModal()
    }

    debounceSuggest = debounce(async (key) => {
        this._handleGetSuggestions(key)
    }, 500)

    _handleGetSuggestions = async (inputValue) => {
        try {
            const data = await this._getSuggestions()
            const listKey = data.map((env) => env.key)
            this.setState({
                listSuggest: data,
                isLoading: false,
                listKey,
            })
        } catch (error) {
            console.error(error)
        }
    }

    _getSuggestions = async () => {
        const {title} = this.props
        const isUpdate = title === 'Update config group'
        if (isUpdate) {
            return this._fetchListGroupConfigs()
        }
        return this._fetchSuggestConfigGroup()
    }

    _fetchListGroupConfigs = async () => {
        const {title, group} = this.props
        try {
            const {success, message, data} = await getListGroupConfigs(group.cluster, group._id)
            if (!success) {
                throw new Error(message)
            }
            const envArray = Object.keys(data.config_map).map((key) => ({key, value: data.config_map[key]}))
            return envArray
        } catch (error) {
            console.error(error)
        }
    }

    _fetchSuggestConfigGroup = async () => {
        const {group} = this.props
        const {limit, page, selectedKey} = this.state
        try {
            const payload = {
                page: page,
                limit: limit,
                key: selectedKey ? selectedKey : '',
            }
            const {success, message, data} = await suggestConfigGroup(group.cluster, payload)
            if (!success) {
                throw new Error(message)
            }
            this.setState({
                totalSuggestions: data.total,
            })
            return data.envs
        } catch (error) {
            console.error(error)
        }
    }

    _handleInputChange = async (value) => {
        this.setState({
            isLoading: true,
            selectedKey: value,
            page: 1,
            isKeyError: false,
        })
        this.debounceSuggest(value)
    }

    _handleOnChange = (value) => {
        const {listSuggest} = this.state
        const itemSelect = listSuggest.find((item) => item.key === value)
        if (itemSelect) {
            this.inputRef.current.value = itemSelect.value
        }
        this.setState({
            selectedKey: value,
            page: 1,
        })
    }

    _handleCloseModal = () => {
        const {handleToggleModal} = this.props
        this.setState({
            isKeyError: false,
            isValueError: false,
            page: 1,
            totalSuggestions: 0,
            selectedKey: '',
        })
        handleToggleModal()
    }

    _loadMoreSuggestions = async () => {
        const {listSuggest, listKey, totalSuggestions} = this.state
        
        if (listSuggest.length >= totalSuggestions) {
            return
        }
        this.setState((prevState) => ({
            page: prevState.page + 1,
        }))
        try {
            const data = await this._getSuggestions()
            if (!data) {
                return
            }
            const listKeys = [...listKey, ...data.map((env) => env.key)]
            this.setState({
                listSuggest: [...listSuggest, ...data],
                isLoading: false,
                listKey: listKeys,
            })
        } catch (error) {
            console.error(error)
        }
    }

    render() {
        const {itemConfig, isModalOpen, title} = this.props
        const {isLoadingButton, isLoading, listKey, isKeyError, isValueError} = this.state
        const isUpdate = title === 'Update config group'
        return (
            <div className="ModalConfig">
                <Modal isOpen={isModalOpen} toggle={this._handleCloseModal}>
                    <ModalHeader>{title}</ModalHeader>
                    <ModalBody>
                        <div>
                            <form id="createConfigForm" onSubmit={this._handleAddConfig}>
                                <div>
                                    <label>
                                        <span className="text-danger">*</span>Key:
                                    </label>
                                    <AutoComplete
                                        handleOnChange={this._handleOnChange}
                                        loading={isLoading}
                                        handleInputChange={this._handleInputChange}
                                        itemConfig={itemConfig}
                                        options={listKey}
                                        loadMoreSuggestions={this._loadMoreSuggestions}
                                        isUpdate={isUpdate}
                                    />
                                    {isKeyError && <span className="text-danger">Key is required!</span>}
                                </div>
                                <div>
                                    <label>
                                        <span className="text-danger">*</span>Value:
                                    </label>
                                    <input
                                        name="value"
                                        ref={this.inputRef}
                                        defaultValue={itemConfig?.value}
                                        className="form-control"
                                        onChange={(e) => {
                                            this.setState({isValueError: false})
                                        }}
                                    />
                                    {isValueError && <span className="text-danger">Value is required!</span>}
                                </div>
                            </form>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button onClick={this._handleCloseModal} className="btn btn-secondary">
                            Cancel
                        </button>
                        <button
                            disabled={isLoadingButton}
                            form="createConfigForm"
                            type="submit"
                            className="btn btn-primary d-flex align-items-center mr-2"
                        >
                            {isLoadingButton && <Spinner size="sm" />}
                            <span className="ml-1">Save changes</span>
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ModalConfig

