import React, {Component} from 'react'

const _tableColumns = [
    'File name',
    'Import by',
    'Created At',
]

class TableHeader extends Component {
    render() {
        return (
            <thead className='TableHeader'>
            <tr>
                {_tableColumns.map(column => (
                    <th scope='col' key={column}>
                        {column}
                    </th>
                ))}
            </tr>
            </thead>
        )
    }
}

export default TableHeader
