import React, {Component} from 'react'
import PropTypes from 'prop-types'
import KeyColumn from './columns/KeyColumn'
import ValueColumn from './columns/ValueColumn'
import UpdatedColumn from './columns/UpdatedColumn'
import CreatedColumn from './columns/CreatedColumn'
import IndexColumn from './columns/IndexColumn'

class RenderTableRow extends Component {
    render() {
        const {item, page, limit, index} = this.props
        const tableIndex = (page - 1) * limit + 1 + index

        return (
            <tr className='RenderTableRow'>
                <IndexColumn tableIndex={tableIndex} />
                <KeyColumn item={item} />
                <ValueColumn item={item} />
                <UpdatedColumn item={item} />
                <CreatedColumn item={item} />
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

export default RenderTableRow
