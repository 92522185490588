import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Card, CardBody, CardTitle} from 'reactstrap'

class ConversionsBox extends Component {
    render() {
        const {formattedData} = this.props
        const {totalOrders, totalSales, salesPerOrder, conversionRatePercent} = formattedData
        return (
            <div className='ConversionsBox Box'>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>Conversions</h5>
                        </CardTitle>
                        <div className='card-item'>
                            <span>Orders</span>
                            <span>{totalOrders || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Unit Sales</span>
                            <span>{totalSales || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Unit Sales per Order</span>
                            <span>{salesPerOrder || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Conversion Rate</span>
                            <span>{conversionRatePercent || 0}%</span>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

ConversionsBox.propTypes = {
    formattedData: PropTypes.object.isRequired,
}
export default ConversionsBox
