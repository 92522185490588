import React, {Component} from 'react'
import qs from 'query-string'
import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {
    exportAccountingReportSummary,
    exportDuplicateDeposits, exportTransactionOut,
    getSharedEmail
} from '../../../../../services/api/AccountingReport'
import PropTypes from 'prop-types'
import {toaster} from '../../../../shared/PFToast'
class ExportModal extends Component {
    state = {
        link: '',
        email: '',
        loading: false,
        error: ''
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Check modal is open
        if(prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
            this._getSharedGoogleSheetAccount()
        }
    }

    _handleCloseModal = () => {
        // Reset state
        this.setState({
            link: '',
            email: '',
            loading: false,
            error: ''
        })

        this.props.onToggleModal()
    }

    _getSharedGoogleSheetAccount = async () => {
        try {
            const {data, success, message} = await getSharedEmail()

            if (!success) {
                this.setState({error: message})
                return
            }

            this.setState({email: data})
        } catch (e) {
            this.setState({error: e.message})
        }
    }

    _handleChange = e => {
        this.setState({link: e.target.value})
    }

    _handleClick = async e => {
        e.preventDefault()

        try {
            this.setState({loading: true})
            const {exportType, dateRange} = this.props
            const payload = {link: this.state.link, query: dateRange}
            // eslint-disable-next-line no-restricted-globals
            const query = qs.parse(location.search)
            const version = query && query.version
            let fnSendRequest

            switch (exportType) {
                case 'duplicated-top-up':
                    fnSendRequest = exportDuplicateDeposits
                    break
                case 'transaction-out':
                    fnSendRequest = exportTransactionOut
                    break
                default:
                    fnSendRequest = exportAccountingReportSummary
            }

            const {success, message} = await fnSendRequest(payload, version)

            if (!success) {
                this.setState({error: message})
                return
            }

            this._handleCloseModal()

            toaster({
                type: 'success',
                message: 'Export successfully!'
            })
        } catch (e) {
            this.setState({error: e.message})
        } finally {
            this.setState({loading: false})
        }
    }

    render() {
        const {isOpen} = this.props
        const {loading, email, error, link} = this.state
        const canExport = !loading && !error && link.trim() !== ''

        return (
            <Modal isOpen={isOpen} toggle={this._handleCloseModal} autoFocus={false}>
                <ModalHeader>Link to Google Sheet</ModalHeader>
                <ModalBody>
                    <Label>Link</Label>
                    <Input autoFocus onChange={this._handleChange}/>

                    <div className='jumbotron p-3 mt-3 mb-0'>
                        <div>Share your Google Sheet to this account:</div>
                        <strong>{!email ? '---' : email}</strong>
                    </div>

                    {!!error && <div className='mt-3 text-danger'>{error}</div>}
                </ModalBody>
                <ModalFooter>
                    <Button className='btn-default' onClick={this._handleCloseModal}>Cancel</Button>
                    <Button
                        color='primary'
                        disabled={!canExport}
                        onClick={this._handleClick}
                    >
                        Export
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

ExportModal.propTypes = {
    dateRange: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onToggleModal: PropTypes.func.isRequired
}

export default ExportModal
