import React, {Component} from 'react'
import ProductAttributes from './ProductAttributes'
import ProductEditor from './ProductEditor'
import ProductVariant from './ProductVariants'
import ProductImages from './ProductImages'
import PropTypes from 'prop-types'
import {getProductLineDetails} from '../../../services/api/ProductLinesServices'
import {ATTRIBUTE_PRESELECTED} from '../../create-new-product-line/constant'

class EditProductLine extends Component {
   state = {
        attributes: [],
        images: [],
        error: '',
        listVariants: [],
        originalListVariants: [],
        loading: true,
        title: '',
        product_type: '',
        category: null,
        available_for_store_modes: [],
        available_for_campaign: false,
        support_preview_mockup: false,
        categoryId: '',
        saving: false,
        modal: false,
        is_popular: false,
        errorObj: '',
        sku_prefix: '',
        affiliateCategory: undefined,
        currency: '',
        store: '',
        description: '',
        size_chart: '',
        size_chart_image: '',
        est_process_time_to: '',
        est_process_time_from: '',
        ffm_mapping_product_types: [],
        hasVariantError: true,
        shouldRequireNewSku: false,
       shouldWarningAttribute: false
    }

    componentDidMount() {
        this._fetchListProductLine()
    }

    _fetchListProductLine = async () => {
        try {
            const {productLineId} = this.props

            const {success, message, data} = await getProductLineDetails(productLineId)

            if (!success) throw new Error(message)

            const {
                attributes = [],
                images = [],
                variants = [],
                title,
                description = '',
                size_chart = '',
                size_chart_image = '',
                sku_prefix,
                est_process_time_from,
                product_type,
                category,
                is_popular,
                available_for_store_modes,
                currency,
                affiliate_category = {},
                est_process_time_to,
                available_for_campaign,
                support_preview_mockup,
                ffm_mapping_product_types = [],
            } = Object.assign({}, data)

            const currentModes = available_for_store_modes.map((mode) => ({value: mode, label: mode}))

            let affiliateCategory = {}

            const parseAttributes = attributes.map((attr) => {
                if (attr.value_type === 'Label' && !attr.is_preselected) {
                    return {
                        ...attr,
                        value_type: 'Pack',
                    }
                }
                return attr
            })

            if (!!affiliate_category && Object.keys(affiliate_category).length > 0) {
                affiliateCategory = Object.assign(
                    {...affiliate_category},
                    {
                        label: affiliate_category.name,
                        value: affiliate_category._id,
                    },
                )
            }

            this.setState({
                attributes: parseAttributes,
                images: images,
                description: description,
                size_chart: size_chart,
                size_chart_image: size_chart_image,
                listVariants: variants,
                originalListVariants: variants,
                error: '',
                loading: false,
                title: title,
                est_process_time_from: est_process_time_from,
                est_process_time_to: est_process_time_to,
                store: available_for_store_modes.join(),
                product_type: product_type,
                is_popular: is_popular,
                categoryId: (category && category._id) || '',
                category: category || null,
                available_for_store_modes: currentModes,
                affiliateCategory,
                currency: currency,
                sku_prefix: sku_prefix,
                available_for_campaign,
                support_preview_mockup,
                shouldRequireNewSku: available_for_campaign,
                shouldWarningAttribute: false,
                ffm_mapping_product_types: [
                    ...new Set(this.state.ffm_mapping_product_types.concat(ffm_mapping_product_types)),
                ],
            })
        } catch (e) {
            return this.setState({
                error: e.message,
                loading: false,
            })
        }
    }

    _handleCreateAttribute = () => {
        this.setState(({attributes}) => {
            return {
                shouldWarningAttribute: true,
                attributes: [
                    ...attributes,
                    {
                        name: '',
                        value_type: '',
                        values: [],
                    },
                ],
            }
        })
    }

    _handleChangeAttributesState = (index, field, value) => {
        this.setState(({attributes}) => {
            const attributesUpdated = attributes.map((att, idx) => {
                if (idx === index) {
                    return {
                        ...att,
                        [field]: value,
                        ...(field === 'value_type' && {is_preselected: ATTRIBUTE_PRESELECTED[value]}),
                    }
                }

                return att
            })

            return {
                attributes: attributesUpdated,
            }
        })
    }

    _handleRemoveAttribute = (index) => {
        this.setState(({attributes}) => {
            const attributesUpdated = attributes.filter((att, idx) => idx !== index)

            return {
                attributes: attributesUpdated,
            }
        })
    }

    _handleDiscardChangeState = () => {
        const {originalListVariants} = this.state
        return this.setState({
            listVariants: originalListVariants,
        })
    }

    _handleListVariantsState = (variantId, field, value, isDeleted = false) => {
        return this.setState(({listVariants}) => {
            const newListVariants = listVariants.map((variant) => {
                if (variant._id === variantId) {
                    return {
                        ...variant,
                        [field]: value,
                    }
                }

                return variant
            })
            return {
                listVariants: newListVariants,
            }
        })
    }

    _onPasteListVariants = (value, name, isNumber = true, filteredVariants = []) => {
        const {listVariants} = this.state
        for (let i in filteredVariants) {
            const currValue = value[i] || ''
            if (currValue) {
                filteredVariants[i][name] = isNumber ? Number(currValue) : currValue
            }
        }

        listVariants.forEach((variant) => {
            const matchedVariant = filteredVariants.find((item) => item._id === variant._id)
            if (matchedVariant) {
                Object.assign(variant, matchedVariant)
            }
        })

        const listVariantsNew = [...listVariants]

        this.setState({
            ...this.state,
            listVariants: listVariantsNew,
        })
    }

    _handleChange = (options, property) => {
        this.setState({
            ...this.state,
            [property]: options,
        })
    }

    _handleChangeInput = (value, name) => {
        this.setState({
            ...this.state,
            [name]: value,
        })
    }

    _handleSelectCategories = (options) => {
        const categories = !options ? [] : options
        const firstSelect = categories[0] || {id: ''}
        const {id} = firstSelect
        this.setState({
            ...this.state,
            categoryId: id,
        })
    }

    _handleUpdateProductEditor =  () => {
        const {listVariants: variants, available_for_campaign} = this.state
        const listVariants=[...variants]
        if (available_for_campaign) {
            const newSkuEmpty = listVariants.filter((v) => v.new_sku === '' || v.new_sku === undefined)
            if (newSkuEmpty.length > 0) {
                for (let i in listVariants) {
                    if (listVariants[i]['new_sku'] === ''|| listVariants[i]['new_sku'] === undefined) {
                        listVariants[i]['newSkuError'] = 'New sku code is required.'
                    }
                }
                this._handleChange(listVariants, 'listVariants')
                return new Promise((resolve, reject) => {
                    reject({
                        success: false,
                        type: 'variant-invalid'
                    })
                })
            }
        }
        return new Promise((resolve) => {
            resolve()
        })
    }

    render() {
        const {
            attributes,
            listVariants,
            images,
            error,
            loading,
            product_type,
            title,
            sku_prefix,
            store,
            categoryId,
            category,
            is_popular,
            available_for_store_modes,
            est_process_time_from,
            available_for_campaign,
            affiliateCategory,
            currency,
            description,
            size_chart,
            size_chart_image,
            est_process_time_to,
            support_preview_mockup,
            ffm_mapping_product_types,
        } = this.state

        const {productLineId} = this.props

        const colorAttribute =
            Array.isArray(attributes) && attributes.find((attribute) => attribute.value_type.toLowerCase() === 'color')

        if (loading) return null

        if (error) return <i className="text-danger fs-14">{error}</i>

        return (
            <div className="EditProductLine">
                <div className="row">
                    <div className="col-md-9">
                        <ProductEditor
                            onUpdate={this._handleUpdateProductEditor}
                            onHandleSelectCategories={this._handleSelectCategories}
                            onHandleChangeInput={this._handleChangeInput}
                            onHandleChange={this._handleChange}
                            onFetchListProductLine={this._fetchListProductLine}
                            product_type={product_type}
                            title={title}
                            sku_prefix={sku_prefix}
                            description={description}
                            est_process_time_from={est_process_time_from}
                            category={category}
                            is_popular={is_popular}
                            store={store}
                            categoryId={categoryId}
                            affiliateCategory={affiliateCategory}
                            currency={currency}
                            available_for_store_modes={available_for_store_modes}
                            available_for_campaign={available_for_campaign}
                            support_preview_mockup={support_preview_mockup}
                            est_process_time_to={est_process_time_to}
                            size_chart={size_chart}
                            size_chart_image={size_chart_image}
                            productLineId={productLineId}
                            colorAttribute={colorAttribute}
                            ffm_mapping_product_types={ffm_mapping_product_types}
                        />

                        <ProductAttributes
                            onAddNewAttributeSuccess={() => this.setState({shouldWarningAttribute: true})}
                            shouldWarning={this.state.shouldWarningAttribute}
                            onHandleCreateAttribute={this._handleCreateAttribute}
                            onHandleChangeAttributesState={this._handleChangeAttributesState}
                            onHandleRemoveAttribute={this._handleRemoveAttribute}
                            onFetchListProductLine={this._fetchListProductLine}
                            attributes={attributes}
                            productLineId={productLineId}
                        />

                        <div id={'variants-section'}>
                            <ProductVariant
                                shouldRequireNewSku={this.state.shouldRequireNewSku}
                                onHandleDiscardChangeState={this._handleDiscardChangeState}
                                onHandleListVariantsState={this._handleListVariantsState}
                                onPasteListVariants={this._onPasteListVariants}
                                onHandleChange={this._handleChange}
                                listVariants={listVariants}
                                store={store}
                                available_for_store_modes={available_for_store_modes}
                                attributes={attributes}
                                currency={currency}
                                productLineId={productLineId}
                                setHasVariantError={(hasVariantError) =>
                                    this.setState({hasVariantError: hasVariantError})
                                }
                            />
                        </div>
                    </div>

                    <div className="col-md-3">
                        <ProductImages
                            images={images}
                            onFetchListProductLine={this._fetchListProductLine}
                            productLineId={productLineId}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

EditProductLine.propTypes = {
    productLineId: PropTypes.string.isRequired,
}

export default EditProductLine
