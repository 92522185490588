import React, {Fragment} from 'react'
import ProductVariantFilter from '../ProductVariantFilter'
import {randomID} from '../../../../../helpers/common/randomID'

const ShippingTableRow = (props) => {
    const {variants, attributes, filter, handleFilterVariants, fulfillment_location = '', tab, updateFilter} = props

    return (
        <tbody>
            <ProductVariantFilter
                tab={tab}
                attributes={attributes}
                filter={filter}
                updateFilter={updateFilter}
                handleFilterVariants={handleFilterVariants}
            />
            {!!variants.length &&
                variants.map((variant, index) => {
                    const {sku, attributes, shipping_prices} = variant
                    return (
                        <tr key={`shipping_${index}`}>
                            {!!attributes.length &&
                                attributes.map((attribute) => {
                                    const _id = randomID()
                                    return <td key={_id}>{attribute.value_text}</td>
                                })}
                            <td>{sku}</td>
                            {!!shipping_prices.length &&
                                shipping_prices.map((item) => (
                                    <Fragment key={`shipping_price_${item.to_zone}`}>
                                        {fulfillment_location.toLowerCase() === 'china' && item.to_zone === 'US' ? (
                                            <Fragment>
                                                <td className="text-center">{item.post_service || '-'}</td>
                                                <td className="text-center">{item.fast_shipping || '-'}</td>
                                                <td className="text-center">{item.express_shipping || '-'}</td>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <td className="text-center">{item.first_item || '-'}</td>
                                                <td className="text-center">{item.additional_item || '-'}</td>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                ))}
                        </tr>
                    )
                })}
        </tbody>
    )
}

export default ShippingTableRow

