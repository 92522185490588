import React, {useRef, useState} from 'react'
import {Button, ModalHeader, ModalFooter, Modal, ModalBody} from 'reactstrap'
import {getImportTemplate, importMappingRules} from '../../../../../services/api/MappingRulesServices'
import {toaster} from '../../../../shared/PFToast'

const ImportRuleBySkuProductModal = (props) => {
    const {isOpen, toggle, header, getListRules} = props
    const [error, setError] = useState('')
    const inputRef = useRef()
    const [dateError, setDateError] = useState('')

    const onChangeFile = async (event) => {
        try {
            setError('')
            setDateError('')
            const formData = new FormData()
            formData.append('file', event.target.files[0])

            const {success, message, data} = await importMappingRules(formData, 'product-sku')
            if (!success) {
                return setError(message)
            }

            const {csvInvalidRule = ''} = data

            if (csvInvalidRule) {
                return setDateError(csvInvalidRule)
            }

            toaster({
                type: 'success',
                message: `Import successfully!`
            })

            getListRules()
            clear()
        } catch (e) {
            console.log(e.message)
        }
    }

    const onUploadFile = () => {
        inputRef.current.click()
        inputRef.current.value = ''
    }

    const clear = () => {
        toggle()
        inputRef.current.value = ''
    }

    const _downloadBlob = (fileName, fileBlob, type) => {
        const blob = new Blob([fileBlob], {type})
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(blob)
        downloadLink.download = fileName
        downloadLink.click()
    }

    const _downloadTemplate = async () => {
        try {
            setError('')
            setDateError('')
            const params = {
                type: 'product-sku'
            }
            const {data, success, message} = await getImportTemplate(params)
            if (!success) {
                return setError(message)
            }
            const {filename, content} = data
            _downloadBlob(filename, content, {type: 'text/csv'})
        } catch (e) {
            console.log(e.message)
        }
    }

    return (
        <div>
            <Modal className="ImportRulesModal" isOpen={isOpen} toggle={toggle} centered size="lg" backdrop="static">
                <ModalHeader toggle={toggle}>{header}</ModalHeader>
                <ModalBody>
                    <div className="mb-1">1. Apply điều kiện AND giữa các điều kiện trong 1 RULE</div>
                    <div className="mb-1">2. Hướng dẫn nhập điều kiện</div>
                    <ul>
                        <li className="mb-1">Dấu ",": điều kiện AND</li>
                        <li className="mb-1">Dấu "/": điều kiện OR</li>
                        <li className="mb-1">Text "empty" -> ko có giá trị</li>
                        <li className="mb-1">Dấu "*": chứa cụm từ (eg *t-shirt*)</li>
                        <li className="mb-1">{`EQUALS: "{value}" (eg: "t-shirt")`}</li>
                    </ul>
                    <div className='d-flex flex-column align-items-center mt-3'>
                        <div className='mb-2'>
                            <a
                                className='text-primary cursor-pointer'
                                onClick={_downloadTemplate}
                            >
                                Download template import rule by SKU products
                            </a>
                        </div>
                        <input type='file' accept='.csv' onChange={onChangeFile} hidden ref={inputRef} />
                        <Button color='primary' onClick={onUploadFile}>
                            <i className='ti-upload mr-2' />
                            Import
                        </Button>
                        {error && <div className='mt-1 text-danger'>{error}</div>}
                        {dateError && <div className='mt-1 text-danger'>Import failed. To download the error files, click <a onClick={() => {_downloadBlob('import-by-sku-product-error.csv', dateError, {type: 'text/csv'})}} style={{textDecoration: 'underline', cursor: 'pointer'}}>here</a></div>}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color='default' onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ImportRuleBySkuProductModal
