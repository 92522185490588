import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/manager' : 'https://admin-staging.merchize.com/api/manager'

const api = createAPIServices({baseUrl})

export const getListPipeline = params =>
    api.makeAuthRequest({
        url: `/stores/jobs/list`,
        method: 'GET',
        params
})

export const retryJob = jobId =>
    api.makeAuthRequest({
        url: `/stores/jobs/${jobId}/retry`,
        method: 'POST'
})

export const retryJobGroup = jobId =>
    api.makeAuthRequest({
        url: `/groups/jobs/${jobId}/retry`,
        method: 'POST'
    })

export const changeDomain = (storeId, newDomain) => {
    return api.makeAuthRequest({
        url: `/stores/${storeId}/jobs/change-domain`,
        method: 'POST',
        data: {
            newDomain,
        },
    })
}

export const updateStoreThemes = (storeId, theme) => {
    return api.makeAuthRequest({
        url: `/stores/${storeId}/jobs/change-theme`,
        method: 'POST',
        data: {theme:theme},
    })
}

export const changeStorefrontStatus = (storeId, status) => {
    return api.makeAuthRequest({
        url: `/stores/${storeId}/jobs/change-storefront-status`,
        method: 'POST',
        data: {status},
    })
}

export const resetPipeline = (jobId) => {
    return api.makeAuthRequest({
        url: `/stores/jobs/${jobId}/reset`,
        method: 'POST',
    })
}

export const updateSchedularPriority = (storeId, data) => {
    return api.makeAuthRequest({
        url: `/stores/${storeId}`,
        method: 'PUT',
        data
    })
}
