import React, { useEffect, useState } from "react"
import { getListResources } from "../../../services/api/ResourceService"
import { toaster } from "../../shared/PFToast"

const ResourceFilterSelect = (props) => {

    const [resources, setResouces] = useState([])
    const { onChange, value, resourceKind } = props

    useEffect(() => {
        fetchResources(resourceKind)
    }, [resourceKind])

    const fetchResources = async (search_term) => {
        try {
            const { data, success, message } = await getListResources({ page: 1, limit: 100, search_term })
            if (success) {
                setResouces(data.resources)
            } else {
                toaster({
                    type: "success",
                    message
                })
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="ResourceFilterSelect">
            <p className="mb-0 hidden">Kind</p>
            <select name="resourceId" onChange={onChange} value={value}>
                <option value="">All</option>
                {
                    resources.length > 0 && resources.map(resource => (
                        <option key={resource._id} value={resource._id}>{resource.name}</option>
                    ))
                }
            </select>
        </div>
    )
}

export default ResourceFilterSelect