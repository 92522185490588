import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {toaster} from '../../../../shared/PFToast'
import {deleteProductLineShipping} from '../../../../../services/api/ShippingSettingsServices'

class ListSelectedItemProductLine extends Component {

    _deleteProductLineShipping = async (sku) => {
        const {productLineSelected, onChangeProductLineSelected, profilesId} = this.props
        const productLineSelectedNew = productLineSelected.filter(product => product.sku !== sku)

        try {
            const {success, message} = await deleteProductLineShipping(profilesId, sku)
            if (!success) throw new Error(message)
            toaster({message: 'Deleted product line successfully!', type: 'success', duration: 1000})

            onChangeProductLineSelected(productLineSelectedNew)

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data

            toaster({message: message ? message : e.message, type: 'error', duration: 5000})
        }
    }

    render() {
        const {productLineSelected} = this.props

        return (
            <div className="ListItemProductLine table-responsive">
                {
                    productLineSelected.length > 0 && Array.isArray(productLineSelected)
                    &&
                    <table className="ListProductLine table table-striped table-hover mt-3 mb-0">
                        <thead>
                        <tr>
                            <th className='table-col-20'>SKU</th>
                            <th className='table-col-50'>Title</th>
                            <th className='table-col-20'>Type</th>
                            <th className='table-col-10 text-center'>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            productLineSelected.map((item, index) => {
                                return (
                                    <tr key={`ListProductLine_${index}`}>
                                        <td>{item.sku}</td>
                                        <td>{item.title}</td>
                                        <td>{item.type}</td>
                                        <td className='text-danger text-center cursor-pointer'>
                                            {
                                                productLineSelected.length > 1 &&
                                                <i onClick={() => this._deleteProductLineShipping(item.sku)}
                                                   className='ti-close'/>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                }
            </div>
        )
    }
}

ListSelectedItemProductLine.propTypes = {
    productLineSelected: PropTypes.array.isRequired,
    onChangeProductLineSelected: PropTypes.func.isRequired,
}

export default ListSelectedItemProductLine
