import React, {Component} from 'react'
import ArtworkUploadSide from "./ArtworkUploadSide"
import PropTypes from 'prop-types'

class ArtworksUploadContainer extends Component {
    render() {
        const {sides, productId} = this.props

        return (
            <div className="ArtworksUploadContainer">
                <div className="row">
                    {
                        sides.map(side => {
                            return (
                                <div
                                    className={`GroupArtworksPosition col-12 col-md-3 mb-3 mb-md-0 text-center ${side}`}
                                    key={`artworkUpload_${side}`}
                                >
                                    <ArtworkUploadSide side={side} productId={productId}/>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

ArtworksUploadContainer.propTypes = {
    sides: PropTypes.array.isRequired,
    productId: PropTypes.string.isRequired,
}

export default ArtworksUploadContainer
