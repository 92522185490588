import React, {Component} from 'react'
import PropTypes from 'prop-types'

class PFTable extends Component {
    state = {
        selected: [],
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const isSourceChanged = prevProps.source.length !== this.props.source.length || !prevProps.source.every((item, index) => item._id === this.props.source[index]._id)

        if (isSourceChanged) {
            this.setState({selected: []})
        }
    }

    _handleSelect = (newId, isSelect) => {
        if (isSelect) {
            this.setState((prevState) => ({
                selected: [...prevState.selected, newId],
            }))
        } else {
            this.setState(prevState => ({
                selected: prevState.selected.filter(selectedId => selectedId !== newId),
            }))
        }
    }

    _handleSelectAll = isSelect => {
        const ids = this.props.source.map(item => item._id)

        if (isSelect) {
            this.setState({selected: ids})
        } else {
            this.setState({selected: []})
        }
    }


    render() {
        const table = {
            selected: this.state.selected,
            onSelect: this._handleSelect,
            onSelectAll: this._handleSelectAll,
        }

        return (
            <div className='PFTable'>
                <table className='table table-borderless table-striped'>
                    {this.props.children(table)}
                </table>
            </div>
        )
    }
}

PFTable.defaultProps = {
    source: [],
}

PFTable.propTypes = {
    source: PropTypes.array.isRequired,
}

export default PFTable
