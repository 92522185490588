import React, {Component} from 'react'
import {render, unmountComponentAtNode} from 'react-dom'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap'
import PropTypes from 'prop-types'

class ConfirmModal extends Component {
    state = {
        loading: false,
        error: '',
    }

    _toggleModal = () => {
        this.props.onClose(false)
    }

    _handleClickOk = () => {
        const {onOk, onClose} = this.props

        if (typeof onClose !== 'function') {
            onClose(false)
            return
        }

        if (!onOk || typeof onOk !== 'function') {
            return onClose(false)
        }

        const result = onOk()

        if (!result || !result.then) {
            return result
        }

        this.setState({loading: true})

        result.then(response => {
            onClose(false)
        }).catch(e => {
            this.setState({
                loading: false,
                error: e.message,
            })
        })
    }

    _getFooterContent = () => {
        const {confirmText, cancelText, confirmColor, cancelColor} = this.props
        const {loading} = this.state

        return (
            <>
                {
                    cancelText &&
                    <Button color={cancelColor} onClick={this._toggleModal} className='btn-default'>
                        {cancelText}
                    </Button>
                }
                {
                    confirmText &&
                    <Button color={confirmColor} onClick={this._handleClickOk} disabled={loading}>
                        {
                            loading && <Spinner color='white' size='sm' className='mr-2'/>
                        }
                        {confirmText}
                    </Button>
                }
            </>
        )
    }

    render() {
        const {title, message, size, className} = this.props
        const {error} = this.state
        const footerContent = this._getFooterContent()

        return (
            <Modal
                isOpen
                className={`ConfirmModal modal-dialog-centered ${className}`}
                toggle={this._toggleModal}
                size={size}
            >
                {
                    title &&
                    <ModalHeader toggle={this._toggleModal}>{title}</ModalHeader>
                }
                <ModalBody>
                    {message}

                    {
                        error &&
                        <div className='p-3 mt-3 bg-light text-danger'>
                            {error}
                        </div>
                    }
                </ModalBody>
                <ModalFooter>{footerContent}</ModalFooter>
            </Modal>
        )
    }
}

ConfirmModal.defaultProps = {
    title: '',
    message: 'Are you sure?',
    confirmText: 'Ok',
    cancelText: 'Cancel',
    confirmColor: 'primary',
    cancelColor: '',
    className: '',
    size: null,
}

ConfirmModal.propTypes = {
    title: PropTypes.node,
    message: PropTypes.node,
    confirmText: PropTypes.node,
    cancelText: PropTypes.node,
    confirmColor: PropTypes.string,
    cancelColor: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onOk: PropTypes.func,
}

export function confirmModal(props) {
    return new Promise(resolve => {
        let el = document.createElement('div')

        const handleResolve = result => {
            unmountComponentAtNode(el)
            el = null
            resolve(result)
        }

        render(<ConfirmModal {...props} onClose={handleResolve}/>, el)
    })
}
