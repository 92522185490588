import React, {Component} from 'react'
import DocTitle from "../../shared/DocTitle"
import StoreTransactions from './StoreTransactions'


class StoreTransactionsContainer extends Component {

    render() {
        return (
            <div className='StoreTransactionsContainer'>
                <DocTitle title='Transactions'/>

                <StoreTransactions {...this.props.location.state} />
            </div>
        )
    }

}

StoreTransactionsContainer.propTypes = {}

export default StoreTransactionsContainer