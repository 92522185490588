import React, {Component} from 'react'
import ProductVariantsHead from './ProductVariantsHead'
import ProductVariantsRow from './ProductVariantsRow'
import PropTypes from 'prop-types'
import ProductVariantFilter from './ProductVariantFilter'

class ProductVariantsTable extends Component {
    render() {
        const {
            variants,
            filteredVariants,
            attributes,
            handleListVariantsState,
            onPasteListVariants,
            store,
            currency,
            filter,
            handleFilterVariants,
        } = this.props

        return (
            <div className="ProductVariantsTable">
                <table className="table table-hover">
                    <ProductVariantsHead store={store} attributes={attributes} />
                    <tbody>
                        <ProductVariantFilter filter={filter} attributes={attributes} handleFilterVariants={handleFilterVariants} />
                        {Array.isArray(filteredVariants) &&
                            filteredVariants.map((variant, index) => {
                                return (
                                    <ProductVariantsRow
                                        currency={currency}
                                        store={store}
                                        onPasteListVariants={onPasteListVariants}
                                        key={variant._id}
                                        variant={variant}
                                        attributes={attributes}
                                        handleListVariantsState={handleListVariantsState}
                                        variantIndex={variant._id}
                                        totalVariants={variants.length}
                                    />
                                )
                            })}
                    </tbody>
                </table>
            </div>
        )
    }
}

ProductVariantsTable.propTypes = {
    variants: PropTypes.array.isRequired,
    attributes: PropTypes.array.isRequired,
    handleListVariantsState: PropTypes.func.isRequired,
    store: PropTypes.string.isRequired,
}

export default ProductVariantsTable

