import React from 'react'
import TableLoading from '../../../shared/TableLoading'
import PFPagePagination from '../../../shared/PFPagePagination'
import TableRow from '../components/TableRow'
const _tableColumns = ['Order number', 'Store ID', 'Paypal refund', 'Fulfillment refund', 'Profit change', 'Status']


const Table = ({isLoading, page, pages, orderRefund, onChangeFilter}) => {

    const _onChangePage =(page)=>{
        onChangeFilter('page',page)
    }
    return (
        <div className='TableContainer table-responsive mt-5'>
            <table className='table table-striped table-light'>
                <thead className='TableHeader thead-light'>
                    <tr>
                        {_tableColumns.map(column => (
                            <th scope='col' key={column}>
                                {column}
                            </th>
                        ))}
                    </tr>
                </thead>
                {!isLoading && <TableRow
                    orderRefund={orderRefund}
                />}
                {isLoading && <TableLoading
                    columnQuantity={_tableColumns.length}
                />}

            </table>
            <PFPagePagination
                page={page}
                pages={pages}
                onChangePage={_onChangePage}
            />
        </div>

    )
}

export default Table
