import React, {useCallback, useEffect, useState} from 'react'
import GlobalDepositTable from './GlobalDepositTable'
import PFPagePagination from '../../shared/PFPagePagination'
import { DEFAULT_QUERY } from '../constants'
import { toaster } from '../../shared/PFToast'
import { trimObjectValues } from '../../../helpers/common/cleanObject'
import { searchGlobalDeposits } from '../../../services/api/BillingServices'
import GlobalDepositTableFilter from './GlobalDepositTableFilter'

const GlobalDepositPage = () => {
    const [items, setItems] = useState([])
    const [query, setQuery] = useState(DEFAULT_QUERY)
    const [pages, setPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)

    const _handleChangePage = (page) => {
        setQuery(prev => ({
            ...prev,
            page
        }))
    }

    const _fetchListDeposit = useCallback(async (refetch = true) => {
        try {
            setLoading(true)
            let payload = {}
            if (refetch) {
                payload = DEFAULT_QUERY
            } else {
                payload = trimObjectValues(query)
            }
            const {success, data, message} = await searchGlobalDeposits(payload)
            if (!success) {
                throw new Error(message)
            }
            setPages(data.pages)
            setTotal(data.total)
            setItems(data.deposits)
        } catch(error) {
            toaster({
                type: 'error',
                message: error.message
            })
        } finally {
            setLoading(false)
        }
    }, [query])

    useEffect(() => {
        const debounceFetch = setTimeout(() => {
            _fetchListDeposit(false)
        }, 500)

        return () => {
            clearTimeout(debounceFetch);
          };
    }, [_fetchListDeposit])

    return (
        <div className="GlobalDepositPage">
            <div className="d-flex flex-wrap mb-3">
                <h2 className="mb-0">Global deposits</h2>
            </div>
            <div className="GlobalDepositPageInner SectionInner">
                <GlobalDepositTableFilter query={query} total={total} setQuery={setQuery} />
                <GlobalDepositTable items={items} loading={loading} fetchDeposits={_fetchListDeposit} />
                <PFPagePagination page={query.page} pages={pages} onChangePage={_handleChangePage} />
            </div>
        </div>
    )
}

export default GlobalDepositPage

