import React, {Component} from 'react'
import ResourcePage from './ResourcePage'
import getEnv from '../../../helpers/common/getEnv'

class ResourceContainer extends Component {
    render() {
        const isSupportMpMode = getEnv('isEnableMpMode')
        if (isSupportMpMode) return null

        return (
            <div className='ClustersContainer'>
                <ResourcePage />
            </div>
        )
    }
}

export default ResourceContainer
