export const DEFAULT_QUERY = {
    page: 1,
    type: '',
    created_at_start: '',
    created_at_end: '',
    last_suspend_at_start: '',
    last_suspend_at_end: '',
    store_slug: '',
    mongodb_resource: '',
    limit: 50
}

export const DATABASE_JOB_STATUS = [
    {
        label: 'All',
        value: ''
    },
    {
        label: 'Done',
        value: 'done'
    },
    {
        label: 'Cancelled',
        value: 'cancelled'
    },
    {
        label: 'Pending',
        value: 'pending'
    },
    {
        label: 'Processing',
        value: 'processing'
    },
    {
        label: 'Error',
        value: 'error'
    },
]

export const DATABASE_JOB_TYPE = [
    {
        label: 'All',
        value: ''
    },
    {
        label: 'Mongo dump',
        value: 'mongodump'
    },
    {
        label: 'Mongo restore',
        value: 'mongorestore'
    }
]
