import React from 'react'
import ShippingTable from './ShippingTable'
import getHistory from '../../../../../store/getHistory'

const ShippingContainer = (props) => {
    const {currency, productId} = props

    const togglePrivateShipping = () => {
        const history = getHistory()
        history.push(`/a/catalog/${productId}/private-shipping`)
    }

    return (
        <div className="ShippingContainer">
            <div className="d-flex justify-content-end my-3">
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary mr-3" onClick={togglePrivateShipping}>
                        Private shipping
                    </button>
                    <span>
                        Currency: <b>{currency}</b>
                    </span>
                </div>
            </div>
            <ShippingTable {...props} />
        </div>
    )
}

export default ShippingContainer

