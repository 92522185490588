import React, {Component, Fragment} from 'react'
import ImageProgressUpload from "./ImageProgressUpload"
import {uploadVariantImage} from "../../../../../services/api/ProductServices"

const _maxSize = 4 * 1024 * 1024 // 4MB

class UploadVariantImages extends Component {
    constructor(props) {
        super(props);

        this.inputFiles = React.createRef()

        this.state = {
            loading: false,
            totalUpload: 0,
            error: "",
        }
    }

    _handleChangeFile = e => {
        const selectedFiles = e.target.files

        if (selectedFiles.length <= 0) {
            return
        }

        if (selectedFiles.length > 10) {
            const {onUpdateError} = this.props

            onUpdateError("Maximum files upload (<11)")

            this.inputFiles.current.value = ""

            return
        }

        const filesFormatted = []

        for (let i = 0; i < selectedFiles.length; i++) {
            filesFormatted.push({
                file: selectedFiles[i],
                is_valid: selectedFiles[i].size < _maxSize,
            })
        }

        const inValidFiles = filesFormatted.filter(file => !file.is_valid)
        const validFiles = filesFormatted.filter(file => file.is_valid)

        if (inValidFiles.length > 0) {
            const arrFilesName = inValidFiles.map(file => file.file.name)

            this.setState({
                error: `${arrFilesName.join(", ")} <span class="text-muted">exceeds the maximum upload size</span>`,
            })
        }

        this._handleUploadImage(validFiles)
    }

    _handleUploadImage = async (validFiles) => {
        this.setState({
            loading: true,
            totalUpload: validFiles.length,
        })

        const {variantId, onFetchListVariantImage, onUpdateError} = this.props

        const files = validFiles.map(validFile => validFile.file)

        for (let i = 0; i < files.length; i++) {
            try {
                const postData = new FormData()
                postData.append('images', files[i])

                const {success, message} = await uploadVariantImage(variantId, postData)

                if (!success) {
                    onUpdateError(message)
                    continue
                }

                await onFetchListVariantImage()

                this.setState(({totalUpload}) => {
                    return {
                        totalUpload: totalUpload - 1,
                    }
                })
            } catch (e) {
                this.setState({
                    loading: false,
                })

                onUpdateError(e.message)
            }
        }
    }

    render() {
        const {loading, totalUpload, error} = this.state
        const output = []

        for (let i = 0; i < totalUpload; i++) {
            output.push(<ImageProgressUpload key={i}/>)
        }

        return (
            <Fragment>
                {
                    loading && output
                }

                <div className="UploadVariantImages p-2 d-flex align-items-stretch">
                    <div
                        className="border BorderWrapper VariantImage d-flex align-items-stretch justify-content-center">
                        <div className="BorderInner">
                            <input
                                type="file"
                                ref={this.inputFiles}
                                accept="image/*"
                                id="uploadVariantImage"
                                disabled
                                multiple
                            />
                            <label htmlFor="uploadVariantImage"
                                   className="mb-0 d-flex flex-column justify-content-center align-items-center flex-grow-1">
                                <i className="ti-cloud-up"/>
                                <small>Upload</small>
                            </label>
                        </div>
                    </div>
                </div>

                {
                    error &&
                    <div className="w-100 mt-3 fs-14" dangerouslySetInnerHTML={{__html: error}}/>
                }
            </Fragment>
        )
    }
}

export default UploadVariantImages
