import React from 'react'
import PropTypes from 'prop-types'

const TableFilter = ({options, onChange, value}) => {
    return (
        <td>
            <select className="form-control" value={value} onChange={onChange}>
                <option value="">All</option>
                {options.map((v) => {
                    return (
                        <option key={v.code} value={v.code}>
                            {v.text}
                        </option>
                    )
                })}
            </select>
        </td>
    )
}

export default TableFilter

TableFilter.propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
}
