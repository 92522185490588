import React, {Component} from 'react'
import ProductAttributeItem from "./ProductAttributeItem"
import PropTypes from 'prop-types'

class ProductAttributesList extends Component {
    render() {
        const {attributes, onChangeAttributesState, onRemoveAttribute} = this.props

        return (
            <div className="ProductAttributesList">
                <ul>
                    {
                        attributes.map((att, index) => {
                            return (
                                <ProductAttributeItem key={index} attribute={att}
                                                      onChangeAttributesState={onChangeAttributesState}
                                                      onRemoveAttribute={onRemoveAttribute}
                                                      attributeIndex={index}/>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}

ProductAttributesList.propTypes = {
    attributes: PropTypes.array.isRequired,
    onChangeAttributesState: PropTypes.func.isRequired,
    onRemoveAttribute: PropTypes.func.isRequired,
}

export default ProductAttributesList
