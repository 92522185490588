import React, {useEffect, useState} from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import PFInputMulti from '../../shared/PFInputMulti'
import {toaster} from '../../shared/PFToast'

const ColorMappingModal = (props) => {
    const {isOpen, attributes = [], toggle, onChangeMapping} = props

    const [disableSubmit, setDisableSubmit] = useState(true)
    const [colors, setColors] = useState([])
    const [isOpenAddColor, setIsOpenAddColor] = useState(false)
    const [colorId, setColorId] = useState('')

    const _handleRemoveMappingColor = (id, removeColor) => {
        const tempColors = [...colors]
        const index = colors.findIndex((color) => color.id === id)
        tempColors[index].ffm_mapping_names = tempColors[index].ffm_mapping_names.filter(
            (ffmColor) => ffmColor !== removeColor
        )
        setDisableSubmit(false)
        setColors(tempColors)
    }

    useEffect(() => {
        const colorAttribute = attributes.find((attribute) => attribute.value_type.toLowerCase() === 'color') || {}
        setColors(colorAttribute.values || [])
    }, [attributes])

    const _handleSave = async (colors, onClose = false) => {
        try {
            const newAttributes = attributes.map((attr) => {
                if (attr.value_type.toLowerCase() !== 'color') return attr
                return {
                    ...attr,
                    values: colors,
                }
            })
            onChangeMapping(newAttributes)
            onClose && toggle()
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    const _toggleAddColor = (colorId = '') => {
        setColorId(colorId)
        setIsOpenAddColor((prev) => !prev)
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="xl" centered>
            <ModalHeader toggle={toggle}>Mapping color</ModalHeader>
            <ModalBody>
                <div className="TableColorMapping table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th className="table-col-20">Platform color</th>
                                <th>Fulfillment color</th>
                            </tr>
                        </thead>
                        <tbody>
                            {colors.length > 0 ? (
                                colors.map((color) => {
                                    const {id, name, value, ffm_mapping_names = []} = color
                                    return (
                                        <tr key={id}>
                                            <td>
                                                <div className="badge badge-secondary p-2">{`${name} | ${value}`}</div>
                                            </td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    {ffm_mapping_names.map((ffmColor) => (
                                                        <div className="FFMColorMapping ">
                                                            <div className="badge badge-secondary mr-2 p-2">
                                                                {ffmColor}
                                                                <span
                                                                    class="Icon cursor-pointer ml-2"
                                                                    onClick={() =>
                                                                        _handleRemoveMappingColor(id, ffmColor)
                                                                    }
                                                                >
                                                                    <i class="fas fa-times"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div className="ButtonAdd">
                                                        <Button
                                                            color="link"
                                                            className="p-0"
                                                            onClick={() => _toggleAddColor(id)}
                                                        >
                                                            <i className="fa fa-plus"></i>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td className="text-center" colSpan={2}>
                                        <span>No color attribute!</span>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {colorId && isOpenAddColor && (
                    <AddMappingColorModal
                        colorId={colorId}
                        isOpen={isOpenAddColor}
                        toggle={_toggleAddColor}
                        colors={colors}
                        handleSave={_handleSave}
                        setDisableSubmit={setDisableSubmit}
                    />
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggle}>
                    Close
                </Button>
                <Button color="primary" onClick={() => _handleSave(colors, true)} disabled={disableSubmit}>
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const AddMappingColorModal = (props) => {
    const {isOpen, toggle, colors, colorId, handleSave, setDisableSubmit} = props

    const [value, setValue] = useState([])

    const _handleChangeFfmProductColor = (event) => {
        const {value} = event
        setValue(value)
    }

    const _handleSave = async () => {
        const tempColors = [...colors]
        const index = colors.findIndex((color) => color.id === colorId)
        tempColors[index].ffm_mapping_names = [...tempColors[index].ffm_mapping_names, ...value]
        handleSave(tempColors)
        setDisableSubmit(false)
        toggle()
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
            <ModalHeader toggle={toggle}>Add fulfillment color</ModalHeader>
            <ModalBody>
                <PFInputMulti
                    defaultValue={value}
                    onChange={_handleChangeFfmProductColor}
                    placeholder="Input color name and press enter"
                />
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggle}>
                    Close
                </Button>
                <Button color="primary" onClick={() => _handleSave()} disabled={!value.length}>
                    Add
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ColorMappingModal

