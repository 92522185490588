import React, {Component} from 'react'
import TableContainer from './TableContainer'
import {searchGlobalSettings} from './../../../services/api/StoreServices'
import PubSub from '../GlobalSettingBroker'
import CreateGlobalSetting from './actions/CreateGlobalSetting'
import PFPagePagination from '../../shared/PFPagePagination'
import SearchGlobalSettings from './actions/SearchGlobalSettings'
class GlobalSettings extends Component {
    state = {
        items: [],
        loading: true,
        params: {
            limit: 10,
            page: 1,
            key: '',
            value:''
        },
        total: 0,
        pages: 1,
        err: '',
    }

    _subscription = null

    componentDidMount() {
        this._getGlobalSettings()
        this._subscription = PubSub.subscribe('STORE_FETCH_GLOBAL_SETTINGS', this._getGlobalSettings)
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this._subscription)
    }

    _getGlobalSettings = async () => {
        
        const {params} = this.state
        const {limit, page, key, value} = params

        try {
            this.setState({loading : true})
            const {success, data, message} = await searchGlobalSettings(limit, page, key, value)

            if (!success) {
                return this.setState({
                    err: message,
                })
            }

            const {total, pages, settings} = Object.assign({}, data)

            this.setState({
                items: Array.isArray(settings) ? settings : [],
                total,
                pages,
            })
        } catch (e) {
            this.setState({
                err: e.message,
            })
        }finally {
            this.setState({
                loading: false
            })
        }
    }

    _handlePageChange = (page) => {
        const {params} = this.state
        this.setState(
            {
                params: {...params, page}
            },
            () => this._getGlobalSettings(),
        )
    }

    _handleSearch = (type, value) => {
        const { params } = this.state

        if(type === 'key'){
            this.setState(
                {
                    params: { ...params, value: '', key: value}
                },
                () => this._getGlobalSettings()
            )
        }else{
            this.setState(
                {
                    params: { ...params,key : '', value: value}
                },
                () => this._getGlobalSettings()
            )
        }     
    }

    render() {
        const {items, params, pages, loading, err} = this.state
        const {page} = params

        return (
            <div className='GlobalSettings'>
                <div className='headGlobalSetting d-flex align-items-center justify-content-between mt-3 mb-3'>
                    <CreateGlobalSetting />
                    <SearchGlobalSettings onKeyChange={this._handleSearch}/>
                </div>

                    <div className='GlobalSettingsPageInner SectionInner'>
                        {!!err && <div className='alert alert-danger'>{err}</div>}
                        <TableContainer items={items} loading={loading}/>
                        <PFPagePagination page={page} pages={pages} onChangePage={this._handlePageChange}/>
                    </div>
                
            </div>
        )
    }
}

GlobalSettings.propTypes = {}

export default GlobalSettings