import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {deleteShippingRate} from '../../../../../services/api/ShippingSettingsServices'
import {toaster} from '../../../../shared/PFToast'
import {confirmModal} from '../../../../shared/PFConfirmModalV2'

class ZoneRateItem extends Component {

    _handleClickDelete = () => {
        const {rate} = this.props
        const {title} = rate

        confirmModal({
            confirmText: 'Delete',
            cancelText: 'Cancel',
            confirmColor: 'danger',
            title: 'Delete Rate',
            message: <span>Are you sure you want to delete rate <b>{title}</b>?</span>,
            onOk: this._deleteShippingRate,
        })
    }

    _deleteShippingRate = async () => {
        const {zoneId, rate, onFetZoneDetails} = this.props
        const {_id: rateId} = rate
        try {
            const {success, message} = await deleteShippingRate(zoneId, rateId)

            if (!success) throw new Error(message)

            toaster({message: 'Deleted shipping rate successfully!', type: 'success', duration: 5000})

            onFetZoneDetails()

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            toaster({message: message ? message : e.message, type: 'error', duration: 5000})
        }
    }

    render() {
        const {rate, zoneId, profilesId} = this.props
        const {title, subtitle, _id: rateId} = rate

        return (
            <li className="ZoneRateItem d-flex justify-content-between align-items-center px-3 py-2">
                <div>
                    <div>{title}</div>
                    <div className="text-muted">{subtitle}</div>
                </div>
                <div className='ActionRateItem d-flex align-items-center'>
                    <Link className='ActionRateItemEdit fs-14'
                          to={`/a/settings/shipping-zones/${profilesId}/${zoneId}/rates/${rateId}`}>Edit</Link>
                    <span className='cursor-pointer text-danger fs-14' onClick={this._handleClickDelete}>Delete</span>
                </div>
            </li>
        )
    }
}

ZoneRateItem.propTypes = {
    zoneId: PropTypes.string.isRequired,
    rate: PropTypes.object.isRequired,
    onFetZoneDetails: PropTypes.func.isRequired,
    profilesId: PropTypes.string.isRequired,
}

export default ZoneRateItem
