import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ClusterFormContainer from "./form/ClusterFormContainer"
import {getClusterDetail, updateCluster} from "../../../../services/api/ClustersServices"

class ClusterDetailsContainer extends Component {
    state = {
        cluster: null,
        loading: false,
        error: "",
    }

    _cluster = null

    componentDidMount() {
        this._fetchClusterDetail()
    }

    _fetchClusterDetail = async () => {
        const {clusterId} = this.props
        try {
            const {data, success, message} = await getClusterDetail(clusterId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            this._cluster = data

            return this.setState({
                error: "",
                cluster: data,
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _handleUpdateCluster = (field, value) => {
        this._cluster = {
            ...this._cluster,
            [field]: value,
        }
    }

    _handleSaveCluster = async () => {
        this.setState({
            loading: true,
        })

        const {name, host, index, ip, mongodb_uri, nats_uri, redis_uri, provider, status, zone, _id} = this._cluster

        const sendData = {
            name,
            host,
            index,
            ip,
            mongodb_uri,
            nats_uri,
            provider,
            redis_uri,
            status,
            zone,
            _id
        }
        try {
            const {success, message} = await updateCluster(sendData)

            if (!success) {
                return this.setState({
                    error: message,
                    loading: false,
                })
            }

            return this.setState({
                loading: false,
            })
        } catch (e) {
            return this.setState({
                loading: false,
                error: e.message,
            })
        }
    }

    render() {
        const {cluster, error, loading} = this.state

        return (
            <div className="ClusterDetailsContainer">
                <h3 className="border-bottom pb-2 mb-3">Cluster detail</h3>
                {
                    !!cluster &&
                    <ClusterFormContainer cluster={cluster} loading={loading}
                                          onUpdateCluster={this._handleUpdateCluster}
                                          onSaveCluster={this._handleSaveCluster}/>
                }

                {
                    !!error &&
                    <div className="text-danger mt-3">{error}</div>
                }
            </div>
        )
    }
}

ClusterDetailsContainer.propTypes = {
    clusterId: PropTypes.string.isRequired,
}

export default ClusterDetailsContainer
