import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

class Email extends Component {
    render() {
        const {item} = this.props
        const {_id, email, name} = item

        return (
            <td className='Email'>
                {(!name && email) ? <Link to={`/a/accounts/${_id}`}>{email}</Link> : <span>{email || '-'} </span>}
            </td>
        )
    }
}

Email.propTypes = {
    item: PropTypes.object.isRequired,
}

export default Email
