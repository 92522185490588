import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ClusterSettingsHead from "./ClusterSettingsHead"
import ClusterSettingsBody from "./ClusterSettingsBody"

class ClusterSettingsTable extends Component {
    render() {
        return (
            <table className="ClusterSettingsTable table">
                <ClusterSettingsHead/>

                <ClusterSettingsBody {...this.props}/>
            </table>
        )
    }
}

ClusterSettingsTable.propTypes = {
    settings: PropTypes.array.isRequired,
    onFetchClusterSettings: PropTypes.func.isRequired,
    clusterId: PropTypes.string.isRequired,
}

export default ClusterSettingsTable
