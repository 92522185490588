import createAPIServices from './createApiServices'

const baseUrl =
    process.env.NODE_ENV === 'production' ? '/api/statistic/charts' : 'https://manager-qa.merch8.com/api/statistic/charts'

const api = createAPIServices({baseUrl})

export const getStatAllStores = ({query = {}}) =>
    api.makeAuthRequest({
        url: '',
        method: 'POST',
        data: query,
    })

export const getStatStore = ({query = {}, slug = ''}) =>
    api.makeAuthRequest({
        url: `/${slug}`,
        method: 'POST',
        data: query,
    })
