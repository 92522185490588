import React from 'react'
import PropTypes from 'prop-types'
import {SortableContainer} from 'react-sortable-hoc'
import SortableElementVariantImage from "./SortableElementVariantImage"
import UploadVariantImages from "./UploadVariantImages"

const SortableContainerVariantImage = SortableContainer((props) => {
    const {images, variantId, onFetchListVariantImage, onUpdateError} = props

    return (
        <div className="VariantImageContainer d-flex flex-wrap ListImages overflow-auto">
            {
                images.map((image, index) => {
                    const {side, thumb} = image

                    return <SortableElementVariantImage key={side} variantId={variantId} index={index} value={thumb}
                                                        side={side} onFetchListVariantImage={onFetchListVariantImage}
                                                        onUpdateError={onUpdateError}/>
                })
            }

            <UploadVariantImages onFetchListVariantImage={onFetchListVariantImage} variantId={variantId}
                                 onUpdateError={onUpdateError}/>
        </div>
    )
})

SortableContainerVariantImage.propTypes = {
    images: PropTypes.array.isRequired,
    variantId: PropTypes.string.isRequired,
    onFetchListVariantImage: PropTypes.func.isRequired,
    onUpdateError: PropTypes.func,
}

export default SortableContainerVariantImage
