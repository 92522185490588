import React, {Component} from 'react'
import PropTypes from 'prop-types'

class WithdrawalGateway extends Component {
    render() {
        const {gateway} = this.props

        return (
            <td className="TransactionOutNote">
                {gateway}
            </td>
        )
    }
}

WithdrawalGateway.propTypes = {
    gateway: PropTypes.string.isRequired,
}

export default WithdrawalGateway
