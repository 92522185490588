import React from 'react'
import DateCustomFilter from '../../../../shared/DateCustomFilter'
import {Input} from 'reactstrap'

const ImportLogsFilters = (props) => {
    const {handleDateChange, handleInputChange, query} = props

    return (
        <div className='ImportLogsFilters d-flex mb-3'>
            <div className='SearchBar mr-3'>
                <Input
                    onChange={handleInputChange}
                    type='text'
                    className='SearchInput'
                    placeholder='Seach file log'
                />
                <i className='fas fa-search SearchIcon' />
            </div>
            <div className="date-filter-group ">
                <DateCustomFilter
                    query={query}
                    placeholderDateFilter="Created at"
                    onChange={handleDateChange}
                />
            </div>
        </div>
    )
}

export default ImportLogsFilters
