import React, {Component} from 'react'
import PropTypes from 'prop-types'

class Index extends Component {
    render() {
        const {tableIndex} = this.props
        return (
            <td className='Index'>
                <span>{tableIndex}</span>
            </td>
        )
    }
}

Index.propTypes = {
    tableIndex: PropTypes.number.isRequired,
}

export default Index
