import React, {Component} from 'react'
import PropTypes from 'prop-types'

class IntervalFilter extends Component {
    state = {
        value: '1d',
    }

    _handleChange = event => {
        this.setState(
            {
                value: event.target.value,
            },
            () => this.props.handleIntervalChange(this.state.value)
        )
    }

    render() {
        const {value} = this.state
        return (
            <div className='IntervalFilter'>
                <span> Interval </span>
                <select value={value} onChange={this._handleChange}>
                    <option value='1M'>1 month</option>
                    <option value='1w'>1 week</option>
                    <option value='1d'>1 day</option>
                    <option value='1h'>1 hour</option>
                    <option value='10m'>10 minutes</option>
                </select>
            </div>
        )
    }
}

IntervalFilter.propTypes = {
    handleIntervalChange: PropTypes.func.isRequired,
}

export default IntervalFilter
