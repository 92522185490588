import React from 'react'
import PropTypes from 'prop-types'
import {SortableElement} from "react-sortable-hoc"
import VariantImage from "./VariantImage"

const SortableElementVariantImage = SortableElement((props) => {
    return (
        <div className="SortableElementVariantImage">
            <VariantImage {...props}/>
        </div>
    )
})

SortableElementVariantImage.propTypes = {
    value: PropTypes.string.isRequired,
    side: PropTypes.string.isRequired,
    variantId: PropTypes.string.isRequired,
    onFetchListVariantImage: PropTypes.func.isRequired,
    onUpdateError: PropTypes.func
}

export default SortableElementVariantImage
