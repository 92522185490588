import React, {useEffect, useState} from 'react'
import DesignRow from './DesignRow'
import VariantDimensionModal from '../modal/VariantDimensionModal'
import {randomID} from '../../../../../helpers/common/randomID'
import {toaster} from '../../../../shared/PFToast'
import {getVariantMockup} from '../../../../../services/api/ProductLinesServices'

const DesignTable = (props) => {
    const {variants, attributes} = props

    const [variantDimension, setVariantDimension] = useState({
        id: '',
        isOpen: false,
        dimensions: [],
        variantTitle: '',
    })
    const [mockups, setMockups] = useState([])

    const _fetchVariantMockup = async () => {
        try {
            const payload = {
                page: 1,
                limit: 1000,
                preview_v2: true,
            }
            const {success, data, message} = await getVariantMockup(payload)
            if (!success) throw new Error(message)
            setMockups(data.mockups)
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    useEffect(() => {
        if (variants.length) {
            _fetchVariantMockup()
        }
    }, [variants])

    if (!variants.length) return null

    const _toggleVariantDimension = (variantId, dimensions, variantTitle) => {
        setVariantDimension((prev) => ({...prev, isOpen: !prev.isOpen, id: variantId, dimensions, variantTitle}))
    }

    return (
        <div className="DesignTable table-responsive">
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        {!!attributes.length &&
                            attributes.map((attribute) => {
                                const _id = randomID()
                                return (
                                    <th className="text-capitalize" key={_id}>
                                        {attribute.name}
                                    </th>
                                )
                            })}
                        <th>Template Mockup</th>
                        <th className="text-center">Dimension</th>
                    </tr>
                </thead>
                <DesignRow mockups={mockups} variants={variants} {...props} toggle={_toggleVariantDimension} />
            </table>
            {variantDimension.isOpen && (
                <VariantDimensionModal
                    isOpen={variantDimension.isOpen}
                    variantId={variantDimension.id}
                    dimensions={variantDimension.dimensions}
                    variantTitle={variantDimension.variantTitle}
                    toggle={_toggleVariantDimension}
                />
            )}
        </div>
    )
}

export default DesignTable

