import React, {Component} from 'react'
import WithdrawalActions from './data/WithdrawalActions'
import WithdrawalAmount from './data/WithdrawalAmount'
import WithdrawalDate from './data/WithdrawalDate'
import WithdrawalNote from './data/WithdrawalNote'
import WithdrawalOwner from './data/WithdrawalOwner'
import WithdrawalStatus from './data/WithdrawalStatus'
import PropTypes from 'prop-types'
import WithdrawalTransactionId from './data/WithdrawalTransactionId'
import WithdrawalGateway from './data/WithdrawalGateway'

class StoreWithdrawalsRow extends Component {

    render() {
        const {withrawal, store} = this.props
        const {created, note, amount, status, _id, account, transaction_id, gateway} = withrawal

        return (
            <tr className="TransactionOutRow">
                <WithdrawalDate created={created}/>
                <WithdrawalOwner account={account}/>
                <WithdrawalAmount amount={amount}/>
                <WithdrawalStatus status={status}/>
                <WithdrawalGateway gateway={gateway}/>
                <WithdrawalTransactionId transactionId={transaction_id}/>
                <WithdrawalNote note={note}/>
                <WithdrawalActions withrawalId={_id} status={status} store={store}
                                   onFetchWithdrawals={this.props.onFetchWithdrawals}/>
            </tr>
        )
    }

}

StoreWithdrawalsRow.propTypes = {
    store: PropTypes.object.isRequired,
}

export default StoreWithdrawalsRow
