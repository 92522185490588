import React, {Component} from 'react'
import PropTypes from 'prop-types'

class HostSelect extends Component {
    _handleChange = event => {
        const {name, value} = event.target
        this.setState(
            {
                value: event.target.value,
            },
            () => this.props.handleSelectChange(name, value)
        )
    }

    render() {
        return (
            <div className='HostSelect Select'>
                <span>Host </span>
                <select name='hostSelect' onChange={this._handleChange}>
                    <option value='gitlab'>Gitlab</option>
                </select>
            </div>
        )
    }
}

HostSelect.propTypes = {
    handleSelectChange: PropTypes.func.isRequired,
}

export default HostSelect
