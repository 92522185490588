import React, {Component} from 'react'
import PropTypes from 'prop-types'
// import Link from 'react-router-dom/Link'

class ActionColumn extends Component {
    render() {
        const {item} = this.props
        const {onClickRemove, onClickEdit} = item

        return (
            <td className='ActionColumn'>
                {/*<Link to={`/a/categories/${_id}`}>*/}
                {/*    <button className="ViewButton btn btn-primary mr-3">*/}
                {/*        <span className="ti-eye mr-1"/>*/}
                {/*        View*/}
                {/*    </button>*/}
                {/*</Link>*/}
                <button className="ViewButton btn btn-primary mr-3" onClick={onClickEdit}>
                    <span className="ti-eye mr-1"/>
                    Edit
                </button>
                <button className="ViewButton btn btn-danger" onClick={onClickRemove}>
                    <span className="ti-trash mr-1"/>
                    Delete
                </button>
            </td>

        )
    }
}

ActionColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ActionColumn
