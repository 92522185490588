import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _groups from '../../helpers/getGroup'

class GroupSelect extends Component {
    _handleChange = event => {
        const {name, value} = event.target
        this.setState(
            {
                value: event.target.value,
            },
            () => this.props.handleSelectChange(name, value)
        )
    }

    render() {
        return (
            <div className='GroupSelect Select'>
                <span>Group </span>
                <select name='group' onChange={this._handleChange}>
                    <option value=''>All groups</option>
                    {_groups.map(item => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
            </div>
        )
    }
}

GroupSelect.propTypes = {
    handleSelectChange: PropTypes.func.isRequired,
}

export default GroupSelect
