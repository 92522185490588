import React, {Component} from 'react'
import {addStoreTags, updateStoreTags} from "../../../services/api/StoreServices"
import StoreTagsCreate from "./store-tags/StoreTagsCreate"
import StoreTagsList from "./store-tags/StoreTagsList"
import PropTypes from 'prop-types'


class StoreTags extends Component {
    state = {
        error: "",
    }

    _handleCreateStoreTags = async (tag) => {
        try {
            const {storeDetails} = this.props
            const {_id:storeId} = storeDetails
            const tagArray = tag.split(',')
            const {success, message, data} = await addStoreTags(storeId, {
                titles: tagArray,
            })

            if (!success) {
                this.setState({
                    error: message,
                })

                return false
            }

            const {tags} = data

            this.setState({
                tags: tags,
                error: "",
            })

            return true
        } catch (e) {
            this.setState({
                error: e.message,
            })

            return false
        }
    }

    _handleRemoveStoreTag = async (selectedTag) => {
        try {
            const {storeDetails} = this.props
            const {_id:storeId} = storeDetails
            const {tags} = storeDetails

            const newTags = tags.filter(tag => tag !== selectedTag)

            const {data, success, message} = await updateStoreTags(storeId, {
                tags: newTags,
            })

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            const {tags: updatedTags} = data

            return this.setState({
                tags: updatedTags,
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {error} = this.state
        const tags = this.props.storeDetails.tags
        
        return (
            <div className="StoreTags">
                <div className="list-group Tags">
                    <div className="list-group-item"><strong>Tags</strong></div>

                    <div className="list-group-item">
                        <div className="mb-2">Enter tags , seperated by commas</div>

                        <StoreTagsCreate _handleCreateStoreTags={this._handleCreateStoreTags}/>

                        <div className="mt-2">
                            <StoreTagsList tags={tags} _handleRemoveStoreTag={this._handleRemoveStoreTag}/>
                        </div>

                        {
                            !!error && <div className="text-danger mt-3">{error}</div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

StoreTags.propTypes = {
    storeDetails: PropTypes.object.isRequired,
    tags: PropTypes.array,
}

export default StoreTags
