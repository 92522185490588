import React, {Component} from 'react'
import ManageCountriesContainer from "./manage-countries/ManageCountriesContainer"
import SelectedCountriesContainer from "./selected-countries/SelectedCountriesContainer"
import {getShippingZoneCountries} from "../../../../../services/api/ShippingSettingsServices"
import PropTypes from 'prop-types'

class ZoneCountriesContainer extends Component {
    state = {
        selectedCountries: [],
        zoneCountries: {},
        error: "",
    }

    componentDidMount() {
        this._fetchListCountries()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedCountries.length !== this.props.selectedCountries.length) {
            this.setState({
                selectedCountries: this.props.restOfWorld ? "rest" : this.props.selectedCountries,
            })
        }

        if (prevState.selectedCountries.length !== this.state.selectedCountries.length) {
            const {selectedCountries} = this.state
            const {onUpdateZoneData} = this.props

            if ("rest" === selectedCountries) {
                onUpdateZoneData("rest_of_world", true)
            } else {
                const countriesCode = selectedCountries.map(country => country.code)

                onUpdateZoneData("countries", countriesCode)
                onUpdateZoneData("rest_of_world", false)
            }
        }
    }

    _fetchListCountries = async () => {
        try {
            const {data, success, message} = await getShippingZoneCountries(this.props.profilesId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                zoneCountries: data,
            })
        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            return this.setState({
                error: message ? message : e.message,
            })
        }
    }

    _handleUpdateSelectedCountries = (arrCountries) => {
        this.setState({
            selectedCountries: arrCountries,
        })
    }

    _removeSelectedCountry = (countryCode) => {
        this.setState(({selectedCountries}) => {
            const filteredCountries = selectedCountries.filter(country => country.code !== countryCode)

            return {
                selectedCountries: filteredCountries,
            }
        })
    }

    _handleSelectRestOfWorld = () => {

        this.setState({
            selectedCountries: "rest",
        })
    }

    render() {
        const {selectedCountries, zoneCountries, error} = this.state
        const {countries = []} = zoneCountries

        const {restOfWorld, prevSelectedCountries} = this.props

        return (
            <div className="ZoneCountriesContainer">
                <div className="SectionInner">
                    <div className="d-flex justify-content-between pb-3 border-bottom align-items-center">
                        <div>
                            <h6 className="mb-0">Countries and regions</h6>
                            <div className="text-muted fs-14">Add or remove countries and regions in this zone.</div>
                        </div>

                        <ManageCountriesContainer selectedCountries={selectedCountries} zoneCountries={zoneCountries}
                                                  restOfWorld={restOfWorld}
                                                  onUpdateSelectedCountries={this._handleUpdateSelectedCountries}
                                                  onSelectRestOfWorld={this._handleSelectRestOfWorld}/>
                    </div>
                    {
                        error && <p className='text-danger fs-14 mt-2'>{error}</p>
                    }

                    <SelectedCountriesContainer selectedCountries={selectedCountries} countries={countries}
                                                prevSelectedCountries={prevSelectedCountries}
                                                restOfWorld={restOfWorld}
                                                onRemoveSelectedCountry={this._removeSelectedCountry}/>
                </div>
            </div>
        )
    }
}

ZoneCountriesContainer.propTypes = {
    profilesId: PropTypes.string.isRequired
}

export default ZoneCountriesContainer
