import React from 'react';
import { FormGroup } from 'react-bootstrap';
import { Form, Input, Label } from 'reactstrap';
import JsonInputCpn from './JsonInputCpn';
import ModalCommon from './ModalCommon';

const placeholderHeaders = "{'Content-Type':'application/json','merchize-webhook-key': '{secretKey}'}"
const placeholderPayload = "{'event_type':'ORDER.XXXXX.XXXX','resource': {}}"

const initState = {
    name: '',
    description: '',
    headers: '',
    payload: ''
}

const TextErr = ({ children }) => {
    return (
        <span style={{ color: 'red', fontSize: '14px' }}>
            {children}
        </span>
    )
}

function ModalAddEvent(props) {
    const { onClose, isShow, onSubmit } = props

    const [formData, setFormData] = React.useState(initState)

    const [formDataErr, setFormDataErr] = React.useState(initState)

    const checkInputIsValid = (data) => Object.keys(data).filter(key => {
        if (data[key] === '') {
            setFormDataErr((prev) => ({ ...prev, [key]: `${key} must not be empty` }))
            return key
        }
        else {
            setFormDataErr((prev) => ({ ...prev, [key]: '' }))
            return null
        }
    });

    const checkInputIsValidJson = data =>
        Object.keys(data).filter(key => {
            try {
                if(typeof data[key] === 'object') return false
                JSON.parse(data[key])
                setFormDataErr((prev) => ({ ...prev, [key]: '' }))
                return false
            } catch (error) {
                setFormDataErr((prev) => ({ ...prev, [key]: `${key} must be json` }))
                return key
            }
        })


    const checkValueWhenBlur = ({ target }) => {
        if (checkInputIsValid({ [target.id]: target.value }).length <= 0) {
            if (target.id === 'headers' || target.id === 'payload') {
                if (checkInputIsValidJson({
                    [target.id]: target.value,
                }).length === 0) {
                    const fValue = JSON.stringify(JSON.parse(formData[target.id]), null, '\t')
                    setFormData(prev => ({ ...prev, [target.id]: fValue }))
                }
            }
        }
    }
    const handleChangeValue = ({ target }) => {
        setFormDataErr({
            ...formDataErr,
            [target.id]: '',
        })
        setFormData({
            ...formData,
            [target.id]: target.value
        })
    }

    const handleClickOk = () => {
        Object.keys(formData).map(key =>
            formData[key] = typeof formData[key] === 'string'
                ? formData[key]?.trim()
                : formData[key]
        );

        if (checkInputIsValid(formData).length === 0) {
            if (checkInputIsValidJson({
                headers: formData.headers,
                payload: formData.payload,
            }).length === 0) {
                onSubmit(formData)
            }
        }
    }

    const handleClose = () => {
        onClose()
        setFormData(initState)
        setFormDataErr(initState)
    }

    // new
    const handleChangeJsonValueStoreSeller = (e) => {
        const { error, json } = e
        if (!error) {
            setFormData(prev => ({ ...prev, payload: json }))
        }
    }

    const handleChangeJsonValueStoreManager = (e) => {
        const { error, json } = e
        if (!error) {
            setFormData(prev => ({ ...prev, headers: json }))
        }
    }

    return (
        <ModalCommon
            isOpen={isShow}
            title='Create webhook event'
            onClose={handleClose}
            onSubmit={handleClickOk}
        >
            <Form>
                <FormGroup>
                    <Label style={{ color: '#74606b', fontWeight: 'bold' }} for="name">
                        Name
                    </Label>
                    <Input
                        spellCheck="false" required
                        id="name"
                        placeholder="Enter event name"
                        value={formData.name}
                        onChange={handleChangeValue}
                        onBlur={checkValueWhenBlur}
                    />
                    {
                        formDataErr.name &&
                        <TextErr>
                            {formDataErr.name}
                        </TextErr>
                    }
                </FormGroup>
                <FormGroup style={{ marginTop: '10px' }}>
                    <Label style={{ color: '#74606b', fontWeight: 'bold' }} for="description">
                        Description
                    </Label>
                    <Input
                        spellCheck="false" required
                        id="description"
                        placeholder="Enter description name"
                        value={formData.description}
                        onChange={handleChangeValue}
                        onBlur={checkValueWhenBlur}
                    />
                    {
                        formDataErr.description &&
                        <TextErr>
                            {formDataErr.description}
                        </TextErr>
                    }
                </FormGroup>

                <FormGroup style={{ marginTop: '10px' }}>
                    <Label style={{ color: '#74606b', fontWeight: 'bold' }} for="headers">
                        Headers
                        <span style={{ opacity: '0.3', fontWeight: '400', marginLeft: '10px' }}>
                            {placeholderHeaders}
                        </span>
                    </Label>
                    <JsonInputCpn
                        style={{
                            body: { fontSize: '16px', border: '1px solid #ced4da', width: 'inherit', minHeight: '200px' },
                            container: { width: 'inherit', minHeight: '200px' },
                            outerBox: { width: 'inherit', minHeight: '200px' },
                            errorMessage: { color: 'deeppink', fontSize: '16px' }
                        }}
                        onChange={handleChangeJsonValueStoreManager}
                    />
                    {
                        formDataErr.headers &&
                        <TextErr>
                            {formDataErr.headers}
                        </TextErr>
                    }
                </FormGroup>

                <FormGroup style={{ marginTop: '10px' }}>
                    <Label style={{ color: '#74606b', fontWeight: 'bold' }} for="payload">
                        Payload
                        <span style={{ opacity: '0.3', fontWeight: '400', marginLeft: '10px' }}>{placeholderPayload}</span>
                    </Label>
                    <JsonInputCpn
                        style={{
                            body: { fontSize: '16px', border: '1px solid #ced4da', width: 'inherit', minHeight: '200px' },
                            container: { width: 'inherit', minHeight: '200px' },
                            outerBox: { width: 'inherit', minHeight: '200px' },
                            errorMessage: { color: 'deeppink', fontSize: '16px' },
                        }}
                        onChange={handleChangeJsonValueStoreSeller}
                    />
                    {
                        formDataErr.payload &&
                        <TextErr>
                            {formDataErr.payload}
                        </TextErr>
                    }
                </FormGroup>
            </Form>
        </ModalCommon>
    );
}

export default ModalAddEvent;
