import React, {useCallback, useEffect, useState} from 'react'
import DatabaseJobsFilters from './DatabaseJobsFilters'
import {DEFAULT_QUERY} from '../constants'
import DatabaseJobsTable from './DatabaseJobsTable'
import PFPagePagination from '../../shared/PFPagePagination'
import {trimObjectValues} from '../../../helpers/common/cleanObject'
import {searchGlobalDeposits} from '../../../services/api/BillingServices'
import {toaster} from '../../shared/PFToast'
import {getListDatabaseJobs} from '../../../services/api/DatabaseJobsServices'

const DatabaseJobsPage = () => {
    const [items, setItems] = useState([])
    const [query, setQuery] = useState(DEFAULT_QUERY)
    const [pages, setPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)

    const onChangeFilter = (newParams) => {
        setQuery({
            ...query,
            ...newParams
        })
    }

    const _handleChangePage = (page) => {
        setQuery(prev => ({
            ...prev,
            page
        }))
    }

    const _fetchDatabaseJobs = useCallback(async (refetch = true) => {
        try {
            setLoading(true)
            let payload = {}
            if (refetch) {
                payload = DEFAULT_QUERY
            } else {
                payload = trimObjectValues(query)
            }
            const {success, data, message} = await getListDatabaseJobs(payload)
            if (!success) {
                throw new Error(message)
            }
            setPages(data.pages)
            setTotal(data.total)
            setItems(data.databaseJobs)
        } catch(error) {
            toaster({
                type: 'error',
                message: error.message
            })
        } finally {
            setLoading(false)
        }
    }, [query])

    useEffect(() => {
        const debounceFetch = setTimeout(() => {
            _fetchDatabaseJobs(false)
        }, 500)

        return () => {
            clearTimeout(debounceFetch);
        };
    }, [_fetchDatabaseJobs])

    return (
        <div className="DatabaseJobsPage">
            <h2 className="mb-3">Database Jobs</h2>
            <div className="SectionInner">
                <DatabaseJobsFilters query={query} total={total} setQuery={setQuery}/>
                <DatabaseJobsTable items={items} loading={loading} fetchDeposits={_fetchDatabaseJobs}/>
                <PFPagePagination page={query.page} pages={pages} onChangePage={_handleChangePage} />
            </div>
        </div>
    )
}

export default DatabaseJobsPage
