import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class PFAlert extends Component {
    state = {
        isOpen: true,
    }

    _close = () => {
        const {onClose} = this.props

        this.setState({isOpen: false}, () => {
            if (typeof onClose === 'function') this.props.onClose()
        })
    }

    _getIcon = () => {
        const {type, icon} = this.props

        if (!type) return null

        let iconDOM = null

        if (icon) {
            iconDOM = icon
        } else {
            switch (type) {
                case 'success':
                    iconDOM = <i className="fas fa-check-circle"/>
                    break
                case 'info':
                    iconDOM = <i className="fas fa-info-circle"/>
                    break
                case 'warning':
                    iconDOM = <i className="fas fa-exclamation-circle"/>
                    break
                case 'error':
                    iconDOM = <i className="fas fa-times-circle"/>
                    break
                default:

            }
        }

        return (
            <div className='IconType'>
                {iconDOM}
            </div>
        )
    }

    _getDismiss = () => {
        const {closable, closeText} = this.props

        if (!closable) return null

        const defaultClose = <span className='IconDismiss'><i className="fas fa-times"/></span>

        return (
            <div className='Dismiss bg-secondary text-white rounded-circle' onClick={this._close}>
                {closeText ? closeText : defaultClose}
            </div>
        )
    }

    render() {
        if (!this.state.isOpen) return null

        const {title, message, banner, type, className} = this.props
        const icon = this._getIcon()
        const dismiss = this._getDismiss()

        return (
            <div className={classNames('PFAlert', {'banner': banner, [type]: type, [className]: className})}>
                <div className='d-flex align-items-center'>
                    {icon}
                    <div className='mr-3'>
                        {title && <h5 className='Title'>{title}</h5>}
                        {message && <div className='Message'>{message}</div>}
                    </div>
                    {dismiss}
                </div>
            </div>
        )
    }
}

PFAlert.defaultProps = {
    title: '',
    message: '',
    type: 'info',
    closable: true,
}

PFAlert.propTypes = {
    title: PropTypes.string,
    message: PropTypes.node,
    type: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
    banner: PropTypes.bool,
    icon: PropTypes.node,
    closable: PropTypes.bool,
    closeText: PropTypes.node,
    className: PropTypes.string,
    onClose: PropTypes.func,
}

export default PFAlert
