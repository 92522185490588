import React from 'react'
import PropTypes from 'prop-types'
import {changeStorefrontStatus} from '../../../services/api/DeployService'

class ButtonChangeStoreFrontStatus extends React.Component {
    state = {
        isLoading: false
    }

    componentDidMount() {
        const {store: {disabled_storefront}} = this.props
        if (typeof disabled_storefront === 'undefined') {
            this._handleChangeStatusStorefront(false)
        }
    }

    _handleChangeStatusStorefront = async (status) => {
        this.setState({isLoading: true})

        try {
            const {store, changeStoreDetail} = this.props
            const {_id: storeId} = store

            const {success, message} = await changeStorefrontStatus(storeId, status)
            if (!success) {
                throw new Error(message)
            } else {
                alert(`The change has been successfully added to the queue.`)
            }

            changeStoreDetail({
                ...store,
                disabled_storefront: status,
            })

        } catch (e) {
            alert(e.message)
        }

        this.setState({isLoading: false})
    }

    _handleActiveStore = () => {
        return this._handleChangeStatusStorefront(false)
    }

    _handleDisabledStore = () => {
        return this._handleChangeStatusStorefront(true)
    }

    _renderButton = () => {
        const {store: {disabled_storefront}} = this.props
        const {isLoading} = this.state

        if (disabled_storefront === true || typeof disabled_storefront === 'undefined') {
            return (
                <button
                    className='btn btn-success btn-sm'
                    onClick={this._handleActiveStore}
                    disabled={isLoading}>
                    {isLoading ? 'Enabling' : 'Enable'}
                </button>
            )
        }

        return (
            <button
                className='btn btn-danger btn-sm'
                onClick={this._handleDisabledStore}
                disabled={isLoading}>
                {isLoading ? 'Disabling' : 'Disable'}
            </button>
        )
    }

    render() {
        return (
            <div className='ButtonChangeStatus d-inline-block'>
                {this._renderButton()}
            </div>
        )
    }
}

ButtonChangeStoreFrontStatus.propTypes = {
    store: PropTypes.object.isRequired,
    changeStoreDetail: PropTypes.func.isRequired
}

export default ButtonChangeStoreFrontStatus
