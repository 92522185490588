import React, {Component} from 'react'

// import PropTypes from 'prop-types'

class FromData extends Component {
    state = {
        from: this.props.from,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.from !== this.props.from) {
            this.setState({
                from: this.props.from,
            })
        }
    }

    _handleChangeInput = e => {
        const {value} = e.target
        const {rowIndex, onUpdatePriceData} = this.props

        this.setState({
            from: value,
        })

        onUpdatePriceData(rowIndex, "from", Number(value))
    }

    _handlePressInput = e => {
        if (e.key === "Enter") {
            e.preventDefault()
        }
    }

    render() {
        const {rowIndex} = this.props
        const {from} = this.state

        return (
            <td className="FromData">
                {
                    rowIndex === 0 ?
                        <span className="">$0</span>
                        :
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">$</div>
                            </div>
                            <input type="number" step="any" className="form-control" min={0} value={from}
                                   onChange={this._handleChangeInput} onKeyPress={this._handlePressInput} required/>
                        </div>
                }
            </td>
        )
    }
}

// FromData.defaultProps = {}

// FromData.propTypes = {}

export default FromData
