import React, {Component} from 'react'
import PropTypes from 'prop-types'

class TagsColumn extends Component {
    _renderTags = () => {
        const {tags, _id: storeId} = this.props.item

        if (!Array.isArray(tags) || tags.length <= 0) {
            return null
        }

        return tags.map((tag, index) => {
            return (
                <span key={`${tag}_${index}_${storeId}`} className="badge badge-secondary mr-2 mb-2">{tag}</span>
            )
        })
    }

    render() {
        const renderTags = this._renderTags()

        return (
            <td className="Tags">
                {renderTags}
            </td>
        )
    }
}

TagsColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default TagsColumn