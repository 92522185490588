import React, {useCallback, useState} from 'react'
import ReturnPage from '../../shared/ReturnPage'
import {Button, InputGroup} from 'reactstrap'
import {useEffect} from 'react'
import {createCashbackRule, getCashbackRuleDetail, updateCashbackRule} from '../../../services/api/BillingServices'
import {
    CASHBACK_RULE_QUANTITY_TYPE,
    CASHBACK_RULE_TYPE,
    CASHBACK_RULE_VALUE_TYPE,
    CASHBACK_RULE_VALUE_TYPE_BASE_COST,
    DEFAULT_CASHBACK_RULE_FORM,
    DEFAULT_ERRORS,
} from '../constants'
import {toaster} from '../../shared/PFToast'
import StoreSelect from './StoreSelect'
import ProductTypeInput from './ProductTypeInput'
import {createDefaultOptionStore, createOptionsFromArray} from '../helpers'
import NumberFormat from 'react-number-format'
import {DateRangePicker} from 'react-dates'
import getHistory from '../../../store/getHistory'
import moment from 'moment'
import classNames from 'classnames'
import {trimObjectValues} from '../../../helpers/common/cleanObject'
import parseSearchQuery from '../../../helpers/routing/parseSearchQuery'
import {Link} from 'react-router-dom'

const requiredMsg = 'This field is required'

const CashbackRuleForm = (props) => {
    const [focusedInput, setFocusedInput] = useState(null)
    const [form, setForm] = useState(DEFAULT_CASHBACK_RULE_FORM)
    const [errors, setErrors] = useState(DEFAULT_ERRORS)
    const [loading, setLoading] = useState(false)

    const _getCashbackRuleId = () => {
        const {match} = props
        const {cashbackRuleId} = Object.assign({}, match.params)
        return cashbackRuleId
    }

    const _getQueryAction = () => {
        const query = parseSearchQuery()
        const {action} = query
        return action
    }

    const id = _getCashbackRuleId()
    const action = _getQueryAction()
    const isDuplicate = action && action === 'duplicate'

    const fetchCashbackRule = useCallback(async () => {
        try {
            if (!id) return
            const {success, data, message} = await getCashbackRuleDetail(id)
            if (!success) {
                throw new Error(message)
            }

            const {
                store,
                name,
                product_types,
                cashback_type,
                value_type,
                value,
                fulfillment_quantity_type,
                fulfillment_quantity_value,
                apply_time_from,
                apply_time_to,
                note,
                status,
            } = data
            setForm({
                store: createDefaultOptionStore(store),
                apply_time_from: moment(apply_time_from),
                apply_time_to: moment(apply_time_to),
                product_types: isDuplicate ? [] : createOptionsFromArray(product_types),
                name: isDuplicate ? '' : name,
                cashback_type,
                value: isDuplicate ? 0 : value,
                value_type: isDuplicate ? DEFAULT_CASHBACK_RULE_FORM.value_type : value_type,
                fulfillment_quantity_type: isDuplicate
                    ? DEFAULT_CASHBACK_RULE_FORM.fulfillment_quantity_type
                    : fulfillment_quantity_type,
                fulfillment_quantity_value: isDuplicate ? 0 : fulfillment_quantity_value,
                note,
                status,
            })
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }, [id, isDuplicate])
    useEffect(() => {
        fetchCashbackRule()
    }, [fetchCashbackRule])

    const onSubmitForm = async () => {
        try {
            const errorsObj = onValidateForm(trimObjectValues(form), errors, onValidateField)
            setErrors(errorsObj)
            if (Object.keys(errorsObj).length !== 0) {
                return
            }
            setLoading(true)
            let response = null
            const payload = {
                ...form,
                store: form.store.value,
                apply_time_from: moment(form.apply_time_from).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                apply_time_to: moment(form.apply_time_to).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                product_types: [...new Set(form.product_types.map((type) => type.value))],
            }
            if (id && !isDuplicate) {
                response = await updateCashbackRule(id, payload)
            } else {
                if (payload._id) delete payload._id
                response = await createCashbackRule(payload)
            }
            const {success, message} = response
            if (!success) {
                throw new Error(message)
            }
            toaster({
                type: 'success',
                message: `${id ? (isDuplicate ? 'Duplicate' : 'Update') : 'Create'} cashback rule successfully.`,
            })
            const history = getHistory()
            history.push(`/a/cashback-rule`)
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } finally {
            setLoading(false)
        }
    }

    const onChangeForm = (name, value) => {
        if(name === 'cashback_type' && value !== 'base_cost' && ['private_fee', 'discount'].includes(form?.value_type)) {
            let errorMessage = ''
            errorMessage = onValidateField(name, value, form)
            setForm((prev) => {
                return {
                    ...prev,
                    [name]: value,
                    value_type: 'fixed'
                }
            })
            setErrors((prev) => {
                return {
                    ...prev,
                    [name]: errorMessage,
                }
            })
        } else if(name === 'value_type' && (value === 'private_fee' || value === 'discount')) {
            setForm((prev) => {
                return {
                    ...prev,
                    [name]: value,
                    value: 0
                }
            })
            setErrors((prev) => {
                return {
                    ...prev,
                    value: '',
                }
            })
        } else {
            let errorMessage = ''
            errorMessage = onValidateField(name, value, form)
            setForm((prev) => {
                return {
                    ...prev,
                    [name]: value,
                }
            })
            setErrors((prev) => {
                return {
                    ...prev,
                    [name]: errorMessage,
                }
            })
        }
    }

    const onFocusChange = (focusedInput) => setFocusedInput(focusedInput)

    const isOutsideRangeDate = (date) => {
        const tomorrow = moment().endOf('month')
        const startDate = moment('2018-11-05', 'YYYY-MM-DD')

        if (startDate.isAfter(date)) return true

        return !date.isBefore(tomorrow)
    }

    const handleTimeRangeChange = ({startDate, endDate}) => {
        if (startDate && endDate) {
            setErrors((prev) => {
                return {
                    ...prev,
                    apply_time_from: '',
                    apply_time_to: '',
                }
            })
        }
        return setForm((prev) => ({
            ...prev,
            apply_time_from: startDate,
            apply_time_to: endDate,
        }))
    }

    const dateRangeMsg = errors.apply_time_from || errors.apply_time_to

    return (
        <div className="CashbackRuleFormWrapper">
            <ReturnPage url="/a/cashback-rule" title="Cashback Rule" />
            {form && (
                <div className="CashbackRuleFormContainer">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                        <h1 className="PageTitle">Cashback Rule detail</h1>
                        <div className="BtnAction">
                            {!isDuplicate && id && (
                                <Link
                                    className="btn btn-info mr-3"
                                    to={`/a/form-cashback-rule/${id}?action=duplicate`}
                                    target="_blank"
                                >
                                    Duplicate
                                </Link>
                            )}
                            {!isDuplicate && id && form.status === 'inactive' ? null : (
                                <Button color="primary" disabled={loading} onClick={onSubmitForm}>
                                    {loading && <i className="fas fa-circle-notch fa-spin mr-2" />}
                                    {id ? (isDuplicate ? 'Duplicate' : 'Update') : 'Create'}
                                </Button>
                            )}
                        </div>
                    </div>
                    <div className="SectionInner">
                        <div className="form-group">
                            <label className="font-weight-bold">
                                Rule name<span className="text-danger">*</span>
                            </label>
                            <input
                                type="text"
                                name="name"
                                value={form.name}
                                maxLength={500}
                                placeholder="Enter name of cashback rule"
                                className={classNames('form-control', {'invalid-input': errors.name})}
                                onChange={(e) => onChangeForm(e.target.name, e.target.value)}
                            />
                            {errors.name && <span className="error-message">{errors.name}</span>}
                        </div>
                        <StoreSelect value={form.store} errors={errors} onChangeStore={onChangeForm} />
                        <ProductTypeInput
                            product_types={form.product_types}
                            errors={errors}
                            onChangeProductType={onChangeForm}
                        />
                        <div className="form-group">
                            <label className="font-weight-bold">
                                Cashback type<span className="text-danger">*</span>
                            </label>
                            <select
                                name="cashback_type"
                                className="form-control"
                                value={form.cashback_type}
                                onChange={(e) => onChangeForm(e.target.name, e.target.value)}
                            >
                                {CASHBACK_RULE_TYPE.map((type, index) => (
                                    <option key={`cashback_type_${index}`} value={type.value}>
                                        {type.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className={`form-group InputStore InputGroup ${dateRangeMsg ? 'invalid-date' : ''}`}>
                            <label className="font-weight-bold mb-2">
                                Apply time<span className="text-danger">*</span>
                            </label>
                            <DateRangePicker
                                small
                                readOnly
                                noBorder
                                showClearDates
                                enableOutsideDays
                                reopenPickerOnClearDates
                                minimumNights={0}
                                numberOfMonths={2}
                                isOutsideRange={isOutsideRangeDate}
                                startDate={form.apply_time_from}
                                startDateId="apply_time_from"
                                endDate={form.apply_time_to}
                                endDateId="apply_time_to"
                                onDatesChange={handleTimeRangeChange}
                                focusedInput={focusedInput}
                                onFocusChange={onFocusChange}
                                displayFormat={'DD/MM/YYYY'}
                            />
                            {dateRangeMsg && <span className="error-message">{dateRangeMsg}</span>}
                        </div>

                        <div className="form-group">
                            <label className="font-weight-bold">
                                Value<span className="text-danger">*</span>
                            </label>
                            <InputGroup>
                                <select
                                    name="value_type"
                                    className={classNames('form-control', {'invalid-input': errors.value})}
                                    value={form.value_type}
                                    style={{maxWidth: '250px'}}
                                    onChange={(e) => onChangeForm(e.target.name, e.target.value)}
                                >
                                    {form?.cashback_type === 'base_cost' ? CASHBACK_RULE_VALUE_TYPE_BASE_COST.map((type, index) => (
                                        <option key={`value_type_${index}`} value={type.value}>
                                            {type.label}
                                        </option>
                                    )) : CASHBACK_RULE_VALUE_TYPE.map((type, index) => (
                                        <option key={`value_type_${index}`} value={type.value}>
                                            {type.label}
                                        </option>
                                    ))}
                                </select>
                                <NumberFormat
                                    name="value"
                                    disabled={form?.value_type === 'private_fee' || form?.value_type === 'discount'}
                                    maxLength={9}
                                    value={form.value || 0}
                                    decimalScale={2}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    className={classNames('form-control', {'invalid-input': errors.value})}
                                    onValueChange={({floatValue}) => onChangeForm('value', floatValue)}
                                />
                            </InputGroup>
                            {errors.value && <span className="error-message">{errors.value}</span>}
                        </div>
                        <div className="form-group">
                            <label className="font-weight-bold">Quantity</label>
                            <InputGroup>
                                <select
                                    className="form-control"
                                    name="fulfillment_quantity_type"
                                    value={form.fulfillment_quantity_type}
                                    style={{maxWidth: '250px'}}
                                    onChange={(e) => onChangeForm(e.target.name, e.target.value)}
                                >
                                    {CASHBACK_RULE_QUANTITY_TYPE.map((type, index) => (
                                        <option key={`fulfillment_quantity_type_${index}`} value={type.value}>
                                            {type.label}
                                        </option>
                                    ))}
                                </select>
                                <NumberFormat
                                    maxLength={9}
                                    name="fulfillment_quantity_value"
                                    value={form.fulfillment_quantity_value || 0}
                                    decimalScale={0}
                                    thousandSeparator={true}
                                    allowNegative={false}
                                    className="form-control"
                                    onValueChange={({floatValue}) =>
                                        onChangeForm('fulfillment_quantity_value', floatValue)
                                    }
                                />
                            </InputGroup>
                        </div>

                        <div className="form-group">
                            <label className="font-weight-bold">Note</label>
                            <textarea
                                name="note"
                                rows={3}
                                className="form-control"
                                value={form.note}
                                onChange={(e) => onChangeForm(e.target.name, e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label className="font-weight-bold">
                                Status<span className="text-danger">*</span>
                            </label>
                            <div className="d-flex">
                                <div className="custom-control custom-radio mr-3">
                                    <input
                                        type="radio"
                                        disabled={!id}
                                        id="customRadio1"
                                        name="status"
                                        className="custom-control-input"
                                        checked={form.status === 'active'}
                                        onChange={() => setForm((prev) => ({...prev, status: 'active'}))}
                                    />
                                    <label className="custom-control-label" htmlFor="customRadio1">
                                        Active
                                    </label>
                                </div>
                                <div className="custom-control custom-radio">
                                    <input
                                        type="radio"
                                        disabled={!id}
                                        id="customRadio2"
                                        name="status"
                                        className="custom-control-input"
                                        checked={form.status === 'inactive'}
                                        onChange={() => setForm((prev) => ({...prev, status: 'inactive'}))}
                                    />
                                    <label className="custom-control-label" htmlFor="customRadio2">
                                        Inactive
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const onValidateField = (name, value, form = {}) => {
    let errorMsg = null
    switch (name) {
        case 'value':
            console.log(form)
            if (!value && (form?.value_type === 'fixed' || form?.value_type === 'percentage')) errorMsg = requiredMsg
            if (value === 0 && (form?.value_type === 'fixed' || form?.value_type === 'percentage')) errorMsg = 'Value must be greater than 0'
            break
        case 'name':
        case 'store':
        case 'apply_time_from':
        case 'apply_time_to':
            if (!value) errorMsg = requiredMsg
            break
        case 'product_types':
            if (value.length === 0) errorMsg = requiredMsg
            break
        default:
            break
    }
    return errorMsg
}

const onValidateForm = (form, formError, validateFunc) => {
    const errorObj = {}
    Object.keys(formError).map((x) => {
        const message = validateFunc(x, form[x], form)
        if (message) errorObj[x] = message
    })
    return errorObj
}

export default CashbackRuleForm

