import React, {Component} from 'react'
import PropTypes from 'prop-types'
import CreatedDeposit from './column/CreatedDeposit'
import StatusDeposit from './column/StatusDeposit'
import AmountDeposit from './column/AmountDeposit'
import ActionDeposit from './column/ActionDeposit'
import TransactionDeposit from './column/TransactionDeposit'
import RequestDeposit from './column/RequestDeposit'
import NoteDeposit from './column/NoteDeposit'
import classNames from 'classnames'
import GatewayDeposit from './column/GatewayDeposit'
import RejectionNoteDeposit from './column/RejectionNoteDeposit'

class DepositsTableRow extends Component {
    render() {
        const {entity, fetchDeposits} = this.props
        return (
            <>
                {entity.map((deposit, index) => (
                    <tr
                        key={index}
                        className={classNames({
                            DepositDuplicate:
                                deposit.status === 'pending' && deposit.approvedTimes && deposit.approvedTimes > 0,
                        })}
                    >
                        <TransactionDeposit deposit={deposit} />
                        <GatewayDeposit gateway={deposit.gateway} />
                        <AmountDeposit amount={deposit.amount} amountVn={deposit.amount_vn} />
                        <RequestDeposit deposit={deposit} />
                        <NoteDeposit note={deposit.note} />
                        <RejectionNoteDeposit rejected_note={deposit.rejected_note} />
                        <CreatedDeposit created={deposit.created} />
                        <StatusDeposit deposit={deposit} />
                        <ActionDeposit fetchDeposits={fetchDeposits} deposit={deposit} />
                    </tr>
                ))}
            </>
        )
    }
}

DepositsTableRow.propTypes = {
    entity: PropTypes.array.isRequired,
    fetchDeposits: PropTypes.func.isRequired,
}

export default DepositsTableRow
