import React, {useState, useEffect} from 'react'

import DocTitle from '../../shared/DocTitle'
import Filter from './components/Filter'
import Table from './components/Table'
import {getOrderRefund} from '../../../services/api/OrderRefundServices'

const ListOrderRefund = () => {
    const [isLoading, setLoading] = useState(false)
    const [filter, setFilter] = useState({
        page: 1,
        status: '',
        order_number: '',
        store_slug: ''
    })

    const [totalPage, setTotalPage] = useState(0)
    const [orderRefund, setOrderRefund] = useState([])

    useEffect(() => {
        const callApiGetOrderRefund = async () => {
            try {
                setLoading(true)
                const {success, data = {}} = await getOrderRefund({
                    ...filter
                })
                if (success) {
                    setOrderRefund(data.orderRefund || [])
                    setTotalPage(data.pages || 0)
                }
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.log(error)
            }

        }
        callApiGetOrderRefund()
    }, [filter])


    const _onChangeFilter = (name, value) => {
        switch (name) {
            case 'status':
                setFilter({...filter, [name]: value, page: 1})

                break
            case 'order_number':
                setFilter({...filter, [name]: value, page: 1})
                break
            case 'store_slug':
                setFilter({...filter, [name]: value, page: 1})
                break
            case 'page':
                setFilter({...filter, [name]: value})
                break
            default:
                break
        }
    }

    return (
        <div className="OrderRefund">
            <DocTitle title="Order refund" />
            <h2>Order refund</h2>
            <div className='OrderRefundContent'>
                <Filter
                    filter={filter}
                    onChangeFilter={_onChangeFilter}
                    orderRefund={orderRefund}
                />
                <Table
                    pages={totalPage}
                    page={filter.page}
                    isLoading={isLoading}
                    orderRefund={orderRefund}
                    onChangeFilter={_onChangeFilter}
                />
            </div>

        </div>
    )
}

export default ListOrderRefund
