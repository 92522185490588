import React, {useState} from 'react'
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import ExportActionModal from './ExportActionModal'
import {getRuleExport} from '../../../../../services/api/MappingRulesServices'
import {toaster} from '../../../../shared/PFToast'

const dropDownOptions = [
    {
        action: 'variant-sku',
        label: 'Export rule by SKU variants'
    },
    {
        action: 'product-sku',
        label: 'Export rule by SKU products'
    },
]

const ExportActionButton = (props) => {
    const {getListRules, params} = props
    const [isDropdownOpen, setDropdownOpen] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const [actionType, setActionType] = useState({})

    const toggleDropdown = () => setDropdownOpen(!isDropdownOpen)

    const handleToggleModal = (action) => {
        setActionType(action)
        setModalOpen(!isModalOpen)
    }

    const _downloadBlob = (fileName, fileBlob, type) => {
        const blob = new Blob([fileBlob], {type})
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(blob)
        downloadLink.download = fileName
        downloadLink.click()
    }

    const _exportRules = async (action) => {
        try {
            const req = Object.entries({...params, type: action}).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
            if (req.searchValue) delete req.searchValue
            if (req.searchFieldSelected) delete req.searchFieldSelected
            const {data, success, message} = await getRuleExport(req)
            if (!success) {
                return toaster({
                    type: 'error',
                    message: message
                })
            }
            const {filename, content} = data
            _downloadBlob(filename, content, {type: 'text/csv'})
        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
            })
        }
    }

    return (
        <div className="ImportActionButton ml-3">
            <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown} direction="down">
                <DropdownToggle color="default" caret>Export rule</DropdownToggle>
                <DropdownMenu end dark>
                    {dropDownOptions.map((item) => {
                        const {action, label} = item
                        return (
                            <DropdownItem key={action} onClick={() => _exportRules(action)}>
                                {label}
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </Dropdown>
            <ExportActionModal
                isOpen={isModalOpen}
                header={actionType?.label || ''}
                toggle={handleToggleModal}
                action={actionType?.action || ''}
                getListRules={getListRules}
            />
        </div>
    )
}

export default ExportActionButton
