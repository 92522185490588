import React, {Component} from 'react'
import PropTypes from 'prop-types'

class WithdrawalOwner extends Component {
    render() {
        return (
            <td className="TransactionOutOwner">
                {this.props.account}
            </td>
        )
    }
}

WithdrawalOwner.propTypes = {
    account: PropTypes.string.isRequired,
}

export default WithdrawalOwner
