import React, {Component} from 'react'
import makeAnimated from 'react-select/animated'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import {getAccount} from '../../../services/api/StoreAccountServices'

class OwnerSelect extends Component {
    state = {
        options: [],
        email: '',
        err: '',
        params: {email: ''},
    }

    componentDidMount() {
        this._getAccounts()
    }

    _getAccounts = async () =>
        new Promise(async resolve => {
            const {
                params: {email},
            } = this.state
            this.setState({
                err: '',
            })
            try {
                const {success, data, message} = await getAccount({email})
                if (!success) {
                    return this.setState({
                        err: message,
                    })
                }

                const accounts = Array.isArray(data) ? data : []
                this.setState(
                    () => ({
                        options: accounts.map(account => ({
                            id: account._id,
                            value: account.email,
                            label: account.email,
                        })),
                        err: '',
                    }),
                    () => resolve(this.state.options)
                )
            } catch (e) {
                this.setState({
                    err: e.message || e,
                })
            }
        })

    _handleChange = option => {
        this.setState(
            {
                email: option.value,
            },
            () => this.props.saveOwnerId(option.id)
        )
    }

    _handleInputChange = input =>
        this.setState(
            {
                params: {email: input},
            },
            () => {
                this._getAccounts()
            }
        )

    _getNoOptionsMessage = () => {
        const {
            params: {email},
        } = this.state
        if (!email) return 'Enter email to search..'
        return 'No matching emails found!'
    }

    _getLoadingMessage = () => 'Loading emails...'

    render() {
        const {options, email, err} = this.state

        return (
            <div className='CollectionsSelector col'>
                <div className='form-group'>
                    <AsyncSelect
                        components={makeAnimated()}
                        onChange={this._handleChange}
                        onInputChange={this._handleInputChange}
                        noOptionsMessage={this._getNoOptionsMessage}
                        loadingMessage={this._getLoadingMessage}
                        options={options}
                        value={{label: email}}
                        loadOptions={this._getAccounts}
                        placeholder='Search email'
                    />
                    {!!err && <p className='text-danger'>{err}</p>}
                </div>
            </div>
        )
    }
}

OwnerSelect.propTypes = {
    saveOwnerId: PropTypes.func.isRequired,
}

export default OwnerSelect
