import {curry} from 'lodash'

const replace = curry((regex, replacement, s) => {
    return s.replace(regex, replacement)
})

export const removeUnderscore = replace(/_/g)

export const insertSpace = removeUnderscore(' ')

const upperFirstLetter = (s) => s.charAt(0).toUpperCase() + s.slice(1)

export const convertSneakCaseToTitle = (s) => {
    return insertSpace(upperFirstLetter(s))
}

const replaceByTikTok = replace(/tiktok|tikTok|Tiktok|TikTok/g, 'TikTok')

export const standardTikTokInString = (s) => {
    return replaceByTikTok(s)
}
