import React, { PureComponent } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import MenuItem from './MenuItem'
import { NAVBAR_MENU, NAVBAR_MENU_MP } from '../constants'
import getEnv from '../../../helpers/common/getEnv'
import { isAdmin, isSuperAdmin } from '../../../services/AuthServices'

class NavbarLeft extends PureComponent {

    render() {
        const isSupportMpMode = getEnv('isEnableMpMode')
        const navbar = isSupportMpMode ? NAVBAR_MENU_MP : NAVBAR_MENU

        const roleSupperAdmin = isSuperAdmin()
        const roleAdmin = isAdmin()
        const { isCollapsed } = this.props
        return (
            <div className="NavbarLeft">
                <div className="NavbarLeftInner justify-content-between">
                    <nav className="PrimaryMenu">
                        {
                            navbar.map(nav => (
                                <MenuItem menuItem={nav} key={nav.href} isCollapsed={isCollapsed} />
                            ))
                        }
                    </nav>
                    <nav className="PrimaryMenu">
                        <NavLink to="/a/affiliates" className="nav-link" activeClassName="active" key="/a/affiliates">
                            <span className="IconHolder">
                                <i className="fas fa-boxes" />
                            </span>
                            <span className="Title">Affiliates</span>
                        </NavLink>
                    </nav>
                    {
                        (roleSupperAdmin || roleAdmin) &&

                        <nav className="PrimaryMenu">
                            <NavLink to="/a/settings" className="nav-link" activeClassName="active" key="/a/settings">
                                <span className="IconHolder">
                                    <i className="ti-settings" />
                                </span>
                                <span className="Title">Settings</span>
                            </NavLink>
                        </nav>
                    }

                </div>
            </div>
        )
    }
}

NavbarLeft.propTypes = {
    history: PropTypes.object.isRequired
}

export default withRouter(NavbarLeft)
