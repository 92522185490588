import React from 'react'
import PropTypes from 'prop-types'
import {backupDropDatabase} from '../../../services/api/StoreServices'
import {confirmModal} from '../../shared/PFConfirmModalV2'
import {toaster} from '../../shared/PFToast'

class ButtonBackupDropDatabase extends React.Component {
    state = {
        isLoading: false,
        disabledDrop: false,
    }

    _handleClickDropDatabase = (e) => {
        e.preventDefault()
        confirmModal({
            confirmText: 'Backup & Drop',
            cancelText: 'Cancel',
            confirmColor: 'danger',
            title: 'Confirm Backup & Drop',
            message: (
                <span>
                    Are you sure you want to Backup & Drop database
                </span>
            ),
            onOk: this._handleDropDatabase,
        })
    }

    _handleDropDatabase = async () => {
        try {
            this.setState({ isLoading: true })
            const { store } = this.props
            const { _id: storeId } = store
            const payload = {
                storeIds: [storeId]
            }
            const { data, success, message } = await backupDropDatabase(payload)
            this.setState({ isLoading: false })
            if (!success) {
                throw new Error(message)
            } else {
                const {error = [], success = []} = data
                const isError = error.find(item => item.storeId === storeId)
                if(isError !== undefined) {
                    toaster({
                        type: 'error',
                        message: isError.reason,
                        duration: 3000,
                    })
                } else {
                    this.setState({ disabledDrop: true })
                    toaster({
                        type: 'success',
                        message: 'Backup & Drop database successfully.',
                        duration: 3000,
                    })
                }
            }
        } catch (error) {
            this.setState({ isLoading: false })
            alert(error.message)
        }
    }

    render() {
        const { is_backup_and_drop_db } = this.props
        const { isLoading, disabledDrop } = this.state

        return (
            <div className='ButtonRestoreDatabase d-inline-block'>
                <button
                    className='btn btn-danger btn-sm'
                    onClick={this._handleClickDropDatabase}
                    disabled={isLoading || disabledDrop || is_backup_and_drop_db}
                >
                    {isLoading && <i className='fas fa-circle-notch fa-spin mr-2'></i>}
                    Backup & Drop
                </button>
            </div>
        )
    }
}

ButtonBackupDropDatabase.propTypes = {
    isRestore: PropTypes.bool.isRequired,
    changeStoreDetail: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
}

export default ButtonBackupDropDatabase
