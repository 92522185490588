import React, { useEffect, useState } from 'react'
import { filterStoreTransferLog } from '../../../../services/api/StoreServices'
import { toaster } from '../../../shared/PFToast'
import StoreTransferLogFilter from './StoreTransferLogFilter'
import StoreTransferLogTable from './StoreTransferLogTable'

const StoreTransferLogContainer = (props) => {
    const { storeId } = props
    const [loading, setLoading] = useState(false)
    const [pages, setPages] = useState(0)
    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        originalStoreId: '',
        destinationStoreId: '',
        job: '',
        product: '',
        startDate: '',
        endDate: '',
        status: 'pending',
        type: '',
    })
    const [listStore, setListStore] = useState([])

    useEffect(() => {
        fetchStoreTransfer(params)
    }, [])

    const onChangeFilter = (newParams) => {
        setParams({
            ...params,
            ...newParams
        })
        fetchStoreTransfer(newParams)
    }

    const onPageChange = (page) => {
        const newParams = {
            ...params,
            page
        }
        setParams(newParams)
        fetchStoreTransfer(newParams)
    }

    const fetchStoreTransfer = async (requestParams) => {
        setLoading(true)
        const { data, success, message } = await filterStoreTransferLog(storeId, requestParams)
        if (success) {
            setListStore(data.logsHistories)
            setPages(data.pages)
        } else {
            toaster({
                type: 'danger',
                message
            })
        }
        setLoading(false)
    }
    return (
        <div className='StoreTransferJobContainer'>
            <StoreTransferLogFilter params={params} onChange={onChangeFilter} />
            <StoreTransferLogTable
                listStore={listStore}
                loading={loading}
                params={params}
                page={params.page}
                pages={pages}
                onChangeFilter={onChangeFilter}
                onPageChange={onPageChange} />
        </div>
    )
}

export default StoreTransferLogContainer