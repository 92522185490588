import React, {Component} from 'react'
import {DateRangePicker} from "react-dates"
import moment, {isMoment} from "moment"

class BalanceReportInputDate extends Component {

    state = {
        focusedInput: null,
        from: moment().startOf('month'),
        to: moment(),
    }

    _isOutsideRangeDate = date => {
        const tomorrow = moment().add(1, 'day')
        return !date.isBefore(tomorrow) || date.isSame(tomorrow, 'day')
    }

    _onFocusChange = focusedInput => {
        this.setState({
            focusedInput,
        })
    }

    // _formatDate = date => (isMoment(date) ? moment(date).format('DD/MM/YYYY') : '')
    _formatDate = date => (isMoment(date) ? moment(date).startOf('day').utc().toISOString() : '')

    _handleTimeRangeChange = ({startDate, endDate}) => {

        const formatDateFrom = this._formatDate(startDate)
        const formatDateTo = this._formatDate(endDate)

        this.setState({
            from: startDate,
            to: endDate
        })
        this.props.onChange('formDate', {from: formatDateFrom, to: formatDateTo})
    }

    render() {
        const {from, to, focusedInput} = this.state

        return (
            <div className='InputDate InputGroup mr-4'>
                <label className='mr-2'>Date range: </label>
                <DateRangePicker
                    small
                    showClearDates={true}
                    enableOutsideDays
                    reopenPickerOnClearDates
                    minimumNights={0}
                    numberOfMonths={1}
                    isOutsideRange={this._isOutsideRangeDate}
                    startDate={from}
                    startDateId='BalanceReportInputDate_from'
                    endDate={to}
                    endDateId='BalanceReportInputDate_to'
                    onDatesChange={this._handleTimeRangeChange}
                    focusedInput={focusedInput}
                    onFocusChange={this._onFocusChange}
                    displayFormat={'DD/MM/YYYY'}
                />
            </div>
        )
    }
}

export default BalanceReportInputDate
