import React, {Component} from 'react'
import TransactionOutCancellation from '../../actions/TransactionOutCancellation'

class TransactionOutActions extends Component {

    render() {
        return (
            <td className='TransactionOutActions text-right'>
                <TransactionOutCancellation {...this.props}/>
            </td>
        )
    }
}

export default TransactionOutActions
