import React, {useEffect, useState} from 'react'
import CreatableSelect from 'react-select/creatable'
import {createOption, createOptionsFromArray} from '../helpers'

const ProductTypeInput = (props) => {
    const {
        title,
        name = 'product_types',
        isRequired = true,
        allowParse = true,
        product_types = [],
        errors = {},
        onChangeProductType,
        placeholder = 'Enter product type and press enter',
    } = props

    const [value, setValue] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [allowParseProductTypes, setAllowParseProductTypes] = useState(allowParse)

    const handleKeyDown = (event) => {
        if (!inputValue) return
        let newValue = []
        // detect tab or enter
        if (event.keyCode === 9 || event.keyCode === 13) {
            newValue = [...value, ...handleParseProductTypes(inputValue)]
            setInputValue('')
            setValue(newValue)
            onChangeProductType(name, newValue)
            event.preventDefault()
        }
    }

    const handleChange = (selectedOption) => {
        const newValue = selectedOption && selectedOption.length !== 0 ? selectedOption : []
        setValue(newValue)
        onChangeProductType(name, newValue)
    }

    const handleParseProductTypes = (value) => {
        let columnArray = []
        if (allowParseProductTypes) {
            columnArray = value.split(/(\s+)/).filter((i) => i.trim().length !== 0)
        } else {
            columnArray = value.split(',').filter((i) => i.trim().length !== 0)
        }
        // Parse the column into an array
        const productTypes = columnArray.map((col) => col.trim())
        const optionProductTypes = createOptionsFromArray(productTypes)
        return optionProductTypes
    }

    useEffect(() => {
        product_types.length !== 0 && setValue(product_types)
    }, [product_types])

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: errors.product_types ? '#dd3d4c' : provided.borderColor,
            boxShadow: errors.product_types ? 'unset' : provided.boxShadow,
            '&:hover': {
                borderColor: errors.product_types ? '#dd3d4c' : provided.borderColor,
                boxShadow: errors.product_types ? 'unset' : provided.boxShadow,
            },
        }),
    }

    return (
        <div className="form-group">
            <label className="font-weight-bold">
                {title || 'Product type'}
                {isRequired && <span className="text-danger">*</span>}
            </label>
            {allowParse && (
                <div className="custom-control custom-checkbox mb-2">
                    <input
                        type="checkbox"
                        id="allowParse"
                        name="status"
                        className="custom-control-input"
                        checked={allowParseProductTypes}
                        onChange={() => setAllowParseProductTypes((prev) => !prev)}
                    />
                    <label className="custom-control-label" htmlFor="allowParse">
                        Allow copy multiple (data not contain space)
                    </label>
                </div>
            )}
            <CreatableSelect
                isMulti
                isClearable
                value={value}
                menuIsOpen={false}
                inputValue={inputValue}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                styles={customStyles}
                components={{DropdownIndicator: null}}
                onInputChange={(newValue) => setInputValue(newValue)}
                placeholder={placeholder}
            />
            {errors.product_types && <span className="error-message">{errors.product_types}</span>}
        </div>
    )
}

export default ProductTypeInput

