import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {changeStoreMode} from "../../../services/api/StoreServices"

class StoreMode extends Component {
    state = {
        loading: false,
        mode: ''
    }

    _handleClickChange = async e => {
        e.preventDefault()
        if (this.state.loading) return false
        const r = window.confirm('Are you sure to change store mode?')
        if (!r) return false
        this.setState({
            loading: true,
        })
        const {getStoreDetail} = this.props
        const {mode} = this.state
        const {_id: storeId} = this.props.store
        try {
            const {success, message} = await changeStoreMode(storeId, mode || 'normal')

            if (!success) {
                throw new Error(message)
            }

            this.setState({
                loading: false
            }, () => {
                window.alert('Successfully!')
                getStoreDetail()
            })

        } catch (e) {
            this.setState({
                loading: false,
            }, () => {
                window.alert(e.message)
            })
        }
    }

    _handleChangeMode = e => {
        const {value} = e.target
        this.setState({
            mode: value
        })
    }

    render() {
        const {mode, loading} = this.state
        const vMode = mode || this.props.store.mode
        return (
            <div className={`StoreMode card ${this.props.store.is_backup_and_drop_db ? 'disable' : ''}`}>
                <div className="card-header">
                    Mode
                </div>

                <div className="card-body">
                    <div className="form-group">
                        <select value={vMode} onChange={this._handleChangeMode} className="form-control">
                            <option disabled value="">Select store mode</option>
                            <option value="normal">Normal</option>
                            <option value="merchize-payment">merchize-payment</option>
                            <option value="marketplace">marketplace</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <button onClick={this._handleClickChange} disabled={loading}
                                className="btn btn-secondary">
                            Change
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

StoreMode.propTypes = {
    store: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default StoreMode
