import { useState, useEffect } from 'react'
import { searchGlobalSettings } from '../services/api/StoreServices'
import * as _ from 'lodash'
import { isAdmin } from '../services/AuthServices'

const ENABLE_MP_MODE_SETTING_KEY = 'enable_mp_mode'

const defaultState = {
    settings: {},
    isSupportMpMode: false
}

const _isSupportMpMode = settings => {
    const item = _.find(settings, s => s.key === ENABLE_MP_MODE_SETTING_KEY)
    return (item && (item.value === 'true' || item.value === '1' || item.value === 'yes')) || false
}

export default () => {
    const [state, changeState] = useState(_.defaultsDeep({}, defaultState))

    useEffect(() => {
        async function fetchData() {
            try {
                const resGlobalSetting = await searchGlobalSettings(999, 1)

                if (resGlobalSetting && resGlobalSetting.success) {
                    const { data } = resGlobalSetting
                    if (data && data.settings && data.settings.length) {
                        changeState({
                            settings: data.settings,
                            isSupportMpMode: _isSupportMpMode(data.settings)
                        })
                    }
                }
            } catch(error) {
                console.error(error)
            }
        }
        isAdmin() && fetchData()
    }, [])

    return {
        state
    }
}
