import React from 'react'

const StoreTransferJobFilter = (props) => {
    let { params, onChange } = props

    const onChangeSelect = (e) => {
        const { name, value } = e.target
        params = {
            ...params,
            [name]: value
        }
        onChange(params)
    }

    return (
        <div className='row ml-0'>
            <div className='StatusFilterSelect Select'>
                <span>Status</span>

                <select name='status' onChange={onChangeSelect} value={params.status}>
                    <option value=''>All</option>
                    <option value='pending'>Pending</option>
                    <option value='processing'>Processing</option>
                    <option value='cancelled'>Cancelled</option>
                    <option value='fail'>Fail</option>
                    <option value='done'>Done</option>
                </select>
            </div>
            <div className='StatusFilterSelect Select'>
                <span>Type</span>

                <select name='type' value={params.type} onChange={onChangeSelect}>
                    <option value=''>All</option>
                    <option value='copy'>Copy</option>
                    <option value='rollback'>Rollback</option>
                </select>
            </div>
        </div>
    )
}

export default StoreTransferJobFilter