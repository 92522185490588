import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {currency} from '../../../../statics/currency'

class PlanDetails extends Component {
    _onChangeInput = (key) => (e) => {
        const {value} = e.target
        this.props.onChangePlan(key, key === 'order' ? (value ? Number(value) : 1) : value)
    }

    _onChangeCheckbox = (key) => (e) => {
        const {checked} = e.target
        this.props.onChangePlan(key, checked)
    }

    render() {
        const {plan} = this.props
        const durationUnits = ['day', 'month']

        return (
            <div className="PlanDetails">
                <div className="row mb-2">
                    <div className="col-12 d-flex">
                        <div className="custom-control custom-checkbox mb-2">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="is_active"
                                checked={plan.is_active}
                                onChange={this._onChangeCheckbox('is_active')}
                            />
                            <label className="custom-control-label" htmlFor="is_active">
                                Active this product
                            </label>
                        </div>

                        <div className="custom-control custom-checkbox ml-5">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="is_public"
                                checked={plan.is_public}
                                onChange={this._onChangeCheckbox('is_public')}
                            />
                            <label className="custom-control-label" htmlFor="is_public">
                                Public plan
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <label className="mb-0" htmlFor="plan-title">Title</label>
                        <input
                            id="plan-title"
                            className="form-control"
                            value={plan.title || ''}
                            onChange={this._onChangeInput('title')}
                        />
                    </div>
                    <div className="col-6">
                        <label className="mb-0" htmlFor="plan-title">Subtitle</label>
                        <input
                            id="plan-subtitle"
                            className="form-control"
                            value={plan.subtitle || ''}
                            onChange={this._onChangeInput('subtitle')}
                        />
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-4">
                        <label className="mb-0" htmlFor="price-origin">Origin Price</label>
                        <input
                            value={plan.price_origin || ''}
                            id="price-origin"
                            className="form-control"
                            onChange={this._onChangeInput('price_origin')}
                        />
                    </div>
                    <div className="col-4">
                        <label className="mb-0" htmlFor="price">Price</label>
                        <input
                            value={plan.price || ''}
                            id="price"
                            className="form-control"
                            onChange={this._onChangeInput('price')}
                        />
                    </div>
                    <div className="col-4">
                        <label className="mb-0" htmlFor="plan-currency">Currency</label>
                        <select
                            value={plan.currency || ''}
                            id="plan-currency"
                            className="custom-select"
                            onChange={this._onChangeInput('currency')}
                        >
                            <option value={''}>Select a currency</option>
                            {currency.map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.title}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <label className="mb-0" htmlFor="duration-amount">Duration amount</label>
                        <input
                            value={plan.duration_amount || ''}
                            id="duration-amount"
                            className="form-control"
                            onChange={this._onChangeInput('duration_amount')}
                        />
                    </div>
                    <div className="col-6">
                        <label className="mb-0" htmlFor="duration-unit">Duration unit</label>
                        <select
                            value={plan.duration_unit || ''}
                            id="duration-unit"
                            className="custom-select"
                            onChange={this._onChangeInput('duration_unit')}
                        >
                            <option value={null}>Select a unit</option>
                            {durationUnits.map((unit) => (
                                <option value={unit} key={unit}>
                                    {unit}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-6">
                        <label className="mb-0" htmlFor="transaction-rate">Transaction rate</label>
                        <input
                            value={plan.transaction_rate}
                            id="transaction-rate"
                            className="form-control"
                            onChange={this._onChangeInput('transaction_rate')}
                        />
                    </div>
                    <div className="col-6">
                        <label className="mb-0" htmlFor="max-products">Max product entity</label>
                        <input
                            value={plan.max_products || 0}
                            id="max-products"
                            className="form-control"
                            onChange={this._onChangeInput('max_products')}
                        />
                    </div>
                </div>
                {plan.is_public && (
                    <div className="row mb-2">
                        <div className="col-6">
                            <label className="mb-0" htmlFor="more-info-url">More info url</label>
                            <input
                                value={plan.more_info_url}
                                id="more-info-url"
                                className="form-control"
                                onChange={this._onChangeInput('more_info_url')}
                            />
                        </div>
                        <div className="col-3">
                            <label className="mb-0" htmlFor="plan-order">Display order</label>
                            <input
                                min={1}
                                id="plan-order"
                                type="number"
                                maxLength={2}
                                className="form-control"
                                value={plan.order}
                                disabled={!plan.is_public}
                                onChange={this._onChangeInput('order')}
                            />
                        </div>
                        <div className="col-3">
                            <label className="mb-0" htmlFor="available-storefront">Available storefront</label>
                            <select
                                value={plan.available_storefront || false}
                                id="available-storefront"
                                className="custom-select"
                                onChange={this._onChangeInput('available_storefront')}
                            >
                                <option value={false}>Disable</option>
                                <option value={true}>Enable</option>
                            </select>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}

PlanDetails.propTypes = {
    plan: PropTypes.object.isRequired,
    onChangePlan: PropTypes.func.isRequired,
}

export default PlanDetails

