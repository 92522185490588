import React, {Component} from 'react'
import {Link} from "react-router-dom"
import PropTypes from "prop-types"

class ReturnPage extends Component {
    render() {
        const {url, title, subTitle} = this.props

        return (
            <div className="ReturnPage mb-2">
                <Link to={url} className="BackButton">
                    <i className="fas fa-chevron-left"/>
                    <span className="ml-3">{title} {subTitle && <span className='text-muted'> - {subTitle}</span>}</span>
                </Link>
            </div>
        )
    }
}

ReturnPage.defaultProps = {
    subTitle: ''
}

ReturnPage.propTypes = {
    url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default ReturnPage
