import React, {Component} from 'react'
import DocTitleByStore from '../../../dashboard/shared/DocTitleByStore'
import ShippingProfilesNewPage from './ShippingProfilesNewPage'
import {Link} from 'react-router-dom'

class ShippingProfilesNewContainer extends Component {

    render() {

        return (
            <div className="SettingPage ProfilesEditContainer">
                <DocTitleByStore title="Shipping Profiles New"/>
                <div className="row">
                    <div className="col-md-8 col-12 m-auto ">
                        <div className="BackWrapper">
                            <Link className="nav-link BackButton" to="/a/settings-shipping">
                                <i className="fas fa-chevron-left"/>
                                <span>Shipping profiles</span>
                            </Link>
                        </div>
                        <div className="MenuTitle">
                            Create a new custom shipping profile
                        </div>
                        <ShippingProfilesNewPage/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShippingProfilesNewContainer
