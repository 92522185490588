import React, {Component} from 'react'
import PFFilterOptions from '../../shared/PFFilterOptions'
import ProductTableList from './ProductTableList'
import InputSearchStore from './filter/InputSearchStore'
import {searchProductReviewTicket} from '../../../services/api/SellerServices'
import PFPagePagination from '../../shared/PFPagePagination'

const STATUS_FILTER = [
    {name: 'All', value: ''},
    {name: 'Pending', value: 'pending'},
    {name: 'Approved', value: 'approved'},
    {name: 'Rejected', value: 'rejected'}
]

const STORE_MODE = [
    {name: 'All', value: ''},
    {name: 'Marketplace', value: 'marketplace'},
    {name: 'Merchize payment', value: 'merchize-payment'}
]

class ProductApprovalPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: '',
            products: [],
            loading: false,
            totalProduct: 81,
            storeID: '',
            search_product: '',
            total: 0,
            pages: 1,
            params: {
                page: 1,
                limit: 10,
                approve_status: 'pending',
                title: '',
                store_domain: '',
                store_slug: '',
                store_mode: ''
            }
        }

        this.timer = null
    }

    componentDidMount() {
        this._getProduct()
    }

    _getProduct = async () => {
        const {params} = this.state
        this.setState({
            loading: true
        })

        try {
            const {message, data, success} = await searchProductReviewTicket(params)

            // fetch store detail

            // [{ticket_id: '', backoffice_api_url: store.backoffice_api_url}]

            if (!success) throw new Error(message)

            const {page, pages, limit, total} = data

            this.setState({
                products: data.tickets,
                error: '',
                total: total || 0,
                pages: pages || 1,
                params: {
                    ...this.state.params,
                    page: page || 1,
                    limit: limit || 10
                },
                loading: false
            })

        } catch (e) {
            this.setState({
                error: e.message,
                loading: false
            })

        }
    }

    _handleChangeInput = (e) => {
        const value = e.target.value
        const name = e.target.name

        console.log('value: ', value)
        console.log('name: ', name)

        if (this.timer) clearTimeout(this.timer)

        this.timer = setTimeout(
            this.setState(({params}) => ({
                params: {
                    ...params,
                    page: 1,
                    [name]: value
                }
            }), () => this._getProduct()), 2000)
    }

    _handleChange = (propety, value) => {
        this.setState(({params}) => ({
            params: {
                ...params,
                page: 1,
                [propety]: value
            }
        }), () => this._getProduct())
    }

    _handlePageChange = page => {
        const {params} = this.state
        this.setState(
            {
                params: {...params, page}
            },
            () => this._getProduct()
        )
    }

    render() {
        const {products, loading, totalProduct, params, total, pages, error} = this.state
        const {title, page, approve_status, store_mode} = params

        return (
            <div className="ProductApprovalPage SectionInner">
                <div className='filter d-flex align-items-center flex-wrap mb-4'>
                    <div className='ProductApprovalFilter d-flex flex-wrap align-items-center mr-3'>
                        <label className='mr-2 m-0'>Approval status:</label>
                        <PFFilterOptions
                            defaultValue={approve_status}
                            onChange={this._handleChange}
                            field='approve_status'
                            options={STATUS_FILTER}
                        />
                    </div>

                    <InputSearchStore onChange={this._handleChange}/>

                    <div className="SearchProductApproval form-group d-flex flex-wrap align-items-center m-0  mr-3">
                        <label className='mr-2 mb-0'>Product</label>
                        <div className='SearchInputApproval border rounded form-control'>
                            <input
                                onChange={this._handleChangeInput}
                                type='text'
                                name='title'
                                id='SearchProductTitleApproval'
                                value={title}
                                className='SearchInput border-0'
                                placeholder='Search product title...'
                            />
                            <i className='fas fa-search SearchIcon'/>
                        </div>
                    </div>
                    <div className='ProductApprovalFilter d-flex flex-wrap align-items-center'>
                        <label className='mr-2 m-0'>Mode:</label>
                        <PFFilterOptions
                            defaultValue={store_mode}
                            onChange={this._handleChange}
                            field='store_mode'
                            options={STORE_MODE}
                        />
                    </div>


                    <div className="TotalEntity col-auto p-0 ml-auto mt-3 mt-md-0">
                        <span className="Entity font-weight-bold">{total} items</span>
                    </div>
                </div>
                {
                    error ?
                        <span className='text-danger'>{error}</span>
                        :
                        <ProductTableList
                            loading={loading}
                            totalProduct={totalProduct}
                            products={products}
                            onGetProducts={this._getProduct}
                        />
                }

                {!loading && <PFPagePagination page={page} pages={pages} onChangePage={this._handlePageChange}/>}
            </div>
        )
    }
}

export default ProductApprovalPage
