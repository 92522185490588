import React, {Fragment} from 'react'
import RenderTableRow from './RenderTableRow'

const TableBody = (props) => {
    const {items, onClickDelete} = props
    return (
        <tbody className="TableBody ProfilesTable__tbody">
        {items && items.length > 0 ?
            <Fragment>
                {items.map((item, index) => (
                    <RenderTableRow key={index} item={item} onClickDelete={onClickDelete}/>
                ))
                }
            </Fragment>
            :
            <tr>
                <td><i>No Template Found</i></td>
            </tr>
        }
        </tbody>
    )
}

export default TableBody
