import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getDnsRecord, createDnsRecord} from '../../../../services/api/DNSServices'

class DnsRecord extends Component {
    state = {
        record: {},
        err: '',
        loading: true,
        isCreatingRecord: false,
    }

    componentDidMount() {
        this._getDnsRecord()
    }

    componentDidUpdate(prevProps) {
        const {storeDetails} = this.props
        if (prevProps.storeDetails.status !== storeDetails.status) {
            this._getDnsRecord()
        }
    }

    _createDnsRecord = async () => {
        try {
            this.setState({
                isCreatingRecord: true,
            })
            const {storeId} = this.props
            const {success, data, message} = await createDnsRecord(storeId)
            if (!success) {
                alert(message)
                return this.setState({
                    isCreatingRecord: false,
                })
            }

            const record = Object.assign({}, data[0])
            this.setState({
                record,
                isCreatingRecord: false,
            })
        } catch (error) {
            return this.setState({
                err: error.message || error,
            })
        }
    }

    _getDnsRecord = async () => {
        try {
            const {storeId} = this.props
            const {success, data, message} = await getDnsRecord(storeId)
            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }
            const record = Object.assign({}, data[0])
            this.setState({
                record,
                loading: false,
            })
        } catch (error) {
            return this.setState({
                err: error.message || error,
                loading: false,
            })
        }
    }

    _toggleModal = () => {
        this.setState({
            showModal: false,
        })
    }

    render() {
        const {record, loading, isCreatingRecord} = this.state
        const {storeDetails} = this.props
        const {sub_domain} = storeDetails
        const {zone_id: host_subdomain, content: host_cluster, name} = record
        return (
            <div className={`DnsRecord ${this.props.storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                <div className='list-group'>
                    <div className='list-group-item'>
                        <h5>DNS Record</h5>
                    </div>
                    {!loading &&
                        (Object.keys(record).length ? (
                            <div className='RecordExist'>
                                <div className='list-group-item'>
                                    <div className='d-flex align-items-baseline'>
                                        <span>Name:</span>
                                        <span className='ml-3'>{name}</span>
                                    </div>
                                </div>
                                <div className='list-group-item'>
                                    <div className='d-flex align-items-baseline'>
                                        <span>Subdomain:</span>
                                        <span className='ml-3'>{`${sub_domain}.${host_subdomain}`}</span>
                                    </div>
                                </div>
                                <div className='list-group-item'>
                                    <div className='d-flex align-items-baseline'>
                                        <span>Cluster:</span>
                                        <span className='ml-3'>{host_cluster}</span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='RecordNotExist'>
                                <div className='list-group-item'>
                                    {isCreatingRecord ? (
                                        <span>Creating...</span>
                                    ) : (
                                        <div>
                                            <span>This store hasn't created DNS Record</span>
                                            <button
                                                className='btn btn-primary btn-sm ml-3'
                                                disabled={loading}
                                                onClick={this._createDnsRecord}>
                                                <i className='fas fa-sync-alt' />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        )
    }
}

DnsRecord.propTypes = {
    storeId: PropTypes.string.isRequired,
    storeDetails: PropTypes.object.isRequired,
}

export default DnsRecord
