import React, {Component} from 'react'
import PropTypes from 'prop-types'

class StoreModeProductLines extends Component {
    render() {
        const {item} = this.props
        const storeMode = Array.isArray(item.available_for_store_modes) ? item.available_for_store_modes : []
        return (
            <td>
                {storeMode.map(storeMode => {
                    return (
                        <span key={storeMode} className='badge badge-secondary badge-pill mr-1'>
                            {storeMode}
                    </span>
                    )
                })}
            </td>
        )
    }
}

StoreModeProductLines.propTypes = {
    item: PropTypes.object.isRequired,
}

export default StoreModeProductLines
