import React, {Component} from 'react'

// import PropTypes from 'prop-types'

class InputRateTitle extends Component {
    state = {
        title: this.props.title,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.title !== this.props.title) {
            this.setState({
                title: this.props.title,
            })
        }
    }

    _handleChangeInput = e => {
        const {value} = e.target

        this.setState({
            title: value,
        })

        this.props.onUpdateRatesData("title", value)
    }

    render() {
        const {title} = this.state

        return (
            <div className="InputRateTitle">
                <label htmlFor="createRateTitle">Title</label>
                <input type="text" name="title" className="form-control" id="createRateTitle"
                       onChange={this._handleChangeInput} value={title} required/>
            </div>
        )
    }
}

// InputRateTitle.defaultProps = {}

// InputRateTitle.propTypes = {}

export default InputRateTitle
