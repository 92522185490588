import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import TablePagination from './TablePagination'

class TableContainer extends Component {
    render() {
        const {page, totalPage, handlePageChange} = this.props
        return (
            <div className='TableContainer table-responsive '>
                <table className='table table-striped table-light'>
                    <TableHeader />
                    <TableBody {...this.props} />
                </table>
                <TablePagination page={page} pages={totalPage} handlePageChange={handlePageChange} />
            </div>
        )
    }
}

TableContainer.propTypes = {
    page: PropTypes.number.isRequired,
    totalPage: PropTypes.number.isRequired,
    handlePageChange: PropTypes.func.isRequired,
}

export default TableContainer
