import React from 'react'
import {Link} from 'react-router-dom'
import commonDateTime from '../../../../helpers/time/commonDateTime'

const RenderTableRow = (props) => {
    const {item, onClickDelete} = props
    const {_id, name, category_name, marketplace, is_active, created} = item

    return (
        <tr>
            <td>{name}</td>
            <td>{category_name}</td>
            <td>
                <span>{is_active ? 'Active' : 'Inactive'}</span>
            </td>
            <td>{commonDateTime(created)}</td>
            <td>{marketplace}</td>
            <td className="ActionColumn">
                <div className="d-flex">
                    <div className="cursor-pointer mr-4">
                        <Link to={`/a/amazon/${_id}`}><i className="far fa-edit text-dark"/></Link>
                    </div>
                    <div className="cursor-pointer" onClick={() => onClickDelete(_id)}>
                        <i className="far fa-trash-alt"/>
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default RenderTableRow
