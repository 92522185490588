export const STATUS_FILTER = [
    {
        value: '',
        name: 'All'
    },
    {
        value: 'pending',
        name: 'Pending'
    },
    {
        value: 'approved',
        name: 'AM Approved'
    },
    {
        value: 'paid',
        name: 'Paid'
    },
    {
        value: 'rejected',
        name: 'Rejected'
    },
]

export const STATUS_FILTER_FOR_ACCOUNTANT = [
    {
        value: '',
        name: 'All'
    },
    {
        value: 'approved',
        name: 'AM Approved'
    },
    {
        value: 'paid',
        name: 'Paid'
    },
    {
        value: 'rejected',
        name: 'Rejected'
    },
]
