import React, { Component } from 'react'
import PropTypes from "prop-types"
import formatThousandNumber from '../../../../../helpers/common/formatThousandNumber'
import thousandSeparateRegex from '../../../../../helpers/common/thousandSeparateRegex'

class AmountDeposit extends Component {

    render() {
        const { amount, amountVn } = this.props
        return (
            <td className='text-right pr-5'>
                <p className='m-0'>${formatThousandNumber(amount, 2)}</p>
                {amountVn && <p className='m-0'>{thousandSeparateRegex(amountVn)}₫</p>}
            </td>
        )
    }
}

AmountDeposit.propTypes = {
    amount: PropTypes.number,
}

export default AmountDeposit