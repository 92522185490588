import React, {useState, useRef} from 'react'
import MessageActions from '../../shared/MessageActions'
import {Modal, ModalBody, ModalHeader} from 'reactstrap'
import {importNewSku} from '../../../services/api/ProductLinesServices'

const ImportSKUModal = (props) => {
    const {isOpen, toggle} = props
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState({})

    const fileUploader = useRef()

    const _handleChangeFile = async (e) => {
        const selectedFile = e.target.files[0]
        setLoading(true)
        setMessage({})

        try {
            const postData = new FormData()
            postData.append('file', selectedFile)
            const {success, message} = await importNewSku(postData)

            fileUploader.current.value = ''

            if (!success) {
                setLoading(false)
                setMessage(
                    <MessageActions type="error" close={false}>
                        {message}
                    </MessageActions>
                )

                return false
            }

            let notice = (
                <MessageActions type="success" close={false}>
                    Import new SKU successfully!
                </MessageActions>
            )

            setLoading(false)
            setMessage(notice)
        } catch (error) {
            setLoading(false)
            setMessage({
                type: 'error',
                content: error.message,
            })
        }
    }

    return (
        <Modal isOpen={isOpen} className="ImportNewSku modal-dialog-centered" size="lg">
            <ModalHeader toggle={toggle}>Import new SKU</ModalHeader>

            <ModalBody>
                <p>
                    Make sure your file has 2 fields: "<b>Variant ID, Old SKU, New SKU</b>"
                </p>
                <div className="d-flex justify-content-end">
                    <div className="UploadInput mb-3">
                        <input
                            type="file"
                            accept=".csv"
                            id="importNewSku"
                            ref={fileUploader}
                            onChange={_handleChangeFile}
                        />
                        <label htmlFor="importNewSku" className="btn btn-sm btn-info cursor-pointer mb-0 ButtonImport">
                            <i className="ti-import mr-2" />
                            {loading ? (
                                <span className="float-none">
                                    Importing
                                    <span className="Updating">
                                        <span>.</span>
                                        <span>.</span>
                                        <span>.</span>
                                    </span>
                                </span>
                            ) : (
                                'Import'
                            )}
                        </label>
                    </div>
                </div>

                {Object.entries(message).length > 0 && <div className="mt-3 fs-14">{message}</div>}
            </ModalBody>
        </Modal>
    )
}

export default ImportSKUModal

