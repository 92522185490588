import React, { useState } from 'react'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import TableFilter from '../../../shared/TableFilter'
import TableLoading from '../../../shared/TableLoading'
import humanizeTime from '../../../../helpers/time/humanizeTime'
import { getStatus } from './StatusCommon'

const StoreTransferLogTable = (props) => {
    let { params, total, onChangeFilter } = props
    const [searchFieldSelected, setSearchFieldSelected] = useState('Original store')
    
    const onSubmitFilter = ({ limit, value, field, page }) => {
        const fieldMap = {
            'Original store': 'originalStoreId',
            'Destination store': 'destinationStoreId',
            'Job': 'job',
            'Product': 'product',
        }

        const parsedField = fieldMap[field]
        const parsedValue = value.trim()
        params = {
            ...params,
            limit,
            page: 1,
            [parsedField]: parsedValue,
        }
        onChangeFilter(params)
        setSearchFieldSelected(field)
    }

    const handleDateChange = ({ from, to }) => {
        if (!from && !to) {
            params = {
                ...params,
                endDate: '',
                startDate: '',
            }
            onChangeFilter(params)
        } else {
            const startDate = moment(from).format('DD/MM/YYYY')
            const endDate = moment(to).format('DD/MM/YYYY')
            params = {
                ...params,
                endDate,
                startDate,
            }
            onChangeFilter(params)
        }
    }
    return (
        <div className='StoreTransferJobTable'>
            <TableFilter
                total={total}
                defaultLimit={params.limit}
                customInput={true}
                placeholderDateFilter="Created at"
                onSubmitForm={onSubmitFilter}
                searchFieldSelected={searchFieldSelected}
                searchField={['Original store', 'Destination store', 'Job', 'Product']}
                placeholders={{
                    'Original store': 'Search for original ID',
                    'Destination store': 'Search for destination ID',
                    'Job': 'Search for job ID',
                    'Product': 'Search for product ID'
                }}
                dateFilterOn={true}
                handleDateChange={handleDateChange}
                query={{ startDate: params.startDate, endDate: params.endDate }}
            />
            <div className='TableContainer table-responsive'>
                <table className='table table-striped table-light'>
                    <StoreTransferLogTableHeader />
                    <StoreTransferLogTableBody {...props} />
                </table>
            </div>
            <div className='d-flex justify-content-end'>
                <StoreTransferLogTablePagination {...props} />
            </div>
        </div>
    )
}

const StoreTransferLogTableHeader = () => {
    return (
        <thead>
            <tr>
                <th>Job</th>
                <th>Product</th>
                <th>Original store</th>
                <th>Destination store</th>
                <th>Retry count</th>
                <th>Created date</th>
                <th>Status</th>
            </tr>
        </thead>
    )
}

const StoreTransferLogTableBody = (props) => {
    const { loading, listStore } = props
    const validateDate = time => {
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }
    return (
        <>
            {
                loading ? <TableLoading columnQuantity={7} /> : <tbody>
                    {
                        listStore.length > 0 && listStore.map((item, index) => (
                            <tr key={index}>
                                <td>{item.job}</td>
                                <td>{item.product}</td>
                                <td>{item.original_store}</td>
                                <td>{item.destination_store}</td>
                                <td>{item.retry_count}</td>
                                <td>{validateDate(item.created)}</td>
                                <td>{getStatus(item.status)}</td>
                            </tr>
                        ))
                    }
                    {
                        !listStore.length > 0 &&
                        <tr>
                            <td colSpan={7} className='text-muted text-center'>No result.</td>
                        </tr>
                    }
                </tbody>
            }
        </>

    )
}

const StoreTransferLogTablePagination = (props) => {
    const { page, pages } = props

    const handlePageChange = (page) => {
        const { selected } = page
        props.onPageChange(selected + 1)
    }

    if (pages <= 1) return null

    return (
        <ReactPaginate
            pageCount={pages}
            forcePage={page - 1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName='pagination'
            pageClassName='page-item'
            previousClassName='Previous page-item'
            nextClassName='Next page-item'
            pageLinkClassName='page-link'
            previousLinkClassName='page-link'
            nextLinkClassName='page-link'
            subContainerClassName='pages pagination'
            activeClassName='active'
        />
    )
}


export default StoreTransferLogTable