import React from 'react'
import useStoreSettings from './useStoreSettings'

const withStoreModeValidator = (conditions = {}) => (OriginalComponent, ReplaceComponent) => props => {
    const currentStore = useStoreSettings()
    const replaceComponent = typeof ReplaceComponent === 'function' ? <ReplaceComponent {...props}/> : null

    if (typeof conditions === 'function') {
        const isValid = conditions(currentStore, props)

        return isValid ? <OriginalComponent {...props}/> : replaceComponent
    }

    const currentMode = currentStore.getSetting('mode', 'normal')
    const currentRole = currentStore.roles[0]
    const validator = Object.assign({}, conditions)

    if (!validator.hasOwnProperty(currentMode)) return replaceComponent

    const allowRoles = validator[currentMode]

    if ('all' === allowRoles) return <OriginalComponent {...props}/>

    if (Array.isArray(allowRoles) && allowRoles.includes(currentRole)) return <OriginalComponent {...props}/>

    return replaceComponent
}

export default withStoreModeValidator

