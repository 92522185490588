import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {searchDeposits} from '../../../services/api/BillingServices'
import PFTable from '../../shared/PFTable'
import DepositsHeader from "./DepositsHeader"
import DepositsTableHead from './table/DepositsTableHead'
import DepositsTableBody from './table/DepositsTableBody'
import PFPagePagination from '../../shared/PFPagePagination'
import changeQuerySearch from '../../../helpers/routing/changeQuerySearch'

class DepositsTable extends Component {
    state = {
        deposits: {
            page: this.props.page,
            limit: 10,
            pages: 1,
            entity: [],
            total: 0,
            status: '',
        },
        loading: true,
        error: '',
    }

    componentDidMount() {
        this._fetchDeposits()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.store.slug !== this.props.store.slug) return this._fetchDeposits()
        if (prevProps.page !== this.props.page) {
            this.setState(({deposits}) => {
                return {
                    deposits: {
                        ...deposits,
                        page: this.props.page,
                    },
                }
            }, this._fetchDeposits)
        }
    }

    _fetchDeposits = async () => {
        const {slug} = this.props.store
        const {deposits} = this.state
        const {status, page, pages, limit} = deposits
        if (!slug) return
        this.setState({
            loading: true,
        })
        try {
            const {message, success, data} = await searchDeposits({
                siteId: slug,
                status,
                pages,
                page,
                limit
            })
            if (!success) {
                this.setState({
                    loading: false,
                    error: message
                })
            }
            this.setState({
                deposits: {
                    ...deposits,
                    entity: data.deposits,
                    total: data.total,
                    pages: data.pages
                },
                loading: false
            })

        } catch (e) {
            return this.setState({
                error: e.message,
                loading: false
            })
        }
    }

    _switchPage = page => {
        changeQuerySearch({page}, true)
    }

    render() {
        const {deposits, loading, error} = this.state
        const {entity, page, pages} = deposits

        return (
            <>
                <DepositsHeader deposits={deposits}/>
                <div className='SectionInner position-relative Deposits'>
                    <div className="DepositsTable table-responsive">
                        <PFTable source={entity}>
                            {
                                table => (
                                    <>
                                        <DepositsTableHead {...table} {...this.props}/>
                                        <DepositsTableBody {...table} fetchDeposits={this._fetchDeposits}
                                                           error={error} loading={loading}
                                                           entity={entity} {...this.props}/>
                                    </>
                                )
                            }
                        </PFTable>
                        <PFPagePagination page={page} pages={pages} onChangePage={this._switchPage}/>
                    </div>
                </div>
            </>

        )
    }
}

DepositsTable.propTypes = {
    store: PropTypes.object.isRequired,
}

export default DepositsTable
