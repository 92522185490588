export const DEFAULT_CASHBACK_RULE_FORM = {
    store: null,
    name: '',
    product_types: [],
    cashback_type: 'unit',
    value_type: 'fixed',
    value: 0,
    fulfillment_quantity_type: 'total',
    fulfillment_quantity_value: 0,
    apply_time_from: null,
    apply_time_to: null,
    note: '',
    status: 'active',
}

export const DEFAULT_ERRORS = {
    store: '',
    name: '',
    product_types: '',
    value: '',
    apply_time_from: '',
    apply_time_to: '',
}

export const CASHBACK_RULE_TYPE = [
    {
        label: 'Unit',
        value: 'unit',
    },
    {
        label: 'Fulfillment cost',
        value: 'fulfillment_cost',
    },
    {
        label: 'Base cost',
        value: 'base_cost',
    },
]

export const CASHBACK_RULE_VALUE_TYPE = [
    {
        label: 'Fixed',
        value: 'fixed',
    },
    {
        label: 'Percentage',
        value: 'percentage',
    },
]

export const CASHBACK_RULE_VALUE_TYPE_BASE_COST = [
    {
        label: 'Fixed',
        value: 'fixed',
    },
    {
        label: 'Percentage',
        value: 'percentage',
    },
    {
        label: 'Private fee',
        value: 'private_fee',
    },
    {
        label: 'Discount amount',
        value: 'discount',
    },
]

export const CASHBACK_RULE_QUANTITY_TYPE = [
    {
        label: 'Total item',
        value: 'total',
    },
    {
        label: 'Item per day',
        value: 'per_day',
    },
    {
        label: 'Total order',
        value: 'total_orders',
    },
    {
        label: 'Total order contain product ',
        value: 'total_orders_contain_product',
    },
]

export const CASHBACK_HISTORY_STATUSES = ['approved', 'done', 'pending', 'processing', 'rejected', 'failed', 'cancelled']

export const CASHBACK_RULE_HISTORY_ACTION = {
    CONFIRM: 'confirm',
    RETRY: 'retry',
    CANCEL: 'cancel',
    REJECT: 'reject'
}
