import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { updateClusterEnv } from "../../../../services/api/ClustersServices"

class ClusterEnvUpdater extends Component {
    state = {
        form: {
            key: '',
            value: ''
        },
        submitting: false,
    }

    _handleSubmit = e => {
        e.preventDefault()

        this._submit()
    }

    _submit = async () => {
        const { form, submitting } = this.state

        if (submitting) return true

        this.setState({
            submitting: true,
        })

        const { clusterId } = this.props

        try {
            const { success, message } = await updateClusterEnv(clusterId, form)

            if (!success) {
                throw new Error(message)
            }

            return this.setState({
                submitting: false,
                form: {
                    key: '',
                    value: ''
                }
            }, () => {
                this.props.onRefresh()
            })
        } catch (e) {
            console.log(e)

            this.setState({
                submitting: false,
            })
        }
    }

    _onChange = (key) => e => {
        const { value } = e.target

        this.setState(({ form }) => ({
            form: {
                ...form,
                [key]: value,
            }
        }))
    }

    _onFilter = (e) => {
        const { value } = e.target
        this.props.updateFilterQuery(value)
    }

    _onFilterString = (e) => {
        const { value } = e.target
        this.props.updateFilterString(value ? value.trim() : "")
    }

    render() {
        const { submitting, form } = this.state
        const { key, value } = form

        return (
            <div className="ClusterEnvUpdater">
                <form onSubmit={this._handleSubmit}>
                    <div className="row">
                        <div className='col-4 EnvFilter'>
                            <div className="d-flex">
                                <div className="left flex-shrink-0">
                                    <select
                                        className="form-control"
                                        onChange={this._onFilter}
                                    >
                                        <option value="key">Key</option>
                                        <option value="value">Value</option>
                                    </select>
                                </div>
                                <div className="right flex-grow-1">
                                    <input
                                        type="text"
                                        value={this.props.search}
                                        className="form-control"
                                        placeholder={`Search by ${this.props.query}...`}
                                        onChange={this._onFilterString}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <input disabled={submitting} type="text" placeholder="Key"
                                    value={key}
                                    onChange={this._onChange('key')}
                                    className="form-control" />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <input disabled={submitting} type="text" placeholder="Value"
                                    onChange={this._onChange('value')}
                                    value={value}
                                    className="form-control" />
                            </div>
                        </div>

                        <div className="col-2">
                            <div className="form-group">
                                <button disabled={submitting} className="btn btn-success" type="submit">Save</button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        )
    }
}

ClusterEnvUpdater.propTypes = {
    clusterId: PropTypes.string.isRequired,
    onRefresh: PropTypes.func.isRequired,
}

export default ClusterEnvUpdater
