import React, {Component} from 'react'

import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'

export default class JSONEditorShared extends Component {

    componentDidMount() {
        const options = {
            mode: 'form',
            modes: ['text', 'code', 'tree', 'form', 'view'],
            onChangeText: this.props.onChangeText
        }

        this.jsoneditor = new JSONEditor(this.container, options)
        this.jsoneditor.set(this.props.json)
    }

    componentWillUnmount() {
        if (this.jsoneditor) {
            this.jsoneditor.destroy()
        }
    }

    componentDidUpdate() {
        this.jsoneditor.update(this.props.json)
    }

    render() {
        return (
            <div className="JsonEditorShared" ref={elem => this.container = elem}/>
        )
    }
}
