import React, {Component} from 'react'
import PropTypes from 'prop-types'
import StatusDepositApproved from './StatusDepositApproved'
import StatusDepositRejected from './StatusDepositRejected'
import AddNote from './AddNote'
import IgnorePendingMail from './IgnorePendingMail.js'

class ActionDeposit extends Component {
    render() {
        const {deposit} = this.props

        return (
            <>
                {!deposit.internal_transaction && deposit.status === 'pending' ? (
                    <td>
                        <div className="d-flex align-items-center justify-content-center align-items-center">
                            <StatusDepositApproved {...this.props} />
                            <StatusDepositRejected {...this.props} />
                            <IgnorePendingMail {...this.props} />
                            <AddNote {...this.props} />
                        </div>
                    </td>
                ) : (
                    <td className="text-center">
                        {deposit.status !== 'cancelled' ? <AddNote {...this.props} /> : '-'}
                    </td>
                )}
            </>
        )
    }
}

ActionDeposit.propTypes = {
    deposit: PropTypes.object.isRequired,
    fetchDeposits: PropTypes.func.isRequired,
}

export default ActionDeposit

