import React, {Component} from 'react'
import className from 'classnames'
import GroupCountries from "./GroupCountries"


class ListCountriesContainer extends Component {
    state = {
        restSelected: this.props.selectedCountries === "rest",
    }

    _tempSelectedCountries = this.props.selectedCountries === "rest" ? [] : this.props.selectedCountries

    _groupCountries = [
        {
            group: "Asia",
            items: this.props.countries.filter(countryObj => countryObj.country.group === "Asia"),
        },
        {
            group: "Europe",
            items: this.props.countries.filter(countryObj => countryObj.country.group === "Europe"),
        },
        {
            group: "Africa",
            items: this.props.countries.filter(countryObj => countryObj.country.group === "Africa"),
        },
        {
            group: "Central America",
            items: this.props.countries.filter(countryObj => countryObj.country.group === "Central America"),
        },
        {
            group: "South America",
            items: this.props.countries.filter(countryObj => countryObj.country.group === "South America"),
        },
        {
            group: "Oceania",
            items: this.props.countries.filter(countryObj => countryObj.country.group === "Oceania"),
        },
        {
            group: "North America",
            items: this.props.countries.filter(countryObj => countryObj.country.group === "North America"),
        },
        {
            group: "Other",
            items: this.props.countries.filter(countryObj => countryObj.country.group === "Other"),
        },
    ]

    _getTempSelectedCountries = () => {
        return this._tempSelectedCountries
    }

    _handleChangeInputRest = e => {
        const {checked} = e.target

        if (!checked) {
            this._tempSelectedCountries = []
        }

        this.setState({
            restSelected: checked,
        })
    }

    _handleUncheckInputRest = () => {
        this.setState({
            restSelected: false,
        })
    }

    _updateTempSelectedCountries = (countryParam, action) => {

        if ("add" === action) {
            this._tempSelectedCountries = [...this._tempSelectedCountries, countryParam]
        } else if ("remove" === action) {
            this._tempSelectedCountries = this._tempSelectedCountries.filter(countryObj => countryObj.code !== countryParam.code)
        }
    }

    _submitSelectedCountries = e => {
        e.preventDefault()
        const {onUpdateSelectedCountries, onSelectRestOfWorld, onToggleModal} = this.props

        if (this.state.restSelected) {
            onSelectRestOfWorld()
        } else {
            onUpdateSelectedCountries(this._tempSelectedCountries)
        }

        onToggleModal()
    }

    render() {
        const {selectedCountries, countries, restOfWorldAvailable, search, onToggleModal, restOfWorld} = this.props
        const {restSelected} = this.state

        if (countries.length <= 0) {
            return null
        }

        return (
            <div className="ListCountriesContainer">
                <ul>
                    <li className={className("border-top", {Disabled: !restOfWorldAvailable && !restOfWorld})}>
                        <div className="CountryItem">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" id="restOfTheWorld" className="custom-control-input"
                                       onChange={this._handleChangeInputRest}
                                       checked={restSelected || (!restOfWorldAvailable && !restOfWorld)}/>

                                <label htmlFor="restOfTheWorld" className="custom-control-label">Rest of world</label>
                            </div>
                        </div>
                    </li>

                    {
                        this._groupCountries.map((groupCountries, index) => <GroupCountries
                            key={`groupCountries_${index}`}
                            groupCountries={groupCountries} restSelected={restSelected} search={search}
                            selectedCountries={selectedCountries}
                            onGetTempSelectedCountries={this._getTempSelectedCountries}
                            onUpdateTempSelectedCountries={this._updateTempSelectedCountries}
                            onUncheckInputRest={this._handleUncheckInputRest}
                            />)
                    }
                </ul>

                <div className="Actions d-flex justify-content-end pt-3 border-top">
                    <button className="btn border mr-3" onClick={onToggleModal}>Cancel</button>
                    <button className="btn btn-primary" onClick={this._submitSelectedCountries}>Save</button>
                </div>
            </div>
        )
    }
}

ListCountriesContainer.propTypes = {}

export default ListCountriesContainer
