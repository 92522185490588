import React, {Component} from 'react'

const _tableColumns = [
    '#',
    '',
    'Product Line',
    'Product Mockup',
    'Type',
    'Fulfillment Product Type',
    'Active',
    'Currency',
    'Category',
    'Affiliate Category',
    'Store Mode',
]

class TableHeader extends Component {
    render() {
        return (
            <thead className="TableHeader thead-light">
                <tr>
                    {_tableColumns.map((column) => (
                        <th scope="col" key={column}>
                            {column}
                        </th>
                    ))}
                </tr>
            </thead>
        )
    }
}

export default TableHeader

