import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {initializeInvoice, isInitializeInvoice} from '../../../../services/api/InitializeStoreInvoiceService'
import moment from 'moment'

class InitStoreInvoice extends Component {
    state = {
        settings: {},
        loaded: false,
        submitting: false,
    }

    componentDidMount() {
        this._fetchSettings()
    }

    _fetchSettings = async () => {
        const {storeId} = this.props

        try {
            const {success, data, message} = await isInitializeInvoice(storeId)
            if (!success) {
                throw new Error(message)
            }

            if (data) {
                return this.setState({
                    loaded: true,
                    settings: data,
                })
            }

            this.setState({
                loaded: true,
            })
        } catch (e) {
            window.alert(e.message)
        }
    }

    _initializable = () => {
        const {settings, loaded} = this.state

        if (!loaded) return false

        const {startedAt} = Object.assign({}, settings)

        return !startedAt
    }

    _initialize = async () => {
        const {storeId} = this.props
        this.setState({
            submitting: true,
        })

        try {
            const {success, message} = await initializeInvoice(storeId)
            if (!success) {
                throw new Error(message)
            }

            this.setState({
                submitting: false,
            }, async () => {
                await this._fetchSettings()
            })
        } catch (e) {
            this.setState({
                submitting: false,
            })

            window.alert(e.message)
        }
    }

    _handleClick = (e) => {
        e.preventDefault()

        this._initialize()
    }

    render() {
        const {submitting} = this.state
        const initializable = this._initializable()
        const {startedAt, finishedAt} = Object.assign({}, this.state.settings)
        const textStarted = startedAt ? moment(startedAt * 1).format('DD/MM/YYYY hh:mm:ss a') : ''
        const textFinished = finishedAt ? moment(finishedAt * 1).format('DD/MM/YYYY hh:mm:ss a') : ''

        return (
            <div className="InitStoreInvoice">
                <button onClick={this._handleClick} className="btn btn-info" disabled={!initializable || submitting}>
                    Initialize creating invoices
                </button>

                {
                    !initializable &&
                    <div className="Time mt-3">
                        <div className="Started">
                            <strong className="Label">Started: </strong>
                            <span>{textStarted}</span>
                        </div>

                        <div className="Started">
                            <strong className="Label">Finished: </strong>
                            <span>{textFinished || '--'}</span>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

InitStoreInvoice.propTypes = {
    storeId: PropTypes.string.isRequired,
}

export default InitStoreInvoice
