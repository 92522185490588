import React from 'react'
import BaseCostTable from './BaseCostTable'
import getHistory from '../../../../../store/getHistory'

const BaseCostContainer = (props) => {
    const {currency, productId} = props

    const openPrivateBase = () => {
        const history = getHistory()
        history.push(`/a/catalog/${productId}/private-base-cost`)
    }

    return (
        <div className="BaseCostContainer">
            <div className="d-flex justify-content-end my-3">
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary mr-3" onClick={openPrivateBase}>
                        Private base
                    </button>
                    <span>
                        Currency: <b>{currency}</b>
                    </span>
                </div>
            </div>
            <BaseCostTable {...props} />
        </div>
    )
}

export default BaseCostContainer

