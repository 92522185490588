import React, {Component} from 'react'
import PropTypes from "prop-types";
import DeleteGlobalSetting from '../actions/DeleteGlobalSetting'
import EditGlobalSetting from '../actions/EditGlobalSetting'

class ActionsColumn extends Component {

    render() {

        return (
            <td className="ActionsColumn d-flex">
                <EditGlobalSetting {...this.props}/>
                <span className="text-secondary mr-2 ml-2">|</span>
                <DeleteGlobalSetting {...this.props} />
            </td>
        );
    }

}

ActionsColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ActionsColumn