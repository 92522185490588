import React, {Component} from 'react'
import PropTypes from 'prop-types'

class TransactionOutID extends Component {
    render() {
        const {transactionID} = this.props

        return (
            <td className="TransactionOutID">
                {transactionID}
            </td>
        )
    }
}

TransactionOutID.defaultProps = {
    transactionID: ""
}

TransactionOutID.propTypes = {
    transactionID: PropTypes.string.isRequired
}

export default TransactionOutID
