import React from 'react'
import {Formik, Field, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import StatusSelect from './StatusSelect'

class CreateClusterForm extends React.Component {
    render() {
        return (
            <div className='CreateClusterForm'>
                <div className='FormTitle'>
                    <strong> Create Cluster </strong>
                </div>
                <Formik
                    initialValues={{
                        name: '',
                        ip: '',
                        host: '',
                        provider: '',
                        mongodb_uri: '',
                        redis_uri: '',
                        nats_uri: '',
                        status: {value: 'active', label: 'active'},
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required('Name is required'),
                        ip: Yup.string().required('IP is required'),
                        host: Yup.string().required('Host is required'),
                        provider: Yup.string().required('Provider is required'),
                        mongodb_uri: Yup.string().required('Mongodb URI is required'),
                        redis_uri: Yup.string().required('Redis URI is required'),
                        nats_uri: Yup.string().required('Nats URI is required'),
                        status: Yup.object().shape({
                            label: Yup.string().required(),
                            value: Yup.string().required(),
                        }),
                    })}
                    onSubmit={async (fields, {setSubmitting}) => {
                        const payload = {
                            ...fields,
                            status: fields.status.value,
                        }
                        const {createNewCluster} = this.props
                        await createNewCluster(payload)
                        setSubmitting(false)
                    }}
                    render={({errors, touched, isSubmitting, setFieldValue, setFieldTouched, values}) => (
                        <Form>
                            <div className='form-group'>
                                <span>Name</span>
                                <Field
                                    name='name'
                                    type='text'
                                    className={`form-control${errors.name && touched.name ? ' is-invalid' : ''}`}
                                />
                                <ErrorMessage name='name' component='div' className='invalid-feedback' />
                            </div>
                            <div className='form-group'>
                                <span>IP</span>
                                <Field
                                    name='ip'
                                    type='text'
                                    className={`form-control${errors.ip && touched.ip ? ' is-invalid' : ''}`}
                                />
                                <ErrorMessage name='ip' component='div' className='invalid-feedback' />
                            </div>
                            <div className='form-group'>
                                <span>Host</span>
                                <Field
                                    name='host'
                                    type='text'
                                    className={`form-control${errors.host && touched.host ? ' is-invalid' : ''}`}
                                />
                                <ErrorMessage name='host' component='div' className='invalid-feedback' />
                            </div>
                            <div className='form-group'>
                                <span>Provider</span>
                                <Field
                                    name='provider'
                                    type='text'
                                    className={`form-control${
                                        errors.provider && touched.provider ? ' is-invalid' : ''
                                    }`}
                                />
                                <ErrorMessage name='provider' component='div' className='invalid-feedback' />
                            </div>
                            <div className='form-group'>
                                <span>Mongodb URI</span>
                                <Field
                                    name='mongodb_uri'
                                    type='text'
                                    className={`form-control${
                                        errors.mongodb_uri && touched.mongodb_uri ? ' is-invalid' : ''
                                    }`}
                                />
                                <ErrorMessage name='mongodb_uri' component='div' className='invalid-feedback' />
                            </div>
                            <div className='form-group'>
                                <span>Redis URI</span>
                                <Field
                                    name='redis_uri'
                                    type='text'
                                    className={`form-control${
                                        errors.redis_uri && touched.redis_uri ? ' is-invalid' : ''
                                    }`}
                                />
                                <ErrorMessage name='redis_uri' component='div' className='invalid-feedback' />
                            </div>
                            <div className='form-group'>
                                <span>Nats URI</span>
                                <Field
                                    name='nats_uri'
                                    type='text'
                                    className={`form-control${
                                        errors.nats_uri && touched.nats_uri ? ' is-invalid' : ''
                                    }`}
                                />
                                <ErrorMessage name='nats_uri' component='div' className='invalid-feedback' />
                            </div>
                            <StatusSelect
                                value={values.status}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                error={errors.status}
                                touched={touched.status}
                            />
                            <div className='form-group'>
                                <div className='form-group'>
                                    <button type='submit' disabled={isSubmitting} className='btn btn-primary mr-2'>
                                        Create
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                />
            </div>
        )
    }
}

CreateClusterForm.propTypes = {
    createNewCluster: PropTypes.func.isRequired,
}

export default CreateClusterForm
