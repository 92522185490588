import React from 'react'
import {UncontrolledTooltip} from 'reactstrap'

const ResetFilterButton = (props) => {
    const {onResetFilters} = props

    const onClick = () => {
        onResetFilters()
    }

    return (
        <div className="ResetFilters" style={{top: -38}} onClick={onClick}>
            <button className="btn border IconWrapper" id="ResetCustomerFilter">
                <i className="fas fa-redo" />
            </button>

            <UncontrolledTooltip placement="left" target="ResetCustomerFilter">
                Reset filters
            </UncontrolledTooltip>
        </div>
    )
}

export default ResetFilterButton

