import React, {useEffect, useState} from 'react'
import {HEADER_ITEMS, ITEM_TYPE_TEXT, ITEM_TYPE_TEXTAREA} from '../statics/amazon'
import PropTypes from 'prop-types'

export const RenderHead = () => {
    return (
        <tr>
            {HEADER_ITEMS.map(column => (
                <th className="table-col-20" scope="col" key={column}>
                    {column}
                </th>
            ))}
        </tr>
    )
}

export const RenderRow = (props) => {
    const {item, updateItem, isUploadTemp, setUploadTemp} = props
    const {label, field_name, type} = item
    const [checked, setChecked] = useState(item.can_edit)

    const typeItem = (type) => {
        switch (type) {
            case ITEM_TYPE_TEXT:
                return <span>Textfield</span>
            case ITEM_TYPE_TEXTAREA:
                return <span>Textarea</span>
            default:
                return <span>Droplist</span>
        }
    }

    const optionItem = (type, item) => {
        if (type === 0) {
            return <div className="d-flex flex-column">
                {item.options.map((option, index) => {
                    return <span key={index}>{option}</span>
                })}
            </div>
        } else return null
    }

    useEffect(() => {
        const updateInputCheck = () => {
            item.can_edit = checked
            updateItem(item)
        }
        updateInputCheck()
    }, [checked, item])

    useEffect(() => {
        if (isUploadTemp) {
            setChecked(true)
            setUploadTemp(false)
        }
    }, [item])

    return (
        <tr>
            <td className="table-col-20">{label}</td>
            <td className="table-col-20">{field_name}</td>
            <td className="table-col-20">{typeItem(type)}</td>
            <td className="table-col-20">{optionItem(type, item)}</td>
            <td className="table-col-20 text-center">
                <input type="checkbox" checked={checked} onChange={() => setChecked(!checked)}/>
            </td>
        </tr>
    )
}

RenderRow.propTypes = {
    item: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    updateItem: PropTypes.func.isRequired,
    isUploadTemp: PropTypes.bool.isRequired,
    setUploadTemp: PropTypes.func.isRequired
}
