import React, {Component} from 'react'
import ProductMockUpJsonEditor from '../../product-mockup/shared/ProductMockUpJsonEditor'
import {
    getProductMockupDetail,
    deleteProductMockupDetail,
    saveProductMockupDetail,
    syncMockupDetail,
} from '../../../services/api/ProductLinesServices'
import {toaster} from '../../shared/PFToast'
import {confirmModal} from '../../shared/PFConfirmModalV2'
import ReturnPage from '../../shared/ReturnPage'
import getHistory from '../../../store/getHistory'
import classNames from 'classnames'

class ProductMockupPage extends Component {
    state = {
        error: '',
        loading: true,
        loadingSync: false,
        preview: {},
        meta: {},
        preview_meta: {},
        side_infos: {},
        title: '',
        titleProduct: '',
        product_type: '',
        errorObj: {},
        size_chart: '',
        wrap_type: '',
        preview_v2: {},
    }

    componentDidMount() {
        this._getProductMockUpDetail()
    }

    _getProductMockUpDetail = async () => {
        const {id} = this.props

        try {
            const {success, data, message} = await getProductMockupDetail(id)

            if (!success) throw new Error(message)

            const {
                preview = {},
                preview_meta = {},
                meta = {},
                title = '',
                product_type = '',
                side_infos = {},
                size_chart = {},
                wrap_type = '',
                preview_v2 = {},
            } = data

            this.setState({
                title: title,
                titleProduct: title,
                preview: preview,
                preview_meta: preview_meta,
                meta: meta,
                side_infos: side_infos,
                product_type: product_type,
                size_chart: size_chart,
                wrap_type: wrap_type,
                preview_v2: preview_v2,
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })
        } finally {
            this.setState({
                loading: false,
            })
        }
    }

    _handleClickDelete = (e) => {
        const {title} = this.state
        e.preventDefault()
        confirmModal({
            confirmText: 'Delete',
            cancelText: 'Cancel',
            confirmColor: 'danger',
            title: 'Confirm delete',
            message: (
                <span>
                    Are you sure you want to delete <b>{title}?</b>
                </span>
            ),
            onOk: this._deleteMockUpDetail,
        })
    }

    _handleSyncDetail = async (e) => {
        try {
            e.preventDefault()
            const {id} = this.props
            this.setState({loadingSync: true})
            const {success, message} = await syncMockupDetail(id)
            this.setState({loadingSync: false})
            if (!success) {
                throw new Error(message)
            }
            toaster({
                type: 'success',
                message: 'Sync detail successfully.',
                duration: 3000,
            })
            this._getProductMockUpDetail()
        } catch (error) {
            this.setState({loadingSync: false})
            toaster({
                type: 'error',
                message: error.message,
                duration: 5000,
            })
        }
    }

    _scrollErrorInput = () => {
        const scrollableDiv = document.getElementsByClassName('invalid-feedback')
        scrollableDiv[0].scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
    }

    _validateTitle = (e) => {
        e.preventDefault()
        const {title, product_type, wrap_type} = this.state
        const titleFormat = title.trim()
        const productTypeFormat = product_type.trim()

        let errorKey = {}

        if (!titleFormat) {
            errorKey.title = 'This field is required.'
        }

        if (!productTypeFormat) {
            errorKey.product_type = 'This field is required.'
        }

        if (!wrap_type) {
            errorKey.wrap_type = 'This field is required.'
        }

        this.setState({
            errorObj: errorKey,
        })

        if (Object.keys(errorKey).length > 0) return setTimeout(this._scrollErrorInput, 150)

        this._saveMockUpDetail()
    }

    _saveMockUpDetail = async () => {
        const {id} = this.props

        const {preview, meta, preview_meta, title, product_type, side_infos, size_chart, wrap_type, preview_v2} =
            this.state

        const params = {
            id,
            preview,
            meta,
            preview_meta,
            title,
            product_type,
            side_infos,
            size_chart,
            wrap_type,
            preview_v2,
        }

        try {
            const {success, message} = await saveProductMockupDetail(params)

            if (!success) throw new Error(message)

            toaster({
                type: 'success',
                message: 'Saving product mockup successfully!',
                duration: 5000,
            })

            this.setState({
                titleProduct: title,
            })
        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
                duration: 5000,
            })
        }
    }

    _deleteMockUpDetail = async () => {
        const {id} = this.props

        try {
            const {success, message} = await deleteProductMockupDetail(id)

            if (!success) throw new Error(message)

            toaster({
                type: 'success',
                message: 'Deleted Product Mockup successfully!',
                duration: 5000,
            })

            const history = getHistory()
            history.push('/a/product-mockup')
        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
                duration: 5000,
            })
        }
    }

    _changeJSON = (property, data) => {
        this.setState({
            [property]: data,
        })
    }

    _handleChangeInput = (e) => {
        e.preventDefault()

        const {name, value} = e.target

        let {errorObj} = this.state
        if (errorObj[name]) {
            delete errorObj[name]
        }

        this.setState({
            [name]: value,
        })
    }

    render() {
        const {
            preview,
            meta,
            preview_meta,
            title,
            titleProduct,
            side_infos,
            errorObj,
            product_type,
            size_chart,
            wrap_type,
            preview_v2,
        } = this.state

        return (
            <div className="ProductMockupDetailPage">
                <ReturnPage url="/a/product-mockup" title="Product mockup" />
                <h2>{titleProduct}</h2>
                <div className="ProductMockupDetailInner mt-4">
                    <div className="form-group">
                        <label htmlFor="TitleProductMockup" className="font-weight-bold">
                            Title
                        </label>
                        <input
                            type="text"
                            className={classNames('form-control', {'is-invalid': errorObj.title})}
                            id="TitleProductMockup"
                            name="title"
                            placeholder="Title product mockup ..."
                            value={title}
                            onChange={this._handleChangeInput}
                        />
                        {errorObj.title && <div className="invalid-feedback">{errorObj.title}</div>}
                    </div>

                    <div className="form-group">
                        <label className="font-weight-bold">Wrap type</label>
                        <select
                            value={wrap_type}
                            name="wrap_type"
                            className={classNames('form-control', {'is-invalid': errorObj.wrap_type})}
                            onChange={this._handleChangeInput}
                        >
                            <option disabled value="">
                                Select wrap type ...
                            </option>
                            <option value="flat">Flat</option>
                            <option value="curve">Curve</option>
                        </select>
                        {errorObj.wrap_type && <div className="invalid-feedback">{errorObj.wrap_type}</div>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="ProductTypeMockup" className="font-weight-bold">
                            Product type
                        </label>
                        <input
                            type="text"
                            className={classNames('form-control', {'is-invalid': errorObj.product_type})}
                            id="ProductTypeMockup"
                            name="product_type"
                            placeholder="Product type ..."
                            value={product_type}
                            onChange={this._handleChangeInput}
                        />
                        {errorObj.product_type && <div className="invalid-feedback">{errorObj.product_type}</div>}
                    </div>

                    <ProductMockUpJsonEditor
                        title="Size chart "
                        json={size_chart}
                        property={'size_chart'}
                        onChangeJSON={this._changeJSON}
                    />

                    <ProductMockUpJsonEditor
                        title="Side infos"
                        json={side_infos}
                        property={'side_infos'}
                        onChangeJSON={this._changeJSON}
                    />

                    <ProductMockUpJsonEditor
                        title="Meta"
                        json={meta}
                        property={'meta'}
                        onChangeJSON={this._changeJSON}
                    />

                    <ProductMockUpJsonEditor
                        title="Preview"
                        json={preview}
                        property={'preview'}
                        onChangeJSON={this._changeJSON}
                    />

                    <ProductMockUpJsonEditor
                        title="Preview meta"
                        json={preview_meta}
                        property={'preview_meta'}
                        onChangeJSON={this._changeJSON}
                    />

                    <ProductMockUpJsonEditor
                        title="Preview V2"
                        json={preview_v2}
                        property={'preview_v2'}
                        onChangeJSON={this._changeJSON}
                    />

                    <div className="Action d-flex justify-content-end mt-3">
                        <button className="btn btn-danger mr-2" onClick={this._handleClickDelete}>
                            Delete
                        </button>
                        <button className="btn btn-primary mr-2" onClick={this._handleSyncDetail}>
                            Sync
                        </button>
                        <button className="btn btn-primary" onClick={this._validateTitle}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductMockupPage

