import React, {useCallback, useEffect, useState} from 'react'
import {DEFAULT_FILTER, PLATFORM_OPTIONS} from '../constants'
import ShipmentReportTable from './table/ShipmentReportTable'
import DelayShipmentModal from './DelayShipmentModal'
import DateCustomFilter from '../../shared/DateCustomFilter'
import StoreSelect from './StoreSelect'
import moment from 'moment'
import PFPagePagination from '../../shared/PFPagePagination'
import {getOverdueShipment} from '../../../services/api/OverdueShipmentServices'
import pluralize from '../../../helpers/common/pluralize'
import Select from 'react-select'

const ShipmentReportPage = () => {
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState(DEFAULT_FILTER)
    const [pages, setPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [reports, setReports] = useState([])
    const [error, setError] = useState('')
    const [delayShipment, setDelayShipment] = useState({
        isOpen: false,
        data: null,
    })

    const _fetchShipmentReport = useCallback(async () => {
        try {
            setError('')
            setLoading(true)
            const {success, data, message} = await getOverdueShipment(query)
            if (!success) {
                throw new Error(message)
            }
            setTotal(data.total)
            setPages(data.pages)
            setReports(data.reports)
        } catch (error) {
            setError(error.message)
        } finally {
            setLoading(false)
        }
    }, [query])

    const _toggleDelayShipment = (data = null, slug = '', destination = '', stage = '') => {
        setDelayShipment((prev) => ({
            isOpen: !prev.isOpen,
            data,
            slug,
            destination,
            stage,
        }))
    }

    const _onChangeLimit = (event) => {
        const {value} = event.target
        setQuery((prev) => ({
            ...prev,
            page: 1,
            limit: value,
        }))
    }

    const _handleChangePlatformFilter = (platform) => {
        setQuery((prev) => ({
            ...prev,
            page: 1,
            source: platform,
        }))
    }

    const _handleDateChange = ({from, to}) => {
        let startDate,
            endDate = ''
        if (from && to) {
            startDate = moment(from).format('YYYY-MM-DD')
            endDate = moment(to).format('YYYY-MM-DD')
        }
        setQuery((prev) => ({
            ...prev,
            page: 1,
            packaged_at: {
                from: startDate,
                to: endDate,
            },
        }))
    }

    const _handleChangeStore = (value) => {
        let newQuery = {
            ...query,
            page: 1,
            stores: value,
        }
        if (value.length === 0) {
            newQuery['packaged_at'] = {
                from: moment().subtract(60, 'days').format('YYYY-MM-DD'),
                to: moment().format('YYYY-MM-DD'),
            }
        }
        setQuery(newQuery)
    }

    const _handleChangeSort = (value, key = 'delay_delivered_13bds') => {
        const newQuery = {
            deduplicate: query.deduplicate,
            packaged_at: query.packaged_at,
            source: query.source,
            stores: query.stores,
            page: 1,
            limit: query.limit,
            [key]: value
        }
        setQuery(newQuery)
    }

    const _handleChangePage = (page) => {
        setQuery((prev) => ({...prev, page}))
    }

    const _handleChangeCheckbox = (e) => {
        const value = e.target.checked
        setQuery((prev) => ({
            ...prev,
            page: 1,
            deduplicate: value,
        }))
    }

    useEffect(() => {
        _fetchShipmentReport()
    }, [_fetchShipmentReport])

    return (
        <div className="ShipmentReportPage">
            <div className="d-flex justify-content-between align-items-center">
                <h2>Overdue Shipment Report</h2>
            </div>

            <div className="ProductLinesInner SectionInner">
                {!!error && <span className="text-warning mb-3">{error}</span>}
                <div className="TableFilter row align-items-center justify-content-between">
                    <div className="TableLength mb-3">
                        Show
                        <select value={query.limit} onChange={_onChangeLimit}>
                            <option value="10">10 rows</option>
                            <option value="20">20 rows</option>
                            <option value="50">50 rows</option>
                            <option value="100">100 rows</option>
                        </select>{' '}
                        entries
                    </div>

                    <div className="Filter align-items-start d-flex justify-content-end">
                        <div className="input-group d-flex align-items-center justify-content-end">
                            <div className="date-filter-group mb-3 ml-3">
                                <DateCustomFilter
                                    query={{
                                        startDate: query.packaged_at.from,
                                        endDate: query.packaged_at.to,
                                    }}
                                    outsideRange="2023-08-1"
                                    disabled={!query.stores.length}
                                    placeholderDateFilter="Packaged at"
                                    onChange={_handleDateChange}
                                />
                            </div>

                            <div className="d-flex align-items-center mb-3 ml-5">
                                <label className="mb-0 mr-2">Store</label>
                                <StoreSelect values={query.stores} onChangeStore={_handleChangeStore} />
                            </div>

                            <div className="d-flex align-items-center mb-3 ml-5">
                                <label className="mb-0 mr-3">Platform</label>
                                <Select
                                    styles={{
                                        control: (base) => ({
                                            ...base,
                                            width: `${8 * query.source + 100}px`,
                                            minWidth: '200px',
                                        }),
                                    }}
                                    options={PLATFORM_OPTIONS}
                                    onChange={({value}) => _handleChangePlatformFilter(value)}
                                    placeholder={'Select platform'}
                                />
                            </div>
                        </div>

                    </div>
                    <b className={'ml-auto Total mb-3'}>{pluralize(total, 'item', 's')}</b>
                </div>

                <div className="cbo">
                    <div className="custom-control custom-checkbox mb-3">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="DisplayOnlyOverdueOrders"
                            onChange={_handleChangeCheckbox}
                        />
                        <label className="custom-control-label" htmlFor="DisplayOnlyOverdueOrders">
                            Display only exactly overdue orders
                        </label>
                    </div>
                </div>

                <ShipmentReportTable reports={reports} loading={loading} toggle={_toggleDelayShipment} query={query} onChangeSort={_handleChangeSort} platform={query?.source}/>


                <PFPagePagination page={query.page} pages={pages} onChangePage={_handleChangePage} />

                {delayShipment.isOpen && delayShipment.data && (
                    <DelayShipmentModal
                        packagedAt={query.packaged_at}
                        delayShipment={delayShipment}
                        toggle={_toggleDelayShipment}
                        platform={query?.source}
                        query={query}
                    />
                )}
            </div>
        </div>
    )
}

export default ShipmentReportPage

