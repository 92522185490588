import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import humanizeTime from '../../../../../helpers/time/humanizeTime'

class ActivateDateColumn extends Component {
    _validateDate = date => {
        const momentTime = moment(date)
        return humanizeTime(momentTime)
    }

    render() {
        const {item} = this.props
        const {activated_at, created} = item
        const date = activated_at || created
        const time = this._validateDate(date)
        return (
            <td className='ActivateTimeColumn'>
                <span>{time || ''}</span>
            </td>
        )
    }
}

ActivateDateColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ActivateDateColumn
