import React, {Component} from 'react'
import JSONEditorShared from '../../shared/JSONEditorShared'
import PropTypes from 'prop-types'

class ProductMockUpJsonEditor extends Component {

    _changeJSON = (data) => {
        const json = JSON.parse(data)
        this.props.onChangeJSON(this.props.property, json)
    }

    render() {
        const {json, title} = this.props

        return (
            <div className='ProductMockupMeta mb-3'>
                <label className='font-weight-bold'>{title}</label>
                <JSONEditorShared
                    json={json}
                    onChangeText={this._changeJSON}
                />
            </div>
        )
    }
}

ProductMockUpJsonEditor.propTypes = {
    title: PropTypes.string,
    json: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
}

export default ProductMockUpJsonEditor
