import React, {useState} from 'react'
import TikTokFeeTable from './TikTokFeeTable'
import WarehouseFeeModal from './WarehouseFeeModal'

const TikTokFeeContainer = (props) => {
    const {currency, catalog} = props
    const warehouseFee = !!catalog.warehouse_fee ? catalog.warehouse_fee : {}
    const [isOpen, setIsOpen] = useState(false)

    const openWarehouseFee = () => {
        setIsOpen(true)
    }

    return (
        <div className="BaseCostContainer">
            <div className="d-flex justify-content-end my-3">
                <div className="d-flex align-items-center">
                    <button className="btn btn-primary mr-3" onClick={openWarehouseFee}>
                        Warehouse fee
                    </button>
                    <span>
                        Currency: <b>{currency}</b>
                    </span>
                </div>
            </div>
            <TikTokFeeTable {...props} />
            {isOpen && (
                <WarehouseFeeModal
                    warehouseFee={warehouseFee}
                    isOpen={isOpen}
                    toggle={() => setIsOpen(!isOpen)}
                />
            )}
        </div>
    )
}

export default TikTokFeeContainer

