import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {_convertPasteValueToArray} from '../../../helper'
import classNames from 'classnames'

class ProductVariantPrice extends Component {
    _handleChangeInput = (e) => {
        const {value} = e.target
        const {name} = this.props
        const vValue = Number(value)
        if (isNaN(vValue)) {
            return
        }

        const {variantIndex, handleListVariantsState} = this.props
        handleListVariantsState(variantIndex, name, +value)
    }

    _onPaste = (e) => {
        e.preventDefault()
        const {name} = this.props

        this.props.onPasteListVariants(_convertPasteValueToArray(e), name)
    }

    render() {
        const {price = '', error = ''} = this.props
        return (
            <td className="ProductVariantPrice">
                <input
                    onPaste={this._onPaste}
                    className={classNames('form-control', {'is-invalid': error})}
                    type="number"
                    value={price}
                    min="0"
                    onChange={this._handleChangeInput}
                />
                {error && <i className="text-danger fs-14">{error}</i>}
            </td>
        )
    }
}

ProductVariantPrice.defaultProps = {
    error: '',
}

ProductVariantPrice.propTypes = {
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variantIndex: PropTypes.number.isRequired,
    handleListVariantsState: PropTypes.func.isRequired,
    totalVariants: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
}

export default ProductVariantPrice

