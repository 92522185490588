import React, {Component} from 'react'
import ZoneRateItem from './ZoneRateItem'
import PropTypes from 'prop-types'

class ZoneRatesList extends Component {
    render() {
        const {rates, zoneId, onFetZoneDetails, profilesId} = this.props

        return (
            <ul className="ZoneRatesList mt-3">
                {
                    rates.map(rate => <ZoneRateItem key={rate._id} profilesId={profilesId}
                                                    onFetZoneDetails={onFetZoneDetails} rate={rate}
                                                    zoneId={zoneId}/>)
                }
            </ul>
        )
    }
}

// ZoneRatesList.defaultProps = {}

ZoneRatesList.propTypes = {
    onFetZoneDetails: PropTypes.func.isRequired,
    zoneId: PropTypes.string.isRequired,
    rates: PropTypes.array.isRequired,
    profilesId: PropTypes.string.isRequired,
}

export default ZoneRatesList
