import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AccountDetails from './AccountDetails'
import ListStores from './ListStores'
import ReturnPage from '../../shared/ReturnPage'

class AccountDetailPage extends Component {
    render() {
        const {accountId} = this.props
        return (
            <div className='AccountDetailPage'>
                <ReturnPage url='/a/accounts' title='Accounts'/>

                <div className='AccountDetailPageInner SectionInner'>
                    <AccountDetails accountId={accountId}/>
                    <ListStores accountId={accountId}/>
                </div>
            </div>
        )
    }
}

AccountDetailPage.propTypes = {
    accountId: PropTypes.string.isRequired,
}

export default AccountDetailPage
