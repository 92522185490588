import React, { Component } from 'react'
import { Input } from 'reactstrap'
import TableContainer from './TableContainer'
import TablePagination from './TablePagination'
import ButtonExportAccounts from "./columns/ButtonExportAccounts"
import { getListAccounts } from '../../../services/api/StoreAccountServices'

class AccountsPage extends Component {

    state = {
        items: [],
        loading: true,
        params: {
            page: 1,
            limit: 100,
            email: '',
        },
        total: 0,
        totalPage: 1,
        err: '',
    }

    componentDidMount() {
        this._getListAccounts()
    }

    _getListAccounts = async () => {
        const { params } = this.state
        const { page, limit, email } = params

        try {
            const { success, data, message } = await getListAccounts({
                page,
                limit,
                email,
            })

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            const { total, totalPage, accounts } = Object.assign({}, data)

            this.setState({
                items: Array.isArray(accounts) ? accounts : [],
                total,
                totalPage,
                loading: false,
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _handlePageChange = page => {
        const { params } = this.state
        this.setState(
            {
                params: { ...params, page },
            },
            () => this._getListAccounts()
        )
    }

    _handleInputChange = async e => {
        const { params } = this.state
        const email = e.target.value
        this.setState({
            params: { ...params, email },
            loading: true,
            totalPage: 1,
        }, () => {
            this._getListAccounts()
        })

    }

    render() {
        const { items, params, totalPage, loading, err } = this.state
        const { page, limit, email } = params
        return (
            <div className='AccountsPage'>
                <h2>Accounts</h2>
                <div className='AccountsPageInner SectionInner'>
                    {!!err && <span className='text-warning'>{err}</span>}
                    <div className='d-flex align-items-center flex-wrap justify-content-end mb-2'>
                        <div className='SearchBar'>
                            <Input
                                onChange={this._handleInputChange}
                                type='text'
                                className='SearchInput'
                                placeholder='Seach email'
                            />
                            <i className='fas fa-search SearchIcon' />
                        </div>
                        <ButtonExportAccounts
                            params={
                                email
                            }
                            items={items}
                        />

                    </div>
                    <TableContainer items={items} page={page} limit={limit} loading={loading} />
                    <TablePagination page={page} pages={totalPage} handlePageChange={this._handlePageChange} />
                </div>
            </div>
        )
    }
}

export default AccountsPage
