import React, {Component} from 'react'

class ZoneName extends Component {
    _handleChangeInput = e => {
        const {value} = e.target

        this.props.onUpdateZoneData("name", value)
    }

    render() {
        return (
            <div className="ZoneName">
                <div className="SectionInner">
                    <h6 className="newZoneName">Zone name</h6>
                    <input type="text" className="form-control" onChange={this._handleChangeInput} required/>
                    <div className="text-muted mt-1 fs-14">Customers won't see this.</div>
                </div>
            </div>
        )
    }
}

export default ZoneName
