import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ActiveColumn extends Component {
    render() {
        const {item} = this.props
        const {is_active} = item
        let noti =''
        if(is_active){
            noti ='true'
        }
        else{
            noti = 'false'
        }

        return (
            <td className='ActiveColumn'>
                {noti}
            </td>
        )
    }
}

ActiveColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ActiveColumn
