import React, {Component} from 'react'
import PropTypes from 'prop-types'

class CapacityColumn extends Component {
    render() {
        const {item} = this.props
        const {used = 0} = item

        return (
            <td className='HostColumn'>
                <span>{used}</span>
            </td>
        )
    }
}

CapacityColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default CapacityColumn
