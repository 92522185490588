import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import StoreTransferJobContainer from './StoreTransferJobContainer';

const ModalStoreTransferJob = (props) => {
    const { storeId, modalStoreTransferJob, toggleStoreTransferJob } = props
    return (
        <Modal isOpen={modalStoreTransferJob} toggle={toggleStoreTransferJob} size="xl">
            <ModalHeader toggle={toggleStoreTransferJob}>Store transfer jobs</ModalHeader>
            <ModalBody>
                <StoreTransferJobContainer storeId={storeId} />
            </ModalBody>
        </Modal>
    )

}

export default ModalStoreTransferJob