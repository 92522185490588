import api from './manager'

export const getSettingsByCategory = (category) => {
    return api.makeAuthRequest({
        url: `/settings/${category}`,
        method: 'GET'
    })
}

export const saveSettingsByCategory = (category, settings) => {
    return api.makeAuthRequest({
        url: `/settings/${category}`,
        method: 'POST',
        data: settings
    })
}

export const uploadBannerImage = (formData) => {
    return api.makeAuthRequest({
        url: '/settings/general/appearance',
        method: 'POST',
        data: formData
    })
}

export const removeBannerImage = (type) => {
    return api.makeAuthRequest({
        url: `/settings/general/appearance/${type}`,
        method: 'DELETE'
    })
}
