import createAPIServices from "./createApiServices"

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/paypal-webhook' : 'https://dev2-manager.merch8.com/api/paypal-webhook'
const api = createAPIServices({baseUrl})

export const getOrderRefund= (params = {}) => {
    return api.makeAuthRequest({
        url: `/order-refund`,
        method: 'GET',
        params: {
            ...params
        },
    })
}


export const getDetailOrderRefund= (id) => {
    return api.makeAuthRequest({
        url: `/order-refund/${id}`,
        method: 'GET',

    })
}
