import {ToWords} from 'to-words'

const toWords = new ToWords({
    localeCode: 'en-US',
    converterOptions: {
        ignoreDecimal: false,
        ignoreZeroCurrency: false
    }
})

export const NumberToWord  = (value = '') =>  {
    if (!value || isNaN(value)) return
    return toWords.convert(value)
}
