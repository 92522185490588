import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ListItemProductLine extends Component {
    _handleInputChange = e => {
        const {checked} = e.target
        const {product, onSelectedProductLine} = this.props
        const action = checked ? 'add' : 'remove'
        onSelectedProductLine(product, action)
    }

    render() {
        const {product, productLineSelected, skus} = this.props
        const listSkuSelected = (Array.isArray(productLineSelected) && productLineSelected.length > 0) ? productLineSelected.map(item => item.sku) : []
        const isChecked = (Array.isArray(productLineSelected) && productLineSelected.length > 0) ? listSkuSelected.includes(product.sku) : false
        const isSKU_User = (Array.isArray(skus) && skus.length > 0) ? skus.map(item => item.sku).includes(product.sku) : false

        return (
            <tr>
                <td className='ColAllSelection'>
                    <div className='custom-control custom-checkbox'>
                        <input type="checkbox" className='custom-control-input'
                               id={`productLineShipping_${product.sku}`}
                               disabled={isSKU_User}
                               onChange={this._handleInputChange} checked={isChecked}/>
                        <label htmlFor={`productLineShipping_${product.sku}`} className='custom-control-label'/>
                    </div>
                </td>
                <td>{product.sku || ''}</td>
                <td>{product.title || ''}</td>
                <td>{product.type || ''}</td>
            </tr>
        )
    }
}

ListItemProductLine.propTypes = {
    product: PropTypes.object.isRequired,
    onSelectedProductLine: PropTypes.func.isRequired,
}


export default ListItemProductLine
