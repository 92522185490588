import React, {useState} from 'react'
import AsyncSelect from 'react-select/async'
import {getListStoresV2} from '../../../services/api/StoreServices'
import {MAX_STORE_QUERY} from '../constants'

const createOptions = (options) => {
    return options.map((option) => ({
        label: option.trim(),
        value: option.trim(),
    }))
}

const StoreSelect = (props) => {
    const {values = [], onChangeStore, defaultValue = [], needMaxStore = true} = props

    const [menuIsOpen, setMenuIsOpen] = useState(false)
    let timer = null

    const fetchListStores = async (storeSlug = '') => {
        try {
            const params = {
                limit: 1000,
                slug: storeSlug,
                status: 'active',
            }

            const {data, success, message} = await getListStoresV2(params)

            if (!success) throw new Error(message)

            const {stores} = data

            const listStores = stores.map((store) => {
                return {
                    value: store._id,
                    label: store.slug + ' - ' + store.domain,
                }
            })
            return listStores
        } catch (e) {
            console.log(e)
        }
    }

    const handleLoadOptions = (inputValue) => {
        if (timer) clearTimeout(timer)

        return new Promise((resolve) => {
            timer = setTimeout(() => {
                resolve(fetchListStores(inputValue))
            }, 500)
        })
    }

    const handleMenuOpen = () => {
        if (needMaxStore && values.length === MAX_STORE_QUERY) return
        setMenuIsOpen(true)
    }

    const handleMenuClose = () => {
        setMenuIsOpen(false)
    }

    const handleInputChange = (options) => {
        const value = options && options.length ? options.map((option) => option.value) : []
        if (needMaxStore && value.length === MAX_STORE_QUERY) setMenuIsOpen(false)
        onChangeStore(value)
    }

    return (
        <AsyncSelect
            isMulti
            isClearable
            cacheOptions
            defaultOptions
            placeholder="Select stores"
            menuIsOpen={menuIsOpen}
            closeMenuOnSelect={false}
            styles={{
                control: (styles) => ({
                    ...styles,
                    minWidth: '250px',
                    maxWidth: '500px',
                    maxHeight: '200px',
                    overflowY: 'auto',
                }),
            }}
            noOptionsMessage={() => null}
            loadOptions={handleLoadOptions}
            onChange={handleInputChange}
            values={createOptions(values)}
            defaultValue={defaultValue}
            onMenuOpen={handleMenuOpen}
            onMenuClose={handleMenuClose}
        />
    )
}

export default StoreSelect

