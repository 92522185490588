import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ListGroup, ListGroupItem} from 'reactstrap'
import {getStoreDetail} from '../../../services/api/StoreServices'
import hiddenReferrer from '../../../helpers/common/hiddenReferrer'

class StoreDetails extends Component {
    state = {
        loading: true,
        err: '',
        storeDetail: {},
    }

    componentDidMount() {
        this._getStoreDetail()
    }

    _getStoreDetail = async () => {
        try {
            const {storeId} = this.props
            const {success, data, message} = await getStoreDetail(storeId)

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            const storeDetail = Object.assign({}, data)
            this.setState({
                storeDetail,
                loading: false,
                err: '',
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    render() {
        const {storeDetail} = this.state
        const {name, domain, slug} = storeDetail
        const hiddenRefLink = hiddenReferrer(domain)

        return (
            <div className='StoreDetails'>
                <ListGroup>
                    <ListGroupItem>
                        <h5>StoreDetails</h5>
                        <div className='StoreDetailsInner'>
                            <div className='StoreDetails__name'>
                                <div className='text-muted'>
                                    <i className='fas fa-angle-right' />
                                    Name
                                </div>
                                <span>{name}</span>
                            </div>
                            <div className='StoreDetails__domain'>
                                <div className='text-muted'>
                                    <i className='fas fa-angle-right' />
                                    Domain
                                </div>{' '}
                                <span>
                                    <a href={hiddenRefLink} rel='noopener noreferrer' target='_blank'>
                                        {domain}
                                    </a>
                                </span>
                            </div>
                            <div className='StoreDetails__slug'>
                                <div className='text-muted'>
                                    <i className='fas fa-angle-right' />
                                    Slug
                                </div>{' '}
                                <span>{slug}</span>
                            </div>
                        </div>
                    </ListGroupItem>
                </ListGroup>
            </div>
        )
    }
}

StoreDetails.propTypes = {
    storeId: PropTypes.string.isRequired,
}

export default StoreDetails
