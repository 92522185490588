import React, {Component} from 'react'

const _tableColumns = ['#', 'Name', 'Group', 'Branch', 'Commit SHA', 'Commit Message', 'Registry URL', 'Created']

class TableHeader extends Component {
    render() {
        return (
            <thead className='TableHeader'>
                <tr>
                    {_tableColumns.map(column => (
                        <th scope='col' key={column}>
                            {column}
                        </th>
                    ))}
                </tr>
            </thead>
        )
    }
}

export default TableHeader
