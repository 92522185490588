import React, {Component} from 'react'
import ArtworksUploadContainer from './upload/ArtworksUploadContainer'
import ArtworksListContainer from './list/ArtworksListContainer'
import PropTypes from 'prop-types'
import withStoreModeValidator from '../../../shared/withStoreModeValidator'

const ARTWORK_SIDES = ['front', 'back', 'sleeve', 'hood']

class ProductArtworksContainer extends Component {

    constructor(props) {
        super(props);
        const {attributes, artwork_separated_by} = this.props
        const attributeSelectObj = Array.isArray(attributes) && attributes.find(attribute => attribute._id === artwork_separated_by)
        const {values = []} = Object.assign({}, attributeSelectObj)
        const slug = (Array.isArray(values) && values.length > 0) ? values[0].slug : ''

        this.state = {
            slug: slug,
            values: values
        }
    }

    _handleSelectAttibute = property => (e) => {
        const {value} = e.target
        this.setState({
            [property]: value
        })
    }

    render() {

        const {slug, values} = this.state
        const {artwork_separated_by, revisionId, productId} = this.props

        return (
            <div className="ProductArtworksContainer mt-3 ArtworksProductApproval" id='product_artworks'>
                <div className="SectionInner">

                    <div className="ProductArtworksTop mb-3 d-flex justify-content-between align-items-center flex-wrap">
                        <div className="ProductArtworksHeading">
                            <h3 className="Title fs-20 mb-0">Artworks</h3>
                        </div>

                        {
                            artwork_separated_by &&
                            <div className="SelectedAttributesProduct">
                                <select className="form-control"
                                        value={slug}
                                        onChange={this._handleSelectAttibute('slug')}
                                >
                                    {
                                        Array.isArray(values) && values.length > 0 && values.map((value, index) => {
                                            return (
                                                <option key={`SelectAttributeProductArtworks_${index}`} value={value.slug}>{value.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        }

                    </div>

                    <div className="mb-3">
                        <ArtworksUploadContainer
                            sides={ARTWORK_SIDES} productId={productId}/>
                    </div>

                    <ArtworksListContainer
                        revisionId={revisionId}
                        slug={slug} artwork_separated_by={artwork_separated_by}
                        sides={ARTWORK_SIDES} productId={productId}/>
                </div>
            </div>
        )
    }
}

ProductArtworksContainer.defaultProps = {
    productId: '',
    attributes: [],
}

ProductArtworksContainer.propTypes = {
    productId: PropTypes.string.isRequired,
    attributes: PropTypes.array.isRequired,
    slug: PropTypes.string.isRequired,
    artwork_separated_by: PropTypes.string.isRequired,
}

export default withStoreModeValidator({
    normal: 'all',
    marketplace: ['admin'],
    'merchize-payment': ['admin'],
})(ProductArtworksContainer)
