import React, {Component} from 'react'
import PropTypes from 'prop-types'
// import Link from 'react-router-dom/Link'

class ActionColumn extends Component {
    render() {
        const {item} = this.props
        const {onClickDelele, onClickEdit, kind} = item

        return (
            <td className='ActionColumn'>
                <button className="ViewButton btn btn-primary mr-3" onClick={onClickEdit} disabled={kind === 'alb'}>
                    <span className="ti-eye mr-1"/>
                    Edit
                </button>
                <button className="ViewButton btn btn-danger" onClick={onClickDelele} disabled={kind === 'alb'}>
                    <span className="ti-trash mr-1"/>
                    Delete
                </button>
            </td>

        )
    }
}

ActionColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ActionColumn
