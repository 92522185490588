export default class DesignFormatConst {
    static DESIGN_FRAME_SIZE = 500
    static STORE_DIMENSION = 1000
    static DEVICE_MOBILE_SIZE = 768

    static MESSAGES_PREVIEW = "This is the preview that will be shown on the product mockup for your customer. This way they will have a better understanding that they can personalize it with their own input."
    static MESSAGES_PLACEHOLDER = "Here you can put your 'call to action' so that your customer understands what information to input in the text field"
    static MESSAGES_PLACEHOLDER_IMAGE = "This will allow you to add an example image in your design to make it clear to your customer that they can upload their own similar image. If no placeholder image is selected it will show the image 'Your image here'"
    static MESSAGES_LABEL = "Personalized text label show on product page"
}
