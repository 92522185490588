import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import className from 'classnames'

class InputSearchCountries extends Component {
    _handleChangeInput = e => {
        const {value} = e.target

        this.props.onUpdateSearchState(value)
    }

    render() {
        return (
            <div
                className={className("InputSearchCountries position-relative mb-3", {Searching: this.props.search !== ""})}>
                <input type="text" className="form-control SearchInput" placeholder="Search countries and regions"
                       onChange={this._handleChangeInput}/>
                <i className="fas fa-search SearchIcon position-absolute"/>
            </div>
        )
    }
}

// InputSearchCountries.defaultProps = {}

// InputSearchCountries.propTypes = {}

export default InputSearchCountries
