import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getStoreDetail} from "../../../services/api/StoreServices"
import StoreDeployTabs from "./StoreDeployTabs"

class StoreDeployment extends Component {
    state = {
        store: {},
        error: ''
    }

    componentDidMount() {
        this._fetchStoreDetail()
    }

    _fetchStoreDetail = async () => {
        const {storeId} = this.props

        try {
            const {success, data, message} = await getStoreDetail(storeId)

            if (!success) {
                throw new Error(message)
            }

            return this.setState({
                store: data,
                error: ''
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {store, error} = this.state

        return (
            <div className="StoreDeployment">
                {
                    !!error &&
                    <div className="ErrorText">
                        <div className="Text">{error}</div>
                    </div>
                }

                <StoreDeployTabs store={store}/>
            </div>
        )
    }
}

StoreDeployment.propTypes = {
    storeId: PropTypes.string.isRequired,
}

export default StoreDeployment
