import React, {Component} from 'react'
import PropTypes from 'prop-types'

class IndexColumn extends Component {
    render() {
        const {index} = this.props
        return (
            <td className='IndexColumn'>
                <span>{index || ''}</span>
            </td>
        )
    }
}

IndexColumn.propTypes = {
    index: PropTypes.number.isRequired,
}

export default IndexColumn
