import React, {useRef, useState} from 'react'
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button} from 'reactstrap'
import ImportActionModal from './ImportActionModal'

const dropDownOptions = [
    {
        action: 'import_variants',
        label: 'Import rule by SKU variants'
    },
    {
        action: 'import_products',
        label: 'Import rule by SKU products'
    },
    {
        action: 'import_log',
        label: 'Import log'
    },
]

const ImportActionButton = (props) => {
    const {getListRules} = props
    const [isDropdownOpen, setDropdownOpen] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const [actionType, setActionType] = useState({})

    const toggleDropdown = () => setDropdownOpen(!isDropdownOpen)

    const handleToggleModal = (action) => {
        setActionType(action)
        setModalOpen(!isModalOpen)
    }

    return (
        <div className="ImportActionButton ml-3">
            <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown} direction="down">
                <DropdownToggle color="primary" caret>Import</DropdownToggle>
                <DropdownMenu end dark>
                    {dropDownOptions.map((item) => {
                        const {action, label} = item
                        return (
                            <DropdownItem key={action} onClick={() => handleToggleModal({action, label})}>
                                {label}
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
            </Dropdown>
            <ImportActionModal
                isOpen={isModalOpen}
                header={actionType?.label || ''}
                toggle={handleToggleModal}
                action={actionType?.action || ''}
                getListRules={getListRules}
            />
        </div>
    )
}

export default ImportActionButton
