import React, {Component} from 'react'
import PropTypes from 'prop-types'
// import Link from 'react-router-dom/Link'

class ProductLinesColumn extends Component {
    render() {
        const {item} = this.props
        const {title} = item

        return (
            <td className="TitleColumn">
                {/*<Link to={`/a/categories/${_id}`}>{title}</Link>*/}
                {title}
            </td>
        )
    }
}

ProductLinesColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ProductLinesColumn
