import React, {Component} from 'react'
import ProductMockupDetailPage from './ProductMockupDetailPage'

class ProductMockupDetailContainer extends Component {
    render() {
        const {id} = this.props.match.params

        return (
            <div className='ProductMockupDetailContainer'>
                <ProductMockupDetailPage id={id}/>
            </div>
        )
    }
}

export default ProductMockupDetailContainer
