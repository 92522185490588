import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {createProductGallery} from '../../../../services/api/ProductServices'
import ProductGallerySortableItem from './ProductGallerySortableItem'
import {toaster} from '../../../shared/PFToast'

class ProductGalleryImage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            preview: this.props.image.thumb || '',
            uploading: this.props.image.status === 'pending',
            error: '',
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currUploader !== this.props.currUploader
            && this.props.position === this.props.currUploader
            && this.props.image.hasOwnProperty('file')) {
            this._uploadImage()
        }
    }


    componentDidMount() {
        const isNewImage = this.props.image.hasOwnProperty('file')
        const isCurrUploader = this.props.position === this.props.currUploader && isNewImage

        if (isCurrUploader) {
            this._uploadImage()
        }
    }

    _uploadImage = async () => {
        const {image, productId, onUpdateImage, position, onNextUploader} = this.props

        try {
            this.setState({uploading: true})

            const formData = new FormData()
            formData.append('image', image.file)

            const {data, success, message} = await createProductGallery(productId, formData)

            if (!success) throw new Error(message)

            toaster({message: 'Image uploaded', type: 'success', duration: 5000})

            onUpdateImage(position, {...data, status: 'uploaded'})
        } catch (e) {
            toaster({message: e.message, type: 'danger', duration: 5000})

            this.setState({
                uploading: false,
                error: e.message,
            })

            onUpdateImage(position, {...image, status: 'failed'})
        } finally {
            onNextUploader()
        }
    }

    render() {
        const {preview, uploading, error} = this.state
        const {position, showAll} = this.props
        const isHidden = position > 7 && showAll === false && uploading === false

        if (isHidden) return null

        if (error) return null

        return <ProductGallerySortableItem {...this.props} uploading={uploading} preview={preview} disabled/>
    }
}

ProductGalleryImage.propTypes = {
    index: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    showAll: PropTypes.bool.isRequired,
    image: PropTypes.object.isRequired,
    onRemoveImage: PropTypes.func.isRequired,
    onNextUploader: PropTypes.func.isRequired,
}

export default ProductGalleryImage
