import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import humanizeTime from '../../../../helpers/time/humanizeTime'

class CreateDate extends Component {
    _validateDate = date => {
        const momentTime = moment(date)
        return humanizeTime(momentTime)
    }

    render() {
        const {item} = this.props
        const {created} = item
        const time = this._validateDate(created)
        return (
            <td className='CreateDate'>
                <span>{time || ''}</span>
            </td>
        )
    }
}

CreateDate.propTypes = {
    item: PropTypes.object.isRequired,
}

export default CreateDate
