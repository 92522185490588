import React, { useEffect, useState } from 'react'
import { filterStoreTransferJob } from '../../../../services/api/StoreServices'
import StoreTransferJobFilter from './StoreTransferJobFilter'
import StoreTransferJobTable from './StoreTransferJobTable'

const StoreTransferJobContainer = (props) => {
    const { storeId } = props
    const [loading, setLoading] = useState(false)
    const [pages, setPages] = useState(0)
    const [params, setParams] = useState({
        page: 1,
        limit: 10,
        originalStoreId: '',
        destinationStoreId: '',
        startDate: '',
        endDate: '',
        status: 'pending',
        type: '',
    })
    const [listStore, setListStore] = useState([])

    useEffect(() => {
        fetchStoreTransfer(params)
    }, [])

    const onChangeFilter = (newParams) => {
        setParams({
            ...params,
            ...newParams
        })
        fetchStoreTransfer(newParams)
    }

    const onPageChange = (page) => {
        const newParams = {
            ...params,
            page
        }
        setParams(newParams)
        fetchStoreTransfer(newParams)
    }

    const fetchStoreTransfer = async (requestParams) => {
        setLoading(true)
        const { data, success } = await filterStoreTransferJob(storeId, requestParams)
        if (success) {
            setListStore(data.jobs)
            setPages(data.pages)
        }
        setLoading(false)
    }
    return (
        <div className='StoreTransferJobContainer'>
            <StoreTransferJobFilter params={params} onChange={onChangeFilter} />
            <StoreTransferJobTable
                listStore={listStore}
                loading={loading}
                params={params}
                page={params.page}
                pages={pages}
                onChangeFilter={onChangeFilter}
                onPageChange={onPageChange} />
        </div>
    )
}

export default StoreTransferJobContainer