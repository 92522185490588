import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import TableFilter from '../../../shared/TableFilter'
import humanizeTime from '../../../../helpers/time/humanizeTime'
import TableLoading from '../../../shared/TableLoading'
import { getStatus } from '../store-transfer-log/StatusCommon'
import AllJobSelection from './bulk-action/AllJobSelection'
import JobSelection from './bulk-action/JobSelection'
import BulkCancelJob from './bulk-action/BulkCancelJob'

const StoreTransferJobTable = (props) => {
    let { params, total, onChangeFilter } = props
    const [selected, setSelected] = useState([])
    const [modal, setModal] = useState(false);
    const [refetch, setRefetch] = useState(false)
    const [searchFieldSelected, setSearchFieldSelected] = useState('Original store')

    useEffect(() => {
        if (refetch) {
            params = {
                ...params,
                page: 1,
            }
            onChangeFilter(params)
            setRefetch(false)
        }
    }, [refetch])

    const onSubmitFilter = ({ limit, value, field, page }) => {
        const fieldMap = {
            'Original store': 'originalStoreId',
            'Destination store': 'destinationStoreId',
        }

        const parsedField = fieldMap[field]
        const parsedValue = value.trim()
        params = {
            ...params,
            limit,
            page: 1,
            [parsedField]: parsedValue,
        }
        onChangeFilter(params)
        setSearchFieldSelected(field)
    }

    const handleDateChange = ({ from, to }) => {
        if (!from && !to) {
            params = {
                ...params,
                endDate: '',
                startDate: '',
            }
            onChangeFilter(params)
        } else {
            const startDate = moment(from).format('DD/MM/YYYY')
            const endDate = moment(to).format('DD/MM/YYYY')
            params = {
                ...params,
                endDate,
                startDate,
            }
            onChangeFilter(params)
        }
    }
    return (
        <div className='StoreTransferJobTable'>
            <BulkCancelJob selected={selected} modal={modal} setModal={setModal} setRefetch={setRefetch} />
            <TableFilter
                total={total}
                defaultLimit={params.limit}
                customInput={true}
                placeholderDateFilter="Created at"
                onSubmitForm={onSubmitFilter}
                searchFieldSelected={searchFieldSelected}
                searchField={['Original store', 'Destination store']}
                placeholders={{
                    'Original store': 'Search for original ID',
                    'Destination store': 'Search for destination ID',
                }}
                dateFilterOn={true}
                handleDateChange={handleDateChange}
                query={{ startDate: params.startDate, endDate: params.endDate }}
            />
            <div className='TableContainer table-responsive '>
                <table className='table table-striped table-light'>
                    <StoreTransferJobTableHeader selected={selected} setSelected={setSelected} setModal={setModal} {...props} />
                    <StoreTransferJobTableBody selected={selected} setSelected={setSelected} {...props} />
                </table>
            </div>
            <div className='d-flex justify-content-end'>
                <StoreTransferJobTablePagination {...props} />
            </div>
        </div>
    )
}

const StoreTransferJobTableHeader = (props) => {
    const { selected, setSelected, setModal, listStore } = props
    return (
        <thead>
            <tr>
                <AllJobSelection selected={selected} setSelected={setSelected} setModal={setModal} data={listStore} />
                <th>Created by</th>
                <th>Original store</th>
                <th>Destination store</th>
                <th>Processed items</th>
                <th>Total items</th>
                <th>Created date</th>
                <th>Status</th>
            </tr>
        </thead>
    )
}

const StoreTransferJobTableBody = (props) => {
    const { loading, listStore, selected, setSelected } = props
    const validateDate = time => {
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }
    return (
        <>
            {
                loading ? <TableLoading columnQuantity={7} /> : <tbody>
                    {
                        listStore.length > 0 && listStore.map((item, index) => (
                            <tr key={index}>
                                <JobSelection selected={selected} setSelected={setSelected} job={item} {...props} />
                                <td>{item.created_by}</td>
                                <td>{item.original_store}</td>
                                <td>{item.destination_store}</td>
                                <td>{item.processed_items}</td>
                                <td>{item.total_items}</td>
                                <td>{validateDate(item.created)}</td>
                                <td>{getStatus(item.status)}</td>
                            </tr>
                        ))
                    }
                    {
                        !listStore.length > 0 &&
                        <tr>
                            <td colSpan={7} className='text-muted text-center'>No result.</td>
                        </tr>
                    }
                </tbody>
            }
        </>

    )
}

const StoreTransferJobTablePagination = (props) => {
    const { page, pages } = props

    const handlePageChange = (page) => {
        const { selected } = page
        props.onPageChange(selected + 1)
    }

    if (pages <= 1) return null

    return (
        <ReactPaginate
            pageCount={pages}
            forcePage={page - 1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName='pagination'
            pageClassName='page-item'
            previousClassName='Previous page-item'
            nextClassName='Next page-item'
            pageLinkClassName='page-link'
            previousLinkClassName='page-link'
            nextLinkClassName='page-link'
            subContainerClassName='pages pagination'
            activeClassName='active'
        />
    )
}


export default StoreTransferJobTable