import React from 'react'
import PropTypes from 'prop-types'

const PayoutFilterLimit = (props) => {
    const {onChange, limit} = props

    const handChange = (e) => {
        e.preventDefault()
        const value = e.target.value
        onChange(value)
    }

    return (
        <div className="PayoutFilterLimit mr-4">
            <label className='mr-2 font-weight-bold'>Show</label>
            <select value={limit}
                    onChange={handChange}>
                <option value="10">10 rows</option>
                <option value="20">20 rows</option>
                <option value="50">50 rows</option>
            </select> entries
        </div>
    )

}

PayoutFilterLimit.propTypes = {
    onChange: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired
}

export default PayoutFilterLimit
