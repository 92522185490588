import React from "react"

const StorefrontStatusFilterSelect = (props) => {

    const { onChange, value } = props
    return (
        <div className='StorefrontStatusFilterSelect mr-4'>
            <p className="mb-0">Storefront Status</p>

            <select className="w-100" name='disable_storefront' onChange={onChange} value={value}>
                <option value=''>All</option>
                <option value={false}>Enable</option>
                <option value={true}>Disable</option>
            </select>
        </div>
    )
}

export default StorefrontStatusFilterSelect