import React, {Component} from 'react'
import PropTypes from 'prop-types'

class Method extends Component {
    _mapMethod = (method) => {
        return method.length > 0 ? method.map(item => {
            if(item === 'google') {
                return 'Google'
            } else if(item === 'user_password') {
                return 'User/Pass'
            }
        }).join(', ') : ''
    }
    render() {
        const {item} = this.props
        const {login_methods = []} = item

        return (
            <td className='Method'>
                <span>{this._mapMethod(login_methods)} </span>
            </td>
        )
    }
}

Method.propTypes = {
    item: PropTypes.object.isRequired,
}

export default Method
