import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import NumberFormat from 'react-number-format'

const PaypalRefund = ({ payPalRefunds }) => {

    return (
        <div className='row mb-3'>
            <div className="col-12">
                <h6 className='mb-3 font-weight-bold'>Paypal refund</h6>
                <table className="table ">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Time</th>
                            <th scope="col">Paypal ID</th>
                            <th scope="col">Value</th>
                            <th scope="col">Note</th>
                            <th scope="col">Payee email</th>
                            <th scope="col">Payer email</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payPalRefunds.map((payPalRefund, index) => {
                            return <tr key={index}>
                                <td>{moment(payPalRefund.paypal_create_time).format('DD-MM-YYYY HH:mm A')}</td>
                                <td>{_.get(payPalRefund, 'paypal_id', '')}</td>
                                <td>
                                    <NumberFormat value={_.get(payPalRefund, 'amount_refund', 0)} className="foo" displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value, props) => <div {...props}>{value}</div>} />
                                </td>
                                <td><span className='note'>
                                    {_.get(payPalRefund, 'note', '')}
                                </span></td>
                                <td>{_.get(payPalRefund, 'payee_email', '')}</td>
                                <td>{_.get(payPalRefund, 'payer_email', '')}</td>
                            </tr>
                        })}

                    </tbody>
                </table>
                <hr />
            </div>
        </div>

    )
}

export default PaypalRefund
