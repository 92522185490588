import React from 'react'
import ProductVariantFilter from '../ProductVariantFilter'
import {randomID} from '../../../../../helpers/common/randomID'

const TikTokFeeTableRow = (props) => {
    const {variants, attributes, filter, handleFilterVariants, tab, updateFilter} = props

    return (
        <tbody>
            <ProductVariantFilter
                tab={tab}
                attributes={attributes}
                filter={filter}
                updateFilter={updateFilter}
                handleFilterVariants={handleFilterVariants}
            />
            {!!variants.length &&
                variants.map((variant, index) => {
                    const {sku, attributes} = variant
                    const warehouse = !!variant.warehouse ? variant.warehouse : {}
                    const {seller_shipping: sellerShipping = [], tiktok_shipping: tiktokShipping = []} = warehouse
                    return (
                        <tr key={`tiktok_fee_${index}`}>
                            {!!attributes.length &&
                                attributes.map((attribute) => {
                                    const randomId = randomID()
                                    return (
                                        <td key={randomId}>
                                            <div className="d-flex align-items-center">
                                                <span className="ml-2">{attribute.value_text}</span>
                                            </div>
                                        </td>
                                    )
                                })}
                            <td>{sku}</td>
                            <td>{tiktokShipping.join(', ')}</td>
                            <td>{sellerShipping.join(', ')}</td>
                        </tr>
                    )
                })}
        </tbody>
    )
}

export default TikTokFeeTableRow

