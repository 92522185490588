export const DEFAULT_FILTER = {
    page: 1,
    limit: 100,
    ffm_location: '',
    destination: '',
    state: '',
    shipping_plan: '',
    sortBy: 'newest'
}

export const FULFILLMENT_LOCATIONS = [
    {
        name: 'US',
        value: 'US',
    },
    {
        name: 'EU',
        value: 'EU',
    },
    {
        name: 'VN',
        value: 'VN',
    },
    {
        name: 'CN',
        value: 'CN',
    },
    {
        name: 'AU',
        value: 'AU',
    },
    {
        name: 'GB',
        value: 'GB',
    },
]

export const DESTINATION = [
    {
        name: 'US',
        value: 'US',
    },
    {
        name: 'EU',
        value: 'EU',
    },
    {
        name: 'GB',
        value: 'GB',
    },
    {
        name: 'AU',
        value: 'AU',
    },
    {
        name: 'ROW',
        value: 'ROW',
    },
]

export const SHIPPING_PLANS = [
    {
        name: 'Express shipping - express',
        value: 'express'
    },
    {
        name: 'Fast shipping ⚡️  - fast',
        value: 'fast'
    },
    {
        name: 'Standard - standard',
        value: 'standard'
    },
    {
        name: 'Ship by tiktok',
        value: 'shipbytiktok'
    },
]

export const SCOPE = [
    {
        name: 'Tiktok shipping',
        value: 'tiktok_shipping'
    },
    {
        name: 'Seller shipping',
        value: 'seller_shipping'
    },
    {
        name: 'Normal orders',
        value: 'non-tiktok'
    },
]

