import React, {Component} from 'react'

// import PropTypes from 'prop-types'

class FirstItemPriceInput extends Component {
    state = {
        firstItem: this.props.firstItem,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.firstItem !== this.props.firstItem) {
            this.setState({
                firstItem: this.props.firstItem,
            })
        }
    }

    _handleChangeInput = e => {
        const {value} = e.target

        this.setState({
            firstItem: value,
        })

        this.props.onUpdatePerItemData("first_item", Number(value))
    }

    _handlePressInput = e => {
        if (e.key === "Enter") {
            e.preventDefault()
        }
    }

    render() {
        const {firstItem} = this.state

        return (
            <div className="FirstItemPriceInput">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                    </div>

                    <input type="number" step="any" name="first_item" className="form-control"
                           onChange={this._handleChangeInput} onKeyPress={this._handlePressInput}
                           required value={firstItem} min={0}/>
                </div>
            </div>
        )
    }
}

// FirstItemPriceInput.defaultProps = {}

// FirstItemPriceInput.propTypes = {}

export default FirstItemPriceInput
