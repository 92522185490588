import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {cancelPendingTransactionOut} from '../../../../services/api/BillingServices'
import {UncontrolledTooltip} from 'reactstrap'
import PFConfirmModal from '../../../shared/PFConfirmModal'

class TransactionOutCancellation extends Component {

    _doAction = async () => {
        const {transactionId, onFetchTransactions} = this.props

        const {success, message} = await cancelPendingTransactionOut(transactionId)

        if (!success) throw new Error(message)

        return {
            data: <div className='text-success'>Success!</div>,
            onSuccess: onFetchTransactions,
        }
    }

    render() {
        const {status, transactionId} = this.props

        if ('pending' !== status) return null

        return (
            <div className='TransactionOutCancellation'>
                <PFConfirmModal
                    title='Confirm reject transaction'
                    description='Are you sure want to cancel this transaction?'
                >
                    {
                        confirm => {
                            return (
                                <div onClick={confirm(this._doAction)}>
                                    <span id={`reject_${transactionId}`}
                                          className='text-danger cursor-pointer'><i className="fas fa-times"/></span>

                                    <UncontrolledTooltip placement='bottom' target={`reject_${transactionId}`}>
                                        Cancel Transaction
                                    </UncontrolledTooltip>
                                </div>
                            )
                        }
                    }
                </PFConfirmModal>
            </div>
        )
    }
}

TransactionOutCancellation.propTypes = {
    status: PropTypes.string.isRequired,
    transactionId: PropTypes.string.isRequired,
}

export default TransactionOutCancellation
