export const fieldMap = {
    'Store domain': 'storeDomain',
    'Store ID': 'storeId',
    'Details': 'details',
}

export const DEFAULT_PARAMS = {
    page: 1,
    limit: 50,
    storeDomain: '',
    storeId: '',
    details: '',
    status: '',
    searchFieldSelected: 'Store domain',
}
