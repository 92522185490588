import React, {Component} from 'react'
import ProductMockupPage from './ProductMockupPage'

class ProductMockupContainer extends Component {
    render() {
        return (
            <div className='ProductMockupContainer'>
                <ProductMockupPage/>
            </div>
        )
    }
}

export default ProductMockupContainer
