import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class FeatureImage extends Component {
    state = {
        srcValid: '',
    }

    _handleLoad = () => {
        this.setState({
            srcValid: true,
        })
    }

    _handError = () => {
        this.setState({
            srcValid: false,
        })
    }

    _getBackgroundImage = () => {
        const {srcValid} = this.state

        if ('' === srcValid) {
            return ''
        }

        if (true === srcValid) {
            return this.props.src
        }

        return '/assets/images/placeholder-image.png'
    }

    render() {
        const {src, className: externalClass, width, bgSize} = this.props
        const bg = this._getBackgroundImage()

        return (
            <div className={classNames('FeatureImage d-inline-block', {[externalClass]: !!externalClass})}
                 style={{width: width}}>
                <div className='ImageWrapper' style={{backgroundImage: `url("${bg}")`, backgroundSize: bgSize}}>
                    <img src={src} alt='' onLoad={this._handleLoad} onError={this._handError}/>
                </div>
            </div>
        )
    }
}

FeatureImage.defaultProps = {
    width: 50,
    bgSize: 'contain',
}

FeatureImage.propTypes = {
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
    width: PropTypes.number,
    bgSize: PropTypes.string,
}

export default FeatureImage
