import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { cancelStoreTransferJob } from '../../../../../services/api/StoreServices';
import { toaster } from '../../../../shared/PFToast';

const BulkCancelJob = (props) => {

    const { storeId, selected, modal, setModal, setRefetch } = props
    const [loading, setLoading] = useState(false)

    const toggle = () => setModal(!modal);

    const cancelJob = async () => {
        setLoading(true)
        const { success, message } = await cancelStoreTransferJob(storeId, selected)
        if (success) {
            setRefetch(true)
            setModal(false)
        } else {
            toaster({
                type: 'danger',
                message
            })
        }
        setLoading(false)
    }

    return (
        <Modal isOpen={modal} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Cancel store transfer jobs</ModalHeader>
            <ModalBody>
                Are you sure to cancel store transfer jobs?
            </ModalBody>
            <ModalFooter>
                <Button outline color="secondary" onClick={toggle}>
                    Cancel
                </Button>
                <Button color="primary" onClick={cancelJob} disabled={loading}>
                    {
                        loading ? <i className="fas fa-circle-notch fa-spin"></i> : 'Ok'
                    }
                </Button>
            </ModalFooter>
        </Modal>
    )

}

export default BulkCancelJob