import createAPIServices from './createApiServices'

const baseUrl =
    process.env.NODE_ENV === 'production' ? '/api/manager' : 'https://admin-staging.merchize.com/api/manager'

const api = createAPIServices({baseUrl})

export const getListGroups = (params) =>
    api.makeAuthRequest({
        url: `/groups`,
        method: 'GET',
        params,
    })

export const changeStatusGroup = (params) =>
    api.makeAuthRequest({
        url: `/groups/action/update-status`,
        method: 'POST',
        params,
    })
