import React, {Component} from 'react'
import PropTypes from "prop-types"
import PubSub from '../../GlobalSettingBroker'
import PFConfirmModal from '../../../shared/PFConfirmModal'
import EditGlobalSettingModal from './EditGlobalSettingModal'
import {editGlobalSetting} from "../../../../services/api/StoreServices"

class EditGlobalSetting extends Component {
    _formValue = this.props.item.value

    _updateSetting = value => {
        this._formValue = value
    }

    _doAction = async () => {
        const {item} = this.props
        const value = this._formValue

        if(!value.trim()) throw new Error('Field value is require!')

        const {success, message} = await editGlobalSetting(item._id, value)

        if (!success) throw new Error(message)

        return {
            onSuccess: this._onFetchSettings(),
            hideAfterSuccess: true
        }
    }

    _onFetchSettings = () => {
        PubSub.publish("STORE_FETCH_GLOBAL_SETTINGS")
    }

    render() {
        const {item} = this.props

        return (
            <span className="EditSetting">
                <PFConfirmModal
                    title='Edit Setting'
                    description={<EditGlobalSettingModal title={item.key} value={item.value} onChangeSetting={this._updateSetting}/>}
                >
                    {
                        confirm => {
                            return (
                                <div className="ViewButton text-primary cursor-pointer" onClick={confirm(this._doAction)}>
                                    Edit
                                </div>
                            )
                        }
                    }
                </PFConfirmModal>
            </span>
        )
    }

}

EditGlobalSetting.propTypes = {
    item: PropTypes.object.isRequired,
}

export default EditGlobalSetting