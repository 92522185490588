import {useState, useEffect, useRef} from 'react'
import {Spinner} from 'reactstrap'

const AutoComplete = ({options = [], itemConfig, handleInputChange, loading, handleOnChange, loadMoreSuggestions, isUpdate}) => {
    const [value, setValue] = useState(itemConfig?.key || '')
    const [showSuggestions, setShowSuggestions] = useState(false)
    const suggestions = options.filter((option) => option.toLowerCase().includes(value.toLowerCase()))
    const suggestionsRef = useRef()
    const autocompleteRef = useRef()

    useEffect(() => {
        const handleClick = (event) => {
            if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
                setShowSuggestions(false)
            }
        }
        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }, [])

    const handleChange = (event) => {
        setValue(event.target.value)
        handleInputChange(event.target.value)
        if (suggestionsRef.current) {
            suggestionsRef.current.scrollTop = 0;
        }
    }

    const handleSuggestionClick = (suggetion) => {
        setValue(suggetion)
        handleOnChange(suggetion)
        setShowSuggestions(false)
    }

    useEffect(() => {
        const handleScroll = () => {
            if (suggestionsRef.current && !isUpdate) {
                const { scrollTop, scrollHeight, clientHeight } = suggestionsRef.current;
                if (scrollTop + clientHeight >= scrollHeight) {
                    loadMoreSuggestions();
                }
            }
        };

        if (suggestionsRef.current) {
            suggestionsRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (suggestionsRef.current) {
                suggestionsRef.current.removeEventListener('scroll', handleScroll);
            }
        }; 
    },[suggestions,suggestionsRef,loadMoreSuggestions])

    return (
        <div className="AutoComplete" ref={autocompleteRef}>
            <div className="CustomInput">
                <input
                    value={value}
                    onChange={handleChange}
                    className="InputAutoComplete form-control"
                    onFocus={() => setShowSuggestions(true)}
                />
                {loading && <Spinner color="secondary" className="Spin" size="sm" />}
            </div>
            {showSuggestions && (
                <ul ref={suggestionsRef} className={`Suggestions ${suggestions.length < 6 ? 'overflow-hidden' : 'overflow-scroll'}`}>
                    {suggestions.map((suggestion,index) => (
                        <li onClick={() => handleSuggestionClick(suggestion)} key={index}>
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

export default AutoComplete
