import React, {Component} from 'react'
import tldjs from 'tldjs'
import TableContainer from './TableContainer'
import TablePagination from './TablePagination'
import StatusFilterSelect from './StatusFilterSelect'
import TableFilter from '../../shared/TableFilter'
import moment from 'moment'
import ResetFilterButton from './ResetFilterButton'
import {getFiltersStorage, resetFiltersStorage, setFilterStorage} from '../../shared/ManageFiltersState'
import {fieldMap, DEFAULT_PARAMS} from '../constants'
import Pubsub from '../../shared/brokers/FilterBroker'
import {getListMappingRules} from '../../../services/api/MappingRulesServices'
import ImportActionButton from './actions/import/ImportActionButton'
import {hasRole, isAdmin} from '../../../services/AuthServices'
import ExportActionButton from './actions/export/ExportActionButton'

class MappingRulesPage extends Component {
    constructor(props) {
        super(props)

        const storageParams = getFiltersStorage('settingRules') || {}

        this.state = {
            items: [],
            loading: true,
            params: {
                ...DEFAULT_PARAMS,
                ...storageParams,
            },
            total: 0,
            totalPage: 1,
            err: ''
        }
    }

    componentDidMount() {
        this._getListRules()
    }

    _getListRules = async () => {
        const {params} = this.state
        if (!params.cluster) {
            delete params.group
        }
        this.setState({
            items: [],
        })
        try {
            const req = Object.entries(params).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
            if (req.searchValue) delete req.searchValue
            if (req.searchFieldSelected) delete req.searchFieldSelected
            const {success, data, message} = await getListMappingRules(req)

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            const {total, totalPages, rules} = Object.assign({}, data)

            this.setState({
                items: Array.isArray(rules) ? rules : [],
                total,
                totalPage: totalPages,
                loading: false,
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _handlePageChange = (page) => {
        this.setState(
            {
                params: {...this.state.params, page},
            },
            () => {
                this._getListRules()
                setFilterStorage('settingRules', {page})
            }
        )
    }

    _handleChangeFilter = (e) => {
        const {name, value} = e.target
        this.setState(
            (prevState) => ({
                params: {
                    ...prevState.params,
                    page: 1,
                    groupId: (name === 'cluster' && value === '') ? '' : prevState.params.groupId,
                    [name]: value}
            }),
            () => {
                this._getListRules()
                setFilterStorage('settingRules', {[name]: value})
            }
        )
    }

    _onSubmitFilter = ({limit, value, field}) => {

        const parsedField = fieldMap[field]
        console.log(parsedField, field)
        const parsedValue =
            field === 'storeDomain'
                ? tldjs.parse(value).hostname
                : value.trim()
        const {params} = this.state
        this.setState(
            () => ({
                params: {
                    ...params,
                    ...{
                        storeDomain: '',
                        storeId: '',
                        page: '',
                        loading: '',
                    },
                    ...{
                        [parsedField]: parsedValue,
                        searchFieldSelected: field,
                        searchValue: parsedValue,
                        limit,
                        page: 1,
                        loading: false,
                    },
                },
            }),
            () => {
                this._getListRules()
                setFilterStorage('settingRules', {
                    ...params,
                    ...{
                        domain: '',
                        slug: '',
                        page: '',
                        loading: '',
                        tags: [],
                    },
                    ...{
                        [parsedField]: parsedValue,
                        searchFieldSelected: field,
                        searchValue: parsedValue,
                        limit,
                        page: 1,
                        loading: false,
                    },
                })
            }
        )
    }

    _handleDateChange = ({from, to}) => {
        const {params} = this.state
        if (!from && !to) {
            this.setState(
                {
                    params: {
                        ...params,
                        endLastSuspendAt: '',
                        startLastSuspendAt: '',
                    },
                },
                () => {
                    this._getListRules()
                    setFilterStorage('settingRules', {startLastSuspendAt: '', endLastSuspendAt: ''})
                }
            )
        } else {
            const startLastSuspendAt = moment(from).format('YYYY-MM-DD')
            const endLastSuspendAt = moment(to).format('YYYY-MM-DD')
            this.setState(
                {
                    params: {
                        ...params,
                        endLastSuspendAt,
                        startLastSuspendAt,
                    },
                },
                () => {
                    this._getListRules()
                    setFilterStorage('settingRules', {
                        startLastSuspendAt,
                        endLastSuspendAt,
                    })
                }
            )
        }
    }

    _handleResetFilter = () => {
        this.setState(
            () => ({
                resource_kind: '',
                params: DEFAULT_PARAMS,
            }),
            () => {
                this._getListRules()
                resetFiltersStorage('settingRules')
            }
        )
        Pubsub.publish('RESET_FILTER')
    }

    _downloadBlob = (fileName, fileBlob, type) => {
        const blob = new Blob([fileBlob], {type})
        const downloadLink = document.createElement('a')
        downloadLink.href = URL.createObjectURL(blob)
        downloadLink.download = fileName
        downloadLink.click()
    }

    render() {
        const {items, params, totalPage, loading, err, total} = this.state
        const {
            page,
            limit,
            status,
            startLastSuspendAt,
            endLastSuspendAt,
            searchValue,
            searchFieldSelected,
        } = params

        const isRoleOperator = hasRole('operator')
        if (!(isRoleOperator || isAdmin())) return null


        return (
            <div className="StoresPage">
                <div className="d-flex flex-wrap mb-3">
                    <h2 className="mb-0">Mapping rule</h2>
                    <div className='d-flex flex-wrap ml-auto'>
                        <ExportActionButton params={params}/>
                        <ImportActionButton getListRules={this._getListRules}/>
                    </div>
                </div>
                <div className='StoresPageInner SectionInner'>
                    <ResetFilterButton onResetFilters={this._handleResetFilter} />
                    {!!err && <span className="text-warning">{err}</span>}
                    <div className="Filter d-flex justify-content-between mb-3">
                        <div className="FilterAttributes d-flex flex-wrap">
                            <StatusFilterSelect onChange={this._handleChangeFilter} value={status || ''} />
                        </div>
                    </div>

                    <TableFilter
                        total={total}
                        defaultLimit={limit}
                        onSubmitForm={this._onSubmitFilter}
                        searchValue={searchValue}
                        searchFieldSelected={searchFieldSelected}
                        searchField={[
                            'Store domain',
                            'Store ID',
                            'Details',
                        ]}
                        placeholders={{
                            'Store domain': 'Search for store domain',
                            'Store ID': 'Search for store ID',
                            Details: 'Search for details',
                        }}
                        placeholderDateFilter="Last suspend at"
                        dateFilterOn={false}
                        handleDateChange={this._handleDateChange}
                        query={{startDate: startLastSuspendAt, endDate: endLastSuspendAt, hasReset: true}}
                    />
                    <TableContainer items={items} page={page} limit={limit} loading={loading} />
                    <TablePagination page={page} pages={totalPage} handlePageChange={this._handlePageChange} />
                </div>
            </div>
        )
    }
}

export default MappingRulesPage

