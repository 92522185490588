import React from 'react'
import {Switch, Route, Redirect} from 'react-router'
import HomeContainer from './home/components/HomeContainer'
import LoginPageContainer from "./login/components/LoginPageContainer"
import DashboardContainer from "./dashboard/components/DashboardContainer"
import DocTitle from "./shared/DocTitle"
import LogoutPageContainer from "./logout/components/LogoutPageContainer"
import AuthPageContainer from "./auth/components/AuthPageContainer"
import AppContext from '../context/AppContext'
import useAppState from '../hooks/useAppState'

function App() {
    const useApp = useAppState()

    return (
        <AppContext.Provider value={useApp}>
            <div className="App">
                <DocTitle title="Merchize - Sellers"/>
                <Switch>
                    <Route path="/" exact component={HomeContainer}/>
                    <Route path="/a" component={DashboardContainer}/>
                    <Route path="/login" component={LoginPageContainer}/>
                    <Route path="/logout" component={LogoutPageContainer}/>
                    <Route exact path="/auth/:token" component={AuthPageContainer}/>
                    <Redirect to="/"/>
                </Switch>
            </div>
        </AppContext.Provider>
    )
}

export default App
