import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import PFTablePlaceholder from '../../../../shared/PFTablePlaceholder'
import StatusStyle from '../../../../shared/StatusStyle'
import humanizeTime from '../../../../../helpers/time/humanizeTime'
import moment from 'moment'
import {confirmModal} from '../../../../shared/PFConfirmModalV2'
import {toaster} from '../../../../shared/PFToast'
import {forceRetryDailyTopupJob} from '../../../../../services/api/AccountingReport'

class DailyTopupReportTableBody extends Component {
    _toggleConfirmModal = (id) => {
        confirmModal({
            confirmText: 'Retry',
            confirmColor: 'primary',
            title: 'Confirm retry',
            message: <span>Are you sure you want to retry this report job?</span>,
            onOk: () => this._handleRetry(id),
        })
    }

    _handleRetry = async (id) => {
        try {
            const {success, message} = await forceRetryDailyTopupJob(id)
            if (!success) {
                throw new Error(message)
            }
            this.props.refetch()
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    render() {
        const {page, reportData, loading} = this.props

        return (
            <tbody className="DailyTopupReportTableBody TableLoading">
                {loading ? (
                    <PFTablePlaceholder columnQuantity={6} />
                ) : reportData.length === 0 ? (
                    <tr>
                        <td colSpan={6}>
                            <i>This store has no report</i>
                        </td>
                    </tr>
                ) : (
                    <Fragment>
                        {reportData.map((item, index) => {
                            const {_id, status, created_at, from, to, link} = item

                            return (
                                <tr key={`BalanceReportList_${index}`}>
                                    <td>{page * index + 1}</td>
                                    <td>
                                        {from && to && (
                                            <span>
                                                {moment(from).add(7, 'hours').format('DD/MM/YYYY')}
                                            </span>
                                        )}
                                    </td>
                                    <td>{humanizeTime(created_at)}</td>
                                    <td>
                                        <StatusStyle status={status} />
                                    </td>
                                    <td>{link && <a href={link}>{link.substring(link.lastIndexOf('/') + 1)}</a>}</td>
                                    <td>
                                        {status !== 'done' && (
                                            <button
                                                type="button"
                                                className="btn btn-primary text-center"
                                                onClick={() => this._toggleConfirmModal(_id)}
                                            >
                                                Retry
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                    </Fragment>
                )}
            </tbody>
        )
    }
}

DailyTopupReportTableBody.propTypes = {
    reportData: PropTypes.array.isRequired,
}

export default DailyTopupReportTableBody
