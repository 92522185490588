import React from 'react'
import GroupsPage from './GroupsPage'

const GroupsContainer = () => {
    return (
        <div className='GroupsContainer'>
            <GroupsPage/>
        </div>
    )
}

export default GroupsContainer
