import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import ObjectInspector from 'react-object-inspector'
import humanizeTime from '../../../helpers/time/humanizeTime'

class StoreHistory extends Component {
    _validateDate = time => {
        const momentTime = moment(time)

        return humanizeTime(momentTime)
    }

    render() {
        const {history} = this.props
        const {event, description, value, created} = history
        const createDate = this._validateDate(created)

        return (
            <div className='StoreHistories'>
                <div className='card'>
                    <div className='card-body'>
                        <h5 className='card-title'>Histories</h5>
                        <ul className='list-group list-group-flush'>
                            <li className='list-group-item'>
                                <span className='text--bold'>Event: </span>
                                {event}
                            </li>
                            <li className='list-group-item'>
                                <span className='text--bold'>Description: </span> {description}
                            </li>
                            {value ? (
                                <li className='list-group-item'>
                                    <span className='text--bold'>
                                        Value: <ObjectInspector data={value} />
                                    </span>
                                </li>
                            ) : (
                                ''
                            )}
                            <li className='list-group-item'>
                                <span className='text--bold'>Created: </span> {createDate}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

StoreHistory.propTypes = {
    history: PropTypes.object.isRequired,
}

export default StoreHistory
