import React, {Component} from 'react'
import CreateCategoryForm from './CreateCategoryForm'
import {createCategory} from '../../services/api/CategoriesServices'
import {Link} from 'react-router-dom'
import {toaster} from '../shared/PFToast'
import getHistory from '../../store/getHistory'

class CreateCategoryPage extends Component {
    state = {
        err: '',
        loading: '',
    }

    _createNewCategory = async payload => {
        const {title} = payload
        this.setState({
            err: '',
        })
        try {
            const {success, message} = await createCategory(payload)
            if (!success) {
                return this.setState({
                    err: message,
                })
            }

            toaster({
                type: 'success',
                message: `Created Category ${title} sucessfully`,
                duration: 5000
            })

            const history = getHistory()
            history.push('/a/categories')

        } catch (error) {
            this.setState({
                err: error.message || error,
            })
        }
    }

    render() {
        const {err} = this.state
        return (
            <div className='CreateCategoryPage'>
                <Link className='nav-link BackButton' to='/a/categories'>
                    <i className='fas fa-chevron-left' />
                    <span>Categories</span>
                </Link>
                <h1 className='PageTitle'>Create Category</h1>
                <div className='CreateCategoryPageInner SectionInner'>
                    {!!err && <p className='text-warning'>{err} </p>}
                    <CreateCategoryForm createNewCategory={this._createNewCategory} />
                </div>
            </div>
        )
    }
}

export default CreateCategoryPage
