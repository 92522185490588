import React, {Component} from 'react'
import PushNotificationsModal from "./PushNotificationsModal"

class PushNotificationsContainer extends Component {
    state = {
        isOpen: false,
    }

    _handleToggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    render() {
        const {isOpen} = this.state

        return (
            <div className="PushNotificationsContainer">
                <button className="btn btn-outline-primary" onClick={this._handleToggleModal}>Push notification</button>

                <PushNotificationsModal isOpen={isOpen} onToggleModal={this._handleToggleModal}/>
            </div>
        )
    }
}

export default PushNotificationsContainer
