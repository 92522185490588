import React, {useEffect, useState} from 'react'
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'
import {CATALOG_VARIANT_TABS} from '../../constants'
import BaseCostContainer from './basecost/BaseCostContainer'
import ShippingContainer from './shipping/ShippingContainer'
import RelatedProductContainer from './related-product/RelatedProductContainer'
import DesignContainer from './design/DesignContainer'
import ColorMappingContainer from './color-mapping/ColorMappingContainer'
import parseSearchQuery from '../../../../helpers/routing/parseSearchQuery'
import changeQuerySearch from '../../../../helpers/routing/changeQuerySearch'
import TikTokFeeContainer from './tiktok-fee/TikTokFeeContainer'
import SellerShippingContainer from './seller-shipping/SellerShippingContainer'
import {
    convertSneakCaseToTitle,
    formatTikTokInString,
    standardTikTokInString,
} from '../../../../helpers/common/stringFormatter'

const CatalogVariantContainer = (props) => {
    const {currentTab} = parseSearchQuery()
    const {label, attributes, variants} = props
    const [tab, setTab] = useState(CATALOG_VARIANT_TABS[0].value)
    const hasColorAttribute = attributes.find((attr) => attr.type === 'color')

    useEffect(() => {
        setTab(currentTab || 'base_cost')
    }, [currentTab])

    const tiktokShippingTabs = () => {
        const getTiktokWarehousesPricing = variants
            .filter((v) => v?.tiktok_warehouse_pricing)
            .map((v) => {
                const warehousePricing = v.tiktok_warehouse_pricing
                const keys = Object.keys(warehousePricing)
                return keys
                    .filter((key) => warehousePricing[key]?.length > 0)
                    .map((key) => {
                        const warehouses = warehousePricing[key].map((w) => w.warehouse)
                        return warehouses.map((warehouse) => {
                            return {
                                title: `${key}_${warehouse}`,
                                value: `${key}_${warehouse}`,
                                key,
                                warehouse,
                            }
                        })
                    })
            })

        const tiktokWarehousesPricing = getTiktokWarehousesPricing.flat(Infinity)
        const uniqueTiktokWarehousesPricing = tiktokWarehousesPricing.filter(
            (item, index) => tiktokWarehousesPricing.findIndex((i) => i.title === item.title) === index,
        )
        return uniqueTiktokWarehousesPricing.map((t) => ({
            ...t,
            title: standardTikTokInString(convertSneakCaseToTitle(t.title)),
        }))
    }

    return (
        <div className="CatalogVariants mt-4" id="catalogVariants">
            <div className="SectionInner">
                <h3 className="SectionHeading mb-3">Variants</h3>

                <Nav tabs>
                    {CATALOG_VARIANT_TABS.slice(0, 1)
                        .concat([
                            ...tiktokShippingTabs(),
                            ...CATALOG_VARIANT_TABS.slice(1, CATALOG_VARIANT_TABS.length - 1),
                        ])
                        .map((t) => {
                            const {title, value} = t
                            if (label !== 'branding' && value === 'related_product') return null
                            if (!hasColorAttribute && value === 'color_mapping') return null
                            return (
                                <NavItem key={value}>
                                    <NavLink
                                        className={tab === value ? 'active' : null}
                                        onClick={() => {
                                            changeQuerySearch({currentTab: value})
                                            props.resetFilter()
                                        }}
                                    >
                                        {title}
                                    </NavLink>
                                </NavItem>
                            )
                        })}
                </Nav>
                <TabContent activeTab={tab}>
                    <TabPane tabId="base_cost">
                        <BaseCostContainer tab={tab} {...props} />
                    </TabPane>

                    {tiktokShippingTabs().map((t) => {
                        return (
                            <TabPane key={t.value} tabId={t.value}>
                                <SellerShippingContainer warehouse={t.warehouse} shippingKey={t.key} {...props} />
                            </TabPane>
                        )
                    })}

                    <TabPane tabId="shipping">
                        <ShippingContainer tab={tab} {...props} />
                    </TabPane>
                    <TabPane tabId="tiktok_fee">
                        <TikTokFeeContainer tab={tab} {...props} />
                    </TabPane>
                    {label === 'branding' && (
                        <TabPane tabId="related_product">
                            <RelatedProductContainer tab={tab} {...props} />
                        </TabPane>
                    )}
                    <TabPane tabId="design">
                        <DesignContainer tab={tab} {...props} />
                    </TabPane>
                    <TabPane tabId="color_mapping">
                        <ColorMappingContainer {...props} />
                    </TabPane>
                </TabContent>
            </div>
        </div>
    )
}

export default CatalogVariantContainer
