import {SCOPE, SHIPPING_PLANS} from '../constants'

export const _mapOptions = (options, getBy = 'name') => {
    return options.map(o => {
        return {
            label: getBy === 'name' ? o.name : o.value,
            value: o.value
        }
    })
}

export const _mapStatesOptions = (states) => {
    return states.map(s => {
        return {
            label: s.name,
            value: s.code
        }
    })
}

export const _parseOptionValue = (options) => {
    if (!options) return null
    if (Array.isArray(options)) {
        return options
    }
    return {
        label: options,
        value: options
    }
}

export const _parseScopeValue = (option) => {
    const plan = SCOPE.find(p => p.value === option)
    return plan ? {
        label: plan.name,
        value: plan.value
    } : null
}

export const _parseShipmentValue = (option) => {
    const plan = SHIPPING_PLANS.find(p => p.value === option)
    return plan ? {
        label: plan.name,
        value: plan.value
    } : null
}
