import React, {Component} from 'react'
import DesignFormatContext from './design-format/context/DesignFormatContext'
import DesignFormatConst from './design-format/DesignFormatConst'

const {Provider: ProductsGalleryProvider} = DesignFormatContext

class ProductDesignFormatProvider extends Component {
    product = null

    state = {
        productId: '',
        designs: null,
        check: false,
        frameSize: 400
    }

    constructor() {
        super()
        this.layer = React.createRef()
    }


    _getDimensionInfo(designs, frameSize = null) {
        const frameDimension = frameSize || this.state.frameSize
        designs = designs.map((item) => {
            let {x, y, width, height, fontSize} = item
            x = this._getDimension(x, frameDimension)
            y = this._getDimension(y, frameDimension)
            width = this._getDimension(width, frameDimension)
            height = this._getDimension(height, frameDimension)
            fontSize = Math.round(this._getDimension(fontSize, frameDimension))
            item = {...item, x, y, width, height, fontSize}
            return item
        })

        return designs
    }

    _getDimension(dimension, frameSize = null) {
        if (dimension) {
            dimension =
                (dimension * (frameSize || this.state.frameSize)) / DesignFormatConst.STORE_DIMENSION
        }

        return dimension
    }

    _prettyDesignStates(designs) {
        designs.forEach((v, i) => {
            if (!v.sortId) v.sortId = 'id_' + Math.floor(Math.random() * 100000)
            v.index = i
        })
        return designs
    }

    render() {
        return (
            <ProductsGalleryProvider
                value={{
                    productId: this.state.productId,
                    designs: this.state.designs,
                    frameSize: this.state.frameSize,
                    layer: this.layer,
                    initConfig: (productId, product, data, frameSize) => {
                        this.product = product

                        const designs = this._prettyDesignStates(
                            this._getDimensionInfo(data.items, frameSize)
                        )
                        this.setState({
                            can_preview: data.enableEdit,
                            productId,
                            frameSize,
                            designs
                        })
                    },
                    enableEdit: (enable) => {
                        this.setState(
                            {
                                can_preview: enable
                            }
                        )
                    },
                    focusItem: (sortId, isCollapsed) => {

                    }
                }}
            >
                {this.props.children}
            </ProductsGalleryProvider>
        )
    }
}

export default ProductDesignFormatProvider
