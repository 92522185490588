import React, {Component} from 'react'
import StoresPage from './StoresPage'

class StoresPageContainer extends Component {
    render() {
        return (
            <div className='StoresPageContainer'>
                <StoresPage/>
            </div>
        )
    }
}

export default StoresPageContainer
