import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {makeRequest} from "../../../../services/api/DeploymentServices"
import changeQuerySearch from "../../../../helpers/routing/changeQuerySearch"

class StoreDeployTab extends Component {
    state = {
        loading: false,
        error: '',
        output: ''
    }

    componentDidMount() {
        this._deploy()
    }

    _deploy = async () => {
        if (this.state.loading) return false

        this.setState({
            loading: true,
        })

        const {store} = this.props
        const {backoffice_master_url, _id: storeId} = store
        const api = `${backoffice_master_url}/api/deployer/stores/${storeId}/activate`

        try {
            const {success, message} = await makeRequest({
                url: api,
                method: 'POST',
                timeout: 100 * 1000
            })

            if (!success) {
                throw new Error(message)
            }

            this.setState({
                loading: false,
                error: ''
            }, () => {
                changeQuerySearch({tab: 'status'})
            })
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message,
                output: ''
            })
        }
    }

    render() {
        const {error, loading} = this.state

        return (
            <div className="StoreDeployTab">
                <h3>
                    {!!loading ? 'Deploying...' : 'Result:'}
                </h3>

                {
                    !!error &&
                    <div className="ErrorText">
                        <div className="Text text-danger">
                            {error}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

StoreDeployTab.propTypes = {
    store: PropTypes.object.isRequired,
}

export default StoreDeployTab
