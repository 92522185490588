import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SettingInput from '../../setting/shared/SettingInput'

class CdnSettings extends Component {
    _onChangeSettingInput = (key) => (value) => {
        this.props.onChangeSetting(key, value)
    }

    render() {
        const {settings, disabled} = this.props

        return (
            <div className="CdnSetting">
                <SettingInput
                    itemId='product_images_cdn'
                    label="Product Images CDN"
                    value={settings.product_images_cdn}
                    onChange={this._onChangeSettingInput('product_images_cdn')}
                    disabled={disabled}
                />
                <SettingInput
                    itemId='cdn_prefix_image'
                    label="Seller’s Images CDN"
                    value={settings.cdn_prefix_image}
                    onChange={this._onChangeSettingInput('cdn_prefix_image')}
                    disabled={disabled}
                />
                <SettingInput
                    itemId='cdn_prefix_assets'
                    label="Assets CDN"
                    value={settings.cdn_prefix_assets}
                    onChange={this._onChangeSettingInput('cdn_prefix_assets')}
                    disabled={disabled}
                />
            </div>
        )
    }
}

CdnSettings.propTypes = {
    settings: PropTypes.object.isRequired,
    onChangeSetting: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
}

export default CdnSettings
