import React, {Component} from 'react'
import PropTypes from 'prop-types'
import hiddenReferrer from '../../../../helpers/common/hiddenReferrer'

class RegistryURLColumn extends Component {
    _formatURL = url => url.slice(0, 50)

    render() {
        const {item} = this.props
        const {registry_url} = item
        const formattedURL = this._formatURL(registry_url)
        const hiddenRef = hiddenReferrer(registry_url)

        return (
            <td className='HostColumn'>
                <a href={hiddenRef} target='_blank' rel='noopener noreferrer'>
                    {`${formattedURL}...`}
                </a>
            </td>
        )
    }
}

RegistryURLColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default RegistryURLColumn
