import React, {Component} from 'react'
import SettingInput from '../../setting/shared/SettingInput'
import PropTypes from 'prop-types'

class S3Image extends Component {
    _onChangeSettingInput = (key) => (value) => {
        this.props.onChangeSetting(key, value)
    }

    render() {
        const {settings, disabled} = this.props

        return (
            <div className="S3Image">
                <SettingInput
                    itemId='s3_image_bucket'
                    disabled={disabled}
                    label="S3 image bucket"
                    value={settings.s3_image_bucket}
                    onChange={this._onChangeSettingInput('s3_image_bucket')}
                />
                <SettingInput
                    itemId='s3_image_access_key'
                    disabled={disabled}
                    label="S3 image access key"
                    value={settings.s3_image_access_key}
                    onChange={this._onChangeSettingInput('s3_image_access_key')}
                />
                <SettingInput
                    itemId='s3_image_secret_key'
                    disabled={disabled}
                    label="S3 image secret key"
                    value={settings.s3_image_secret_key}
                    onChange={this._onChangeSettingInput('s3_image_secret_key')}
                />
            </div>
        )
    }
}

S3Image.propTypes = {
    settings: PropTypes.object.isRequired,
    onChangeSetting: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
}

export default S3Image
