import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Button, Spinner } from 'react-bootstrap'
import _ from 'lodash'
import {assign} from '../../../../services/api/ResourceService'

class AssignButton extends Component {
    state = {
        loading: false,
        err: ''
    }

    _onClickAssign = async () => {
        try {
            const { checking, resourceId, itemId, level, onUpdated } = this.props
            if(!checking){
                this.setState({ loading: true })
                const {success, message, data} = await assign(resourceId, { level, target_id: itemId})
                this.setState({loading: false, err: ''}, () => success && onUpdated(data))
                !success && alert(message)

            }
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false
            })
            alert(e.message || e)
        }
    }

    render() {
        const { itemId, assignStatus, checking } = this.props
        const { loading } = this.state

        return (
            <Button variant="primary" disabled={_.get(assignStatus, itemId, false)} onClick={this._onClickAssign}>
                {(checking || loading) &&  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />}
                {!checking && !loading && (_.get(assignStatus, itemId, false) ? 'assigned': 'assign')}
            </Button>
        )
    }
}

AssignButton.propTypes = {
    assignStatus: PropTypes.object.isRequired,
    resourceId: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    checking: PropTypes.bool.isRequired,
    onUpdated: PropTypes.func.isRequired,
}

export default AssignButton
