import createApiServices from './createApiServices'

const createAPIRequest = (baseUrl) => {
    if (!baseUrl) {
        const currentStore = Object.assign({}, window.currentStore)
        if (!currentStore) throw new Error(`Can't get current store`)
        const {backoffice_api_url} = currentStore
        baseUrl = backoffice_api_url + '/billing'
    } else {
        baseUrl += '/billing'
    }
    return createApiServices({baseUrl})
}

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/billing' : 'https://admin-staging.merchize.com/api/billing'
// const baseUrl = 'https://admin-staging.merchize.com/api/billing'
const apiBilling = createApiServices({baseUrl})

export const searchTransactionsOut = ({page = 1, limit = 10}) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/transactions-out/search`,
        method: 'POST',
        data: {page, limit},
    })
}

export const createTransactionsOut = (params) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/transactions-out',
        method: 'POST',
        data: params,
    })
}

export const cancelPendingTransactionOut = (id) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/transactions-out/${id}`,
        method: 'DELETE',
    })
}

export const searchDeposits = ({status = 'pending', page = 1, limit = 10}) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/deposits/search`,
        method: 'POST',
        data: {status, page, limit},
    })
}

export const approveDeposit = (payload) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/deposits/${payload._id}/approve`,
        method: 'POST',
        params: payload.param
    })
}

export const rejectDeposit = (depositId, data) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/deposits/${depositId}/reject`,
        method: 'POST',
        data,
    })
}

export const searchWithrawals = ({status, page = 1, limit = 10}) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/withdrawals/search`,
        method: 'POST',
        data: {page, limit, status},
    })
}

export const changeWithrawalApprove = ({id, transaction_id}) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/withdrawals/${id}/approved`,
        method: 'POST',
        data: {transaction_id},
    })
}

export const changeWithrawalReject = (id) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/withdrawals/${id}/rejected`,
        method: 'POST',
    })
}

export const getPayoutApproval = (params) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/v2/withdrawal/search',
        method: 'POST',
        data: params,
    })
}

export const getListCashbackRules = (params) => {
    return apiBilling.makeAuthRequest({
        url: `cashback-rules`,
        method: 'GET',
        params,
    })
}

export const getCashbackRuleDetail = (cashbackRuleId) => {
    return apiBilling.makeAuthRequest({
        url: `/cashback-rules/${cashbackRuleId}`,
        method: 'GET',
    })
}

export const createCashbackRule = (data) => {
    return apiBilling.makeAuthRequest({
        url: '/cashback-rules',
        method: 'POST',
        data,
    })
}

export const updateCashbackRule = (cashbackRuleId, data) => {
    return apiBilling.makeAuthRequest({
        url: `/cashback-rules/${cashbackRuleId}`,
        method: 'POST',
        data,
    })
}

export const getListCashbackRecall = (params) => {
    return apiBilling.makeAuthRequest({
        url: `/cashback-recalls`,
        method: 'GET',
        params,
    })
}

export const exportListRecall = (params) => {
    return apiBilling.makeAuthRequest({
        url: `/exports/cashback-recalls`,
        method: 'POST',
        params,
    })
}

export const bulkActionCashbackRecall = (data) => {
    return apiBilling.makeAuthRequest({
        url: `/bulk-cashback-recalls`,
        method: 'PUT',
        data,
    })
}

export const cashbackCalculate = (data) => {
    return apiBilling.makeAuthRequest({
        url: `/cashback-calculation-histories`,
        method: 'POST',
        data,
    })
}

export const getCashbackRuleHistory = (params) => {
    return apiBilling.makeAuthRequest({
        url: `/cashback-calculation-histories`,
        method: 'GET',
        params,
    })
}

export const actionCashbackRule = (action = 'confirm', cashbackId) => {
    return apiBilling.makeAuthRequest({
        url: `/cashback-calculation-histories/${action}/${cashbackId}`,
        method: 'PUT',
    })
}

export const searchGlobalDeposits = (data) => {
    return apiBilling.makeAuthRequest({
        url: `/deposits/global-search`,
        method: 'POST',
        data,
    })
}

export const approveGlobalDeposit = (baseUrl, payload) => {
    const api = createAPIRequest(baseUrl)
    return api.makeAuthRequest({
        url: `/deposits/${payload._id}/approve`,
        method: 'POST',
        params: payload.param,
    })
}

export const rejectGlobalDeposit = (baseUrl, depositId, data) => {
    const api = createAPIRequest(baseUrl)
    return api.makeAuthRequest({
        url: `/deposits/${depositId}/reject`,
        method: 'POST',
        data,
    })
}

export const addNote = (depositId, data) => {
    return apiBilling.makeAuthRequest({
        url: `/deposits/${depositId}/add-note`,
        method: 'POST',
        data,
    })
}

export const ignoreSendingEmail = (depositId, data) => {
    return apiBilling.makeAuthRequest({
        url: `/deposits/${depositId}/ignore-pending-mail`,
        method: 'POST',
        data
    })
}
