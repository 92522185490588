import React, {Component} from 'react'

class ZoneName extends Component {
    state = {
        name: this.props.name,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.name !== this.props.name) {
            this.setState({
                name: this.props.name,
            })
        }
    }

    _handleChangeInput = e => {
        const {value} = e.target

        this.setState({
            name: value,
        })

        this.props.onUpdateZoneData("name", value)
    }

    render() {
        const {name} = this.state

        return (
            <div className="ZoneName">
                <div className="SectionInner">
                    <h6 className="newZoneName">Zone name</h6>
                    <input type="text" name="name" className="form-control" onChange={this._handleChangeInput}
                           value={name} required/>
                    <div className="text-muted mt-1 fs-14">Customers won't see this.</div>
                </div>
            </div>
        )
    }
}

export default ZoneName
