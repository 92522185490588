import React from "react"

const ResourceKindFilterSelect = (props) => {

    const { onChange, value } = props

    const onChangeResourceKind = (e) => {
        const { value } = e.target
        onChange(value)
    }
    return (
        <div className='StorefrontStatusFilterSelect mr-3'>
            <p className="mb-0">Resource</p>
            <select name='resource_kind' onChange={onChangeResourceKind} value={value}>
                <option value=''>All kind</option>
                <option>mongodb</option>
                <option>efk</option>
                <option>alb</option>
                <option>fulfillment</option>
                <option>ebay</option>
            </select>
        </div>
    )
}

export default ResourceKindFilterSelect