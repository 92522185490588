import api from './manager'

export const getListAffiliates = (params) => {
    const { page, limit, query, type } = params
    let newParams = { page, limit };
    if (query) {
        if (type == '0') {
            newParams = { ...newParams, name: query.trim() }
        } else {
            newParams = { ...newParams, email: query.trim() }
        }
    }
    if (!newParams?.name) delete newParams.name
    if (!newParams?.email) delete newParams.email
    return api.makeAuthRequest({
        url: `/affiliates`,
        method: 'GET',
        params: newParams
    })
}

export const getAffiliateById = (id) => {
    return api.makeAuthRequest({
        url: `/affiliates/${id}`,
        method: 'GET',
    })
}

export const createAffiliate = (data) => {
    return api.makeAuthRequest({
        url: `/affiliates`,
        method: 'POST',
        data,
    })
}

export const deleteAffiliateById = (id) => {
    return api.makeAuthRequest({
        url: `/affiliates/${id}`,
        method: 'DELETE',
    })
}

export const genAffiliateLink = (data) => {
    return api.makeAuthRequest({
        url: `/affiliates/link`,
        method: 'POST',
        data,
    })
}

export const getAffiliatesProfit = (code, request) => {
    const params = {
        page: request.page,
        limit: request.limit
    }
    return api.makeAuthRequest({
        url: `affiliates/profit/${code}`,
        method: 'GET',
        params
    })
}

export const updateAffiliateStatus = (id) => {
    return api.makeAuthRequest({
        url: `affiliates/${id}`,
        method: 'PUT',
    })
}

export const createWithdrawal = (data) => {
    return api.makeAuthRequest({
        url: `affiliates/withdraw`,
        method: 'POST',
        data
    })
}

export const getAffiliateBalance = (code) => {
    return api.makeAuthRequest({
        url: `affiliates/balance/${code}`,
        method: 'GET'
    })
}

export const getAffiliateWithdrawal = (code, params) => {
    return api.makeAuthRequest({
        url: `affiliates/withdraws/${code}`,
        method: 'GET',
        params
    })
}