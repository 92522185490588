import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ObjectInspector from 'react-object-inspector'

class MetadataColumn extends Component {
    render() {
        const {item} = this.props
        const {metadata} = item

        return (
            <td className='HostColumn'>
                <span><ObjectInspector data={metadata || {}} /></span>
            </td>
        )
    }
}

MetadataColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default MetadataColumn
