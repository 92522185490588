import React, {Component} from 'react'
import {Tooltip} from 'reactstrap';
import DesignFormatConst from '../DesignFormatConst'

class DesignFormatText extends Component {
    _isMounted = false
    state = {
        disabled: true,
        fontStyle: {}
    }

    componentDidMount() {
        this._isMounted = true
    }

    onClick = (event) => {
        this.props.onClick(event)
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    _onTooltipText = () => {
        this.setState({
            showText: !this.state.showText
        })
    }

    _onTooltipLabel = () => {
        this.setState({
            showLabel: !this.state.showLabel
        })
    }

    showError(message, isFocus) {
        this.setState({
            item: {
                ...this.state.item,
            }
        }, () => {
            if (this.nameInput && isFocus) this.nameInput.focus()
        })
    }

    render() {
        const {showText, showLabel} = this.state
        const itemData = this.props.value

        return (
            <>
                <div className="mb-3 w-100 d-md-flex align-items-center align-items-center">
                    <div className="panel-title position-relative">
                        <div>
                            <label className="m-0">Label</label>
                            <i className="ti-info-alt ml-2 cursor-pointer" id="TooltipLabel"/>
                            <Tooltip placement="top" isOpen={showLabel} target="TooltipLabel" toggle={this._onTooltipLabel}>
                                {DesignFormatConst.MESSAGES_LABEL}
                            </Tooltip>
                        </div>
                    </div>
                    <div className="panel-input">
                        <input
                            ref={(input) => { this.nameInput = input }}
                            className={`form-control`}
                            placeholder="Your text here"
                            value={itemData.name || ''}
                            disabled={this.state.disabled}
                            required
                        />
                    </div>
                </div>
                <div className="mb-3 w-100 d-md-flex align-items-center">
                    <div className="panel-title position-relative">
                        <div>
                            <label className="m-0">Placeholder text</label>
                            <i className="ti-info-alt ml-2 cursor-pointer" id="TooltipText"/>
                            <Tooltip placement="top" isOpen={showText} target="TooltipText" toggle={this._onTooltipText}>
                                {DesignFormatConst.MESSAGES_PLACEHOLDER}
                            </Tooltip>
                        </div>
                    </div>
                    <div className="panel-input">
                        <textarea
                            className={`form-control panel-textarea`}
                            placeholder="Your text here"
                            value={itemData.default_value || ''}
                            disabled={this.state.disabled}
                        />
                    </div>
                </div>
                <div className="mb-3 w-100 d-md-flex align-items-center">
                    <div className="panel-title">
                        <label className="m-0">Font family</label>
                    </div>
                    <div className="panel-input">
                        <input
                            className="w-100 form-control"
                            value={itemData.fontFamily || ''}
                            disabled={this.state.disabled}
                            style={{...this.state.fontStyle}}
                        />
                    </div>
                </div>
                <div className="mb-3 w-100 d-md-flex align-items-center">
                    <div className="panel-title">
                        <label className="m-0">Text color</label>
                    </div>
                    <div className="panel-input">
                        <div className="form-control w-100 d-flex h-auto">
                            <input
                                className="input-color p-0"
                                type="color"
                                value={itemData.color || '#000000'}
                                disabled={this.state.disabled}
                            />
                            <span className="ml-2 text-color">
                                {itemData.color || '#000000'}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="mb-3 w-100 d-md-flex align-items-center">
                    <div className="panel-title">
                        <label className="m-0">Text size</label>
                    </div>
                    <div className="panel-input">
                        <input
                            className="w-100 form-control"
                            defaultValue={Math.round(itemData.fontSize || 0)}
                            disabled={this.state.disabled}
                        />
                    </div>
                </div>
                <div className="mb-3 w-100 d-md-flex align-items-center">
                    <div className="panel-title">
                        <label className="m-0">Text alignment</label>
                    </div>
                    <div className="panel-input">
                        <input
                            className="w-100 form-control"
                            defaultValue={itemData.align || ''}
                            disabled={this.state.disabled}
                        />
                    </div>
                </div>
                <div className="mb-3 w-100 d-md-flex align-items-center">
                    <div className="panel-title">
                        <label className="m-0">Max length</label>
                    </div>
                    <div className="panel-input">
                        <div
                            className={`d-flex align-items-center form-control w-100`}>
                            <label className="wrap-checkmark">
                                <input
                                    className="mr-1 input-checkbox custom-checkmark"
                                    type="checkbox"
                                    checked={itemData.enable_max_length || false}
                                    disabled={this.state.disabled}
                                />
                                <span className="checkmark"></span>
                            </label>
                            <input
                                className="form-control"
                                defaultValue={itemData.max_length || ''}
                                disabled={this.state.disabled}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default DesignFormatText
