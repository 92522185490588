import React, {Component} from 'react'
import PropTypes from 'prop-types'

class HostColumn extends Component {
    render() {
        const {item} = this.props
        const {host} = item
        return (
            <td className='HostColumn'>
                <span>{host}</span>
            </td>
        )
    }
}

HostColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default HostColumn
