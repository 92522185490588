import React from 'react'
import {randomID} from '../../../../../helpers/common/randomID'
import Select from 'react-select'
import {updateAllProductCatalogVariantMockup} from '../../../../../services/api/ProductLinesServices'
import {toaster} from '../../../../shared/PFToast'
import DesignVariantFilter from './DesignVariantFilter'

const DesignRow = (props) => {
    const {mockups, variants, attributes, filter, handleFilterVariants, toggle, tab, updateFilter} = props

    const _handleChange = async (option, variantId) => {
        try {
            const payload = {
                mockup: option.value,
                variant_ids: [variantId],
            }
            const {success, message} = await updateAllProductCatalogVariantMockup(payload)
            if (!success) throw new Error(message)
            toaster({
                type: 'success',
                message: 'Update variant template mockup successfully!',
            })
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    return (
        <tbody>
            <DesignVariantFilter
                tab={tab}
                attributes={attributes}
                filter={filter}
                handleFilterVariants={handleFilterVariants}
                mockups={mockups}
                variants={variants}
                updateFilter={updateFilter}
            />
            {!!variants.length &&
                variants.map((variant, index) => {
                    const {attributes, dimensions, mockup} = variant
                    const variantTitle = attributes.map((attr) => attr.value_text).join(' ')
                    return (
                        <tr key={`design_row_${index}`}>
                            {!!attributes.length &&
                                attributes.map((attribute) => {
                                    const _id = randomID()
                                    return <td key={_id}>{attribute.value_text}</td>
                                })}
                            <td>
                                <Select
                                    isMulti={false}
                                    cacheOptions
                                    options={mockups.map((mockup) => {
                                        return {
                                            label: mockup.title,
                                            value: mockup._id,
                                        }
                                    })}
                                    defaultValue={
                                        mockup
                                            ? {
                                                  label: mockup.title,
                                                  value: mockup._id,
                                              }
                                            : null
                                    }
                                    onChange={(e) => _handleChange(e, variant._id)}
                                    defaultOptions={true}
                                    placeholder="Select template mockup"
                                />
                            </td>
                            <td className="text-center">
                                <i
                                    className="text-primary cursor-pointer fas fa-eye"
                                    onClick={() => toggle(variant._id, dimensions, variantTitle)}
                                />
                            </td>
                        </tr>
                    )
                })}
        </tbody>
    )
}

export default DesignRow

