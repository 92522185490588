import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const TableRowAccountant = (props) => {

    const {accountant} = props
    const {account = {}, date = ''} = Object.assign({}, accountant)
    const {email = ''} = Object.assign({}, account)
    return (
        <td>
            <p className="mb-0">{email && email}</p>
            <span className="text-muted text-uppercase">{date && moment(date).format('hh:mm a DD/MM/YYYY')}</span>
        </td>
    )

}

TableRowAccountant.propTypes = {
    accountant: PropTypes.object,
}

export default TableRowAccountant
