import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {deleteResource} from '../../../services/api/ResourceService'

class DeleteModal extends Component {
    state = {
        loading: false,
    }

    _onToggle = () => {
        this.props.onClose()
    }

    _submitRemove = async () => {
        this.setState({
            loading: true
        })
        const {resource} = this.props
        try {
            const {message} = await deleteResource({ id: resource._id, kind: resource.kind})
            this.setState({
                loading: false,
            })
            if (message) return alert(message)
            return this.props.onOk()
        } catch (e) {
            this.setState({
                loading: false,
            })
            alert(e.message || e)
        }
    }

    render() {
        const {loading} = this.state
        const {open, resource} = this.props

        return (
            <Modal isOpen={open} toggle={this._onToggle} className="RemoveModal">
                <ModalHeader toggle={this._onToggle}>Remove item</ModalHeader>
                <ModalBody>Are you sure you want to remove <span className="font-weight-bold">{resource.name}?</span>
                </ModalBody>
                <ModalFooter>
                    <button disabled={loading} className="btn btn-primary" onClick={this._submitRemove}>OK</button>
                </ModalFooter>
            </Modal>
        )
    }
}

DeleteModal.propTypes = {
    open: PropTypes.bool.isRequired,
    resource: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
}

export default DeleteModal
