import React, {Component} from 'react'
import PropTypes from 'prop-types'
import hiddenReferrer from '../../../../../helpers/common/hiddenReferrer'

class DomainColumn extends Component {
    render() {
        const {item} = this.props
        const {domain, namespace} = item
        const href = `https://${domain.trim()}`
        const hiddenRef = hiddenReferrer(href)

        return (
            <td className='DomainColumn'>
                <a href={hiddenRef} target='_blank' rel='noopener noreferrer'>
                    {domain || ''}
                    <i className='ti-new-window ml-2'/>
                </a>
                <div>
                    <code>{namespace}</code>
                </div>
            </td>
        )
    }
}

DomainColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default DomainColumn
