import {createStoreAPI} from './createStoreAPIService'

const api = createStoreAPI({suffix: '/stores'})

export const getDnsRecord = (storeId = '') =>
    api.makeAuthRequest({
        url: `/${storeId}/get-dr`,
        method: 'GET',
    })

export const createDnsRecord = (storeId = '') =>
    api.makeAuthRequest({
        url: `/${storeId}/create-dr`,
        method: 'POST',
    })
