import React from 'react'
import info_danger from '../../../../statics/assets/icons/info_danger.svg'
import PropTypes from 'prop-types'

const NotificationValid = (props) => {
    const {content} = props

    if(!content) return null

    return(
        <div className='NotificationValid fs-14 text-muted mt-1 d-flex aligin-items-center'>
            <img className='mr-1' src={info_danger} alt='info'/><span dangerouslySetInnerHTML={{__html: content}} />
        </div>
    )
}

NotificationValid.defaultProps = {
    content: '',
}

NotificationValid.propTypes = {
    content: PropTypes.string.isRequired,
}

export default NotificationValid
