import React, {Component} from 'react'
import TableHeader from './TableHeader'
import TableBody from './TableBody'

class TableContainer extends Component {
    render() {
        const {items} = this.props
        const noResult = items.length === 0 || typeof items === "undefined"

        return (
            <div className='TableContainer table-responsive TableImportLogs'>
                <table className='table table-striped table-light'>
                    <TableHeader />
                    <TableBody {...this.props} />
                </table>
                {noResult === true && (
                    <div className='EmptyTable d-flex justify-content-center'>
                        <span className='text-muted'>No results</span>
                    </div>
                )}
            </div>
        )
    }
}

export default TableContainer
