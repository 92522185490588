import React, {Component} from 'react'
import PropTypes from 'prop-types'
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'
import {DateRangePicker} from 'react-dates'
import moment from 'moment'
import classNames from 'classnames'

class DateCustomFilter extends Component {
    constructor(props) {
        super(props)

        const {query} = this.props
        const {startDate, endDate} = query
        const parseStartDate = moment(startDate)
        const parseToDate = moment(endDate)
        this.state = {
            focusedInput: null,
            from: parseStartDate.isValid() ? parseStartDate : null,
            to: parseToDate.isValid() ? parseToDate : null,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {query} = this.props
        const {startDate, endDate, hasReset = false} = query
        const parseStartDate = startDate ? moment(startDate) : null
        const parseToDate = endDate ? moment(endDate) : null
        if (hasReset) {
            if (!startDate && !endDate) {
                if (prevState.from !== null || prevState.to !== null) {
                    this.setState({
                        focusedInput: null,
                        from: null,
                        to: null,
                    })
                }
            }
        } else {
            if (
                parseStartDate &&
                parseToDate &&
                !(parseStartDate.isSame(prevState.from) && parseToDate.isSame(prevState.to))
            ) {
                this.setState((prevState) => ({
                    ...prevState,
                    from: parseStartDate.isValid() ? parseStartDate : null,
                    to: parseToDate.isValid() ? parseToDate : null,
                }))
            }
        }
    }

    _reset = () => {
        this.setState({
            focusedInput: null,
            from: null,
            to: null,
        })
    }

    _handleToggle = (e) => {
        e.preventDefault()
        this.setState(({focusedInput}) => ({
            focusedInput: focusedInput ? null : 'startDate',
        }))
    }

    _onFocusChange = (focusedInput) => {
        this.setState({
            focusedInput,
        })
    }

    _handleDatesChange = ({startDate, endDate}) => {
        this.setState(
            {
                from: startDate,
                to: endDate,
            },
            () => {
                this._handleAfterDatesChange()
            }
        )
    }

    _handleAfterDatesChange = () => {
        const {from, to} = this.state
        const result = {}

        if (from && from.format) {
            result.from = from.format('MM/DD/YYYY')
        }

        if (to && to.format) {
            result.to = to.format('MM/DD/YYYY')
        }

        this.props.onChange(result)
    }

    _isOutsideRangeDate = (date) => {
        const {outsideRange = '2018-11-05'} = this.props
        const tomorrow = moment().add(1, 'day')
        const startDate = moment(outsideRange, 'YYYY-MM-DD')

        if (startDate.isAfter(date)) return true

        return !date.isBefore(tomorrow) || date.isSame(tomorrow, 'day')
    }

    _isActive = () => {
        const {from, to} = this.state
        return from || to
    }

    render() {
        const {focusedInput, from, to} = this.state
        const {placeholderDateFilter, disabled} = this.props
        const isOpenEditor = !!focusedInput
        const isActive = this._isActive()

        return (
            <div className={classNames('DateCustomFilter flex-nowrap d-flex', {isOpenEditor, active: isActive})}>
                <span className="d-inline-flex align-items-center mr-3 cursor-pointer">
                    {placeholderDateFilter ? placeholderDateFilter : 'Paid at'}
                </span>

                <DateRangePicker
                    small
                    noBorder
                    showClearDates
                    enableOutsideDays
                    reopenPickerOnClearDates
                    minimumNights={0}
                    numberOfMonths={2}
                    isOutsideRange={this._isOutsideRangeDate}
                    startDate={from}
                    startDateId="_date_filter_from"
                    endDate={to}
                    disabled={disabled}
                    endDateId="_date_filter_to"
                    onDatesChange={this._handleDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={this._onFocusChange}
                    displayFormat="DD/MM/YYYY"
                />
            </div>
        )
    }
}

DateCustomFilter.propTypes = {
    query: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    outsideRange: PropTypes.string,
}

export default DateCustomFilter

