import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import humanizeTime from '../../../../helpers/time/humanizeTime'

class UpdatedColumn extends Component {
    _validateDate = time => {
        if (!time) return ''
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }

    render() {
        const {item} = this.props
        const {updated} = item
        const validDate = this._validateDate(updated)
        return (
            <td className='UpdatedColumn'>
                <span>{validDate}</span>
            </td>
        )
    }
}

UpdatedColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default UpdatedColumn
