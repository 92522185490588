import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'


class WithdrawalsButton extends Component {

    render() {
        const {store} = this.props
        if (!store.hasOwnProperty('_id')) return null
        const {_id} = store

        return (
            <div className='WithdrawalsButton'>
                <Link
                    to={{
                        pathname: `/a/stores/${_id}/withdrawals`,
                        state: {store},
                    }}
                    className='ml-3'
                >
                    <button className='btn btn-secondary'>Withdrawals</button>
                </Link>
            </div>
        )
    }
}

WithdrawalsButton.propTypes = {
    store: PropTypes.object.isRequired,
}

export default WithdrawalsButton
