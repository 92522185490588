import React from 'react'

const CatalogPrivateBaseCostFilter = (props) => {
    const {attributes, handleFilterVariants, filter, valueAttributes, listStores, isAdd} = props

    const _onFilterVariant = (e) => {
        const {name, value} = e.target
        const newFilter = {...filter}
        if (value) {
            newFilter[name] = value
        } else {
            delete newFilter[name]
        }
        handleFilterVariants(newFilter)
    }

    return (
        <tr className="ProductVariantsRow">
            {attributes.map((attribute) => {
                const {type, name} = attribute
                return (
                    <td key={`filterAttribute${type}`}>
                        <select name={name} value={filter[name] || ''} className="form-control" onChange={_onFilterVariant}>
                            <option value="">All</option>
                            {valueAttributes.filter(item => item.name === name).map((v) => {
                                return (
                                    <option key={v.value_code} value={v.value_code}>
                                        {v.value_text}
                                    </option>
                                )
                            })}
                        </select>
                    </td>
                )
            })}
            {listStores.map((item, index) => {
                const actionType = item?.action_type || ''
                if(actionType !== 'delete' && item.store?._id !== 'default_id') {
                    return <td key={`filterColumnPriceEmpty${index}`}/>
                } else {
                    return null
                }

            })}
            {!isAdd && <td/>}
        </tr>
    )
}

export default CatalogPrivateBaseCostFilter

