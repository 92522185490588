import React from 'react'
import SettingMailPage from './SettingMailPage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import {isSuperAdmin} from '../../../services/AuthServices'

const SettingMailContainer = () => {

    const checkRole = isSuperAdmin()

    if (!checkRole) return null

    return (
        <div className="SettingMail">
            <DocTitleByStore title="Mail settings"/>
            <SettingMailPage/>
        </div>
    )
}

export default SettingMailContainer
