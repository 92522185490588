import React, {Component} from 'react'
import FeaturesModal from './FeaturesModal'
import PlanModal from './PlanModal'
import {getFeatures, getPlans} from '../../../services/api/PlanServices'
import separateThousands from '../../../helpers/common/thousandSeparateNumber'
import RemoveModal from './RemoveModal'

class PricingPage extends Component {
    state = {
        modal: {
            plan: {
                open: false,
                plan: {},
            },
            feature: {
                open: false,
            },
            remove: {
                open: false,
                plan: {},
            }
        },
        plans: {
            entity: [],
            loading: false,
        },
        features: {
            entity: [],
        },
    }

    componentDidMount() {
        this._fetchFeatures()
        this._fetchPlans()
    }

    _fetchFeatures = async () => {
        try {
            const {success, data, message} = await getFeatures()
            if (success) return this.setState(({features}) => ({
                features: {
                    ...features,
                    entity: data,
                },
            }))

            alert(message)
        } catch (e) {
            alert(e.message || e)
        }
    }

    _fetchPlans = async () => {
        const {plans} = this.state
        this.setState({
            plans: {
                ...plans,
                loading: true,
            }
        })

        try {
            const {success, data, message} = await getPlans()
            const updatedPlans = (success) ? {...plans, entity: data, loading: false} : {...plans, loading: false}
            this.setState({plans: updatedPlans})
            if (message) return alert(message)
        } catch (e) {
            alert(e.message || e)
        }
    }

    _onClickPlan = (plan) => () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                plan: {
                    open: true,
                    plan: {...plan},
                }
            }
        }))
    }

    _onClickAddNewPlan = () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                plan: {
                    open: true,
                    plan: {},
                }
            }
        }))
    }

    _onClickFeatures = () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                feature: {
                    open: true,
                }
            }
        }))
    }

    _onCloseModal = () => {
        this.setState(({modal}) => ({
            modal: {
                plan: {...modal.plan, open: false, plan: {}},
                feature: {...modal.feature, open: false},
                remove: {...modal.remove, open: false, plan: {}}
            }
        }))
    }

    _addNewPlan = (newPlan) => {
        this.setState(({plans, modal}) => ({
            plans: {
                ...plans,
                entity: [...plans.entity, newPlan],
            },
            modal: {
                plan: {...modal.plan, open: false, plan: {}},
                feature: {...modal.feature, open: false},
                remove: {...modal.remove, open: false, plan: {}}
            }
        }))
    }

    _onChangeFeature = (data) => {
        this.setState(({features, modal}) => ({
            features: {
                ...features,
                entity: data,
            },
        }))
    }

    _onClickRemove = (plan) => () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                remove: {
                    open: true,
                    plan: {...plan},
                }
            }
        }))
    }

    _onRemovePlan = () => {
        const {modal, plans} = this.state
        const {entity} = plans
        const {plan} = modal.remove
        const newPlans = entity.filter((item) => item._id !== plan._id)
        const remove = {plan: {}, open: false}
        this.setState({
            plans: {
                ...plans,
                entity: newPlans,
            },
            modal: {
                ...modal,
                remove,
            }
        })
    }

    _changePlan = () => {
        const {modal} = this.state
        this._fetchPlans()
        this.setState({
            modal: {
                plan: {...modal.plan, open: false, plan: {}},
                feature: {...modal.feature, open: false},
                remove: {...modal.remove, open: false, plan: {}}
            }
        })
    }

    render() {
        const {feature, plan, remove} = this.state.modal
        const {plans, features} = this.state

        return (
            <div className="PricingPage">
                <div className="TopMenuButtons">
                    <button className="btn btn-primary" onClick={this._onClickAddNewPlan}>Add new plan</button>
                    <button className="btn btn-primary" onClick={this._onClickFeatures}>Features</button>
                </div>
                <div className="PricingList">
                    {plans.entity.map((plan) => <div className="SectionInner PlanWrapper" key={plan._id}>
                        <button className="ViewButton btn btn-danger" onClick={this._onClickRemove(plan)}>
                            <span className="ti-trash mr-1"/>
                            Delete
                        </button>
                        <button className="ViewButton btn btn-primary mr-3" onClick={this._onClickPlan(plan)}>
                            <span className="ti-eye mr-1"/>
                            View
                        </button>
                        <div
                            className="font-weight-bold">{plan.title} - {plan.price} {plan.currency}/{plan.duration_unit}</div>
                        <div>Transaction rate: {+(plan.transaction_rate) * 100}%</div>
                        <div>Max product entity: {separateThousands(plan.max_products || 0)}</div>
                    </div>)}
                </div>

                <FeaturesModal
                    features={features.entity} onChangeFeature={this._onChangeFeature}
                    open={feature.open} onClose={this._onCloseModal}/>
                <PlanModal
                    features={features.entity} onChangeFeature={this._onChangeFeature} onAddPlan={this._addNewPlan}
                    open={plan.open} plan={plan.plan} onClose={this._onCloseModal} onChangePlan={this._changePlan}/>
                <RemoveModal open={remove.open} plan={remove.plan} onClose={this._onCloseModal}
                             onOk={this._onRemovePlan}/>
            </div>
        )
    }
}

export default PricingPage
