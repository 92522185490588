import React, {Component} from 'react'
import {
    getShippingRateDetails,
    updateShippingRate,
} from '../../../../services/api/ShippingSettingsServices'
import RateTitleContainer from './title/RateTitleContainer'
import WeightRulesContainer from './weight-rules/WeightRulesContainer'
import PriceRulesContainer from './price-rules/PriceRulesContainer'
import RatePreferContainer from './prefer/RatePreferContainer'
import ManageRatesActionsContainer from './actions/ManageRatesActionsContainer'
import PerItemsRuleContainer from './per-items/PerItemsRuleContainer'
import getHistory from '../../../../store/getHistory'
import ReturnPage from '../../../shared/ReturnPage'
import RatePlanContainer from './plan/RatePlanContainer'
import PropTypes from 'prop-types'

const _errorMessage = {
    weight_based: 'Each weight rule must have unique "From" value.',
    price_based: 'Each price rule must have unique "From" value.',
}

class RateEditPage extends Component {
    state = {
        loading: false,
        error: '',
    }

    _rateData = {
        title: '',
        subtitle: '',
        fulfillment_plan: 'standard',
        prefer: 'max',
        rules: [],
    }

    _zone = {}

    componentDidMount() {
        this._fetchRateDetails()
    }

    _fetchRateDetails = async () => {
        this.setState({
            loading: true,
        })

        const {zoneId, rateId} = this.props

        try {
            const {data, success, message} = await getShippingRateDetails(zoneId, rateId)

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                })
            }

            const {title, subtitle, rules, prefer, zone, fulfillment_plan} = data

            const formatRules = rules.map(rule => {
                const {_id, items} = rule

                const formatItems = items.map(item => {
                    const {data} = item
                    const {first_item = '', additional_item = '', from = '', cost = ''} = data

                    if (first_item === '') {
                        return {
                            _id: item._id,
                            from,
                            cost,
                        }
                    }

                    return {
                        _id: item._id,
                        first_item,
                        additional_item,
                    }
                })

                return {
                    type: _id,
                    items: formatItems,
                }
            })

            this._rateData = {
                ...this._rateData,
                title,
                subtitle,
                fulfillment_plan,
                rules: formatRules,
                prefer,
            }

            this._zone = zone

            return this.setState({
                loading: false,
                error: '',
            })
        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            this.setState({
                error: message ? message : e.message,
                loading: false,
            })
        }
    }

    _handleUpdateRatesData = (field, data) => {
        this._rateData[field] = data
    }

    _handleUpdateRateRules = (type, data) => {
        const findRule = this._rateData.rules.find(rule => rule.type === type)

        if (findRule === undefined) {
            this._rateData.rules = [...this._rateData.rules, {type: type, items: data}]
        } else {
            this._rateData.rules = this._rateData.rules.map(rule => {
                if (rule.type === type) {
                    return {
                        ...rule,
                        items: data,
                    }
                }

                return rule
            })
        }
    }

    _handSubmitForm = e => {
        e.preventDefault()

        this._updateShippingRate()
    }

    _getRateData = () => {
        return this._rateData
    }

    _handCheckDuplicateRule = (data) => {
        const {rules} = data

        for (let i = 0; i < rules.length; i++) {
            const {items, type} = rules[i]

            const fromValue = items.map(item => item.from)
            const isDuplicate = fromValue.some((item, idx) => {
                return fromValue.indexOf(item, idx + 1) !== -1
            })

            if (isDuplicate) {
                this.setState({
                    error: _errorMessage[type],
                }, () => {
                    window.scrollTo(0, 0)
                })

                return true
            }
        }

        return false
    }

    _updateShippingRate = async () => {
        const {zoneId, rateId, profilesId} = this.props

        const {rules} = this._rateData
        const filteredRulers = rules.filter(rule => rule.items.length > 0)

        const sendData = {
            ...this._rateData,
            rules: filteredRulers,
        }

        const isDuplicate = this._handCheckDuplicateRule(sendData)

        if (isDuplicate) {
            return
        }

        this.setState({
            loading: true,
        })

        try {
            const {success, message} = await updateShippingRate(zoneId, rateId, sendData)

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                }, () => {
                    window.scrollTo(0, 0)
                })
            }

            const history = getHistory()
            history.push(`/a/settings/settings-shipping/${profilesId}/zones-edit/${zoneId}`)

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            return this.setState({
                loading: false,
                error: message ? message : e.message,
            }, () => {
                window.scrollTo(0, 0)
            })
        }
    }

    render() {
        const {zoneId, rateId, profilesId} = this.props
        const {title, subtitle, prefer, rules, fulfillment_plan} = this._rateData
        const {name: zoneName = ''} = this._zone

        const weightTable = rules.find(rule => rule.type === 'weight_based') || {type: 'weight_based', items: []}
        const priceTable = rules.find(rule => rule.type === 'price_based') || {type: 'price_based', items: []}
        const perItemRule = rules.find(rule => rule.type === 'item_based') || {type: 'item_based', items: []}

        const {loading, error} = this.state

        return (
            <div className="RateEditPage">
                <ReturnPage url={`/a/settings/settings-shipping/${profilesId}/zones-edit/${zoneId}`}
                            title={`Zone: ${zoneName}`}/>

                <div className="d-flex align-items-center justify-content-between mb-3">
                    <h1 className="PageTitle mb-0">{title}</h1>
                </div>

                {
                    error &&
                    <div className="ErrorMessage p-3 mb-3">
                        {error}
                    </div>
                }

                <form onSubmit={this._handSubmitForm}>
                    <div className="mb-4">
                        <RateTitleContainer title={title} subtitle={subtitle}
                                            onUpdateRatesData={this._handleUpdateRatesData}/>
                    </div>

                    <RatePlanContainer fulfillmentPlan={fulfillment_plan}
                                       onUpdateRatesData={this._handleUpdateRatesData}/>

                    <div className="mb-4">
                        <WeightRulesContainer weightTable={weightTable.items}
                                              onUpdateRateRules={this._handleUpdateRateRules}/>
                    </div>

                    <div className="mb-4">
                        <PriceRulesContainer priceTable={priceTable.items}
                                             onUpdateRateRules={this._handleUpdateRateRules}/>
                    </div>

                    <div className="mb-4">
                        <PerItemsRuleContainer perItemRule={perItemRule.items}
                                               onUpdateRateRules={this._handleUpdateRateRules}/>
                    </div>

                    <RatePreferContainer prefer={prefer} onUpdateRatesData={this._handleUpdateRatesData}/>

                    <div className="mt-4">
                        <ManageRatesActionsContainer zoneId={zoneId} loading={loading} rateId={rateId}
                                                     title={title}
                                                     onGetRateData={this._getRateData}/>
                    </div>

                </form>
            </div>
        )
    }
}

// RateEditPage.defaultProps = {}

RateEditPage.propTypes = {
    zoneId: PropTypes.string.isRequired,
    rateId: PropTypes.string.isRequired,
    profilesId: PropTypes.string.isRequired,
}

export default RateEditPage
