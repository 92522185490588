import React, {Component} from 'react'
import {confirmModal} from '../../../shared/PFConfirmModalV2'
import {deleteAffiliateCategories, getUsageStatusOfAffiliateCategory} from '../../../../services/api/ProductLinesServices'
import {toaster} from '../../../shared/PFToast'

class DeleteAffiliateCategory extends Component {

    _handleClickRemove = async(e) => {
        e.preventDefault()
        const {id} = this.props
        try {
            const {success, data, message} = await getUsageStatusOfAffiliateCategory(id)

            if (!success) throw new Error(message)

            this._showConfirmDialog(!data)
        } catch (err) {
            toaster({
                type: 'error',
                message: err.message,
                duration: 5000
            })
        }
    }

    _showConfirmDialog = (canRemove) => {
        const {name} = this.props

        if (canRemove) {
            confirmModal({
                confirmText: 'OK',
                confirmColor: 'danger',
                title: 'Remove category',
                message: <span>Would you like to remove category <b>{name}</b>.</span>,
                onOk: this._handleRemoveAffiliate
            })
        } else {
            confirmModal({
                confirmText: 'OK',
                cancelText: '',
                confirmColor: 'danger',
                title: 'Remove category',
                message: <span>This affiliate category is being used by some product lines. Can not remove.</span>,
                // onOk: () => {}
            })
        }
    }

    _handleRemoveAffiliate = async () => {

        const {id, name, onGetAffiliateCategories} = this.props

        try {
            const {success, message} = await deleteAffiliateCategories(id)

            if (!success) throw new Error(message)

            toaster({
                type: 'success',
                message: `Deleted affiliate ${name} category successfully!`,
                duration: 5000
            })

            onGetAffiliateCategories()

        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
                duration: 5000
            })
        }
    }

    render() {
        return (
            <div className="DeleteAffiliateCategory">
                <button onClick={this._handleClickRemove} className="ViewButton btn btn-sm btn-danger fs-14">
                    <span className="ti-trash mr-1"/>
                    Delete
                </button>
            </div>
        )
    }
}

DeleteAffiliateCategory.propTypes = {}

export default DeleteAffiliateCategory
