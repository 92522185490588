import React, {Component} from 'react'

class CatalogImages extends Component {
    render() {
        const {thumbnail} = this.props

        return (
            <div className="CatalogImages">
                <div className="SectionInner">
                    <div className="ProductImage text-center">
                        {thumbnail ? (
                            <div className="ProductImagesItem border">
                                <div
                                    className="ImageWrapper"
                                    style={{backgroundImage: `url("${thumbnail}")`, backgroundSize: 'contain'}}
                                >
                                    <img className="ProductImageView" src={thumbnail} alt="" width="400" height="400" />
                                </div>
                            </div>
                        ) : (
                            <span className="text-muted">There is no thumbnail</span>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default CatalogImages

