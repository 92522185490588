import React, {Component} from 'react'

// import PropTypes from 'prop-types'

class WeightRulesActions extends Component {
    _handleClickRemove = () => {
        const {rowIndex, onRemoveRow} = this.props

        onRemoveRow(rowIndex)
    }

    render() {
        const {rowIndex} = this.props

        return (
            <td className="WeightRulesActions">
                {
                    rowIndex > 0 && <span className="text-danger cursor-pointer Remove" onClick={this._handleClickRemove}>Remove</span>
                }
            </td>
        )
    }
}

// WeightRulesActions.defaultProps = {}

// WeightRulesActions.propTypes = {}

export default WeightRulesActions
