import React, {Component} from 'react'

class RequestDeposit extends Component {

    render() {
        const {deposit} = this.props
        const {requested_by} = deposit
        const requestedBy = requested_by?.email || '-'

        return (
            <td>{requestedBy}</td>
        )
    }
}


export default RequestDeposit