import React, {Component} from 'react'
import PropTypes from 'prop-types'

class CommitMessageColumn extends Component {
    render() {
        const {item} = this.props
        const {commit_message} = item
        return (
            <td className='BranchColumn'>
                <span>{commit_message}</span>
            </td>
        )
    }
}
CommitMessageColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default CommitMessageColumn
