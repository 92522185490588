import React, {Component} from 'react'
import * as AuthService from "../../../services/AuthServices"
import {setCurrentStore} from "../../../services/CurrentStoreService"
import getHistory from "../../../store/getHistory"

class LogoutPage extends Component {
    componentDidMount() {
        this._logout()
    }

    _logout = () => {
        AuthService.logoutUser()
        setCurrentStore('', [])

        const history = getHistory()
        history.push('/')
    }

    render() {
        return (
            <div className="LogoutPage">
                <p>Logging out...</p>
            </div>
        )
    }
}

export default LogoutPage
