import createAPIServices from './createApiServices'

const baseUrl =
    process.env.NODE_ENV === 'production'
        ? '/api/statistic/metrics'
        : 'https://dev2-manager.merch8.com/api/statistic/metrics'

const api = createAPIServices({baseUrl})

export const getMetricAllStores = ({timeRange}) =>
    api.makeAuthRequest({
        url: '',
        method: 'POST',
        data: {
            timeRange,
        },
    })

export const getMetricStore = ({timeRange = '', slug = ''}) =>
    api.makeAuthRequest({
        url: `/${slug}`,
        method: 'POST',
        data: {
            timeRange,
        },
    })
