import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Input from '../shared/Input'
import TextEditor from '../../shared/TextEditor'

const TOOLBAR_CONFIGS = {
    options: ['inline', 'textAlign', 'list', 'link', 'image', 'blockType', 'fontSize'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
        bold: {
            className: 'EditorToolbarButton',
        },
        italic: {
            className: 'EditorToolbarButton',
        },
        underline: {
            className: 'EditorToolbarButton',
        },
        strikethrough: {
            className: 'EditorToolbarButton',
        },
    },
    textAlign: {
        inDropdown: false,
        options: ['left', 'center', 'right', 'justify'],
        left: {className: 'EditorToolbarButton'},
        center: {className: 'EditorToolbarButton'},
        right: {className: 'EditorToolbarButton'},
        justify: {className: 'EditorToolbarButton'},
    },
    list: {
        options: ['unordered', 'ordered'],
        unordered: {
            className: 'EditorToolbarButton',
        },
        ordered: {
            className: 'EditorToolbarButton',
        },
    },
    link: {
        options: ['link', 'unlink'],
        link: {
            className: 'EditorToolbarButton',
        },
        unlink: {
            className: 'EditorToolbarButton',
        },
    },
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H4', 'H5', 'H6'],
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30],
    },
    image: {
        className: 'EditorToolbarButton',
    },
}

class GeneralEdit extends Component {
    _changeInput = property => value => {
        this.props.onChange(property, value)
    }

    render() {
        const {product, editable} = this.props
        const {description = ''} = product

        return (
            <div className='GeneralEdit ProductApprovalGeneralEdit'>
                <div className='SectionInner'>
                    <Input label='Title' value={product.title} onChange={this._changeInput('title')}
                           disabled={!editable}/>
                    <div>
                        <label className="font-weight-500">Description</label>
                        <div dangerouslySetInnerHTML={{__html: description}}
                             style={{
                                 background: '#FAFBFA',
                                 border: '1px solid #E9EEEC',
                                 boxSizing: 'border-box',
                                 borderRadius: '4px',
                                 minHeight: '240px',
                                 overflow: 'auto',
                                 padding: '0.375rem 0.75rem',
                             }}/>
                    </div>
                </div>
            </div>
        )
    }
}

GeneralEdit.propTypes = {
    product: PropTypes.object.isRequired,
    editable: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default GeneralEdit
