import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import CustomTimeRangeFilter from './CustomTimeRangeFilter'

const TimeRangeList = [
    {
        name: 'Today',
        value: 'today',
        type: 'quick',
    },
    {
        name: 'Yesterday',
        value: 'yesterday',
        type: 'quick',
    },
    {
        name: 'Last 7 days',
        value: '7-day-ago',
        type: 'relative',
    },
    {
        name: 'This week',
        value: 'this-week',
        type: 'quick',
    },
    {
        name: 'This month',
        value: 'this-month',
        type: 'quick',
    },
    {
        name: 'All time',
        value: 'all-time',
        type: 'quick',
    },
]
class TimeRangeFilter extends Component {
    _handleClick = (type, value) => () => {
        this.props.handleTimeRangeChange(type, value)
    }

    render() {
        const {type, value, handleTimeRangeChange} = this.props
        return (
            <div className='TimeRangeFilter LabelFilter'>
                <span>Date filter</span>
                <ul className='FilterItems' role='tablist'>
                    {TimeRangeList.map(item => {
                        const {value: itemValue, type: itemType} = item
                        const active = itemValue === value
                        return (
                            <li
                                value={item.value}
                                key={item.name}
                                className={classNames({active})}
                                onClick={this._handleClick(itemType, itemValue)}>
                                <span className='nav-link'>{item.name}</span>
                            </li>
                        )
                    })}
                    <CustomTimeRangeFilter type={type} handleTimeRangeChange={handleTimeRangeChange} />
                </ul>
            </div>
        )
    }
}
TimeRangeFilter.propTypes = {
    handleTimeRangeChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
}

export default TimeRangeFilter
