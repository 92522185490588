import React, {Component} from 'react'
import DocTitleByStore from '../../../dashboard/shared/DocTitleByStore'
import BalanceReportPage from './BalanceReportPage'
import getEnv from '../../../../helpers/common/getEnv'

class BalanceReportContainer extends Component {

    render() {

        const isSupportMpMode = getEnv('isEnableMpMode')
        if (isSupportMpMode) return null

        return (
            <div className="ReportAccountingContainer">
                <DocTitleByStore title="All Store Report"/>
                <BalanceReportPage/>
            </div>
        )
    }
}

export default BalanceReportContainer
