import api from './manager'

export const getListAccounts = ({page = 1, limit = 10, email = ''}) => {
    return api.makeAuthRequest({
        url: '/accounts',
        method: 'GET',
        params: {
            page,
            limit,
            email
        }
    })
}

export const getAccount = ({email = ''}) => {
    return api.makeAuthRequest({
        url: '/accounts/by-email',
        method: 'GET',
        params: {
            email
        }
    })
}

export const getAccountDetail = (accountId = '') => {
    return api.makeAuthRequest({
        url: `/accounts/${accountId}`,
        method: 'GET'
    })
}

export const updateAccountRoles = ({accountId = '', roles = []}) => {
    return api.makeAuthRequest({
        url: `/accounts/${accountId}/roles`,
        method: 'POST',
        data: {
            roles
        }
    })
}

export const getStoresByAccount = (accountId = '') => {
    return api.makeAuthRequest({
        url: `/accounts/${accountId}/stores`,
        method: 'GET',
    })
}


export const exportEmailAccount = (params) => {
    return api.makeAuthRequest({
        url: '/accounts/export-emails',
        method: 'GET',
        params
    })
}

export const exportEmailAccountExclude = () => {
    return api.makeAuthRequest({
        url: '/accounts/export-emails/exclude-admin',
        method: 'GET',
    })
}
