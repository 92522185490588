import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AccountDetailPage from './AccountDetailPage'

class AccountDetailPageContainer extends Component {
    render() {
        const {match} = this.props
        const accountId = match.params.id
        return (
            <div className='AccountDetailPageContainer'>
                <AccountDetailPage accountId={accountId} />
            </div>
        )
    }
}

AccountDetailPageContainer.propTypes = {
    match: PropTypes.object.isRequired,
}

export default AccountDetailPageContainer
