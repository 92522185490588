import React from 'react'
import {STATUS_FILTER, STATUS_FILTER_FOR_ACCOUNTANT} from '../constants/index'
import {getOnlyRoles} from '../../../../services/AuthServices'
import PropTypes from 'prop-types'

const PayoutFilterStatus = (props) => {
    const {onChange, status} = props

    const handChange = (e) => {
        e.preventDefault()
        const value = e.target.value
        onChange(value)
    }

    const role = getOnlyRoles()

    const STATUS = (role === 'accountant') ? STATUS_FILTER_FOR_ACCOUNTANT : STATUS_FILTER

    return (
        <div className="PayoutFilterStatus mr-4">
            <label className="mr-2 font-weight-bold">Approval status: </label>
            <select value={status}
                    onChange={handChange}>
                {
                    STATUS.map((item, index) => {
                            return <option key={index}
                                           value={item.value}>{item.name}</option>

                        }
                    )
                }
            </select>
        </div>
    )

}

PayoutFilterStatus.propTypes = {
    onChange: PropTypes.func.isRequired,
    status: PropTypes.string.isRequired
}

export default PayoutFilterStatus
