import React, {Component} from 'react'

class ModalEditGlobal extends Component{
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        }
    }

    _handleChange = e => {
        this.setState({
            value: e.target.value
        })
        this.props.onChangeSetting(e.target.value)
    }


    render() {
        const {value} = this.state
        const {title} = this.props

        return (
            <div>
                <div className='mb-2'>Edit Global Setting <span
                    className='h6 mb-0'>"{title}"</span>
                </div>
                <div className="form-group">
                    <input type="text" value={value} className='form-control' onChange={this._handleChange}/>
                </div>
            </div>
        )
    }
}

export default ModalEditGlobal