import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'

class ContentFile extends Component {
    state = {}

    _onToggle = () => {
        this.props.onClose()
    }

    render() {
        const {open, content} = this.props
        return (
            <Modal size='lg' isOpen={open} toggle={this._onToggle} className="PlanModal">
                <ModalHeader toggle={this._onToggle}>Stage Details</ModalHeader>
                <ModalBody>
                    <div className='StoresPage'>
                        {content.split('\n').map((line, i) => {
                            return (
                                <div>{line}</div>
                            )
                        })}
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

ContentFile.propTypes = {
    pipeline: PropTypes.object.isRequired,
    stage: PropTypes.object.isRequired,
}

export default ContentFile
