import React, {Component} from 'react'
import ProductMockupNewPage from './ProductMockupNewPage'

class ProductMockupNewContainer extends Component {
    render() {

        return (
            <div className='ProductMockupNewContainer'>
                <ProductMockupNewPage/>
            </div>
        )
    }
}

export default ProductMockupNewContainer
