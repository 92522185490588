import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {deleteClusterSetting, updateClusterSetting} from "../../../../../../services/api/ClustersServices"
import Modal from "reactstrap/es/Modal"
import ModalHeader from "reactstrap/es/ModalHeader"
import ModalBody from "reactstrap/es/ModalBody"
import ModalFooter from "reactstrap/es/ModalFooter"

class ClusterSettingActions extends Component {
    state = {
        key: this.props.settingKey,
        value: this.props.settingValue,
        showModal: false,
        error: "",
    }

    _handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    _handleClickDelete = e => {
        e.preventDefault()

        const result = window.confirm("Are you sure?")

        if (result) {
            this._handleDeleteSetting()
        }
    }

    _handleChangeInput = e => {
        const {name, value} = e.target

        this.setState({
            [name]: value,
        })
    }

    _handleDeleteSetting = async () => {
        const {clusterId, settingKey, onFetchClusterSettings} = this.props
        try {
            const {success, message} = await deleteClusterSetting(clusterId, settingKey)

            if (!success) {
                window.alert(message)
            } else {
                onFetchClusterSettings()
            }
        } catch (e) {
            window.alert(e.message)
        }
    }

    _handleUpdateClusterSetting = async () => {
        const {key, value} = this.state
        const {clusterId, onFetchClusterSettings} = this.props

        try {
            const {success, message} = await updateClusterSetting(clusterId, key, value)
            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                showModal: false,
                error: "",
            }, onFetchClusterSettings)
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {showModal, error, key, value} = this.state

        return (
            <td className="ClusterSettingActions text-center">
                <i className="far fa-edit text-primary" onClick={this._handleToggleModal}/>
                <i className="fas fa-times text-danger ml-3" onClick={this._handleClickDelete}/>

                <Modal isOpen={showModal} toggle={this._handleToggleModal}>
                    <ModalHeader>Update cluster setting</ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label htmlFor="clusterSettingKey">Key</label>
                            <input type="text" className="form-control" id="clusterSettingKey" name="key" value={key}
                                   onChange={this._handleChangeInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="clusterSettingValue">Value</label>
                            <input type="text" className="form-control" id="clusterSettingValue" name="value"
                                   value={value}
                                   onChange={this._handleChangeInput}/>
                        </div>

                        {
                            error &&
                            <div className="text-danger mt-3">{error}</div>
                        }
                    </ModalBody>

                    <ModalFooter>
                        <button className="btn border" onClick={this._handleToggleModal}>Cancel</button>
                        <button className="btn btn-primary ml-3" onClick={this._handleUpdateClusterSetting}>Save changes
                        </button>
                    </ModalFooter>
                </Modal>
            </td>
        )
    }
}

ClusterSettingActions.propTypes = {
    onFetchClusterSettings: PropTypes.func.isRequired,
    clusterId: PropTypes.string.isRequired,
    settingKey: PropTypes.string.isRequired,
    settingValue: PropTypes.string.isRequired,
}

export default ClusterSettingActions
