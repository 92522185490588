import React, {Component} from 'react'


class ProductMockupTableHead extends Component {
     _handleSelectAll = (e)=>{
        this.props.onChangeSelectAll(!this.props.isSelectAll)
    }
    render() {
        const {isSelectAll} = this.props
        return (
            <thead className='ProductMockupTableHead thead-light'>
                <tr>
                    <th className='table-col-10  '>
                        <span>#</span>
                        <input className='ml-4' type='checkbox' checked={isSelectAll} onChange={this._handleSelectAll} />
                    </th>
                    <th className='table-col-30'>Title</th>
                    <th className='table-col-30'>Product Type</th>
                    <th className='table-col-20'>Created</th>
                    <th className='table-col-10 text-center'>Action</th>
                </tr>
            </thead>
        )
    }
}

export default ProductMockupTableHead
