import React, {Component} from 'react'
import SettingSearchProductLine from '../components/product-line/SettingSearchProductLine'
import {createSettingProfilesShipping} from '../../../../services/api/ShippingSettingsServices'
import {toaster} from '../../../shared/PFToast'
import {Spinner} from 'reactstrap'
import classnames from 'classnames'
import getHistory from '../../../../store/getHistory'

class ShippingProfilesNewPage extends Component {

    state = {
        name: '',
        skus: [],
        loading: false,
        errValidateName: '',
        errValidateSku: ''
    }


    _handleChangeInput = e => {
        const {name, value} = e.target
        this.setState({
            [name]: value,
            errValidateName: ''
        })
    }

    _handleChange = (propety, value) => {
        this.setState({
            [propety]: value,
            errValidateSku: ''
        })
    }

    _validateInput = () => {
        const {name, skus} = this.state
        const formatName = name.trim()
        if (!formatName) {
            return this.setState({
                errValidateName: 'This field is required.'
            })
        }
        if (skus.length <= 0) {
            return this.setState({
                errValidateSku: 'This field is required.'
            })
        }
        this._createShippingProfiles()
    }

    _createShippingProfiles = async () => {
        const {name, skus} = this.state
        const formatName = name.trim()

        this.setState({
            loading: true
        })

        try {
            const {data, success, message} = await createSettingProfilesShipping(formatName, skus)
            if (!success) throw new Error(message)

            toaster({
                type: 'success',
                message: 'Created shipping profiles successfully!',
                duration: 5000
            })

            this.setState({
                loading: false
            })

            const newShippingProfiles = Object.assign({}, data)

            const history = getHistory()
            history.push(`/a/settings-shipping/profiles-edit/${newShippingProfiles._id}`)

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data

            toaster({
                type: 'error',
                message: message ? message : e.message,
                duration: 5000
            })
            this.setState({
                loading: false
            })
        }
    }

    render() {

        const {name, loading, errValidateName, errValidateSku} = this.state

        return (
            <div className="ShippingProfilesNewPage mt-3">

                <div className="SectionInner">
                    <div className="form-group">
                        <h6 className="TitleShippingProfiles">Title</h6>
                        <input type="text" className={classnames('form-control', {'border-danger': errValidateName})}
                               id="TitleShippingProfiles" name="name"
                               value={name}
                               onChange={this._handleChangeInput}/>
                        {
                            errValidateName && <i className='text-danger fs-14'>{errValidateName}</i>
                        }
                    </div>
                    <SettingSearchProductLine errValidateSku={errValidateSku} onChange={this._handleChange}/>
                </div>

                <div className='d-flex mt-3'>
                    <button disabled={errValidateSku || errValidateName} className='btn btn-primary ml-auto'
                            onClick={this._validateInput}>
                        {
                            loading && <Spinner color='white' size='sm' className='mr-2'/>
                        }
                        Add profiles
                    </button>
                </div>
            </div>
        )
    }
}

export default ShippingProfilesNewPage
