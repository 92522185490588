import React, { Component } from 'react'
import PropTypes from "prop-types"

class DesignFormatLayer extends Component {
  constructor(props) {
    super(props)
    this.wrapperRef = React.createRef()
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="bg-white position-relative design-layer" id="preview" ref={this.wrapperRef}>
        <img className={'w-100'} src={this.props.featureImage} alt={'design-format-mockup'} />
      </div>
    )
  }
}

DesignFormatLayer.propTypes = {
  featureImage: PropTypes.string,
}
DesignFormatLayer.defaultProps = {
  featureImage: '',
}

export default DesignFormatLayer
