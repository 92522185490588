import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import humanizeTime from '../../../helpers/time/humanizeTime'
import UpdateRolesModal from './UpdateRolesModal'
import {getAccountDetail} from '../../../services/api/StoreAccountServices'

class AccountDetails extends Component {
    state = {
        showModal: false,
        rolesString: '',
        accountDetail: {},
        loading: true,
        err: '',
    }

    componentDidMount() {
        this._getAccountDetail()
    }

    _getAccountDetail = async () => {
        try {
            const {accountId} = this.props
            const {success, data, message} = await getAccountDetail(accountId)

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }
            const accountDetail = Object.assign({}, data[0])
            const {roles} = accountDetail
            const rolesString = roles.join(', ')
            this.setState({
                accountDetail,
                rolesString,
                loading: false,
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _validateDate = time => {
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }

    _handleClick = () => {
        this.setState({
            showModal: true,
        })
    }

    _closeModal = () => {
        this.setState({
            showModal: false,
        })
    }

    _updateRoles = roles => {
        const rolesString = roles ? roles.join(', ') : ''
        this.setState({
            rolesString,
        })
        this._closeModal()
    }

    render() {
        const {accountDetail, err, rolesString} = this.state
        const {_id, is_seller, status, email, name, connected, created} = accountDetail
        const google = connected ? connected.google : ''
        const validDate = this._validateDate(created)
        const isSeller = is_seller ? 'true' : 'false'
        const {showModal} = this.state
        const accountId = _id || ''

        return (
            <div className='AccountDetails'>
                <div className='GroupName'>
                    <h5>Details </h5>
                </div>
                {!!err && <span className='text-warning'>{err}</span>}
                <div className='list-group GroupDetail'>
                    <li className='list-group-item text--right'>
                        Name: <span>{name}</span>
                    </li>
                    <li className='list-group-item text--right'>
                        Email: <span>{email} </span>
                    </li>
                    <li className='list-group-item text--right'>
                        Roles: <span>{rolesString} </span>
                        <button className='btn btn-primary btn-sm' type='button' onClick={this._handleClick}>
                            <li className='far fa-edit' />
                        </button>
                    </li>
                    <div className='list-group-item text--right'>
                        Status: <span className={classNames('Status badge badge-secondary', status)}>{status}</span>
                    </div>
                    <li className='list-group-item text--right'>
                        Seller: <span>{isSeller} </span>
                    </li>
                    <li className='list-group-item text--right'>
                        Google: <span>{google} </span>
                    </li>
                    <li className='list-group-item text--right'>
                        Created: <span>{validDate} </span>
                    </li>
                </div>
                <UpdateRolesModal
                    rolesString={rolesString}
                    updateRoles={this._updateRoles}
                    accountId={accountId}
                    showModal={showModal}
                    closeModal={this._closeModal}
                />
            </div>
        )
    }
}

AccountDetails.propTypes = {
    accountId: PropTypes.string.isRequired,
}

export default AccountDetails
