import classNames from 'classnames'
import React, {Component} from 'react'
import {FormGroup, Input, Label} from 'reactstrap'
import {getProductCatalogDetail, updateProductCatalog} from '../../../services/api/ProductLinesServices'
import {ATTRIBUTE_PRESELECTED} from '../../create-new-product-line/constant'
import {toaster} from '../../shared/PFToast'
import InputDescriptions from '../../shared/components/form/InputDescriptions'
import InputEditor from '../../shared/components/form/InputEditor'
import StoreSelect from '../../shipment-report/components/StoreSelect'
import CatalogImages from './CatalogImages'
import CategoriesSelector from './CategoriesSelector'
import CatalogAttributesContainer from './catalog-attributes/CatalogAttributesContainer'
import CatalogVariantContainer from './catalog-variant/CatalogVariantContainer'
import StatusStyle from '../../shared/StatusStyle'
import PubSub from '../helper/CatalogBroker'
import ProductTypeInput from '../../cashback-rule/components/ProductTypeInput'
import {createOptionsFromArray} from '../../cashback-rule/helpers'

class CatalogDetail extends Component {
    constructor(props) {
        super(props)
        this.editorDescription = React.createRef()
        this.editorSizeChart = React.createRef()
        const {match} = this.props
        const catalogId = match.params.catalogId

        this.state = {
            catalogId,
            catalog: {},
            attributes: [],
            variants: [],
            originVariants: [],
            fetching: false,
            saving: false,
            filter: {},
            errorObj: {},
            store: this.props.store,
        }
    }

    _fetchCatalogDetail = async () => {
        try {
            // this.setState({fetching: true})
            const {catalogId} = this.state

            const {success, message, data} = await getProductCatalogDetail(catalogId)

            if (!success) throw new Error(message)

            this.setState({
                catalog: {
                    ...data,
                    fulfillment_product_types: createOptionsFromArray(data.fulfillment_product_types),
                },
                attributes: data.attributes,
                variants: data.variants,
                originVariants: data.variants,
            })
        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
            })
        } finally {
            this.setState({fetching: false})
        }
    }

    _subscriber = null

    componentDidMount() {
        this._fetchCatalogDetail()

        this._subscriber = PubSub.subscribe('UPDATED_CATALOG_DETAIL', this._fetchCatalogDetail)
    }

    componentWillUnmount() {
        this._subscriber = null
    }

    _handleChangeInput = (name, value) => {
        let {errorObj} = this.state
        if (errorObj[name]) {
            delete errorObj[name]
        }
        this.setState(({catalog}) => {
            return {
                catalog: {
                    ...catalog,
                    [name]: value,
                },
            }
        })
    }

    _handleChangeCheckbox = (property) => (e) => {
        const value = e.target.checked
        this.setState(({catalog}) => {
            return {
                catalog: {
                    ...catalog,
                    [property]: value,
                },
            }
        })
    }

    _handleChangeStore = (value) => {
        this.setState(({catalog}) => {
            return {
                catalog: {
                    ...catalog,
                    applied_stores: value,
                },
            }
        })
    }

    _setEditorRefs = (key, editor) => {
        this[key] = editor
    }

    _handleChangeAttributesState = (index, field, value) => {
        this.setState(({attributes}) => {
            const attributesUpdated = attributes.map((att, idx) => {
                if (idx === index) {
                    return {
                        ...att,
                        [field]: field === 'status' ? (value ? 'active' : 'inactive') : value,
                        ...(field === 'value_type' && {is_preselected: ATTRIBUTE_PRESELECTED[value]}),
                    }
                }

                return att
            })

            return {
                attributes: attributesUpdated,
            }
        })
    }

    _updateProductCatalog = async () => {
        try {
            this.setState({saving: true})
            const {_id, is_popular, available_for_campaign, fulfillment_product_types, applied_stores} =
                this.state.catalog
            const payload = {
                is_popular,
                available_for_campaign,
                fulfillment_product_types: [...new Set(fulfillment_product_types.map((type) => type.value))],
                applied_stores: applied_stores.some((store) => typeof store === 'string')
                    ? applied_stores
                    : applied_stores.map((store) => store._id),
            }
            const {success, message} = await updateProductCatalog(_id, payload)
            if (!success) throw new Error(message)
            toaster({
                type: 'success',
                message: 'Update product catalog successfully!',
            })
            this._fetchCatalogDetail()
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message.split(/\n/g).map((m) => {
                    return <p className="mb-0">{m}</p>
                }),
                duration: 5000,
            })
        } finally {
            this.setState({saving: false})
        }
    }

    _filterVariants = (variants, filter) => {
        const result = variants.filter((variant) => {
            let matchCount = 0
            for (let key in filter) {
                const condition = variant.attributes.some((option) => option.value_code === filter[key])
                if (condition) {
                    matchCount++
                }
            }
            return matchCount === Object.keys(filter).length
        })
        return result
    }

    _handleFilterVariants = (filter) => {
        const {originVariants} = this.state
        const variants = this._filterVariants(originVariants, filter)
        this.setState({filter, variants})
    }

    _resetFilterVariants = () => {
        this.setState(({originVariants}) => {
            return {
                variants: originVariants,
            }
        })
    }

    _updateFilter = (filter) => this.setState({filter})

    _toggleColorMapping = () => this.setState((prevState) => ({isOpenColorMapping: !prevState.isOpenColorMapping}))

    render() {
        const {fetching, saving, catalog, attributes, filter, variants, errorObj} = this.state

        const {
            _id,
            applied_stores,
            available_for_campaign = false,
            categories = [],
            currency,
            description = '',
            is_popular = false,
            label,
            production_time = {},
            sku = '',
            sku_prefix = '',
            scope = '',
            thumbnail_link,
            title,
            fulfillment_location = '',
            fulfillment_product_types = [],
            status = '',
        } = catalog

        if (fetching) {
            return (
                <div className="d-flex justify-content-center">
                    <div className="text-success spinner-border Spinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        }

        return (
            <div className="CatalogDetail">
                <div className="d-flex align-items-start">
                    <h1 className="Title mr-3">{title}</h1>
                    <div className="mt-2">
                        <StatusStyle status={status} />
                    </div>
                </div>
                <div className="CatalogDetailInner">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="SectionInner">
                                <InputEditor label="Title" name="title" value={title} readOnly={true} />

                                <div className="row">
                                    <div className="col-sm-12">
                                        <Label className="font-weight-bold">Label</Label>
                                        <FormGroup>
                                            <FormGroup check inline>
                                                <Input
                                                    name="label"
                                                    type="radio"
                                                    checked={label === 'product'}
                                                    readOnly
                                                />{' '}
                                                <Label check>Product</Label>
                                            </FormGroup>
                                            <FormGroup check inline>
                                                <Input
                                                    name="label"
                                                    type="radio"
                                                    checked={label === 'branding'}
                                                    readOnly
                                                />{' '}
                                                <Label check>Branding</Label>
                                            </FormGroup>
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className={`col-12' ${scope === 'custom' ? 'col-sm-6' : 'col-sm-12'}`}>
                                        <InputEditor label="Scope" name="scope" value={scope} readOnly={true} />
                                    </div>
                                    {scope === 'custom' && (
                                        <div className="col-12 col-sm-6">
                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <Label className="font-weight-bold">Applied store</Label>
                                                    <StoreSelect
                                                        needMaxStore={false}
                                                        defaultValue={
                                                            applied_stores.length > 0
                                                                ? applied_stores.map((store) => {
                                                                      return {
                                                                          label: `${store.slug} - ${store.domain}`,
                                                                          value: store._id,
                                                                      }
                                                                  })
                                                                : []
                                                        }
                                                        onChangeStore={this._handleChangeStore}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="row">
                                    <div className="col-12 col-sm-4">
                                        <InputEditor
                                            label="SKU prefix"
                                            name="sku_prefix"
                                            value={sku_prefix}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <InputEditor label="Product SKU" name="sku" value={sku} readOnly={true} />
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <Label className="font-weight-bold">Processing time (business day)</Label>
                                        <div className="ProcessingTimeCatalog d-flex align-items-center">
                                            <div className="ProcessingTimeMin d-flex align-items-center mr-3">
                                                <label className="mb-0 mr-2">From:</label>
                                                <Input
                                                    value={production_time.min || ''}
                                                    name="production_time_min"
                                                    placeholder="BDs"
                                                    readOnly
                                                />
                                            </div>
                                            <div className="ProcessingTimeMax d-flex align-items-center">
                                                <Label className="mb-0 mr-2">To:</Label>
                                                <Input
                                                    value={production_time.max || ''}
                                                    name="production_time_max"
                                                    placeholder="BDs"
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Label className="font-weight-bold">Fulfillment location</Label>
                                        <Input value={fulfillment_location} name="fulfillment_location" readOnly />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <ProductTypeInput
                                            allowParse={false}
                                            title="Fulfillment product type"
                                            name="fulfillment_product_types"
                                            isRequired={false}
                                            product_types={fulfillment_product_types}
                                            errors={errorObj}
                                            onChangeProductType={this._handleChangeInput}
                                            placeholder="Enter Fulfillment product type and press enter"
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <Label className="font-weight-bold">Categories</Label>
                                        <CategoriesSelector
                                            isDisabled={true}
                                            multiSelect={true}
                                            className="CatalogDetailCategory"
                                            defaultValue={
                                                categories.length > 0
                                                    ? categories.map((category) => {
                                                          return {
                                                              label: category.name,
                                                              value: category.name,
                                                          }
                                                      })
                                                    : null
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <Label className="font-weight-bold">Currency</Label>
                                    <select disabled value={currency} className={classNames('form-control')}>
                                        <option value="" disabled>
                                            --Choose Type--
                                        </option>
                                        <option value="USD">USD</option>
                                        <option value="VND">VND</option>
                                    </select>
                                </div>

                                <div className="d-flex align-items-center mb-3">
                                    <div className="custom-control custom-checkbox mr-3">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="ProductLinePopular"
                                            checked={is_popular}
                                            onChange={this._handleChangeCheckbox('is_popular')}
                                        />
                                        <Label className="custom-control-label" htmlFor="ProductLinePopular">
                                            Product line popular
                                        </Label>
                                    </div>
                                    <div className="custom-control custom-checkbox mr-3">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            id="ProductLineAvailableCampaign"
                                            checked={available_for_campaign}
                                            onChange={this._handleChangeCheckbox('available_for_campaign')}
                                        />
                                        <Label className="custom-control-label" htmlFor="ProductLineAvailableCampaign">
                                            Available for campaign
                                        </Label>
                                    </div>
                                </div>

                                <InputDescriptions
                                    disabled={true}
                                    setEditorRefs={this._setEditorRefs}
                                    description={description}
                                />

                                <div className="d-flex justify-content-end">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        disabled={saving}
                                        onClick={this._updateProductCatalog}
                                    >
                                        {saving ? 'Saving...' : 'Save product'}
                                    </button>
                                </div>
                            </div>

                            <CatalogAttributesContainer
                                productId={_id}
                                attributes={attributes}
                                onChangeAttributesState={this._handleChangeAttributesState}
                            />

                            <CatalogVariantContainer
                                label={label}
                                productId={_id}
                                catalog={catalog}
                                filter={filter}
                                variants={variants}
                                currency={currency}
                                attributes={attributes}
                                fulfillment_location={fulfillment_location}
                                handleFilterVariants={this._handleFilterVariants}
                                resetFilter={this._resetFilterVariants}
                                updateFilter={this._updateFilter}
                            />
                        </div>

                        <div className="col-md-3">
                            <CatalogImages thumbnail={thumbnail_link} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CatalogDetail
