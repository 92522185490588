import React, {Component} from 'react'
import PropTypes from 'prop-types'
import StoreFeatureTag from './StoreFeatureTag'

class StoreFeatureTags extends Component {

    render() {
        const {features, ...rest} = this.props

        return (
            <div className="StoreFeatureTags mt-3">
                <div className="Features d-flex flex-wrap">
                    {features.map((feature, index) => {
                        return <StoreFeatureTag key={`storeFeature_${index}`} tag={feature} {...rest}/>
                    })}
                </div>
            </div>
        )
    }
}

StoreFeatureTags.propTypes = {
    features: PropTypes.array.isRequired,
}

export default StoreFeatureTags
