import { useState } from 'react';
import { ModalTitle } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import { exportProductMockup } from '../../../../services/api/ProductLinesServices';
import { toaster } from "../../../shared/PFToast"
function ButtonExportMockup(props) {
    const {productMockupExportIds,getProductMockupExportIds,titleExport,onChangeSelectAll} = props
    const [isModalOpen, setIsModelOpen] = useState(false)
    const [loading,setLoading]=useState(false)
    const [error,setError] = useState('')
    const _exportProductMockup = async (ids)=>{
        setLoading(true)
        try {
            let params = {}
            params['IDs']= ids
            console.log(params);
            let data =  await exportProductMockup(params)
            let csvContents = "data:text/csv;charset=utf-8," + encodeURIComponent(data)
            const link = document.createElement("a");
            link.setAttribute("href", csvContents);
            link.setAttribute("download", "product_mockup.csv");
            document.body.appendChild(link)
            link.click()
            setLoading(false)
            setIsModelOpen(false)
            getProductMockupExportIds([])
            onChangeSelectAll(false)
            toaster({ message: 'Export successfully', type: 'success', duration: 5000 })
        } catch (error) {
            setLoading(false)
            setError(error.message)
        }
    }
    return (
        <div className='addbtn'>
            <button onClick={() => setIsModelOpen(!isModalOpen)} className='btn btn-primary ml-2'>
                Export
            </button>
            <Modal size='lg' isOpen={isModalOpen} >
                <ModalHeader>
                    <ModalTitle>Export Product Mockup:</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    {productMockupExportIds.length ?
                        <div className="d-flex">
                            <div> Product Mockup</div>
                            <ul >
                                {
                                    titleExport.map((item, index) => {
                                        return (
                                            <li key={`ProductMockupExport:${index}`}> {item}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        :
                        <div>
                            <p>Please select the product mockup you want to export!</p>
                        </div>
                    }
                    {
                        error && <p className='text-danger mb-0'>{error}</p>
                    }
                </ModalBody>
                <ModalFooter className="d-flex">
                    <button onClick={() => setIsModelOpen(false)} className="btn btn-light border">Close</button>
                    <button  disabled={productMockupExportIds.length ? false : true} onClick={()=> _exportProductMockup(productMockupExportIds)} className="btn btn-primary">
                        {
                            loading && <Spinner color='white' size='sm' className='mr-2' />
                        }
                        Export
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ButtonExportMockup;