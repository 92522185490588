const _getEnv = (key, defaultValue = null) => {
    const envs = Object.assign({}, window.__APP_ENV)

    return envs[key] || defaultValue
}

const config = {
    baseAPIUrl: {
        $default: process.env.REACT_APP_MANAGER_BASE_API_URL,
        production: '/api/manager'
    },

    baseSellerHost: {
        $default: process.env.REACT_APP_DEV_BASE_SELLER_HOST,
        production: _getEnv('baseSellerHost'),
    },

    isEnableMpMode: {
        $default: process.env.REACT_APP_ENABLE_MP_MODE === 'true',
        production: process.env.REACT_APP_ENABLE_MP_MODE === 'true',
    }
}

const _getEnvironment = () => {
    return process.env.REACT_APP_ENV || 'development'
}

export default (key, defaultValue = null) => {
    if (!config.hasOwnProperty(key)) {
        return defaultValue
    }

    const env = _getEnvironment()
    const _dataConfig = config[key]

    return _dataConfig[env] ? _dataConfig[env] : (_dataConfig['$default'] !== undefined && _dataConfig['$default'] !== null) ? _dataConfig['$default'] : defaultValue
}
