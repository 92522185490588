import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ClusterHost extends Component {
    state = {
        host: this.props.host,
    }

    _handleChangeInput = e => {
        const {name, value} = e.target
        const {onUpdateCluster} = this.props

        this.setState({
            host: value,
        }, onUpdateCluster(name, value))
    }

    render() {
        const {host} = this.state

        return (
            <div className="ClusterHost form-group">
                <label htmlFor="clusterHost">Host</label>
                <input type="text" className="form-control" id="clusterHost" name="host" value={host}
                       onChange={this._handleChangeInput}/>
            </div>
        )
    }
}

ClusterHost.propTypes = {
    host: PropTypes.string.isRequired,
    onUpdateCluster: PropTypes.func.isRequired,
}

export default ClusterHost
