import React, {useState, useEffect, useRef} from 'react'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import PropTypes from 'prop-types'

const BulkVariantEditModal = (props) => {
    const {openBulkEditVariant, name, isOpenBulkEdit, onChangeBulkEditValue, onCancel, onSubmit} = props
    const [_variantValue, _setVariantValue] = useState('')
    const [_loading, _setLoading] = useState(false)
    const inputRef = useRef()

    useEffect(() => {
        if (!isOpenBulkEdit) return _reset()
        // if (isOpenBulkEdit) inputRef.current.focus()
    }, [isOpenBulkEdit])

    const _handleCloseModal = () => {
        onCancel()
    }

    const _reset = () => {
        _setVariantValue('')
    }

    const _handleClickCancel = () => {
        if (isOpenBulkEdit) return onCancel()
        return openBulkEditVariant()
    }

    const _onChangeInput = (e) => {
        const {value} = e.target
        _setVariantValue(value)
        onChangeBulkEditValue({[name]: value})
    }

    const _onClickSaveChange = async () => {
        _setLoading(true)
        await onSubmit()
        _setLoading(false)
    }

    const _onSubmit = async (e) => {
        e.preventDefault()
        _setLoading(true)
        await onSubmit()
        _setLoading(false)
    }

    return (
        <Modal className='BulkVariantEditModal modal-dialog-centered' isOpen={isOpenBulkEdit} toggle={_handleCloseModal} autoFocus={false}>
            <ModalHeader toggle={_handleCloseModal}>
                Change {name === 'retail_price' ? 'price' : 'weight'}
            </ModalHeader>
            <ModalBody>
                <form onSubmit={_onSubmit}>
                    <label>
                        New {name === 'retail_price' ? 'price' : 'weight'}
                    </label>
                    <input type='text'
                           ref={inputRef}
                           autoFocus={true}
                           className='form-control'
                           onChange={_onChangeInput} value={_variantValue}
                    />
                </form>
            </ModalBody>
            <ModalFooter>
                <button className='btn border bg-light' onClick={_handleClickCancel}>Cancel</button>
                <button className='btn btn-primary' type='button' disabled={_loading} onClick={_onClickSaveChange}>
                    {_loading ? 'Saving...' : 'Save'}
                </button>
            </ModalFooter>
        </Modal>
    )
}

BulkVariantEditModal.propTypes = {
    openBulkEditVariant: PropTypes.func.isRequired,
    isOpenBulkEdit: PropTypes.bool.isRequired,
    onChangeBulkEditValue: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default BulkVariantEditModal
