import React, {Component} from 'react'
import PFTablePlaceholder from './../../../shared/PFTablePlaceholder'
import DepositsTableRow from './DepositsTableRow'
import PropTypes from "prop-types"

class DepositsTableBody extends Component {

    render() {
        const {entity, error, loading} = this.props

        return (
            <tbody className='TableLoading'>
            {
                loading ?
                    <PFTablePlaceholder columnQuantity={9}/>
                    :
                    error ?
                        <tr>
                            <td colSpan={9}>
                                <div className='text-danger'>
                                    <b>ERROR:</b> {error}
                                </div>
                            </td>
                        </tr>
                        :
                        entity.length === 0
                            ?
                            <tr>
                                <td colSpan={9}><i>No results</i></td>
                            </tr>
                            :
                            <DepositsTableRow {...this.props}/>
            }
            </tbody>
        )
    }
}

DepositsTableBody.propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
    entity: PropTypes.array.isRequired,
    fetchDeposits: PropTypes.func.isRequired,
}

export default DepositsTableBody