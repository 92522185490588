import React, {useState, useRef} from 'react'
import MessageActions from '../../shared/MessageActions'
import {Modal, ModalBody, ModalHeader} from 'reactstrap'
import {importCatalogFfmType} from '../../../services/api/ProductLinesServices'

const ImportFfmProductType = (props) => {
    const {isOpen, toggle} = props

    const [message, setMessage] = useState({})
    const [loading, setLoading] = useState(false)
    const fileUploader = useRef(null)

    const _handleChangeFile = async (e) => {
        const selectedFile = e.target.files[0]
        setLoading(true)
        setMessage({})

        try {
            const postData = new FormData()
            postData.append('file', selectedFile)
            const {success, message} = await importCatalogFfmType(postData)

            fileUploader.current.value = ''

            if (!success) throw new Error(message)

            let notice = (
                <MessageActions type="success" close={false}>
                    Import fulfillment product type successfully!
                </MessageActions>
            )

            setMessage(notice)
        } catch (error) {
            setMessage(
                <MessageActions type="error" close={false}>
                    {error.message.split(/\n/g).map((m) => {
                        return <p className="mb-0">{m}</p>
                    })}
                </MessageActions>
            )
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal isOpen={isOpen} className="ImportFfmProductType modal-dialog-centered" size="lg">
            <ModalHeader toggle={toggle}>Import fulfillment product type</ModalHeader>

            <ModalBody>
                <p>Upload csv file to import!</p>
                <div className="d-flex justify-content-end">
                    <div className="UploadInput mb-3">
                        <input
                            type="file"
                            accept=".csv"
                            id="ImportFfmProductType"
                            ref={fileUploader}
                            onChange={_handleChangeFile}
                        />
                        <label
                            htmlFor="ImportFfmProductType"
                            className="btn btn-sm btn-info cursor-pointer mb-0 ButtonImport"
                        >
                            <i className="ti-import mr-2" />
                            {loading ? (
                                <span className="float-none">
                                    Importing
                                    <span className="Updating">
                                        <span>.</span>
                                        <span>.</span>
                                        <span>.</span>
                                    </span>
                                </span>
                            ) : (
                                'Import'
                            )}
                        </label>
                    </div>
                </div>

                {Object.entries(message).length > 0 && <div className="mt-3 fs-14">{message}</div>}
            </ModalBody>
        </Modal>
    )
}

export default ImportFfmProductType

