import React from 'react'
import ShipmentReportPage from './ShipmentReportPage'
import {hasRole, isAdmin} from '../../../services/AuthServices'
import WithStoreSettings from '../../shared/WithStoreSettings'

const ShipmentReportContainer = (props) => {

    const isShipmentReport = hasRole('operator')

    if (!(isShipmentReport || isAdmin())) return null

    return (
        <div className="ShipmentReportContainer">
            <ShipmentReportPage />
        </div>
    )
}

export default function (props) {
    return <WithStoreSettings>{(store) => <ShipmentReportContainer {...props} store={store} />}</WithStoreSettings>
}

