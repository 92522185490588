import React from 'react'
import StatusStyle from '../../../../shared/StatusStyle'
import ProductVariantFilter from '../ProductVariantFilter'
import {randomID} from '../../../../../helpers/common/randomID'
import {UncontrolledTooltip} from 'reactstrap'
import {ReactComponent as Store} from '../../../../../statics/assets/icons/store.svg'

const BaseCostTableRow = (props) => {
    const {variants, attributes, filter, handleFilterVariants, toggleChangeScope, tab, updateFilter} = props

    const hasCustomScope = variants.some((v) => v.scope === 'custom')

    return (
        <tbody>
            <ProductVariantFilter
                tab={tab}
                attributes={attributes}
                filter={filter}
                updateFilter={updateFilter}
                handleFilterVariants={handleFilterVariants}
            />
            {!!variants.length &&
                variants.map((variant, index) => {
                    const {_id, sku, attributes, tiers, status, scope = '', applied_stores = []} = variant
                    return (
                        <tr key={`base_cost_${index}`}>
                            {!!attributes.length &&
                                attributes.map((attribute, attrIndex) => {
                                    const randomId = randomID()
                                    return (
                                        <td key={randomId}>
                                            <div className="d-flex align-items-center">
                                                {attrIndex === 0 && scope === 'custom' ? (
                                                    <>
                                                        <Store
                                                            width={20}
                                                            id="storeScope"
                                                            className="cursor-pointer"
                                                            onClick={() => toggleChangeScope(_id, applied_stores)}
                                                        />
                                                        <UncontrolledTooltip target="storeScope">
                                                            Store applied
                                                        </UncontrolledTooltip>
                                                    </>
                                                ) : (
                                                    <>
                                                        {attrIndex === 0 && hasCustomScope && (
                                                            <div style={{width: '20px'}}>&nbsp;</div>
                                                        )}
                                                    </>
                                                )}
                                                <span className="ml-2">{attribute.value_text}</span>
                                            </div>
                                        </td>
                                    )
                                })}
                            <td>{sku}</td>
                            {[1, 2, 3].map((tier, index) => (
                                <td key={`body_tier_${tier}`}>
                                    {tiers[index] && tiers[index].price ? tiers[index].price : '-'}
                                </td>
                            ))}
                            <td>
                                <StatusStyle status={status} />
                            </td>
                        </tr>
                    )
                })}
        </tbody>
    )
}

export default BaseCostTableRow

