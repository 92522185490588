import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ClusterDetail extends Component {
    render() {
        const {name, host, zone, ip} = this.props.cluster

        return (
            <div className="ClusterDetail mt-3">
                <div className="Name">
                    <div className="d-flex align-items-center">
                        <h5>{name}</h5>
                        <code className="ml-1">{zone}</code>
                    </div>
                </div>

                <div className="Host">
                    <strong>Host: </strong>
                    <span>{host}</span>
                </div>

                <div className="IP">
                    <strong>IP: </strong>
                    <span>{ip}</span>
                </div>
            </div>
        )
    }
}

PropTypes.propTypes = {
    cluster: PropTypes.object.isRequired,
}

export default ClusterDetail
