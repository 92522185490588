import React from 'react'
import PropTypes from 'prop-types'
import {moveStore} from '../../../services/api/StoreServices'
import ModalCommon from '../../shared/components/modal'
import PFTableV3 from '../../shared/components/table/v3'
import {toaster} from '../../shared/PFToast'

class ButtonMoveStore extends React.Component {
    state = {
        isLoading: false,
        selectedResources: null,
    }

    _handleMoveStore = async () => {
        this.setState({isLoading: true})

        try {
            const {store} = this.props
            const {_id: storeId} = store

            const selected = this.state.selectedResources.length > 0 ? this.state.selectedResources[0]: null
            if (!selected) {
                throw new Error('Resource required!')
            }

            const {success, message} = await moveStore(storeId,  { selected_resource: selected })
            if (!success) {
                toaster({type: 'error', message})
            } else {
                this.props.onCloseMoveStoreModal()
                toaster({type: 'success', message: `The change has been successfully added to the queue.`})
            }
        } catch (e) {
            toaster({type: 'error', message: e.message})
        }
        this.setState({isLoading: false})
    }

    _columns = [
        {
            title: 'ID',
            dataIndex: '_id',
            key: '_id',
            render: (data) => {
                return (
                    <>
                        <span>{data._id}</span> <br />
                    </>
                )
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (data) => {
                return (
                    <>
                        <span>{data.name}</span> <br />
                    </>
                )
            },
        },
        {
            title: 'Used',
            dataIndex: 'used',
            key: 'used',
            render: (data) => {
                return (
                    <>
                        <span>{data.used}</span> <br />
                    </>
                )
            },
        },
        {
            title: 'Capacity',
            dataIndex: 'capacity',
            key: 'capacity',
            render: (data) => {
                return (
                    <>
                        <span>{data.capacity}</span> <br />
                    </>
                )
            },
        },
    ]

    _selections = [
        {
            key: 'select_resource',
            text: 'select resource',
        },
    ]

    onSubmitTable = (selected) => {
        this.setState((prev) => ({
            ...prev.state,
            selectedResources: Object.entries(selected)
                .filter(([key, value]) => value === true)
                .map(([key, value]) => key),
        }))
    }

    render() {
        const {isActivate, isModalMoveStoreOpen, onOpenMoveStoreModal, onCloseMoveStoreModal, resourcesList} =
            this.props
        const {isLoading} = this.state
        const {total, totalPage, resources, currentTarget, page} = resourcesList

        return (
            <div className="ButtonMoveDatabase d-inline-block">
                <button
                    className="btn btn-warning btn-sm text-secondary"
                    disabled={isLoading || !isActivate}
                    onClick={onOpenMoveStoreModal}
                >
                    {isLoading ? 'Moving' : 'Move'}
                </button>
                <ModalCommon
                    title="Move Database"
                    isOpen={isModalMoveStoreOpen}
                    onClose={onCloseMoveStoreModal}
                    onSubmit={this._handleMoveStore}
                >
                    <PFTableV3
                        totalItems={total}
                        data={resources}
                        loading={isLoading}
                        onSubmit={this.onSubmitTable}
                        columns={this._columns}
                        disableRows={[currentTarget]}
                        hasCheckbox={true}
                        allowSelectAll={false}
                        paginationOpts={{page, totalPage}}
                    />
                </ModalCommon>
            </div>
        )
    }
}

ButtonMoveStore.propTypes = {
    store: PropTypes.object.isRequired,
    changeStoreDetail: PropTypes.func.isRequired,
    isActivate: PropTypes.bool.isRequired,
}

export default ButtonMoveStore
