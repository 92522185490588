import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {addNote, rejectGlobalDeposit} from '../../../services/api/BillingServices'
import PFConfirmModal from '../../shared/PFConfirmModal'

class AddNote extends Component {
    state = {
        note: '',
        invalid_note: '',
    }

    _addNote = async () => {
        const {note} = this.state
        if (!note) {
            this.setState({invalid_note: 'This field is required'})
            return {
                data: null,
                error: '',
            }
        }
        const {deposit, fetchDeposits} = this.props
        const {_id} = deposit
        const {success, message} = await addNote(_id, {note})
        if (!success) {
            throw new Error(message)
        }
        this._loadingDeposit()
        return {
            data: 'Success',
            onSuccess: fetchDeposits,
            hideAfterSuccess: true,
        }
    }

    _loadingDeposit = () => {
        const {fetchDeposits} = this.props
        return fetchDeposits(false)
    }

    _onChangeNote = (e) => {
        const {value} = e.target
        if (value) {
            this.setState({note: value.trim(), invalid_note: ''})
        } else {
            this.setState((prev) => {
                return {...prev, invalid_note: 'This field is required'}
            })
        }
    }

    _renderAddNoteForm = () => {
        const {invalid_note} = this.state
        return (
            <div className="RejectTopUpRequest">
                <div className="form-group">
                    <label className="font-weight-bold" htmlFor="note">
                        Note<span className="text-danger">*</span>
                    </label>
                    <textarea
                        rows={5}
                        id="note"
                        maxLength={2000}
                        className={`form-control ${invalid_note ? 'invalid-input' : ''}`}
                        onChange={this._onChangeNote}
                    ></textarea>
                    {invalid_note && <span className="error-message">{invalid_note}</span>}
                </div>
            </div>
        )
    }

    render() {
        return (
            <PFConfirmModal
                title="Add Note"
                description={() => this._renderAddNoteForm()}
                textOk="Save"
                textCancel="Cancel"
                centered={true}
                size="lg"
            >
                {(confirm) => {
                    return (
                        <button
                            type="button"
                            onClick={confirm(this._addNote)}
                            className="btn btn-sm"
                            style={{minWidth: '80px', color: 'black', border: '0.5px solid #343a40'}}
                        >
                            Add Note
                        </button>
                    )
                }}
            </PFConfirmModal>
        )
    }
}

AddNote.propTypes = {
    fetchDeposits: PropTypes.func.isRequired,
    deposit: PropTypes.object.isRequired,
}

export default AddNote

