import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link';

class ProductLinesColumn extends Component {
    render() {
        const {item} = this.props
        const {title,_id} = item

        return (
            <td className = "ProductLinesColumn d-flex">
                <div className='Tooltip3 Bottom mr-3'>
                    <Link
                        to={`/a/product-lines/create?ref=${_id}`}
                        className="text-secondary fs-14"
                    >
                        <i className="far fa-clone"/>
                    </Link>

                    <div className='TooltipContent'>
                        <div className='ContentInner' style={{minWidth: 195}}>
                            Duplicate the product line
                        </div>
                    </div>
                </div>
                <Link to={`/a/product-lines/${_id}`}>{title}</Link>
            </td>
        )
    }
}

ProductLinesColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ProductLinesColumn
