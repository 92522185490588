import React, {Component} from 'react'
import ZoneNewPage from './ZoneNewPage'
import DocTitleByStore from '../../../dashboard/shared/DocTitleByStore'

class ZoneNewPageContainer extends Component {
    render() {
        const profilesId = this.props.match.params.profilesId

        return (
            <div className="ZoneNewPageContainer">
                <DocTitleByStore title="Shipping Zone AddNew"/>
                <div className="row">
                    <div className="col-md-8 col-12 m-auto">
                        <ZoneNewPage profilesId={profilesId}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ZoneNewPageContainer
