import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SettingShippingListProductItem from './SettingShippingListProductItem'

class SettingShippingListProduct extends Component {

    render() {
        const {profilesProduct, onGetProfilesSettings} = this.props

        return (
            <div className='ListProductShipping'>
                {
                    profilesProduct.length > 0 && profilesProduct.map((item, index) => {
                        return (
                            <SettingShippingListProductItem onGetProfilesSettings={onGetProfilesSettings}
                                                            key={`SettingShippingListProductItem_${index}`}
                                                            profiles={item}/>
                        )
                    })
                }
            </div>
        )
    }
}

SettingShippingListProduct.propTypes = {
    profilesProduct: PropTypes.array.isRequired,
    onGetProfilesSettings: PropTypes.func.isRequired,
}

export default SettingShippingListProduct
