export const _convertPasteValueToArray = (e) => {
    const text = e.clipboardData.getData('text')
    const valueArray = text.split(/[\r?\n]+/)
    return valueArray
}

export const _filterVariants = (variants, filter) => {
    const result = variants.filter((variant) => {
        let matchCount = 0
        for (let key in filter) {
            const condition = variant.options.some((option) => option.name === filter[key])
            if (condition) {
                matchCount++
            }
        }
        return matchCount === Object.keys(filter).length
    })
    return result
}

