import React, {Component} from 'react'

class ImageProgressUpload extends Component {
    render() {
        return (
            <div className="ImageProgressUpload UploadProgress VariantImage m-2 d-flex align-items-stretch">
                <div className="border w-100 AnimatedPlaceHolderShimmer"/>
            </div>
        )
    }
}

export default ImageProgressUpload
