import React, {Component} from 'react'
import PropTypes from 'prop-types'

class BranchColumn extends Component {
    render() {
        const {tableIndex} = this.props
        return (
            <td className='Index'>
                <span>{tableIndex}</span>
            </td>
        )
    }
}

BranchColumn.propTypes = {
    tableIndex: PropTypes.number.isRequired,
}

export default BranchColumn
