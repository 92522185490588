import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Modal from "reactstrap/es/Modal"
import ModalHeader from "reactstrap/es/ModalHeader"
import ModalBody from "reactstrap/es/ModalBody"
import ModalFooter from "reactstrap/es/ModalFooter"
import {updateClusterSetting} from "../../../../services/api/ClustersServices"

class CreateClusterSetting extends Component {
    state = {
        showModal: false,
        key: "",
        value: "",
        error: "",
    }

    _handleChangeInput = e => {
        const {name, value} = e.target

        this.setState({
            [name]: value,
        })
    }

    _handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    _handleCreateClusterSetting = async () => {
        const {key, value} = this.state
        const {clusterId, onFetchClusterSettings} = this.props

        try {
            const {success, message} = await updateClusterSetting(clusterId, key, value)
            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                showModal: false,
                error: "",
            }, onFetchClusterSettings)
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {showModal, error} = this.state

        return (
            <div className="CreateClusterSetting d-flex justify-content-end">
                <button className="btn btn-sm btn-outline-primary" onClick={this._handleToggleModal}>Add new setting
                </button>

                <Modal isOpen={showModal} toggle={this._handleToggleModal}>
                    <ModalHeader>Add new setting</ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label htmlFor="clusterSettingKey">Key</label>
                            <input type="text" className="form-control" id="clusterSettingKey" name="key"
                                   onChange={this._handleChangeInput}/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="clusterSettingValue">Value</label>
                            <input type="text" className="form-control" id="clusterSettingValue" name="value"
                                   onChange={this._handleChangeInput}/>
                        </div>

                        {
                            error &&
                            <div className="text-danger mt-3">{error}</div>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn border" onClick={this._handleToggleModal}>Cancel</button>
                        <button className="btn btn-primary ml-3" onClick={this._handleCreateClusterSetting}>Save changes
                        </button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

CreateClusterSetting.propTypes = {
    clusterId: PropTypes.string.isRequired,
    onFetchClusterSettings: PropTypes.func.isRequired,
}

export default CreateClusterSetting
