import React, {Component} from 'react'
import ProductGalleryShowMore from './ProductGalleryShowMore'
import ProductGallerySortableContainer from './ProductGallerySortableContainer'
import arrayMove from 'array-move'
import PropTypes from 'prop-types'
import {sortProductGallery} from '../../../../services/api/ProductServices'
import {getProductGallery} from '../../../../services/api/ProductApprovalServices'
import ProductGalleryTitle from './ProductGalleryTitle'
import ProductGalleryInvalidImages from './ProductGalleryInvalidImages'
import {toaster} from '../../../shared/PFToast'
import ProductDesignFormatContainer from '../../../shared/components/personalize/ProductDesignFormatContainer'
import {isPersonalized} from '../../../../helpers/product_personalize'

class ProductGalleryContainer extends Component {
    state = {
        gallery: [],
        showAll: false,
        loading: true,
        error: '',
        invalidImages: [],
        currUploader: -1,
    }

    componentDidMount() {
        this._fetchGallery()
    }

    _fetchGallery = async () => {
        try {
            this.setState({loading: true})

            const {data, success, message} = await getProductGallery(this.props.productId, this.props.revisionId)

            if (!success) {
                this.setState({
                    gallery: [],
                    loading: false,
                    error: message,
                })

                return
            }

            this.setState({
                gallery: data.map(item => ({...item, status: 'uploaded'})),
                loading: false,
                error: '',
            })
        } catch (e) {
            this.setState({
                gallery: [],
                loading: false,
                error: e.message,
            })
        }
    }

    _nextUploader = () => {
        this.setState(({currUploader}) => {
            return {currUploader: currUploader + 1}
        })
    }
    _removeImage = (imageSide) => {
        this.setState(({gallery}) => {
            const filtered = gallery.filter(item => item.side !== imageSide)

            return {
                gallery: filtered,
            }
        })
    }

    _updateImage = (position, image) => {
        this.setState(({gallery}) => {
            const newGallery = gallery
            newGallery[position] = image

            return {
                gallery: newGallery,
            }
        })
    }

    _handleClickShowAll = () => {
        this.setState({showAll: true})
    }

    _handleSortEnd = ({oldIndex, newIndex}) => {
        if (oldIndex !== newIndex) {
            this.setState(({gallery}) => {
                return {
                    gallery: arrayMove(gallery, oldIndex, newIndex),
                }
            }, () => {
                this._sortImages()
            })
        }
    }

    _sortImages = async () => {
        try {
            const {gallery} = this.state
            const {productId} = this.props
            const arrSide = gallery.filter(item => item.status === 'uploaded').map(item => item.side)

            const {success, message} = await sortProductGallery(productId, {gallery: arrSide})

            if (!success) throw new Error(message)

            toaster({message: 'Gallery sorted', type: 'success', duration: 5000})
        } catch (e) {
            toaster({message: e.message, type: 'danger', duration: 5000})
        }
    }

    render() {
        const {gallery, showAll, error, invalidImages, currUploader} = this.state
        const {productId, product} = this.props
        const isPersonalizedProduct = isPersonalized(product)
        return (
            <div className={'ProductGalleryContainer' + (isPersonalizedProduct ? ' gallery-personalized' : '')}>
                <div className='SectionInner'>
                    <ProductGalleryTitle/>

                    {
                        error ?
                            <div className='text-danger'>
                                <span className='font-weight-bold text-uppercase'>Error: </span> {error}
                            </div>
                            :
                            <>
                                <ProductGalleryInvalidImages images={invalidImages}/>

                                <ProductGallerySortableContainer
                                    axis='xy'
                                    gallery={gallery}
                                    useDragHandle
                                    onSortEnd={this._handleSortEnd}
                                    showAll={showAll}
                                    productId={productId}
                                    onAddImage={this._addImages}
                                    currUploader={currUploader}
                                    onNextUploader={this._nextUploader}
                                    onUpdateImage={this._updateImage}
                                    onRemoveImage={this._removeImage}
                                    transitionDuration={0}
                                />

                                { isPersonalizedProduct &&
                                    <ProductDesignFormatContainer gallery={gallery} productId={productId} product={product}/>
                                }
                                <ProductGalleryShowMore showAll={showAll} gallery={gallery}
                                                        onClickShowAll={this._handleClickShowAll}/>
                            </>
                    }
                </div>
            </div>
        )
    }
}

ProductGalleryContainer.defaultProps = {
    product: {}
}

ProductGalleryContainer.propTypes = {
    productId: PropTypes.string.isRequired,
    product: PropTypes.object
}

export default ProductGalleryContainer
