import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {makeRequest} from "../../../../services/api/DeploymentServices"
import ServiceStatus from "./ServiceStatus"

class StoreStatusTab extends Component {
    state = {
        services: [],
        isAllReady: false,
        loading: false,
        error: '',
    }

    _interval = null

    componentDidMount() {
        this._init()
    }

    componentWillUnmount() {
        this._interval && clearInterval(this._interval)
    }

    _init = () => {
        this._refreshStatus()
        this._interval = setInterval(this._refreshStatus, 3000)
    }

    _refreshStatus = async () => {
        if (this.state.loading) return false

        this.setState({
            loading: true,
        })

        const {store} = this.props
        const {backoffice_master_url, _id: storeId} = store
        const api = `${backoffice_master_url}/api/deployer/stores/${storeId}/services/all/status`

        try {
            const {success, data, message} = await makeRequest({
                url: api,
                method: 'GET'
            })

            if (!success) {
                throw new Error(message)
            }

            const {isAllReady, services} = Object.assign({}, data)

            return this.setState({
                loading: false,
                error: '',
                isAllReady,
                services,
            })
        } catch (e) {
            this.setState({
                error: e.message,
                loading: false,
            })
        }
    }

    render() {
        const {error, services, isAllReady, loading} = this.state

        return (
            <div className={classNames("StoreStatusTab", {loading})}>
                <h3>Status</h3>

                {
                    !!error &&
                    <div className="TextError">
                        <div className="Text text-danger">{error}</div>
                    </div>
                }

                {
                    <div className={classNames("Status", {isAllReady})}>
                        {
                            !!isAllReady ? 'Ready' : 'Unready'
                        }
                    </div>
                }

                <ServiceStatus services={services}/>
            </div>
        )
    }
}

StoreStatusTab.propTypes = {
    store: PropTypes.object.isRequired,
}

export default StoreStatusTab
