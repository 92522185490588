export const fieldMap = {
    'Domain': 'domain',
    'Store ID': 'slug',
    'Database': 'database',
    'Tags': 'tags',
    'Feature tags': 'feature_tags',
    'Old domain': 'old_domain',
    'Namespace': 'namespace',
    'Owner email': 'owner_email'
}

export const DEFAULT_PARAMS = {
    page: 1,
    limit: 50,
    domain: '',
    slug: '',
    database: '',
    tags: [],
    startDate: '',
    endDate: '',
    status: 'active',
    mode: '',
    resourceId: '',
    groupId: '',
    searchValue: '',
    disable_storefront: '',
    searchFieldSelected: 'Domain',
}
