import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Card, CardBody, CardTitle} from 'reactstrap'

class OrderBox extends Component {
    render() {
        const {formattedMetricData} = this.props
        const order = Object.keys(formattedMetricData).length ? formattedMetricData.order : {}
        const {total, fulfilled, unfulfilled, partial, paid, pending} = order
        return (
            <div className='OrderBox Box'>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>Order</h5>
                        </CardTitle>
                        <div className='card-item'>
                            <span>Total</span>
                            <span>{total || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Fulfilled</span>
                            <span>{fulfilled || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Unfulfilled</span>
                            <span>{unfulfilled || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Partial</span>
                            <span>{partial || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Paid</span>
                            <span>{paid || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Pending</span>
                            <span>{pending || 0}</span>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

OrderBox.propTypes = {
    formattedMetricData: PropTypes.object.isRequired,
}
export default OrderBox
