import React from 'react'
import DatabaseJobsPage from "./DatabaseJobsPage"

const DatabaseJobsContainer = () => {
    return (
        <>
            <DatabaseJobsPage/>
        </>
    )
}

export default DatabaseJobsContainer
