import React, {Component} from 'react'
import ProductLines from './ProductLines'

class ProductLinesContainer extends Component {
    render() {
        return (
            <div className='ProductLinesContainer'>
                <ProductLines />
            </div>
        )
    }
}

export default ProductLinesContainer
