import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {_convertPasteValueToArray} from '../../../helper'

class ProductVariantWeight extends Component {
    _handleChangeInput = (e) => {
        const {value} = e.target

        const {variantIndex, handleListVariantsState} = this.props
        handleListVariantsState(variantIndex, 'weight', +value)
    }

    _onPaste = (e) => {
        e.preventDefault()
        this.props.onPasteListVariants(_convertPasteValueToArray(e), 'weight')
    }

    render() {
        const {weight = '', error} = this.props
        return (
            <td className="ProductVariantWeight">
                <input
                    type="number"
                    onPaste={this._onPaste}
                    className={classNames('form-control', {'is-invalid': error})}
                    value={weight}
                    min="0"
                    onChange={this._handleChangeInput}
                />
                {error && <i className="text-danger fs-14">{error}</i>}
            </td>
        )
    }
}

ProductVariantWeight.propTypes = {
    weight: PropTypes.number.isRequired,
    error: PropTypes.string.isRequired,
}

export default ProductVariantWeight

