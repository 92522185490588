import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ServiceName extends Component {
    render() {
        const {name} = this.props.service
        return (
            <td className="ServiceName">
                {name}
            </td>
        )
    }
}

ServiceName.propTypes = {
    name: PropTypes.string.isRequired,
}

export default ServiceName
