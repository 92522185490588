import React, {Component} from 'react'
import {unmountComponentAtNode} from 'react-dom'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import PropTypes from 'prop-types'
import {toastSuccess} from '../helpers/ToastHelper'
import {deleteTemplate} from '../../../services/api/SellerServices'

class ModalConfirmDelete extends Component {
    state = {
        isOpen: true,
        loading: false,
        error: null,
        actionResult: null
    }

    componentWillUnmount() {
        unmountComponentAtNode(this.props.el)
    }

    _toggleModal = () => {
        this.setState(prevState => ({isOpen: !prevState.isOpen}))
    }

    _requestDelete = async e => {
        e.preventDefault()

        try {
            this.setState({loading: true})

            const {success, message} = await deleteTemplate(this.props.templateId)

            if (!success) {
                this.setState({loading: false, error: message, actionResult: false})
                return
            }

            this._toggleModal()
            toastSuccess('Deleted Template successfully!')

            this.props.onAfterDelete()
        } catch (e) {
            this.setState({loading: false, error: e.message, actionResult: false})
        }
    }

    _renderActionResult = () => {
        const {error} = this.state
        if (error) return <div className="text-danger">{error}</div>

        return null
    }

    render() {
        const {loading, actionResult} = this.state

        return (
            <Modal modalClassName="modal-container" className="modal-amazon" toggle={this._toggleModal}
                   isOpen={this.state.isOpen}>
                <ModalHeader toggle={this._toggleModal}>Delete template</ModalHeader>
                <ModalBody>
                    {actionResult === null ? 'Are you sure you want to delete this template?' : this._renderActionResult()}
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        className="btn-default"
                        onClick={this._toggleModal}
                    >
                        Cancel
                    </Button>

                    {
                        actionResult === null &&
                        <Button
                            type="button"
                            color="danger"
                            disabled={loading}
                            onClick={this._requestDelete}
                        >
                            Delete
                        </Button>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

ModalConfirmDelete.propTypes = {
    templateId: PropTypes.string.isRequired,
    onAfterDelete: PropTypes.func.isRequired
}

export default ModalConfirmDelete
