import createAPIServices from './createApiServices'
import getEnv from '../../helpers/common/getEnv'

const baseUrl = `${getEnv('baseSellerHost')}/api/seller`
const api = createAPIServices({ baseUrl })

export const getWebhookEvents = () => {
    return api.makeAuthRequest({
        url: `/webhook-event`,
        method: 'GET'
    })
}

export const addWebhookEvent = (data) => {
    return api.makeAuthRequest({
        url: `/webhook-event`,
        method: 'POST',
        data
    })
}

export const removeWebhookEvent = (id) => {
    return api.makeAuthRequest({
        url: `/webhook-event/${id}`,
        method: 'DELETE',
    })
}

export const editWebhookEvent = (data,id) => {
    return api.makeAuthRequest({
        url: `/webhook-event/${id}`,
        method: 'PUT',
        data
    })
}
