import React from 'react'

const TableHeader = () => {
    const _tableColumns = [
        {
            name: 'Template name',
            col: 'table-col-25'
        },
        {
            name: 'Category name',
            col: 'table-col-20'
        },
        {
            name: 'Status',
            col: 'table-col-15'
        },
        {
            name: 'Time create',
            col: 'table-col-15'
        },
        {
            name: 'Marketplace',
            col: 'table-col-15'
        },
        {
            name: 'Action',
            col: 'table-col-10'
        }
    ]

    return (
        <thead className="TableHeader ProfilesTable__thead">
        <tr>
            {_tableColumns.map((column, index) => (
                <th className={column.col} scope="col" key={index}>
                    {column.name}
                </th>
            ))}
        </tr>
        </thead>
    )
}

export default TableHeader
