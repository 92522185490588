import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getStoreHistories} from '../../../services/api/StoreServices'
import StoreHistory from './StoreHistory'

class StoreHistories extends Component {
    state = {
        histories: [],
        loading: false,
        error: '',
    }

    componentDidMount() {
        this._fetchListHistories()
    }

    _fetchListHistories = async () => {
        const {storeId} = this.props
        this.setState({
            loading: true,
            error: '',
        })

        try {
            const {success, data, message} = await getStoreHistories(storeId)

            if (!success) {
                throw new Error(message)
            }

            const {storeHistories} = Object.assign({}, data)

            this.setState({
                loading: false,
                error: '',
                histories: storeHistories,
            })
        } catch (e) {
            this.setState({
                error: e.message,
                loading: false,
            })
        }
    }

    render() {
        const {histories} = this.state

        return histories.map(history => <StoreHistory key={history._id} history={history} />)
    }
}

StoreHistories.propTypes = {
    storeId: PropTypes.string.isRequired,
}

export default StoreHistories
