import React, {Component} from 'react'
import DocTitle from '../../shared/DocTitle'
import PricingPage from './PricingPage'
import getEnv from '../../../helpers/common/getEnv'

class PricingContainer extends Component {

    render() {
        const isSupportMpMode = getEnv('isEnableMpMode')
        if (isSupportMpMode) return null

        return (
            <div className="PricingContainer.js">
                <DocTitle title="Subscription"/>
                <h2>Subscription</h2>
                <PricingPage {...this.props}/>
            </div>
        )
    }
}

export default PricingContainer
