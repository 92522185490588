import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {Input} from 'reactstrap'
import {inActiveGroup} from '../../../services/api/ClustersServices'
import {toaster} from '../../shared/PFToast'
import {confirmModal} from '../../shared/PFConfirmModalV2'

class GroupItem extends Component {
    state = {
        reason: ''
    }

    render() {
        const {group, handleGetAllGroups, index} = this.props
        const {totalStore = 0, namespace, status, cluster = {}, created, type} = group
        console.log(group)
        const {name} = Object.assign({}, cluster)

        const onConfirmInactive = () => {
            confirmModal({
                confirmText: "Inactive",
                confirmColor: "danger",
                title: "Inactive group",
                message: <div>
                    <div className="mb-2">Would you like to inactive group?</div>
                    <Input
                        id="exampleText"
                        name="text"
                        type="textarea"
                        onChange={e => this.setState({reason: e.target.value})}
                    />
                </div>,
                onOk: () => handleInactiveGroup(),
            });
        };

        const handleInactiveGroup = async () => {
            try {
                const {success, message} = await inActiveGroup(group._id, {
                    reason: this.state.reason,
                    resolved: "resolved"
                })
                if (!success) {
                    return toaster({
                        type: 'error',
                        message: message,
                    })
                }
                toaster({
                    type: 'success',
                    message: `Inactive group successfully.`,
                })
                handleGetAllGroups()
            } catch (e) {

            }
        }

        return (
            <tr>
                <td>{index+1}</td>
                <td><Link to={{
                    pathname: `/a/group/${group._id}`,
                    state: {
                        groupName: namespace
                    }
                }}>{namespace}</Link></td>
                <td>{created}</td>
                <td>{type || ''}</td>
                <td>
                    <div>{name}</div>
                </td>
                <td>{totalStore}</td>
                <td className={`StatusColumn ${status}`}>
                    <span className='badge badge-secondary'>{status || ''}</span>
                </td>
                <td className='text-center text-danger'>
                    {(totalStore === 0 && status === 'active') && (
                        <i className='fas fa-times cursor-pointer' onClick={onConfirmInactive}></i>
                    )}
                </td>
            </tr>
        )
    }
}

GroupItem.propTypes = {
    group: PropTypes.object.isRequired
}

export default GroupItem
