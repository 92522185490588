import React, {Component} from 'react'
import {Modal, ModalBody, ModalFooter} from 'reactstrap'
import {statisticStoresBilling} from '../../../../services/api/PlanServices'

class StatisticStoreBilling extends Component {
    state = {
        visible: false,
        loading: false,
        message: {},
    }

    _formData = {}

    _toggleModal = () => {
        this.setState(({visible}) => {
            return {
                visible: !visible,
            }
        })
    }

    _handleSubmit = e => {
        e.preventDefault()

        this._reqExport()
    }

    _handleChange = e => {
        const {value, name, checked} = e.target

        this._formData = {
            ...this._formData,
            [name]: value === 'form-checkbox' ? checked : value,
        }
    }

    _reqExport = async () => {
        try {
            this.setState({
                loading: true,
                message: {},
            })

            const {success, message} = await statisticStoresBilling(this._formData)

            if (!success) throw new Error(message)

            this.setState({
                loading: false,
                message: {
                    type: 'success',
                    content: 'The data has been exported. Please check your google sheet link.',
                },
            })

        } catch (e) {
            this.setState({
                loading: false,
                message: {
                    type: 'error',
                    content: e.message,
                },
            })
        }
    }

    render() {
        const {visible, loading, message} = this.state

        return (
            <div className='StatisticStoreBilling'>
                <button className='btn btn-outline-secondary' onClick={this._toggleModal}>Export billing</button>

                <Modal isOpen={visible} toggle={this._toggleModal} autoFocus={false}>
                    <form onSubmit={this._handleSubmit}>
                        <ModalBody>
                            <div className='form-group'>
                                <label htmlFor='googleSheetInput'>Google Sheet link <span
                                    className='text-danger'>*</span></label>
                                <input type='text' id='googleSheetInput' className='form-control' name='link' required
                                       autoFocus onChange={this._handleChange}/>
                                <small className='form-text text-muted'>
                                    Share to:
                                    <a href='mailto:topup-notifier@platform126.iam.gserviceaccount.com'
                                       className='ml-2'>
                                        exporter@platform126.iam.gserviceaccount.com
                                    </a>.
                                </small>
                            </div>

                            <div className='form-group'>
                                <label htmlFor='storeSlugInput'>Store slug</label>
                                <input type='text' id='storeSlugInput' className='form-control' name='slugs'
                                       onChange={this._handleChange}/>
                                <small className='form-text text-muted'>Separate store slugs by commas.</small>
                            </div>

                            <div className='form-group form-check'>
                                <input type='checkbox' id='includeObStoreCheckbox' className='form-check-input'
                                       name='obStore' value='form-checkbox' onChange={this._handleChange}/>
                                <label htmlFor='includeObStoreCheckbox'>Include ob stores?</label>
                            </div>

                            {
                                message.hasOwnProperty('type')
                                &&
                                <div className={message.type === 'success' ? 'text-success' : 'text-danger'}>
                                    {message.content}
                                </div>
                            }
                        </ModalBody>

                        <ModalFooter>
                            <button type='button' className='btn btn-default' onClick={this._toggleModal}>Cancel
                            </button>
                            <button type='submit' className='btn btn-primary' disabled={loading}>Submit</button>
                        </ModalFooter>
                    </form>
                </Modal>
            </div>
        )
    }
}

export default StatisticStoreBilling
