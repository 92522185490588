import React, {Component} from 'react'
import ProductVariantsTable from './ProductVariants/ProductVariantsTable'
import {updateProductLineVariants} from '../../../services/api/ProductLinesServices'
import ProductVariantsAction from './ProductVariantsAction'
import PropTypes from 'prop-types'
import {toaster} from '../../shared/PFToast'
import {_filterVariants} from '../helper'

class ProductVariants extends Component {
    state = {
        loading: false,
        error: '',
        filter: {},
        filteredVariants: this.props.listVariants || [],
    }

    componentDidUpdate(prevState) {
        const {listVariants} = this.props
        if (listVariants !== prevState.listVariants) {
            const {filter} = this.state
            this._handleFilterVariants(filter)
        }
    }

    _handleListVariantsState = (index, field, value, isDeleted = false) => {
        this.props.onHandleListVariantsState(index, field, value, isDeleted)
    }

    _onPasteListVariants = (value = [], name, isNumber) => {
        const {filteredVariants} = this.state
        this.props.onPasteListVariants(value, name, isNumber, filteredVariants)
    }

    _validateWeight = () => {
        const {listVariants, store} = this.props
        const listVariantsNew = [...listVariants]
        const txtValid = 'The weights of variants must be greater than 0!.'
        let validate = true
        for (let i in listVariantsNew) {
            if (
                (listVariantsNew[i]['weight'] === 0 || !listVariantsNew[i]['weight']) &&
                store === 'marketplace' &&
                !listVariantsNew[i]['is_deleted']
            ) {
                listVariantsNew[i]['weightError'] = txtValid
                validate = false
            } else {
                listVariantsNew[i]['weightError'] = ''
            }
        }
        this.props.onHandleChange(listVariantsNew, 'listVariants')
        return validate
    }

    _validatePrice = () => {
        const {listVariants} = this.props
        const listVariantsNew = [...listVariants]
        const txtValid = 'The price of variants must be greater than or equals base cost.'
        let validate = true
        for (let i in listVariantsNew) {
            if (
                (!listVariantsNew[i]['retail_price'] ||
                    Number(listVariantsNew[i]['retail_price']) < Number(listVariantsNew[i]['base_cost'])) &&
                !listVariantsNew[i]['is_deleted']
            ) {
                listVariantsNew[i]['priceError'] = txtValid
                validate = false
            } else {
                listVariantsNew[i]['priceError'] = ''
            }
        }
        this.props.onHandleChange(listVariantsNew, 'listVariants')
        return validate
    }

    _validateOldVsNewSku = () => {
        const {listVariants} = this.props
        const listVariantsNew = [...listVariants]
        const mappedNewSkus = listVariantsNew.map((v) => v.new_sku)
        let validate = true
        for (let i in listVariantsNew) {
            if (listVariantsNew[i]['sku'] && mappedNewSkus.includes(listVariantsNew[i]['sku'].trim())) {
                listVariantsNew[i]['duplicateSkuError'] = 'Sku code already exists.'
                validate = false
            } else {
                listVariantsNew[i]['duplicateSkuError'] = ''
            }
        }
        this.props.onHandleChange(listVariantsNew, 'listVariants')
        return validate
    }

    _getDuplicateIndices(array, key = 'new_sku') {
        const valueIndices = new Map()
        const duplicateIndices = []

        for (let i = 0; i < array.length; i++) {
            const value = array[i][key]

            if (value && valueIndices.has(value)) {
                const indices = valueIndices.get(value)
                indices.push(i)
                valueIndices.set(value, indices)
                duplicateIndices.push(i)
            } else {
                valueIndices.set(value, [i])
            }
        }

        return duplicateIndices
    }

    _validateSku = () => {
        const {listVariants} = this.props
        const listVariantsNew = [...listVariants]
        const duplicates = this._getDuplicateIndices(listVariantsNew, 'sku')
        for (let i in listVariantsNew) {
            listVariantsNew[i]['skuError'] = ''
        }
        if (duplicates.length > 0) {
            for (let i of duplicates) {
                listVariantsNew[i]['skuError'] = 'Sku code already exists.'
            }
        }
        this.props.onHandleChange(listVariantsNew, 'listVariants')
        return duplicates.length === 0
    }

    _validateNewSku = () => {
        const {listVariants, shouldRequireNewSku} = this.props
        const listVariantsNew = [...listVariants]

        if(shouldRequireNewSku){
            const newSkuEmpty = listVariantsNew.filter((v) => v.new_sku === '')
            if (newSkuEmpty.length > 0) {
                for (let i in listVariantsNew) {
                    if (listVariantsNew[i]['new_sku'] === '') {
                        listVariantsNew[i]['newSkuError'] = 'New sku code is required.'
                    }
                }
                this.props.onHandleChange(listVariantsNew, 'listVariants')
                return false
            }
        }


        const duplicates = this._getDuplicateIndices(listVariantsNew)
        for (let i in listVariantsNew) {
            listVariantsNew[i]['newSkuError'] = ''
        }
        if (duplicates.length > 0) {
            for (let i of duplicates) {
                listVariantsNew[i]['newSkuError'] = 'New sku code already exists.'
            }
        }
        this.props.onHandleChange(listVariantsNew, 'listVariants')
        return duplicates.length === 0
    }

    _validateBaseCost = () => {
        const {listVariants} = this.props
        const listVariantsNew = [...listVariants]
        const txtValid = 'The base cost of variants must be greater than 0!.'
        let validate = true
        for (let i in listVariantsNew) {
            if (
                (!listVariantsNew[i]['base_cost'] ||
                    (Number(listVariantsNew[i]['base_cost']) === 0 && !listVariantsNew[i]['is_deleted'])) &&
                !listVariantsNew[i]['is_deleted']
            ) {
                listVariantsNew[i]['baseCostError'] = txtValid
                validate = false
            } else {
                listVariantsNew[i]['baseCostError'] = ''
            }
        }
        this.props.onHandleChange(listVariantsNew, 'listVariants')
        return validate
    }

    _onValidate = () => {
        const validateWeight = this._validateWeight()
        const validatePrice = this._validatePrice()
        const validateBaseCost = this._validateBaseCost()
        const validateOldVsNewSku = this._validateOldVsNewSku()
        const validateSku = this._validateSku()
        const validateNewSku = this._validateNewSku()


        if (
            validateWeight &&
            validatePrice &&
            validateBaseCost &&
            validateSku &&
            validateNewSku &&
            validateOldVsNewSku
        ) {
            return this._handleUpdateVariants()
        }
    }

    _handleUpdateVariants = async () => {
        try {
            this.setState({
                loading: true,
                error: '',
            })

            const {productLineId, listVariants} = this.props

            const {success, message} = await updateProductLineVariants(productLineId, {variants: listVariants})

            if (!success) throw new Error(message)

            toaster({message: 'Saving variants successfully!', type: 'success', duration: 5000})

            return this.setState({
                loading: false,
                error: '',
            })
        } catch (e) {
            this.setState({
                loading: false,
            })
            toaster({message: e.message, type: 'error', duration: 5000})
        }
    }

    _handleDiscardChangeState = () => {
        this.props.onHandleDiscardChangeState()
    }

    _handleFilterVariants = (filter) => {
        const {listVariants} = this.props
        const variants = _filterVariants(listVariants, filter)
        this.setState({filter, filteredVariants: variants})
    }

    render() {
        const {loading, filteredVariants, filter} = this.state
        const {listVariants, attributes, currency = '', store} = this.props

        return (
            <div className="ProductVariants mt-4">
                <div className="SectionInner">
                    <div className="d-flex align-items-center justify-content-between">
                        <h3 className="SectionHeading mb-3">Variants</h3>
                        <span>
                            Currency: <b>{currency}</b>
                        </span>
                    </div>
                    <ProductVariantsTable
                        currency={currency}
                        store={store}
                        filter={filter}
                        variants={listVariants}
                        attributes={attributes}
                        filteredVariants={filteredVariants}
                        handleListVariantsState={this._handleListVariantsState}
                        onPasteListVariants={this._onPasteListVariants}
                        handleFilterVariants={this._handleFilterVariants}
                    />

                    <ProductVariantsAction
                        handleUpdateVariants={this._onValidate}
                        handleDiscardChangeState={this._handleDiscardChangeState}
                        loading={loading}
                    />
                </div>
            </div>
        )
    }
}

ProductVariants.propTypes = {
    productLineId: PropTypes.string.isRequired,
    listVariants: PropTypes.array.isRequired,
    attributes: PropTypes.array.isRequired,
}

export default ProductVariants
