import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import ServiceListTable from './table/ServiceListTable'



class ServiceListModal extends Component {
    state = {
        loading: false,
        values: [],
    }

    _onToggle = () => {
        this.props.onClose()
    }

    render() {
        const {open,storeDetails} = this.props
        
        return (
            <Modal size='lg' isOpen={open} toggle={this._onToggle} className="ServiceListModal">
                <ModalHeader toggle={this._onToggle}>Services</ModalHeader>
                <ModalBody>
                    <div className="ServiceListModal">
                        <ServiceListTable storeDetails={storeDetails}/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-outline-primary" onClick={this._onToggle}>Cancel</button>
                </ModalFooter>
            </Modal>
        )
    }
}

ServiceListModal.propTypes = {
    open: PropTypes.bool.isRequired,
    storeDetails: PropTypes.object.isRequired,
}

export default ServiceListModal
