import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getPlanDetails} from "../../../../../services/api/PlanServices"

class SubscriptionColumn extends Component {
    state={
        name:'',
    }

    componentDidMount() {
        this.fetchPlan()
    }

    fetchPlan = async () => {
        const {subscription, plan} = this.props.item
        const planId = subscription ? subscription.plan : (plan ? plan._id : null)
        try{
            if(planId !== null) {
                const {success, data} = await getPlanDetails(planId)
                if (success) {
                    this.setState({
                        name:data.title,
                    })
                }
            }
        }
        catch (e) {
        }
    }

    render() {

        return (
            <td className="PlanColumn">
                {this.state.name}
            </td>
        )
    }
}

SubscriptionColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default SubscriptionColumn
