import { createStoreAPI } from './createStoreAPIService'

const api = createStoreAPI({ suffix: '/database-jobs' })

export const getListDatabaseJobs = (params = {}) => {
    const defaultParams = {
        limit: 20,
        page: 1,
    }

    const newParams = { ...defaultParams, ...params }

    return api.makeAuthRequest({
        url: '',
        method: 'GET',
        params: newParams,
    })
}

export const cancelDatabaseJob = (id = '') => {
    return api.makeAuthRequest({
        url: `/${id}/cancel`,
        method: 'POST'
    })
}
