import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/deployment' : 'https://dev2-manager.merch8.com/api/deployment'

const api = createAPIServices({baseUrl})

export const getListVersions = data =>
    api.makeAuthRequest({
        url: `/versions/search`,
        method: 'POST',
        data,
    })
