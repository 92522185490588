import React, {useCallback, useEffect, useState} from 'react'
import PFFilterOptions from '../../shared/PFFilterOptions'
import {DEFAULT_FILTER, DESTINATION, FULFILLMENT_LOCATIONS, SCOPE, SHIPPING_PLANS} from '../constants'
import {toaster} from '../../shared/PFToast'
import {getListDelivery} from '../../../services/api/SellerServices'
import {deleteEmptyObjectkeys} from '../../../helpers/common/cleanObject'
import PFPagePagination from '../../shared/PFPagePagination'
import pluralize from '../../../helpers/common/pluralize'
import SettingDeliveryTable from './SettingDeliveryTable'
import {states} from '../../../statics/states'

const SettingDeliveryPage = () => {
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    const [total, setTotal] = useState(0)
    const [pages, setPages] = useState(1)
    const [query, setQuery] = useState(DEFAULT_FILTER)

    const _onChangeOptions = (field, value) => {
        setQuery((prev) => ({...prev, page: 1, [field]: field === 'limit' ? Number(value) : value}))
    }

    const _handlePageChange = (page) => {
        setQuery((prev) => ({...prev, page}))
    }

    const _fetchDelivery = useCallback(async () => {
        try {
            setLoading(true)
            const {success, data, message} = await getListDelivery(deleteEmptyObjectkeys(query))
            if (!success) throw new Error(message)
            setTotal(data.total)
            const pages = Math.ceil(data.total / query.limit) || 1
            setPages(pages)
            setItems(data.deliveryProfiles)
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } finally {
            setLoading(false)
        }
    }, [query])

    useEffect(() => {
        _fetchDelivery()
    }, [_fetchDelivery])

    return (
        <div className="SettingDeliveryPage">
            <div className="SectionInner">
                <div className="d-flex align-items-center justify-content-between">
                    <div className='d-flex flex-wrap align-items-center TableFilter'>
                        <div className='mr-3'>
                            <div className='mb-2 font-weight-500'>Fulfillment location</div>
                            <PFFilterOptions
                                layout='radio'
                                options={[{name: 'All', value: ''}, ...FULFILLMENT_LOCATIONS]}
                                field='ffm_location'
                                value={query.ffm_location}
                                onChange={_onChangeOptions}
                            />
                        </div>
                        <div className='mr-3'>
                            <div className='mb-2 font-weight-500'>Destination</div>
                            <PFFilterOptions
                                layout='radio'
                                options={[{name: 'All', value: ''}, ...DESTINATION]}
                                field='destination'
                                value={query.destination}
                                onChange={_onChangeOptions}
                            />{' '}
                        </div>
                        <div className='mr-3'>
                            <div className='mb-2 font-weight-500'>Scope</div>
                            <PFFilterOptions
                                layout='select'
                                options={[{name: 'All', value: ''}, ...SCOPE]}
                                field='scope'
                                value={query.scope}
                                onChange={_onChangeOptions}
                            />{' '}
                        </div>
                        <div className='Plan'>
                            <div className='mb-2 font-weight-500'>Plan</div>
                            <PFFilterOptions
                                layout='select'
                                options={[{name: 'All', value: ''}, ...SHIPPING_PLANS]}
                                field='shipping_plan'
                                value={query.shipping_plan}
                                onChange={_onChangeOptions}
                            />{' '}
                        </div>
                    </div>
                    {total ? (
                        <div className='Total font-weight-bold' style={{minWidth: '80px'}}>
                            {pluralize(total, 'item', 's')}
                        </div>
                    ) : null}
                </div>
                <SettingDeliveryTable loading={loading} items={items} refetch={_fetchDelivery} />
                <PFPagePagination page={query.page} pages={pages} onChangePage={_handlePageChange} />
            </div>
        </div>
    )
}

export default SettingDeliveryPage

