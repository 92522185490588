import React, {Component} from 'react'
import ManageRatesContainer from './manage-rates/ManageRatesContainer'
import {getShippingZoneDetails} from '../../../../services/api/ShippingSettingsServices'
import ReturnPage from '../../../shared/ReturnPage'

class RateNewPage extends Component {
    state = {
        zone: {},
        error: '',
    }

    componentDidMount() {
        this._fetchZoneDetail()
    }

    _fetchZoneDetail = async () => {
        const {zoneId} = this.props

        try {
            const {data, success, message} = await getShippingZoneDetails(zoneId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                zone: data,
            })

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data

            this.setState({
                error: message ? message : e.message,
            })
        }
    }

    render() {
        const {zone} = this.state
        const {name = ''} = zone

        const {zoneId, profilesId} = this.props

        return (
            <div className="RateNewPage py-4">
                <div className="row">
                    <div className="col-md-8 col-12 m-auto">
                        <ReturnPage url={`/a/settings/settings-shipping/${profilesId}/zones-edit/${zoneId}`}
                                    title={`Zone: ${name}`}/>
                        <h1 className="PageTitle">Add shipping rate</h1>

                        <ManageRatesContainer {...this.props}/>
                    </div>
                </div>
            </div>
        )
    }
}


export default RateNewPage
