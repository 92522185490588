import React, {Component} from 'react'
import ProjectVersionsPage from './ProjectVersionsPage'
import getEnv from '../../../helpers/common/getEnv'

export default class ProjectVersionsContainer extends Component {

    render() {
        const isSupportMpMode = getEnv('isEnableMpMode')
        if (isSupportMpMode) return null

        return (
            <div className='ProjectVersionsContainer'>
                <ProjectVersionsPage />
            </div>
        )
    }
}
