import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import convertNameToSlug from '../utils/nameToSlug'
import {createNewFeature, editFeature, getFeatures, removeFeature} from '../../../services/api/PlanServices'

class FeaturesModal extends Component {
    state = {
        newFeature: {
            name: '',
            loading: false,
        },
        editFeature: '',
    }

    componentDidMount() {
        return this._fetchFeatures()
    }

    _fetchFeatures = async () => {
        try {
            const {success, data, message} = await getFeatures()
            if (success) return this.setState({
                features: data,
            })

            console.error(message)
        } catch (e) {
            console.error(e.message || e)
        }
    }

    _onCheckActiveFeature = (_id) => async (e) => {
        const {checked} = e.target

        const features = this.props.features.map((feature) => {
            if (feature._id === _id) return {
                ...feature,
                ...{is_active: checked},
            }

            return feature
        })

        const changedFeatured = features.find((feature) => feature._id === _id)
        if (!changedFeatured) return

        const changeResult = await this._changeFeature(changedFeatured)
        if (changeResult) this.props.onChangeFeature(features)
    }

    _submitSaveFeature = (_id) => async (e) => {
        e.preventDefault()
        const {features} = this.state

        const changedFeature = features.find((feature) => feature._id === _id)
        if (!changedFeature || !changedFeature.name) return null
        const slug = convertNameToSlug(changedFeature.name)

        await this._changeFeature({...changedFeature, ...{slug}})
    }

    _changeFeature = async (feature) => {
        this.setState({
            editFeature: feature._id,
        })

        try {
            const {_id, ...featureDetails} = feature
            const {success, message} = await editFeature(feature._id, featureDetails)
            this.setState({
                editFeature: '',
            })
            if (message) alert(message)

            return success
        } catch (e) {
            this.setState({
                editFeature: '',
            })
            alert(e.message || e)
        }
    }

    _onToggle = () => {
        this.props.onClose()
    }

    _onChangeNewFeature = (key) => (e) => {
        const {value} = e.target
        this.setState(({newFeature}) => ({
            newFeature: {
                ...newFeature,
                [key]: value,
            }
        }))
    }

    _onSubmitNewForm = (e) => {
        e.preventDefault()
        const {name, loading} = this.state.newFeature
        if (!name || loading) return null
        const slug = convertNameToSlug(name)

        return this._submitForm({name, slug})
    }

    _submitForm = async (feature) => {
        try {
            this.setState(({newFeature}) => ({
                newFeature: {
                    ...newFeature,
                    loading: true,
                }
            }))

            const {success, data, message} = await createNewFeature(feature)
            const newFeature = {...success ? {name: '', slug: ''} : this.state.newFeature, loading: false}
            const features = success ? [...this.props.features, data] : this.props.features
            this.setState({
                newFeature,
            })
            this.props.onChangeFeature(features)

            if (message) alert(message)
        } catch (e) {
            this.setState(({newFeature}) => ({
                newFeature: {
                    ...newFeature,
                    loading: false,
                }
            }))
            alert(e.message || e)
        }
    }

    _onChangeFeatureInput = (_id, key) => (e) => {
        const {value} = e.target
        const features = this.props.features.map((feature) => {
            if (feature._id === _id) return {
                ...feature,
                ...{[key]: value},
            }
            return feature
        })

        this.props.onChangeFeature(features)
    }

    _onClickRemoveFeature = (_id) => () => {
        this._removeFeature(_id)
    }

    _removeFeature = async (id) => {
        try {
            const {success, message} = await removeFeature(id)
            if (!success) return alert(message)

            const features = this.props.features.filter((feature) => feature._id !== id)
            this.props.onChangeFeature(features)
        } catch (e) {
            alert(e.message || e)
        }
    }

    render() {
        const {features, open} = this.props
        const {newFeature, editFeature} = this.state

        return (
            <Modal isOpen={open} toggle={this._onToggle} className="FeaturesModal">
                <ModalHeader toggle={this._onToggle}>Features</ModalHeader>
                <ModalBody>
                    <div className="ModalBodyWrapper">
                        <form className="NewFeature" onSubmit={this._onSubmitNewForm}>
                            <input className="form-control mb-2" value={newFeature.name}
                                   onChange={this._onChangeNewFeature('name')}
                                   disabled={newFeature.loading} placeholder="Press enter to create new feature"/>
                        </form>

                        <div className="ListFeatures">
                            {features.length ? features.map((feature) => <div className="Feature" key={feature._id}>
                                <form className="FeatureNameForm" onSubmit={this._submitSaveFeature(feature._id)}>
                                    <input className="FeatureName" value={feature.name}
                                           disabled={editFeature === feature._id}
                                           onChange={this._onChangeFeatureInput(feature._id, 'name')}/>
                                </form>
                                <div className="Remove" onClick={this._onClickRemoveFeature(feature._id)}>
                                    <span className="ti-trash"/>
                                </div>
                                <div className="ActiveWrapper">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input"
                                               checked={feature.is_active} disabled={editFeature === feature._id}
                                               onChange={this._onCheckActiveFeature(feature._id)}
                                               id={`active_${feature._id}`}/>
                                        <label className="custom-control-label"
                                               htmlFor={`active_${feature._id}`}>Active</label>
                                    </div>
                                </div>
                            </div>) : <div className="text-muted">No features</div>}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

FeaturesModal.propTypes = {
    onChangeFeature: PropTypes.func.isRequired,
    features: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default FeaturesModal
