import React, {Component} from 'react'
import StoreTagItem from "./StoreTagItem"
import PropTypes from 'prop-types'

class StoreTagsList extends Component {
    render() {
        const {tags, _handleRemoveStoreTag} = this.props
        let tagsLength 
        if(typeof tags === "undefined"){
            tagsLength = 0
        }
        else{
            tagsLength = tags.length
        }

        return (
            tagsLength > 0 &&
            <div className="StoreTagsList">
                {/*<div className="mb-2">Store tags:</div>*/}
                <ul className="ListTags">
                    {
                        tags.map((tag, index) => {
                            return <StoreTagItem key={index} tag={tag} _handleRemoveStoreTag={_handleRemoveStoreTag}/>
                        })
                    }
                </ul>
            </div>
        )
    }
}

StoreTagsList.propTypes = {
    tags: PropTypes.array,
    _handleRemoveStoreTag: PropTypes.func.isRequired,
}

export default StoreTagsList
