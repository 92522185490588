import React, { useState } from "react";
import { Link } from "react-router-dom";
import { arrayMove, SortableContainer, SortableElement } from "react-sortable-hoc";
import { Table } from "reactstrap";
import { removeHighlightProduct, sortHighlightProduct } from "../../../../services/api/HighlightProductServices";
import drag from "../../../../statics/assets/icons/drag-indicator.svg";
import { confirmModal } from "../../../shared/PFConfirmModalV2";
import { toaster } from "../../../shared/PFToast";
import TableLoading from "../../../shared/TableLoading";
import notFoundImg from "../../../../statics/assets/images/no-image.png"

const SortableHighlightList = (props) => {
    const { products, fetchHighlightList, loading, setLoading } = props;

    const onSortStart = () => (document.body.style.cursor = "grabbing");

    const onSortEnd = ({ oldIndex, newIndex }) => {
        document.body.style.cursor = "default";
        const newProducts = arrayMove(products, oldIndex, newIndex);
        sortHighlightList(newProducts)
    };

    const sortHighlightList = async (products) => {
        try {
            setLoading(true)
            const productLineIds = products.map(product => product._id)
            const { success, message } = await sortHighlightProduct({ productLineIds })
            if (!success) {
                setLoading(false)
                return toaster({
                    type: "error",
                    message
                })
            }
            fetchHighlightList()
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    return (
        <Table hover className="TableProductHighlight table table-striped table-light">
            <thead className="thead-light">
                <tr>
                    <th>#</th>
                    <th>Image</th>
                    <th>Product Line</th>
                    <th>Type</th>
                    <th>Category</th>
                    <th className="text-center">Action</th>
                </tr>
            </thead>
            {loading ? (
                <TableLoading columnQuantity={6} />
            ) : products.length > 0 ? (
                <SortableList
                    products={products}
                    fetchHighlightList={fetchHighlightList}
                    distance={1}
                    onSortStart={onSortStart}
                    onSortEnd={onSortEnd}
                />
            ) : (
                <tbody>
                    <tr className="text-center">
                        <td colSpan="6">
                            <span className="text-muted">No results!</span>
                        </td>
                    </tr>
                </tbody>
            )}
        </Table>
    );
};

const SortableItem = SortableElement(({ product, fetchHighlightList }) => {
    const onConfirmDelete = (productId) => {
        confirmModal({
            confirmText: "Delete",
            confirmColor: "danger",
            title: "Remove product line",
            message: <span>Would you like to remove product line?</span>,
            onOk: () => onDelete(productId),
        });
    };

    const onDelete = async (productId) => {
        try {
            const productLineIds = [productId];
            const { data, success, message } = await removeHighlightProduct({ productLineIds });
            if (!success) {
                return toaster({
                    type: "error",
                    message,
                });
            }
            fetchHighlightList();
        } catch (error) {
            console.error(error);
        }
    };

    const { images } = product;
    const category = Object.assign({}, product.category);
    const { title } = category;
    const image = Array.isArray(images) && images.length > 0 ? images[images.length - 1] : notFoundImg;

    return (
        <tr>
            <td>
                <img src={drag} alt="drag" />
            </td>
            <td className="ImageColumn">
                {image && <img className="ProductLineImageView border" src={image} alt={""} />}
            </td>
            <td>
                <div className="d-flex ProductLineColumn">
                    <div className="Tooltip3 Bottom mr-2">
                        {/* <Link to={`/a/product-lines/create?ref=${product._id}`} className="text-secondary fs-14">
                            <i className="far fa-clone" />
                        </Link>

                        <div className="TooltipContent">
                            <div className="ContentInner">Duplicate the product line</div>
                        </div> */}
                    </div>
                    <Link to={`/a/product-lines/${product._id}`}>{product.title}</Link>
                </div>
            </td>
            <td>{product.product_type}</td>
            <td>{title}</td>
            <td className="text-center">
                <button className="btn btn-danger" onClick={() => onConfirmDelete(product._id)}>
                    <i className="fas fa-trash"></i> Delete
                </button>
            </td>
        </tr>
    );
});

const SortableList = SortableContainer(({ products, fetchHighlightList }) => (
    <tbody>
        {products.map((product, index) => (
            <SortableItem
                key={`item-${index}`}
                index={index}
                product={product}
                fetchHighlightList={fetchHighlightList}
            />
        ))}
    </tbody>
));

export default SortableHighlightList;
