import React, {Component} from 'react'
import ProductEdit from './ProductEdit'
import parseSearchQuery from '../../../helpers/routing/parseSearchQuery'

class ProductApprovalEditContainer extends Component {

    render() {
        const urlParams = parseSearchQuery()
        const {ticketID, productsID, slug, storeID, revisionID} = urlParams
        return (
            <div className="ProductEditContainer">
                {
                    Object.keys(urlParams).length > 0
                    &&
                    <ProductEdit
                        {...this.props}
                        ticketId={ticketID}
                        storeId={storeID}
                        slug={slug}
                        revisionId={revisionID}
                        productId={productsID}
                    />
                }
            </div>
        )
    }
}

export default ProductApprovalEditContainer
