import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {ignoreSendingEmail} from '../../../services/api/BillingServices'
import PFConfirmModal from '../../shared/PFConfirmModal'
import {UncontrolledTooltip} from 'reactstrap'

class IgnorePendingEmail extends Component {
    state = {
        note: '',
    }

    _ignoreMail = async () => {
        const {deposit, fetchDeposits} = this.props
        const {_id} = deposit
        const {success, message} = await ignoreSendingEmail(_id, {note: this.state.note})
        if (!success) {
            throw new Error(message)
        }
        this._loadingDeposit()
        return {
            data: 'Success',
            onSuccess: fetchDeposits,
            hideAfterSuccess: true,
        }
    }

    _loadingDeposit = () => {
        const {fetchDeposits} = this.props
        return fetchDeposits(false)
    }

    _onChangeNote = (e) => {
        const {value} = e.target
        if (value) {
            this.setState({note: value.trim()})
        }
    }

    _renderAddNoteForm = () => {
        return (
            <div className="RejectTopUpRequest">
                <div className="form-group">
                    <label htmlFor="title">
                        Are you sure to ignore this transaction? This transaction will not be sent to pending topup
                        warning mail once it is ignored.
                    </label>
                    <label className="font-weight-bold" htmlFor="note">
                        Note
                    </label>
                    <textarea
                        rows={5}
                        maxLength={2000}
                        id="note"
                        className="form-control"
                        onChange={this._onChangeNote}
                    ></textarea>
                </div>
            </div>
        )
    }

    render() {
        const {deposit} = this.props
        const {ignore_send_pending_mail = false} = deposit

        return (
            <>
                <PFConfirmModal
                    title="Ignore Transaction"
                    description={() => this._renderAddNoteForm()}
                    textOk="Save"
                    textCancel="Cancel"
                    centered={true}
                    size="lg"
                >
                    {(confirm) => {
                        return (
                            <button
                                type="button"
                                id={`btnIgnore_${deposit._id}`}
                                onClick={confirm(this._ignoreMail)}
                                className="btn btn-warning btn-sm mr-1"
                                disabled={ignore_send_pending_mail}
                                style={{minWidth: '80px', color: 'black', border: '0.5px solid #343a40'}}
                            >
                                Ignore
                            </button>
                        )
                    }}
                </PFConfirmModal>
                {ignore_send_pending_mail && (
                    <UncontrolledTooltip target={`btnIgnore_${deposit._id}`}>This transaction has been ignored</UncontrolledTooltip>
                )}
            </>
        )
    }
}

IgnorePendingEmail.propTypes = {
    fetchDeposits: PropTypes.func.isRequired,
    deposit: PropTypes.object.isRequired,
}

export default IgnorePendingEmail

