export const trimObjectValues = (obj) => {
    for (let key in obj) {
        if (obj[key] && typeof obj[key] == "string") {
            obj[key] = obj[key].trim()
        }
    }
    return obj
}

export const deleteEmptyObjectkeys = (obj) => {
    for (let key in obj) {
        if (!obj[key] || obj[key] === null || obj[key] === undefined) {
            delete obj[key]
        }
    }
    return obj
}