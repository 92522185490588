import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {changeWithrawalReject} from '../../../../services/api/BillingServices'
import {UncontrolledTooltip} from 'reactstrap'
import PFConfirmModal from '../../../shared/PFConfirmModal'

class WithrawalReject extends Component {
    _doAction = async () => {
        const {withrawalId, onFetchWithdrawals} = this.props

        const {success, message} = await changeWithrawalReject(withrawalId)

        if (!success) throw new Error(message)

        return {
            data: <div className='text-success'>Success!</div>,
            onSuccess: onFetchWithdrawals,
        }
    }

    render() {
        const {status, withrawalId} = this.props

        if ('pending' !== status) return null

        return (
            <div className='WithdrawalReject ml-1'>
                <PFConfirmModal
                    title='Confirm reject withdrawal'
                    description='Are you sure want to reject this withdrawal?'
                >
                    {
                        confirm => {
                            return (
                                <div onClick={confirm(this._doAction)}>
                                    <span id={`reject_${withrawalId}`}
                                          className='text-danger cursor-pointer'><i className="fas fa-times"/></span>

                                    <UncontrolledTooltip placement='bottom' target={`reject_${withrawalId}`}>
                                        Reject Withdrawal
                                    </UncontrolledTooltip>
                                </div>
                            )
                        }
                    }
                </PFConfirmModal>
            </div>
        )
    }
}

WithrawalReject.propTypes = {
    status: PropTypes.string.isRequired,
    withrawalId: PropTypes.string.isRequired,
}

export default WithrawalReject
