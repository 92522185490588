import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getClusterEnvs } from "../../../../services/api/ClustersServices"
import EnvItem from "./EnvItem"
import ClusterEnvUpdater from "./ClusterEnvUpdater"
import _ from 'lodash'

class ClusterListEnvs extends Component {
    state = {
        query: 'key',
        search: '',
        loading: false,
        error: '',
        envs: []
    }

    componentDidMount() {
        this._fetchListEnvs()
    }

    _fetchListEnvs = _.debounce(async () => {
        this.setState({
            loading: true,
        })

        const { query, search } = this.state
        const { clusterId } = this.props
        try {
            const { success, data, message } = await getClusterEnvs(clusterId, query, search)

            if (!success) {
                throw new Error(message)
            }

            const { envs } = Object.assign({}, data)

            this.setState({
                error: '',
                loading: false,
                envs
            })
        } catch (e) {
            this.setState({
                error: e.message,
                loading: false,
            })
        }

        return true
    }, 500)

    _handleRefresh = async () => {
        return this._fetchListEnvs()
    }

    _updateFilterQuery = async (query) => {
        this.setState({ query, search: "" }, () => this._fetchListEnvs())
    }

    _updateFilterString = async (search) => {
        this.setState({ search }, () => this._fetchListEnvs())
    }

    render() {
        const { envs, query, search } = this.state
        const { clusterId } = this.props

        return (
            <div className="ClusterListEnvs">
                <h3>Envs</h3>

                <ClusterEnvUpdater
                    query={query}
                    search={search}
                    onRefresh={this._handleRefresh}
                    clusterId={clusterId}
                    updateFilterQuery={this._updateFilterQuery}
                    updateFilterString={this._updateFilterString} />

                <table className="table table-striped table-light">
                    <thead className="TableHeader thead-light">
                        <tr>
                            <th>#</th>
                            <th>Key</th>
                            <th>Value</th>
                            <th>Created</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody className="TableBody">
                        {
                            envs.map((env, index) => {
                                return (
                                    <EnvItem clusterId={clusterId}
                                        onRefresh={this._handleRefresh} key={env._id}
                                        index={index} env={env} />
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

ClusterListEnvs.propTypes = {
    clusterId: PropTypes.string.isRequired,
}

export default ClusterListEnvs
