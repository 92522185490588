import React, {Component} from 'react'
import PropTypes from "prop-types"

class RejectionNoteDeposit extends Component {

    render() {
        const {rejectedNote = ''} = this.props
        return (
            <td>{rejectedNote || '-'}</td>
        )
    }
}

RejectionNoteDeposit.propTypes = {
    rejectedNote: PropTypes.string.isRequired,
}

export default RejectionNoteDeposit