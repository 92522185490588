import React, {Component} from 'react'
import StoreSettingsPage from './StoreSettingsPage'

class StoreSettingsContainer extends Component {
    render() {
        const storeId = this.props.match.params.id
        return (
            <div className='StoreSettingsContainer'>
                <StoreSettingsPage storeId={storeId} />
            </div>
        )
    }
}

export default StoreSettingsContainer
