import React, {useCallback, useEffect, useState} from 'react'
import {DESTINATION, FULFILLMENT_LOCATIONS, SCOPE, SHIPPING_PLANS} from '../constants'
import {toaster} from '../../shared/PFToast'
import {createDelivery, getDeliveryDetail, updateDelivery} from '../../../services/api/SellerServices'
import parseSearchQuery from '../../../helpers/routing/parseSearchQuery'
import {Formik, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import getHistory from '../../../store/getHistory'
import {states} from '../../../statics/states'
import {deleteEmptyObjectkeys} from '../../../helpers/common/cleanObject'
import {Link} from 'react-router-dom'
import NumberFormat from 'react-number-format'
import {_mapOptions, _mapStatesOptions, _parseOptionValue, _parseScopeValue, _parseShipmentValue} from '../helpers'
import Select from 'react-select'
import _ from 'lodash'

const SettingDeliveryProfile = () => {
    const parseQuery = parseSearchQuery()
    const {id} = parseQuery
    const [loading, setLoading] = useState(false)
    const [profile, setProfile] = useState({
        scope: '',
        ffm_location: '',
        destination: '',
        shipping_plan: '',
        min_delivery: '',
        max_delivery: '',
    })

    const _fetchDelivery = useCallback(async () => {
        try {
            if (!id) return
            setLoading(true)
            const {success, data, message} = await getDeliveryDetail(id)
            if (!success) throw new Error(message)
            const {ffm_location, destination, shipping_plan, min_delivery, scope, max_delivery} = data
            const newProfile = {
                ffm_location,
                destination,
                scope,
                shipping_plan,
                min_delivery,
                max_delivery,
            }
            setProfile(newProfile)
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } finally {
            setLoading(false)
        }
    }, [id])

    const _customStyles = (errors, touched, name) => {
        return {
            control: (provided) => ({
                ...provided,
                borderColor: errors[name] && touched[name] ? '#dd3d4c' : provided.borderColor,
                boxShadow: errors[name] && touched[name] ? 'unset' : provided.boxShadow,
                '&:hover': {
                    borderColor: errors[name] && touched[name] ? '#dd3d4c' : provided.borderColor,
                    boxShadow: errors[name] && touched[name] ? 'unset' : provided.boxShadow,
                },
            }),
        }
    }

    useEffect(() => {
        _fetchDelivery()
    }, [_fetchDelivery])

    useEffect(() => {
        const el = document.getElementById('SettingMenuTitle')
        el.style.cssText = 'display: none !important'

        return () => {
            el.style.cssText = 'display: flex !important'
        }
    }, [])

    return (
        <div className="SettingDeliveryProfile">
            <div className="container">
                <div className="SettingDeliveryProfileTitle">
                    <h2>Delivery Profile</h2>
                </div>
                <div className="SettingDeliveryProfileDetail mt-3">
                    {loading ? (
                        <div className="Loading text-center">
                            <i className="text-primary fas fa-circle-notch fa-spin" />
                        </div>
                    ) : (
                        <Formik
                            enableReinitialize={true}
                            initialValues={profile}
                            validationSchema={Yup.object().shape({
                                scope: Yup.string().required('This field is required'),
                                ffm_location: Yup.string().required('This field is required'),
                                destination: Yup.string().required('This field is required'),
                                shipping_plan: Yup.string().required('This field is required'),
                                min_delivery: Yup.number().integer().required('This field is required'),
                                max_delivery: Yup.number().integer().required('This field is required'),
                            })}
                            onSubmit={async (form, {setSubmitting}) => {
                                try {
                                    let response
                                    const payload = _.clone(deleteEmptyObjectkeys(form))
                                    if (id) {
                                        response = await updateDelivery(id, payload)
                                    } else {
                                        response = await createDelivery(payload)
                                    }
                                    const {success, message} = response
                                    if (!success) throw new Error(message)
                                    toaster({
                                        type: 'success',
                                        message: `${id ? 'Update' : 'Create'} delivery profile successfully`,
                                    })
                                    const history = getHistory()
                                    history.push('/a/settings/delivery')
                                } catch (error) {
                                    toaster({
                                        type: 'error',
                                        message: error.message,
                                    })
                                } finally {
                                    setSubmitting(false)
                                }
                            }}
                            render={({errors, touched, isSubmitting, values, setFieldValue}) => {
                                return (
                                    <Form>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-2">
                                                <label htmlFor="">Scope</label>
                                            </div>
                                            <div className="col-10">
                                                <Select
                                                    isClearable
                                                    id="scope"
                                                    name="scope"
                                                    options={_mapOptions(SCOPE, 'name')}
                                                    value={_parseScopeValue(values.scope)}
                                                    styles={_customStyles(errors, touched, 'scope')}
                                                    onChange={(option) => setFieldValue('scope', option?.value || '')}
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="scope"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-2">
                                                <label htmlFor="">Fulfillment location</label>
                                            </div>
                                            <div className="col-10">
                                                <Select
                                                    isClearable
                                                    id="ffm_location"
                                                    name="ffm_location"
                                                    options={_mapOptions(FULFILLMENT_LOCATIONS, 'value')}
                                                    value={_parseOptionValue(values.ffm_location)}
                                                    styles={_customStyles(errors, touched, 'ffm_location')}
                                                    onChange={(option) => setFieldValue('ffm_location', option?.value || '')}
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="ffm_location"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-2">
                                                <label htmlFor="destination">Destination</label>
                                            </div>
                                            <div className="col-10">
                                                <Select
                                                    isClearable
                                                    id="destination"
                                                    name="destination"
                                                    options={_mapOptions(DESTINATION)}
                                                    value={_parseOptionValue(values.destination)}
                                                    styles={_customStyles(errors, touched, 'destination')}
                                                    onChange={(option) => setFieldValue('destination', option?.value || '')}
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="destination"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-2">
                                                <label htmlFor="shipping_plan">Shipping plan</label>
                                            </div>
                                            <div className="col-10">
                                                <Select
                                                    isClearable
                                                    id="shipping_plan"
                                                    name="shipping_plan"
                                                    options={_mapOptions(SHIPPING_PLANS)}
                                                    value={_parseShipmentValue(values.shipping_plan)}
                                                    styles={_customStyles(errors, touched, 'shipping_plan')}
                                                    onChange={(option) => setFieldValue('shipping_plan', option?.value || '')}
                                                    components={{
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                />
                                                <ErrorMessage
                                                    name="shipping_plan"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-2">
                                                <label htmlFor="min_delivery">Min delivery</label>
                                            </div>
                                            <div className="col-10">
                                                <div className="input-group">
                                                    <NumberFormat
                                                        value={values.min_delivery}
                                                        name="min_delivery"
                                                        allowNegative={false}
                                                        allowedDecimalSeparators={false}
                                                        maxLength="5"
                                                        placeholder="0"
                                                        className={`form-control${
                                                            errors.min_delivery && touched.min_delivery
                                                                ? ' invalid-input'
                                                                : ''
                                                        }`}
                                                        onValueChange={(values) =>
                                                            setFieldValue('min_delivery', values.floatValue)
                                                        }
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">BDs</span>
                                                    </div>
                                                </div>
                                                <ErrorMessage
                                                    name="min_delivery"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-2">
                                                <label htmlFor="max_delivery">Max delivery</label>
                                            </div>
                                            <div className="col-10">
                                                <div className="input-group">
                                                    <NumberFormat
                                                        value={values.max_delivery}
                                                        name="max_delivery"
                                                        allowNegative={false}
                                                        maxLength="5"
                                                        placeholder="0"
                                                        allowedDecimalSeparators={false}
                                                        className={`form-control${
                                                            errors.max_delivery && touched.max_delivery
                                                                ? ' invalid-input'
                                                                : ''
                                                        }`}
                                                        onValueChange={(values) =>
                                                            setFieldValue('max_delivery', values.floatValue)
                                                        }
                                                    />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">BDs</span>
                                                    </div>
                                                </div>
                                                <ErrorMessage
                                                    name="max_delivery"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <Link className="btn btn-default mr-3" to="/a/settings/delivery">
                                                Cancel
                                            </Link>
                                            <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                                                {isSubmitting && <i className="fas fa-circle-notch fa-spin mr-2" />}
                                                Save
                                            </button>
                                        </div>
                                    </Form>
                                )
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default SettingDeliveryProfile

