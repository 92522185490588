import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ChangeOwnerModal from './ChangeOwnerModal'

class OwnerDetails extends Component {
    state = {
        showModal: false,
        ownerDetail: this.props.storeDetails.owner || {},
    }

    _onChangeOwnerSuccess = ownerDetail => {
        this.setState({
            ownerDetail,
        })
        this._toggleModal()
    }

    _handleClick = () => {
        this.setState({
            showModal: true,
        })
    }

    _toggleModal = () => {
        this.setState({
            showModal: false,
        })
    }

    render() {
        const {showModal, ownerDetail} = this.state
        const {email = '-', name = '-', status = '-', roles = []} = ownerDetail
        const rolesString = roles ? roles.join(', ') : '-'
        const {storeDetails} = this.props
        const {_id: storeId} = storeDetails

        return (
            <div className={`OwnerDetails ${this.props.storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                <div className='list-group Owner'>
                    <li className='list-group-item'>
                        <h5>Owner </h5>
                    </li>
                    <li className='list-group-item'>Name: {name}</li>
                    <div className='list-group-item'>
                        <>
                            <span>Email: {email} </span>
                            <button className='btn btn-primary btn-sm' type='button' onClick={this._handleClick}>
                                <li className='far fa-edit' />
                            </button>
                        </>
                    </div>
                    <li className='list-group-item name'>
                        Status: <span className={classNames('Status badge badge-secondary', status)}>{status}</span>
                    </li>
                    <li className='list-group-item name'>Roles: {rolesString}</li>
                </div>
                <ChangeOwnerModal
                    showModal={showModal}
                    email={email}
                    toggleModal={this._toggleModal}
                    onChangeOwnerSuccess={this._onChangeOwnerSuccess}
                    storeId={storeId}
                />
            </div>
        )
    }
}

OwnerDetails.propTypes = {
    storeDetails: PropTypes.object.isRequired,
}

export default OwnerDetails
