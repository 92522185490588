import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ClusterName from "./ClusterName"
import ClusterIP from "./ClusterIP"
import ClusterHost from "./ClusterHost"
import ClusterProvider from "./ClusterProvider"
import ClusterMongodbURI from "./ClusterMongodbURI"
import ClusterRedisURI from "./ClusterRedisURI"
import ClusterNatsURI from "./ClusterNatsURI"
import ClusterStatus from "./ClusterStatus"
import ClusterIndex from './ClusterIndex';

class ClusterFormContainer extends Component {
    _handleSubmitForm = e => {
        e.preventDefault()

        this.props.onSaveCluster()
    }

    render() {
        const {cluster, onUpdateCluster, loading} = this.props
        const {name, ip, mongodb_uri, host, index, nats_uri, provider, status, redis_uri, zone} = cluster

        return (
            <form className="ClusterFormContainer" onSubmit={this._handleSubmitForm}>
                <div className="row mb-4">
                    <div className="col-12">
                        <strong>Zone:</strong> <code>{zone || '--'}</code>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <ClusterName name={name} onUpdateCluster={onUpdateCluster}/>
                    </div>

                    <div className="col-6">
                        <ClusterIP ip={ip} onUpdateCluster={onUpdateCluster}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <ClusterHost host={host} onUpdateCluster={onUpdateCluster}/>
                    </div>

                    <div className="col-6">
                        <ClusterProvider provider={provider} onUpdateCluster={onUpdateCluster}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-6">
                        <ClusterMongodbURI mongodbURI={mongodb_uri} onUpdateCluster={onUpdateCluster}/>
                    </div>

                    <div className="col-6">
                        <ClusterRedisURI redisURI={redis_uri} onUpdateCluster={onUpdateCluster}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-5">
                        <ClusterNatsURI natsURI={nats_uri} onUpdateCluster={onUpdateCluster}/>
                    </div>

                    <div className="col-4">
                        <ClusterStatus status={status} onUpdateCluster={onUpdateCluster}/>
                    </div>

                    <div className="col-3">
                        <ClusterIndex index={index} onUpdateCluster={onUpdateCluster}/>
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    {
                        loading ?
                            <button className="btn btn-primary disabled" type="submit">Saving...</button>
                            :
                            <button className="btn btn-primary" type="submit">Save changes</button>
                    }
                </div>
            </form>
        )
    }
}

ClusterFormContainer.propTypes = {
    cluster: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    onUpdateCluster: PropTypes.func.isRequired,
    onSaveCluster: PropTypes.func.isRequired,
}

export default ClusterFormContainer
