import React, {Component} from 'react'
import StoreDetailPage from './StoreDetailPage'

class StoreDetailPageContainer extends Component {
    _getStoreId = () => {
        const {match} = this.props
        const {id} = Object.assign({}, match.params)

        return id
    }

    render() {
        const storeId = this._getStoreId()

        return (
            <div className='StoreDetailPageContainer'>
                <StoreDetailPage storeId={storeId} />
            </div>
        )
    }
}

export default StoreDetailPageContainer
