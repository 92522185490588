import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import {createAffiliateCategories} from '../../../services/api/ProductLinesServices'
import {toaster} from '../../shared/PFToast'
import getHistory from '../../../store/getHistory'
import NumberFormat from 'react-number-format'

const MAX_PERCENTAGE = 100

class CreateAffiliatePage extends Component {

    state = {
        name: '',
        percentage: '',
        errObj: {}
    }

    _handleChangNumber = ({floatValue}) => {
        let {errObj} = this.state
        if (errObj['percentage']) {
            delete errObj['percentage']
        }
        this.setState({
            percentage: floatValue
        })
    }

    _handleChangeInput = (e) => {

        e.preventDefault()
        const {name, value} = e.target
        let {errObj} = this.state
        if (errObj[name]) {
            delete errObj[name]
        }

        this.setState({
            [name]: value
        })
    }

    _validate = () => {
        const {name, percentage} = this.state

        let errorKey = {}

        if (!name || !name.trim()) {
            errorKey.name = 'Name is required.'
        }
        if (!percentage && percentage !== 0) {
            errorKey.percentage = 'Percentage is required.'
        }

        this.setState(({errObj}) => ({
            errObj: {...errObj, ...errorKey}
        }))

        if (Object.keys(errorKey).length > 0) return

        this._createAffiliateCategory()
    }

    _createAffiliateCategory = async () => {
        const {name, percentage} = this.state
        const params = {name, percentage}

        try {
            const {success, message} = await createAffiliateCategories(params)

            if (!success) throw new Error(message)

            const history = getHistory()
            history.push('/a/affiliate-category')


            toaster({message: `Create category ${name} successfully!`, type: 'success', duration: 5000})

        } catch (e) {
            toaster({message: e.message, type: 'error', duration: 5000})
        }
    }

    _withValueCap = (inputObj) => {
        const { value } = inputObj;
        if (value <= MAX_PERCENTAGE) return inputObj
    }


    render() {
        const {name, percentage, errObj} = this.state

        return (
            <div className='CreateAffiliatePage'>
                <Link className='nav-link BackButton p-0 mb-2' to='/a/affiliate-category'>
                    <i className='fas fa-chevron-left'/>
                    <span className='ml-2'>Affiliate</span>
                </Link>
                <h1 className='PageTitle'>Create Affiliate</h1>
                <div className='SectionInner'>
                    <div className="form-group">
                        <label htmlFor="TitleAffiliateCategoryNew">Name</label>
                        <input type="text"
                               className={classNames('form-control', {'is-invalid': errObj.name})}
                               id="TitleAffiliateCategoryNew" name="name"
                               value={name}
                               onChange={this._handleChangeInput}/>
                        {
                            errObj.name &&
                            <div className='invalid-feedback'>
                                {errObj.name}
                            </div>
                        }
                    </div>
                    <div className="form-group">
                        <label htmlFor="PercentageAffiliateCategoryNew">Percentage</label>
                        <NumberFormat
                            value={percentage}
                            suffix='%'
                            name="percentages"
                            decimalScale={2}
                            isAllowed={this._withValueCap}
                            className={classNames('form-control', {'is-invalid': errObj.percentage})}
                            onValueChange={this._handleChangNumber}
                        />
                        {
                            errObj.percentage &&
                            <div className='invalid-feedback'>
                                {errObj.percentage}
                            </div>
                        }
                    </div>
                    <div className='form-group d-flex'>
                        <button type='submit' onClick={this._validate} className='btn btn-primary ml-auto'>
                            Create affiliate
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateAffiliatePage
