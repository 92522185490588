export const DEFAULT_QUERY = {
    page: 1,
    store_slug: '',
    store_domain: '',
    transaction_id: '',
    gateway: '',
    from: '',
    to: '',
    status: 'pending',
    limit: 50
}

export const DEPOSIT_STATUS = [
    {
        label: 'All',
        value: ''
    },
    {
        label: 'Approved',
        value: 'approved'
    },
    {
        label: 'Cancelled',
        value: 'cancelled'
    },
    {
        label: 'Pending',
        value: 'pending'
    },
    
    {
        label: 'Rejected',
        value: 'rejected'
    },
]