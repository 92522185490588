import React, {useState} from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap'
import {exportProductLine} from '../../../services/api/ProductLinesServices'
import {toaster} from '../../shared/PFToast'

const ExportProductLineModal = (props) => {
    const {isOpen, toggle, productLineId} = props

    const [url, setUrl] = useState('')
    const [loading, setLoading] = useState(false)

    const _onChange = (event) => {
        const {value} = event.target
        setUrl(value)
    }

    const _handleImport = async () => {
        try {
            setLoading(true)
            const payload = {
                link: url,
                option: {
                    _id: productLineId
                }
            }
            const {success, message} = await exportProductLine(payload)
            if (!success) {
                throw new Error(message)
            }
            toaster({
                type: 'success',
                message: 'Export product line successfully!'
            })
            toggle()
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
            <ModalHeader toggle={toggle}>Export Product Line</ModalHeader>
            <ModalBody>
                <Input onChange={_onChange} placeholder="Input link to export..." />
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggle}>
                    Close
                </Button>{' '}
                <Button color="primary" onClick={_handleImport} disabled={loading || !url.length}>
                    {loading && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                    Export
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ExportProductLineModal

