import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

class StoreNameColumn extends Component {
    render() {
        const {item} = this.props
        const {name, _id, slug} = item

        return (
            <td className='StoreNameColumn'>
                <Link to={`/a/stores/${_id}`}>{name || null}</Link>
                <div>
                    <code>{slug}</code>
                </div>
            </td>
        )
    }
}

StoreNameColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default StoreNameColumn
