import React, {Component} from 'react'
import InputRateTitle from "./InputRateTitle"
import InputRateSubTitle from "./InputRateSubTitle"

// import PropTypes from 'prop-types'

class RateTitleContainer extends Component {
    render() {
        const {title, subtitle, onUpdateRatesData} = this.props

        return (
            <div className="RateTitleContainer">
                <div className="SectionInner">
                    <div className="border-bottom pb-3 mb-3">
                        <h6 className="fs-18 mb-0">Rate Title</h6>

                        <div className="text-muted fs-14">
                            This title is what your customers will see at checkout.
                        </div>
                    </div>

                    <div className="mb-3">
                        <InputRateTitle title={title} onUpdateRatesData={onUpdateRatesData}/>
                    </div>

                    <InputRateSubTitle subtitle={subtitle} onUpdateRatesData={onUpdateRatesData}/>
                </div>
            </div>
        )
    }
}

// RateTitleContainer.defaultProps = {}

// RateTitleContainer.propTypes = {}

export default RateTitleContainer
