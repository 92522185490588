import React, {Component} from 'react'
import PropTypes from "prop-types"
import moment from "moment"

class CreatedDeposit extends Component {
    render() {
        const {created} = this.props
        const Date = moment(created).format('DD/MM/YYYY, HH:mm:ss')
        return (
            <td>
                {Date}
            </td>
        )
    }
}

CreatedDeposit.propTypes = {
    created: PropTypes.string.isRequired,
}

export default CreatedDeposit