import React from 'react'
import PropTypes from 'prop-types'
import storeMode from '../constants/storeModes'

class StoreModeFilterSelect extends React.Component {
    render() {
        const {onChange, value} = this.props

        return (
            <div className='StoreModeFilterSelect Select m-0'>
                <span>Mode</span>
                <select name='storeMode' onChange={onChange} value={value}>
                    <option key={-1} value=''>All</option>
                    {storeMode.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)}
                </select>
            </div>
        )
    }
}

StoreModeFilterSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
}

export default StoreModeFilterSelect
