import React, {Component} from 'react'
import PropTypes from 'prop-types'

class OwnerEmailColumn extends Component {
    render() {
        const {item} = this.props
        const owner = !!item.owner ? item?.owner?.email : ''

        return (
            <td className='OwnerEmailColumn'>
                <span>{owner}</span>
            </td>
        )
    }
}

OwnerEmailColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default OwnerEmailColumn
