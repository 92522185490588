import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ValueColumn extends Component {
    render() {
        const {item} = this.props
        const {value} = item
        return (
            <td className='ValueColumn'>
                <span>{value}</span>
            </td>
        )
    }
}

ValueColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ValueColumn
