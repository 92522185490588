import React, {useContext, useEffect, useState} from 'react'
import FormDetailItem from './FormDetailItem'
import {MARKETPLACE_OPTIONS} from '../statics/amazon'
import TemplateFormContext from '../states/TemplateFormContext'
import PropTypes from 'prop-types'
import {validateInput} from '../helpers/ValidateHepler'

const FormDetail = (props) => {
    const {isUploadTemp, setUploadTemp, templateNameExits} = props
    const templateContext = useContext(TemplateFormContext)

    const template = templateContext.template

    const [items, setItems] = useState(template.items)

    useEffect(() => {
        setItems(template.items)
    }, [template])

    const _updateItem = (item) => {
        items.forEach(initialItem => {
            if (initialItem.field_name === item.field_name) {
                initialItem = item
                setItems(items)

                _updateTemplateContext('items', items)
            }
        })
    }

    const _onChangeField = (e, key, type = '') => {
        const value = type === 'checkbox' ? e.target.checked : e.target.value
        if (key !== 'is_active') {
            validateInput(key, 'remove')
        }
        _updateTemplateContext(key, value)
    }

    const _updateTemplateContext = (key, value) => {
        const newTemplate = {
            ...template,
            ...{[key]: value}
        }
        templateContext.setTemplate(newTemplate)
    }

    return (
        <>
            <div className="form-group">
                <h6><b>Template name </b><span className="text-danger">(*)</span></h6>
                <input className={templateNameExits ? "form-control name is-invalid"  : "form-control name"} type="text" value={template.name || ''}
                       onChange={(e) => _onChangeField(e, 'name')} maxLength="255"/>
            </div>
            <div className="form-group">
                <label htmlFor="marketplace">
                    <h6><b>Marketplace </b><span className="text-danger">(*)</span></h6>
                </label>
                <select className="form-control marketplace" value={template.marketplace || ''}
                        onChange={(e) => _onChangeField(e, 'marketplace')}>
                    {MARKETPLACE_OPTIONS.map((option, index) => {
                        return (
                            <option key={index} value={option.value}>{option.name}</option>
                        )
                    })}
                </select>
            </div>
            <div className="form-group d-flex align-items-center">
                <h6><b>Mark as Active</b></h6>
                <label className="switch position-relative ml-3">
                    <input className="form-control" type="checkbox" checked={template.is_active || false}
                           onChange={(e) => _onChangeField(e, 'is_active', 'checkbox')}/>
                    <span className="slider round position-absolute"/>
                </label>
            </div>
            <div className="form-group">
                <h6><b>Category name </b><span className="text-danger">(*)</span></h6>
                <input className="form-control category_name" type="text" value={template.category_name || ''}
                       onChange={(e) => _onChangeField(e, 'category_name')} disabled={true}/>
            </div>
            <div className="form-group">
                <h6 id="template_item_header"><b>Template Detail Item</b></h6>
                <FormDetailItem items={items} updateItem={_updateItem} isUploadTemp={isUploadTemp} setUploadTemp={setUploadTemp}/>
            </div>
        </>
    )
}

FormDetail.defaultProps = {
    template: null
}

FormDetail.propTypes = {
    template: PropTypes.object,
    isUploadTemp: PropTypes.bool.isRequired,
    setUploadTemp: PropTypes.func.isRequired
}

export default FormDetail
