import React, {useEffect, useState} from 'react'
import Link from 'react-router-dom/Link'
import {getListTemplate} from '../../../../services/api/SellerServices'
import TableContainer from './TableContainer'
import ReactDOM from 'react-dom'
import ModalConfirmDelete from '../../modals/ModalConfirmDelete'
import PFPagePagination from '../../../shared/PFPagePagination'

const AmazonItem = () => {

    const [listTemplate, setListTemplate] = useState([])
    const [loading, setLoading] = useState(true)
    const [paginate, setPaginate] = useState({
        page: 0,
        totalPage: 0,
        totalRecord: 0
    })

    const getAllTemplate = async (page = 1) => {
        try {
            setLoading(true)
            const {data, success, message} = await getListTemplate({page})
            if (success) {
                setListTemplate(data.records)
                setPaginate({
                    page,
                    totalPage: data.total_page,
                    totalRecord: data.total_records
                })
            } else {
                console.error(message)
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const _handlePageChange = async (page) => {
        await getAllTemplate(page)
    }

    const _onClickDelete = async (id) => {
        let el = document.createElement('div')
        ReactDOM.render(
            <ModalConfirmDelete
                templateId={id}
                el={el}
                onAfterDelete={getAllTemplate}
            />, el
        )
    }

    useEffect(() => {
        getAllTemplate()
    }, [])

    return (
        <div className="Amazon">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h1 className="PageTitle">Amazon Templates</h1>
                <div className="addbtn">
                    <Link to="amazon/create" className="btn btn-primary">
                        Create New
                    </Link>
                </div>
            </div>
            <div className="AmazonInner SectionInner">
                <div className="d-flex justify-content-end mb-4">
                    <strong>{paginate.totalRecord} item(s)</strong>
                </div>
                <TableContainer loading={loading} items={listTemplate}
                                onClickDelete={_onClickDelete}/>
                {!loading && listTemplate && listTemplate.length > 0
                && <PFPagePagination page={paginate.page}
                                     pages={paginate.totalPage} onChangePage={_handlePageChange}/>}
            </div>
        </div>
    )
}

export default AmazonItem
