import React, { useEffect, useState } from 'react'
import moment from 'moment'
import humanizeTime from '../../../../helpers/time/humanizeTime'
import PFPagePagination from '../../../shared/PFPagePagination'
import WithdrawHistoryModal from '../modal/WithdrawHistoryModal'
import WithdrawRequestModal from '../modal/WithdrawRequestModal'
import { getAffiliateBalance } from '../../../../services/api/AffiliateServices'
import { toaster } from '../../../shared/PFToast'
import formatThousandNumber from '../../../../helpers/common/formatThousandNumber'

const AffiliateProfitTable = (props) => {
    const { id, code, params, profits, onChangeProfitPage } = props

    const [openWidthdrawRequest, toggleWidthdrawRequest] = useState(false)
    const [openWidthdrawHistory, toggleWidthdrawHistory] = useState(false)
    const [balance, setBalance] = useState({
        profit: 0,
        withdraw: 0,
        available: 0
    })
    const [withdrawSuccess, setWidthdrawSuccess] = useState(false)

    useEffect(() => {
        if (withdrawSuccess) {
            fetchAffiliateBalance()
        }
    }, [withdrawSuccess])

    useEffect(() => {
        fetchAffiliateBalance()
    }, [code, id])

    const fetchAffiliateBalance = async () => {
        try {
            if (code && id) {
                const { data, success, message } = await getAffiliateBalance(code)
                if (success) {
                    setBalance({
                        profit: data.profit_amount,
                        withdraw: data.withdrawal_amount,
                        available: data.amount
                    })
                } else {
                    toaster({
                        type: 'error',
                        message
                    })
                }
            }
        } catch (e) {

        }
    }

    const validateDate = (date) => {
        const momentTime = moment(date)
        return humanizeTime(momentTime)
    }

    const handleChangePage = (page) => {
        onChangeProfitPage(page)
    }

    const toggleRequest = () => {
        toggleWidthdrawRequest(!openWidthdrawRequest)
    }

    const toggleHistory = () => {
        toggleWidthdrawHistory(!openWidthdrawHistory)
    }

    return (
        <div className='affiliate-profit-table' >
            <WithdrawRequestModal code={code} openWidthdrawRequest={openWidthdrawRequest} toggleRequest={toggleRequest} setWidthdrawSuccess={setWidthdrawSuccess} available={balance.available} />
            <WithdrawHistoryModal code={code} openWidthdrawHistory={openWidthdrawHistory} toggleHistory={toggleHistory} />
            {
                id && <>
                    <h3>Commission details</h3>
                    <div className='affiliate-profit-total d-flex justify-content-between'>
                        <div className='commission-left d-inline-flex align-items-center'>
                            <span><strong>Total:</strong> {`$${formatThousandNumber(balance.profit, 2)}`}</span>
                            <span><strong>Withdraw:</strong> {`$${formatThousandNumber(balance.withdraw, 2)}`}</span>
                            <span><strong>Available:</strong> {`$${formatThousandNumber(balance.available, 2)}`}</span>
                        </div>
                        <div className='commission-right'>
                            <button type='button' className='btn btn-primary mr-2' onClick={toggleRequest}>
                                Withdrawal request
                            </button>
                            <button type='button' className='btn btn-default' onClick={toggleHistory}>
                                Withdrawal history
                            </button>
                        </div>
                    </div>
                    <div className='table-responsive mt-2'>
                        <table className='table'>
                            <thead className="thead-light">
                                <tr>
                                    <th>Store</th>
                                    <th>Active date</th>
                                    <th>Remain days</th>
                                    <th>Profit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    profits.length > 0 && profits.map((profit, index) => (
                                        <tr key={index}>
                                            <td>{profit.slug}</td>
                                            <td>{validateDate(profit.activated_at)}</td>
                                            <td>{profit.remain_days > 0 ? profit.remain_days : <span class="badge badge-danger">Inactive</span>}</td>
                                            <td>${formatThousandNumber(profit.affiliate_profit, 2)}</td>
                                        </tr>
                                    ))
                                }
                                {
                                    !profits.length > 0 && <tr>
                                        <td colSpan={4} className='text-muted text-center'>No result</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {
                        params.pages > 1 && <PFPagePagination
                            page={params.page}
                            pages={params.pages}
                            onChangePage={handleChangePage}
                        />
                    }
                </>
            }

        </div>
    )
}

export default AffiliateProfitTable
