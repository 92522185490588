import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ClusterRedisURI extends Component {
    state = {
        redisURI: this.props.redisURI
    }

    _handleChangeInput = e => {
        const {name, value} = e.target
        const {onUpdateCluster} = this.props

        this.setState({
            redisURI: value,
        }, onUpdateCluster(name, value))
    }

    render() {
        const {redisURI} = this.state
        
        return (
            <div className="ClusterRedisURI form-group">
                <label htmlFor="clusterRedisURI">Redis URI</label>
                <input type="text" className="form-control" id="clusterRedisURI" name="redis_uri" value={redisURI}
                       onChange={this._handleChangeInput}/>
            </div>
        )
    }
}

ClusterRedisURI.propTypes = {
    redisURI: PropTypes.string.isRequired,
    onUpdateCluster: PropTypes.func.isRequired,
}

export default ClusterRedisURI
