import React, {Component} from 'react'
import {Modal, ModalBody, ModalHeader} from 'reactstrap'
import PropTypes from 'prop-types'
import {changeWithrawalApprove} from '../../../../services/api/BillingServices'

class WithrawalApproveModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transactionID: '',
            message: {
                type: '',
                content: '',
            },
            loading: false,
        }
    }

    _handleChangeInput = e => {
        const {value, name} = e.target

        this.setState({
            [name]: value,
        })
    }

    _handleSubmitForm = (e) => {
        e.preventDefault()

        this._reqApproved()
    }

    _reqApproved = async () => {
        try {
            this.setState({loading: true})

            const {withrawalId} = this.props
            const {transactionID} = this.state

            const params = {
                id: withrawalId,
                transaction_id: transactionID,
            }

            const {success, message} = await changeWithrawalApprove(params)

            if (!success) throw new Error(message)

            this.props.onFetchWithdrawals()
        } catch (e) {
            this.setState({
                message: {
                    type: 'error',
                    content: e.message,
                },
            })
        }
    }

    render() {
        const {isOpen, onToggleModal} = this.props
        const {loading, message} = this.state

        return (
            <Modal isOpen={isOpen} className="TransactionOutCreationModal">
                <ModalHeader toggle={onToggleModal}>
                    Transaction ID
                </ModalHeader>

                <ModalBody>
                    {(message.type !== '') && (
                        <div className={`alert alert-${message.type === 'error' ? 'danger' : 'success'} fade show`}
                             role="alert">
                            {(typeof message.content === 'object') ?
                                message.content.map((er, index) => {
                                    return <span key={index}>{er}<br/></span>
                                })
                                :
                                message.content
                            }
                        </div>
                    )}
                    <form onSubmit={this._handleSubmitForm}>
                        <div className="form-group">
                            <label htmlFor="transactionID">Transaction ID</label>
                            <input type="text" name="transactionID" className="form-control" id="transactionID"
                                   onChange={this._handleChangeInput} required
                            />
                        </div>

                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {
                                    loading ? 'Submitting...' : 'Submit'
                                }
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        )
    }
}

WithrawalApproveModal.propTypes = {
    status: PropTypes.string.isRequired,
    withrawalId: PropTypes.string.isRequired,
}

export default WithrawalApproveModal
