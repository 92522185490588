import createApiServices from './createApiServices'

const createAPIRequest = () => {
    const currentStore = Object.assign({}, window.currentStore)

    if (!currentStore) throw new Error(`Can't get current store`)

    const { backoffice_api_url } = currentStore

    const baseUrl = backoffice_api_url + '/artwork'

    return createApiServices({ baseUrl })
}


export const getProductArtworks = (productId) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/artworks',
        method: 'GET',
        params: {
            product: productId,
        },
    })
}

export const listArtworks = (params) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/artworks',
        method: 'GET',
        params,
    })
}

export const deleteArtwork = (artworkId) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/artworks/${artworkId}`,
        method: 'DELETE'
    })
}

export const uploadArtwork = (productId, formData) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/artworks',
        params: {product_id: productId},
        method: 'POST',
        data: formData,
        timeout: 10 * 60 * 1000,
    })
}

export const getProductArtworksAttribute = (attribute) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/artworks/by-attribute',
        params: {product: attribute},
        method: 'GET',
        timeout: 10 * 60 * 1000,
    })
}


export const uploadArtworkAttributes = (productId, attributes, formData) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/artworks/${productId}/upload-artwork-by-attributes`,
        params: {attributes: attributes},
        method: 'POST',
        data: formData,
        timeout: 10 * 60 * 1000,
    })
}