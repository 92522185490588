import React, { Component } from 'react'
import InputSearchStore from './filter/InputSearchStore'
import TrademarkTableList from './TrademarkTableList'
import PFPagePagination from '../../shared/PFPagePagination'
import { getOrders } from '../../../services/api/TrademarkSevices'

class TrademarkPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: '',
            orders: [],
            loading: false,
            totalOrder: 100,
            storeID: '',
            total: 0,
            pages: 1,
            params: {
                page: 1,
                limit: 10,
                slug: '',
                order_code: '',
                status: 'all',
                is_read: 'all',
            }
        }

        this.timer = null
    }

    componentDidMount() {
        this._getOrder()
    }

    _getOrder = async () => {
        const { params } = this.state
        if (!params.slug) {
            delete params.slug
        } else {
            params.slug = params.slug.trim()
        }
        if (!params.order_code) {
            delete params.order_code
        } else {
            params.order_code = params.order_code.trim()
        }
        this.setState({
            loading: true
        })
        try {
            const { message, data, success } = await getOrders(params)
            if (!success) throw new Error(message)

            const { page, pages, limit, total } = data
            this.setState({
                orders: data.items || [], // unconfirmed
                error: '',
                total: total || 0,
                pages: pages || 1,
                params: {
                    ...this.state.params,
                    page: page || 1,
                    limit: limit || 10
                },
                loading: false
            })

        } catch (e) {
            this.setState({
                error: e.message,
                loading: false
            })
        }
    }

    _handleChangeInput = (e) => {
        const value = e.target.value
        const name = e.target.name

        if (this.timer) clearTimeout(this.timer)

        this.timer = setTimeout(
            this.setState(({ params }) => ({
                params: {
                    ...params,
                    page: 1,
                    [name]: value
                }
            }), () => this._getOrder()), 2000
        )
    }

    _handleChange = (property, value) => {
        this.setState(({ params }) => ({
            params: {
                ...params,
                page: 1,
                [property]: value
            }
        }), () => this._getOrder())
    }

    _handlePageChange = page => {
        const { params } = this.state
        this.setState({
            params: {
                ...params, page
            }
        }, () => this._getOrder())
    }

    render() {
        const { orders, loading, totalOrder, params, total, pages, error } = this.state
        const { page } = params

        return (
            <div className='ProductApprovalPage SectionInner'>
                <div className='OrderRefund filter align-items-center mb-4'>
                    <InputSearchStore onChange={this._handleChange} />

                    {/* <div className="TotalEntity col-auto p-0 ml-auto mt-3 mt-md-0">
                        <span className="Entity font-weight-bold">{total} orders</span>
                    </div> */}
                </div>
                {
                    error ?
                        <span className='text-danger'>{error}</span>
                        :
                        <TrademarkTableList
                            loading={loading}
                            orders={orders}
                            totalOrder={totalOrder}
                            onReloadData={this._getOrder}
                        />
                }
                {
                    !loading && <PFPagePagination page={page} pages={pages} onChangePage={this._handlePageChange} />
                }
            </div>
        )
    }
}

export default TrademarkPage
