import React from 'react'
import PropTypes from 'prop-types'

const PayoutFilterLimit = (props) => {
    const {onChange, payoutID} = props

    const handChange = (e) => {
        e.preventDefault()
        const value = e.target.value
        onChange(value)
    }

    return (
        <div className="PayoutFilterID d-flex align-items-center">
            <label className="mr-2 mb-0 font-weight-bold">Payout ID</label>
            <input type="text" value={payoutID} onChange={handChange}/>
        </div>
    )

}

PayoutFilterLimit.propTypes = {
    onChange: PropTypes.func.isRequired,
    payoutID: PropTypes.string.isRequired,
}

export default PayoutFilterLimit
