import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/billing' : 'https://admin-staging.merchize.com/api/billing'
const api = createAPIServices({baseUrl})

export const getPlans = () => {
    return api.makeAuthRequest({
        url: '/plans',
        method: 'GET'
    })
}

export const createPlan = (plan) => {
    return api.makeAuthRequest({
        url: `/plans`,
        method: 'POST',
        data: plan
    })
}

export const editPlan = (planId, plan) => {
    return api.makeAuthRequest({
        url: `/plans/${planId}`,
        method: 'POST',
        data: plan
    })
}

export const removePlan = (planId) => {
    return api.makeAuthRequest({
        url: `/plans/${planId}`,
        method: 'DELETE'
    })
}

export const getPlanDetails = (planId) => {
    return api.makeAuthRequest({
        url: `/plans/${planId}`,
        method: 'GET'
    })
}

export const getFeatures = () => {
    return api.makeAuthRequest({
        url: '/features',
        method: 'GET'
    })
}

export const createNewFeature = (data) => {
    return api.makeAuthRequest({
        url: '/features',
        method: 'POST',
        data
    })
}

export const editFeature = (featureId, feature) => {
    return api.makeAuthRequest({
        url: `/features/${featureId}`,
        method: 'POST',
        data: feature
    })
}

export const removeFeature = (featureId) => {
    return api.makeAuthRequest({
        url: `/features/${featureId}`,
        method: 'DELETE'
    })
}

export const createStoreSubscription = (storeId, planId) => {
    return api.makeAuthRequest({
        url: '/subscriptions',
        method: 'POST',
        data: {
            store: storeId,
            plan: planId
        }
    })
}

export const statisticStoresBilling = (formData) => {
    return api.makeAuthRequest({
        url: '/statistic/stores-billing',
        method: 'POST',
        data: formData
    })
}

export const exportContestAllStore = () => {
    return api.makeAuthRequest({
        url: '/credit/general-export',
        method: 'GET'
    })
}

export const exportContestAllStoreGoogleSheet = (data) => {
    return api.makeAuthRequest({
        url: '/credit/sheet-export-all',
        method: 'POST',
        data: data
    })
}

export const exportContestDetailStore = (storeId) => {
    return api.makeAuthRequest({
        url: `/store/${storeId}/order-credit-export`,
        method: 'GET'
    })
}
