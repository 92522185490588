import React, {Component} from 'react'
import {updateShippingZone} from '../../../../../services/api/ShippingSettingsServices'
import {toaster} from '../../../../shared/PFToast'
import getHistory from '../../../../../store/getHistory'
import PropTypes from 'prop-types'

class ZoneActionsContainer extends Component {
    state = {
        loading: false,
        error: '',
    }

    _toggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    _handleClickSave = e => {
        e.preventDefault()

        this._updateShippingZone()
    }

    _updateShippingZone = async () => {
        const {zoneId, onGetZoneData, profilesId} = this.props
        const zoneData = onGetZoneData()

        this.setState({
            loading: true,
        })

        try {
            const {success, message} = await updateShippingZone(zoneId, zoneData)

            if (!success) throw new Error(message)

            toaster({message: 'Updated shipping zone successfully!', type: 'success', duration: 5000})

            const history = getHistory()
            history.push(`/a/settings-shipping/profiles-edit/${profilesId}`)

            return this.setState({
                loading: false,
                error: '',
            })

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data

            toaster({message: message ? message : e.message, type: 'error', duration: 5000})

            this.setState({
                loading: false,
            })
        }
    }

    render() {
        const {loading, error} = this.state

        return (
            <div className="ZoneActionsContainer">
                <div className="d-flex justify-content-between">
                    <button type="submit" className="btn btn-primary UpdateZone ml-auto" onClick={this._handleClickSave}
                            disabled={loading}>
                        {
                            loading ? <span className="spin m-0"/> : 'Save changes'
                        }
                    </button>
                </div>
                {
                    !!error && <div className="mt-3 text-danger text-right">{error}</div>
                }
            </div>
        )
    }
}

// ZoneActionsContainer.defaultProps = {}

ZoneActionsContainer.propTypes = {
    profilesId: PropTypes.string.isRequired,
}

export default ZoneActionsContainer
