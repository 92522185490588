import React, {Component} from 'react'
import moment from 'moment'
import {getStatAllStores} from '../../../../../services/api/ChartServices'
import Chart from './Chart'
import TimeRangeFilter from './TimeRangeFilter'
import IntervalFilter from './IntervalFilter'
import getChartField from '../helpers/getChartField'

const indexArr = ['order', 'importer', 'product']
class ChartContainer extends Component {
    static jsfiddleUrl = 'https://jsfiddle.net/alidingling/xqjtetw0/'

    state = {
        err: '',
        datas: [],
        type: 'relative',
        value: '7-day-ago',
        interval: '1d',
    }

    componentDidMount() {
        this._getStatAllStoresByIndexes()
    }

    _getQuery = indexStr => {
        const {type, value, interval} = this.state
        return {
            timeRange: `${type}|${value}`,
            interval,
            fields: indexStr,
        }
    }

    _getIndexes = index => {
        const indexArr = getChartField(index)
        return `${index}:${indexArr.join(',')}`
    }

    _getStatAllStoresByIndexes = async () => {
        const datas = []
        for (const index of indexArr) {
            const indexStr = this._getIndexes(index)
            const data = await this._getStat(indexStr)
            if (!Object.keys(data).length) break
            const buckets = Object.keys(data).length ? data.buckets : []
            const parsedData = this._parseBuckets(indexStr, buckets)
            datas.push(parsedData)
        }
        if (datas.length) {
            return this.setState({
                datas,
            })
        }
    }

    _parseBuckets = (indexStr, buckets) => {
        if (!buckets.length) return []
        const data = []
        const index = indexStr.slice(0, indexStr.indexOf(':'))
        const fieldArr = indexStr.slice(indexStr.indexOf(':') + 1, indexStr.length).split(',')
        let value = {}
        buckets.forEach(bucket => {
            const {key_as_string} = bucket
            fieldArr.forEach(field => {
                const fieldValue = bucket[`${index}.${field}`].value
                const fixedValue = field === 'conversionRate' ? fieldValue.toFixed(2) : fieldValue
                value = {
                    ...value,
                    [field]: fixedValue,
                }
            })
            const item = {
                date: moment(key_as_string).format('MM/DD/YYYY'),
                ...value,
            }
            data.push(item)
        })
        return data
    }

    _getStat = async indexStr => {
        if (!indexStr) return
        const query = this._getQuery(indexStr)
        const {success, message, data} = await getStatAllStores({query})
        if (!success) {
            this.setState({
                err: message,
            })
            return {}
        }
        return data
    }

    _handleTimeRangeChange = (type, value) => {
        this.setState(
            {
                type,
                value,
            },
            () => this._getStatAllStoresByIndexes()
        )
    }

    _handleIntervalChange = interval =>
        this.setState(
            {
                interval,
            },
            () => this._getStatAllStoresByIndexes()
        )

    render() {
        const {datas, type, value} = this.state
        return (
            <div className='ChartContainer'>
                <div className='Filter'>
                    <TimeRangeFilter type={type} value={value} handleTimeRangeChange={this._handleTimeRangeChange} />
                    <IntervalFilter handleIntervalChange={this._handleIntervalChange} />
                </div>

                <div className='Charts'>
                    {!!datas.length && datas.map((data, idx) => <Chart name={indexArr[idx]} key={idx} data={data} />)}
                </div>
            </div>
        )
    }
}

export default ChartContainer
