import React, {Component} from 'react'
import CreateNewProductLine from './CreateNewProductLine'


class CreateNewProductLineContainer extends Component {
    render() {

        const params = new URLSearchParams(this.props.location.search)
        const duplicateProductId = params.get('ref') || ''

        return (
            <div>
                <section className="CreateProductPageContainer">
                    <h1 className="PageTitle">Create Product Line</h1>
                    <div className="SectionInner">
                        <CreateNewProductLine duplicateProductId={duplicateProductId}/>
                    </div>
                </section>
            </div>
        )
    }
}

export default CreateNewProductLineContainer
