import React, {Component} from 'react'
import PropTypes from 'prop-types'
import AccountStoreDetails from './AccountStoreDetails'
import {getStoresByAccount} from '../../../services/api/StoreAccountServices'

class ListStores extends Component {
    state = {
        stores: [],
        loading: true,
        err: '',
    }

    componentDidMount() {
        this._getStoresByAccount()
    }

    _getStoresByAccount = async () => {
        try {
            const {accountId} = this.props
            const {success, data, message} = await getStoresByAccount(accountId)

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }
            const {stores} = data
            this.setState({
                stores: Array.isArray(stores) ? stores : [],
                loading: false,
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    render() {
        const {stores, err} = this.state

        return (
            <div className='ListStores'>
                <div className='GroupName'>
                    <h5>Stores </h5>
                </div>
                {!!err && <span className='text-warning'>{err}</span>}
                <div className='GroupDetail'>
                    {!!stores.length && stores.map(item => <AccountStoreDetails key={item._id} item={item} />)}
                </div>
            </div>
        )
    }
}

ListStores.propTypes = {
    accountId: PropTypes.string.isRequired,
}

export default ListStores
