import React from 'react'
import {confirmModal} from '../../../shared/PFConfirmModalV2'
import {actionPayoutApproval} from '../../../../services/api/BillingPayoutServices'
import {toaster} from '../../../shared/PFToast'
import PropTypes from 'prop-types'

const PayoutApprovalActionApproval = (props) => {

    const {ID, onFetchPayout, am_updated} = props

    const {account = {}} = Object.assign({}, am_updated)
    const {email = ''} = Object.assign({}, account)

    const handleClickApproval = e => {
        e.preventDefault()
        confirmModal({
            confirmText: 'Yes',
            cancelText: 'No',
            size: 'md',
            confirmColor: 'primary',
            title: 'Approve by AM',
            message: <div>
                {
                    email && <p className="mb-1"><b>Supporter:</b> {email}</p>
                }
                <p>This request will be sent to accountants after your confirmation.</p>
            </div>,
            onOk: handleApproval
        })
    }

    const handleApproval = async () => {
        try {
            const {success, message} = await actionPayoutApproval(ID)

            if (!success) throw new Error(message)

            onFetchPayout()

            toaster({message: 'Approved successfully!', type: 'success', duration: 5000})

        } catch (e) {
            toaster({message: e.message, type: 'error', duration: 5000})
        }
    }

    return (
        <button onClick={handleClickApproval} className='btn btn-sm btn-success'>Approve by AM</button>
    )

}

PayoutApprovalActionApproval.propTypes = {
    onFetchPayout: PropTypes.func.isRequired,
    ID: PropTypes.string.isRequired,
}

export default PayoutApprovalActionApproval
