import React, {Component} from 'react'
import moment from 'moment'
import {deleteArtwork} from '../../../../../services/api/ArtworkServices'
import PubSub from '../../../helper/ProductEditBroker'
import className from 'classnames'
import PropTypes from 'prop-types'
import {toaster} from '../../../../shared/PFToast'
import {confirmModal} from '../../../../shared/PFConfirmModalV2'
import FeatureImage from '../../../../shared/FeatureImage'

class ArtworkItem extends Component {

    _handleClickRemove = e => {
        e.preventDefault()

        confirmModal({
            confirmText: 'Delete',
            confirmColor: 'danger',
            title: 'Confirm delete',
            message: 'Are you sure want to delete this artwork?',
            onOk: this._handleRemoveArtwork,
        })
    }

    _handleRemoveArtwork = async () => {
        try {
            const {artwork} = this.props
            const {success, message} = await deleteArtwork(artwork._id)

            if (!success) {
                window.alert(message)

                return null
            }

            toaster({
                type: 'warning',
                message: <span><b>Notice:</b> Changing artwork of this product will only reflect on future orders which hasn't been fulfilled yet.</span>,
                duration: 5000,
            })

            PubSub.publish('PRODUCT_EDIT_RELOAD_ARTWORKS')
        } catch (e) {
            window.alert(e.message)
        }
    }

    render() {
        const {artwork} = this.props
        const {created, name, thumbnail, is_deleted} = artwork
        const dateFormatted = moment(created).format('MMMM Do YYYY h:mm a')
        return (
            <div
                className={className('ArtworkItem position-relative border rounded overflow-hidden', {isDeleted: is_deleted})}>
                <FeatureImage src={thumbnail} width="100%" />

                <div className="Created">
                    <small>{dateFormatted}</small>
                </div>

                {
                    is_deleted &&
                    <span className="DeleteLabel"><span className="badge badge-dark">Deleted</span></span>
                }
            </div>
        )
    }
}

ArtworkItem.propTypes = {
    artwork: PropTypes.object.isRequired,
}

export default ArtworkItem
