import React, {Component} from 'react'
import PropTypes from 'prop-types'
import KeyColumn from './columns/KeyColumn'
import ValueColumn from './columns/ValueColumn'
import ActionColumn from './columns/ActionsColumn'

class RenderTableRow extends Component {
    render() {
        const {item} = this.props
        return (
            <tr>
                <KeyColumn item={item} />
                <ValueColumn item={item} />
                <ActionColumn item={item} />
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
}

export default RenderTableRow
