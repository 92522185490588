import React, {Component} from 'react'
import {getListResourceForStoreId} from '../../../../services/api/ResourceService'
import ObjectInspector from 'react-object-inspector'

const _tableColumns = [
    'Name',
    'Kind',
    'Metadata',
    'Specs',
]

class ResourcePage extends Component {
    state = {
        items: [],
        loading: true,
        err: '',
    }

    componentDidMount() {
        this._getListResourceForStoreId()
    }

    _getListResourceForStoreId = async () => {
        try {
            const { _id } = this.props.storeDetails
            const {success, data, message} = await getListResourceForStoreId(_id)

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            this.setState({
                items: data,
                loading: false,
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    render() {
        const {items = [], loading, err} = this.state
        const resource = items.map(item => item.resource)
        return (
            <div className='ClustersPage'>
                <div className='ClustersPageInner SectionInner'>
                    {!!err && <span className='text-warning'>{err}</span>}
                    <div className='TableContainer table-responsive '>
                    <table className='table table-striped table-light'>
                        <thead className='TableHeader'>
                            <tr>
                                {_tableColumns.map(column => (
                                    <th scope='col' key={column}>
                                        {column}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className='TableBody'>
                            {!loading &&
                                resource.map((resource, index) => (
                                    <tr className='RenderTableRow' key = {index}>
                                        <td className='HostColumn'>
                                            <span>{resource.name || ''}</span>
                                        </td>
                                        <td className='HostColumn'>
                                            <span>{resource.kind || ''}</span>
                                        </td>
                                        <td className='HostColumn'>
                                            <span><ObjectInspector data={resource.metadata || {}} /></span>
                                        </td>
                                        <td className='HostColumn'>
                                            <span><ObjectInspector data={resource.specs || {}} /></span>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        )
    }
}

export default ResourcePage
