import React, {Component} from 'react'
import PubSub from '../../StoreBroker'
import {Modal, ModalBody, ModalHeader} from "reactstrap"
import {getStoreAccounts} from "../../../../services/api/StoreServices"
import {createTransactionsOut} from '../../../../services/api/BillingServices'
import PropTypes from "prop-types"

class TransactionOutCreationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            amount: "",
            note: "",
            owner: "",
            message: {
                type: "",
                content: ""
            },
            loading: false,
            accounts: []
        }
    }

    componentDidMount() {
        this._fetchStoreAccounts()
    }

    _fetchStoreAccounts = async () => {
        const storeID = this.props.store._id
        const {data, success, message} = await getStoreAccounts(storeID, "")

        if (!success) {
            throw new Error(message)
        }

        this.setState({
            accounts: data
        })
    }

    _handleChangeInput = e => {
        const {value, name} = e.target

        this.setState({
            [name]: value,
        })
    }

    _handleFormValidate = () => {
        const {amount, note, owner} = this.state
        const error = []

        if(!amount) error.push('Amount is required')
        if(!note) error.push('Note is required')
        if(!owner) error.push('Please select a Owner')

        return error
    }

    _handleSubmitForm = e => {
        e.preventDefault()
        this._handleCreateTransactionOut()
    }

    _handleCreateTransactionOut = async () => {
        const {amount, note, owner} = this.state

        try {
            this.setState({
                loading: true,
            })

            const formValidate = this._handleFormValidate()

            if(formValidate.length>0) {
                this.setState({
                    message: {
                        type: "error",
                        content: formValidate
                    },
                    loading: false,
                })
                return
            }

            const params = {
                amount,
                note,
                owner
            }

            const {success, message} = await createTransactionsOut(params)

            if(!success) throw new Error(message)

            PubSub.publish("BILLING_FETCH_TRANSACTION_OUT")

            this.setState({
                message: {
                    type: "success",
                    content: "Transaction out successfully",
                },
                loading: false,
            }, () => {
                setTimeout(this.props.onToggleModal, 1000)
            })
        } catch (e) {
            this.setState({
                message: {
                    type: "error",
                    content: e.message,
                },
                loading: false,
            })
        }
    }

    render() {
        const {isOpen, onToggleModal} = this.props
        const {accounts, loading, message} = this.state

        const elmSelectAccount = accounts.map((account) => {
            return <option key={account.account._id} value={account.account._id}>{account.account.name} ({account.account.email})</option>
        })

        return (
            <Modal isOpen={isOpen} className="TransactionOutCreationModal">
                <ModalHeader toggle={onToggleModal}>
                    Create transaction out
                </ModalHeader>

                <ModalBody>
                    {(message.type !== '') && (
                        <div className={`alert alert-${message.type==='error' ? "danger" : "success"} fade show`} role="alert">
                            {(typeof message.content === 'object') ?
                                message.content.map((er,index)=>{
                                    return <span key={index}>{er}<br/></span>
                                })
                                :
                                message.content
                            }
                        </div>
                    )}
                    <form onSubmit={this._handleSubmitForm}>
                        <div className="form-group">
                            <label htmlFor="transactionOutAmount">Amount</label>

                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text font-weight-500">$</div>
                                </div>
                                <input type="number" name="amount" className="form-control" id="transactionOutAmount"
                                       onChange={this._handleChangeInput}
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="transactionOutNote">Note</label>
                            <textarea name="note" className="form-control" cols="30" rows="10" id="transactionOutNote"
                                      onChange={this._handleChangeInput}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="transactionOutAccount">Account</label>
                            <select name="owner" className="form-control"
                                    onChange={this._handleChangeInput}
                            >
                                <option value="">Select Account</option>
                                {elmSelectAccount}
                            </select>
                        </div>

                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {
                                    loading ? "Submitting..." : "Submit"
                                }
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        )
    }

}

TransactionOutCreationModal.propTypes = {
    store: PropTypes.object.isRequired,
}

export default TransactionOutCreationModal