import getEnv from "../../helpers/common/getEnv"
import createApiServices from "./createApiServices"

const baseUrl = `${getEnv('baseSellerHost')}/api/product-line`
// const baseUrl = `https://seller-staging.merchize.com/api/product-line`
const api = createApiServices({ baseUrl })

export const getHighlightProducts = (params) => {
    return api.makeAuthRequest({
        url: `/manager/product-lines-highlight`,
        method: 'GET',
        params
    })
}

export const addHighlightProduct = (data) => {
    return api.makeAuthRequest({
        url: `/manager/product-lines-highlight/add-item`,
        method: 'POST',
        data
    })
}

export const removeHighlightProduct = (data) => {
    return api.makeAuthRequest({
        url: `/manager/product-lines-highlight/remove-item`,
        method: 'POST',
        data
    })
}

export const sortHighlightProduct = (data) => {
    return api.makeAuthRequest({
        url: `/manager/product-lines-highlight/move-item`,
        method: 'PUT',
        data
    })
}
