import ImportRuleModal from './../import-rule/ImportRuleModal'
import ImportRuleBySkuProductModal from '../import-rule-by-sku-product/ImportRuleBySkuProductModal'
import ImportLogsModal from '../import-logs/ImportLogsModal'

const ExportActionModal = (props) => {
    const {isOpen, action} = props

    const _renderModal = (action) => {
        switch (action) {
            case 'import_variants':
                return <ImportRuleModal {...props}/>
            case 'import_products':
                return <ImportRuleBySkuProductModal {...props}/>
            case 'import_log':
                return <ImportLogsModal {...props}/>
        }
    }

    return (
        <div>
            {isOpen && _renderModal(action)}
        </div>
    )
}

export default ExportActionModal
