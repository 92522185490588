import React, { Component } from 'react'
import PFTable from "../../../../shared/PFTable"
import PropTypes from "prop-types"
import DailyTopupReportTableHead from './DailyTopupReportTableHead'
import DailyTopupReportTableBody from './DailyTopupReportTableBody'

class DailyTopupReportTable extends Component {

    render() {
        const { reportData } = this.props
        return (
            <div className="DailyTopupReportTable table-responsive">
                {
                    <PFTable source={reportData}>
                        {
                            table => (
                                <>
                                    <DailyTopupReportTableHead {...table} {...this.props} />
                                    <DailyTopupReportTableBody {...table} {...this.props} />
                                </>
                            )
                        }
                    </PFTable>
                }
            </div>
        )
    }
}

DailyTopupReportTable.propTypes = {
    reportData: PropTypes.array.isRequired,
}

export default DailyTopupReportTable