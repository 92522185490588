import React, {Component} from 'react'
import FromData from "./table-data/FromData"
import CostData from "./table-data/CostData"
import WeightRulesActions from "./table-data/WeightRulesActions"

// import PropTypes from 'prop-types'

class WeightRulesRow extends Component {
    render() {
        const {onRemoveRow, rowIndex, onUpdateWeightData, rowData} = this.props
        const {cost, from} = rowData

        return (
            <tr className="WeightRulesRow">
                <FromData rowIndex={rowIndex} from={from} onUpdateWeightData={onUpdateWeightData}/>
                <CostData onUpdateWeightData={onUpdateWeightData} rowIndex={rowIndex} cost={cost}/>
                <WeightRulesActions onRemoveRow={onRemoveRow} rowIndex={rowIndex}/>
            </tr>
        )
    }
}

// WeightRulesRow.defaultProps = {}

// WeightRulesRow.propTypes = {}

export default WeightRulesRow
