import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input} from 'reactstrap'
import classNames from 'classnames'
import {updateStoreSettings, removeStoreSettings} from '../../../services/api/StoreSettingsServices'
import TableContainer from './TableContainer'

class StoreSettings extends Component {
    state = {
        value: '',
        key: '',
        dropdownOpen: false,
        actionName: 'Action',
        err: '',
        message: '',
        loading: false,
    }

    _removeStoreSettings = async () => {
        this.setState({
            err: '',
            loading: true,
        })
        const {storeId} = this.props
        const {key, actionName} = this.state
        if (!key) return
        try {
            const {success, message} = await removeStoreSettings({
                storeId,
                key,
            })
            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }
            this.setState({
                loading: false,
            })
            this._getSuccessMessage(actionName)
        } catch (e) {
            return this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _updateStoreSettings = async () => {
        this.setState({
            err: '',
            loading: true,
        })
        const {storeId} = this.props
        const {key, value, actionName} = this.state
        if (!key || !value) return
        const keyObj = {[key]: value}
        try {
            const {success, message} = await updateStoreSettings({
                storeId,
                keyObj,
            })
            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }
            this.setState({
                loading: false,
            })
            this._getSuccessMessage(actionName)
        } catch (e) {
            return this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _getSuccessMessage = actionName => {
        this.setState({
            message: `${actionName} successfully`,
        })
        setTimeout(() => {
            this.setState({
                message: '',
            })
        }, 2000)
    }

    _toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen,
        }))
    }

    _checkInput = () => {
        const {key, value, actionName} = this.state
        const emptyField = []
        if (!key) {
            emptyField.push('Key')
        }
        if (!value && actionName !== 'Delete') {
            emptyField.push('Value')
        }
        if (emptyField.length) {
            this.setState({
                err: `${emptyField.join(', ')}  cannot be empty!`,
            })
            return false
        }
        return true
    }

    _handleClick = () => {
        const {actionName} = this.state
        const isValidInput = this._checkInput()
        if (!isValidInput) return
        if (actionName === 'Action') {
            return this.setState({
                err: 'Please choose an action!',
            })
        }
        const action = actionName.toLowerCase()
        switch (action) {
            case 'delete':
                this._removeStoreSettings()
                break
            case 'update':
                this._updateStoreSettings()
                break
            default:
                break
        }
    }

    _handleActionChange = e => {
        this.setState({
            actionName: e.target.name,
        })
    }

    _handleInputChange = e => {
        const {name} = e.target
        const {value} = e.target
        this.setState({
            [name]: value,
        })
    }

    render() {
        const {key, err, value, dropdownOpen, actionName, message, loading} = this.state
        const {storeId} = this.props

        return (
            <div className='StoreSettings'>
                {!!err && <p className='errText text-warning'>{err}</p>}
                {message && <span className='text-success'>{message}</span>}
                <div className='Input'>
                    <Input name='key' placeholder='Key' value={key} onChange={this._handleInputChange} />
                    <Input
                        name='value'
                        placeholder='Value'
                        disabled={actionName === 'Delete'}
                        value={value}
                        onChange={this._handleInputChange}
                    />
                    <div className='Dropdown'>
                        <Dropdown isOpen={dropdownOpen} toggle={this._toggle}>
                            <DropdownToggle caret>{actionName}</DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem name='Update' onClick={this._handleActionChange}>
                                    Update
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem name='Delete' onClick={this._handleActionChange}>
                                    Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className='Button'>
                    <button
                        className={classNames('btn btn-primary btn-sm', {
                            disabled: loading,
                        })}
                        type='button'
                        onClick={this._handleClick}>
                        {loading ? (
                            <>
                                <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
                                Loading...
                            </>
                        ) : (
                            'Submit'
                        )}
                    </button>
                </div>
                <div className='StoreSettings__Table'>
                    <TableContainer message={message} storeId={storeId} />
                </div>
            </div>
        )
    }
}

StoreSettings.propTypes = {
    storeId: PropTypes.string.isRequired,
}

export default StoreSettings
