import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {changeStatusGroup} from '../../../services/api/GroupService'

class GroupItem extends Component {
    constructor(props) {
        super(props)
        this._onOffGroup = this._onOffGroup.bind(this)
    }

    async _onOffGroup(e, status) {
        e.preventDefault()

        const r = window.confirm('Are you sure want to check status this group?')
        if (!r) return false

        try {
            const {success, message} = await changeStatusGroup({
                clusterId: this.props.clusterId,
                namespace: this.props.group.namespace,
                status,
            })

            if (!success) {
                throw new Error(message)
            }

            this.props.onRefresh()
        } catch (e) {
            window.alert(e.message)
        }
    }

    render() {
        const {index, countStores, namespace, active} = this.props.group

        return (
            <tr>
                <td>{index}</td>
                <td>{namespace}</td>
                <td>{countStores || 0}</td>
                <td className="text-center">
                    {countStores !== 0 && <i className="text-info fas fa-check" style={{cursor: 'not-allowed'}} />}
                    {countStores === 0 &&
                        (active !== false ? (
                            <i
                                className="text-danger fas fa-times cursor-pointer"
                                onClick={(e) => this._onOffGroup(e, false)}
                            />
                        ) : (
                            <i
                                className="text-success fas fa-check cursor-pointer"
                                onClick={(e) => this._onOffGroup(e, true)}
                            />
                        ))}
                </td>
            </tr>
        )
    }
}

GroupItem.propTypes = {
    group: PropTypes.object.isRequired,
    onRefresh: PropTypes.func.isRequired,
    clusterId: PropTypes.string.isRequired,
}

export default GroupItem
