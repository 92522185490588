import React, {Component} from 'react'
import formatThousandNumber from "../../../../../helpers/common/formatThousandNumber"
import PropTypes from 'prop-types'

class TransactionOutAmount extends Component {
    render() {
        const {amount, type} = this.props
        const amountFormatted = formatThousandNumber(amount, 2)

        return (
            <td className="TransactionOutAmount text-right">
                {"out" === type && "-"} ${amountFormatted}
            </td>
        )
    }
}

TransactionOutAmount.propTypes = {
    amount: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
}

export default TransactionOutAmount
