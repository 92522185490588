import React from "react"
import DatePicker from "react-datepicker"
import NumberFormat from "react-number-format"
import { Input, Label } from 'reactstrap'
import "react-datepicker/dist/react-datepicker.css";

const SettingExchangeRate = (props) => {

    const { exchangeRate, exchangeApplyTime, exchangeNotificationTime, onChangeSetting, error } = props

    return (
        <>
            <div className="SectionDetails">
                <p className="font-bold">Exchange rate</p>
            </div>

            <div className="SectionSetting">
                <div className="SectionInner">
                    <div className="mb-3">
                        <Label className="d-block">
                            <div className="mb-2">Rate<span className="text-danger">*</span></div>
                            <NumberFormat
                                maxLength={7}
                                decimalScale={2}
                                value={exchangeRate}
                                thousandSeparator={true}
                                allowNegative={false}
                                decimalSeparator={false}
                                className={`form-control${error.exchange_rate ? ' invalid-input' : ''}`}
                                onValueChange={(e) => onChangeSetting('exchange_rate', e)}
                            />
                        </Label>
                        <p className='error-message'>{error.exchange_rate}</p>
                    </div>
                    <div className="mb-3">
                        <Label className="d-block">
                            <div className="mb-2">Apply time<span className="text-danger">*</span></div>
                            <DatePicker
                                selected={exchangeApplyTime}
                                on
                                onChange={(date) => onChangeSetting('exchange_apply_time', date)}
                                timeInputLabel="Time:"
                                dateFormat="dd/MM/yyyy h:mm aa"
                                showTimeInput
                                isClearable
                                minDate={new Date()}
                                className={`form-control${error.exchange_apply_time ? ' invalid-input' : ''}`}
                            />
                        </Label>
                        <p className='error-message' style={{marginTop: '-10px'}}>{error.exchange_apply_time}</p>
                    </div>
                    <div className="mb-3">
                        <Label className="d-block">
                            <div className="mb-2">Notification time</div>
                            <DatePicker
                                selected={exchangeNotificationTime}
                                onChange={(date) => onChangeSetting('exchange_notification_time', date)}
                                timeInputLabel="Time:"
                                dateFormat="dd/MM/yyyy h:mm aa"
                                showTimeInput
                                isClearable
                                minDate={new Date()}
                                className={`form-control`}
                            />
                        </Label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingExchangeRate