import React, {Component} from 'react'
import TableFilter from '../../shared/TableFilter'
import TablePagination from './TablePagination'
import {exportProductLineVariant, getProductLines} from '../../../services/api/ProductLinesServices'
import TableContainer from './TableContainer'
import Link from 'react-router-dom/Link'
import StoreModeFilterSelect from './StoreModeFilterSelect'
import CategoriesSelector from './CategoriesSelector'
import ProductLineMappingModal from './ProductLineMappingModal'
import {toastError} from '../../amazon/helpers/ToastHelper'
import ImportSKUModal from './ImportSKUModal'

class ProductLines extends Component {
    state = {
        items: [],
        loading: false,
        loadingExport: false,
        params: {
            page: 1,
            limit: 10,
            title: '',
            storeMode: '',
            category: '',
            available_for_campaign: false,
            available_for_fulfill: true,
        },
        searchFieldSelected: 'Product Line',
        total: 0,
        pages: 1,
        err: '',
        open: false,
        openImportMapping: false,
        openImportNewSku: false,
    }

    _inputFile = null

    componentDidMount() {
        this._getProductLines()
    }

    _getProductLines = async () => {
        const {params} = this.state

        try {
            this.setState({loading: true})
            const {success, data, message} = await getProductLines(params)
            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            const {total, pages, products} = Object.assign({}, data)

            this.setState({
                items: Array.isArray(products) ? products : [],
                pages: pages ? pages : 0,
                total: total ? total : 0,
                loading: false,
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _handleChangeFilter = (e) => {
        let {name, value} = e.target
        value = name === 'available_for_fulfill' ? this._parseBool(value) : value
        this.setState((prevState) => ({params: {...prevState.params, [name]: value}}), this._getProductLines)
    }

    _handleChangeCheckbox = (property) => (e) => {
        const value = e.target.checked
        this.setState((prevState) => ({params: {...prevState.params, [property]: value}}), this._getProductLines)
    }

    _parseBool = (value) => {
        return value === 'true'
    }

    _handlePageChange = (page) => {
        this.setState(
            {
                params: {...this.state.params, page},
            },
            () => this._getProductLines()
        )
    }

    _handleSelectCategories = (options = []) => {
        const categories = !options ? [] : options
        const firstSelect = categories[0] || {id: ''}
        const {id} = firstSelect

        this.setState(({params}) => {
            return {
                params: {
                    ...params,
                    page: 1,
                    category: id,
                    loading: true,
                },
            }
        }, this._getProductLines)
    }

    _onSubmitFilter = ({limit, value, field, page}) => {
        const fieldMap = {
            'Product Line': 'title',
            'FFM Product Type': 'ffm_mapping_product_type',
        }

        const parsedField = fieldMap[field]

        this.setState(
            ({params}) => ({
                params: {
                    ...{
                        limit,
                        page: '',
                        loading: '',
                    },
                    ...{
                        [parsedField]: value,
                        limit,
                        page: 1,
                        loading: false,
                    },
                },
                searchFieldSelected: field,
            }),
            () => {
                this._getProductLines()
            }
        )
    }

    _toggleImportProductLineMapping = () =>
        this.setState((prevState) => ({openImportMapping: !prevState.openImportMapping}))

    _exportProductLine = async () => {
        try {
            this.setState({loadingExport: true})
            const payload = {...this.state.params}
            delete payload.page
            delete payload.limit
            const response = await exportProductLineVariant(payload)
            const url = window.URL.createObjectURL(new Blob([response]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `product_lines.csv`)
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            toastError(error.message)
        } finally {
            this.setState({loadingExport: false})
        }
    }

    _toggleImportNewSku = () => this.setState((prevState) => ({openImportNewSku: !prevState.openImportNewSku}))

    render() {
        const {
            items,
            params,
            pages,
            loading,
            err,
            total,
            openImportMapping,
            searchFieldSelected,
            loadingExport,
            openImportNewSku,
        } = this.state
        const {page, limit, storeMode, available_for_campaign, available_for_fulfill} = params

        return (
            <div className="ProductLines">
                <div className="d-flex justify-content-between align-items-center">
                    <h2>Product Line</h2>
                    <div className="addbtn">
                        <Link to="product-lines/highlight" className="btn btn-success mr-3">
                            Highlight products
                        </Link>
                        <button
                            disabled={loadingExport}
                            className="btn btn-primary mr-3"
                            onClick={this._exportProductLine}
                        >
                            {loadingExport && <i className="fas fa-circle-notch fa-spin mr-2" />}
                            Export product line
                        </button>
                        <button className="btn btn-primary mr-3" onClick={this._toggleImportNewSku}>
                            Import new SKU
                        </button>
                        <button className="btn btn-primary mr-3" onClick={this._toggleImportProductLineMapping}>
                            Import product line mapping
                        </button>
                        <Link to="product-lines/create" className="btn btn-primary">
                            Create new product line
                        </Link>
                    </div>
                    {openImportNewSku && <ImportSKUModal isOpen={openImportNewSku} toggle={this._toggleImportNewSku} />}
                    {openImportMapping && (
                        <ProductLineMappingModal
                            isOpen={openImportMapping}
                            toggle={this._toggleImportProductLineMapping}
                            refetch={this._getProductLines}
                        />
                    )}
                </div>

                <div className="ProductLinesInner SectionInner">
                    {!!err && <span className="text-warning">{err}</span>}
                    <div className="mb-3">
                        <div className="align-items-center row mb-2">
                            <TableFilter
                                defaultLimit={limit}
                                onSubmitForm={this._onSubmitFilter}
                                searchField={['Product Line', ['FFM Product Type']]}
                                searchFieldSelected={searchFieldSelected}
                                placeholders={{
                                    'Product Line': 'Search for product line',
                                    'FFM Product Type': 'Search for fulfillment product type',
                                }}
                            />
                            <div className="Filter d-flex align-items-center col">
                                <div className="FilterAttributes mr-3">
                                    <StoreModeFilterSelect
                                        onChange={this._handleChangeFilter}
                                        value={storeMode || ''}
                                    />
                                </div>

                                <CategoriesSelector
                                    multiSelect={false}
                                    defaultSelectedOptions={{value: '', label: 'All'}}
                                    handleSelectCollection={this._handleSelectCategories}
                                />
                                <div className="TotalEntity ml-3">
                                    <strong className="Entity">{total} items</strong>
                                </div>
                            </div>
                        </div>
                        <div className="mb-1 d-flex align-items-center">
                            <div className="AvailableForFulfullFilterSelect Select m-0">
                                <span>Fulfill status</span>
                                <select
                                    name="available_for_fulfill"
                                    onChange={this._handleChangeFilter}
                                    value={available_for_fulfill}
                                >
                                    <option value={true}>Available</option>
                                    <option value={false}>Unavailable</option>
                                </select>
                            </div>
                            <div className="custom-control custom-checkbox ml-3">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="ProductLineAvailableCampaign"
                                    checked={available_for_campaign}
                                    onChange={this._handleChangeCheckbox('available_for_campaign')}
                                />
                                <label className="custom-control-label" htmlFor="ProductLineAvailableCampaign">
                                    Available for campaign
                                </label>
                            </div>
                        </div>
                    </div>

                    <TableContainer
                        totalItems={total}
                        items={items}
                        page={page}
                        limit={limit}
                        loading={loading}
                        refetchProductLine={this._getProductLines}
                    />
                    <TablePagination page={page} pages={pages} handlePageChange={this._handlePageChange} />
                </div>
            </div>
        )
    }
}

export default ProductLines

