import React, {useEffect} from 'react'

const ProductVariantFilter = (props) => {
    const {attributes, handleFilterVariants, filter, tab, updateFilter} = props

    const _onFilterVariant = (e) => {
        const {name, value} = e.target
        const newFilter = {...filter}
        if (value) {
            newFilter[name] = value
        } else {
            delete newFilter[name]
        }
        handleFilterVariants(newFilter)
    }

    useEffect(() => {
        updateFilter({})
        handleFilterVariants({})
    }, [tab, attributes, handleFilterVariants, updateFilter])
    
    return (
        <tr className="ProductVariantsRow">
            {attributes.map((attribute) => {
                const {values = [], type, name} = attribute
                return (
                    <td key={type}>
                        <select name={name} value={filter[name] || ''} className="form-control" onChange={_onFilterVariant}>
                            <option value="">All</option>
                            {values.map((v) => {
                                return (
                                    <option key={v.code} value={v.code}>
                                        {v.text}
                                    </option>
                                )
                            })}
                        </select>
                    </td>
                )
            })}
        </tr>
    )
}

export default ProductVariantFilter

