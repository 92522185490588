import React, { Component } from 'react'
import getHistory from '../../../store/getHistory'
import AffiliatesTable from './table/AffiliatesTable'
// import { getLocalData, setLocalData } from '../../../services/StorageServices'
import { getListAffiliates } from '../../../services/api/AffiliateServices'
import { toaster } from '../../shared/PFToast'
import debounce from 'lodash/debounce'
class AffiliateContainer extends Component {
    constructor(props) {
        super(props)
        // const pageNumber = getLocalData('ProductMockupNumberPage') || 1
        // const pageLimit = getLocalData('ProductMockupLimitItem') || 10
        this.state = {
            affiliates: [],
            params: {
                page: 1,
                pages: 1,
                total: 0,
                query: '',
                type: '0',
                limit: 10,
            },
            loading: false,
        }
    }

    _onCreate = () => {
        const history = getHistory()
        history.push('/a/affiliate/create')
    }

    _onChangePage = (page) => {
        const { params } = this.state
        this.setState(
            {
                params: { ...params, page },
            },
            () => this._getAffiliates()
        )
    }

    _onChangeEntries = (e) => {
        const limit = e.target.value
        const { params } = this.state
        this.setState(
            {
                params: { ...params, page: 1, limit },
            },
            () => this._getAffiliates()
        )
    }

    _onChangeType = (e) => {
        const { params } = this.state
        this.setState(
            {
                params: { ...params, page: 1, type: e.target.value },
            },
            () => this._getAffiliates()
        )
    }

    _onChangeInputSearch = (e) => {
        const { params } = this.state
        this.setState(
            {
                params: { ...params, page: 1, query: e.target.value },
            },
            () => this._getAffiliates()
        )
    }

    _getAffiliates = debounce(async () => {
        this.setState({
            loading: true,
        })
        const { params } = this.state
        const { success, data, message } = await getListAffiliates(params)
        if (success) {
            this.setState({
                affiliates: data.affiliates, params: {
                    page: data.page,
                    pages: data.pages,
                    total: data.total,
                    limit: data.limit,
                    type: params.type,
                    query: params.query
                }
            })
        } else {
            toaster({
                type: 'error',
                message
            })
        }
        this.setState({
            loading: false,
        })
    }, 500)

    _onDeleteSuccess = () => {
        this._getAffiliates()
    }

    componentDidMount() {
        this._getAffiliates()
    }

    render() {
        const { loading, affiliates, params } = this.state
        return (
            <div className="affiliate-page">
                <h3>Affiliates</h3>
                <div className="card">
                    <div className="row mb-4 align-items-center justify-content-between">
                        <div className="col-auto">
                            Show
                            <select
                                className="ml-2 mr-2"
                                onChange={this._onChangeEntries}
                            >
                                <option value="10">10 rows</option>
                                <option value="20">20 rows</option>
                                <option value="50">50 rows</option>
                                <option value="100">100 rows</option>
                            </select>
                            entries
                        </div>

                        <div className="d-flex flex-wrap col-auto">
                            <div className="left flex-shrink-0">
                                <select
                                    className="form-control"
                                    onChange={this._onChangeType}
                                >
                                    <option value="0">Affiliate name</option>
                                    <option value="1">Affiliate mail</option>
                                </select>
                            </div>
                            <div className="right flex-grow-1">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="search"
                                    placeholder="Search title..."
                                    onBlur={this._onBlurInputSearch}
                                    onChange={this._onChangeInputSearch}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="btn btn-primary btn-create"
                        onClick={this._onCreate}
                    >
                        Create new affilate
                    </button>
                    <div className="affilate-table table-responsive mt-2">
                        <AffiliatesTable
                            loading={loading}
                            affiliates={affiliates}
                            params={params}
                            onDeleteSuccess={this._onDeleteSuccess}
                            onChangePage={this._onChangePage}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default AffiliateContainer
