import React from 'react'
import {FormText, Input, Label} from 'reactstrap'
import InputUploadBannerImage from './InputUploadBannerImage'

const SettingBanner = ({
                           bannerDashboardUrl,
                           bannerImageDesktop,
                           bannerImageTablet,
                           bannerImageMobile,
                           onChangeSetting,
                           onUploadBanner,
                           onRemoveBanner
                       }) => {
    return (
        <>
            <div className="SectionDetails">
                <p className="font-bold">Store banner</p>
            </div>

            <div className="SectionSetting">
                <div className="SectionInner">
                    <div className="mb-3">
                        <Label className="d-block">
                            <div className="mb-2">Banner url</div>
                            <Input value={bannerDashboardUrl} onChange={e => onChangeSetting('banner_dashboard_url', e.target.value)} />
                        </Label>
                    </div>

                    <div className="mb-3">
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <div className="mb-2">
                                    <div>Desktop image</div>
                                    <FormText className="mt-0">Recommend dimension: 1600 x 240</FormText>
                                </div>
                                <InputUploadBannerImage
                                    imageKey="banner_image_desktop"
                                    imageSrc={bannerImageDesktop}
                                    onUploadBanner={onUploadBanner}
                                    onRemoveBanner={onRemoveBanner}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-2">
                                    <div>Tablet image</div>
                                    <FormText className="mt-0">Recommend dimension: 738 x 240</FormText>
                                </div>
                                <InputUploadBannerImage
                                    imageKey="banner_image_tablet"
                                    imageSrc={bannerImageTablet}
                                    onUploadBanner={onUploadBanner}
                                    onRemoveBanner={onRemoveBanner}
                                />
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="mb-2">
                                    <div>Mobile image</div>
                                    <FormText className="mt-0">Recommend dimension: 345 x 148</FormText>
                                </div>
                                <InputUploadBannerImage
                                    imageKey="banner_image_mobile"
                                    imageSrc={bannerImageMobile}
                                    onUploadBanner={onUploadBanner}
                                    onRemoveBanner={onRemoveBanner}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingBanner
