import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {deleteImageProductLine} from '../../../../services/api/ProductLinesServices'
import {toaster} from '../../../shared/PFToast'
import {confirmModal} from '../../../shared/PFConfirmModalV2'


class ProductImagesItem extends Component {

    _handleClickDelete = e => {
        e.preventDefault()

        confirmModal({
            confirmText: 'Delete',
            cancelText: 'Cancel',
            confirmColor: 'danger',
            title: 'Delete image',
            message: 'Are you sure you want to delete this image',
            onOk: this._handleDelete
        })
    }

    _handleDelete = async () => {

        const {image, productLineId} = this.props
        const {id} = image

        try {
            const {success, message} = await deleteImageProductLine(productLineId, id)

            if (!success) throw new Error(message)

            this.props.reloadImages()

            toaster({message: 'Deleted image successfully!', type: 'success', duration: 5000})

        } catch (e) {
            toaster({message: e.message, type: 'error', duration: 5000})
        }
    }

    render() {
        const {image} = this.props
        const {thumb} = image

        return (
            <div className='ProductImagesItem mt-2 border'>
                <div className='ImageWrapper' style={{backgroundImage: `url("${thumb}")`, backgroundSize: "contain"}}>
                    <img className='ProductImageView' src={thumb} alt='' width='200' height='200'/>
                </div>

                <i className='RemoveImage ti-close' onClick={this._handleClickDelete}/>
            </div>
        )
    }
}

ProductImagesItem.propTypes = {
    productLineId: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    reloadImages: PropTypes.func.isRequired
}

export default ProductImagesItem
