import React, {Component} from 'react'
import BackNavigationButton from '../../shared/BackNavigationButton'
import TransactionOutCreationContainer from './actions/TransactionOutCreationContainer'
import StoreTransactionsTable from './table/StoreTransactionsTable'

class StoreTransactions extends Component {
    constructor(props) {
        super(props)
        window.currentStore = Object.assign({}, this.props.store)
    }

    componentWillUnmount() {
        window.currentStore = null
    }

    render() {
        const {store} = this.props
        const {_id, name} = store

        return (
            <div className="StoreTransactions StoreDetails">
                <BackNavigationButton to={`/a/stores/${_id}`} text={name}/>

                <div className="text--right">
                    <h1 className='PageTitle mb-4'>Transactions out</h1>
                    <TransactionOutCreationContainer {...this.props}/>
                </div>

                <div className="bg-white p-3 border rounded shadow-sm">
                    <StoreTransactionsTable {...this.props}/>
                </div>
            </div>
        )
    }

}

export default StoreTransactions
