import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ClusterDetailsContainer from './details/ClusterDetailsContainer'
import ClusterSettingContainer from './settings/ClusterSettingContainer'
import DNSrecordContainer from './DNS record/DNSrecordContainer'
import ClusterEnvsContainer from './ClusterEnvsContainer'
import ClusterListGroups from './ClusterListGroups'

class ClusterPage extends Component {
    render() {
        const {clusterId} = this.props

        return (
            <div className="ClusterPage">
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="SectionInner">
                            <ClusterDetailsContainer {...this.props}/>
                        </div>

                        <div className="SectionInner mt-2">
                            <ClusterListGroups {...this.props}/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div>
                            <div className="SectionInner">
                                <ClusterSettingContainer {...this.props}/>
                            </div>
                        </div>

                        <div>
                            <div className="SectionInner">
                                <DNSrecordContainer {...this.props}/>
                            </div>
                        </div>
                    </div>
                </div>

                <ClusterEnvsContainer clusterId={clusterId}/>
            </div>
        )
    }
}

ClusterPage.propTypes = {
    clusterId: PropTypes.string.isRequired
}

export default ClusterPage
