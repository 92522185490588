import React, {Component} from 'react'
import PropTypes from "prop-types";
import PubSub from '../../GlobalSettingBroker'
import PFConfirmModal from '../../../shared/PFConfirmModal'
import {deleteGlobalSetting} from "../../../../services/api/StoreServices";

const ModalDescription = (props) => {
    return (
        <div>
            <div className='mb-2'>Are you sure want to delete <span
                className='h6 mb-0'>"{props.title}"</span>?
            </div>
        </div>
    )
}

class DeleteGlobalSetting extends Component {

    _doAction = async () => {
        const {item} = this.props

        const {success, message} = await deleteGlobalSetting(item._id)

        if (!success) throw new Error(message)

        return {
            data: 'Success',
            onSuccess: this._onFetchSettings(),
            hideAfterSuccess: true
        }
    }

    _onFetchSettings = () => {
        PubSub.publish("STORE_FETCH_GLOBAL_SETTINGS")
    }

    render() {
        const {item} = this.props

        return (
            <span className="DeleteSetting">
                <PFConfirmModal
                    title='Confirm delete'
                    description={<ModalDescription title={item.key}/>}
                >
                    {
                        confirm => {
                            return (
                                <div className="ViewButton text-danger cursor-pointer" onClick={confirm(this._doAction)}>
                                    Delete
                                </div>
                            )
                        }
                    }
                </PFConfirmModal>
            </span>
        );
    }

}

DeleteGlobalSetting.propTypes = {
    item: PropTypes.object.isRequired,
}

export default DeleteGlobalSetting