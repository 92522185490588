import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {CustomInput} from 'reactstrap'
import _ from 'lodash'
import withStoreModeValidator from '../../shared/withStoreModeValidator'

class ProductDisplayEdit extends Component {
    _getDisplayState = (product) => {
        const isStealthy = !(_.isNil(product.is_stealthy)) ? product.is_stealthy : false
        const isFeatured = !(_.isNil(product.is_featured)) ? product.is_featured : false
        const hideFromListingPage = !(_.isNil(product.is_shop_hidden) || _.isNil(product.is_collection_hidden)) ? product.is_shop_hidden && product.is_collection_hidden : false

        return {
            isStealthy,
            isFeatured,
            hideFromListingPage,
        }
    }

    _checkCheckbox = (key) => (e) => {
        const {checked} = e.target

        if (key === 'hide_from_listing_page') return this.props.onChangeMultiple({
            'is_shop_hidden': checked,
            'is_collection_hidden': checked,
        })

        return this.props.onChangeMultiple({
            [key]: checked,
        })
    }

    render() {
        const {product, editable} = this.props
        const {isStealthy, isFeatured, hideFromListingPage} = this._getDisplayState(product)

        return (
            <div className="ProductDisplayEdit mb-3">
                <div className="SectionInner">
                    <h3 className="Title fs-20">Display</h3>

                    <div className="Block mt-2">
                        <div className="CheckBox">
                            <CustomInput
                                id="is_stealthy"
                                type="checkbox"
                                label="Hide from search engines"
                                checked={isStealthy}
                                disabled={!editable}
                                onChange={this._checkCheckbox('is_stealthy')}
                            />
                        </div>
                        <div className="CheckBox">
                            <CustomInput
                                id="is_featured"
                                type="checkbox"
                                label="Add to homepage"
                                checked={isFeatured}
                                disabled={!editable}
                                onChange={this._checkCheckbox('is_featured')}
                            />
                        </div>
                        <div className="CheckBox">
                            <CustomInput
                                id="hide_from_listing_page"
                                type="checkbox"
                                label="Hide from listing page"
                                checked={hideFromListingPage}
                                disabled={!editable}
                                onChange={this._checkCheckbox('hide_from_listing_page')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ProductDisplayEdit.propTypes = {
    editable: PropTypes.bool.isRequired,
    product: PropTypes.object.isRequired,
    onChangeMultiple: PropTypes.func.isRequired,
}

export default withStoreModeValidator({
    normal: 'all',
    marketplace: ['admin', 'owner'],
    'merchize-payment': 'all',
})(ProductDisplayEdit)
