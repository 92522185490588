import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import humanizeTime from '../../../../helpers/time/humanizeTime'
import {getListAssignedForResource} from '../../../../services/api/ResourceService'
import TablePagination from '../TablePagination'
import RemoveModal from './RemoveModal'
import AssignModal from './AssignModal'
import _ from 'lodash'

const _tableColumns = ['Level', 'Id', 'Name', 'Used', 'Created', 'Actions']

class ResourceAssignedList extends Component {
    state = {
        modal: {
            remove: {
                open: false,
                resourceTarget: {},
            },
            assign: {
                open: false,
                resourceTarget: {},
            },
        },
        items: [],
        loading: true,
        params: {
            page: 1,
            limit: 20,
        },
        total: 0,
        totalPage: 1,
        err: '',
    }

    componentDidMount() {
        this._getResourceTargetList()
    }

    _getResourceTargetList = async () => {
        try {
            const {resourceId} = this.props
            const {params} = this.state
            const {success, data, message} = await getListAssignedForResource(resourceId, params)
            const {total, totalPage, items} = Object.assign({}, data)

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            this.setState({
                items: items,
                totalPage: totalPage,
                total: total,
                loading: false,
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _validateDate = (time) => {
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }

    _handlePageChange = (page) => {
        const {params} = this.state
        this.setState(
            {
                params: {...params, page},
            },
            () => this._getResourceTargetList(),
        )
    }

    _onClickDelele = (resourceTarget) => () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                remove: {
                    open: true,
                    resourceTarget,
                },
            },
        }))
    }

    _onClickAssignNew = () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                assign: {
                    open: true,
                    resourceTarget: {},
                },
            },
        }))
    }

    _onCloseModal = () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                remove: {...modal.remove, open: false, resourceTarget: {}},
                assign: {...modal.assign, open: false, resourceTarget: {}},
            },
        }))
    }

    _onRemoveResourceTarget = () => {
        const {modal, items} = this.state
        const {resourceTarget} = modal.remove
        const newItems = items.filter((item) => item._id !== resourceTarget._id)
        const remove = {resourceTarget: {}, open: false}
        this.setState(
            {
                items: newItems,
                modal: {
                    ...modal,
                    remove,
                },
            },
            () => this.props.onUpdated(),
        )
    }

    _onUpdatedAssignStatus = (data) => {
        const {items} = this.state
        this.setState(
            {
                items: [data, ...items],
            },
            () => this.props.onUpdated(),
        )
    }

    render() {
        const {
            loading,
            items,
            params: {page},
            totalPage,
        } = this.state
        const {remove, assign} = this.state.modal

        return (
            <div>
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Assigned List</h5>
                        <div className="Action">
                            <button className="btn btn-primary" onClick={this._onClickAssignNew}>
                                <i className="fas fa-plus" /> Assign new one
                            </button>
                        </div>
                        <br />
                        <div className="TableContainer table-responsive ">
                            <table className="table table-striped table-light">
                                <thead className="TableHeader thead-light">
                                    <tr>
                                        {_tableColumns.map((column) => (
                                            <th scope="col" key={column}>
                                                {column}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="TableBody">
                                    {!loading &&
                                        items.map((item, index) => (
                                            <tr className="RenderTableRow" key={index}>
                                                <td className="HostColumn">
                                                    <span>{_.get(item, 'level', '')}</span>
                                                </td>
                                                <td className="HostColumn">
                                                    <span>{_.get(item, 'target._id', '')}</span>
                                                </td>
                                                <td className="HostColumn">
                                                    <span>
                                                        {_.get(item, 'target.domain') ||
                                                            _.get(item, 'target.name') ||
                                                            _.get(item, 'target.namespace') ||
                                                            ''}
                                                    </span>
                                                </td>
                                                <td className="HostColumn">
                                                    <span>{_.get(item, 'used') || ''}</span>
                                                </td>
                                                <td className="CreatedColumn">
                                                    <span>{this._validateDate(_.get(item, 'created'))}</span>
                                                </td>
                                                <td className="ActionColumn">
                                                    {/* <button className="ViewButton btn btn-primary mr-3" onClick={this._onClickView}>
                                                    <span className="ti-eye mr-1"/>
                                                    View
                                                </button> */}
                                                    <button
                                                        className="ViewButton btn btn-danger"
                                                        onClick={this._onClickDelele(item)}
                                                    >
                                                        <span className="ti-trash mr-1" />
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            <TablePagination page={page} pages={totalPage} handlePageChange={this._handlePageChange} />
                        </div>
                    </div>
                </div>
                <RemoveModal
                    open={remove.open}
                    resourceTarget={remove.resourceTarget}
                    onClose={this._onCloseModal}
                    onOk={this._onRemoveResourceTarget}
                />
                <AssignModal
                    resourceId={this.props.resourceId}
                    open={assign.open}
                    onClose={this._onCloseModal}
                    onUpdated={this._onUpdatedAssignStatus}
                    dbKind={this.props.dbKind}
                />
            </div>
        )
    }
}

ResourceAssignedList.propTypes = {
    resourceId: PropTypes.string.isRequired,
    onUpdated: PropTypes.func.isRequired,
    dbKind: PropTypes.string,
}

export default ResourceAssignedList
