import React, {Component} from 'react'
import ZoneName from './details/ZoneName'
import ZoneCountriesContainer from './countries/ZoneCountriesContainer'
import ZoneRatesContainer from './rates/ZoneRatesContainer'
import {
    getSettingProfilesShippingDetail,
    getShippingZoneDetails
} from '../../../../services/api/ShippingSettingsServices'
import ZoneActionsContainer from './actions/ZoneActionsContainer'
import PropTypes from 'prop-types'
import ReturnPage from '../../../shared/ReturnPage'
import {toaster} from '../../../shared/PFToast'

class ZoneEditPage extends Component {
    state = {
        loading: false,
        error: '',
        nameProfile: '',
        listCountries: []
    }

    _zoneData = {
        name: '',
        countries: [],
        rest_of_world: false,
        rates: []
    }

    componentDidMount() {
        this._fetZoneDetails()
        this._getShippingProfilesDetail()
    }

    _updateZoneData = (field, data) => {
        this._zoneData[field] = data
    }

    _getZoneData = () => {
        const {name, countries, rest_of_world} = this._zoneData

        return {
            name,
            countries,
            rest_of_world
        }
    }

    _getShippingProfilesDetail = async () => {
        const {profilesId} = this.props

        try {
            const {data, message, success} = await getSettingProfilesShippingDetail(profilesId)

            if (!success) throw new Error(message)

            return this.setState({
                nameProfile: data.name || ''
            })

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            toaster({message: message ? message : e.message, type: 'error', duration: 5000})
        }
    }

    _fetZoneDetails = async () => {
        this.setState({
            loading: true
        })
        const {zoneId} = this.props
        try {
            const {data, success, message} = await getShippingZoneDetails(zoneId)
            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            const {name, rest_of_world, rates} = data

            const countries = Array.isArray(data.countries) ? data.countries.map(item => item.code) : []

            this._zoneData = {
                ...this._zoneData,
                name,
                countries,
                rest_of_world,
                rates
            }

            return this.setState({
                loading: false,
                listCountries: data.countries || [],
                error: ''
            })

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            return this.setState({
                loading: false,
                error: message ? message : e.message
            })
        }
    }

    render() {
        const {nameProfile, listCountries} = this.state
        const {zoneId, profilesId} = this.props
        const {name, rest_of_world, rates} = this._zoneData

        const countries = Array.isArray(listCountries) ? listCountries : []

        const prevSelectedCountries = countries || []

        return (
            <div className="ZoneEditPage">
                <ReturnPage url={`/a/settings-shipping/profiles-edit/${profilesId}`}
                            title={`Profile : ${nameProfile}`}/>
                <div className="MenuTitle">
                    {name}
                </div>
                <div className='ZoneEditPageContainer mt-3'>
                    <div className="mb-3">
                        <ZoneName name={name} onUpdateZoneData={this._updateZoneData}/>
                    </div>

                    <div className="mb-3">
                        <ZoneCountriesContainer selectedCountries={countries} restOfWorld={rest_of_world}
                                                zoneId={zoneId} totalRest={countries.length}
                                                prevSelectedCountries={prevSelectedCountries}
                                                profilesId={profilesId}
                                                onUpdateZoneData={this._updateZoneData}/>
                    </div>

                    <div className="mb-4">
                        <ZoneRatesContainer profilesId={profilesId} onFetZoneDetails={this._fetZoneDetails}
                                            zoneId={zoneId} rates={rates}/>
                    </div>

                    <ZoneActionsContainer profilesId={profilesId} name={name} zoneId={zoneId}
                                          onGetZoneData={this._getZoneData}/>
                </div>
            </div>
        )
    }
}

// ZoneEditPage.defaultProps = {}

ZoneEditPage.propTypes = {
    profilesId: PropTypes.string.isRequired
}

export default ZoneEditPage
