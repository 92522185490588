import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ObjectInspector from 'react-object-inspector'

class SpecsColumn extends Component {
    render() {
        const {item} = this.props
        const {specs} = item

        return (
            <td className='HostColumn'>
               <span><ObjectInspector data={specs || {}} /></span>
            </td>
        )
    }
}

SpecsColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default SpecsColumn
