import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import NavLink from 'react-router-dom/es/NavLink'
import className from 'classnames'
import SubMenuItem from './SubMenuItem'

class MenuItem extends Component {
    state = {
        displaySubMenu: false,
    }

    _handleClickToggleSubmenu = (event) => {
        event.preventDefault();
        this.setState({displaySubMenu: !this.state.displaySubMenu})
    }

    _toClassName = str => {
        return str.replace(/\s/g, '')
    }

    _renderIcon = (item) => {
        const {fa, icon = '', active_icon = ''} = item

        if (fa) {
            return (<i className={fa}/>)
        }
        return (
            <>
                {icon && <img className={icon && active_icon ? 'default-icon' : ''} src={icon} alt="icon"/>}
                {active_icon && <img className="active-icon" src={active_icon} alt="icon"/>}
            </>
        )
    }

    render() {
        const {menuItem , isCollapsed} = this.props
        const {href, title, fa, label, sub_menu = '', icon = ''} = menuItem
        const {displaySubMenu} = this.state

        const SubMenu = () => {
            return (
                <div className="SubMenu">
                    <h6 className="border-bottom cursor-pointer mb-0 ml-0 Title">{title}</h6>
                    <ul>
                        {
                            sub_menu && sub_menu.map(item => {
                                return <SubMenuItem key={item.href} item={item}/>
                            })
                        }
                    </ul>
                </div>
            )
        }

        return (
            <div
                className={className(`MenuItem ${this._toClassName(title)}`, {'SubMenuActive': displaySubMenu}, {'HasChild': !!sub_menu})}>
                <div className='d-flex justify-content-between align-items-center' onClick={this._handleClickToggleSubmenu}>
                    <NavLink to={href} activeClassName="active" className="nav-link">
                        <span className="IconHolder" data-tooltip={title}>
                            {this._renderIcon(menuItem)}
                        </span>
                        <span className="Title mr-auto">{title}</span>
                        {
                            'label' in menuItem &&
                            <span className="Label badge badge-primary">{label}</span>
                        }
                        {(["Integration Config", "Accounting Report", "Order"].includes(title)) &&
                            <i className={'cursor-pointer ' + (isCollapsed ? 'd-none ' : 'ml-4 ') + (displaySubMenu ? 'fas fa-chevron-up active' : 'fas fa-chevron-down')} />
                        }
                    </NavLink>
                </div>

                {!!sub_menu && (["Integration Config", "Accounting Report", "Order"].includes(title)) ?
                    <Fragment>
                        {displaySubMenu && <SubMenu/>}
                    </Fragment>
                    :
                    <SubMenu/>
                }
            </div>
        )
    }
}

MenuItem.propTypes = {
    menuItem: PropTypes.object.isRequired,
}

export default MenuItem
