import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {confirmModal} from "../../../shared/PFConfirmModalV2"
import {approveProductTicket} from '../../../../services/api/SellerServices'
import {toaster} from "../../../shared/PFToast"

class ProductActionApprove extends Component {

    state = {
        error: ''
    }

    _handleClickApprove = e => {
        const {productTitle} = this.props
        e.preventDefault()

        confirmModal({
            confirmText: 'Ok',
            cancelText: 'Close',
            confirmColor: 'primary',
            title: 'Confirm approve',
            message: <span>Are you sure you want to approve <b>{productTitle}?</b></span>,
            onOk: this._handleApprove
        })
    }

    _handleApprove = async () => {
        try {
            const {ticketId, onGetProduct} = this.props
            const {backoffice_master_url} = window.currentStore
            const backofficeMasterUrl = backoffice_master_url + '/api/seller'
            const {success, message} = await approveProductTicket(ticketId, backofficeMasterUrl)
            if (!success) throw new Error(message)

            toaster({
                type: 'success',
                message: 'Product approved successfully',
                duration: 5000
            })

            onGetProduct()

            window.location.pathname = 'a/product-approval'

        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
                duration: 5000
            })
        }
    }


    render() {
        const {approvalStatus} = this.props

        return (
            <button disabled={approvalStatus === 'approved' || approvalStatus === 'rejected'}
                    className='btn btn-primary mr-2' onClick={this._handleClickApprove}>Approve</button>
        )
    }
}

ProductActionApprove.propTypes = {
    productTitle: PropTypes.string.isRequired,
    ticketId: PropTypes.string.isRequired,
    approvalStatus: PropTypes.string.isRequired,
    onGetProduct: PropTypes.func.isRequired,
}

export default ProductActionApprove
