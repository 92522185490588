import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/statistic' : 'https://manager-qa.merch8.com/api/statistic'
const api = createAPIServices({baseUrl})

export const getAllAnalytics = ({timeRange = ''}) =>
    api.makeAuthRequest({
        url: `/overview`,
        method: 'POST',
        data: {
            timeRange,
        },
    })

export const getStoreAnalytics = ({timeRange = '', slug = ''}) =>
    api.makeAuthRequest({
        url: `/overview/${slug}`,
        method: 'POST',
        data: {
            timeRange,
        },
    })
