import React, {Component} from 'react'
import Categories from './Categories'

class CategoriesContainer extends Component {
    render() {
        return (
            <div className='CategoriesContainer'>
                <Categories />
            </div>
        )
    }
}

export default CategoriesContainer
