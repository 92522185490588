import React from 'react';

const TableSkeleton = ({ columnQuantity }) => {
    const mappedColumns = new Array(columnQuantity).fill(null)

    return (
        <>
            <tr>
                {mappedColumns.map((column, i) => (
                    <td key={i}>
                        <span className="table-loading-placeholder Cell" />
                    </td>
                ))}
            </tr>
            <tr>
                {mappedColumns.map((column, i) => (
                    <td key={i}>
                        <span className="table-loading-placeholder Cell" />
                    </td>
                ))}
            </tr>
            <tr>
                {mappedColumns.map((column, i) => (
                    <td key={i}>
                        <span className="table-loading-placeholder Cell" />
                    </td>
                ))}
            </tr>
        </>
    )
}

export default TableSkeleton;
