import React, {Component} from 'react'

class DepositsTableHead extends Component {
    render() {
        return (
            <thead className='thead-light'>
            <tr>
                <th className='table-col-10'>Transaction ID</th>
                <th className='table-col-10 text-center'>Gateway</th>
                <th className='table-col-10 text-right pr-5'>Amount</th>
                <th className='table-col-15'>Email</th>
                <th className='table-col-30'>Note</th>
                <th className='table-col-20'>Reason for rejection</th>
                <th className='table-col-15'>Date of request</th>
                <th className='table-col-10 text-center'>Status</th>
                <th className='table-col-10 text-center'>Action</th>
            </tr>
            </thead>
        )
    }
}

export default DepositsTableHead