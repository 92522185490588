import React, {useEffect, useState} from 'react'
import ColorMappingTable from './ColorMappingTable'
import {toaster} from '../../../../shared/PFToast'
import {updateCatalogColorMapping} from '../../../../../services/api/ProductLinesServices'

const ColorMappingContainer = (props) => {
    const {attributes, productId} = props
    const [loading, setLoading] = useState(false)
    const [colorAttribute, setColorAttribute] = useState(null)

    const _updateColorAttribute = async () => {
        try {
            setLoading(true)
            const {success, message} = await updateCatalogColorMapping(productId, colorAttribute)
            if (!success) throw new Error(message)
            toaster({
                type: 'success',
                message: 'Update color mapping successfully!',
            })
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!!attributes.length) {
            const color = attributes.find((attr) => attr.type === 'color') || null
            setColorAttribute(color)
        }
    }, [attributes])

    return (
        <div className="ColorMappingContainer mt-3">
            <ColorMappingTable colorAttribute={colorAttribute} setColorAttribute={setColorAttribute} />
            <div className="d-flex justify-content-end">
                <button type="button" className="btn btn-primary" disabled={loading} onClick={_updateColorAttribute}>
                    {loading ? 'Saving...' : 'Save color mapping'}
                </button>
            </div>
        </div>
    )
}

export default ColorMappingContainer

