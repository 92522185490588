import React from 'react'
import TableHeader from './TableHeader'
import TableBody from './TableBody'

const TableContainer = (props) => {
    return (
        <div className="TableContainer table-responsive ">
            <table className="table table-striped table-light ProfilesTable">
                <TableHeader/>
                <TableBody {...props} />
            </table>
        </div>
    )
}

export default TableContainer
