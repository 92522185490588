import React, {Component} from 'react'
import DocTitleByStore from '../../../dashboard/shared/DocTitleByStore'
import DetailsReportPage from './DetailsReportPage'
import getEnv from '../../../../helpers/common/getEnv'

class DetailsReportContainer extends Component {

    render() {
        const isSupportMpMode = getEnv('isEnableMpMode')
        if (isSupportMpMode) return null

        return (
            <div className="ReportAccountingContainer">
                <DocTitleByStore title="Detailed Report"/>
                <DetailsReportPage/>
            </div>
        )
    }
}

export default DetailsReportContainer
