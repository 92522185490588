import React, {useContext, useState} from 'react'
import FormDetail from './FormDetail'
import {createTemplate, extractDataTemplate, updateTemplate} from '../../../services/api/SellerServices'
import ReactDOM from 'react-dom'
import ModalError from '../modals/ModalError'
import PropTypes from 'prop-types'
import getHistory from '../../../store/getHistory'
import {toastSuccess, toastWaring} from '../helpers/ToastHelper'
import TemplateFormContext from '../states/TemplateFormContext'
import {DEFAULT_DISABLE_FIELDS, MARKETPLACE_OPTIONS} from '../statics/amazon'
import upload from '../../../statics/assets/icons/upload.svg'
import uploadError from '../../../statics/assets/icons/uploadError.svg'
import stroke from '../../../statics/assets/icons/stroke.svg'
import trashIcon from '../../../statics/assets/icons/trash.svg'
import {validateInput} from '../helpers/ValidateHepler'

const Loading = () => <div className="Loading">
    <i className="fa fa-spinner fa-spin"/> Loading
</div>

const TemplateForm = (props) => {
    const {template, templateId} = props
    const templateContext = useContext(TemplateFormContext)

    if (template && !templateContext.template) {
        templateContext.setTemplate(template)
    }
    const [file, setFile] = useState()
    const [isLoading, setLoading] = useState(false)
    const [fileError, setFileError] = useState(false)
    const [isUpload, setUpload] = useState(false)
    const [isUploadTemp, setUploadTemp] = useState(false)
    const [templateNameExits, setTemplateNameExits] = useState(false)
    const extension = ['xlsm']

    const showError = (title, message) => {
        let el = document.createElement('div')
        ReactDOM.render(
            <ModalError
                title={title}
                message={message}
                el={el}
            />, el
        )
    }

    const uploadTemplate = async (formData, file, fileName) => {
        try {
            setLoading(true)
            const {data, success, message} = await extractDataTemplate(formData)
            if (success) {
                const {headers, browseNode, browsePath, data: items, rules} = data
                setFile(file)
                const templateData = {
                    templateMetadata: {headers},
                    browseNode, category_name: browsePath, items, file_name: fileName,
                    rules, name: '',
                    is_active: false,
                    marketplace: MARKETPLACE_OPTIONS[1].value // Amazon
                }

                items.forEach(i => {
                    if (DEFAULT_DISABLE_FIELDS.includes(i.field_name)) {
                        i.can_edit = false
                    } else i.can_edit = true
                })

                if (templateContext.template) {
                    templateData.name = templateContext.template.name
                    templateData.marketplace = templateContext.template.marketplace
                    templateData.is_active = templateContext.template.is_active
                }
                templateContext.setTemplate(templateData)
            } else {
                showError('Upload Error', message)
                setFileError(true)
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    const submitTemplate = async (payload) => {
        try {
            setTemplateNameExits(false);
            const {
                success,
                message
            } = templateId ? await updateTemplate(templateId, payload) : await createTemplate(payload)
            if (success) {
                const history = getHistory()
                history.push('/a/amazon')
                toastSuccess(templateId ? 'Edit Template successfully!' : 'Create Template successfully!')
            } else {
                toastWaring(message)
                if(message === 'Template name already exists'){
                    setTemplateNameExits(true)
                    const inputError = document.querySelector('input.is-invalid')
                    if (inputError && typeof inputError.focus === 'function') inputError.focus()
                }
            }
        } catch (e) {
            toastWaring(e.message)
        }
    }

    const handleUploadFile = (e) => {
        const file = e.target.files[0]
        if (file) {
            const formData = new FormData()
            formData.append('template', file)
            if (extension.includes(file.name.split('.').pop())) {
                setFileError(false)
                setUpload(true)
                setUploadTemp(true)

                uploadTemplate(formData, file, file.name)
            } else {
                e.target.value = ''
                setFileError(true)
                showError('Upload Error', 'File type is not supported')
            }
        }
    }

    const _validateForm = (template) => {
        const templateRequire = {
            name: template.name,
            marketplace: template.marketplace,
            category_name: template.category_name
        }

        for (const [key, value] of Object.entries(templateRequire)) {
            if (value === '') {
                validateInput(key, 'add', 'empty')
                return false
            }
        }

        if (!template.items || !Array.isArray(template.items)) throw Error('items is empty')

        template.items.forEach(i => {
            if (DEFAULT_DISABLE_FIELDS.includes(i.field_name)) {
                i.can_edit = false
            } else if (!i.hasOwnProperty('can_edit')) {
                i.can_edit = true
            }
        })
        return template
    }

    const _onSubmitForm = (e) => {
        e.preventDefault()
        const upFile = isUpload
        if (templateId) templateContext.template.upFile = upFile
        const payload = _validateForm(templateContext.template)
        if (payload) submitTemplate(payload)
    }

    const _goBack = () => {
        const history = getHistory()
        history.go(-1)
    }

    const resetInputFile = () => {
        templateContext.setTemplate(null)
        setFile(null)
        document.getElementById('uploadFile').value = ''
    }

    return (
        <div className="TemplateForm">
            <form className="FormDetail" onSubmit={_onSubmitForm}>
                <div className="SectionInner">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h6><b>Upload Template File </b><span className="text-danger">(*)</span></h6>
                            <div>
                                <label
                                    className={'py-2 px-4 mr-3 cursor-pointer ' + (fileError ? 'label_upload_error' : 'label_upload')}
                                    htmlFor="uploadFile">
                                    <img src={fileError ? uploadError : upload} className="mr-2" alt="uploadError"/>
                                    <span className={fileError ? 'text-danger' : null}>Choose File</span>
                                </label>
                                <input type="file" name="file" id="uploadFile" onChange={handleUploadFile}/>
                                {file ? <><img src={stroke} className="mr-2" alt="stroke"/>{file.name}</>
                                    : (templateContext.template
                                        ? <><img src={stroke} className="mr-2"
                                                 alt="stroke"/>{templateContext.template.file_name}</>
                                        : 'No file chosen')}
                                {isLoading && <Loading/>}
                            </div>
                        </div>
                        <div>
                            {file && <img className="cursor-pointer" src={trashIcon} alt="trash" onClick={resetInputFile}/>}
                        </div>
                    </div>
                    <div className="mt-2">
                        {templateContext.template &&
                        <FormDetail isUploadTemp={isUploadTemp} setUploadTemp={setUploadTemp} templateNameExits={templateNameExits}/>
                        }
                    </div>
                </div>
                {
                    templateContext.template &&
                    <div className="mt-3 form-group d-flex justify-content-end">
                        {templateId ?
                            <>
                                <button type="button" onClick={_goBack} className="btn btn-default mr-2">Cancel</button>
                                <input type="submit" value="Save" className="btn btn-primary"/>
                            </>
                            :
                            <>
                            <button type="button" onClick={_goBack} className="btn btn-default mr-2">Cancel</button>
                            <input type="submit" value="Create" className="btn btn-primary"/>
                            </>
                        }
                    </div>
                }
            </form>
        </div>
    )
}

TemplateForm.defaultProps = {
    template: null,
    templateId: ''
}

TemplateForm.propTypes = {
    template: PropTypes.object,
    templateId: PropTypes.string
}

export default TemplateForm
