import React, {Component} from 'react'
import tldjs from 'tldjs'
import TableContainer from './TableContainer'
import TablePagination from './TablePagination'
import {getListStores} from '../../../services/api/StoreServices'
import ClusterFilterSelect from './ClusterFilterSelect'
import StatusFilterSelect from './StatusFilterSelect'
import PlanFilterSelect from './PlanFilterSelect'
import TableFilter from '../../shared/TableFilter'
import moment from 'moment'
import PushNotificationsContainer from './actions/push-notifications/PushNotificationsContainer'
import ModeFilterSelect from './ModeFilterSelect'
import StatisticStoreBilling from './actions/StatisticStoreBilling'
import StorefrontStatusFilterSelect from './StorefrontStatusFilterSelect'
import ResourceFilterSelect from './ResourceFilterSelect'
import ResourceKindFilterSelect from './ResourceKindFilterSelect'
import GroupFilterSelect from './GroupFilterSelect'
import CashbackButton from './actions/cash-back/CashbackButton'
import StoreActionButton from './actions/store/StoreActionButton'
import ResetFilterButton from './ResetFilterButton'
import {getFiltersStorage, resetFiltersStorage, setFilterStorage} from '../../shared/ManageFiltersState'
import {fieldMap, DEFAULT_PARAMS} from '../constants'
import Pubsub from '../../shared/brokers/FilterBroker'
import ExportCsv from './actions/export-csv/ExportCsv'
import {Link} from 'react-router-dom'

class StoresPage extends Component {
    constructor(props) {
        super(props)

        const storageParams = getFiltersStorage('stores') || {}

        this.state = {
            items: [],
            loading: true,
            params: {
                ...DEFAULT_PARAMS,
                ...storageParams,
            },
            total: 0,
            totalPage: 1,
            err: '',
            resource_kind: storageParams.resource_kind || '',
        }
    }

    componentDidMount() {
        this._getListStores()
    }

    _getListStores = async () => {
        const {params} = this.state
        if (!params.cluster) {
            delete params.group
        }
        this.setState({
            items: [],
        })
        try {
            const req = Object.entries(params).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
            if (req.startLastSuspendAt) {
                req.startLastSuspendAt = moment(req.startLastSuspendAt).format('DD/MM/YYYY')
            }
            if (req.endLastSuspendAt) {
                req.endLastSuspendAt = moment(req.endLastSuspendAt).format('DD/MM/YYYY')
            }
            if (req.searchValue) delete req.searchValue
            if (req.searchFieldSelected) delete req.searchFieldSelected
            const {success, data, message} = await getListStores(req)

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            const {total, totalPage, stores} = Object.assign({}, data)

            this.setState({
                items: Array.isArray(stores) ? stores : [],
                total,
                totalPage,
                loading: false,
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _handlePageChange = (page) => {
        this.setState(
            {
                params: {...this.state.params, page},
            },
            () => {
                this._getListStores()
                setFilterStorage('stores', {page})
            }
        )
    }

    _handleChangeFilter = (e) => {
        const {name, value} = e.target
        this.setState(
            (prevState) => ({
                params: {
                    ...prevState.params,
                    groupId: (name === 'cluster' && value === '') ? '' : prevState.params.groupId,
                    [name]: value}
            }),
            () => {
                this._getListStores()
                setFilterStorage('stores', {[name]: value})
            }
        )
    }

    _onSubmitFilter = ({limit, value, field, page}) => {
        const parsedField = fieldMap[field]
        const parsedValue =
            parsedField === 'tags'
                ? value
                      .split(',')
                      .map((tag) => tag.trim())
                      .filter((tag) => tag)
                : parsedField === 'domain'
                ? tldjs.parse(value).hostname
                : value.trim()
        const {params} = this.state
        this.setState(
            () => ({
                params: {
                    ...params,
                    ...{
                        domain: '',
                        slug: '',
                        page: '',
                        loading: '',
                        tags: [],
                    },
                    ...{
                        [parsedField]: parsedValue,
                        searchFieldSelected: field,
                        searchValue: parsedValue,
                        limit,
                        page: 1,
                        loading: false,
                    },
                },
            }),
            () => {
                this._getListStores()
                setFilterStorage('stores', {
                    ...params,
                    ...{
                        domain: '',
                        slug: '',
                        page: '',
                        loading: '',
                        tags: [],
                    },
                    ...{
                        [parsedField]: parsedValue,
                        searchFieldSelected: field,
                        searchValue: parsedValue,
                        limit,
                        page: 1,
                        loading: false,
                    },
                })
            }
        )
    }

    _handleDateChange = ({from, to}) => {
        const {params} = this.state
        if (!from && !to) {
            this.setState(
                {
                    params: {
                        ...params,
                        endLastSuspendAt: '',
                        startLastSuspendAt: '',
                    },
                },
                () => {
                    this._getListStores()
                    setFilterStorage('stores', {startLastSuspendAt: '', endLastSuspendAt: ''})
                }
            )
        } else {
            const startLastSuspendAt = moment(from).format('YYYY-MM-DD')
            const endLastSuspendAt = moment(to).format('YYYY-MM-DD')
            this.setState(
                {
                    params: {
                        ...params,
                        endLastSuspendAt,
                        startLastSuspendAt,
                    },
                },
                () => {
                    this._getListStores()
                    setFilterStorage('stores', {
                        startLastSuspendAt,
                        endLastSuspendAt,
                    })
                }
            )
        }
    }

    _handleChangeResourceKind = (resource_kind) => {
        this.setState(prevState => ({
            resource_kind,
            params: {
                ...prevState.params,
                resourceId: '',
            }
        }),
            () => {
                setFilterStorage('stores', {
                    resource_kind
                })
            })
    }

    _handleResetFilter = () => {
        this.setState(
            () => ({
                resource_kind: '',
                params: DEFAULT_PARAMS,
            }),
            () => {
                this._getListStores()
                resetFiltersStorage('stores')
            }
        )
        Pubsub.publish('RESET_FILTER')
    }

    render() {
        const {items, params, totalPage, loading, err, total, resource_kind} = this.state
        const {
            page,
            limit,
            cluster,
            status,
            planId,
            mode,
            disable_storefront,
            resourceId,
            groupId,
            startLastSuspendAt,
            endLastSuspendAt,
            searchValue,
            searchFieldSelected,
        } = params

        return (
            <div className="StoresPage">
                <div className='d-flex flex-wrap mb-3'>
                    <h2 className='mb-0'>Stores</h2>
                    <div className='d-flex flex-wrap ml-auto mr-3'>
                        <StoreActionButton />
                        <CashbackButton />
                        <PushNotificationsContainer />
                    </div>
                    <div>
                        <Link to={`/a/mapping-rules`}>
                            <button className='btn btn-outline-secondary mr-3'>Mapping rules</button>
                        </Link>
                    </div>
                    <StatisticStoreBilling />
                    <ExportCsv params={this.state.params} />
                </div>
                <div className='StoresPageInner SectionInner'>
                    <ResetFilterButton onResetFilters={this._handleResetFilter} />
                    {!!err && <span className="text-warning">{err}</span>}
                    <div className="Filter d-flex justify-content-between mb-3">
                        <div className="FilterAttributes d-flex flex-wrap">
                            <ClusterFilterSelect onChange={this._handleChangeFilter} value={cluster || ''} />
                            <GroupFilterSelect
                                onChange={this._handleChangeFilter}
                                value={groupId || ''}
                                clusterId={cluster}
                            />
                            <StatusFilterSelect onChange={this._handleChangeFilter} value={status || ''} />
                            <ModeFilterSelect onChange={this._handleChangeFilter} value={mode || ''} />
                            <PlanFilterSelect onChange={this._handleChangeFilter} value={planId || ''} />
                            <StorefrontStatusFilterSelect
                                onChange={this._handleChangeFilter}
                                value={disable_storefront || ''}
                            />
                            <ResourceKindFilterSelect
                                onChange={this._handleChangeResourceKind}
                                value={resource_kind || ''}
                            />
                            <ResourceFilterSelect
                                onChange={this._handleChangeFilter}
                                value={resourceId || ''}
                                resourceKind={resource_kind}
                            />
                        </div>
                    </div>

                    <TableFilter
                        total={total}
                        defaultLimit={limit}
                        onSubmitForm={this._onSubmitFilter}
                        searchValue={searchValue}
                        searchFieldSelected={searchFieldSelected}
                        searchField={[
                            'Domain',
                            'Store ID',
                            'Old domain',
                            'Tags',
                            'Database',
                            'Feature tags',
                            'Namespace',
                            'Owner email'
                        ]}
                        placeholders={{
                            Domain: 'Search for store domain',
                            'Store ID': 'Search for store ID',
                            'Old domain': 'Search for old domain',
                            Tags: 'Search for tags name, separated by commas',
                            Database: 'Search for database name',
                            'Feature tags': 'Search for feature tags',
                            Namespace: 'Search for namespace',
                            'Owner email': 'Search for owner email',
                        }}
                        placeholderDateFilter="Last suspend at"
                        dateFilterOn={true}
                        handleDateChange={this._handleDateChange}
                        query={{startDate: startLastSuspendAt, endDate: endLastSuspendAt, hasReset: true}}
                    />
                    <TableContainer items={items} page={page} limit={limit} loading={loading} />
                    <TablePagination page={page} pages={totalPage} handlePageChange={this._handlePageChange} />
                </div>
            </div>
        )
    }
}

export default StoresPage

