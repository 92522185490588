import React, {Component} from 'react'
import PropTypes from 'prop-types'

class WithdrawalTransactionId extends Component {
    render() {
        return (
            <td className='WithdrawalTransactionId'>
                {
                    this.props.transactionId || '-'
                }
            </td>
        )
    }
}

WithdrawalTransactionId.defaultProps = {
    transactionId: '',
}

WithdrawalTransactionId.propTypes = {
    transactionId: PropTypes.string,
}

export default WithdrawalTransactionId
