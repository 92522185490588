import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment/moment'
import ObjectInspector from 'react-object-inspector'
import humanizeTime from '../../../../helpers/time/humanizeTime'

class StoreHistory extends Component {
    _validateDate = time => {
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }

    render() {
        const {resource} = this.props
        const {name, kind, capacity, used, status, metadata = {}, specs = {}, created} = resource
        const createDate = this._validateDate(created)

        return (
            <div>
                <div className='card'>
                    <div className='card-body'>
                        <h5 className='card-title'>Details</h5>
                        <ul className='list-group list-group-flush'>
                            <li className='list-group-item'>
                                <span className='font-weight-bold'>Name: </span>
                                {name}
                            </li>
                            <li className='list-group-item'>
                                <span className='font-weight-bold'>Kind: </span> {kind}
                            </li>
                            <li className='list-group-item'>
                                <span className='font-weight-bold'>Capacity: </span> {capacity === 0 ? 'unlimited' : capacity}
                            </li>
                            <li className='list-group-item'>
                                <span className='font-weight-bold'>Used: </span> {used}
                            </li>
                            <li className='list-group-item'>
                                <span className='font-weight-bold'>Status: </span> {status}
                            </li>
                            <li className='list-group-item'>
                                    <span className='font-weight-bold'>
                                    Metadata: <ObjectInspector data={metadata} />
                                    </span>
                            </li>
                            <li className='list-group-item'>
                                    <span className='font-weight-bold'>
                                    Specs: <ObjectInspector data={specs} />
                                    </span>
                            </li>
                            <li className='list-group-item'>
                                <span className='font-weight-bold'>Created: </span> {createDate}
                            </li>
                        </ul>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}

StoreHistory.propTypes = {
    resource: PropTypes.object.isRequired,
}

export default StoreHistory
