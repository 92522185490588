import createAPIServices from './createApiServices'
import getEnv from '../../helpers/common/getEnv';

const baseUrl = `${getEnv('baseSellerHost')}/api/product-line`
// const baseUrl = `https://seller-staging.merchize.com/api/product-line`

const api = createAPIServices({baseUrl})

export const getCategories = (params) => {
    const defaultParams = {
        limit: 20,
        page: 1,
    }

    const newParams = {...defaultParams, ...params}


    return api.makeAuthRequest({
        url: '/manager/categories/search',
        method: 'POST',
        data: newParams,
    })
}

export const createCategory = data => {
    return api.makeAuthRequest({
        url: '/manager/categories',
        method: 'POST',
        data,
    })
}

export const getCategoryDetails = (categoryId = '') => {
    return api.makeAuthRequest({
        url: `/manager/categories/${categoryId}`,
        method: 'GET',
    })
}

export const deleteCategory = (categoryId) => {
    return api.makeAuthRequest({
        url: `/manager/categories/${categoryId}`,
        method: 'DELETE',
    })
}

export const updateCategory = (categoryId, data) => {
    return api.makeAuthRequest({
        url: `/manager/categories/${categoryId}`,
        method: 'POST',
        data,
    })
}

export const getCatalogCategories = (data) => {
    return api.makeAuthRequest({
        url: `/manager/catalog-categories/search`,
        method: 'POST',
        data,
    }) 
}