import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import { isAdmin } from '../../services/AuthServices'
import { addWebhookEvent, editWebhookEvent, getWebhookEvents, removeWebhookEvent } from '../../services/api/WebhookEvents'
import PFTable from '../shared/PFTable'
import { toaster } from '../shared/PFToast'
import ModalAddEvent from './ModalAddEvent'
import ModalEditEvent from './ModalEditEvent'
import TableEvents from './TableEvents'
import { confirmModal } from '../shared/PFConfirmModalV2'

const initDetail = {
    visible: false,
    data: null
}


const WebhookSettingsContainer = () => {

    const checkRole = isAdmin()

    const [loading, setLoading] = useState(false)
    const [events, setEvents] = useState([])
    const [showModalAddEvent, setShowModalAddEvent] = useState(false)
    const [detailEdit, setDetailEdit] = useState(initDetail)

    const fetchWebhookEvents = async () => {
        setLoading(true)
        try {
            const { success, data } = await getWebhookEvents()
            if (data.length && success) setEvents(data)
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchWebhookEvents()
    }, [])


    const handleCreateEvent = async (payload) => {
        try {
            await addWebhookEvent(payload)
            fetchWebhookEvents()
            setShowModalAddEvent(false)
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }


    const confirmEdit = (newData,id) => {
        confirmModal({
            confirmText: 'Update',
            confirmColor: 'primary',
            title: 'Update event',
            message: 'Are you sure you want to update this event?',
            onOk: () => handleEditEvent(newData,id),
        })
    }
    const handleEditEvent = async (newData, id) => {
        try {
            await editWebhookEvent(newData, id)
            setDetailEdit(initDetail)
            fetchWebhookEvents()
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } 
    }

    const confirmDelete = (id) => {
        confirmModal({
            confirmText: 'Delete',
            confirmColor: 'danger',
            title: 'Delete event',
            message: 'Are you sure you want to delete this event? This cannot be undone!',
            onOk: () => handleDelete(id),
        })
    }
    const handleDelete = async (id) => {
        try {
            await removeWebhookEvent(id)
            fetchWebhookEvents()
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }


    if (!checkRole) return <></>

    return (
        <div className="card">
            <div className="card-body shadow-sm">
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        color="primary"
                        style={{minWidth : '100px'}}
                        onClick={() => {
                            setDetailEdit(initDetail)
                            setShowModalAddEvent(true)
                        }}
                    >
                        Create
                    </Button>
                </div>
    
                <div className='table-responsive mt-4'>
                    <PFTable source={[]}>
                        {() => (
                            <TableEvents
                                loading={loading}
                                data={events}
                                onDelete={confirmDelete}
                                onClickEdit={(data)=>{
                                    setShowModalAddEvent(false)
                                    setDetailEdit({
                                        visible : true,
                                        data
                                    })
                                }}
                            />
                        )}
                    </PFTable>
               </div>
    
                {
                    showModalAddEvent && <ModalAddEvent
                        isShow={showModalAddEvent}
                        onClose={()=>{
                            setShowModalAddEvent(false)
                        }}
                        onSubmit={handleCreateEvent}
                    />
                }

                {
                    detailEdit.visible && <ModalEditEvent
                        isShow={detailEdit.visible}
                        onClose={() => {
                            setDetailEdit(initDetail)
                        }}
                        onSubmit={(newData) => {
                            confirmEdit(newData, detailEdit.data._id)
                        }}
                        dataEdit={detailEdit.data}
                    />
                }
           </div>
        </div>
    )
}

WebhookSettingsContainer.propTypes = {}

export default WebhookSettingsContainer
