import React, { useState } from "react";
import getHistory from "../../../../store/getHistory";
import moment from "moment";
import Clipboard from 'react-clipboard.js'
import humanizeTime from "../../../../helpers/time/humanizeTime";

const TableBody = (props) => {
    const { loading, affiliates, setAffiliateId, toggle, params, updateAffiliateStatus } = props;
    const [copyLink, setCopyLink] = useState("Copy");
    const history = getHistory();

    const _navigateEdit = (id) => {
        history.push(`/a/affiliate/${id}`);
    };

    const _onToggle = () => {
        toggle();
    };

    const _handleCopyLink = (link) => {
        setCopyLink("Copied");
        setTimeout(() => {
            setCopyLink("Copy");
        }, 2000);
    };

    const _validateDate = (time) => {
        const momentTime = moment(time);
        return humanizeTime(momentTime);
    };

    return (
        <tbody>
            {loading ? (
                <Loading />
            ) : affiliates.length > 0 ? (
                affiliates.map((affiliate, index) => (
                    <tr key={index}>
                        <td>{(params.page - 1) * params.limit + index + 1}</td>
                        <td className="affiliate-name">
                            <span>{affiliate.name}</span>
                            <p className="mb-0">{affiliate.email}</p>
                        </td>
                        <td>{affiliate.code}</td>
                        <td>
                            <div className="affiliate-link">
                                <a className="mr-4" target="_blank" href={affiliate.link}>
                                    {affiliate.link}
                                </a>
                                <Clipboard component="div" data-clipboard-text={affiliate.link}
                                    className="align-items-center"
                                    onSuccess={_handleCopyLink}>
                                    <div className="Tooltip3 Top">
                                        <i className="ti-files cursor-pointer" />
                                        <div className="TooltipContent">
                                            <div className="ContentInner">
                                                <small className="text-monospace">{copyLink}</small>
                                            </div>
                                        </div>
                                    </div>
                                </Clipboard>
                            </div>
                        </td>
                        <td>{_validateDate(affiliate.created)}</td>
                        <td className="btn-actions">
                            <button className="btn btn-primary" onClick={() => _navigateEdit(affiliate._id)}>
                                <i className="fa fa-eye mr-1"></i>Edit
                            </button>
                            {
                                affiliate.is_deleted && <button
                                    className="btn btn-success ml-2"
                                    onClick={() => {
                                        updateAffiliateStatus(affiliate._id);
                                    }}
                                >
                                    Active
                                </button>
                            }
                            {
                                !affiliate.is_deleted && <button
                                    className="btn btn-danger ml-2"
                                    onClick={() => {
                                        _onToggle();
                                        setAffiliateId(affiliate._id);
                                    }}
                                >
                                    Deactive
                                </button>
                            }
                        </td>
                    </tr>
                ))
            ) : (
                <tr className="text-center">
                    <td colSpan="6">
                        <span className="text-mute">No results</span>
                    </td>
                </tr>
            )}
        </tbody>
    );
};

const Loading = () => {
    return (
        <tr>
            <td colSpan="6">Loading...</td>
        </tr>
    );
};

export default TableBody;
