import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import CashbackModal from './CashbackModal'

const CashbackButton = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [isOpen, setIsOpen] = useState(false)

    const handleToggleModal = () => setIsOpen(!isOpen)

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);


    return (
        <div className="CashbackContainer mr-3">
            <Dropdown color="default" isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret>Cashback</DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={handleToggleModal}>Create cashback</DropdownItem>
                    <DropdownItem>
                        <Link to="/a/stores/cashback-history" style={{ color: "#6c757d" }}>Cashback history</Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>

            <CashbackModal isOpen={isOpen} toggle={handleToggleModal} />
        </div>
    )
}

export default CashbackButton