import React, {useState, useCallback} from 'react'
import AsyncSelect from 'react-select/async'
import {getListStores} from "../../../../services/api/StoreServices"
import debounce from 'lodash/debounce'


const Filter = ({filter, onChangeFilter}) => {
    let _timer = null

    const [typeStore, setTypeStore] = useState('slug')

    const _fetchListStores = async (storeName) => {
        try {
            const params = {
                limit: 10,
                [typeStore]: storeName,
            }

            const {data, success, message} = await getListStores(params)

            if (!success) throw new Error(message)

            const {stores} = data
            return stores.map(store => {
                return {
                    value: store.slug,
                    label: store.slug + '--' + store.domain,
                }
            })
        } catch (e) {
            console.log(e)

        }
    }

    const _handleLoadOptions = (inputValue) => {
        if (_timer) clearTimeout(_timer)

        return new Promise(resolve => {
            _timer = setTimeout(() => {
                resolve(_fetchListStores(inputValue))
            }, 500)
        })
    }
    const _handleInputChange = (options) => {
        const valueSelect = options.value
        onChangeFilter('store_slug', valueSelect)
    }
    const debounceOrderNumber = useCallback(debounce((name, value) => onChangeFilter(name, value), 1000), [])

    const _onChange = (name, value) => {
        switch (name) {
            case "slug":
                setTypeStore(value)
                break
            case "status":
                onChangeFilter(name, value)
                break
            case "order_number":
                debounceOrderNumber(name, value)
                break
            default:
                break
        }
    }

    return (
        <div className='Filter'>
            <div className='row'>
                <div className='col-5'>
                    <div className='InputStore flex-wrap d-flex align-items-center InputGroup mr-4'>
                        <label className='mr-2'>Store: </label>
                        <div className='SelectInputStore d-flex align-items-center border rounded'>
                            <select
                                value={typeStore}
                                className='border-0'
                                onChange={(e) => {_onChange('slug', e.target.value)}}
                            >
                                <option value='slug'>Store ID</option>
                                <option value='domain'>Domain</option>
                            </select>
                            <AsyncSelect
                                noOptionsMessage={() => null}
                                defaultOptions
                                loadOptions={_handleLoadOptions}
                                onChange={_handleInputChange}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-4'>
                    <div className='InputStore flex-wrap d-flex align-items-center InputGroup mr-4'>
                        <label className='mr-2'>Order number: </label>
                        <input className='OrderNumber' onChange={(e) => {_onChange('order_number', e.target.value)}} placeholder='Order number' type="text" id="fname" name="fname" />
                    </div>
                </div>
                <div className='col-3'>
                    <div className='InputStore flex-wrap d-flex align-items-center InputGroup mr-4'>
                        <label className='mr-2'>Payment status: </label>
                        <div className='d-flex align-items-center border rounded'>
                            <select
                                className='border-4 PaymentStatus'
                                onChange={(e) => {_onChange('status', e.target.value)}}
                            >
                                <option value=''>All</option>
                                <option value='refund'>Refunded</option>
                                <option value='partial_refund '>Partially refunded</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter
