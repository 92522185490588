import React from 'react'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import SettingDeliveryPage from './SettingDeliveryPage'

const SettingDeliveryContainer = () => {
    return <div className="SettingDeliveryContainer">
         <DocTitleByStore title="Setting delivery" />
         <SettingDeliveryPage />
    </div>
}

export default SettingDeliveryContainer

