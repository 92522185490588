import React, {Component} from 'react'

// import PropTypes from 'prop-types'

class AdditionalItemPriceInput extends Component {
    _handleChangeInput = e => {
        const {value} = e.target

        this.props.onUpdatePerItemData("additional_item", Number(value))
    }

    _handlePressInput = e => {
        if (e.key === "Enter") {
            e.preventDefault()
        }
    }

    render() {
        return (
            <div className="AdditionalItemPriceInput">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                    </div>

                    <input type="number" step="any" name="additional_item" className="form-control"
                           onChange={this._handleChangeInput} onKeyPress={this._handlePressInput} required/>
                </div>
            </div>
        )
    }
}

// AdditionalItemPriceInput.defaultProps = {}

// AdditionalItemPriceInput.propTypes = {}

export default AdditionalItemPriceInput
