import React, {Component} from 'react'
import PropTypes from 'prop-types'
import WithrawalApproveModal from './WithrawalApproveModal'
import {UncontrolledTooltip} from 'reactstrap'

class WithrawalApprove extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
        }
    }

    _handleToggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }

    render() {
        const {isOpen} = this.state
        const {status, withrawalId} = this.props

        if ('pending' !== status) return null

        return (
            <div className='TransactionOutApprove mr-2'>
                <span id={`approve_${withrawalId}`} className='Icon text-success cursor-pointer'
                      onClick={this._handleToggleModal}>
                    <i className="fas fa-check"/>
                </span>

                <UncontrolledTooltip target={`approve_${withrawalId}`} placement='bottom'>
                    Approve Withdrawal
                </UncontrolledTooltip>

                {isOpen && (
                    <WithrawalApproveModal
                        isOpen={isOpen}
                        onToggleModal={this._handleToggleModal}
                        store={this.props.store}
                        status={this.props.status}
                        withrawalId={this.props.withrawalId}
                        onFetchWithdrawals={this.props.onFetchWithdrawals}
                    />
                )}
            </div>
        )
    }
}

WithrawalApprove.propTypes = {
    status: PropTypes.string.isRequired,
    withrawalId: PropTypes.string.isRequired,
}

export default WithrawalApprove
