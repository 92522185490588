import React, { useState } from 'react'
import TableHead from './TableHead'
import TableBody from './TableBody'
import PFPagePagination from '../../../shared/PFPagePagination'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { deleteAffiliateById, updateAffiliateStatus } from '../../../../services/api/AffiliateServices'
import { toaster } from '../../../shared/PFToast'

const AffiliatesTable = (props) => {
    const { loading, affiliates, onChangePage, params, onDeleteSuccess } = props
    const [modal, setModal] = useState(false)
    const [affilateId, setAffiliateId] = useState(null)

    const toggle = () => {
        setModal(!modal)
    }

    const _updateAffiliateStatus = async (id) => {
        const { success, message } = await updateAffiliateStatus(id)
        if (success) {
            toaster({
                type: 'success',
                message: 'Active affiliate status successfully.',
            })
            onDeleteSuccess()
        } else {
            toaster({
                type: 'error',
                message
            })
        }
    }

    const _handleChangePage = (page) => {
        onChangePage(page)
    }

    return (
        <>
            <DeletePopup
                toggle={toggle}
                modal={modal}
                onDeleteSuccess={onDeleteSuccess}
                affilateId={affilateId}
            />
            <table className="table">
                <TableHead />
                <TableBody
                    params={params}
                    loading={loading}
                    affiliates={affiliates}
                    toggle={toggle}
                    setModal={setModal}
                    updateAffiliateStatus={_updateAffiliateStatus}
                    setAffiliateId={setAffiliateId}
                />
            </table>
            <PFPagePagination
                page={params.page}
                pages={params.pages}
                onChangePage={_handleChangePage}
            />
        </>
    )
}

const DeletePopup = (props) => {
    const [loading, setLoading] = useState(false)
    const { affilateId, modal, toggle, onDeleteSuccess } = props

    const _handleDelete = async () => {
        setLoading(true)
        const { success, message } = await deleteAffiliateById(affilateId)
        if (success) {
            onDeleteSuccess()
            toggle()
        } else {
            toaster({
                type: 'error',
                message
            })
        }
        setLoading(false)
    }
    return (
        <div>
            <Modal
                isOpen={modal}
                toggle={toggle}
                centered
            >
                <ModalHeader toggle={toggle}>Deactive affiliate</ModalHeader>
                <ModalBody>Are you sure you want to deactive this affiliate?</ModalBody>
                <ModalFooter>
                    <Button
                        color="default"
                        disabled={loading}
                        onClick={toggle}
                    >
                        Cancel
                    </Button>{' '}
                    <Button
                        color="danger"
                        disabled={loading}
                        onClick={_handleDelete}
                    >
                        {loading && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                        Deactive
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default AffiliatesTable
