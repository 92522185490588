import React, {Component} from 'react'
import SettingSectionDetails from '../../setting/shared/SettingSectionDetails'
import MailSettings from './MailSettings'
import {getSettingsByCategory, saveSettingsByCategory} from '../../../services/api/SettingServices'

class SettingMailPage extends Component {
    state = {
        settings: {
            'smtp_host': '',
            'smtp_port': '',
            'smtp_user': '',
            'smtp_pass': '',
        },
        loading: {
            fetchSettings: false,
            saveSettings: false,
        },
        err: null,
    }

    componentWillMount() {
        this._fetchSettings()
    }

    _onChangeSetting = (key, value) => {
        this.setState(({settings}) => ({
            settings: {
                ...settings,
                [key]: value,
            }
        }))
    }

    _onClickSaveButton = () => {
        this._saveSettings()
    }

    _fetchSettings = async () => {
        this.setState(({loading}) => ({
            loading: {
                ...loading,
                fetchSettings: true,
            }
        }))

        try {
            const {data, success, err} = await getSettingsByCategory('mail')

            if (success) return this.setState(({loading, settings}) => ({
                loading: {
                    ...loading,
                    fetchSettings: false,
                },
                settings: {
                    ...settings,
                    ...data
                }
            }))

            alert(err)
        } catch (e) {
            alert(e.message || e)
            this.setState(({loading}) => ({
                loading: {
                    ...loading,
                    fetchSettings: false,
                }
            }))
        }
    }

    _saveSettings = async () => {
        this.setState(({loading}) => ({
            loading: {
                ...loading,
                saveSettings: true,
            }
        }))

        try {
            const {settings} = this.state
            const {data, err} = await saveSettingsByCategory('mail', {...settings})

            this.setState(({loading}) => ({
                loading: {
                    ...loading,
                    saveSettings: false,
                },
                settings: {
                    ...settings,
                    ...data,
                }
            }))

            if (err) return alert(err)
        } catch (e) {
            alert(e.message || e)
            this.setState(({loading}) => ({
                loading: {
                    ...loading,
                    saveSettings: false,
                }
            }))
        }
    }

    render() {
        const {settings, loading} = this.state

        return (
            <div className="SettingMailPage">

                <div className="Section">
                    <SettingSectionDetails title='Mail settings'/>
                    <div className="SectionSetting">
                        <div className="SectionInner">
                            <MailSettings
                                settings={settings}
                                disabled={loading.fetchSettings}
                                onChangeSetting={this._onChangeSetting}
                            />
                        </div>
                    </div>
                </div>

                <div className="ButtonSave">
                    <button
                        className="btn btn-primary"
                        disabled={loading.saveSettings || loading.fetchSettings}
                        onClick={this._onClickSaveButton}
                    >
                        <span
                            className="Label"
                        >
                            Save
                        </span>
                    </button>
                </div>

            </div>
        )
    }
}

export default SettingMailPage
