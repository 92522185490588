import React, { Component } from 'react'
import ProductDesignFormatProvider from './ProductDesignFormatProvider'
import DesignFormatContainer from './design-format/DesignFormatContainer'

class ProductDesignFormatContainer extends Component {

  render() {
    const { product, gallery, productId, type = 'front' } = this.props

    return (
      <ProductDesignFormatProvider>
        <DesignFormatContainer
          product={product}
          productId={productId}
          type={type}
          gallery={gallery}
        />
      </ProductDesignFormatProvider>
    )
  }
}

export default ProductDesignFormatContainer

