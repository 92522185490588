import React, {useState} from 'react'
import {useEffect} from 'react'
import {useCallback} from 'react'
import {getListDailyTopup} from '../../../../services/api/AccountingReport'
import {toaster} from '../../../shared/PFToast'
import DailyTopupReportInputDate from './filter/DailyTopupReportInputDate'
import DailyTopupReportTable from './data/DailyTopupReportTable'
import PFPagePagination from '../../../shared/PFPagePagination'
import {deleteEmptyObjectkeys} from '../../../../helpers/common/cleanObject'

const DailyTopupReportPage = () => {
    const [loading, setLoading] = useState(false)
    const [pages, setPages] = useState(1)
    const [reportData, setReportData] = useState([])
    const [query, setQuery] = useState({
        page: 1,
        limit: 10,
        formDate: {
            from: '',
            to: '',
        },
    })

    const _fetchDailyTopupReport = useCallback(async () => {
        try {
            setLoading(true)
            const {page, limit, formDate} = query
            const params = {
                page,
                limit,
                from: formDate.from,
                to: formDate.to,
            }
            const {success, data, message} = await getListDailyTopup(deleteEmptyObjectkeys(params))
            setLoading(false)
            if (!success) {
                throw new Error(message)
            }
            setPages(data.pages)
            setReportData(data.jobs)
        } catch (error) {
            setLoading(false)
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }, [query])

    const _handleChangeDate = (property, value) => {
        setQuery((prev) => ({
            ...prev,
            [property]: value,
        }))
    }

    const _onChangeSelect = (event) => {
        const {value} = event.target
        setQuery((prev) => ({
            ...prev,
            limit: value,
        }))
    }

    const _handlePageChange = (page) => {
        setQuery((prev) => ({
            ...prev,
            page,
        }))
    }

    useEffect(() => {
        _fetchDailyTopupReport()
    }, [_fetchDailyTopupReport])

    return (
        <div className="DailyTopupReportPage">
            <h1 className="PageTitle">Daily Topup Report</h1>

            <div className="SectionInner">
                <div className="TableFilter d-flex align-items-center flex-wrap">
                    <div className="TableLength mr-3">
                        Show
                        <select className="ml-2" name="limit" value={query.limit} onChange={_onChangeSelect}>
                            <option value="10">10 rows</option>
                            <option value="20">20 rows</option>
                            <option value="50">50 rows</option>
                            <option value="100">100 rows</option>
                        </select>
                    </div>
                    <DailyTopupReportInputDate onChange={_handleChangeDate} />
                </div>
                <DailyTopupReportTable page={query.page} reportData={reportData} loading={loading} refetch={_fetchDailyTopupReport} />
                <PFPagePagination page={query.page} pages={pages} onChangePage={_handlePageChange} />
            </div>
        </div>
    )
}

export default DailyTopupReportPage
