import React, {Component} from 'react'
import ManageCountriesContainer from "./manage-countries/ManageCountriesContainer"
import SelectedCountriesContainer from "./selected-countries/SelectedCountriesContainer"
import {getShippingZoneCountries} from "../../../../../services/api/ShippingSettingsServices"

class ZoneCountriesContainer extends Component {
    state = {
        selectedCountries: [],
        zoneCountries: {},
        error: "",
    }

    componentDidMount() {
        this._fetchListCountries()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedCountries} = this.state
        const {onUpdateZoneData} = this.props

        if ("rest" === selectedCountries) {
            onUpdateZoneData("rest_of_world", true)
        } else {
            const countriesCode = selectedCountries.map(country => country.code)
            onUpdateZoneData("countries", countriesCode)
        }
    }

    _fetchListCountries = async () => {
        const {profilesId} = this.props
        try {
            const {data, success, message} = await getShippingZoneCountries(profilesId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                zoneCountries: data,
            })

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            return this.setState({
                error: message ? message : e.message,
            })
        }
    }

    _handleUpdateSelectedCountries = (arrCountries) => {
        this.setState({
            selectedCountries: arrCountries,
        })
    }

    _removeSelectedCountry = (countryCode) => {
        this.setState(({selectedCountries}) => {
            const filteredCountries = selectedCountries.filter(country => country.code !== countryCode)

            return {
                selectedCountries: filteredCountries,
            }
        })
    }

    _handleSelectRestOfWorld = () => {
        this.setState({
            selectedCountries: "rest",
        })
    }

    render() {
        const {selectedCountries, zoneCountries} = this.state
        const {countries = []} = zoneCountries


        return (
            <div className="ZoneCountriesContainer">
                <div className="SectionInner">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h6 className="mb-0 fs-18">Countries and regions</h6>
                            <div className="text-muted fs-14">Add or remove countries and regions in this zone.</div>
                        </div>

                        <ManageCountriesContainer selectedCountries={selectedCountries} zoneCountries={zoneCountries}
                                                  onUpdateSelectedCountries={this._handleUpdateSelectedCountries}
                                                  onSelectRestOfWorld={this._handleSelectRestOfWorld}/>
                    </div>

                    <SelectedCountriesContainer selectedCountries={selectedCountries} countries={countries}
                                                onRemoveSelectedCountry={this._removeSelectedCountry}/>
                </div>
            </div>
        )
    }
}

export default ZoneCountriesContainer
