import React, {useState, useEffect} from 'react'

const useFetch = ({fetchFunc, enabled = true}) => {
    const [response, setResponse] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const {success, data, message} = await fetchFunc()
                if (!success) {
                    throw new Error(message)
                }
                setResponse(data)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (enabled) {
            fetchData()
        }
    }, [])

    return {response, loading, error}
}

export default useFetch
