import React, {Component} from 'react'
import PropTypes from 'prop-types'

class DnsRecordName extends Component {
    render() {
        const {name} = this.props.record
        return (
            <td className="DnsRecordName">
                {name}
            </td>
        )
    }
}

DnsRecordName.propTypes = {
    record: PropTypes.object.isRequired,
}

export default DnsRecordName
