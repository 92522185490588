import React, { Component } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import StoreHistories from "./StoreHistories"
import OwnerDetails from "./OwnerDetails"
import StoreDetails from "./StoreDetails"
import { getStoreDetail, ignoreSuspend } from "../../../services/api/StoreServices"
import StorePlan from "./StorePlan"
import InitStoreInvoice from "./init/InitStoreInvoice"
import ClusterDetail from "./cluster-detail/ClusterDetail"
import DnsRecord from "./dns-record/DnsRecord"
import ServiceListPageContainer from "./ServiceList/ServiceListPageContainer"
import StoreTags from "./StoreTags"
import StorePreferredLanguage from "./StorePreferredLanguage"
import StoreNote from "./StoreNote"
import ImporterWorker from "./ImporterWorker/ImporterWorker"
import StoreFeatureTagsContainer from "./feature-tags/StoreFeatureTagsContainer"
import StoreMode from "./StoreMode"
import BackNavigationButton from "../../shared/BackNavigationButton"
import WithdrawalsButton from "./WithdrawalsButton"
import TransactionsButton from "./TransactionsButton"
import MigrationDetail from "./migration-detail/MigrationDetail"
import StoreImportProducts from "./StoreImportProducts"
import ResourceContainer from "./resources/ResourceContainer"
import JobContainer from "./cicd/JobContainer"
import { toaster } from "../../shared/PFToast"
const REFRESH_TIME = 5000

class StoreDetailPage extends Component {
    state = {
        storeDetails: {},
        err: "",
        loading: true,
        loadingIgnoreSuspend: false,
        stt: "",
    }

    _refresh = null

    componentWillUnmount() {
        this._refresh && clearInterval(this._refresh)
    }

    componentDidMount() {
        this._getStoreDetail()
        this._setRefresh()
    }

    _setRefresh = () => {
        this._refresh = setInterval(() => {
            this._getStoreDetail(false)
        }, REFRESH_TIME)
    }

    _getStoreDetail = async (loadingAnimation = true) => {
        try {
            this.setState({
                loading: loadingAnimation,
            })

            const { storeId } = this.props
            const { success, data, message } = await getStoreDetail(storeId)

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            const storeDetails = Object.assign({}, data)
            this.setState({
                storeDetails,
                loading: false,
                err: "",
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _changeStoreDetail = (storeDetails) => {
        this.setState({
            storeDetails,
        })
    }

    _changeStoreSubscription = (subscription) => {
        const { storeDetails } = this.state

        return this._changeStoreDetail({ ...storeDetails, subscription })
    }

    _handleIgnoreSuspend = async () => {
        try {
            const { storeId } = this.props
            this.setState({loadingIgnoreSuspend: true})
            const { success, message } = await ignoreSuspend(storeId)
            this.setState({loadingIgnoreSuspend: false})
            if (!success) {
                this.setState({
                    loading: false,
                    err: message,
                })
            }
            toaster({
                type: "success",
                message: "Ignore suspend successfully!"
            })
            this._getStoreDetail(false)
        } catch (error) {
            this.setState({loadingIgnoreSuspend: false})
        }
    }

    render() {
        const { storeId } = this.props
        const { storeDetails, err, loading, loadingIgnoreSuspend } = this.state
        const cluster = Object.assign({}, storeDetails.cluster)
        const provider = storeDetails.provider || ""
        const { mode = "", group, tags } = storeDetails

        return (
            <div className="StoreDetailPage">
                <BackNavigationButton to="/a/stores" text="Stores" />

                <div className="d-flex justify-content-between align-items-center">
                    <InitStoreInvoice storeId={storeId} />

                    {mode !== "normal" && <WithdrawalsButton store={storeDetails} />}
                    {mode === "marketplace" && <TransactionsButton store={storeDetails} />}

                    <div className="StoreDetailPageButtons">
                        <button
                            className="btn btn-warning mr-2"
                            disabled={tags && !tags.includes("pre-suspend") || loadingIgnoreSuspend}
                            onClick={this._handleIgnoreSuspend}
                        >
                            { loadingIgnoreSuspend && <i className="fas fa-circle-notch fa-spin mr-2"></i> }
                            Ignore suspend
                        </button>
                        <Link to={`/a/stores/${storeId}/deposits`}>
                            <button className="btn btn-primary DepositsButton">Deposits</button>
                        </Link>
                    </div>
                </div>

                <div className={`StoreDetailInner SectionInner ${storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                    {!!err && <span className="text-warning">{err}</span>}
                    {!loading && (
                        <div className="row">
                            <div className="StoreLeftPanel col">
                                <div className={`mt-3 ${storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                                    <StoreDetails
                                        storeDetails={storeDetails}
                                        changeStoreDetail={this._changeStoreDetail}
                                    />
                                </div>
                                <div className="mt-3">
                                    <ResourceContainer storeDetails={storeDetails} />
                                </div>
                                <div className="mt-3">
                                    <JobContainer storeDetails={storeDetails} />
                                </div>
                                <div className={`mt-3 ${storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                                    <StoreTags storeDetails={storeDetails} />
                                </div>
                                <div className={`mt-3 ${storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                                    <StorePreferredLanguage
                                        getStoreDetail={this._getStoreDetail}
                                        onChange={this._changeStoreDetail}
                                        store={storeDetails}
                                    />
                                </div>
                                <div className={`mt-3 ${storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                                    <StoreFeatureTagsContainer store={storeDetails} />
                                </div>
                                <div className={`mt-3 ${storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                                    <StoreNote storeDetails={storeDetails} />
                                </div>
                                {mode !== "marketplace" && (
                                    <div className={`mt-3 ${storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                                        <StoreImportProducts storeDetails={storeDetails} />
                                    </div>
                                )}
                            </div>
                            <div className="StoreRightPanel col">
                                <StoreMode
                                    getStoreDetail={this._getStoreDetail}
                                    onChange={this._changeStoreDetail}
                                    store={storeDetails}
                                />

                                <StorePlan
                                    storeDetails={storeDetails}
                                    changeSubscription={this._changeStoreSubscription}
                                />
                                <OwnerDetails storeDetails={storeDetails} />

                                <div className="mt-3">
                                    <ClusterDetail storeDetails={storeDetails} group={group} cluster={cluster} storeId={storeId} />
                                </div>

                                <div className="mt-3">
                                    <MigrationDetail storeDetails={storeDetails} provider={provider} storeId={storeId} />
                                </div>

                                <div className="mt-3">
                                    <DnsRecord storeDetails={storeDetails} storeId={storeId} />
                                </div>
                                <div className="mt-3">
                                    <ServiceListPageContainer storeDetails={storeDetails} />
                                </div>
                                <div className="mt-3">
                                    <ImporterWorker storeDetails={storeDetails} storeId={storeId} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="mt-3">
                    <StoreHistories storeId={storeId} />
                </div>
            </div>
        )
    }
}

StoreDetailPage.propTypes = {
    storeId: PropTypes.string.isRequired,
}

export default StoreDetailPage
