import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {randomID} from '../../helpers/common/randomID'

class PFTablePlaceholder extends Component {
    render() {
        const {rowQuantity = 3, columnQuantity, columns} = this.props
        const mappedRows = new Array(rowQuantity).fill(null)
        const mappedColumns = columns.length ? columns : new Array(columnQuantity).fill(null)
        return (
            <>
                {mappedRows.map((index) => {
                    const id = randomID()
                    return (
                        <tr key={id}>
                            {mappedColumns.map((column, i) => (
                                <td key={i}>
                                    <span className="table-loading-placeholder Cell" />
                                </td>
                            ))}
                        </tr>
                    )
                })}
            </>
        )
    }
}

PFTablePlaceholder.defaultProps = {
    columns: [],
    rowQuantity: 3,
    columnQuantity: 0,
}

PFTablePlaceholder.propTypes = {
    columns: PropTypes.array,
    rowQuantity: PropTypes.number,
    columnQuantity: PropTypes.number,
}
export default PFTablePlaceholder

