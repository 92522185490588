import React, {Component, Fragment} from 'react'
import AsyncSelect from 'react-select/async'
import {getListStores} from '../../../../../../services/api/StoreServices'
import PropTypes from 'prop-types'

class InputSelectStores extends Component {
    _timer = null

    _fetchListStores = async (storeName) => {
        try {
            const params = {
                limit: 10,
                domain: storeName,
                loading: false,
                status: 'active',
            }

            const {data, success, message} = await getListStores(params)

            if (!success) {
                return [{label: message, value: 'error'}]
            }

            const {stores} = data

            return stores.map((store) => {
                return {
                    value: store._id,
                    label: store.domain,
                }
            })
        } catch (e) {
            return [{label: e.message, value: 'error'}]
        }
    }

    _handleLoadOptions = (inputValue) => {
        if (this._timer) clearTimeout(this._timer)

        return new Promise((resolve) => {
            this._timer = setTimeout(() => {
                resolve(this._fetchListStores(inputValue))
            }, 500)
        })
    }

    _handleInputChange = (selected) => {
        const arrIds = Array.isArray(selected) ? selected.map((store) => store.value) : []

        this.props.onChangeFormData('storeIds', arrIds)
    }

    _handleChangeRadio = (e) => {
        const {value} = e.target
        this.props.onChangeFormData('all', 'all' === value)
        this.props.handleChangeType('type', value)
    }

    _handleChangeStoreByPreferredLanguage = (e) => {
        const {value} = e.target
        this.props.onChangeFormData('preferred_language', value)
    }

    _handleChangeDomains = (e) => {
        const {value} = e.target

        this.props.onChangeFormData('domains', value)
    }

    render() {
        return (
            <div className="InputSelectStores form-group">
                <div className="mb-2 font-weight-500">Stores</div>

                <div className="row">
                    <div className="col-4">
                        <div className="custom-control custom-radio mb-2 mr-3">
                            <input
                                type="radio"
                                id="selectAllStores"
                                name="store"
                                value="all"
                                className="custom-control-input"
                                defaultChecked
                                onChange={this._handleChangeRadio}
                            />
                            <label htmlFor="selectAllStores" className="custom-control-label">
                                Select all stores
                            </label>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="custom-control custom-radio mb-2">
                            <input
                                type="radio"
                                id="selectStoreWorldwide"
                                name="store"
                                value="en"
                                className="custom-control-input"
                                onChange={this._handleChangeStoreByPreferredLanguage}
                            />
                            <label htmlFor="selectStoreWorldwide" className="custom-control-label">
                                Select store in World wide
                            </label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-4">
                        <div className="custom-control custom-radio mr-3">
                            <input
                                type="radio"
                                id="selectIndividualStore"
                                name="store"
                                value="storeIds"
                                className="custom-control-input"
                                onChange={this._handleChangeRadio}
                            />
                            <label htmlFor="selectIndividualStore" className="custom-control-label">
                                Select individual store
                            </label>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="custom-control custom-radio">
                            <input
                                type="radio"
                                id="selectStoreVietnam"
                                name="store"
                                value="vi"
                                className="custom-control-input"
                                onChange={this._handleChangeStoreByPreferredLanguage}
                            />
                            <label htmlFor="selectStoreVietnam" className="custom-control-label">
                                Select store in Vietnam
                            </label>
                        </div>
                    </div>
                </div>
                {this.props.type === 'storeIds' && (
                    <div className="row position-relative" style={{zIndex: 2}}>
                        <div className="col-12 custom-control">
                            <AsyncSelect
                                isMulti
                                defaultOptions
                                loadOptions={this._handleLoadOptions}
                                onChange={this._handleInputChange}
                                className="SelectStores mt-2 ml-3"
                            />
                        </div>
                    </div>
                )}

                <div className="custom-control custom-radio my-2">
                    <input
                        type="radio"
                        id="selectSpecialStore"
                        name="store"
                        value="special"
                        className="custom-control-input"
                        onChange={this._handleChangeRadio}
                    />
                    <label htmlFor="selectSpecialStore" className="custom-control-label">
                        Select special stores
                    </label>

                    {this.props.type === 'special' && (
                        <input
                            value={this.props.formData.domains}
                            className="form-control mt-2"
                            name="domains"
                            type="text"
                            onChange={this._handleChangeDomains}
                            placeholder="Domains special stores"
                        />
                    )}
                </div>
            </div>
        )
    }
}

InputSelectStores.propTypes = {
    onChangeFormData: PropTypes.func.isRequired,
}

export default InputSelectStores