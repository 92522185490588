export default {
    order: 1,
    currency: 'USD',
    duration_amount: 1,
    duration_unit: 'month',
    max_products: 5000,
    price: 29.99,
    subtitle: '',
    title: '',
    transaction_rate: 0.02,
    is_public: true,
    is_active: true,
    available_storefront: false,
}
