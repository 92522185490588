import React, {Component} from 'react'
import PropTypes from 'prop-types'

class KindColumn extends Component {
    render() {
        const {item} = this.props
        const {kind} = item

        return (
            <td className='HostColumn'>
                <span>{kind}</span>
            </td>
        )
    }
}

KindColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default KindColumn
