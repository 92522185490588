import React from 'react'
import ShipmentReportTableHead from './ShipmentReportTableHead'
import ShipmentReportTableBody from './ShipmentReportTableBody'

const ShipmentReportTable = (props) => {

    return (
        <div className="table-responsive mb-3">
            <table className="table table-bordered table-hover">
                <ShipmentReportTableHead {...props} />
                <ShipmentReportTableBody {...props} />
            </table>
        </div>
    )
}

export default ShipmentReportTable

