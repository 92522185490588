import React from 'react'
import RowProfitAdd from './RowProfitAdd'
import moment from 'moment'
import NumberFormat from 'react-number-format'

const ProfitAdjustment = ({ onClickAddProfit, profits, onChangeProfits, errorProfit, isDisabled, profitAdjustment }) => {
    return (
        <div className='row mb-3'>
            <div className="col-12">
                <div className='row'>
                    <div className='col-8'>
                        <h6 className='mb-3 font-weight-bold'>Profit adjustment</h6>
                    </div>
                    <div className='col-4 text-right pr-4'>
                        <button type="button" disabled={isDisabled} onClick={onClickAddProfit} className="btn btn-primary btn-sm"><i className="fas fa-plus"></i></button>
                    </div>
                </div>

                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Time</th>
                            <th scope="col">Value</th>
                            <th scope="col">Note</th>
                            <th scope="col">Created by</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {profitAdjustment.map((item, index) => {
                            const { email_actor = '', ip_actor = '' } = item
                            return <tr key={index}>
                                <td>{moment(item.created).format('DD-MM-YYYY HH:mm A')}</td>
                                <td>
                                    <NumberFormat value={item.profit} className="foo" displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value, props) => <div {...props}>{value}</div>} />
                                </td>
                                <td>
                                    <div className='note'>{item.note}</div>
                                </td>
                                <td>
                                    <span>
                                        {email_actor}
                                    </span>
                                    <br />
                                    <span>
                                        {ip_actor}
                                    </span>
                                </td>
                            </tr>
                        })}

                        <RowProfitAdd
                            profits={profits}
                            onChangeProfits={onChangeProfits}
                            errorProfit={errorProfit}
                        />
                    </tbody>
                </table>
                <hr />
            </div>
        </div>

    )
}


export default ProfitAdjustment
