import React from 'react'
import humanizeTime from '../../../helpers/time/humanizeTime'

const GroupInfomation = (props) => {
    const {group} = props

    return (
        <div className='GroupClusterInfomation'>
            <div>
                <label>Namespace:</label> {group.namespace || ''}
            </div>
            <div>
                <label>Type:</label> {group.type || ''}
            </div>
            <div>
                <label>Status:</label> {group.status || ''}
            </div>
            <div>
                <label>Created:</label> {group.created ? humanizeTime(group.created) : ''}
            </div>
        </div>
    )
}

export default GroupInfomation
