import React from 'react'
import {Formik, Field, Form, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'

class CreateCategoryForm extends React.Component {
    render() {
        return (
            <div className='CreateCategoryForm'>
                    <Formik
                        initialValues={{
                            title: '',
                        }}
                        validationSchema={Yup.object().shape({
                            title: Yup.string().trim().required('Title is required'),
                        })}
                        onSubmit={async (fields, {setSubmitting}) => {
                            const payload = {
                                ...fields
                            }
                            const {createNewCategory} = this.props
                            await createNewCategory(payload)
                            setSubmitting(false)
                        }}
                        render={({errors, touched, isSubmitting}) => {
                            return (
                                <Form>
                                    <div className='form-group'>
                                        <span>Title</span>
                                        <Field
                                            name='title'
                                            type='text'
                                            className={`form-control${errors.title && touched.title ? ' is-invalid' : ''}`}
                                        />
                                        <ErrorMessage name='title' component='div' className='invalid-feedback'/>
                                    </div>
                                    <div className='form-group'>
                                        <div className='form-group'>
                                            <button type='submit' disabled={isSubmitting || Object.keys(errors).length} className='btn btn-primary mr-2'>
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )
                        }}
                    />
            </div>
        )
    }
}

CreateCategoryForm.propTypes = {
    createNewCategory: PropTypes.func.isRequired,
}

export default CreateCategoryForm
