import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import {getStoreDetail} from '../../../services/api/StoreServices'
import DepositsTable from './DepositsTable'
import parseSearchQuery from "../../../helpers/routing/parseSearchQuery"

class StoreDepositsPage extends Component {
    state = {
        store: {_id: this.props.storeId},
    }

    componentDidMount() {
        this._fetchStoreDetails()
    }

    componentWillUnmount() {
        window.currentStore = null
    }

    _fetchStoreDetails = async () => {
        const {store} = this.state

        try {
            const {success, data, message} = await getStoreDetail(store._id)
            if (success) return this.setState({store: {...store, ...data}})
            console.error(message)
        } catch (e) {
            console.error(e.message || e)
        }
    }

    render() {
        const {store} = this.state
        window.currentStore = Object.assign({}, this.state.store)
        const urlParams = parseSearchQuery()
        const {page = 1} = urlParams
        const currentPageParsed = Number(page)

        return (
            <div className="StoreDeposits container-fluid">
                <Link to={`/a/stores/${store._id}`}><i className="fas fa-chevron-left mr-2"/>{store.name}</Link>
                <DepositsTable page={currentPageParsed} store={store}/>
            </div>
        )
    }
}

StoreDepositsPage.propTypes = {
    storeId: PropTypes.string.isRequired,
}

export default StoreDepositsPage
