import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ClusterNatsURI extends Component {
    state = {
        natsURI: this.props.natsURI,
    }

    _handleChangeInput = e => {
        const {name, value} = e.target
        const {onUpdateCluster} = this.props

        this.setState({
            natsURI: value,
        }, onUpdateCluster(name, value))
    }

    render() {
        const {natsURI} = this.state

        return (
            <div className="ClusterNatsURI form-group">
                <label htmlFor="clusterNatsURI">Nats URI</label>
                <input type="text" className="form-control" id="clusterNatsURI" name="nats_uri" value={natsURI}
                       onChange={this._handleChangeInput}/>
            </div>
        )
    }
}

ClusterNatsURI.propTypes = {
    natsURI: PropTypes.string.isRequired,
    onUpdateCluster: PropTypes.func.isRequired,
}

export default ClusterNatsURI
