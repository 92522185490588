import React, {Component} from 'react'
import {Button} from 'reactstrap'
import ExportModal from './ExportModal'
import PropTypes from 'prop-types'

class ExportActionContainer extends Component {
    state = {
        isOpen: false,
        isExportOrder: false,
        isExportAll: false,
        isExportPackage: false
    }

    _toggleModal = (isExportOrder, isExportAll = false, isExportPackage) => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
            isExportOrder: isExportOrder,
            isExportAll: isExportAll,
            isExportPackage: isExportPackage
        }))
    }

    _validateDateRange = () => {
        const {dateRange} = this.props
        if (typeof dateRange !== 'object') return false

        return !(!dateRange.from || !dateRange.to)
    }

    render() {
        const canExport = this._validateDateRange()

        return (
            <div className='ExportReportAction'>
                <Button
                    color='primary'
                    disabled={!canExport}
                    onClick={() => this._toggleModal(false)}
                >
                    Export
                </Button>
                {/*&nbsp;&nbsp;&nbsp;*/}
                {/*<Button*/}
                {/*    color='primary'*/}
                {/*    disabled={!canExport}*/}
                {/*    onClick={() => this._toggleModal(true)}*/}
                {/*>*/}
                {/*    Export 1C orders profit*/}
                {/*</Button>*/}
                {/*&nbsp;&nbsp;&nbsp;*/}
                {/*<Button*/}
                {/*    color='primary'*/}
                {/*    disabled={!canExport}*/}
                {/*    onClick={() => this._toggleModal(true, true)}*/}
                {/*>*/}
                {/*    Export all orders profit*/}
                {/*</Button>*/}
                &nbsp;&nbsp;&nbsp;
                <Button
                    color='primary'
                    disabled={!canExport}
                    onClick={() => this._toggleModal(false, false, true)}
                >
                    Export 1C orders profit
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                    color='primary'
                    disabled={!canExport}
                    onClick={() => this._toggleModal(false, true, true)}
                >
                    Export all orders profit
                </Button>

                <ExportModal {...this.props} isOpen={this.state.isOpen}
                             onToggleModal={this._toggleModal}
                             isExportOrder={this.state.isExportOrder}
                             isExportAll={this.state.isExportAll}
                             isExportPackage={this.state.isExportPackage}
                />
            </div>
        )
    }
}

ExportActionContainer.propTypes = {
    dateRange: PropTypes.object.isRequired,
}

export default ExportActionContainer
