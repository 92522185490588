import React from 'react'
import moment from 'moment'
import NumberFormat from 'react-number-format'

const FulfillmentRefund = ({ invoiceRefund }) => {
    return (
        <div className='row mb-3'>
            <div className="col-12">
                <h6 className='mb-3 font-weight-bold'>Fulfillment  refund</h6>
                <table className="table">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Time</th>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Value</th>
                            <th scope="col">Note</th>
                            <th scope="col">Tag</th>

                        </tr>
                    </thead>
                    <tbody>
                        {invoiceRefund.map((item, index) => {
                            const { reason = {} } = item
                            return <tr key={index}>
                                <td>{moment(item.created).format('DD-MM-YYYY HH:mm A')}</td>
                                <td>{item.ffm_refund_id}</td>
                                <td>
                                    <NumberFormat value={item.price} className="foo" displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value, props) => <div {...props}>{value}</div>} />
                                </td>
                                <td>
                                    <div className='note'>{item.reason_for_seller}</div>
                                </td>
                                <td>
                                    {
                                        reason.description
                                    }
                                </td>
                            </tr>
                        })}

                    </tbody>
                </table>
                <hr />
            </div>
        </div>

    )
}

export default FulfillmentRefund
