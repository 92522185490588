import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import _ from 'lodash'
import moment from 'moment/moment'
import humanizeTime from '../../../../helpers/time/humanizeTime'
import {getListStores} from '../../../../services/api/StoreServices'
import {getListClusters} from '../../../../services/api/ClustersServices'
import {checkAssign} from '../../../../services/api/ResourceService'
import TablePagination from '../TablePagination'
import AssignButton from './AssignButton'
import classNames from 'classnames'
import {getListGroups} from '../../../../services/api/GroupService'

const _tableColumns = ['Id', 'Name', 'Status', 'Created', 'Actions']

class AssignModal extends Component {
    state = {
        items: [],
        assignStatus: {},
        loading: true,
        checking: true,
        params: {
            page: 1,
            limit: 8,
        },
        target: 'Store',
        search_term: '',
        total: 0,
        totalPage: 1,
        err: '',
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.open !== this.props.open && this.props.open) {
            this.setState(
                {
                    search_term: '',
                },
                () => this._getTargetList(),
            )
        }
    }

    _getTargetList = async () => {
        try {
            this.setState({
                assignStatus: {},
                loading: true,
                checking: true,
            })

            const {params, target, search_term} = this.state
            let response = {}
            if (target === 'Store') {
                response = await getListStores({domain: search_term, ...params})
            } else if (target === 'Group') {
                response = await getListGroups({namespace: search_term, ...params})
            } else {
                response = await getListClusters({name: search_term, ...params})
            }
            const {success, data, message} = response
            const {total, totalPage, pages, stores, clusters, groups} = Object.assign({}, data)

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            this.setState(
                {
                    items: stores || groups || clusters || [],
                    totalPage: totalPage || pages || 1,
                    total: total,
                    loading: false,
                },
                () => this._checkAssignStatus(),
            )
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _checkAssignStatus = async () => {
        try {
            const {items} = this.state
            const itemIds = items.map((item) => item._id)
            const {resourceId} = this.props
            const {success, data} = await checkAssign(resourceId, {targets: itemIds})
            if (success) {
                this.setState({
                    assignStatus: data,
                    checking: false,
                })
            }
        } catch (e) {}
    }

    _validateDate = (time) => {
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }

    _onToggle = () => {
        this.props.onClose()
        this.setState({
            target: 'Store',
        })
    }

    _handlePageChange = (page) => {
        const {params} = this.state
        this.setState(
            {
                params: {...params, page},
            },
            () => this._getTargetList(),
        )
    }

    _onChangeInput = (key) => (e) => {
        const {value} = e.target
        this.setState(
            {
                [key]: value,
                params: {
                    page: 1,
                    limit: 8,
                },
                total: 0,
                totalPage: 1,
            },
            () => this._getTargetList(),
        )
    }

    _onUpdatedAssignStatus = (data) => {
        const {onUpdated} = this.props
        this.setState(
            {
                assignStatus: {
                    ...this.state.assignStatus,
                    [_.get(data, 'target._id').toString()]: true,
                },
            },
            () => onUpdated(data),
        )
    }

    _handleSearchChange = async (e) => {
        const value = e.target.value
        const timeOut = 500
        this.setState(
            {
                search_term: value,
                loading: true,
                totalPage: 1,
                params: {
                    ...this.state.params,
                    page: 1,
                },
            },
            () => {
                clearInterval(this.typingTimer)
                this.typingTimer = setTimeout(this._getTargetList, timeOut)
            },
        )
    }

    render() {
        const {open, resourceId} = this.props
        const {
            loading,
            checking,
            items,
            params: {page},
            totalPage,
            target,
            assignStatus,
        } = this.state

        const isMongodb = this.props.dbKind === 'mongodb'

        const targetArray = !isMongodb ? ['Store'] : ['Store', 'Group']

        return (
            <Modal size="lg" isOpen={open} toggle={this._onToggle} className="PlanModal">
                <ModalHeader toggle={this._onToggle}>Assign new one</ModalHeader>
                <ModalBody>
                    <div className="StoresPage">
                        <div className="StoresPageInner SectionInner">
                            <div className="TableFilter row">
                                <div className="Filter col">
                                    <form className="input-group d-flex justify-content-start">
                                        <div className="input-group-prepend SelectWrapper">
                                            <select
                                                value={target}
                                                id="select-level"
                                                className="custom-select"
                                                onChange={this._onChangeInput('target')}
                                            >
                                                {targetArray.map((item) => (
                                                    <option key={item} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="InputWrapper position-relative">
                                            <i className="ti-search position-absolute d-none" />
                                            <input
                                                onChange={this._handleSearchChange}
                                                className="form-control InputSearch"
                                                placeholder={'Search Name'}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <table className="table table-striped table-light">
                                <thead className="TableHeader thead-light">
                                    <tr>
                                        {_tableColumns.map((column) => (
                                            <th scope="col" key={column}>
                                                {column}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="TableBody">
                                    {!loading &&
                                        items.map((item, index) => (
                                            <tr className="RenderTableRow" key={index}>
                                                <td className="HostColumn">
                                                    <span>{_.get(item, '_id', '')}</span>
                                                </td>
                                                <td className="HostColumn">
                                                    <span>
                                                        {_.get(item, 'domain') ||
                                                            _.get(item, 'name') ||
                                                            _.get(item, 'namespace') ||
                                                            ''}
                                                    </span>
                                                </td>
                                                <td className={classNames('StatusColumn', _.get(item, 'status', ''))}>
                                                    <span className="badge badge-secondary">
                                                        {_.get(item, 'status', '') || ''}
                                                    </span>
                                                </td>
                                                <td className="CreatedColumn">
                                                    <span>{this._validateDate(_.get(item, 'created'))}</span>
                                                </td>
                                                <td className="ActionColumn">
                                                    <AssignButton
                                                        resourceId={resourceId}
                                                        assignStatus={assignStatus}
                                                        itemId={_.get(item, '_id', '')}
                                                        level={target.toLowerCase()}
                                                        checking={checking}
                                                        onUpdated={this._onUpdatedAssignStatus}
                                                    ></AssignButton>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            <TablePagination page={page} pages={totalPage} handlePageChange={this._handlePageChange} />
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

AssignModal.propTypes = {
    resourceId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onUpdated: PropTypes.func.isRequired,
}

export default AssignModal
