import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ProductVariantsHead extends Component {
    render() {
        const {attributes, store} = this.props

        return (
            <thead className="ProductVariantsHead thead-light">
            <tr>
                {
                    Array.isArray(attributes) && attributes.map(att => {
                        return (
                            <th className='table-col-10' key={att._id}>{att.name}</th>
                        )
                    })
                }
                <th className="table-col-10 SKU">Old SKU</th>
                <th className="table-col-10 SKU">New SKU</th>
                {
                    store === 'marketplace' &&
                    <th className="table-col-10 SKU">Weight (gram)</th>
                }
                <th className="table-col-10">Base cost</th>
                <th className="table-col-10">Price</th>
                <th className="table-col-10">
                    <div className="d-flex justify-content-center">
                        Actions
                    </div>
                </th>
            </tr>
            </thead>
        )
    }
}

ProductVariantsHead.propTypes = {
    attributes: PropTypes.array.isRequired
}

export default ProductVariantsHead
