import React, {Component} from 'react'
import ResourcePage from './ResourcePage'
import getEnv from '../../../../helpers/common/getEnv'

class ResourceContainer extends Component {
    render() {
        const isSupportMpMode = getEnv('isEnableMpMode')
        if (isSupportMpMode) return null

        return (
            <div className='StoreFeatureTagsContainer'>
                <div className='card'>
                    <div className='card-header font-weight-bold'>Resources</div>

                    <div className='card-body'>
                        <ResourcePage storeDetails={this.props.storeDetails}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResourceContainer
