import React from 'react'
import AmazonItem from './AmazonItem'

const AmazonContainer = () => {
    return (
        <div className="AmazonContainer">
            <AmazonItem/>
        </div>
    )
}

export default AmazonContainer
