import createAPIServices from "./createApiServices"

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/billing' : 'https://admin-staging.merchize.com/api/billing'
const api = createAPIServices({baseUrl})
const apiHasResponseType = createAPIServices({baseUrl, responseType: 'blob'})


export const getDetailReportAccounting = (storeID, date, params) => {
    return api.makeAuthRequest({
        url: `/report/change-detail-of-store/data`,
        method: 'POST',
        data: {
            storeID: storeID,
            date: date,
            page: params.page,
            pages: params.pages,
            limit: params.limit
        }
    })
}

export const exportDetailReportAccounting = (storeID, date) => {
    return apiHasResponseType.makeAuthRequest({
        url: `/report/change-detail-of-store/export`,
        method: 'POST',
        data: {
            storeID,
            date
        }
    })
}

export const getBalanceReportAccounting = (params) => {
    return api.makeAuthRequest({
        url: `/report/summary-all-store/data`,
        method: 'POST',
        data: {
            date: params.formDate,
            limit: params.limit,
            page: params.page,
        }
    })
}

export const exportBalanceReportAccounting = (date) => {
    return apiHasResponseType.makeAuthRequest({
        url: `/report/summary-all-store/export`,
        method: 'POST',
        data: {
            date
        }
    })
}

export const getSharedEmail = () => {
    return api.makeAuthRequest({
        url: '/accounting-reports/email',
        method: 'GET'
    })
}

export const exportAccountingReportSummary = (payload) => {
    const url = '/accounting-reports/summary/stores'

    return api.makeAuthRequest({
        url,
        method: 'POST',
        data: payload
    })
}

export const exportAccountingReportStore = (storeID, payload) => {
    const url = `/accounting-reports/detail/stores/${storeID}`

    return api.makeAuthRequest({
        url,
        method: 'POST',
        data: payload
    })
}

export const exportDuplicateDeposits = (payload) => {
    const url = '/accounting-reports/duplicated-deposits'

    return api.makeAuthRequest({
        url,
        method: 'POST',
        data: payload
    })
}

export const exportTransactionOut = (payload) => {
    const url = '/accounting-reports/transaction-outs'

    return api.makeAuthRequest({
        url,
        method: 'POST',
        data: payload
    })
}

export const exportDailyProfit = (payload) => {
    const url = '/accounting-reports/daily-profit'

    return api.makeAuthRequest({
        url,
        method: 'POST',
        data: payload
    })
}

export const exportOrderProfit = (payload) => {
    const url = '/accounting-reports/order-profit'

    return api.makeAuthRequest({
        url,
        method: 'POST',
        data: payload
    })
}

export const exportPackageProfit = (payload) => {
    const url = '/accounting-reports/package-profit'

    return api.makeAuthRequest({
        url,
        method: 'POST',
        data: payload
    })
}

export const getListDailyTopup = (params) => {
    return api.makeAuthRequest({
        url: `daily-topup-report-jobs`,
        method: 'GET',
        params
    })
}

export const forceRetryDailyTopupJob = (id) => {
    return api.makeAuthRequest({
        url: `daily-topup-report-jobs/${id}/force-retry`,
        method: 'PUT',
    })
}