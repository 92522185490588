import React, {Component} from 'react'
import PropTypes from 'prop-types'
import RenderTableRow from './RenderTableRow'
import TableLoading from '../../shared/TableLoading'


class TableBody extends Component {
    render() {
        const {items, loading} = this.props
        return (
            loading 
                ? <TableLoading  columnQuantity={3}> </TableLoading> :
                <tbody className='TableBody'>
                    {
                        items.map((item) => (
                            <RenderTableRow key={item._id} item={item} />
                        ))
                    }
                </tbody>
        
        )
    }
}

TableBody.propTypes = {
    items: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default TableBody
