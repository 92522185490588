import React, {Component} from 'react'
import StoreWithdrawalsRow from './StoreWithdrawalsRow'
import {searchWithrawals} from './../../../../services/api/BillingServices'
import PropTypes from 'prop-types'
import PFPagePagination from '../../../shared/PFPagePagination'

class StoreWithdrawalsTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            withdrawals: null,
            pages: 1,
            filters: {
                page: 1,
                limit: 10,
            },
            error: '',
        }
    }

    componentDidMount() {
        this._fetchListWithdrawals()
    }

    _fetchListWithdrawals = async () => {
        try {
            const {filters} = this.state
            const {page, limit} = filters

            const params = {
                page,
                limit,
            }

            const {data, success, message} = await searchWithrawals(params)

            if (!success) {
                this.setState({
                    error: message,
                })
                return
            }

            const {withdrawals, pages} = data

            this.setState({
                withdrawals,
                pages,
                error: '',
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })
        }
    }

    _handleChangePage = (page) => {
        this.setState(({filters}) => {
            return {
                filters: {
                    ...filters,
                    page,
                },
            }
        }, this._fetchListWithdrawals)
    }

    render() {
        const {withdrawals, filters, pages, error} = this.state
        const {page} = filters

        return (
            <div className="WithdrawalsTableContainer">
                {(error !== '') && (
                    <div className="alert alert-danger fade show">{error}</div>
                )}
                <table className="table table-hover">
                    <thead className="thead-light">
                    <tr>
                        <th className="w-15">Created</th>
                        <th className="w-15">Account</th>
                        <th className="w-5 text-right">Amount</th>
                        <th className='w-10 text-center'>Status</th>
                        <th className='w-10'>Gateway</th>
                        <th className='w-15'>Transaction Id</th>
                        <th>Note</th>
                        <th className='Actions text-center'>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        Array.isArray(withdrawals) &&
                        <>
                            {
                                withdrawals.length === 0 ?
                                    <tr>
                                        <td colSpan={10}><em>No results</em></td>
                                    </tr>
                                    :
                                    withdrawals.map(withrawal => {
                                        return <StoreWithdrawalsRow
                                            key={withrawal._id}
                                            store={this.props.store}
                                            withrawal={withrawal}
                                            onFetchWithdrawals={this._fetchListWithdrawals}
                                        />
                                    })
                            }
                        </>
                    }
                    </tbody>
                </table>

                <PFPagePagination page={page} pages={pages} onChangePage={this._handleChangePage}/>
            </div>
        )
    }

}

StoreWithdrawalsTable.propTypes = {
    store: PropTypes.object.isRequired,
}

export default StoreWithdrawalsTable
