import React, {Component} from 'react'
import ArtworkItem from "./ArtworkItem"
import PropTypes from 'prop-types'
import Classname from "classnames";

class ArtworksListBySide extends Component {
    state = {
        toggle: false
    }

    _onChangeToggle = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    }

    render() {
        const {groupArtworks, slug, artwork_separated_by} = this.props

        if (groupArtworks === undefined || groupArtworks.length === 0) return null

        const groupArtworksSlug = Array.isArray(groupArtworks) && groupArtworks.filter(group => group.attribute === slug)

        const listArtworks = artwork_separated_by ? groupArtworksSlug : groupArtworks

        return (
            <div className="ArtworksListBySide">
                <div className={Classname("groupAccodion", (!this.state.toggle && groupArtworks.length > 1) && 'shortInfo')}>
                    {
                        listArtworks.map((artwork, index) => {
                            return (
                                <div className='bg-light rounded p-3 text-center'>
                                    <ArtworkItem key={`ArtworksListBySide_${index}`} artwork={artwork}/>
                                </div>
                            )
                        })
                    }
                </div>
                {(!this.state.toggle && listArtworks.length > 1) && (
                    <u className="cursor-pointer mt-2" onClick={this._onChangeToggle}>view more</u>
                )}
            </div>
        )
    }
}

ArtworksListBySide.propTypes = {
    groupArtworks: PropTypes.array.isRequired,
}

export default ArtworksListBySide
