import React, { useState } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { bulkMarkAsRead } from "../../../services/api/TrademarkSevices"
import { toaster } from "../../shared/PFToast"

const ConfirmRead = (props) => {
    const [loading, setLoading] = useState(false)

    const { open, toggleModal, ids, onAfterBulkAction } = props
    const onSubmit = async () => {
        try {
            setLoading(true)
            const { success, message } = await bulkMarkAsRead({ orderItemIds: ids, enable: true })
            if (success) {
                toaster({
                    type: 'success',
                    message: `Mark ${ids.length > 0 ? "product" : "products"} as read successfully!`
                })
                onAfterBulkAction()
                toggleModal()
            } else {
                toaster({
                    type: 'error',
                    message
                })
            }
            setLoading(false)
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    return (
        <Modal isOpen={open} toggle={toggleModal} centered >
            <ModalHeader toggle={toggleModal}>Mark as read</ModalHeader>
            <ModalBody>
                Are you sure you want mark {ids && ids.length > 1 ? "these products" : "this product"} as read?
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggleModal} disabled={loading}>
                    Cancel
                </Button>
                <Button color="primary" disabled={loading} onClick={onSubmit}>
                    {
                        loading && <i className="fas fa-circle-notch fa-spin mr-2"></i>
                    }
                    Mark as read
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmRead