import React, {Component} from 'react'
import DocTitleByStore from '../../../dashboard/shared/DocTitleByStore'
import ShippingProfilesEditPage from './ShippingProfilesEditPage'
import {isAdmin, isSuperAdmin} from '../../../../services/AuthServices'

class ShippingProfilesEditContainer extends Component {

    render() {
        const profilesId = this.props.match.params.id

        const isSupperAdmin = isSuperAdmin()
        const roleAdmin = isAdmin()

        if (isSupperAdmin || roleAdmin) return (
            <div className="SettingPage ProfilesEditContainer">
                <DocTitleByStore title="Shipping Profiles Edit"/>
                <div className="row">
                    <div className="col-md-8 col-12 m-auto ">

                        <ShippingProfilesEditPage profilesId={profilesId}/>
                    </div>
                </div>
            </div>
        )

        return null
    }
}

export default ShippingProfilesEditContainer
