import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toaster } from '../../shared/PFToast'
import { cashbackCalculate, getListCashbackRules } from '../../../services/api/BillingServices'
import CashbackRuleTable from '../tables/CashbackRuleTable'
import PFPagePagination from '../../shared/PFPagePagination'
import { deleteEmptyObjectkeys, trimObjectValues } from '../../../helpers/common/cleanObject'
import { debounce } from 'lodash'
import { confirmModal } from '../../shared/PFConfirmModalV2'

const CashbackRuleContainer = () => {
    const [loading, setLoading] = useState(false)
    const [loadingCalculate, setLoadingCalculate] = useState(false)
    const [pages, setPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [storeFilter, setStoreFilter] = useState('store_slug')
    const [query, setQuery] = useState({
        page: 1,
        limit: 10,
        store_slug: '',
        store_domain: '',
        product_type: '',
        status: 'active',
        sort: -1,
    })
    const [cashbackRules, setCashbackRules] = useState([])

    const fetchCashbackRules = useCallback(async () => {
        try {
            setLoading(true)
            const params = trimObjectValues(query)
            const { success, data, message } = await getListCashbackRules(deleteEmptyObjectkeys(params))
            setLoading(false)
            if (!success) {
                throw new Error(message)
            }
            setPages(data.pages)
            setTotal(data.total)
            setCashbackRules(data.cashback_rules)
        } catch (error) {
            setLoading(false)
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }, [query])

    useEffect(() => {
        fetchCashbackRules()
    }, [fetchCashbackRules])

    const debouncedSetQuery = debounce((name, value) => {
        setQuery((prev) => ({
            ...prev,
            page: 1,
            [name]: value ? value.trim() : '',
        }))
    }, 500)

    const onChangeSearch = (e) => {
        const { name, value } = e.target
        debouncedSetQuery(name, value)
    }

    const onChangeSelect = (e) => {
        const { name, value } = e.target
        setQuery({
            ...query,
            page: 1,
            [name]: value,
        })
    }

    const handlePageChange = (page) => {
        setQuery({
            ...query,
            page,
        })
    }

    const generateStringNumber = (number) => {
        return number && number < 10 ? `0${number}` : number
    }

    const generateCalculationId = () => {
        const now = new Date()
        const year = now.getFullYear()
        const month = generateStringNumber(now.getMonth() + 1)
        const date = generateStringNumber(now.getDate())
        const hour = generateStringNumber(now.getHours())
        const minute = generateStringNumber(now.getMinutes())
        return `CB-${date}${month}${year}-${hour}${minute}`
    }

    const onConfirmCalculate = () => {
        confirmModal({
            confirmText: 'Confirm',
            confirmColor: 'primary',
            title: 'Confirm calculate cashback',
            message: 'Are you sure to calculate cashback for active rules? This action will not be unrollable.',
            onOk: onCalculateCashback
        })
    }

    const onCalculateCashback = async () => {
        try {
            setLoadingCalculate(true)
            const payload = {
                calculation_id: generateCalculationId(),
            }
            const { success, message } = await cashbackCalculate(payload)
            setLoadingCalculate(false)
            if (!success) {
                throw new Error(message)
            }
            toaster({
                type: 'success',
                message: 'The calculation is running in background, please wait until the calculation is done.',
            })
        } catch (error) {
            setLoadingCalculate(false)
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    const onChangeFilterStore = (event) => {
        const { value } = event.target
        setStoreFilter(value)
        setQuery({
            ...query,
            store_slug: '',
            store_domain: '',
        })
    }

    return (
        <div className="CashbackRuleContainer">
            <div className="CashbackRule">
                <div className="d-flex justify-content-between align-items-center">
                    <h2>Cashback Rule</h2>
                    <div className="list-btn">
                        <button
                            type="button"
                            className="btn btn-default mr-3"
                            onClick={onConfirmCalculate}
                            disabled={loadingCalculate}
                        >
                            {loadingCalculate && <i className="fas fa-circle-notch fa-spin mr-2"></i>} Cashback
                            Calculator
                        </button>
                        <Link to="/a/cashback-rule-history" className="btn btn-default mr-3">
                            History cashback
                        </Link>
                        <Link to="/a/cashback-recall" className="btn btn-success mr-3">
                            Recall
                        </Link>
                        <Link to="/a/form-cashback-rule" className="btn btn-primary">
                            Create new rule
                        </Link>
                    </div>
                </div>

                <div className="SectionInner">
                    <div className="d-flex justify-content-between mb-3">
                        <div className="TableFilter d-flex flex-wrap">
                            <div className="TableLength mr-3">
                                Show
                                <select className="ml-2" name="limit" value={query.limit} onChange={onChangeSelect}>
                                    <option value="10">10 rows</option>
                                    <option value="20">20 rows</option>
                                    <option value="50">50 rows</option>
                                    <option value="100">100 rows</option>
                                </select>
                            </div>
                            <div className="form-group SearchInput d-flex align-items-center mr-3">
                                <label className="m-0 mr-2">Store</label>
                                <div className="input-group-prepend">
                                    <select
                                        className="form-control"
                                        style={{ minWidth: '120px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                        value={storeFilter}
                                        onChange={onChangeFilterStore}
                                    >
                                        <option value="store_slug">Store slug</option>
                                        <option value="store_domain">Domain</option>
                                    </select>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    name={storeFilter}
                                    value={query[storeFilter]}
                                    style={{ minWidth: '120px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                    placeholder="Enter search store..."
                                    onChange={onChangeSearch}
                                />
                            </div>
                            <div
                                className="form-group SearchInput d-flex align-items-center mr-3"
                                style={{ minWidth: '360px' }}
                            >
                                <label className="m-0 mr-2" style={{ minWidth: '100px' }}>
                                    Product type
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="product_type"
                                    name="product_type"
                                    placeholder="Enter search product type..."
                                    onChange={onChangeSearch}
                                />
                            </div>
                            <div className="TableLength">
                                Status
                                <select className="ml-2" name="status" value={query.status} onChange={onChangeSelect}>
                                    <option value="">All</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                </select>
                            </div>
                        </div>
                        <strong className="Entity" style={{ minWidth: '70px' }}>
                            {total} items
                        </strong>
                    </div>

                    <CashbackRuleTable
                        cashbackRules={cashbackRules}
                        setCashbackRules={setCashbackRules}
                        loading={loading}
                    />
                    <PFPagePagination page={query.page} pages={pages} onChangePage={handlePageChange} />
                </div>
            </div>
        </div>
    )
}

export default CashbackRuleContainer
