import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ClusterSettingKey extends Component {
    render() {
        return (
            <td className="ClusterSettingKey">
                {this.props.settingKey}
            </td>
        )
    }
}

ClusterSettingKey.propTypes = {
    settingKey: PropTypes.string.isRequired,
}

export default ClusterSettingKey
