import createApiServices from './createApiServices'

const createAPIRequest = () => {
    const currentStore = Object.assign({}, window.currentStore)
    if (!currentStore) throw new Error(`Can't get current store`)
    const { backoffice_api_url } = currentStore
    const baseUrl = backoffice_api_url + '/product'
    return createApiServices({ baseUrl })
}


export const getPendingProducts = (payload) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/products/review-search',
        method: 'POST',
        data: payload,
    })
}

export const approvePendingReviewProduct = (productId) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/approve-new`,
        method: 'POST',
    })
}

export const rejectPendingReviewProduct = (productId, reason) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/reject-new`,
        method: 'POST',
        data: {
            reason,
        },
    })
}

export const bulkApproveProducts = (ids) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/products/bulk/approve-new',
        method: 'POST',
        data: {
            productIds: ids,
        },
    })
}

export const bulkRejectProducts = (ids, reason) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/products/bulk/reject-new',
        method: 'POST',
        data: {
            productIds: ids,
            reason,
        },
    })
}

export const getProduct = (id) => {
    const api = createAPIRequest(window.currentStore)
    return api.makeAuthRequest({
        url: `/products/${id}`,
        method: 'GET',
    })
}

export const makeProductsActive = (ids, filter) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/products/activate',
        method: 'POST',
        data: {
            ids, filter,
        },
    })
}

export const makeProductsDeactive = (ids, filter) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/products/deactivate',
        method: 'POST',
        data: {
            ids, filter,
        },
    })
}

export const getProductGallery = (productId) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/gallery`,
        method: 'GET',
    })
}

export const sortProductGallery = (productId, newArrGallery) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/images`,
        method: 'POST',
        data: newArrGallery,
    })
}

export const createProductGallery = (productId, image) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/gallery`,
        method: 'POST',
        data: image
    })
}

export const deleteProductGallery = (productId, imageId) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/gallery/${imageId}`,
        method: 'DELETE',
    })
}

export const setArtworkSeparatedAttribute = (productId, attributeId) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/artwork-separated-by`,
        method: 'POST',
        data: {
            attribute: attributeId,
        },
    })
}

export const changeVariantImageSides = (id, data) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/variants/${id}/sides`,
        method: 'POST',
        data: data,
    })
}

export const getVariantImages = (id) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/variants/${id}/images`,
        method: 'GET',
    })
}

export const uploadVariantImage = (id, data) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/variants/${id}/image`,
        method: 'POST',
        data: data,
    })
}

export const removeVariantImageSide = (variantId, side) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/variants/${variantId}/side/${side}`,
        method: 'DELETE',
    })
}

export const updateDefaultVariant = (productId, variantId) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/default-variants`,
        method: 'POST',
        data: {
            variantId,
        },
    })
}

export const getAllProducts = (params = {}) => {
    const api = createAPIRequest()
    const defaultParams = {
        limit: 20,
        page: 1,
    }

    const newParams = { ...defaultParams, ...params }

    return api.makeAuthRequest({
        url: '/products',
        method: 'GET',
        params: newParams,
    })
}

export const deleteProduct = (id) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${id}/delete`,
        method: 'POST',
    })
}

export const saveCollectionByName = (name) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/collections',
        method: 'POST',
        data: {
            name,
        },
    })
}
export const searchCollectionsByName = (name) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: '/collections',
        method: 'GET',
        params: {
            name,
        },
    })
}

export const saveProductGeneralEdit = (_id, details) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${_id}`,
        method: 'POST',
        data: {
            ...details,
        },
    })
}