import AmazonMenu from '../../../statics/assets/icons/amazon-menu.svg'
import IntergrationIcon from '../../../statics/assets/icons/intergration.svg'
import IntergrationActiveIcon from '../../../statics/assets/icons/intergration-active.svg'
import { hasRole, isAdmin } from '../../../services/AuthServices'

const SHIPMENT_REPORT = isAdmin() || hasRole('operator') ? [
    {
        title: 'Overdue Shipment Report',
        fa: 'fas fa-ship',
        href: '/a/shipment-report'
    },
] : []

const MAPPING_RULES = isAdmin() || hasRole('operator') ? [
    {
        title: 'Mapping Rule',
        fa: 'fa fa-book',
        href: '/a/mapping-rules'
    },
] : []

export const ACCOUNTING = [
    {
        title: 'All Store Report',
        fa: 'ti-bar-chart-alt',
        href: '/a/accounting-report/all-store-report',
    },
    {
        title: 'Detailed Report',
        fa: 'ti-bar-chart-alt',
        href: '/a/accounting-report/detailed-report',
    },
    {
        title: 'Daily Profit Report',
        fa: 'ti-bar-chart-alt',
        href: '/a/accounting-report/daily-profit-report',
    },
    {
        title: 'Daily Topup Report',
        fa: 'ti-bar-chart-alt',
        href: '/a/accounting-report/daily-topup-report',
    }
]

const order =[{
    title: 'Refund Order',
    fa: 'ti-money',
    href: '/a/order/refund-order',
}]

const amazon = [
    {
        title: 'Amazon Templates',
        href: '/a/amazon',
        icon: AmazonMenu
    }
]

export const NAVBAR_MENU = [
    // {
    //     title: 'Analytics',
    //     fa: 'far fa-chart-bar',
    //     href: '/a/analytics',
    // },
    {
        title: 'Stores',
        fa: 'ti-dashboard',
        href: '/a/stores',
    },
    ...MAPPING_RULES,
    {
        title: 'Clusters',
        fa: 'fas fa-cubes',
        href: '/a/clusters',
    },
    {
        title: 'Groups',
        fa: 'fas fa-list',
        href: '/a/groups',
    },
    {
        title: 'Resources',
        fa: 'fa fa-cogs',
        href: '/a/resource-settings',
    },
    {
        title: 'CI/CD',
        fa: 'fa fa-paper-plane',
        href: '/a/jobs',
    },
    {
        title: 'Accounts',
        fa: 'far fa-user',
        href: '/a/accounts',
    },
    {
        title: 'Plan',
        fa: 'fas fa-money-check-alt',
        href: '/a/pricing',
    },
    {
        title: 'Cashback Rule',
        fa: 'fa fa-book',
        href: '/a/cashback-rule'
    },
    {
        title: 'Catalog',
        fa: 'fas fa-archive',
        href: '/a/catalog',
    },
    {
        title: 'Catalog Category',
        fa: 'fas fa-tag',
        href: '/a/catalog-categories',
    },
    {
        title: 'Product Mockup',
        fa: 'fas fa-clone',
        href: '/a/product-mockup',
    },
    {
        title: 'Product Line',
        fa: 'fas fa-box',
        href: '/a/product-lines',
    },
    {
        title: 'Category',
        fa: 'fas fa-boxes',
        href: '/a/categories',
    },
    ...SHIPMENT_REPORT,
    {
        title: 'Integration Config',
        fa: '',
        href: '/a/amazon',
        sub_menu: amazon,
        icon: IntergrationIcon,
        active_icon: IntergrationActiveIcon
    },
    {
        title: 'Accounting Report',
        fa: 'fas fa-chart-pie',
        href: '/a/accounting-report/all-store-report',
        sub_menu: ACCOUNTING,
        // sub_menu: ACCOUNTING.filter(Boolean),
    },
    {
        title: 'Global deposits',
        fa: 'fas fa-money-check-alt',
        href: '/a/global-deposits',
    },
    // {
    //     title: 'Product Approval',
    //     fa: 'fas fa-tasks',
    //     href: '/a/product-approval',
    // },
    {
        title: 'Trademark Order',
        fa: 'fas fa-trademark',
        href: '/a/trademark',
    },
    {
        title: 'Order',
        fa: 'far fa-list-alt',
        href: '/a/order/refund-order',
        sub_menu: order,
        // sub_menu: order.filter(Boolean),
    },
    {
        title: 'Contest Report',
        fa: 'fas fa-chart-area',
        href: '/a/contest-report',
    },
    {
        title: 'Payout Approval',
        fa: 'fab fa-paypal',
        href: '/a/payout-approval',
    },
    {
        title: 'Database Jobs',
        fa: 'fas fa-database',
        href: '/a/database-jobs',
    },
]

export const NAVBAR_MENU_MP = [
    {
        title: 'Stores',
        fa: 'ti-dashboard',
        href: '/a/stores',
    },
    {
        title: 'Accounts',
        fa: 'far fa-user',
        href: '/a/accounts',
    },
    {
        title: 'Product Mockup',
        fa: 'fas fa-clone',
        href: '/a/product-mockup',
    },

    {
        title: 'Product Line',
        fa: 'fas fa-box',
        href: '/a/product-lines',
    },
    {
        title: 'Affiliate Category',
        fa: 'fas fa-globe',
        href: '/a/affiliate-category',
    },

    {
        title: 'Category',
        fa: 'fas fa-boxes',
        href: '/a/categories',
    },
    {
        title: 'Product Approval',
        fa: 'fas fa-tasks',
        href: '/a/product-approval',
    },
    {
        title: 'Database Jobs',
        fa: 'fas fa-database',
        href: '/a/database-jobs',
    },
]
