import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ClusterIndex extends Component {
    state = {
        index: this.props.index,
    }

    _handleChangeInput = e => {
        const {name,value} = e.target
        const {onUpdateCluster} = this.props

        this.setState({
            index: value,
        }, onUpdateCluster(name, value))
    }

    render() {
        const {index} = this.state
        
        return (
            <div className="ClusterIndex form-group">
                <label htmlFor="clusterIndex">Index</label>
                <input type="text" className="form-control" id="clusterIndex" name="index" value={index}
                       onChange={this._handleChangeInput}/>
            </div>
        )
    }
}

ClusterIndex.propTypes = {
    
    onUpdateCluster: PropTypes.func.isRequired,
}

export default ClusterIndex
