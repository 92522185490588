import React, {Component} from 'react'
import {uploadArtwork} from "../../../../../services/api/ArtworkServices"
import PubSub from '../../../helper/ProductEditBroker'
import PropTypes from 'prop-types'
import {toaster} from "../../../../shared/PFToast"

class ArtworkUploadSide extends Component {
    state = {
        loading: false,
    }

    _handleChangeInput = e => {
        const {files} = e.target
        const file = files[0]

        const {side} = this.props

        const formData = new FormData()
        formData.append(side, file)

        this._handleUploadArtwork(formData)
    }

    _handleUploadArtwork = async (formData) => {
        try {
            this.setState({
                loading: true,
            })

            const {productId} = this.props
            const {success, message} = await uploadArtwork(productId, formData)

            if (!success) {
                this.setState({
                    loading: false,
                })
                window.alert(message)
                return
            }

            this.setState({
                loading: false,
            })

            toaster({
                type: 'warning',
                message: <span><b>Notice:</b> Changing artwork of this product will only reflect on future orders which hasn't been fulfilled yet.</span>,
                duration: 5000
            })

            PubSub.publish("PRODUCT_EDIT_RELOAD_ARTWORKS")

        } catch (e) {
            this.setState({
                loading: false,
            })

            window.alert(e.message)
        }
    }

    render() {
        const {side} = this.props
        const {loading} = this.state

        return (
            <div className="ArtworkUploadSide">
                <div className="UploadInput">
                    <input disabled type="file" name={side} accept="image/*" id={`uploadInput_${side}`}
                           onChange={this._handleChangeInput}/>
                    <label htmlFor={`uploadInput_${side}`}
                           className="UploadLabel mb-0 d-block cursor-pointer bg-info text-white text-monospace rounded py-2 fs-14">
                        <span className="mr-3"><i className="fas fa-cloud-upload-alt"/></span>
                        {
                            loading ?
                                <span>Uploading <span className="Updating">
                                            <span>.</span>
                                            <span>.</span>
                                            <span>.</span>
                                        </span></span>
                                :
                                <>Upload <u className="font-weight-bold">{side}</u></>
                        }
                    </label>
                </div>
            </div>
        )
    }
}

ArtworkUploadSide.propTypes = {
    side: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
}

export default ArtworkUploadSide
