import React, {Component} from 'react'
import {addNewFeatureTags} from '../../../../services/api/StoreServices'
import PropTypes from 'prop-types'

class InputFeatureTags extends Component {
    state = {
        error: '',
        loading: false,
        tag: '',
    }

    _handleChangeInput = e => {
        const {value} = e.target

        this.setState({
            tag: value,
        })
    }

    _handleKeyPressInput = e => {
        if (44 === e.which) {
            e.preventDefault()

            this._reqAddFeatureTags()
        }
    }

    _handleBlurInput = () => {
        this._reqAddFeatureTags()
    }

    _handleSubmitForm = e => {
        e.preventDefault()

        this._reqAddFeatureTags()
    }

    _reqAddFeatureTags = async () => {
        try {
            const isValid = this._validateTagInput()

            if (!isValid) {
                return
            }

            this.setState({
                loading: true,
            })

            const {storeId, onAddFeature} = this.props

            const {success, message} = await addNewFeatureTags(storeId, [this.state.tag])

            if (!success) {
                throw new Error(message)
            }

            onAddFeature(this.state.tag)

            this.setState({
                tag: '',
                error: '',
                loading: false,
            })
        } catch (e) {
            this.setState({
                error: e.message,
                loading: false,
            })
        }
    }

    _validateTagInput = () => {
        const vTag = this.state.tag.trim()

        if ('' === vTag) {
            return false
        }

        const {features} = this.props
        const found = features.find(feature => feature === vTag)

        if (undefined !== found) {
            this.setState({
                error: `You have already used "${vTag}"`,
            })

            return false
        }

        return true
    }

    render() {
        const {tag, error} = this.state

        return (
            <div className='InputFeatureTags'>
                <form onSubmit={this._handleSubmitForm}>
                    <input type='text' className='form-control' value={tag} onChange={this._handleChangeInput}
                           onKeyPress={this._handleKeyPressInput} onBlur={this._handleBlurInput} autoComplete='off'
                           placeholder='Add a tag...'/>
                </form>

                {
                    error &&
                    <div className='text-danger mt-3'><span className='font-weight-bold'>ERROR:</span> {error}</div>
                }
            </div>
        )
    }
}

InputFeatureTags.propTypes = {
    storeId: PropTypes.string.isRequired,
    onAddFeature: PropTypes.func.isRequired,
}

export default InputFeatureTags
