import React, {Component} from 'react'
import ProductAttributeNewValue from './ProductAttributeItem/ProductAttributeNewValue'
import PropTypes from 'prop-types'
import SettingCheckbox from '../../../setting/shared/SettingCheckbox'
import {setAttributeProductLine, setAttributeProductLineStoreFront} from '../../../../services/api/ProductLinesServices'
import {toaster} from '../../../shared/PFToast'

class ProductAttributeItem extends Component {
    state = {
        toggleNewValue: false,
        is_active: this.props.attribute.is_active,
        hide_attribute_storefront: this.props.attribute.hide_attribute_storefront || false
    }

    _handleClickListValues = e => {
        this.setState({
            toggleNewValue: !this.state.toggleNewValue
        })
    }

    _handleRemoveAttributeValue = (e) => {
        const removeValue = e.target.getAttribute('data-value')
        const {attribute, onChangeAttributesState, attributeIndex} = this.props
        const {values} = attribute
        const newValues = values.filter(value => value.value !== removeValue)

        onChangeAttributesState(attributeIndex, 'values', newValues)
    }

    _handleRemoveAttribute = () => {
        const {attributeIndex, onRemoveAttribute} = this.props
        onRemoveAttribute(attributeIndex)
    }

    _detectColorAtt = () => {
        const attribute = Object.assign({}, this.props.attribute)

        const {name = ''} = attribute

        const nameFormatted = name.trim().toLowerCase()

        return nameFormatted === 'color'
    }

    _changeSettings = (key, id, index) => value => {

        const {onChangeAttributesState} = this.props

        this.setState({
            [key]: value
        }, () => this._changeAttribute(key, value, id), onChangeAttributesState(index, key, value))
    }

    _changeAttribute = async (key, value, id) => {
        try {

            const {
                success,
                message
            } = (key === 'hide_attribute_storefront' ? await setAttributeProductLineStoreFront(value, id) : await setAttributeProductLine(value, id))

            if (!success) throw new Error(message)

            toaster({message: 'Change attribute successfully!', type: 'success', duration: 5000})

        } catch (e) {
            toaster({message: e.message, type: 'error', duration: 5000})
        }
    }

    render() {
        const {toggleNewValue, is_active, hide_attribute_storefront} = this.state
        const {attribute, onChangeAttributesState, attributeIndex, isRemoveAttributes} = this.props
        const {name: attName, value_type, values, _id} = attribute
        const isRemoveValues = Array.isArray(values) && values.length > 1

        return (
            <li className="ProductAttributeItem mb-4">
                <div className="card">
                    <div className="card-header d-flex">
                        <h6 className="mb-0 font-weight-bold">{attName || ''}</h6>

                        <div className="ml-auto d-flex align-items-center">
                            <SettingCheckbox
                                label="Active attribute"
                                itemId={`attribute_enabled_${_id}`}
                                checked={is_active}
                                onChange={this._changeSettings('is_active', _id, attributeIndex)}
                            />
                            <div className="mr-3" />

                            {value_type === 'Color' && (
                                <SettingCheckbox
                                    label="Hide attribute storefront"
                                    itemId={`hide_attribute_storefront${_id}`}
                                    checked={hide_attribute_storefront}
                                    onChange={this._changeSettings('hide_attribute_storefront', _id, attributeIndex)}
                                />
                            )}

                            {isRemoveAttributes && (
                                <div className="ml-3">
                                    <i className="fas fa-caret-down" />
                                    <span
                                        className="text-danger ml-3 cursor-pointer"
                                        onClick={this._handleRemoveAttribute}
                                    >
                                        Remove
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-3">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    value={attName || ''}
                                    onChange={({target: {value}}) =>
                                        onChangeAttributesState(attributeIndex, 'name', value)
                                    }
                                />
                            </div>

                            <div className="col-3">
                                <label>Type</label>
                                <select
                                    className="form-control"
                                    value={value_type || ''}
                                    placeholder="Type"
                                    onChange={({target: {value}}) =>
                                        onChangeAttributesState(attributeIndex, 'value_type', value)
                                    }
                                >
                                    <option value="" disabled>
                                        --Choose Type--
                                    </option>
                                    <option value="Size">Size</option>
                                    <option value="Color">Color</option>
                                    <option value="Label">Label</option>
                                    <option value="Pack">Pack</option>
                                </select>
                            </div>

                            <div className="col-6">
                                <label>Values</label>
                                <div className="AttributeValues">
                                    <div className="border rounded p-3">
                                        <ul className="ListAttributes p-0">
                                            {values.map((value, index) => {
                                                return (
                                                    <li
                                                        className="AttributeValue d-inline-block mr-2 mb-2 position-relative"
                                                        key={index}
                                                    >
                                                        <div className="Label badge badge-secondary p-2">
                                                            <div
                                                                id={`${attName.trim()}_${index}`}
                                                                className="d-inline-block"
                                                            >
                                                                <div className="cursor-pointer">
                                                                    <span className="Name pr-2">{value.name}</span>
                                                                    <span className="Value pl-2">{value.value}</span>
                                                                </div>
                                                            </div>
                                                            {isRemoveValues && (
                                                                <span className="ml-2 px-1 cursor-pointer IconRemove">
                                                                    <i
                                                                        className="fas fa-times"
                                                                        data-value={value.value}
                                                                        onClick={this._handleRemoveAttributeValue}
                                                                    />
                                                                </span>
                                                            )}
                                                        </div>
                                                        {/*
                                                            {
                                                                isColor &&
                                                                <ProductAttributeEditor
                                                                    target={`${attName.trim()}_${index}`}
                                                                    name={value.name} value={value.value}
                                                                    productLineId={this.props.productLineId}/>
                                                            }*/}
                                                    </li>
                                                )
                                            })}
                                        </ul>

                                        <small
                                            className="text-primary cursor-pointer"
                                            onClick={this._handleClickListValues}
                                        >
                                            Add more +
                                        </small>
                                    </div>
                                    <ul></ul>
                                </div>
                            </div>
                        </div>

                        {toggleNewValue && (
                            <ProductAttributeNewValue
                                onChangeAttributesState={onChangeAttributesState}
                                values={values}
                                attributeIndex={attributeIndex}
                                {...this.props}
                            />
                        )}
                    </div>
                </div>
            </li>
        )
    }
}

ProductAttributeItem.propTypes = {
    attribute: PropTypes.object.isRequired,
    onChangeAttributesState: PropTypes.func.isRequired,
    onRemoveAttribute: PropTypes.func.isRequired,
    attributeIndex: PropTypes.number.isRequired,
    productLineId: PropTypes.string.isRequired
}

export default ProductAttributeItem
