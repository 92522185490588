import React, {Component} from 'react'
import PropTypes from 'prop-types'
import NavLink from 'react-router-dom/NavLink'

class SubMenuItem extends Component {

    _renderIcon = (item) => {
        const {fa, icon = '', active_icon = ''} = item
        if (fa) {
            return (<i className={fa}/>)
        }
        return (
            <>
                {icon && <img className={icon && active_icon ? 'default-icon' : ''} src={icon} alt="icon"/>}
                {active_icon && <img className="active-icon" src={active_icon} alt="icon"/>}
            </>
        )
    }

    render() {
        const {item} = this.props
        const {title, href} = item

        return (
            <li className={`MenuItem ${title}`}>
                <NavLink to={href} activeClassName="active" className="nav-link">
                    <span className="IconHolder" data-tooltip={title}>{this._renderIcon(item)}</span>{title}
                </NavLink>
            </li>
        )
    }
}

SubMenuItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default SubMenuItem
