import React, {Component} from 'react'
import ProductActionTakedown from "./action/ProductActionTakedown"

import PropTypes from 'prop-types'

class ProductDisplayAction extends Component {
    render() {
        return (
            <ProductActionTakedown {...this.props}/>
        )
    }
}

ProductDisplayAction.propTypes = {
    productTitle: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired
}

export default ProductDisplayAction
