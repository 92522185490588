import React from 'react'
import TableHead from '../table/TableHead'
import TableBody from '../table/TableBody'

const GlobalDepositTable = (props) => {
    return (
        <div className="GlobalDepositTable table-responsive">
            <table className="table table-striped table-light">
                <TableHead />
                <TableBody {...props} />
            </table>
        </div>
    )
}

export default GlobalDepositTable

