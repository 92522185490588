import React, {useState} from 'react'

const TemplateFormContext = React.createContext({})
export const TemplateFormProvider = (props) => {
    const [_template, setTemplate] = useState()

    const _setTemplate = template => setTemplate(template)

    const value = {
        template: _template,
        setTemplate: _setTemplate
    }
    return (
        <TemplateFormContext.Provider value={value}>{props.children}</TemplateFormContext.Provider>
    )
}
export default TemplateFormContext
