import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {updateStoreThemes} from '../../../services/api/DeployService';

class ThemeModal extends Component {
    state = {
        theme_name: this.props.theme_name,
        loading: false,
        err: '',
    }
    _onToggle = () => {
        this.props.close()
        this.setState({
                err: '',
                theme_name: this.props.theme_name,
            },
        )
    }

    _handleChangeTheme = async (e) => {
        e.preventDefault()
        this.setState({
            loading: true,
            err: '',
        })

        try {
            const {storeDetails, changeStoreDetail} = this.props
            const {_id: storeId} = storeDetails
            const {theme_name} = this.state

            const {success, message} = await updateStoreThemes(storeId, theme_name)
            if (!success) throw new Error(message)
            this.setState({loading: false, err: ''})
            changeStoreDetail({
                ...storeDetails,
                theme_name,
            })
            const {close} = this.props
            return close()
        } catch (e) {
            this.setState({
                err: e.message,
                loading: false,
            })
        }
    }


    _handleTitleChange = event => {
        const value = event.target.value
        this.setState(
            {
                theme_name: value,
            },
        )
    }

    render() {
        const {openStore} = this.props
        const {theme_name, err, loading} = this.state
        return (
            <Modal isOpen={openStore} toggle={this._onToggle} className="ThemeModal">
                <ModalHeader toggle={this._onToggle}>Theme Store</ModalHeader>
                <ModalBody>
                    <div className="DomainModal">
                        <input
                            type="text"
                            className="form-control"
                            required
                            onChange={this._handleTitleChange}
                            value={theme_name || ''}
                        />
                        {err.length > 0 && (
                            <div className="mt-2 alert alert-danger">{err}</div>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-default" onClick={this._onToggle}>Cancel</button>
                    <button className="btn btn-primary" onClick={this._handleChangeTheme} disabled={loading}>
                        {
                            loading ? 'Saving...' : 'Save'
                        }
                    </button>
                </ModalFooter>
            </Modal>
        )
    }

}

ThemeModal.propTypes = {
    openStore: PropTypes.bool.isRequired,
    theme_name: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    changeStoreDetail: PropTypes.func.isRequired,
    storeDetails: PropTypes.object.isRequired,
}

export default ThemeModal
