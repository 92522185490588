import React from 'react'
import ColorMappingTableRow from './ColorMappingTableRow'

const ColorMappingTable = (props) => {
    const {colorAttribute, setColorAttribute} = props

    if (!colorAttribute) return null

    return (
        <div className="ColorMappingTable table-responsive">
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        <th>Color Name</th>
                        <th>Color Code</th>
                    </tr>
                </thead>
                <ColorMappingTableRow colorAttribute={colorAttribute} setColorAttribute={setColorAttribute} />
            </table>
        </div>
    )
}

export default ColorMappingTable

