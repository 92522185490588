import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

class StatusStyle extends Component {
    _mapStatus = () => {
        let {status} = this.props

        if (!status) {
            return null
        }

        let output = ''
        switch (status) {
            case 'partial':
                output = <span className="badge badge-info badge-pill">{status}</span>
                break
            case 'rejected':
            case 'revoked':
            case 'cancelled':
            case 'error':
            case 'failed':
            case 'suspended':
            case 'refunded':
            case 'deleted':
                output = <span className="badge badge-danger badge-pill">{status}</span>
                break
            case 'fulfilled':
            case 'active':
            case 'done':
            case 'approved':
            case 'recalled':
                output = <span className="badge badge-success badge-pill">{status}</span>
                break
            case 'unfulfilled':
            case 'no_recall':
                if (status === 'no_recall') status = 'no recall'
                output = <span className="badge badge-warning badge-pill">{status}</span>
                break
            case 'pending':
                output = <span className="badge badge-info pending-animation badge-pill">{status}</span>
                break
            case 'paid':
            case 'inactive':
                output = <span className="badge badge-secondary badge-pill">{status}</span>
                break
            case 'processing':
            case 'uploading':
                output = <span className="badge badge-primary badge-pill processing-animation">{status}</span>
                break
            default:
                output = status
        }

        return output
    }

    render() {
        const status = this._mapStatus()

        return <Fragment>{status}</Fragment>
    }
}

StatusStyle.propTypes = {
    status: PropTypes.string.isRequired,
}

export default StatusStyle
