import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import TemplateForm from '../../components/TemplateForm'
import PropTypes from 'prop-types'
import {editTemplate} from '../../../../services/api/SellerServices'

const EditAmazonPage = (props) => {
    const [template, setTemplate] = useState(null)

    const getTemplate = async (templateId) => {
        const {data, success} = await editTemplate(templateId)
        if (success) {
            setTemplate(data)
        }
    }

    useEffect(() => {
        if (props.templateId) {
            getTemplate(props.templateId)
        }
    }, [])

    return (
        <div className="EditAmazonPage">
            <Link className="nav-link BackButton pl-0" to="/a/amazon">
                <i className="fas fa-chevron-left"/>
                <span> Amazon Template</span>
            </Link>
            <h1 className="PageTitle">Edit Amazon Templates</h1>
            <div>
                {
                    template && <TemplateForm template={template} templateId={props.templateId}/>
                }
            </div>
        </div>
    )
}

EditAmazonPage.propTypes = {
    templateId: PropTypes.string.isRequired
}

export default EditAmazonPage
