import React, {Component} from 'react'
import _ from 'lodash'
import GeneralEdit from './GeneralEdit'
import OrganizationEdit from './OrganizationEdit'
import {saveProductGeneralEdit} from '../../../services/api/ProductServices'
import {getTicket} from '../../../services/api/SellerServices'
import {getProduct, searchVariantProductRevision} from '../../../services/api/ProductApprovalServices'
import {changeVariants} from '../../../services/api/VariantServices'
import StatusEdit from './StatusEdit'
import VariantEdit from './VariantEdit'
import ProductDisplayEdit from './ProductDisplayEdit'
import FacebookPixelContainer from './facebook-pixel/FacebookPixelContainer'
import ProductArtworksContainer from './artworks/ProductArtworksContainer'
import PubSub from '../helper/ProductEditBroker'
import ProductGalleryContainer from './gallery/ProductGalleryContainer'
import ProductDisplayAction from './ProductDisplayAction'
import {getStoreDetail, searchGlobalSettings} from '../../../services/api/StoreServices'
import ProductActionApprove from './action/ProductActionApprove'
import ProductActionReject from './action/ProductActionReject'
import PFAlert from '../../shared/PFAlert'
import IconWarning from '../../../statics/assets/icons/warning.svg'
import IconClose from '../../../statics/assets/icons/Close.svg'
import {isPersonalized} from '../../../helpers/product_personalize'

class ProductEdit extends Component {

    state = {
        product: {
            collections: [],
            attributes: [],
            tags: [],
            variants: [],
            meta: {},
            variantPage: 0,
            variantEntity: 0,
            changeVariants: []
        },
        variants: {
            filters: {}
        },
        updatedProduct: {},
        loading: {
            submitEdit: false
        },
        err: null,
        isUpdated: false,
        variantPerPage: 20,
        editable: true,
        store: {},
        ticketStatus: '',
        rejectMessage: '',
        loadingProduct: true
    }

    async componentDidMount() {
        await this._callApiGetStoreDetail()
        this._getProduct()
    }

    _callApiGetStoreDetail = async () => {
        try {
            const {storeId} = this.props
            const {data} = await getStoreDetail(storeId)
            window.currentStore = data
            this.setState({
                store: data
            })
        } catch (error) {
            console.log(error)
        }
    }

    _getProduct = async () => {
        const {revisionId, productId, ticketId} = this.props
        const resGlobalSetting = await searchGlobalSettings(20, 1, 'seller_host')

        let sellerHost = 'https://dev1-seller.merch8.com/api/seller'

        if (resGlobalSetting.success) {
            sellerHost = Array.isArray(resGlobalSetting.data.settings) && resGlobalSetting.data.settings.length > 0
                ? `${resGlobalSetting.data.settings[0].value}/api/seller` : ''
        }

        try {
            const [productResp, variantResp, ticket] = await Promise.all([
                getProduct(productId, revisionId),
                searchVariantProductRevision(productId, revisionId),
                getTicket(ticketId, sellerHost)
            ])
            if (productResp.success || variantResp.success || ticket.success) {
                const editable = false
                const validatedData = {
                    ...productResp.data,
                    variants:
                        variantResp.data && variantResp.success && productResp.success ? variantResp.data.variants : [],
                    variantPage: variantResp.data && variantResp.success && productResp ? variantResp.data.page : 0,
                    variantEntity: variantResp.data && variantResp.success && productResp ? variantResp.data.total : 0,
                    designs: (productResp.success && productResp.data)
                        ? (productResp.data.revision ? productResp.data.revision.designs : productResp.data.designs)
                        : []
                }

                this.setState({
                    product: {...validatedData},
                    updatedProduct: {...validatedData},
                    editable,
                    ticketStatus: ticket.data && ticket.data.approve_status ? ticket.data.approve_status : '',
                    rejectMessage: ticket.data && ticket.data.rejected ? ticket.data.rejected.reason : '',
                    loadingProduct: false
                })
            }

            this.setState({
                loadingProduct: false
            })

            if (productResp.message) alert(productResp.message)

        } catch (e) {
            this.setState({
                err: e.message,
                loadingProduct: false
            })
        }
    }

    _changeProduct = (property, value) =>
        this.setState(state => ({
            ...state,
            updatedProduct: {
                ...state.updatedProduct,
                [property]: value
            },
            isUpdated: true
        }))

    _changeProductMeta = (property, value) => {
        this.setState(({updatedProduct}) => {
            const {meta} = updatedProduct

            return {
                updatedProduct: {
                    ...updatedProduct,
                    meta: {
                        ...meta,
                        [property]: value
                    }
                },
                isUpdated: true
            }
        })
    }

    _changeProductByKeys = changedState => {
        this.setState(({updatedProduct}) => ({
            updatedProduct: {
                ...updatedProduct,
                ...changedState
            },
            isUpdated: true
        }))
    }

    _changeStatus = (property, value) => {
        this.setState(state => ({
            product: {
                ...state.product,
                [property]: value
            },
            updatedProduct: {
                ...state.updatedProduct,
                [property]: value
            }
        }))
    }

    _discardChange = () => {
        PubSub.publish('PRODUCT_EDIT_DISCARD_CHANGES')

        this.setState(state => ({
            updatedProduct: state.product,
            isUpdated: false
        }))
    }

    _saveEdit = () => {
        PubSub.publish('PRODUCT_EDIT_SAVE_CHANGES')

        this._submitEdit()
    }

    _submitUpdateVariants = async () => {
        this.setState(state => ({
            loading: {
                ...state.loading,
                submitEdit: true
            }
        }))

        const {_id} = this.state.updatedProduct

        const changedVariants = _.differenceWith(
            this.state.updatedProduct.variants,
            this.state.product.variants,
            _.isEqual
        )

        try {
            const saveVariants = await changeVariants(_id, changedVariants)

            if (saveVariants.success) {
                const {data} = saveVariants
                const successChanged = data.filter(response => response && response.success).map(item => item.variant)
                const {updatedProduct, product} = this.state
                const {variants: updatedVariants} = updatedProduct
                const {variants: oldVariants} = product
                const variants = []

                for (const i in updatedVariants) {
                    const variant = successChanged.includes(updatedVariants[i]._id)
                        ? updatedVariants[i]
                        : oldVariants[i]
                    variants.push(variant)
                }

                this.setState(state => ({
                    loading: {
                        ...state.loading,
                        submitEdit: false
                    },
                    product: {...updatedProduct, variants},
                    updatedProduct: {...updatedProduct, variants}
                }))

                return true
            }

            this.setState(state => ({
                loading: {
                    ...state.loading,
                    submitEdit: false
                },
                err: saveVariants.err
            }))

            return false
        } catch (e) {
            this.setState(state => ({
                loading: {
                    ...state.loading,
                    submitEdit: false
                },
                err: e.message || e
            }))

            return false
        }
    }

    _submitEdit = async () => {
        this.setState(state => ({
            loading: {
                ...state.loading,
                submitEdit: true
            }
        }))

        const {_id, ...details} = this.state.updatedProduct
        const changedVariants = _.differenceWith(
            this.state.updatedProduct.variants,
            this.state.product.variants,
            _.isEqual
        )

        try {
            const collections =
                details.collections && Array.isArray(details.collections)
                    ? details.collections
                        .filter(collection => collection && collection._id)
                        .map(collection => collection._id)
                    : []

            const saveProductRequest = saveProductGeneralEdit(_id, {
                ...details,
                collections
            })

            const saveVariantsRequest = changeVariants(_id, changedVariants)

            const [saveProduct, saveVariants] = await Promise.all([saveProductRequest, saveVariantsRequest])

            if (saveProduct.success || saveVariants.success) {
                return this.setState(state => ({
                    loading: {
                        ...state.loading,
                        submitEdit: false
                    },
                    product: saveProduct.data.product,
                    updatedProduct: saveProduct.data.product,
                    isUpdated: false
                }), this._getProduct)
            }

            this.setState(state => ({
                loading: {
                    ...state.loading,
                    submitEdit: false
                },
                err: saveProduct.err || saveVariants.err,
                isUpdated: false
            }))
        } catch (e) {
            this.setState(state => ({
                loading: {
                    ...state.loading,
                    submitEdit: false
                },
                err: e.message || e,
                isUpdated: false
            }))
        }
    }

    _switchVariantPage = page => {
        this._getVariant(page)
    }

    _changeVariantLimit = value => {
        this.setState({
            variantPerPage: value
        }, this._getVariant)
    }

    _getVariant = async page => {
        const {productId, revisionId} = this.props
        const {variants, variantPerPage} = this.state
        const {filters} = variants

        const slugFilter = Object.keys(filters)
            .map(key => filters[key])
            .filter(filter => filter)

        const payload = {page: page, limit: variantPerPage, options: slugFilter}

        if (!productId) return

        try {
            const {success, data} = await searchVariantProductRevision(productId, revisionId, payload)

            if (success) {
                const validatedData = {
                    variants: data.variants || [],
                    variantPage: data.page || 0,
                    variantEntity: data.total || 0
                }

                this.setState(({product, updatedProduct}) => ({
                    product: {
                        ...product,
                        ...validatedData
                    },
                    updatedProduct: {
                        ...updatedProduct,
                        ...validatedData
                    }
                }))
            }
        } catch (e) {
            this.setState({
                err: e.message
            })
        }
    }

    _onSubmitVariantsFilter = (key, value) => {
        const {variants} = this.state
        const {filters} = variants

        this.setState(
            {
                variants: {
                    ...variants,
                    filters: {
                        ...filters,
                        [key]: value
                    }
                }
            },
            () => this._getVariant()
        )
    }

    _handleChangeState = (field, value) => {
        this.setState({
            [field]: value
        })
    }

    render() {
        const {productId, revisionId, ticketId} = this.props
        const {
            updatedProduct,
            product,
            isUpdated,
            variantPerPage,
            editable,
            store,
            ticketStatus,
            rejectMessage,
            loadingProduct
        } = this.state
        const {is_taken_down, revision = {}, meta = {}} = product
        const artwork_separated_by = product.artwork_separated_by ? product.artwork_separated_by : ''

        if (Object.keys(store).length <= 0 || !revision || revision === null || loadingProduct) {
            return null
        }

        const {facebook_pixel_id = '', facebook_conversion, facebook_pixel_ids_collection = []} = meta

        const isPersonalizedProduct = isPersonalized(product)

        return (
            <div className="ProductEdit">
                <div className="d-flex justify-content-end align-items-center ProductEditHeader flex-wrap mb-3 ">
                    <div className="ActionsApproveReject">
                        <ProductActionApprove onGetProduct={this._getProduct} approvalStatus={ticketStatus}
                                              ticketId={ticketId} productTitle={revision.title || ''}/>
                        <ProductActionReject onGetProduct={this._getProduct} approvalStatus={ticketStatus}
                                             ticketId={ticketId} productTitle={revision.title || ''}/>
                    </div>

                </div>
                {
                    is_taken_down &&
                    <PFAlert
                        message={<div className="TextMesInfo">
                            <p>We removed your product because it doesn't follow
                                <a target="_blank" rel="noopener noreferrer"
                                   href="https://bymerchize.com/merchize-built-in-payment/#policy"> our policy</a>.
                                If you violate our policy again, your account may be restricted or disabled.
                            </p>
                        </div>}
                        type="error"
                        className="mb-3 ProductWasTakenDown"
                        closable={false}
                        icon={<img alt="" src={IconClose}/>}
                    />
                }
                {
                    ticketStatus === 'rejected' &&
                    <PFAlert
                        message={<div className="TextMesInfo">
                            <h6>Reasons for rejection</h6>
                            {<p>{rejectMessage ? rejectMessage : 'N/A'}.</p>}
                        </div>}
                        type="error"
                        className="mb-3 ProductWasReject"
                        closable={false}
                        icon={<img alt="" src={IconWarning}/>}
                    />
                }
                {isPersonalizedProduct &&
                <div className="mb-2">
                    <div className="PersonalizedBanner py-3 px-4 d-flex">
                        <i className="ti-info-alt cursor-pointer mr-2 h-100"/>
                        <div className="d-flex flex-column">
                            <b>Personalized product</b>
                        </div>
                    </div>
                </div>
                }
                <div className="PageContent">
                    <div className="row align-items-center">
                        <div className="col-8">
                            <div className="Header">
                                <h1 className="PageTitle">{product.title || 'Product'}</h1>
                                <StatusEdit product={product}/>
                            </div>
                        </div>
                        {
                            ticketStatus === 'approved' &&
                            <div className="col-4">
                                <ProductDisplayAction onGetProduct={this._getProduct} productId={productId}
                                                      isTakenDown={is_taken_down} productTitle={revision.title || ''}/>
                            </div>
                        }

                    </div>
                    {isPersonalizedProduct &&
                    <div className="my-3">
                        <ProductGalleryContainer revisionId={revisionId} productId={productId}
                                                 product={updatedProduct}/>
                    </div>
                    }
                    <div className="row">
                        <div className="col-12 col-md-9">
                            <GeneralEdit product={revision} editable={editable} onChange={this._changeProduct}/>
                            {!isPersonalizedProduct &&
                            <>
                                <div className="mt-3">
                                    <ProductGalleryContainer revisionId={revisionId} productId={productId}
                                                             product={updatedProduct}/>
                                </div>
                                <ProductArtworksContainer revisionId={revisionId} productId={productId}
                                                          product={revision}
                                                          attributes={revision.attributes}
                                                          artwork_separated_by={artwork_separated_by}/>
                            </>
                            }
                            <div className="mt-3">
                                <VariantEdit
                                    productId={productId}
                                    revisionId={revisionId}
                                    editable={editable}
                                    product={updatedProduct}
                                    onChange={this._changeProduct}
                                    isUpdated={isUpdated}
                                    switchPage={this._switchVariantPage}
                                    variantPerPage={variantPerPage}
                                    onGetVariants={this._getVariant}
                                    submitVariants={this._submitUpdateVariants}
                                    onSubmitVariantsFilter={this._onSubmitVariantsFilter}
                                    onChangeVariantsLimit={this._changeVariantLimit}
                                />
                            </div>

                        </div>

                        <div className="col-12 col-md-3">
                            <div className="StickySidebar">
                                <div className="mb-3">
                                    <OrganizationEdit
                                        product={product}
                                        onChange={this._changeProduct}
                                        editable={editable}
                                    />
                                </div>

                                <ProductDisplayEdit
                                    product={product}
                                    editable={editable}
                                    onChangeMultiple={this._changeProductByKeys}
                                />

                                <FacebookPixelContainer
                                    facebookPixelId={facebook_pixel_id}
                                    editable={editable}
                                    facebook_conversion={facebook_conversion}
                                    fbPixelIdsCollection={facebook_pixel_ids_collection}
                                    onChangeMeta={this._changeProductMeta}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3 mb-4">
                        <ProductActionApprove onGetProduct={this._getProduct} approvalStatus={ticketStatus}
                                              ticketId={ticketId} productTitle={revision.title || ''}/>
                        <ProductActionReject onGetProduct={this._getProduct} approvalStatus={ticketStatus}
                                             ticketId={ticketId} productTitle={revision.title || ''}/>
                    </div>

                </div>
            </div>
        )
    }
}

export default ProductEdit
