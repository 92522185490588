import React, {Component} from 'react'
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types'
import {getListStores} from "../../../../../services/api/StoreServices"

class DetailReportInputStore extends Component {

    state = {
        filter: 'slug'
    }

    _handleInputChange = options => {
        const valueSelect = options.value
        this.props.onChange('storeID', valueSelect)
    }

    _timer = null

    _fetchListStores = async (storeName) => {
        const {filter} = this.state
        try {
            const params = {
                limit: 10,
                [filter]: storeName,
                exclude_test_store: true,
            }

            const {data, success, message} = await getListStores(params)

            if (!success) throw new Error(message)

            const {stores} = data

            return stores.map(store => {
                return {
                    value: store._id,
                    label: store.slug + '--' + store.domain,
                }
            })

        } catch (e) {
            this.setState({
                error: e.message || e,
                loadingGetStore: false
            })
        }
    }

    _handleLoadOptions = (inputValue) => {
        if (this._timer) clearTimeout(this._timer)

        return new Promise(resolve => {
            this._timer = setTimeout(() => {
                resolve(this._fetchListStores(inputValue))
            }, 500)
        })
    }

    _onChangeSelect = (e) => {
        const {value} = e.target
        this.setState({
            filter: value
        })
    }


    render() {
        const {filter} = this.state
        return (
            <div className='InputStore flex-wrap d-flex align-items-center InputGroup mr-4'>
                <label className='mr-2'>Store: </label>
                <div className='SelectInputStore d-flex align-items-center border rounded'>
                    <select
                        value={filter}
                        className='border-0'
                        onChange={this._onChangeSelect}
                    >
                        <option value='slug'>Store ID</option>
                        <option value='domain'>Domain</option>
                    </select>
                    <AsyncSelect noOptionsMessage={() => null} defaultOptions loadOptions={this._handleLoadOptions}
                                 onChange={this._handleInputChange}/>
                </div>
            </div>
        )
    }
}

DetailReportInputStore.propTypes = {
    onChange: PropTypes.func.isRequired
}


export default DetailReportInputStore
