import React from 'react'
import PropTypes from 'prop-types'
import {activateStore, suspendStore} from '../../../services/api/StoreServices'
import getHistory from "../../../store/getHistory"

class ButtonChangeStatus extends React.Component {
    state = {
        isLoading: false
    }

    _handleChangeStatusStore = async (status, handleChangeAPI) => {
        this.setState({isLoading: true})

        try {
            const {store, changeStoreDetail} = this.props
            const {_id: storeId} = store

            const {success, message} = await handleChangeAPI(storeId)
            if (!success){
                throw new Error(message)
            } else {
                alert(`The change has been successfully added to the queue.`)
            }

            // changeStoreDetail({
            //     ...store,
            //     status
            // })

            // const history = getHistory()
            // if (status === 'active') {
            //     history.push(`/a/stores/${storeId}/deploy`)
            // } else if (status === 'suspended') {
            //     history.push(`/a/stores/${storeId}/deploy?tab=suspend`)
            // }
        } catch (e) {
            alert(e.message)
        }

        this.setState({isLoading: false})
    }

    _handleActiveStore = () => {
        return this._handleChangeStatusStore('active', activateStore)
    }

    _handleSuspendStore = () => {
        return this._handleChangeStatusStore('suspended', suspendStore)
    }

    render() {
        const {store: {status}, isActivate} = this.props
        const {isLoading} = this.state

        return (
            <div className='ButtonChangeStatus d-inline-block'>
                {
                    (() => {
                        if (status === 'pending' || status === 'suspended')
                            return <button
                                className='btn btn-success btn-sm'
                                onClick={this._handleActiveStore}
                                disabled={isLoading || !isActivate}
                            >
                                {isLoading ? 'Activating' : 'Activate'}
                            </button>

                        return <button
                            className='btn btn-danger btn-sm'
                            onClick={this._handleSuspendStore}
                            disabled={isLoading|| !isActivate}
                        >
                            {isLoading ? 'Suspending' : 'Suspend'}
                        </button>
                    })()
                }
            </div>
        )
    }
}

ButtonChangeStatus.propTypes = {
    store: PropTypes.object.isRequired,
    changeStoreDetail: PropTypes.func.isRequired,
    isActivate: PropTypes.bool.isRequired
}

export default ButtonChangeStatus
