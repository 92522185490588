import React, {Component} from 'react'
import TableFilter from '../../shared/TableFilter'
import TablePagination from './TablePagination'
import {getCategories} from '../../../services/api/CategoriesServices'
import TableContainer from './TableContainer'
import Link from 'react-router-dom/Link'
import RemoveModal from './RemoveModal'
import EditModal from './EditModal'

class Categories extends Component {
    state = {
        modal: {
            edit: {
                open: false,
                category: {},
            },
            remove: {
                open: false,
                category: {},
            }
        },
        items: [],
        loading: true,
        params: {
            page: 1,
            limit: 20,
            title: '',
        },
        total: 0,
        pages: 1,
        err: '',
    }

    componentDidMount() {
        this._getCategories()
    }

    _getCategories = async () => {
        const {params} = this.state

        try {
            const {success, data, message} = await getCategories(params)
            if (!success) {
                return this.setState({
                    err: message,
                    loading: false
                })
            }

            const {total, totalPage, categories} = Object.assign({}, data)

            this.setState({
                items: Array.isArray(categories) ? categories : [],
                total,
                totalPage,
                loading: false
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false
            })
        }
    }

    _handlePageChange = page => {
        this.setState(
            {
                params: {...this.state.params, page}
            },
            () => this._getCategories()
        )
    }

    _onSubmitFilter = ({limit, value, field, page}) => {
        const fieldMap = {
            'Category Title': 'title',
        }

        const parsedField = fieldMap[field]

        this.setState(
            ({params}) => ({
                params: {
                    ...{
                        limit: '',
                        page: '',
                        loading: '',
                    },
                    ...{
                        [parsedField]: value,
                        limit,
                        page: 1,
                        loading: false,
                    },
                },
            }),
            () => {
                this._getCategories()
            }
        )
    }

    _onCloseModal = () => {
        this.setState(({modal}) => ({
            modal: {
                remove: {...modal.remove, open: false, category: {}},
                edit: {...modal.edit, open: false, category: {}}
            }
        }))
    }

    _onClickRemove = (category) => () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                remove: {
                    open: true,
                    category: {...category},
                }
            }
        }))
    }

    _onClickEdit = (category) => () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                edit: {
                    open: true,
                    category: {...category},
                }
            }
        }))
    }

    _onRemoveCategory = () => {
        const {modal, items} = this.state
        const {category} = modal.remove
        const newItems = items.filter((item) => item._id !== category._id)
        const remove = {category: {}, open: false}
        this.setState({
            items: newItems,
            modal: {
                ...modal,
                remove,
            }
        })
    }

    _onEditCategory = (category) => {
        const {modal, items} = this.state
        const newItems = items.map((item) => item._id === category._id ? {...item, ...category} : item)
        const edit = {category: {}, open: false}
        this.setState({
            items: newItems,
            modal: {
                ...modal,
                edit,
            }
        })
    }

    render() {
        const {items, params, pages, loading, err, total, modal} = this.state
        const {remove, edit} = modal
        const {page, limit} = params
        const newItems = items.map(item => {
            return {
                ...item,
                onClickRemove: this._onClickRemove(item),
                onClickEdit: this._onClickEdit(item)
            }
        })

        return (
            <div className='Categories'>
                <h2>Category</h2>
                <div className='CategoriesInner SectionInner'>
                    {!!err && <span className='text-warning'>{err}</span>}
                    <TableFilter
                        total={total}
                        defaultLimit={limit}
                        onSubmitForm={this._onSubmitFilter}
                        searchField={['Category Title']}
                        placeholders={{
                            'Category Title': 'Search title',
                        }}
                    />
                    <div className="addbtn">
                        <Link to="categories/create" className="btn btn-primary">
                            Create new category
                        </Link>
                    </div>
                    <TableContainer items={newItems} page={page} limit={limit} loading={loading}/>
                    <TablePagination page={page} pages={pages} handlePageChange={this._handlePageChange}/>
                </div>
                <RemoveModal open={remove.open} category={remove.category} onClose={this._onCloseModal}
                             onOk={this._onRemoveCategory}/>
                <EditModal open={edit.open} category={edit.category} onClose={this._onCloseModal}
                           onEditCategory={this._onEditCategory}/>
            </div>
        )
    }
}

export default Categories
