import React, {Component} from 'react'
import PropTypes from 'prop-types'

class MessageActions extends Component {
    state = {
        show: true,
    }

    _handleHideMessage = e => {
        e.preventDefault()

        this.setState({
            show: false,
        })
    }

    render() {
        const {show} = this.state

        if (!show) {
            return null
        }

        const {type, className, close, border} = this.props
        let wrapperClasses = "MessageActions position-relative " + type

        if (className !== "") {
            wrapperClasses = wrapperClasses + " " + className
        }

        if (!border) {
            wrapperClasses = wrapperClasses + " NoBorder"
        }

        return (
            <div className={wrapperClasses}>
                <div className="Content">
                    {this.props.children}
                </div>
                {
                    close &&
                    <span className="Icon cursor-pointer position-absolute" onClick={this._handleHideMessage}><i
                        className="fas fa-times-circle"/></span>
                }
            </div>
        )
    }
}

MessageActions.defaultProps = {
    className: "",
    close: true,
    border: true,
}

MessageActions.propTypes = {
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    close: PropTypes.bool,
    border: PropTypes.bool,
}

export default MessageActions
