import React, {Component} from 'react'
import makeAnimated from 'react-select/animated'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import {getCatalogCategories} from '../../../services/api/CategoriesServices'

class CategoriesSelector extends Component {
    state = {
        selectedCategories: [],
        selectedOptions: null,
        options: [],
        params: {title: ''},
        input: '',
    }

    _timeout = null

    _getCategories = () => {
        return new Promise(async (resolve) => {
            if (this._timeout !== null) clearTimeout(this._timeout)

            this._timeout = setTimeout(async () => {
                const {
                    params: {title},
                } = this.state

                try {
                    const {success, data, message} = await getCatalogCategories({page: 1, limit: 1000, title})
                    if (!success) {
                        return this.setState({
                            err: message,
                        })
                    }
                    const {categories} = data
                    const options =
                        (this.props.defaultSelectedOptions &&
                            title === '' && [
                                this.props.defaultSelectedOptions,
                                ...categories.map((category) => {
                                    return {
                                        ...category,
                                        id: category._id,
                                        value: category.slug,
                                        label: category.name,
                                    }
                                }),
                            ]) ||
                        categories.map((category) => {
                            return {
                                ...category,
                                id: category._id,
                                value: category.slug,
                                label: category.name,
                            }
                        })

                    this.setState(
                        () => ({
                            options,
                        }),
                        () => {
                            resolve(this.state.options)
                        }
                    )
                } catch (e) {
                    console.error(e.message)
                }
            }, 550)
        })
    }

    _handleChange = (options) => {
        const _selectedCategories =
            options !== null
                ? (this.props.multiSelect &&
                      options.map((option) => {
                          return option.id
                      })) || [options]
                : []
        this.setState(
            {
                selectedCategories: _selectedCategories,
                selectedOptions: options,
            },
            () => {
                this.props.handleSelectCollection(_selectedCategories)
            }
        )
    }

    _handleInputChange = (input) => {
        this.setState(
            {
                params: {title: input},
                input: input,
            },
            () => {
                // this._getCategories()
            }
        )

        return input
    }

    _getNoOptionsMessage = () => {
        return 'There are no categories!'
    }

    _getLoadingMessage = () => {
        return 'Loading categories...'
    }

    render() {
        const {selectedOptions, input} = this.state
        const {className = '', isDisabled, isClearable} = this.props

        return (
            <div className={`Categories ${className}`}>
                <div className="d-flex align-items-center mb-0">
                    {/* {!isHideTitle && <span>Category Select</span>} */}
                    <AsyncSelect
                        isClearable={isClearable}
                        className={'selectProductLine w-100'}
                        cacheOptions
                        components={makeAnimated()}
                        onChange={this._handleChange}
                        onInputChange={this._handleInputChange}
                        inputValue={input}
                        defaultOptions={true}
                        noOptionsMessage={this._getNoOptionsMessage}
                        loadingMessage={this._getLoadingMessage}
                        loadOptions={this._getCategories}
                        value={selectedOptions || this.props.defaultValue || this.props.defaultSelectedOptions}
                        placeholder="Select categories"
                        isMulti={this.props.multiSelect}
                        isDisabled={isDisabled}
                    />
                </div>
            </div>
        )
    }
}

CategoriesSelector.propTypes = {
    handleSelectCollection: PropTypes.func,
    multiSelect: PropTypes.bool,
    defaultSelectedOptions: PropTypes.object,
    defaultValue: PropTypes.any,
}

export default CategoriesSelector

