import React, {Component} from 'react'
import DocTitleByStore from "../../dashboard/shared/DocTitleByStore"
import ClusterPage from "./ClusterPage"

class ClusterPageContainer extends Component {
    render() {
        const clusterId = this.props.match.params.id

        return (
            <div className="ClusterPageContainer">
                <DocTitleByStore title="Cluster page"/>
                <ClusterPage clusterId={clusterId}/>
            </div>
        )
    }
}

export default ClusterPageContainer
