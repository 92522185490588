import React, {Component} from 'react'
import PropTypes from 'prop-types'
import IndexColumn from './columns/IndexColumn'
import StatusColumn from './columns/StatusColumn'
import NameColumn from './columns/NameColumn'
import KindColumn from './columns/KindColumn'
import MetadataColumn from './columns/MetadataColumn'
import SpecsColumn from './columns/SpecsColumn'
import CapacityColumn from './columns/CapacityColumn'
import CreatedColumn from './columns/CreatedColumn'
import ActionColumn from './columns/ActionColumn'
import UsedColumn from './columns/UsedColumn'

class RenderTableRow extends Component {
    render() {
        const {item} = this.props

        return (
            <tr className='RenderTableRow'>
                <IndexColumn item={item}/>
                <NameColumn item={item}/>
                <KindColumn item={item}/>
                <CapacityColumn item={item}/>
                <UsedColumn item={item}/>
                <MetadataColumn item={item}/>
                <SpecsColumn item={item}/>
                <StatusColumn item={item}/>
                <CreatedColumn item={item}/>
                <ActionColumn item={item}/>
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
}

export default RenderTableRow
