import React from 'react'
import GlobalSettings from './GlobalSettings'
import {isSuperAdmin} from '../../../services/AuthServices'

const GlobalSettingsContainer = () => {

    const checkRole = isSuperAdmin()

    if (!checkRole) return null

    return (
        <div className="GlobalSettingsContainer">
            <GlobalSettings/>
        </div>
    )
}

GlobalSettingsContainer.propTypes = {}

export default GlobalSettingsContainer
