import { createStoreAPI } from './createStoreAPIService'

const api = createStoreAPI({ suffix: '' })

export const uploadFileDatabase = () => {
    return api.makeAuthRequest({
        url: `/upload/getAwsS3PresignedUploadUrl`,
        method: 'GET'
    })
}
