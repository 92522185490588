import React, {Component} from 'react'
import {render, unmountComponentAtNode} from 'react-dom'
import {Button, Toast, ToastBody, ToastHeader} from 'reactstrap'
import PropTypes from 'prop-types'

class PFToast extends Component {
    state = {
        height: 0,
    }

    componentDidMount() {
        const {duration, unmountToast} = this.props

        if (duration > 0) {
            setTimeout(() => {
                unmountToast()
            }, duration)
        }
    }

    _getIcon = () => {
        const {type} = this.props

        if (!type) return null

        switch (type) {
            case 'success':
                return <span className='Icon'><i className="fas fa-check-circle text-success"/></span>
            case 'info':
                return <span className='Icon'><i className="fas fa-info-circle text-info"/></span>
            case 'warning':
                return <span className='Icon'><i className="fas fa-exclamation-circle text-warning"/></span>
            case 'danger':
            case 'error':
                return <span className='Icon'><i className="fas fa-times-circle text-danger"/></span>
            default:
                return null
        }
    }

    render() {
        const {title, message, unmountToast, className = ''} = this.props
        const icon = this._getIcon()

        return (
            <Toast className={`d-inline-block shadow ${className}`}>
                {
                    title &&
                    <ToastHeader toggle={unmountToast}>
                        {icon}
                        {title}
                    </ToastHeader>
                }

                <ToastBody>
                    <div className='d-flex'>
                        {!title && icon}
                        <div className='Message align-self-center'>
                            {message}
                        </div>
                        {!title && <Button className='align-self-start' close onClick={unmountToast}/>}
                    </div>
                </ToastBody>
            </Toast>
        )
    }
}

PFToast.defaultProps = {
    title: '',
    message: '',
    type: '',
    duration: 5000,
}

PFToast.propTypes = {
    title: PropTypes.string,
    message: PropTypes.node,
    type: PropTypes.string,
    duration: PropTypes.number,
    unmountToast: PropTypes.func.isRequired,
    className: PropTypes.string
}

export function toaster(config = {}) {
    const configObj = Object.assign({}, config)
    const {placement = 'top'} = configObj

    const toastWrapper = document.querySelector(`#PFToast .Toast_${placement}`)
    let toastElement = document.createElement('div')
    toastWrapper.appendChild(toastElement)

    const unmountToast = () => {
        unmountComponentAtNode(toastElement)
        toastElement.remove()
    }

    render(<PFToast {...config} unmountToast={unmountToast}/>, toastElement)

    return unmountToast
}
