import React, {Component} from 'react'
import {Modal, ModalBody, ModalHeader} from "reactstrap"
import FormContainer from "./form/FormContainer"
import PropTypes from 'prop-types'

class PushNotificationsModal extends Component {
    render() {
        const {isOpen, onToggleModal} = this.props

        return (
            <Modal isOpen={isOpen} toggle={onToggleModal} className="PushNotificationsModal modal-lg">
                <ModalHeader toggle={onToggleModal}>Push notification</ModalHeader>
                <ModalBody>
                    <FormContainer onToggleModal={onToggleModal}/>
                </ModalBody>
            </Modal>
        )
    }
}

PushNotificationsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onToggleModal: PropTypes.func.isRequired,
}

export default PushNotificationsModal
