import React, {Component} from 'react'
import RateNewPage from "./RateNewPage"

class RateNewPageContainer extends Component {
    render() {
        const zoneId = this.props.match.params.zoneId
        const profilesId = this.props.match.params.profilesId

        return (
            <div className="RateNewPageContainer">
                <RateNewPage profilesId={profilesId} zoneId={zoneId}/>
            </div>
        )
    }
}

export default RateNewPageContainer
