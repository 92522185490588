import React from 'react'
import NumberFormat from 'react-number-format'

const RowProfitAdd = ({profits, onClickProfits, onChangeProfits, errorProfit}) => {
    let rowProfitAdd = []
    for (let i in profits) {
        rowProfitAdd.push(<tr key={i}>
            <td></td>
            <td>
                <NumberFormat
                    defaultValue={profits[i].value}
                    thousandSeparator
                    className='form-control'
                    id='topUpAmount'
                    required
                    autoFocus
                    placeholder='value'
                    decimalScale={2}
                    onValueChange={(values) => {onChangeProfits(i, 'value', values.value)}}
                />
                {errorProfit[`${i}value`] && <p className='text-danger'>{errorProfit[`${i}value`]}</p>}
            </td>
            <td>
                <textarea placeholder='Note' value={profits[i].note} onChange={(e) => {onChangeProfits(i, 'note', e.target.value)}} id="TextareaNote" className='TextareaNote form-control' name="note" />
                {errorProfit[`${i}note`] && <p className='text-danger'>{errorProfit[`${i}note`]}</p>}
            </td>
            <td>
            </td>
        </tr>)
    }

    return rowProfitAdd
}


export default RowProfitAdd
