import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {uploadProductLineImages} from '../../../../services/api/ProductLinesServices'

class ProductImagesAdder extends Component {
    state = {
        isUploading: false
    }

    fileUploader = React.createRef()

    _handleChangeFile = (e) => {
        const {files} = e.target
        if (files && files.length > 0) {
            return this._uploadImage(files[0])
        }
    }

    _uploadImage = async (file) => {

        const {reloadImages} = this.props

        try {
            this.setState({isUploading: true})
            const {productLineId} = this.props
            const formData = new FormData()
            formData.append('images', file)

            const {success, message} = await uploadProductLineImages(productLineId, formData)

            if (!success) throw new Error(message)

            reloadImages()

            this.setState({isUploading: false})
        } catch (e) {
            this.setState({isUploading: false})
        }

        this.fileUploader.current.value = null
    }

    _handleClickAddImage = () => {
        this.fileUploader.current.click()
    }

    render() {
        return (
            <div className='ProductImagesAdder'>
                <input className='d-none' type='file' ref={this.fileUploader} onChange={this._handleChangeFile}/>
                {
                    this.state.isUploading ? <span className='text-primary cursor-pointer'>Uploading ...</span> :
                        <span className='text-primary cursor-pointer'
                              onClick={this._handleClickAddImage}>Add image</span>
                }
            </div>
        )
    }
}

ProductImagesAdder.propTypes = {
    productLineId: PropTypes.string.isRequired,
    reloadImages: PropTypes.func.isRequired
}

export default ProductImagesAdder
