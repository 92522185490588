import React, {Component} from 'react'
import PropTypes from 'prop-types'
import GroupItem from './GroupItem'

class ListGroups extends Component {
    render() {
        const {groups, clusterId, onRefresh} = this.props

        return (
            <div className="ListGroups">
                <table className="table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Namespace</th>
                            <th>Count stores</th>
                            <th className='text-center'>Active</th>
                        </tr>
                    </thead>

                    <tbody>
                        {groups.map((group) => {
                            return <GroupItem clusterId={clusterId} group={group} key={group._id} onRefresh={onRefresh} />
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
}

ListGroups.propTypes = {
    groups: PropTypes.array.isRequired,
    onRefresh: PropTypes.func.isRequired,
    clusterId: PropTypes.string.isRequired,
}

export default ListGroups
