import React, { useEffect, useState } from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Col, Input } from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { toaster } from "../../../../shared/PFToast"
import { MONTHS } from "../../../../../statics/month"
import { creatCashback } from "../../../../../services/api/StoreServices"
import { parse } from "json2csv"

const cashback_example = [
    {
        store_id: "(required)",
        value: "(required)",
        link_detail: "(required)",
    },
]

const defaultForm = {
    month: null,
    transactionId: "",
    cashback_for: "",
    content: "Merchize cashback for your store. Detail in: ",
}

const defaultValidate = {
    month: "",
    transactionId: "",
    content: "",
}

const CashbackModal = (props) => {
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState(defaultForm)
    const [selectedFile, setSelectedFile] = useState(null)
    const [validateForm, setValidateForm] = useState(defaultValidate)
    const [response, setResponse] = useState(null)
    const [filename, setFilename] = useState("")

    const { isOpen, toggle } = props

    useEffect(() => {
        setForm(defaultForm)
        setValidateForm(defaultValidate)
        setSelectedFile(null)
        setFilename("")
        setResponse(null)
    }, [isOpen, toggle])

    const onChangeMonth = (date) => {
        const monthOfDate = date ? date.getMonth() + 1 : null
        const selectedMonth = MONTHS.find((m) => m.value === monthOfDate)
        let transactionId = monthOfDate ? `Merchize_cashback_${selectedMonth.label}_${date.getFullYear()}` : ""
        const cashback_for = date ? `${monthOfDate < 10 ? `0${monthOfDate}` : monthOfDate}/${date.getFullYear()}` : ""
        setForm({
            ...form,
            month: date,
            cashback_for,
            transactionId,
        })
        const errorMessage = onValidateField("month", date)
        setValidateForm({
            ...validateForm,
            transactionId: "",
            month: errorMessage,
        })
    }

    const onChangeInput = (e) => {
        const { name, value } = e.target
        setForm({
            ...form,
            [name]: value,
        })
        const errorMessage = onValidateField(name, value)
        setValidateForm({
            ...validateForm,
            [name]: errorMessage,
        })
    }

    const onSubmit = async () => {
        try {
            const errorObj = onValidateForm(form, validateForm, onValidateField)
            setValidateForm(errorObj)
            if (Object.keys(errorObj).length !== 0) {
                return
            }
            if (!selectedFile) {
                return toaster({
                    type: "error",
                    message: "You must upload cashback file",
                })
            }
            setLoading(true)
            const formData = new FormData()

            formData.append("transactionId", form.transactionId)
            formData.append("content", form.content)
            formData.append("cashback_for", form.cashback_for)
            formData.append("cashback_file", selectedFile)
            const { data, success, message } = await creatCashback(formData)
            setLoading(false)
            if (!success) {
                return toaster({
                    type: "error",
                    message,
                })
            }
            if (data.invalid_data && data.invalid_data.length > 0) {
                setResponse(data)
                setSelectedFile(null)
                return
            }
            toaster({
                type: "success",
                message: "Create cashback successfully!",
            })
            setSelectedFile(null)
            setFilename("")
            toggle()
        } catch (e) {
            setLoading(false)
        }
    }

    const onChangeFile = (event) => {
        setSelectedFile(event.target.files[0])
        setFilename(event.target.files[0].name)
        setResponse(null)
        event.target.value = null
    }

    const onRemoveFile = () => {
        setSelectedFile(null)
        setFilename("")
        setResponse(null)
    }

    const _downloadCSV = (data, name = "invalid-cashback.csv", hasFields = true) => {
        let fields = ["store_id", "value", "link_detail", "errors"]
        let csv
        if (hasFields) {
            csv = parse(data, { fields })
        } else {
            csv = parse(data)
        }
        const blob = new Blob([csv], { type: "text/csvcharset=utf-8" })
        const url = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.setAttribute("href", url)
        link.setAttribute("download", name)
        link.style.visibility = "hidden"
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const onValidateField = (name, value) => {
        let errorMsg = null
        switch (name) {
            case "month":
            case "transactionId":
            case "content":
                if (!value) errorMsg = "This field is required"
                break
            default:
                break
        }
        return errorMsg
    }

    const onValidateForm = (form, formError, validateFunc) => {
        const errorObj = {}
        Object.keys(formError).map((x) => {
            const message = validateFunc(x, form[x])
            if (message) errorObj[x] = message
        })
        return errorObj
    }

    return (
        <div>
            <Modal className="cashback-modal" isOpen={isOpen} toggle={toggle} centered size="lg" backdrop="static">
                <ModalHeader toggle={toggle}>Cashback</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup row>
                            <Label for="month" sm={3}>
                                Month<span className="text-danger">*</span>
                            </Label>
                            <Col sm={9}>
                                <DatePicker
                                    className={`form-control ${validateForm.month ? "invalid-input" : ""}`}
                                    selected={form.month}
                                    onChange={(date) => onChangeMonth(date)}
                                    dateFormat="MM/yyyy"
                                    maxDate={new Date()}
                                    showMonthYearPicker
                                />
                                {validateForm.month && <span className="error-message">{validateForm.month}</span>}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="transaction" sm={3}>
                                Transaction ID<span className="text-danger">*</span>
                            </Label>
                            <Col sm={9}>
                                <Input
                                    type="text"
                                    name="transactionId"
                                    value={form.transactionId}
                                    onChange={onChangeInput}
                                    className={`form-control ${validateForm.transactionId ? "invalid-input" : ""}`}
                                />
                                {validateForm.transactionId && (
                                    <span className="error-message">{validateForm.transactionId}</span>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="transaction" sm={3}>
                                Content<span className="text-danger">*</span>
                            </Label>
                            <Col sm={9}>
                                <textarea
                                    name="content"
                                    value={form.content}
                                    onChange={onChangeInput}
                                    className={`form-control ${validateForm.content ? "invalid-input" : ""}`}
                                ></textarea>
                                {validateForm.content && <span className="error-message">{validateForm.content}</span>}
                            </Col>
                        </FormGroup>
                    </Form>
                    <div className="d-flex flex-wrap justify-content-end">
                        <div className="UploadInput mr-2">
                            <input type="file" accept=".csv" id="upload-cashback" onChange={onChangeFile} />
                            <label
                                htmlFor="upload-cashback"
                                className="btn btn-sm btn-info cursor-pointer mb-0 ButtonImport"
                            >
                                <i className="ti-upload mr-2" />
                                <span className="float-none">Upload cashback</span>
                            </label>
                        </div>
                        <div className="UploadInput">
                            <a
                                onClick={() => _downloadCSV(cashback_example, "cashback_example.csv", false)}
                                className="btn btn-sm btn-info cursor-pointer mb-0 ButtonImport text-white"
                            >
                                <i className="ti-import mr-2" />
                                <span className="float-none">Download template</span>
                            </a>
                        </div>
                    </div>
                    {selectedFile && (
                        <div className="upload-file-valid d-flex align-items-center justify-content-between mt-3">
                            <div>
                                Completed import file <i>{`"${selectedFile.name}"`}</i>
                            </div>
                            <a onClick={onRemoveFile}>
                                <i className="fa fa-times-circle"></i>
                            </a>
                        </div>
                    )}
                    {!selectedFile && response && response.invalid_data.length > 0 && (
                        <div className="upload-file-invalid d-flex align-items-center justify-content-between mt-3">
                            <div>
                                <span>
                                    Completed import file <i>{`"${filename}"`}</i>,{" "}
                                    <b>{response.invalid_data.length}</b> item(s)
                                </span>
                                <ul style={{ listStyle: "initial", paddingLeft: "40px" }}>
                                    <li style={{ listStyle: "initial" }}>
                                        Invalid items: <b>{response.invalid_rows}</b> item(s){" "}
                                        {response.invalid_rows > 0 && (
                                            <span onClick={() => _downloadCSV(response.invalid_data)}>
                                                ,{" "}
                                                <u className="text-primary cursor-pointer">
                                                    download invalid link items.
                                                </u>
                                            </span>
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <a onClick={onRemoveFile}>
                                <i className="fa fa-times-circle"></i>
                            </a>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="default" onClick={toggle} disabled={loading}>
                        Cancel
                    </Button>
                    <Button color="primary" disabled={loading} onClick={onSubmit}>
                        {loading && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default CashbackModal
