import React from 'react'
import AsyncSelect from 'react-select/async'
import { getListStoresV2 } from '../../../services/api/StoreServices'

const StoreSelect = (props) => {
    const {value = {}, errors = {}, onChangeStore} = props
    let timer = null

    const fetchListStores = async (storeSlug = '') => {
        try {
            const params = {
                limit: 1000,
                slug: storeSlug,
                status: 'active',
            }

            const {data, success, message} = await getListStoresV2(params)

            if (!success) throw new Error(message)

            const {stores} = data

            const listStores = stores.map((store) => {
                return {
                    value: store._id,
                    label: store.slug + ' - ' + store.domain,
                }
            })
            return listStores
        } catch (e) {
            console.log(e)
        }
    }

    const handleLoadOptions = (inputValue) => {
        if (timer) clearTimeout(timer)

        return new Promise((resolve) => {
            timer = setTimeout(() => {
                resolve(fetchListStores(inputValue))
            }, 500)
        })
    }

    const handleInputChange = (options) => {
        const valueSelect = options ? options : null
        onChangeStore('store', valueSelect)
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: errors.store ? '#dd3d4c' : provided.borderColor,
            boxShadow: errors.store ? 'unset' : provided.boxShadow,
            '&:hover': {
                borderColor: errors.store ? '#dd3d4c' : provided.borderColor,
                boxShadow: errors.store ? 'unset' : provided.boxShadow,
            },
        }),
    }

    return (
        <div className="form-group">
            <label className="font-weight-bold">
                Store<span className="text-danger">*</span>
            </label>
            <AsyncSelect
                isClearable
                defaultOptions
                cacheOptions
                placeholder="Select store"
                noOptionsMessage={() => null}
                styles={customStyles}
                loadOptions={handleLoadOptions}
                onChange={handleInputChange}
                value={value}
            />
            {errors.store && <span className="error-message">{errors.store}</span>}
        </div>
    )
}

export default StoreSelect
