import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import classNames from 'classnames'
import NavbarTop from './NavbarTop'
import NavbarLeft from './NavbarLeft'
import SettingsPageContainer from '../../settings/components/SettingsPageContainer'
import SettingContainer from '../../setting/components/SettingContainer'
import StoresPageContainer from '../../stores/components/StoresPageContainer'
import StoreDetailPageContainer from '../../store-detail/components/StoreDetailPageContainer'
import AccountsPageContainer from '../../accounts/components/AccountsPageContainer'
import AccountDetailPageContainer from '../../account-detail/components/AccountDetailPageContainer'
import StoreSettingsContainer from '../../store-settings/components/StoreSettingsContainer'
import AnalyticsPageContainer from '../../analytics/components/AnalyticsPageContainer'
import PricingContainer from '../../pricing/components/PricingContainer'
import StoreDepositsContainer from '../../store-deposits/components/StoreDepositsContainer'
import ProjectVersionsContainer from '../../project-versions/components/ProjectVersionsContainer'
import ClustersContainer from '../../clusters/components/ClustersContainer'
import ResourceContainer from '../../resource-settings/components/ResourceContainer'
import JobContainer from '../../cicd/components/JobContainer'
import ResourceDetailContainer from '../../resource-settings/components/details/ResourceDetailContainer'
import CreateClusterContainer from '../../clusters/components/create-cluster/CreateClusterContainer'
import ClusterPageContainer from '../../cluster/components/ClusterPageContainer'
import ProductLinesContainer from '../../product-lines/components/ProductLinesContainer'
import CreateNewProductLineContainer from '../../create-new-product-line/components/CreateNewProductLineContainer'
import EditProductLineContainer from '../../edit-product-line/components/EditProductLineContainer'
import StoreDeploymentContainer from '../../store-deploy/components/StoreDeploymentContainer'
import StoreWithdrawalsContainer from '../../store-withdrawals/components/StoreWithdrawalsContainer'
import StoreTransactionsContainer from '../../store-transactions/components/StoreTransactionsContainer'
import CreateCategoryContainer from '../../create-category/CreateCategoryContainer'
import CategoriesContainer from '../../categories/components/CategoriesContainer'
import ProductApprovalEditContainer from '../../product-approval-edit/components/ProductApprovalEditContainer'
import SettingShippingContainer from '../../setting-shipping/components/SettingShippingContainer'
import ShippingProfilesEditContainer from '../../setting-shipping/profiles-edit/components/ShippingProfilesEditContainer'
import ShippingProfilesNewContainer from '../../setting-shipping/profiles-new/components/ShippingProfilesNewContainer'
import ShippingZoneNewPageContainer from '../../setting-shipping/zone-new/components/ZoneNewPageContainer'
import ShippingZoneEditPageContainer from '../../setting-shipping/zone-edit/components/ZoneEditPageContainer'
import ShippingRateEditPageContainer from '../../setting-shipping/rate-edit/components/RateEditPageContainer'
import ShippingRateNewPageContainer from '../../setting-shipping/rate-new/components/RateNewPageContainer'
import BalanceReportContainer from '../../accounting-report/components/BalanceReportStore/BalanceReportContainer'
import DetailsReportContainer from '../../accounting-report/components/DetailsReportStore/DetailsReportContainer'
import DailyProfitReportContainer from '../../accounting-report/components/DailyProfitReport/DailyProfitContainer'
import ContestReportContainer from '../../contest-report/components/AllStore/ContestReportContainer'
import ProductApprovalContainer from '../../product-approval/components/ProductApprovalContainer'
import ProductMockupContainer from '../../product-mockup/components/ProductMockupContainer'
import ProductMockupDetailContainer from '../../product-mockup-detail/components/ProductMockupDetailContainer'
import ProductMockupNewContainer from '../../product-mockup-new/ProductMockupNewContainer'
import AffiliateCategoryContainer from '../../affiliate-category/component/AffiliateCategoryContainer'
import CreateAffiliateContainer from '../../create-affiliate/component/CreateAffiliateContainer'
import ListOrderRefund from '../../order/list-order-refund/ListOrderRefund'
import DetailOrderRefund from '../../order/detail-order-refund/DetailOrderRefund'
import PayoutApprovalContainer from '../../payout-approval/components/PayoutApprovalContainer'
import AmazonContainer from '../../amazon/pages/amazons/AmazonContainer'
import CreateAmazonContainer from '../../amazon/pages/create-amazon/CreateAmazonContainer'
import EditAmazonContainer from '../../amazon/pages/edit-amazon/EditAmazonContainer'
import AffiliateContainer from '../../affiliates/component/AffiliateContainer'
import AffiliateForm from '../../affiliates/component/AffiliateForm'
import TrademarkContainer from '../../trademark/components/TrademarkContainer'
import CashbackHistoryContainer from '../../stores/components/CashbackHistoryContainer'
import HighlightProductContainer from '../../product-lines/components/highlight-products/HighlightProductContainer'
import CashbackRuleContainer from '../../cashback-rule/components/CashbackRuleContainer'
import CashbackRuleForm from '../../cashback-rule/components/CashbackRuleForm'
import CashbackRecallContainer from '../../cashback-rule/components/CashbackRecallContainer'
import CashbackRuleHistoryContainer from '../../cashback-rule/components/CashbackRuleHistoryContainer'
import DailyTopupReportContainer from '../../accounting-report/components/DailyTopupReport/DailyTopupReportContainer'
import DepositContainer from '../../global-deposits/components/GlobalDepositContainer'
import ShipmentReportContainer from '../../shipment-report/components/ShipmentReportContainer'
import CatalogsContainer from '../../catalog/components/CatalogsContainer'
import CatalogCategoriesContainer from '../../catalog-categories/components/CatalogCategoriesContainer'
import CatalogDetail from '../../catalog/components/CatalogDetail'
import DatabaseJobsContainer from '../../database-jobs/components/DatabaseJobsContainer'
import CatalogPrivateBaseCostContainer from '../../catalog-variant/components/CatalogPrivateBaseCostContainer'
import CatalogPrivateShippingCostContainer from '../../catalog-variant/components/CatalogPrivateShippingCostContainer'
import GroupsContainer from '../../groups/components/GroupsContainer'
import GroupDetailContainer from '../../group/components/GroupDetailContainer'
import MappingRulesPageContainer from '../../mapping-rules/components/MappingRulesPageContainer'

class Dashboard extends Component {
    state = {
        isCollapsed: true,
    }

    _handleClick = () => {
        this.setState({
            isCollapsed: !this.state.isCollapsed,
        })
    }

    render() {
        const { store, basePath } = this.props
        const { isCollapsed } = this.state

        return (
            <div className={classNames('DashboardContainer', { isCollapsed })}>
                <NavbarLeft isCollapsed={isCollapsed} />

                <div className="DashboardInner">
                    <NavbarTop store={store} onClick={() => this._handleClick()} />

                    <div className="MainContent">
                        <div className="MainContentInner">
                            <Switch>
                                <Redirect path={`${basePath}`} exact to={`${basePath}/stores`} />
                                <Route path={`${basePath}/stores`} exact component={StoresPageContainer} />
                                <Route
                                    exact
                                    path={`${basePath}/stores/cashback-history`}
                                    component={CashbackHistoryContainer}
                                />
                                <Route path={`${basePath}/stores/:id`} exact component={StoreDetailPageContainer} />
                                <Route
                                    path={`${basePath}/stores/:id/deploy`}
                                    exact
                                    component={StoreDeploymentContainer}
                                />
                                <Route path={`${basePath}/settings`} exact component={SettingsPageContainer} />
                                <Route path={`${basePath}/settings/:setting`} exact component={SettingContainer} />
                                <Route path={`${basePath}/accounts/:id`} exact component={AccountDetailPageContainer} />
                                <Route path={`${basePath}/accounts`} exact component={AccountsPageContainer} />
                                <Route
                                    path={`${basePath}/stores/:id/settings`}
                                    exact
                                    component={StoreSettingsContainer}
                                />
                                <Route
                                    path={`${basePath}/stores/:id/deposits`}
                                    exact
                                    component={StoreDepositsContainer}
                                />
                                <Route
                                    path={`${basePath}/stores/:id/deposits`}
                                    exact
                                    component={StoreDepositsContainer}
                                />
                                <Route
                                    path={`${basePath}/stores/:id/withdrawals`}
                                    exact
                                    component={StoreWithdrawalsContainer}
                                />

                                <Route
                                    path={`${basePath}/stores/:id/transactions`}
                                    exact
                                    component={StoreTransactionsContainer}
                                />

                                <Route path={`${basePath}/analytics`} exact component={AnalyticsPageContainer} />
                                <Route path={`${basePath}/pricing`} exact component={PricingContainer} />
                                <Route
                                    path={`${basePath}/projectversions`}
                                    exact
                                    component={ProjectVersionsContainer}
                                />
                                <Route path={`${basePath}/clusters`} exact component={ClustersContainer} />
                                <Route path={`${basePath}/clusters/create`} exact component={CreateClusterContainer} />
                                <Route path={`${basePath}/clusters/:id`} exact component={ClusterPageContainer} />

                                <Route path={`${basePath}/groups`} exact component={GroupsContainer} />
                                <Route path={`${basePath}/group/:id`} exact component={GroupDetailContainer} />

                                <Route path={`${basePath}/resource-settings`} exact component={ResourceContainer} />
                                <Route
                                    path={`${basePath}/resource-settings/:id`}
                                    exact
                                    component={ResourceDetailContainer}
                                />

                                <Route path={`${basePath}/jobs`} exact component={JobContainer} />

                                <Route
                                    path={`${basePath}/affiliate-category`}
                                    exact
                                    component={AffiliateCategoryContainer}
                                />
                                <Route
                                    path={`${basePath}/affiliate-category/create`}
                                    exact
                                    component={CreateAffiliateContainer}
                                />

                                <Route path={`${basePath}/product-mockup`} exact component={ProductMockupContainer} />
                                <Route
                                    path={`${basePath}/product-mockup/new`}
                                    exact
                                    component={ProductMockupNewContainer}
                                />
                                <Route
                                    path={`${basePath}/product-mockup/:id`}
                                    exact
                                    component={ProductMockupDetailContainer}
                                />

                                <Route path={`${basePath}/product-lines`} exact component={ProductLinesContainer} />
                                <Route
                                    path={`${basePath}/product-lines/create`}
                                    exact
                                    component={CreateNewProductLineContainer}
                                />
                                <Route
                                    path={`${basePath}/product-lines/highlight`}
                                    exact
                                    component={HighlightProductContainer}
                                />
                                <Route
                                    path={`${basePath}/product-lines/:id`}
                                    exact
                                    component={EditProductLineContainer}
                                />
                                <Route path={`${basePath}/affiliates`} exact component={AffiliateContainer} />
                                <Route path={`${basePath}/affiliate/create`} exact component={AffiliateForm} />
                                <Route path={`${basePath}/affiliate/:id`} exact component={AffiliateForm} />
                                <Route path={`${basePath}/categories`} exact component={CategoriesContainer} />
                                <Route
                                    path={`${basePath}/categories/create`}
                                    exact
                                    component={CreateCategoryContainer}
                                />
                                <Route path={`${basePath}/amazon`} exact component={AmazonContainer} />
                                <Route path={`${basePath}/amazon/create`} exact component={CreateAmazonContainer} />
                                <Route path={`${basePath}/amazon/:id`} exact component={EditAmazonContainer} />

                                <Redirect
                                    exact
                                    from={`${basePath}/accounting-report`}
                                    to={`${basePath}/accounting-report/all-store-report`}
                                />

                                <Route
                                    path={`${basePath}/accounting-report/all-store-report`}
                                    exact
                                    component={BalanceReportContainer}
                                />
                                <Route
                                    path={`${basePath}/accounting-report/detailed-report`}
                                    exact
                                    component={DetailsReportContainer}
                                />
                                <Route
                                    path={`${basePath}/accounting-report/daily-profit-report`}
                                    exact
                                    component={DailyProfitReportContainer}
                                />
                                <Route
                                    path={`${basePath}/accounting-report/daily-topup-report`}
                                    exact
                                    component={DailyTopupReportContainer}
                                />
                                <Route path={`${basePath}/contest-report`} exact component={ContestReportContainer} />

                                <Route path={`${basePath}/payout-approval`} exact component={PayoutApprovalContainer} />
                                <Route
                                    path={`${basePath}/payout-approval/:payout_id`}
                                    exact
                                    component={PayoutApprovalContainer}
                                />

                                <Route
                                    path={`${basePath}/product-approval`}
                                    exact
                                    component={ProductApprovalContainer}
                                />
                                <Route
                                    path={`${basePath}/product-approval/products`}
                                    exact
                                    component={ProductApprovalEditContainer}
                                />
                                <Route
                                    path={`${basePath}/trademark`}
                                    exact
                                    component={TrademarkContainer}
                                />
                                <Route path={`${basePath}/order/refund-order`} exact component={ListOrderRefund} />
                                <Route
                                    path={`${basePath}/order/refund-order/:id`}
                                    exact
                                    component={DetailOrderRefund}
                                />
                                <Route exact path="/a/settings-shipping" component={SettingShippingContainer} />

                                <Route
                                    exact
                                    path="/a/settings-shipping/profiles-edit/:id"
                                    component={ShippingProfilesEditContainer}
                                />
                                <Route
                                    exact
                                    path="/a/settings-shipping/profiles-new"
                                    component={ShippingProfilesNewContainer}
                                />

                                <Route
                                    exact
                                    path="/a/settings-shipping/:profilesId/zone-new"
                                    component={ShippingZoneNewPageContainer}
                                />
                                <Route
                                    exact
                                    path="/a/settings/settings-shipping/:profilesId/zones-edit/:zoneId"
                                    component={ShippingZoneEditPageContainer}
                                />

                                <Route
                                    exact
                                    path="/a/settings/shipping-zones/:profilesId/:zoneId/rates/:rateId"
                                    component={ShippingRateEditPageContainer}
                                />
                                <Route
                                    exact
                                    path="/a/settings/shipping-zones/:profilesId/rates/:zoneId/new"
                                    component={ShippingRateNewPageContainer}
                                />
                                <Route
                                    exact
                                    path="/a/cashback-rule"
                                    component={CashbackRuleContainer}
                                />
                                <Route
                                    exact
                                    path="/a/cashback-recall"
                                    component={CashbackRecallContainer}
                                />
                                <Route
                                    exact
                                    path="/a/form-cashback-rule"
                                    component={CashbackRuleForm}
                                />
                                <Route
                                    exact
                                    path="/a/form-cashback-rule/:cashbackRuleId"
                                    component={CashbackRuleForm}
                                />
                                <Route
                                    exact
                                    path="/a/cashback-rule-history"
                                    component={CashbackRuleHistoryContainer}
                                />
                                <Route
                                    exact
                                    path="/a/global-deposits"
                                    component={DepositContainer}
                                />
                                <Route
                                    exact
                                    path="/a/shipment-report"
                                    component={ShipmentReportContainer}
                                />
                                <Route
                                    exact
                                    path="/a/catalog"
                                    component={CatalogsContainer}
                                />
                                <Route
                                    exact
                                    path="/a/catalog/:catalogId"
                                    component={CatalogDetail}
                                />
                                <Route
                                    exact
                                    path="/a/catalog/:catalogId/private-base-cost"
                                    component={CatalogPrivateBaseCostContainer}
                                />
                                <Route
                                    exact
                                    path="/a/catalog/:catalogId/private-shipping"
                                    component={CatalogPrivateShippingCostContainer}
                                />
                                <Route
                                    exact
                                    path="/a/catalog-categories"
                                    component={CatalogCategoriesContainer}
                                />
                                <Route
                                    exact
                                    path="/a/database-jobs"
                                    component={DatabaseJobsContainer}
                                />
                                <Route
                                    exact
                                    path="/a/mapping-rules"
                                    component={MappingRulesPageContainer}
                                />
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Dashboard.propTypes = {
    basePath: PropTypes.string.isRequired,
    store: PropTypes.object.isRequired,
}

export default Dashboard
