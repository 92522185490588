import React, {Component} from 'react'
import PriceRulesTable from "./PriceRulesTable"

// import PropTypes from 'prop-types'

class PriceRulesContainer extends Component {
    render() {
        return (
            <div className="PriceRulesContainer">
                <div className="SectionInner position-relative">
                    <div className='col-7 p-0'>
                        <h6 className="mb-0 fs-18">Price Table</h6>
                        <div className="text-muted fs-14">Charge incremental shipping rates based on product price.</div>
                    </div>
                    <PriceRulesTable {...this.props}/>
                </div>
            </div>
        )
    }
}

// PriceRulesContainer.defaultProps = {}

// PriceRulesContainer.propTypes = {}

export default PriceRulesContainer
