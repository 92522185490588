import React, {useEffect, useState} from 'react'
import {getAllGroups} from '../../../services/api/ClustersServices'
import ListGroups from './ListGroups'
import ClusterFilterSelect from './ClusterFilterSelect'
import StatusFilterSelect from './StatusFilterSelect'

const GroupsPage = () => {
    const [groups, setGroups] = useState([])
    const [cluster, setCluster] = useState(null)
    const [status, setStatus] = useState(null)

    const handleGetAllGroups = async () => {
        try {
            const {success, data, message} = await getAllGroups({cluster, status})
            if (!success) {
                throw new Error(message)
            }
            const {groups} = Object.assign({}, data)
            setGroups(groups)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        handleGetAllGroups()
    }, [status, cluster])

    return (
        <div className='GroupsPage'>
            <h2>Groups</h2>
            <div className="row d-flex mb-3">
                <ClusterFilterSelect onChange={(e) => setCluster(e.target.value)} value={cluster || ''} />
                <StatusFilterSelect onChange={(e) => setStatus(e.target.value)} value={status || ''} />
            </div>
            <div className="SectionInner">
                <ListGroups groups={groups} handleGetAllGroups={handleGetAllGroups} />
            </div>
        </div>
    )
}

export default GroupsPage
