import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Card, CardBody, CardTitle} from 'reactstrap'

class ProductBox extends Component {
    render() {
        const {formattedMetricData} = this.props
        const product = Object.keys(formattedMetricData).length ? formattedMetricData.product : {}
        const {total, active, featured} = product
        return (
            <div className='ProductBox Box'>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>Product</h5>
                        </CardTitle>
                        <div className='card-item'>
                            <span>Total</span>
                            <span>{total || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Active</span>
                            <span>{active || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Featured</span>
                            <span>{featured || 0}</span>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

ProductBox.propTypes = {
    formattedMetricData: PropTypes.object.isRequired,
}
export default ProductBox
