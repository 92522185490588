import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'
import {Link} from 'react-router-dom'
import humanizeTime from '../../../helpers/time/humanizeTime'

class AccountStoreDetails extends PureComponent {
    _validateDate = time => {
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }

    render() {
        const {item} = this.props
        const {_id: storeId, name, domain, status, activated_at, slug, created} = item
        const activateDate = this._validateDate(activated_at)
        const createDate = this._validateDate(created)

        return (
            <div className='list-group AccountStoreDetails'>
                <li className='list-group-item text--right'>
                    Name:{' '}
                    <span>
                        <Link to={`/a/stores/${storeId}`}>{name || null}</Link>
                    </span>
                </li>
                <li className='list-group-item text--right'>
                    Domain: <span>{domain} </span>
                </li>
                <div className='list-group-item text--right'>
                    Status: <span className={classNames('Status badge badge-secondary', status)}>{status}</span>
                </div>
                <li className='list-group-item text--right'>
                    Slug: <span>{slug} </span>
                </li>
                <li className='list-group-item text--right'>
                    Created: <span>{createDate} </span>
                </li>
                <li className='list-group-item text--right'>
                    Activated: <span>{activateDate} </span>
                </li>
            </div>
        )
    }
}

AccountStoreDetails.propTypes = {
    item: PropTypes.object.isRequired,
}

export default AccountStoreDetails
