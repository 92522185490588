import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import NotificationValid from '../notification/NotificationValid'

const InputEditorV2 = (props) => {
    const {label, error, value, name, onChange, disabled, placeholder} = props

    const handleChangeInput = (e) => {
        e.preventDefault()
        const value = e.target.value
        onChange(value, name)
    }

    return (
        <div className="form-group">
            <label>{label}</label>
            <input type="text" className={classNames('form-control', {'is-invalid': error})}
                   value={value} disabled={disabled} placeholder={placeholder}
                   onChange={handleChangeInput}
            />
            {
                error &&
                <NotificationValid content={error}/>
            }
        </div>
    )
}

InputEditorV2.defaultProps = {
    label: '',
    name: '',
    value: '',
    error: '',
    disabled: false,
    placeholder: ''
}

InputEditorV2.propTypes = {
    label: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}
export default InputEditorV2
