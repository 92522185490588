import React, { useEffect, useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import ProductTable from "./ProductTable"
import debounce from 'lodash/debounce'
import { deleteEmptyObjectkeys, trimObjectValues } from "../../../../helpers/common/cleanObject"
import { getProductLines } from "../../../../services/api/ProductLinesServices"
import { toaster } from "../../../shared/PFToast"
import CategoriesSelector from "../CategoriesSelector"
import { addHighlightProduct } from "../../../../services/api/HighlightProductServices"

const defaultQuery = {
    page: 1,
    limit: 10,
    title: "",
    category: ""
}

const AddHighlightProductModal = (props) => {
    const { isOpen, toggleModal, highlightProducts, fetchHighlightList } = props
    const [loading, setLoading] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [pages, setPages] = useState(1)
    const [query, setQuery] = useState(defaultQuery)
    const [checkedProductIds, setCheckedProductIds] = useState([])
    const [products, setProducts] = useState([])

    useEffect(() => {
        setQuery(defaultQuery)
        if (highlightProducts && highlightProducts.length > 0) {
            const checkedProducts = highlightProducts.map(product => product._id)
            setCheckedProductIds(checkedProducts)
        } else {
            setCheckedProductIds([])
        }
    }, [isOpen])

    useEffect(() => {
        fetchProducts(query)
    }, [])

    const fetchProducts = debounce(async (params) => {
        try {
            setLoading(true)
            const { success, data, message } = await getProductLines(params)
            setLoading(false)
            if (!success) {
                return toaster({
                    type: "error",
                    message
                })
            }
            const { pages, products } = Object.assign({}, data)
            setQuery(params)
            setPages(pages)
            setProducts(products)
        } catch (e) {
            setLoading(false)
            console.error(e || e.message)
        }
    }, 1000)

    const handleChangePage = (page) => {
        const newQuery = {
            ...query,
            page,
        }
        fetchProducts(newQuery)
    }

    const handleFilter = (e) => {
        const { name, value } = e.target
        const newQuery = {
            ...query,
            page: 1,
            [name]: value,
        }
        fetchProducts(newQuery)
    }

    const handleSelectCategories = (options = []) => {
        const categories = !options ? [] : options
        const firstSelect = categories[0] || { id: '' }
        const { id } = firstSelect
        const newQuery = {
            ...query,
            page: 1,
            category: id,
        }
        fetchProducts(newQuery)
    }

    const handleHighlightProduct = async () => {
        try {
            setLoadingBtn(true)
            const { success, message } = await addHighlightProduct({ productLineIds: checkedProductIds })
            setLoadingBtn(false)
            if (!success) {
                return toaster({
                    type: "error",
                    message
                })
            }
            fetchHighlightList()
            toggleModal()
        } catch (error) {
            console.error(error)
            setLoadingBtn(false)
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggleModal} size="xl" backdrop="static" centered>
            <ModalHeader toggle={toggleModal}>Add product</ModalHeader>
            <ModalBody>
                <div className="d-flex justify-content-between flex-wrap">
                    <div className="filter d-flex align-items-center flex-wrap">
                        <div className="form-group SearchProductMockup d-flex align-items-center mb-0">
                            <input
                                type="text"
                                className="form-control"
                                name="title"
                                placeholder="Search for product line..."
                                onChange={handleFilter}
                            />
                            <i className="fas fa-search SearchIcon" />
                        </div>
                        <CategoriesSelector
                            className="CategoriesSelectorCustom"
                            isHideTitle={true}
                            multiSelect={false}
                            defaultSelectedOptions={{ value: '', label: 'Category select' }}
                            handleSelectCollection={handleSelectCategories} />
                    </div>
                    <div className="add-btn">
                        <button className="btn btn-primary px-4 my-3" disabled={loadingBtn} onClick={handleHighlightProduct}>
                            {loadingBtn && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                            Add {checkedProductIds.length > 0 ? `(${checkedProductIds.length})` : ""}
                        </button>
                    </div>
                </div>
                <ProductTable
                    loading={loading}
                    page={query.page}
                    pages={pages}
                    pageSize={query.limit}
                    products={products}
                    handleChangePage={handleChangePage}
                    checkedProductIds={checkedProductIds}
                    setCheckedProductIds={setCheckedProductIds}
                />
            </ModalBody>
        </Modal>
    )
}

export default AddHighlightProductModal
