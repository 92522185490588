import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import ListCountriesContainer from "./list-countries/ListCountriesContainer"
import {Modal, ModalHeader, ModalBody} from "reactstrap"
import InputSearchCountries from "./InputSearchCountries"

class ManageCountriesContainer extends Component {
    state = {
        showModal: false,
        search: "",
    }

    _handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    _updateSearchState = (value) => {
        this.setState({
            search: value.trim(),
        })
    }

    render() {
        const {showModal, search} = this.state
        const {selectedCountries, zoneCountries, onUpdateSelectedCountries, onSelectRestOfWorld} = this.props

        const {countries, restOfWorldAvailable: restOfWorld} = zoneCountries

        return (
            <div className="ManageCountriesContainer">
                <button type="button" className="btn btn-primary btn-sm" onClick={this._handleToggleModal}>Manage</button>

                {
                    countries !== undefined &&
                    <Modal isOpen={showModal} className="CountriesModal modal-lg modal-dialog-centered">
                        <ModalHeader>Manage countries and regions</ModalHeader>

                        <ModalBody>
                            <InputSearchCountries search={search} onUpdateSearchState={this._updateSearchState}/>

                            <ListCountriesContainer selectedCountries={selectedCountries}
                                                    countries={countries}
                                                    search={search}
                                                    restOfWorld={restOfWorld}
                                                    onUpdateTempSelectedCountries={this._updateTempSelectedCountries}
                                                    onToggleModal={this._handleToggleModal}
                                                    onUpdateSelectedCountries={onUpdateSelectedCountries}
                                                    onSelectRestOfWorld={onSelectRestOfWorld}/>
                        </ModalBody>
                    </Modal>
                }
            </div>
        )
    }
}

// ManageCountriesContainer.defaultProps = {}
//
// ManageCountriesContainer.propTypes = {}

export default ManageCountriesContainer
