import React, {memo, useMemo, useState} from 'react'
import Select from 'react-select'
import useFetch from '../../../../hooks/useFetch'
import {getListGroups} from '../../../../services/api/ClustersServices'
import PropTypes from 'prop-types'
import {Modal, ModalBody, ModalFooter} from 'reactstrap'

const SelectGroupForm = ({clusterId, isOpen, onToggle, onConfirm}) => {
    const [selectedGroup, setSelectedGroup] = useState(null)

    const {response} = useFetch({
        fetchFunc: () => getListGroups(clusterId),
        enabled: !!clusterId,
    })
    const groupOptions = useMemo(() => {
        if (!response?.groups) return []
        return response.groups.map((group) => ({
            label: group.namespace,
            value: group._id,
        }))
    }, [response])

    const handleSelectGroup = (group) => {
        setSelectedGroup(group)
    }

    const handleConfirm = () => {
        onConfirm(selectedGroup?.value)
    }

    return (
        <Modal isOpen={isOpen} toggle={onToggle}>
            <ModalBody>
                <h4>Select group</h4>
                <Select placeholder={'Select group'} options={groupOptions} onChange={handleSelectGroup} />
            </ModalBody>
            <ModalFooter>
                <button className={'btn btn-secondary'} onClick={onToggle}>
                    Cancel
                </button>
                <button className={'btn btn-primary'} onClick={handleConfirm}>
                    Ok
                </button>
            </ModalFooter>
        </Modal>
    )
}

SelectGroupForm.propTypes = {
    clusterId: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
}

export default memo(SelectGroupForm, (prevProps, nextProps) => {
    return prevProps.clusterId === nextProps.clusterId && prevProps.isOpen === nextProps.isOpen
})
