import React, {useRef, useState} from 'react'
import axios from 'axios'
import {toaster} from '../../shared/PFToast'
import {restoreDatabaseV2} from '../../../services/api/StoreServices'
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap'
import {uploadFileDatabase} from '../../../services/api/StoreUploadServices'

const ButtonRestoreDatabasePopup = (props) => {
    const {isOpen, toggleModal, setDisabledRestore} = props
    const [isCustom, setIsCustom] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [customDumpDatabase, setCustomDumpDatabase] = useState('')
    const [errors, setErrors] = useState({})
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState('')
    const inputRef = useRef()

    const changeDump = (e) => {
        const {value} = e.target
        setCustomDumpDatabase(value)
    }

    const _handleCheckCustom = (e) => {
        const {checked} = e.target
        setIsCustom(checked)
    }

    const _handleClickOk = () => {
        console.log(isCustom)
    }

    const onUploadFile = () => {
        inputRef.current.click()
        inputRef.current.value = ''
    }

    const onChangeFile = async (event) => {
        setFile(event.target.files[0])
        setFileName(event.target.files[0].name)
    }

    const _handleRestoreDatabaseCustom = async () => {
        if(!file) return setErrors({...errors, file: 'Please choose file'})
        if(!customDumpDatabase) return setErrors({...errors, customDumpDatabase: 'Please input custom dump database'})
        try {
            setIsLoading(true)
            const { store } = props
            const { _id: storeId } = store
            const { data, success, message } = await uploadFileDatabase()
            setIsLoading(false)
            if (!success) {
                throw new Error(message)
            } else {
                try {
                    setIsLoading(true)
                    const {publicUrl, uploadUrl} = data
                    const formData = new FormData()
                    formData.append('file', file)
                    //const response = await uploadFileToEndpoint(uploadUrl, formData)
                    const axiosResponse = await axios.put(uploadUrl, {
                        data: file,
                    }, {
                        headers: {
                            'Content-Type': 'application/octet-stream'
                        }
                    });
                    setIsLoading(false)
                    if(axiosResponse.status === 200) {
                        try {
                            setIsLoading(true)
                            const { store } = props
                            const { _id: storeId } = store
                            const payload = {
                                customDumpFileUrl: publicUrl,
                                customDumpDatabase: customDumpDatabase
                            }
                            const { data, success, message } = await restoreDatabaseV2(storeId, payload)
                            setIsLoading(false)
                            if (!success) {
                                throw new Error(message)
                            } else {
                                const {error = []} = data
                                const isError = error.find(item => item.storeId === storeId)
                                if(isError !== undefined) {
                                    toaster({
                                        type: 'error',
                                        message: isError.reason,
                                        duration: 3000,
                                    })
                                } else {
                                    setDisabledRestore()
                                    toggleModal()
                                    toaster({
                                        type: 'success',
                                        message: 'The change has been successfully added to the queue and will take 40 minutes to complete.',
                                        duration: 3000,
                                    })
                                }
                            }
                        } catch (error) {
                            setIsLoading(false)
                            alert(error.message)
                        }
                    } else {
                        toaster({
                            type: 'error',
                            message: 'Upload file error',
                            duration: 3000,
                        })
                    }
                } catch (error) {
                    setIsLoading(false)
                    alert(error.message)
                }
            }
        } catch (error) {
            setIsLoading(false)
            alert(error.message)
        }
    }

    const _restoreDatabaseNotCustom = async () => {
        try {
            setIsLoading(true)
            const { store } = props
            const { _id: storeId } = store
            const { data, success, message } = await restoreDatabaseV2(storeId, null)
            setIsLoading(false)
            if (!success) {
                throw new Error(message)
            } else {
                const {error = []} = data
                const isError = error.find(item => item.storeId === storeId)
                if(isError !== undefined) {
                    toaster({
                        type: 'error',
                        message: isError.reason,
                        duration: 3000,
                    })
                } else {
                    setDisabledRestore()
                    toggleModal()
                    toaster({
                        type: 'success',
                        message: 'The change has been successfully added to the queue and will take 40 minutes to complete.',
                        duration: 3000,
                    })
                }
            }
        } catch (error) {
            setIsLoading(false)
            alert(error.message)
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            className={`ConfirmModal modal-dialog-centered`}
            toggle={toggleModal}
        >
            <ModalHeader toggle={toggleModal}>Restore Database</ModalHeader>
            <ModalBody>
                <input id="useCustom" onClick={_handleCheckCustom} type="checkbox"/> <label htmlFor="useCustom">Use custom dump file</label>
                {isCustom && (
                    <>
                        <h6 className="mt-3">Custom dump database</h6>
                        <Input type="text" onChange={changeDump}/>
                        {errors?.file && <div className="text-danger">{errors?.file}</div> }
                        {errors?.customDumpDatabase && <div className="text-danger">{errors?.customDumpDatabase}</div> }
                        <h6 className="mt-3">Please load file</h6>
                        <label className="btn btn-sm btn-info cursor-pointer mb-0 ButtonImport" onClick={onUploadFile}>
                            <i className="ti-upload mr-2" />
                            <span className="float-none">Upload</span>
                        </label>
                        {fileName ? fileName : <></>}
                    </>
                )}
                <input type="file" onChange={onChangeFile} hidden ref={inputRef} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={toggleModal} className='btn-default'>
                    Cancel
                </Button>
                <Button onClick={isCustom ? _handleRestoreDatabaseCustom : _restoreDatabaseNotCustom} disabled={isLoading}>
                    {
                        isLoading && <Spinner color='white' size='sm' className='mr-2'/>
                    }
                    Restore
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ButtonRestoreDatabasePopup
