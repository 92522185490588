import React, {Component} from 'react'
import {changeStoreNote} from "../../../services/api/StoreServices"
import PropTypes from 'prop-types'


class StoreNote extends Component {
    state = {
        note: this.props.storeDetails.note || '',
        saving: false,
        error: "",
    }

    _handleChangeStoreNote = async () => {
        try {
            const {storeDetails} = this.props
            const {_id: storeId} = storeDetails
            const {note: newNote} = this.state
            this.setState({
                saving: true,
            })
            const {success, message, data} = await changeStoreNote(storeId, {
                note: newNote,
            })

            if (!success) {
                return this.setState({
                    error: message,
                    saving: false,
                })
            }

            return this.setState({
                error: "",
                saving: false,
                note: data.note,
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })
        }
    }

    _delaySaveNote = () => {
        if (this._timeOut) clearTimeout(this._timeOut)
        this._timeOut = setTimeout(() => {
            this._handleUpdateOrderNote()
        }, 1000)
    }

    _handleChangeTextarea = e => {
        const {value} = e.target

        this.setState({
            note: value,
        })
    }

    _handleSubmit = e => {
        e.preventDefault()

        this._handleChangeStoreNote()
    }

    render() {
        const {note, saving} = this.state

        return (
            <div className="StoreNote">
                <div className='card Note'>
                    <div className='card-header font-weight-bold'>Note</div>

                    <div className='card-body'>
                        <form onSubmit={this._handleSubmit}>
                            <div className="form-group mb-0">
                                <textarea value={note} className="form-control" placeholder="Enter your note..."
                                          rows="4"
                                          onChange={this._handleChangeTextarea}/>

                                <div className="text-right mt-3">
                                    {
                                        saving ?
                                            <button className="btn btn-primary btn-sm disabled">Saving...</button>
                                            :
                                            <button className="btn btn-primary btn-sm">Save note</button>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

StoreNote.propTypes = {
    storeDetails: PropTypes.object.isRequired,
    note: PropTypes.array,
}

export default StoreNote
