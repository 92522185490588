import React, {Component} from 'react'
import PubSub from '../../GlobalSettingBroker'
import PFConfirmModal from '../../../shared/PFConfirmModal'
import {createItemGlobalSetting} from '../../../../services/api/StoreServices';

const ModalCreate = (props) => {
    const _handleChange = e => {
        props.onChangeSetting(e.target.name, e.target.value)
    }

    return (
        <div>
            <div className='form-group mb-2'>
                <label>Key Name :</label>
                <input type="text" name='key' required className='form-control' onChange={_handleChange}/>
            </div>
            <div className='form-group mb-2'>
                <label>Value :</label>
                <input type="text" name='value' required className='form-control' onChange={_handleChange}/>
            </div>
        </div>
    )
}

class CreateGlobalSetting extends Component {
    _formData = {
        key: '',
        value: '',
    }

    _onchangeInput = (name, value) => {
        this._formData[name] = value
    }

    _onValidateForm = () => {
        const {key = '', value = ''} = this._formData

        if (key.trim() === '') {
            throw new Error('Key is required')
        }

        if(value.trim() === '') {
            throw new Error('Value is required')
        }
    }

    _doAction = async () => {
        const key = this._formData.key
        const value = this._formData.value

        this._onValidateForm()

        const {success, message} = await createItemGlobalSetting(key, value)

        if (!success) {
            throw new Error(message)
        }

        this._formData = {}

        return {
            data: 'Success',
            onSuccess: this._onFetchSettings(),
            hideAfterSuccess: true,
        }
    }

    _handleAfterCloseModal = () => {
        this._formData = {}
    }

    _onFetchSettings = () => {
        PubSub.publish('STORE_FETCH_GLOBAL_SETTINGS')
    }

    render() {
        return (
            <span className="CreateSetting">
                <PFConfirmModal
                    title='Create Global Setting'
                    description={<ModalCreate onChangeSetting={this._onchangeInput}/>}
                    onAfterClose={this._handleAfterCloseModal}
                >
                    {
                        confirm => {
                            return (
                                <button
                                    className='btn btn-primary'
                                    type='button'
                                    onClick={confirm(this._doAction)}
                                >
                                    <i className='fas fa-plus mr-2'/>
                                    Create Setting
                                </button>
                            )
                        }
                    }
                </PFConfirmModal>
            </span>
        );
    }

}

export default CreateGlobalSetting
