import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"
import classNames from 'classnames'

class BackNavigationButton extends Component {
    render() {
        const {to, text, className} = this.props

        return (
            <div className={classNames("BackNavigationButton BackWrapper", className)}>
                <Link className="nav-link BackButton" to={to}>
                    <i className="fas fa-chevron-left"/>
                    <span>{text}</span>
                </Link>
            </div>
        )
    }
}

BackNavigationButton.defaultProps = {
    className: ''
}

BackNavigationButton.propTypes = {
    className: PropTypes.string,
    to: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
}

export default BackNavigationButton
