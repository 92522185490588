import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import humanizeTime from '../../../../../helpers/time/humanizeTime'

class RenderTableRow extends Component {
    _validateDate = date => {
        const momentTime = moment(date)
        return humanizeTime(momentTime)
    }

    render() {
        const {item} = this.props
        const {created_at} = item
        const time = this._validateDate(created_at)

        return (
            <tr>
                <td>{item.fileName || ''}</td>
                <td>{item.importBy || ''}</td>
                <td>{time || ''}</td>
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

export default RenderTableRow
