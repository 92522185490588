import React, {Component} from 'react'
import PropTypes from 'prop-types'
import IndexColumn from './columns/IndexColumn'
import StoreNameColumn from './columns/StoreNameColumn'
import StatusColumn from './columns/StatusColumn'
import ActivateDateColumn from './columns/ActivateDateColumn'

class RenderTableRow extends Component {
    render() {
        const {item, page, limit, index} = this.props
        const {level, variantSku, catalogSku, messageCondition, name} = item
        const catalog = level === 'variant' ? variantSku : catalogSku
        const tableIndex = limit * (page - 1) + 1 + index

        return (
            <tr>
                <IndexColumn index={tableIndex} />
                <StoreNameColumn item={item} />
                <td>{name || ''}</td>
                <td>{messageCondition || ''}</td>
                <td>{catalog || ''}</td>
                <td>{level || ''}</td>
                <StatusColumn item={item} />
                <ActivateDateColumn item={item} />
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

export default RenderTableRow
