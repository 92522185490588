import React, {Fragment} from 'react'
import PFTablePlaceholder from '../../../shared/PFTablePlaceholder'
import ObjectInspector from 'react-object-inspector'
import classNames from 'classnames'
import moment from 'moment'
import {cancelDatabaseJob} from '../../../../services/api/DatabaseJobsServices'
import {toaster} from '../../../shared/PFToast'
import {confirmModal} from '../../../shared/PFConfirmModalV2'

const TableBody = (props) => {
    const {items, loading, fetchDeposits} = props

    const _handleClickCancelJob = (id) => {
        confirmModal({
            confirmText: 'Submit',
            cancelText: 'Cancel',
            confirmColor: 'danger',
            title: 'Cancel job',
            message: (
                <span>
                    Are you sure you want to cancel job?
                </span>
            ),
            onOk: () => cancelJob(id),
        })
    }

    const cancelJob = async (id) => {
        try {
            const {success, message} = await cancelDatabaseJob(id)
            if(!success) return toaster({type: 'error', message: message, duration: 6000})
            fetchDeposits()
            toaster({type: 'success', message: 'Cancel job successfully!', duration: 6000})
        } catch (e) {
            toaster({type: 'error', message: e.message, duration: 6000})
        }
    }

    return (
        <tbody className="TableLoading">
            {loading ? (
                <PFTablePlaceholder columnQuantity={10} />
            ) : items.length === 0 ? (
                <tr>
                    <td colSpan={10}>
                        <i>No results</i>
                    </td>
                </tr>
            ) : (
                <Fragment>
                    {items.map((deposit, index) => (
                        <tr
                            key={index}
                            className={classNames({
                                DepositDuplicate:
                                    deposit.status === 'pending' && deposit.approvedTimes && deposit.approvedTimes > 0,
                            })}
                        >
                            <td>{deposit?._id || ''}</td>
                            <td>{deposit?.type || ''}</td>
                            <td>
                                <div>{deposit?.store?.name || ''}</div>
                                <div>{deposit?.store?.slug || ''}</div>
                                <div>{deposit?.store?._id || ''}</div>
                            </td>
                            <td>{deposit?.mongodb_resource?.name}</td>
                            <td className={classNames('StatusColumn', deposit?.status)}>
                                <span className='badge badge-secondary'>{deposit?.status || ''}</span>
                            </td>
                            <td>{deposit?.created_at && moment(deposit?.created_at).format('DD/MM/YYYY')}</td>
                            <td>{deposit?.last_suspend_at && moment(deposit?.last_suspend_at).format('DD/MM/YYYY')}</td>
                            <td>{deposit?.updated_at && moment(deposit?.updated_at).format('DD/MM/YYYY')}</td>
                            <td>{deposit?.meta && <ObjectInspector depth={0} path="root.value" data={deposit?.meta} />}</td>
                            <td>{deposit?.trigger_by}</td>
                            <td className="text-center text-danger cursor-pointer">
                                {(deposit?.status === 'pending' || deposit?.status === 'processing') && (
                                    <i onClick={() => _handleClickCancelJob(deposit?._id)} className="fas fa-ban"></i>
                                )}
                            </td>
                        </tr>
                    ))}
                </Fragment>
            )}
        </tbody>
    )
}

export default TableBody

