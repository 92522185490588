import React, {Component} from 'react'
import PropTypes from 'prop-types'


class ModeColumn extends Component {
    render() {
        const {mode} = this.props.item
        const vMode = mode || 'normal'


        return (
            <td className="ModeColumn">
                {vMode}
            </td>
        )
    }
}

ModeColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ModeColumn
