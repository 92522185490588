import React, {Fragment, useState} from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap'
import {importProductLineMapping} from '../../../services/api/ProductLinesServices'
import {toaster} from '../../shared/PFToast'
import pluralize from '../../../helpers/common/pluralize'

const ProductLineMappingModal = (props) => {
    const {isOpen, toggle, refetch} = props

    const [url, setUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const [dataMapping, setDataMapping] = useState(null)

    const _onChange = (event) => {
        const {value} = event.target
        setUrl(value)
    }

    const _handleImport = async () => {
        try {
            setDataMapping(null)
            setLoading(true)
            const payload = {
                spreadsheetId: url,
            }
            const {success, data, message} = await importProductLineMapping(payload)
            if (!success) {
                throw new Error(message)
            }
            setDataMapping(data)
            refetch()
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
            <ModalHeader toggle={toggle}>Import Product Line Mapping</ModalHeader>
            <ModalBody>
                <Input onChange={_onChange} placeholder="Input product line mapping url" />
                <div className="ImportNotification mt-3">
                    {dataMapping && (
                        <Fragment>
                            <p className="mb-0">
                                <b className="text-success">
                                    {pluralize(dataMapping?.map_success.length, 'item', 's')}
                                </b>{' '}
                                has been imported success
                                {dataMapping?.map_error.length > 0 && (
                                    <span>
                                        ,{' '}
                                        <b className="text-danger">
                                            {pluralize(dataMapping?.map_error.length, 'item', 's')}
                                        </b>{' '}
                                        has been imported fail
                                    </span>
                                )}
                            </p>
                            {dataMapping?.map_error.length > 0 && (
                                <div className="ErrorMapping table-responsive">
                                    <table class="table">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Product Type</th>
                                                <th>Sheet Name</th>
                                                <th>Reason</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataMapping?.map_error.map((item) => (
                                                <tr key={item._id}>
                                                    <td>{item.product_type}</td>
                                                    <td>{item.sheetName}</td>
                                                    <td>{item.reason || '-'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </Fragment>
                    )}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggle}>
                    Close
                </Button>{' '}
                <Button color="primary" onClick={_handleImport} disabled={loading || !url.length}>
                    {loading && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                    Import
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ProductLineMappingModal

