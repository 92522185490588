import React, {Component} from 'react'
import moment from "moment"
import humanizeTime from "../../../../../helpers/time/humanizeTime"
import WithStoreSettings from "../../../../shared/WithStoreSettings"
import PropTypes from 'prop-types'

class TransactionOutDate extends Component {
    _validateDate = () => {
        const {created, store} = this.props
        const timezone = store.getTimezone()
        const momentTime = moment(created).utcOffset(timezone)

        return humanizeTime(momentTime)
    }

    render() {
        const time = this._validateDate()

        return (
            <td className="TransactionOutDate">
                {time}
            </td>
        )
    }
}

TransactionOutDate.propTypes = {
    store: PropTypes.object.isRequired,
    created: PropTypes.string.isRequired,
}

export default function (props) {
    return (
        <WithStoreSettings>
            {
                store => {
                    return <TransactionOutDate store={store} {...props} />
                }
            }
        </WithStoreSettings>
    )
}
