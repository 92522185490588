import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {createStoreSubscription, getPlans} from '../../../services/api/PlanServices'
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';


class StorePlan extends Component {
    state = {
        plans: [],
        openModal: false,
        selectStore: '',
        loading: false,
    }

    componentDidMount() {
        return this._fetchPlans()
    }

    _fetchPlans = async () => {
        try {
            const {success, data, message} = await getPlans()
            if (success)
                return this.setState({
                    plans: data,
                })
            alert(message)
        } catch (e) {
            alert(e.message || e)
        }
    }
    _onToggle = (e) => {
        const {openModal} = this.state
        const {value} = e.target

        this.setState({
            openModal: !openModal,
            selectStore: value,
            loading: false,
        })
    }
    _onChangePlan = async () => {
        const {selectStore} = this.state
        const {storeDetails} = this.props
        this.setState({
            loading: true,
        })
        try {
            const {success, data, message} = await createStoreSubscription(storeDetails._id, selectStore)
            if (!success) throw new Error(message)
            const {subscription} = data
            const plan = this.state.plans.find(item => item._id === selectStore)
            this.setState({
                openModal: false,
            })

            return this.props.changeSubscription({...subscription, plan})
        } catch (e) {
            this.setState({loading: false}, () => {
                alert(e.message)
            })
        }
    }


    render() {
        const {storeDetails} = this.props
        if (!storeDetails) return null
        const {plans, openModal, selectStore, loading} = this.state

        const {subscription = {}, plan: planId = ''} = storeDetails
        const {plan = {}} = Object.assign({}, subscription)
        const {_id = ''} = Object.assign({}, plan)
        const subscriptionValue = subscription && _id ? _id : planId
        const nameChange = plans.find(item => item._id === selectStore) || {}

        return (
            <div className={`StorePlan mt-3 ${this.props.storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                <div className='list-group'>
                    <li className='list-group-item text--right'>
                        <h5>Subscription</h5>
                    </li>
                    <li className='list-group-item text--right'>
                        <label htmlFor='change-plan'>Change subscription</label>
                        <select
                            className='custom-select'
                            id='change-plan'
                            value={subscriptionValue}
                            onChange={this._onToggle}
                        >
                            <option value='' disabled>Select a plan</option>
                            {plans.map(plan => (
                                <option key={plan._id} value={plan._id}>
                                    {plan.title}
                                </option>
                            ))}
                        </select>
                    </li>
                </div>

                <Modal isOpen={openModal} toggle={this._onToggle} className="RemoveModal">
                    <ModalHeader toggle={this._onToggle}>Confirm change subscription</ModalHeader>
                    <ModalBody>
                        Change subscription to
                        <span className='text-danger ml-2'>{nameChange.title || ''}</span>
                    </ModalBody>
                    <ModalFooter>
                        <button disabled={loading} className="btn btn-primary"
                                onClick={this._onChangePlan}>{loading ? 'Changing ...' : 'Change subscription'}</button>
                    </ModalFooter>
                </Modal>


                {!!subscriptionValue && (
                    <div className='list-group'>
                        <li className='list-group-item text--right'>
                            <span>Start</span>: <span>{moment(subscription.start).format('MMMM Do YYYY')}</span>
                        </li>
                        <li className='list-group-item text--right'>
                            <span>End</span>:{' '}
                            <span>{subscription.finish ? moment(subscription.finish).format('MMMM Do YYYY') : '--'}</span>
                        </li>
                        <li className='list-group-item text--right'>
                            Status: <span>{subscription.status}</span>
                        </li>
                    </div>
                )}
            </div>
        )
    }
}

StorePlan.propTypes = {
    changeSubscription: PropTypes.func.isRequired,
    storeDetails: PropTypes.object.isRequired,
}

export default StorePlan
