import React, {Component} from 'react'
import PropTypes from 'prop-types'
import RenderTableRow from './RenderTableRow'

class TableBody extends Component {
    render() {
        const {items, page, limit, loading} = this.props
        return (
            <tbody className='TableBody'>
            {
                !loading &&
                items.length === 0 ?
                    <tr className="OrdersTableNoResults">
                        <td colSpan="7"><i>No results</i></td>
                    </tr> :
                    items.map((item, index) => (
                        <RenderTableRow key={index} index={index} item={item} page={page} limit={limit}/>
                    ))
            }
            </tbody>
        )
    }
}

TableBody.propTypes = {
    items: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default TableBody
