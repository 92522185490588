import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ServiceName from "./data/ServiceName"
import axios from 'axios'

const _p = async () => {
    return new Promise(resolve => {
        setTimeout(resolve, 1000)
    })
}

class ServiceListRow extends Component {
    state = {
        loaded: false,
        stt:0,
        sttCompleted:false,
    }

    componentDidMount(){
        console.log("Stt Init")
        //this._makeRequest()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {service} = this.props

        if (service.needPing && !service.completed && !this.state.loaded) {
            return this._ping()
        }
    }

    _ping = async () => {
        this.setState({
            loaded: true,
        })

        try {
            await _p()
            const {index} = this.props
            console.log('Ping', index)
            await this._makeRequest()
            console.log(this.state.sttCompleted,"  ",this.state.stt)
            await this.props.onCompleted(index, {
                success: true,
                statusCode: this.state.stt,
            })

            this.setState({
            })
        } catch (e) {
            this.setState({
            })
        }
    }

    _makeRequest = async () => {
        const {storeDetails,service} = this.props
        const {url} = service
        const {backoffice_api_url} = storeDetails
        await axios.get(`${backoffice_api_url}${url}/heartbeat/ping`)
                                    .then(response => response.status)
                                    .then(status => this.setState({stt:status}))
                                    .catch(function (error) {
                                        console.log(error.request);
                                      });
        console.log("aaa")
        this.setState({sttCompleted:true})
    }

    render() {
        const {service} = this.props
        const {sttCompleted,stt} = this.state
        const spin = <i className="fas fa-circle-notch fa-spin text-muted"></i>
        const completed = <i className="far fa-check-circle text-success" color='blue'></i>
        const rejected = <i className="far fa-times-circle text-danger"></i>
        return (
            <tr className="ServiceListRow">
                <ServiceName service={service}/>
                <td>
                    {sttCompleted? stt === 0 ? "Unknow" : stt
                                : spin}
                </td>
                <td>
                    {sttCompleted ? stt === 0 ? rejected : completed
                                    : spin}
                </td>
            </tr>
        )
    }
}

ServiceListRow.propTypes = {
    index: PropTypes.number.isRequired,
    service: PropTypes.object.isRequired,
    storeDetails: PropTypes.object.isRequired,
    onCompleted: PropTypes.func.isRequired,
}

export default ServiceListRow
