import React from 'react'
import PropTypes from 'prop-types'
import BulkVariantEditModal from './BulkVariantEditModal'

const BulkVariantActions = (props) => {
    const {bulkEditActive, ...rest} = props

    return (
        <div className="BulkVariantActions">
            <BulkVariantEditModal {...rest} name='retail_price' isOpenBulkEdit={bulkEditActive === 'retail_price'}/>
            <BulkVariantEditModal {...rest} name='weight' isOpenBulkEdit={bulkEditActive === 'weight'}/>
        </div>
    )
}

BulkVariantActions.propTypes = {
    openBulkEditVariant: PropTypes.func.isRequired,
    bulkEditActive: PropTypes.bool.isRequired,
    onChangeBulkEditValue: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default BulkVariantActions
