import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StatusStyle from '../../../shared/StatusStyle'
import _ from 'lodash'
import moment from 'moment'
import humanizeTime from '../../../../helpers/time/humanizeTime'
import { Link } from 'react-router-dom'
import { Stepper, Step, Tooltip, StepLabel } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { retryJob } from '../../../../services/api/DeployService'

const styles = theme => ({
    stepIconRoot: {
        color: "grey"
    },
    stepIconActive: {
        color: "#3AAEC1 !important"
    },
    stepIconCompleted: {
        color: "#28A744 !important"
    },
    stepIconError: {
        color: "#DC3644 !important"
    }
});
class JobRow extends Component {
    state = {
        retrying: false,
    }

    _validateDate = time => {
        if (!time) return ''
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }

    _validStatus = item => {
        const status = _.get(item, 'status')

        if (status === 'processing') {
            const started_at = _.get(item, 'started_at')
            const diff = moment().diff(moment(started_at), 'minutes')
            if (diff > 30) {
                return 'error'
            }
        }

        return status
    }

    _isShowRetryButton = (item) => {
        const status = _.get(item, 'status')
        const started_at = _.get(item, 'started_at')
        const diff = moment().diff(moment(started_at), 'minutes')

        return (status === 'error') || (status === 'processing' && diff > 30)
    }

    _retryJob = async (job) => {
        try {
            this.setState({
                retrying: true,
            })

            await retryJob(job._id)
            return this.setState({
                retrying: false,
            })
        } catch (e) {
            return this.setState({
                retrying: false,
            })
        }
    }

    render() {
        const { retrying } = this.state
        const { classes, onClickStage, toggleReset, store } = this.props;
        const { item } = this.props
        const validDate = this._validateDate(_.get(item, 'created_at'))
        const { _id: storeId, slug, domain } = _.get(item, 'store', {})
        const pipelines = _.get(item, 'pipelines', [])

        return (
            <tr className='RenderTableRow'>
                <td>
                    <div><StatusStyle status={this._validStatus(item)} /></div>
                    <div><span>{validDate}</span></div>
                </td>
                <td>
                    <div>{_.get(item, 'name', 'deployment')}</div>
                    <Link to={`/a/stores/${storeId}`}>
                        <u><span>{`#${slug}: ${domain}`}</span></u>
                    </Link>
                </td>
                <td><span>{_.get(item, 'trigger_by', 'system')}</span></td>
                <td>
                    {pipelines.map((pipeline, index) => (
                        <div key={index}>
                            <div><span>{_.get(pipeline, 'name')}</span></div>
                            <div className='d-flex justify-content-between'>
                                <Stepper connector={null} style={{ padding: "3px" }}>
                                    {_.get(pipeline, 'stages', []).map((stage) => (
                                        <Step key={_.get(stage, 'name')}
                                            style={{ padding: "2px" }}
                                            completed={stage.status === 'passed'}
                                            active={stage.status !== 'pending'}>
                                            <Tooltip title={_.get(stage, 'name')} placement="top" arrow={true}>
                                                <StepLabel
                                                    onClick={() => stage.status === 'failed' && onClickStage(item, pipeline, stage)}
                                                    error={stage.status === 'failed'}
                                                    StepIconProps={{
                                                        classes: {
                                                            root: classes.stepIconRoot,
                                                            active: classes.stepIconActive,
                                                            completed: classes.stepIconCompleted,
                                                            error: classes.stepIconError
                                                        }
                                                    }}
                                                />
                                            </Tooltip>
                                        </Step>
                                    ))}
                                </Stepper>
                                {
                                     item.is_restart !== false && <button type='button' className='btn btn-link p-0' onClick={() => toggleReset(pipeline)}>
                                        Reset
                                    </button>
                                }

                            </div>
                        </div>
                    ))}
                </td>
                <td>
                    {item.is_restart !== false && this._isShowRetryButton(item) && (
                        retrying ?
                            (
                                <button className='btn disabled' type='submit'>
                                    Retrying...
                                </button>
                            ) :
                            (
                                <button className='btn btn-sm ml-3'
                                    onClick={() => this._retryJob(item)}>
                                    <i className='fas fa-sync-alt' />
                                </button>
                            ))
                    }
                </td>
            </tr>
        )
    }
}

JobRow.propTypes = {
    item: PropTypes.object.isRequired,
    classes: PropTypes.object,
    onClickStage: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired
}

export default withStyles(styles)(JobRow)
