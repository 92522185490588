import React, {Component} from 'react'
import CatalogAttributeItem from './CatalogAttributeItem'

class CatalogAttributesList extends Component {
    render() {
        const {attributes} = this.props

        return (
            <div className="CatalogAttributesList">
                <ul>
                    {attributes.map((att, index) => {
                        return (
                            <CatalogAttributeItem key={index} attribute={att} attributeIndex={index} {...this.props} />
                        )
                    })}
                </ul>
            </div>
        )
    }
}

export default CatalogAttributesList

