import React, {Component} from 'react'
import StatusStyle from '../../../../shared/StatusStyle'
import PropTypes from "prop-types"
import {PopoverBody, UncontrolledPopover} from "reactstrap"
import classNames from "classnames"
import moment from "moment"

class StatusDeposit extends Component {

    _isEmailApproved = () => {
        const {deposit} = this.props
        const {approved_by} = deposit

        if (!approved_by || !approved_by._id || !approved_by._id.email) {
            return null
        }

        return approved_by._id.email
    }

    _isIPApproved = () => {
        const {deposit} = this.props
        const {approved_by} = deposit

        if (!approved_by || !approved_by.IP) {
            return null
        }

        return approved_by.IP
    }

    _isEmailReject = () => {
        const {deposit} = this.props
        const {rejected_by} = deposit

        if (!rejected_by || !rejected_by._id || !rejected_by._id.email) {
            return null
        }

        return rejected_by._id.email
    }

    _isIPReject = () => {
        const {deposit} = this.props
        const {rejected_by} = deposit

        if (!rejected_by || !rejected_by.IP) {
            return null
        }

        return rejected_by.IP
    }

    render() {
        const {deposit} = this.props
        const {status, _id, confirmed_at} = deposit
        const Date = moment(confirmed_at).format('DD/MM/YYYY, HH:mm:ss')

        const isEmailApproved = this._isEmailApproved()
        const isIPApproved = this._isIPApproved()

        const isEmailRejected = this._isEmailReject()
        const isIPRejected = this._isIPReject()

        const showTooltip = (status === 'approved' || status === 'rejected')

        return (
            <td className='text-center'>
                <div id={"Status-" + _id} className={classNames({"cursor-pointer": showTooltip})}>
                    <StatusStyle status={status}/></div>
                {
                    showTooltip
                    &&
                    <UncontrolledPopover className='shadow' placement='top' target={"Status-" + _id}
                                         trigger='hover'>
                        <PopoverBody className='text-center'>
                            {
                                status === 'approved' &&
                                <>
                                    {isEmailApproved && <span>Approved by <b>{isEmailApproved}</b><br/></span>}
                                    Time : <b>{Date}</b><br/>
                                    {isIPApproved && <span>IP: <b>{isIPApproved}</b></span>}
                                </>
                            }
                            {
                                status === 'rejected' &&
                                <>
                                    {isEmailRejected && <span>Rejected by <b>{isEmailRejected}</b><br/></span>}
                                    Time : <b>{Date}</b><br/>
                                    {isIPRejected && <span>IP: <b>{isIPRejected}</b></span>}
                                </>
                            }


                        </PopoverBody>
                    </UncontrolledPopover>
                }
            </td>
        )
    }
}

StatusDeposit.propTypes = {
    deposit: PropTypes.object.isRequired,
}

export default StatusDeposit