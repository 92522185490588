import React, {Component} from 'react'
import PropTypes from "prop-types"
import StatusDepositApproved from "./StatusDepositApproved"
import StatusDepositRejected from "./StatusDepositRejected"

class ActionDeposit extends Component {
    render() {
        const {deposit} = this.props

        return (
            <>
                {
                    !deposit.internal_transaction && deposit.status === 'pending' ?
                        <td>
                            <div className='d-flex align-items-center justify-content-center align-items-center'>
                                <StatusDepositApproved {...this.props}/>
                                <StatusDepositRejected {...this.props}/>
                            </div>
                        </td>
                        :
                        <td className='text-center'>-</td>
                }
            </>

        )
    }
}

ActionDeposit.propTypes = {
    deposit: PropTypes.object.isRequired,
    fetchDeposits: PropTypes.func.isRequired,
}

export default ActionDeposit