import { createStoreAPI } from './createStoreAPIService'

const api = createStoreAPI({ suffix: '/auto-mapping-rules' })

export const getListMappingRules = (params = {}) => {
    const defaultParams = {
        limit: 20,
        page: 1,
    }

    const newParams = { ...defaultParams, ...params }

    return api.makeAuthRequest({
        url: '',
        method: 'GET',
        params: newParams,
    })
}

export const importMappingRules = (data = {}, type = '') => {
    return api.makeAuthRequest({
        url: '?type=' + type,
        method: 'POST',
        data
    })
}

export const getImportTemplate = (params = {}) => {
    return api.makeAuthRequest({
        url: '/template',
        method: 'GET',
        params: params,
    })
}

export const getRuleExport = (params = {}) => {
    const defaultParams = {
        limit: 20,
        page: 1,
    }

    const newParams = { ...defaultParams, ...params }
    return api.makeAuthRequest({
        url: '/csv',
        method: 'GET',
        params: newParams,
    })
}

export const getImportLogs = (params = {}) => {
    return api.makeAuthRequest({
        url: '/histories',
        method: 'GET',
        params: params,
    })
}
