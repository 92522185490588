import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"

class NameColumn extends Component {
    render() {
        const {item} = this.props
        const {name, _id, kind} = item

        return (
            <td className='HostColumn'>
                {kind !== 'alb' ? <Link to={`/a/resource-settings/${_id}`}>
                    <span>{name}</span>
                </Link> : <span>{name}</span>}
            </td>
        )
    }
}

NameColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default NameColumn
