import React, {Component} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

class Input extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: props.value || '',
        }
        this.id = this._genUniqueId()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value && this.props.value !== this.state.value) {
            this.setState({
                value: this.props.value,
            })
        }
    }

    _genUniqueId = () => _.uniqueId('product-edit-input-')

    _changeInput = e => {
        const {onChange} = this.props
        this.setState({
            value: e.target.value,
        })
        typeof onChange === 'function' && this.props.onChange(e.target.value)
    }

    render() {
        const {label, textarea, disabled} = this.props
        const {value} = this.state
        const {id} = this

        const inputProps = {
            value,
            id,
        }

        return (
            <div className='ProductEditInput'>
                <div className='form-group'>
                    {label ? (
                        <label htmlFor={id} className='font-weight-500'>
                            {label}
                        </label>
                    ) : null}
                    {textarea ? (
                        <textarea className='form-control' {...inputProps} onChange={this._changeInput}
                                  disabled={disabled}/>
                    ) : (
                        <input className='form-control' {...inputProps} onChange={this._changeInput}
                               disabled={disabled}/>
                    )}
                </div>
            </div>
        )
    }
}

Input.defaultProps = {
    label: '',
    value: '',
    onChange: () => {
    },
    textarea: false,
    disabled: false,
}

Input.propsTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.object,
    textarea: PropTypes.bool,
    disabled: PropTypes.bool,
}

export default Input
