import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {createNewGroup, getListGroups} from '../../../services/api/ClustersServices'
import ListGroups from './ListGroups'
import {FormGroup, Input, Label, Popover, UncontrolledPopover} from 'reactstrap'

const CLUSTER_TYPE = {
    SEPARATED_DATABASE: {
        name: 'Separated database',
        value: 'separated_database',
    },
    SHARED_DATABASE: {
        name: 'Shared database',
        value: 'shared_database',
    },
}

class ClusterListGroups extends Component {
    state = {
        groups: [],
        loading: false,
        selectedClusterType: CLUSTER_TYPE.SEPARATED_DATABASE.value,
        isOpenSelectClusterType: false,
    }

    componentDidMount() {
        this._fetchListGroups()
    }

    _fetchListGroups = async () => {
        if (this.state.loading) return false

        this.setState({
            loading: true,
        })

        try {
            const {success, data, message} = await getListGroups(this.props.clusterId)

            if (!success) {
                throw new Error(message)
            }

            const {groups} = Object.assign({}, data)
            this.setState({
                groups: Array.isArray(groups) ? groups : [],
                loading: false,
            })
        } catch (error) {
            console.error(error)
            this.setState({
                loading: false,
            })
        }
    }

    _handleClickNew = async (e) => {
        e.preventDefault()

        const r = window.confirm('Are you sure want to crew new group?')
        if (!r) return
        await createNewGroup({
            clusterId: this.props.clusterId,
            clusterType: this.state.selectedClusterType,
        })
        this._handleToggleSelectClusterType()
        return this._fetchListGroups()
    }

    _handleSelectClusterType = (e) => {
        const {value} = e.target
        this.setState({
            selectedClusterType: value,
        })
    }

    _handleToggleSelectClusterType = () => {
        this.setState({
            isOpenSelectClusterType: !this.state.isOpenSelectClusterType,
        })
    }

    render() {
        const {loading, groups, isOpenSelectClusterType} = this.state
        return (
            <div className="ClusterListGroups">
                <h4 className="Header d-flex">
                    <div>Groups</div>
                    <button
                        id={'CreateNewGroupButton'}
                        disabled={loading}
                        // onClick={this._handleClickNew}
                        className="ml-auto btn btn-success btn-sm"
                    >
                        New
                    </button>
                    <Popover
                        target={'CreateNewGroupButton'}
                        trigger={'legacy'}
                        placement={'top'}
                        isOpen={isOpenSelectClusterType}
                        toggle={this._handleToggleSelectClusterType}
                    >
                        <div className="p-3">
                            <h6>Select group type</h6>
                            <FormGroup check>
                                <Label check for={CLUSTER_TYPE.SEPARATED_DATABASE.value}>
                                    <Input
                                        type={'radio'}
                                        name={'clusterType'}
                                        value={CLUSTER_TYPE.SEPARATED_DATABASE.value}
                                        id={CLUSTER_TYPE.SEPARATED_DATABASE.value}
                                        checked={
                                            this.state.selectedClusterType === CLUSTER_TYPE.SEPARATED_DATABASE.value
                                        }
                                        onChange={this._handleSelectClusterType}
                                    />
                                    Separated database
                                </Label>
                                <Label check for={CLUSTER_TYPE.SHARED_DATABASE.value}>
                                    <Input
                                        type={'radio'}
                                        name={'clusterType'}
                                        id={CLUSTER_TYPE.SHARED_DATABASE.value}
                                        value={CLUSTER_TYPE.SHARED_DATABASE.value}
                                        checked={this.state.selectedClusterType === CLUSTER_TYPE.SHARED_DATABASE.value}
                                        onChange={this._handleSelectClusterType}
                                    />
                                    Shared database
                                </Label>
                            </FormGroup>
                            <button className="btn btn-primary mt-3" onClick={this._handleClickNew} disabled={loading}>
                                Create
                            </button>
                        </div>
                    </Popover>
                </h4>

                <ListGroups clusterId={this.props.clusterId} groups={groups} onRefresh={this._fetchListGroups} />
            </div>
        )
    }
}

ClusterListGroups.propTypes = {
    clusterId: PropTypes.string.isRequired,
}

export default ClusterListGroups
