import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import SettingShippingZoneItem from './SettingShippingZoneItem'
import {deleteSettingProfilesShipping} from '../../../services/api/ShippingSettingsServices'
import {toaster} from '../../shared/PFToast'
import {confirmModal} from '../../shared/PFConfirmModalV2'


class SettingShippingListProduct extends Component {

    _handleClickDelete = () => {
        const {profiles} = this.props
        const {name} = profiles
        confirmModal({
            confirmText: 'Delete',
            cancelText: 'Cancel',
            confirmColor: 'danger',
            title: 'Delete profiles',
            message: <span>Are you sure you want to delete profiles <b>{name}</b>?</span>,
            onOk: this._deleteShippingProfiles,
        })
    }

    _deleteShippingProfiles = async () => {
        const {profiles, onGetProfilesSettings} = this.props
        const {_id} = profiles
        try {

            const {success, message} = await deleteSettingProfilesShipping(_id)
            if (!success) throw new Error(message)
            toaster({message: 'Deleted shipping profiles successfully!', type: 'success', duration: 5000})

            onGetProfilesSettings()

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            toaster({message: message ? message : e.message, type: 'error', duration: 5000})
        }
    }

    render() {
        const {profiles} = this.props
        const {zones, _id, is_general, skus} = profiles

        return (
            <div className='SectionInner mb-3 pt-3'>
                <div className='GeneralShippingTitle d-flex flex-wrap align-items-center justify-content-between mb-3'>
                    <div className='ProfilesTitle'>
                        {
                            profiles.name &&
                            <h6 className='font-weight-bold m-0'>{profiles.name}</h6>
                        }
                    </div>
                    <div className='ActionProfiles'>
                        <Link to={`/a/settings-shipping/profiles-edit/${_id}`} className='btn btn-primary btn-sm'>
                            <i className="far fa-edit mr-2"/>Edit</Link>
                        {
                            !is_general &&
                            <button className='btn btn-danger btn-sm ml-2' onClick={this._handleClickDelete}><i
                                className="fas fa-times mr-2"/>Delete
                            </button>
                        }
                    </div>
                </div>
                {
                    (Array.isArray(zones) && zones.length > 0 && zones)
                    &&
                    <div className='ShippingZone border p-3 rounded'>
                        <div
                            className='ShippingPageTop align-items-center d-flex flex-wrap justify-content-between'>
                            <h6 className='mb-0'>{`Shipping ${zones.length === 1 ? 'zone' : 'zones'}`}</h6>
                        </div>
                        {
                            zones.map((item, index) => {
                                const {rest_of_world = false, countries = [], name, _id: zoneId, rates} = item
                                return (
                                    <SettingShippingZoneItem key={`SettingShippingZoneItem_${index}`} rates={rates}
                                                             zoneId={zoneId} name={name}
                                                             countries={countries}
                                                             readOnly={true}
                                                             profilesId={_id}
                                                             rest_of_world={rest_of_world}/>
                                )
                            })
                        }
                    </div>
                }
                {
                    (Array.isArray(skus) && skus.length > 0)
                    &&
                    <div className='ShippingProductLine border rounded p-3 mt-3 table-responsive'>
                        <div className='ShippingPageTop align-items-center d-flex pb-3'>
                            <h6 className='mb-0'>Product line</h6>
                        </div>
                        <table className='table'>
                            <thead>
                            <tr>
                                <th className='table-col-30'>SKU</th>
                                <th className='table-col-40'>Title</th>
                                <th className='table-col-30'>Type</th>
                            </tr>
                            </thead>
                            <tbody className='ListProductLine'>
                            {
                                skus.map((item, index) => {
                                    return (
                                        <tr key={`ListProductLine_${index}`}>
                                            <td>{item.sku}</td>
                                            <td>{item.title}</td>
                                            <td>{item.type}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                }

            </div>
        )
    }

}

SettingShippingListProduct.propTypes = {
    profiles: PropTypes.object.isRequired
    ,
    onGetProfilesSettings: PropTypes.func.isRequired
    ,
}

export default SettingShippingListProduct
