import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"
import ClusterDetail from "./ClusterDetail"

class StoreDetail extends Component {
    render() {
        const {store} = this.props
        const {name, domain, slug, sub_domain, owner, _id: storeId, namespace, cluster} = store
        const {email, name: sellerName} = Object.assign({}, owner)

        return (
            <div className="StoreDetail mb-4">
                <h1>
                    <Link to={`/a/stores/${storeId}`}>{name}</Link>
                </h1>

                <div className="Domain">
                    <strong>Domain: </strong>
                    <span>{domain}</span>
                </div>

                <div className="Namespace">
                    <strong>Namespace: </strong>
                    <span>
                        <a target="_blank" rel="noopener noreferrer"
                           href={`https://console.cloud.google.com/kubernetes/workload?authuser=1&organizationId=816432422381&project=platform126&workload_list_tablesize=200&workload_list_tablequery=%255B%257B_22k_22_3A_22is_system_22_2C_22t_22_3A10_2C_22v_22_3A_22_5C_22false_5C_22_22_2C_22s_22_3Atrue%257D_2C%257B_22k_22_3A_22metadata%252Fnamespace_22_2C_22t_22_3A10_2C_22v_22_3A_22_5C_22${namespace}_5C_22_22%257D%255D`}>Google Cloud</a>
                            <i> </i><code>{namespace}</code>
                    </span>
                </div>

                <div className="Slug">
                    <strong>StoreID: </strong>
                    <code>{slug}</code>
                </div>

                {
                    !!sub_domain &&
                    <div className="Slug">
                        <strong>Subdomain: </strong>
                        <span>{sub_domain}</span>
                    </div>
                }

                <div className="Owner">
                    <strong>Owner: </strong>
                    <span>{sellerName} - {email}</span>
                </div>

                {
                    !!cluster && Object.keys(cluster).length &&
                    <ClusterDetail cluster={cluster}/>
                }
            </div>
        )
    }
}

StoreDetail.propTypes = {
    store: PropTypes.object.isRequired,
}

export default StoreDetail
