import React from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'

const WarehouseFeeModal = (props) => {
    const {isOpen, toggle, warehouseFee} = props

    const getPrice = (type, warehouse, fee) => {
        const arrPrices = warehouseFee[type] || []
        const itemFee = arrPrices.find(item => item.warehouse === warehouse && item.type === fee)
        const price = itemFee !== undefined ? itemFee?.price : ''
        return !!price ? `$${price}` : '-'
    }

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            style={{maxWidth: '1600px', width: '100%'}}
            centered>
            <ModalHeader toggle={toggle}>TikTok warehouse fee</ModalHeader>
            <ModalBody
                className="pb-0"
                style={{overflowX: 'auto'}}
            >
                <table className="table mb-0">
                    <thead className="thead-light">
                        <tr>
                            <th className="Name">Name</th>
                            <th>TX - Handling fee</th>
                            <th>TX - Additional fee</th>
                            <th>TX - Tracking fee</th>
                            <th>NJ - Handling fee</th>
                            <th>NJ - Additional fee</th>
                            <th>NJ - Tracking fee</th>
                            <th>CA - Handling fee</th>
                            <th>CA - Additional fee</th>
                            <th>CA - Tracking fee</th>
                            <th>TX1 - Handling fee</th>
                            <th>TX1 - Additional fee</th>
                            <th>TX1 - Tracking fee</th>
                            <th>VN - Handling fee</th>
                            <th>VN - Additional fee</th>
                            <th>VN - Tracking fee</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="Name">TikTok shipping</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'TX', 'handling_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'TX', 'additional_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'TX', 'tracking_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'NJ', 'handling_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'NJ', 'additional_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'NJ', 'tracking_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'CA', 'handling_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'CA', 'additional_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'CA', 'tracking_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'TX1', 'handling_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'TX1', 'additional_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'TX1', 'tracking_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'VN', 'handling_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'VN', 'additional_fee')}</td>
                            <td className="text-center">{getPrice('tiktok_shipping', 'VN', 'tracking_fee')}</td>
                        </tr>
                        <tr>
                            <td className="Name">Seller shipping</td>
                            <td className="text-center">{getPrice('seller_shipping', 'TX', 'handling_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'TX', 'additional_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'TX', 'tracking_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'NJ', 'handling_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'NJ', 'additional_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'NJ', 'tracking_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'CA', 'handling_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'CA', 'additional_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'CA', 'tracking_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'TX1', 'handling_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'TX1', 'additional_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'TX1', 'tracking_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'VN', 'handling_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'VN', 'additional_fee')}</td>
                            <td className="text-center">{getPrice('seller_shipping', 'VN', 'tracking_fee')}</td>
                        </tr>
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggle}>
                    Close
                </Button>{' '}
            </ModalFooter>
        </Modal>
    )
}

export default WarehouseFeeModal

