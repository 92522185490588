import React, {Component} from 'react'
import SettingCheckbox from '../../../setting/shared/SettingCheckbox'
import {randomID} from '../../../../helpers/common/randomID'

class CatalogAttributeItem extends Component {
    state = {
        toggleNewValue: false,
        status: this.props.attribute.status,
        marked_as_customized: this.props.attribute.customized,
        hide_on_storefront: this.props.attribute.hide_on_storefront || false,
    }

    _detectColorAtt = () => {
        const attribute = Object.assign({}, this.props.attribute)

        const {name = ''} = attribute

        const nameFormatted = name.trim().toLowerCase()

        return nameFormatted === 'color'
    }

    _changeSettings = (key, id, index) => (value) => {
        const {onChangeAttributesState} = this.props
        this.setState(
            {
                [key]: key === 'status' ? (value ? 'active' : 'inactive') : value,
            },
            () => onChangeAttributesState(index, key, value)
        )
    }

    render() {
        const {status, hide_on_storefront, marked_as_customized} = this.state
        const {attribute, attributeIndex} = this.props
        const {name: attName, type, values} = attribute
        const _id = randomID()

        return (
            <li className="CatalogAttributeItem mb-4">
                <div className="card">
                    <div className="card-header d-flex">
                        <h6 className="mb-0 font-weight-bold">{attName || ''}</h6>

                        <div className="ml-auto d-flex align-items-center">
                            <SettingCheckbox
                                label="Marked as customized"
                                itemId={`marked_as_customized_${_id}`}
                                checked={marked_as_customized}
                                disabled={true}
                            />
                            <div className="mr-3" />
                            <SettingCheckbox
                                label="Active attribute"
                                itemId={`attribute_enabled_${_id}`}
                                checked={status === 'active'}
                                onChange={this._changeSettings('status', _id, attributeIndex)}
                            />
                            <div className="mr-3" />

                            {type === 'color' && (
                                <SettingCheckbox
                                    label="Hide attribute storefront"
                                    itemId={`hide_on_storefront${_id}`}
                                    checked={hide_on_storefront}
                                    onChange={this._changeSettings('hide_on_storefront', _id, attributeIndex)}
                                />
                            )}
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-3">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Name"
                                    value={attName || ''}
                                    readOnly={true}
                                />
                            </div>

                            <div className="col-3">
                                <label>Type</label>
                                <select className="form-control" value={type || ''} placeholder="Type" readOnly={true}>
                                    <option value="" disabled>
                                        --Choose Type--
                                    </option>
                                    <option value="size">Size</option>
                                    <option value="color">Color</option>
                                    <option value="label">Label</option>
                                    <option value="pack">Pack</option>
                                </select>
                            </div>

                            <div className="col-6">
                                <label>Values</label>
                                <div className="AttributeValues">
                                    <div className="border rounded p-3">
                                        <ul className="ListAttributes p-0">
                                            {values.map((value, index) => {
                                                return (
                                                    <li
                                                        className="AttributeValue d-inline-block mr-2 mb-2 position-relative"
                                                        key={index}
                                                    >
                                                        <div className="Label badge badge-secondary p-2">
                                                            <div
                                                                id={`${attName.trim()}_${index}`}
                                                                className="d-flex flex-wrap"
                                                                style={{gap: '10px'}}
                                                            >
                                                                <>
                                                                    <span className="Name pr-2">{value.text}</span>
                                                                    <span className="Value">{value.code}</span>
                                                                </>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }
}

export default CatalogAttributeItem

