import React, {Component} from 'react'
import {Input} from 'reactstrap'
import {Link} from 'react-router-dom'
import TableContainer from './TableContainer'
import {getListClusters} from '../../../services/api/ClustersServices'

class ClustersPage extends Component {
    state = {
        items: [],
        loading: true,
        params: {
            page: 1,
            limit: 100,
            domain: '',
        },
        total: 0,
        totalPage: 1,
        err: '',
        name: '',
        group: '',
    }

    componentDidMount() {
        this._getListClusters()
    }

    _getListClusters = async () => {
        try {
            const {params, name, group} = this.state
            const requestData = {name, ...params, group}
            const {success, data, message} = await getListClusters(requestData)

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            const {pages: totalPage, clusters} = data

            this.setState({
                items: clusters,
                totalPage,
                loading: false,
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _handlePageChange = page => {
        const {params} = this.state
        this.setState(
            {
                params: {...params, page},
            },
            () => this._getListClusters()
        )
    }

    _handleSelectChange = (name, value) =>
        this.setState(
            {
                [name]: value,
            },
            () => this._getListClusters()
        )

    _handleInputChange = async e => {
        const name = e.target.value
        const timeOut = 500
        this.setState(
            {
                name,
                loading: true,
                totalPage: 1,
            },
            () => {
                clearInterval(this.typingTimer)
                this.typingTimer = setTimeout(this._getListClusters, timeOut)
            }
        )
    }

    render() {
        const {params, totalPage, items, loading, err} = this.state
        const {page, limit} = params
        return (
            <div className='ClustersPage'>
                <h2>Clusters</h2>
                <div className='ClustersPageInner SectionInner'>
                    <div className='TopBar'>
                        <div className='Action'>
                            <Link to='/a/clusters/create'>
                                <button className='btn CreateButton btn-primary'>
                                    <i className='fas fa-plus' /> Create Cluster
                                </button>
                            </Link>
                        </div>
                        <div className='SearchBar'>
                            <Input
                                onChange={this._handleInputChange}
                                type='text'
                                className='SearchInput'
                                placeholder='Enter name'
                            />
                            <i className='fas fa-search SearchIcon' />
                        </div>
                    </div>

                    {!!err && <span className='text-warning'>{err}</span>}
                    <TableContainer
                        page={page}
                        limit={limit}
                        totalPage={totalPage}
                        items={items}
                        loading={loading}
                        handlePageChange={this._handlePageChange}
                    />
                </div>
            </div>
        )
    }
}

export default ClustersPage
