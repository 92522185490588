import React from 'react'
import {settings, settingsForAdmin} from '../../../statics/settings'

const SettingsPage = (props) => {
    const {isSupperAdmin} = props

    const menus = isSupperAdmin ? settings.concat(settingsForAdmin) : settingsForAdmin

    const _pushPage = (index) => e => {
        e.preventDefault()
        if (menus[index]) return props.history.push(menus[index].location)
    }

    return (
        <section className="SettingsContainer">
            <h1 className="PageTitle">Settings</h1>

            <div className="SectionInner">
                <div className="card-body Card">
                    <div className="SettingsWrapper">
                        {
                            Array.isArray(menus) && menus.map((setting, index) => {
                                const {description = ''} = setting
                                return (
                                    <div className="SettingWrapper d-flex align-items-center" key={index}
                                         onClick={_pushPage(index)}>
                                        <div className="IconWrapper">
                                            <i className={setting.icon}/>
                                        </div>
                                        <div className="Info">
                                            <h6 className="Title">{setting.title}</h6>
                                            {
                                                description &&
                                                <div
                                                    className="Description text-muted fs-14 font-italic">{description}</div>
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SettingsPage
