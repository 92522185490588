import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ClusterListEnvs from "./envs/ClusterListEnvs"

class ClusterEnvsContainer extends Component {
    render() {
        const {clusterId} = this.props

        return (
            <div className="ClusterEnvsContainer">
                <ClusterListEnvs clusterId={clusterId}/>
            </div>
        )
    }
}

ClusterEnvsContainer.propTypes = {
    clusterId: PropTypes.string.isRequired,
}

export default ClusterEnvsContainer
