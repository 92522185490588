import React, {Component} from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import PropTypes from 'prop-types'
import OwnerSelect from './OwnerSelect'
import {getAccountDetail} from '../../../services/api/StoreAccountServices'
import {changeStoreOwner} from '../../../services/api/StoreServices'

class ChangeOwnerModal extends Component {
    state = {
        err: '',
        ownerId: '',
    }

    _changeOwner = async () => {
        const {ownerId} = this.state
        if (!ownerId) return
        const {storeId} = this.props
        const ownerDetails = await this._getAccountDetail(ownerId)
        const ownerDetail = Object.assign({}, ownerDetails[0])

        this.setState({
            err: '',
        })
        try {
            const {success, message} = await changeStoreOwner({
                storeId,
                ownerId,
            })
            if (!success) {
                return this.setState({
                    err: message,
                })
            }
            const {onChangeOwnerSuccess} = this.props
            return onChangeOwnerSuccess(ownerDetail)
        } catch (e) {
            this.setState({
                err: e.message || e,
            })
        }
    }

    _getAccountDetail = async ownerId => {
        try {
            const {success, data, message} = await getAccountDetail(ownerId)
            if (!success) {
                return this.setState({
                    err: message,
                })
            }
            const accountDetail = Object.assign({}, data)
            return accountDetail
        } catch (e) {
            this.setState({
                err: e.message || e,
            })
        }
    }

    _saveOwnerId = ownerId => {
        this.setState({
            ownerId,
        })
    }

    render() {
        const {showModal, toggleModal} = this.props
        const {err} = this.state
        return (
            <Modal
                isOpen={showModal}
                toggle={toggleModal}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <ModalHeader>Change Owner</ModalHeader>
                <ModalBody>
                    <OwnerSelect saveOwnerId={this._saveOwnerId} />
                    {!!err && <span className='text-warning'>{err}</span>}
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={this._changeOwner}>
                        Save
                    </Button>
                    <Button onClick={this.props.toggleModal}>Close</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

ChangeOwnerModal.propTypes = {
    storeId: PropTypes.string.isRequired,
    toggleModal: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    onChangeOwnerSuccess: PropTypes.func.isRequired,
}

export default ChangeOwnerModal
