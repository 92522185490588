import React, {Component} from 'react'
import PropTypes from 'prop-types'

class GroupColumn extends Component {
    render() {
        const {item} = this.props
        const {group} = item
        return (
            <td className='GroupColumn'>
                <span>{group}</span>
            </td>
        )
    }
}

GroupColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default GroupColumn
