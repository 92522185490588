import React, {Component} from 'react'
import PropTypes from "prop-types";

class KeyColumn extends Component {

    render() {
        const {item} = this.props

        return (
            <td className="KeyColumn">
                {item.key}
            </td>
        );
    }

}

KeyColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default KeyColumn