import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {EditorState, ContentState, convertToRaw} from 'draft-js'
import draftjsToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import {Editor} from 'react-draft-wysiwyg'

const DEFAULT_TOOLBAR = {
    options: ['inline', 'textAlign', 'list', 'link', 'image', 'blockType', 'fontSize'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
        bold: {
            className: 'EditorToolbarButton',
        },
        italic: {
            className: 'EditorToolbarButton',
        },
        underline: {
            className: 'EditorToolbarButton',
        },
        strikethrough: {
            className: 'EditorToolbarButton',
        },
    },
    textAlign: {
        inDropdown: false,
        options: ['left', 'center', 'right', 'justify'],
        left: {className: 'EditorToolbarButton'},
        center: {className: 'EditorToolbarButton'},
        right: {className: 'EditorToolbarButton'},
        justify: {className: 'EditorToolbarButton'},
    },
    list: {
        options: ['unordered', 'ordered'],
        unordered: {
            className: 'EditorToolbarButton',
        },
        ordered: {
            className: 'EditorToolbarButton',
        },
    },
    link: {
        options: ['link', 'unlink'],
        link: {
            className: 'EditorToolbarButton',
        },
        unlink: {
            className: 'EditorToolbarButton',
        },
    },
    blockType: {
        inDropdown: true,
        options: ['Normal', 'H4', 'H5', 'H6'],
    },
    fontSize: {
        options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    image: {
        className: 'EditorToolbarButton',
    },
}

class TextEditor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editorState: this._convertRawData(this.props.value || ''),
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.value && prevProps.value !== this.props.value) {
            this.setState({
                editorState: this._convertRawData(this.props.value || ''),
            })
        }

        if (prevProps.currentPreset !== this.props.currentPreset && !!this.props.currentPreset) {
            this.setState({
                editorState: this._convertRawData(this.props.value || ''),
            })
        }

        if (prevProps.isDiscarded !== this.props.isDiscarded && this.props.isDiscarded) {
            this.setState({
                editorState: this._convertRawData(''),
            })
        }
    }

    componentDidCatch(error, info) {
        console.log('Text Editor error:', error, info)
    }

    _convertRawData = rawData => {
        if (!rawData) return EditorState.createEmpty()
        const formatRawData = rawData.replace(/<figure>/g, '<div>').replace(/<\/figure>/g, '</div>')
        const boundedImgData = this._formatImgDiv(formatRawData)

        const blocksFromHTML = htmlToDraft(boundedImgData)

        if (blocksFromHTML.contentBlocks === null) return EditorState.createEmpty()
        const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)

        return EditorState.createWithContent(state)
    }

    _formatImgDiv = content => {
        const reg = /(<img[^>]*>)/g
        return content.replace(reg, '<p>$1</p>')
    }

    _convertEditStateToRawData = editorState => {
        const content = editorState ? editorState.getCurrentContent() : EditorState.createEmpty().getCurrentContent()

        return draftjsToHtml(convertToRaw(content))
    }

    _changeEditorState = editorState => {
        const html = this._convertEditStateToRawData(editorState)
        const {onChange} = this.props
        typeof onChange === 'function' && onChange(html)

        this.setState({
            editorState,
        })
    }

    _handleResetContent = (content = '') => {
        this.setState({
            editorState: this._convertRawData(content)
        })
    }

    render() {
        const {editorState} = this.state
        const {label, readOnly, toolbar, toolbarHidden} = this.props

        return (
            <div className='Editor'>
                {label && <label className='font-weight-500'>{label}</label>}
                <Editor
                    readOnly={readOnly}
                    toolbarHidden={toolbarHidden}
                    editorState={editorState}
                    onEditorStateChange={this._changeEditorState}
                    editorClassName='EditorApp'
                    toolbarClassName='EditorToolbar'
                    toolbar={toolbar}
                />
            </div>
        )
    }
}

TextEditor.defaultProps = {
    label: null,
    currentPreset: '',
    supportEmbed: false,
    toolbarHidden: false,
    readOnly: false,
    toolbar: DEFAULT_TOOLBAR,
}

TextEditor.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    currentPreset: PropTypes.string,
    supportEmbed: PropTypes.bool,
    toolbar: PropTypes.object,
    onChange: PropTypes.func.isRequired,
}

export default TextEditor
