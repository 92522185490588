import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ProductAttributeNewValue extends Component {
    state = {
        newAttribute: {
            name: "",
            value: "",
        },
        error: ''
    }

    _handleChangeAttributeState = (field, value) => {
        this.setState(({newAttribute}) => {
            return {
                newAttribute: {
                    ...newAttribute,
                    [field]: value,
                }
            }
        })
    }

    _handClickAdd = (e) => {
        e.preventDefault()

        const {values, onChangeAttributesState, attributeIndex} = this.props
        const {newAttribute} = this.state
        const {name, value} = newAttribute

        const existItem = values.find(v => v.name.trim() === name.trim() || v.value.trim() === value.trim())
        if (existItem) {
            return this.setState({
                error: 'Attribute is already exist!'
            })
        }

        const trimName = name ? name.trim() : ''
        const trimValue = value ? value.trim() : ''
        if (!trimName || !trimValue) return
        const newValues = [...values, {name: trimName, value: trimValue}]

        onChangeAttributesState(attributeIndex, "values", newValues)
        this.props.onAddNewAttributeSuccess()

        this.setState({
            newAttribute: {
                name: "",
                value: "",
            },
            error: ''
        })
    }

    render() {
        const {newAttribute, error} = this.state
        const {name, value} = newAttribute

        return (
            <div className="ProductAttributeNewValue mt-3">
                <h6>Add attribute values</h6>
                <form onSubmit={this._handClickAdd}>
                    <div className="row">
                        <div className="col-3">
                            <input type="text" className="form-control" placeholder="Name" value={name || ""}
                                   onChange={({target: {value}}) => {
                                       this._handleChangeAttributeState("name", value)
                                   }} autoFocus required/>
                        </div>

                        <div className="col-3">
                            <input type="text" className="form-control" placeholder="Value" value={value || ""}
                                   onChange={({target: {value}}) => {
                                       this._handleChangeAttributeState("value", value)
                                   }} required/>
                        </div>

                        <div className="col-6">
                            <button className="btn btn-success" type="submit">Add</button>
                        </div>
                    </div>
                    {
                        error ? <div className="row mt-1">
                            <div className="col-12 error-message">{error}</div>
                        </div> : null
                    }
                </form>
            </div>
        )
    }
}

ProductAttributeNewValue.propTypes = {
    onChangeAttributesState: PropTypes.func.isRequired,
    onAddNewAttributeSuccess: PropTypes.func.isRequired,
    values: PropTypes.array.isRequired,
    attributeIndex: PropTypes.number.isRequired,

}

export default ProductAttributeNewValue
