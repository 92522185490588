import React, {useState} from 'react'
import PropTypes from 'prop-types'
import ButtonRestoreDatabasePopup from './ButtonRestoreDatabasePopup'

const ButtonRestoreDatabase = (props) => {
    const {isRestore} = props
    const [isOpen, setIsOpen] = useState(false)
    const [disabledRestore, setDisabledRestore] = useState(false)

    const _toggleModal = () => {
        setIsOpen(!isOpen)
    }

    return (
        <div className='ButtonRestoreDatabase d-inline-block'>
            <button
                className='btn btn-primary btn-sm'
                onClick={_toggleModal}
                disabled={!isRestore || disabledRestore}
            >
                Restore
            </button>
            {isOpen && <ButtonRestoreDatabasePopup toggleModal={_toggleModal} isOpen={isOpen} setDisabledRestore={setDisabledRestore} {...props}/>}
        </div>
    )
}

ButtonRestoreDatabase.propTypes = {
    isRestore: PropTypes.bool.isRequired,
    changeStoreDetail: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
}

export default ButtonRestoreDatabase
