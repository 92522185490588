import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ClusterSettingsTable from "./table/ClusterSettingsTable"
import {getClusterSettings} from "../../../../services/api/ClustersServices"
import CreateClusterSetting from "./CreateClusterSetting"

class ClusterSettingContainer extends Component {
    state = {
        settings: null,
        error: "",
    }

    componentDidMount() {
        this._fetchClusterSettings()
    }


    _fetchClusterSettings = async () => {
        const {clusterId} = this.props
        try {
            const {data, success, message} = await getClusterSettings(clusterId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            return this.setState({
                settings: data,
                error: "",
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {settings} = this.state

        return (
            <div className="ClusterSettingContainer">
                <h3 className="">Cluster settings</h3>

                {
                    !!settings &&
                    <ClusterSettingsTable settings={settings} onFetchClusterSettings={this._fetchClusterSettings} {...this.props}/>
                }

                <CreateClusterSetting {...this.props} onFetchClusterSettings={this._fetchClusterSettings}/>
            </div>
        )
    }
}

ClusterSettingContainer.propTypes = {
    clusterId: PropTypes.string.isRequired,
}

export default ClusterSettingContainer
