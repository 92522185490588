import React, {Component} from 'react'
import RateTitleContainer from './title/RateTitleContainer'
import WeightRulesContainer from './weight-rules/WeightRulesContainer'
import PriceRulesContainer from './price-rules/PriceRulesContainer'
import RatePreferContainer from './prefer/RatePreferContainer'
import ManageRatesActionsContainer from './actions/ManageRatesActionsContainer'
import {createShippingRate} from '../../../../../services/api/ShippingSettingsServices'
import PerItemsRuleContainer from './per-items/PerItemsRuleContainer'
import getHistory from '../../../../../store/getHistory'
import RatePlanContainer from './plan/RatePlanContainer'

// import PropTypes from 'prop-types'

const _errorMessage = {
    weight_based: 'Each weight rule must have unique "From" value.',
    price_based: 'Each price rule must have unique "From" value.',
}

class ManageRatesContainer extends Component {
    state = {
        loading: false,
        error: '',
    }

    _ratesData = {
        title: '',
        subtitle: '',
        fulfillment_plan: 'standard',
        prefer: 'max',
        rules: [],
    }

    _handleUpdateRatesData = (field, data) => {
        this._ratesData[field] = data
    }

    _handleUpdateRateRules = (type, data) => {
        const findRule = this._ratesData.rules.find(rule => rule.type === type)

        if (findRule === undefined) {
            this._ratesData.rules = [...this._ratesData.rules, {type: type, items: data}]
        } else {
            this._ratesData.rules = this._ratesData.rules.map(rule => {
                if (rule.type === type) {
                    return {
                        ...rule,
                        items: data,
                    }
                }

                return rule
            })
        }
    }

    _handCheckDuplicateRule = (data) => {
        const {rules} = data

        for (let i = 0; i < rules.length; i++) {
            const {items, type} = rules[i]

            const fromValue = items.map(item => item.from)
            const isDuplicate = fromValue.some((item, idx) => {
                return fromValue.indexOf(item, idx + 1) !== -1
            })

            if (isDuplicate) {
                this.setState({
                    error: _errorMessage[type],
                }, () => {
                    window.scrollTo(0, 0)
                })

                return true
            }
        }

        return false
    }

    _handSubmitForm = e => {
        e.preventDefault()

        this._createShippingRate()
    }

    _createShippingRate = async () => {
        const {zoneId, profilesId} = this.props

        const {rules} = this._ratesData
        const filteredRulers = rules.filter(rule => rule.items.length > 0)

        const sendData = {
            ...this._ratesData,
            rules: filteredRulers,
        }

        const isDuplicate = this._handCheckDuplicateRule(sendData)

        if (isDuplicate) {
            return
        }

        this.setState({
            loading: true,
        })

        try {
            const {success, message} = await createShippingRate({zoneId, data: sendData})
            if (!success) {
                return this.setState({
                    loading: false,
                    error: message,
                }, () => {
                    window.scrollTo(0, 0)
                })
            }

            this.setState({
                loading: false,
                error: '',
            })

            const history = getHistory()
            history.push(`/a/settings/settings-shipping/${profilesId}/zones-edit/${zoneId}`)
        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            this.setState({
                loading: false,
                error: message ? message : e.message,
            }, () => {
                window.scrollTo(0, 0)
            })
        }
    }

    render() {
        const {zoneId} = this.props
        const {loading, error} = this.state

        return (
            <div className="ManageRatesContainer">
                {
                    error &&
                    <div className="ErrorMessage p-3 mb-3">
                        {error}
                    </div>
                }

                <form onSubmit={this._handSubmitForm}>
                    <div className="mb-4">
                        <RateTitleContainer onUpdateRatesData={this._handleUpdateRatesData}/>
                    </div>

                    <div className='mb-4'>
                        <RatePlanContainer onUpdateRatesData={this._handleUpdateRatesData}/>
                    </div>

                    <div className="mb-4">
                        <WeightRulesContainer onUpdateRateRules={this._handleUpdateRateRules}/>
                    </div>

                    <div className="mb-4">
                        <PriceRulesContainer onUpdateRateRules={this._handleUpdateRateRules}/>
                    </div>

                    <div className="mb-4">
                        <PerItemsRuleContainer onUpdateRateRules={this._handleUpdateRateRules}/>
                    </div>

                    <RatePreferContainer onUpdateRatesData={this._handleUpdateRatesData}/>

                    <div className="mt-4">
                        <ManageRatesActionsContainer zoneId={zoneId} loading={loading}/>
                    </div>
                </form>
            </div>
        )
    }
}

// ManageRatesContainer.defaultProps = {}

// ManageRatesContainer.propTypes = {}

export default ManageRatesContainer
