import React, {Component} from 'react'
import MappingRulesPage from './MappingRulesPage'

class MappingRulesPageContainer extends Component {
    render() {
        return (
            <div className='StoresPageContainer'>
                <MappingRulesPage/>
            </div>
        )
    }
}

export default MappingRulesPageContainer
