import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'

class TagEdit extends Component {
    state = {tag: '', err: null}

    _changeInput = (e) => {
        this.setState({
            tag: e.target.value,
            err: null,
        })
    }

    _submitTag = (e) => {
        e.preventDefault()
        const {tags} = this.props
        const {tag} = this.state

        const submitTags = _.uniq(tag.split(',')
            .map((item) => item.trim())
            .filter((item) => !tags.includes(item)))

        const newTags = [...tags, ...submitTags]
        this.setState({
            tag: '',
        })
        this.props.onChange(newTags)
    }

    _removeTag = (tag) => () => {
        const {tags} = this.props

        const newTags = tags.filter(item => item !== tag)
        this.props.onChange(newTags)
    }

    render() {
        const {tag, err} = this.state
        const {tags, editable} = this.props

        return <div className="TagEdit">
            <form
                className={classnames('form-group', {
                    'has-error': err,
                })}
                onSubmit={this._submitTag}
            >
                <label htmlFor="tag_edit">Tags:</label>
                <input
                    className="form-control"
                    id="tag_edit"
                    placeholder="Vintage, cotton, summer"
                    value={tag}
                    onChange={this._changeInput}
                    autoComplete="off"
                    disabled={!editable}
                />
                {(err) ? <small className="text-danger">{err}</small> : null}
            </form>
            {(tags && tags.length) ? <ul className="Tags">
                {tags.map((item, index) => <li className="Tag" key={index}>
                    <span>{item}</span>
                    {
                        editable &&
                        <span className="CloseButton" onClick={this._removeTag(item)}>
                            <i className="fas fa-times"/>
                        </span>
                    }
                </li>)}
            </ul> : null}
        </div>
    }
}

TagEdit.propTypes = {
    onChange: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    editable: PropTypes.bool.isRequired,
}

export default TagEdit
