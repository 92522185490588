import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ListSelectedItemProductLine extends Component {

    _deleteProductLineShipping = (id) => {
        const {productLineSelected, onChangeProductLineSelected} = this.props
        const productLineSelectedNew = productLineSelected.filter(product => product._id !== id)
        onChangeProductLineSelected(productLineSelectedNew)
    }

    render() {
        const {productLineSelected} = this.props

        return (
            <div className="ListItemProductLine table-responsive">
                {
                    productLineSelected.length > 0 && Array.isArray(productLineSelected)
                    &&
                    <table className="ListProductLine table table-striped table-hover mt-3">
                        <thead>
                            <th className='table-col-20'>SKU</th>
                            <th className='table-col-50'>Title</th>
                            <th className='table-col-20'>Type</th>
                            <th className='table-col-10 text-center'>Action</th>
                        </thead>
                        <tbody>
                        {
                            productLineSelected.map((item, index) => {
                                return (
                                    <tr key={`ListProductLine_${index}`}>
                                        <td>{item.sku}</td>
                                        <td>{item.title}</td>
                                        <td>{item.type}</td>
                                        <td className='text-danger text-center cursor-pointer'><i
                                            onClick={() => this._deleteProductLineShipping(item._id)} className='ti-close'/></td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                }
            </div>
        )
    }
}

ListSelectedItemProductLine.propTypes = {
    productLineSelected: PropTypes.array.isRequired,
    onChangeProductLineSelected: PropTypes.func.isRequired,
}

export default ListSelectedItemProductLine
