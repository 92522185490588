import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ClusterModal from './ClusterModal'
import {Link} from 'react-router-dom'
import SelectGroupForm from './SelectGroupForm'
import {assignGroupToStore} from '../../../../services/api/StoreServices'
import {toastError, toastSuccess} from '../../../amazon/helpers/ToastHelper'

class ClusterDetail extends Component {
    state = {
        showModal: false,
        showChangeGroupModal: false,
        cluster: this.props.cluster,
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (
            this.state.showModal !== nextState.showModal ||
            this.state.showChangeGroupModal !== nextState.showChangeGroupModal ||
            this.props.cluster !== nextProps.cluster
        )
    }

    _handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    _handleToggleChangeGroupModal = () => {
        this.setState(
            {
                showChangeGroupModal: !this.state.showChangeGroupModal,
            },
            () => {
                console.log('updated', this.state.showChangeGroupModal)
            },
        )
    }

    _handleUpdateCluster = (newCluster) => {
        this.setState({
            cluster: newCluster,
        })
    }

    _assignGroup = async (groupId) => {
        try {
            const {success} = await assignGroupToStore(this.props.storeId, groupId)
            if (!success) throw new Error('Assign group failed.')
            toastSuccess('Assign group successfully.')
            this.setState({
                showChangeGroupModal: false,
            })
        } catch (e) {
            console.log(e)
            toastError(e?.message || 'Assign group failed.')
        }
    }

    render() {
        const {showModal, showChangeGroupModal, cluster} = this.state
        const {name, _id: clusterId} = Object.assign({}, cluster)
        const {group} = this.props
        const {namespace} = Object.assign({}, group)
        const hasAssignedGroup = !!namespace

        return (
            <div className={`ClusterDetail ${this.props.storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                <div className="list-group">
                    <div className="list-group-item">
                        <h5>Cluster</h5>
                    </div>

                    <div className="list-group-item">
                        <div className="d-flex align-items-baseline">
                            <span>Name:</span>
                            {!!clusterId && (
                                <Link to={`/a/clusters/${clusterId}`} className="ml-3">
                                    {name}
                                </Link>
                            )}

                            <button className="btn btn-primary btn-sm ml-3" onClick={this._handleToggleModal}>
                                <i className="far fa-edit" />
                            </button>
                        </div>
                    </div>

                    {clusterId && (
                        <div className="list-group-item">
                            Group:{' '}
                            {hasAssignedGroup ? (
                                namespace
                            ) : (
                                <button className="btn btn-primary btn-sm" onClick={this._handleToggleChangeGroupModal}>
                                    Assign group
                                </button>
                            )}
                        </div>
                    )}
                </div>
                <SelectGroupForm
                    clusterId={this.state.cluster?._id}
                    isOpen={showChangeGroupModal}
                    onToggle={this._handleToggleChangeGroupModal}
                    onConfirm={this._assignGroup}
                />
                <ClusterModal
                    showModal={showModal}
                    currentCluster={cluster}
                    onToggleModal={this._handleToggleModal}
                    storeId={this.props.storeId}
                    onUpdateCluster={this._handleUpdateCluster}
                />
            </div>
        )
    }
}

ClusterDetail.propTypes = {
    group: PropTypes.object,
    cluster: PropTypes.object.isRequired,
    storeId: PropTypes.string.isRequired,
}

export default ClusterDetail
