import React, {Component} from 'react'
import PropTypes from 'prop-types'
import GroupItem from './GroupItem'

class ListGroups extends Component {
    render() {
        const {groups} = this.props

        return (
            <div className="ListGroups">
                <table className="table table-striped table-light">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Namespace</th>
                        <th>Created</th>
                        <th>Type</th>
                        <th>Cluster</th>
                        <th>Count stores</th>
                        <th>Status</th>
                        <th style={{width: '1px'}}>Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                    {groups.map((group, index) => {
                        return (
                            <GroupItem group={group} index={index} key={group._id} {...this.props}/>
                        )
                    })}
                    </tbody>
                </table>
            </div>
    )
    }
    }

    ListGroups.propTypes =
        {
            groups: PropTypes.array.isRequired
        }

    export default ListGroups

