import React, {Component} from 'react'

const _tableColumns = [
    'Index',
    'Name',
    'Kind',
    'Capacity',
    'Used',
    'Metadata',
    'Specs',
    'Status',
    'Created',
    'Actions'
]

class TableHeader extends Component {
    render() {
        return (
            <thead className='TableHeader thead-light'>
            <tr>
                {_tableColumns.map(column => (
                    <th scope='col' key={column}>
                        {column}
                    </th>
                ))}
            </tr>
            </thead>
        )
    }
}

export default TableHeader
