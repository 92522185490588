import React, {Component} from 'react'
import PropTypes from 'prop-types'

class VariantLimitSelector extends Component {
    _handleChange = e => {
        const {value} = e.target

        this.props.onChangeVariantsLimit(value)
    }

    render() {
        return (
            <div className='VariantLimitSelector'>
                <select className='form-control' defaultValue='20' onChange={this._handleChange}>
                    <option value='10'>10/page</option>
                    <option value='20'>20/page</option>
                    <option value='50'>50/page</option>
                    <option value='100'>100/page</option>
                </select>
            </div>
        )
    }
}

VariantLimitSelector.propTypes = {
    onChangeVariantsLimit: PropTypes.func.isRequired,
}

export default VariantLimitSelector
