import React, {Component} from 'react'

const _tableColumns = [
    '#',
    'Store Name',
    'Domain',
    'Mode',
    'Cluster',
    'Owner Email',
    'Subscription',
    'Tags',
    'Status',
    'Activated At',
    'Detail',
    'Actions'
]

class StoreTableHeader extends Component {
    render() {
        const { isSelectAll, handleChangeSelectAll } = this.props
        return (
            <thead className='thead-light'>
            <tr>
                <th>
                    <input type='checkbox' checked={isSelectAll} onChange={(e) => handleChangeSelectAll(e.target.checked)} />
                </th>
                {_tableColumns.map(column => (
                    <th scope='col' key={column}>
                        {column}
                    </th>
                ))}
            </tr>
            </thead>
        )
    }
}

export default StoreTableHeader
