import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, ButtonGroup} from 'reactstrap'
import classNames from 'classnames';

class PFFilterOptions extends Component {
    constructor(props) {
        super(props);

        const {defaultValue, options} = this.props

        this.state = {
            active: defaultValue || options[0].value || '',
        }
    }

    _renderLayoutButton = () => {
        return (
            <ButtonGroup>
                {
                    this.props.options.map(option => {
                        return <Button key={option.value} color='' outline>{option.name}</Button>
                    })
                }
            </ButtonGroup>
        )
    }

    _handleChange = value => {
        this.setState({
            active: value,
        }, () => {
            this.props.onChange(this.props.field, value)
        })
    }

    _renderLayoutSelect = () => {
        return (
            <select value={this.state.active} className='form-control'
                    onChange={e => this._handleChange(e.target.value)}>
                {
                    this.props.options.map(option => {
                        const {name, value} = option
                        return <option key={value} value={value}>{name}</option>
                    })
                }
            </select>
        )
    }

    _renderLayoutRadio = (selectedValue) => {
        const {field, store, options, ...rest} = this.props

        return (
            <ul className="RadioLayout Options" {...rest}>
                {
                    options.map((option, index) => {
                        return (
                            <li
                                key={`${field}_${index}`}
                                className={classNames("Option", {"Active": option.value === selectedValue})}
                                onClick={() => this._handleChange(option.value)}
                            >
                                {option.name}
                            </li>
                        )
                    })
                }
            </ul>
        )
    }

    _renderContent = () => {
        switch (this.props.layout) {
            case 'button':
                return this._renderLayoutButton()
            case 'radio':
                const selectedValue = this.props.value !== undefined ? this.props.value : this.state.active
                return this._renderLayoutRadio(selectedValue)
            case 'select':
                return this._renderLayoutSelect()
            default:
                return this._renderLayoutButton()
        }
    }

    render() {
        const content = this._renderContent()

        return (
            <div className='PFFilterOptions'>
                {content}
            </div>
        )
    }
}

PFFilterOptions.defaultProps = {
    layout: 'select',
}

PFFilterOptions.propTypes = {
    layout: PropTypes.oneOf(['button', 'select', 'radio']),
    options: PropTypes.array.isRequired,
    defaultValue: PropTypes.string,
    field: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default PFFilterOptions
