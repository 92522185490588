import React, { Fragment, useState } from 'react'
import PFTable from '../../shared/PFTable'
import PropTypes from 'prop-types'
import TableLoading from '../../shared/TableLoading'
import humanizeTime from '../../../helpers/time/humanizeTime'
import StatusStyle from '../../shared/StatusStyle'
import ModalCashbackHistoryDetail from '../components/ModalCashbackHistoryDetail'
import { actionCashbackRule } from '../../../services/api/BillingServices'
import { toaster } from '../../shared/PFToast'
import { confirmModal } from '../../shared/PFConfirmModalV2'
import { CASHBACK_RULE_HISTORY_ACTION } from '../constants'

const style = { minWidth: '86px' }

const CashbackRuleHistoryTable = (props) => {
    const [cashbackDetail, setCashbackDetail] = useState({
        isOpen: false,
        calculationId: '',
        data: [],
    })
    const { cashbackRuleHistories, loading, refetch } = props

    const onToggleCashbackDetail = (cashback) => {
        if (cashback) {
            setCashbackDetail((prev) => ({
                isOpen: !prev.isOpen,
                calculationId: cashback.calculation_id,
                data: cashback.cashback_calculation_jobs,
            }))
        } else {
            setCashbackDetail((prev) => ({ isOpen: !prev.isOpen, ...prev }))
        }
    }

    const getConfirmTextByAction = (action) => {
        let text = ''
        let color = ''
        switch (action) {
            case 'confirm':
                text = 'Approve'
                color = 'primary'
                break
            case 'retry':
                text = 'Retry'
                color = 'info'
                break
            case 'reject':
                text = 'Reject'
                color = 'danger'
                break
            default: break
        }
        return {
            text,
            color,
        }
    }

    const showConfirm = (action, cashbackId) => {
        const confirmText = getConfirmTextByAction(action).text
        const confirmColor = getConfirmTextByAction(action).color
        const title = `${getConfirmTextByAction(action).text} cashback`
        const message = (
            <span>Are you sure you want to {action === 'confirm' ? 'approve' : action} this cashback?</span>
        )
        return confirmModal({
            confirmText,
            confirmColor,
            title,
            message,
            onOk: () => onHandleActionCashback(action, cashbackId),
        })
    }

    const onHandleActionCashback = async (action, cashbackId) => {
        try {
            const { success, message } = await actionCashbackRule(action, cashbackId)
            if (!success) {
                throw new Error(message)
            }
            refetch()
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    return (
        <div className="CashbackRuleTable table-responsive">
            <ModalCashbackHistoryDetail cashbackDetail={cashbackDetail} toggle={onToggleCashbackDetail} />
            {
                <PFTable source={cashbackRuleHistories}>
                    {() => (
                        <Fragment>
                            <thead className="ProductMockupTableHead thead-light">
                                <tr>
                                    <th>ID</th>
                                    <th>User calculate</th>
                                    <th>Cashback at</th>
                                    <th>User approve</th>
                                    <th>Approve at</th>
                                    <th>Status</th>
                                    <th>File</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <Fragment>
                                {loading ? (
                                    <TableLoading columnQuantity={8} />
                                ) : Array.isArray(cashbackRuleHistories) && cashbackRuleHistories.length === 0 ? (
                                    <tbody>
                                        <tr>
                                            <td colSpan="8">
                                                <i className="text-muted">No results!</i>
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <tbody className="ProductMockupTableBody TableLoading">
                                        {Array.isArray(cashbackRuleHistories) &&
                                            cashbackRuleHistories.map((item, index) => {
                                                const {
                                                    _id,
                                                    calculation_id,
                                                    created_by = {},
                                                    created_at,
                                                    confirm_at,
                                                    confirm_by = {},
                                                    status,
                                                    summary_file_url,
                                                    total_cashback_file_url,
                                                } = item
                                                return (
                                                    <tr key={`cashback_rule_${index}`}>
                                                        <td>
                                                            <span
                                                                className="text-primary cursor-pointer"
                                                                onClick={() => onToggleCashbackDetail(item)}
                                                            >
                                                                {calculation_id}
                                                            </span>
                                                        </td>
                                                        <td>{created_by.email}</td>
                                                        <td>{humanizeTime(created_at)}</td>
                                                        <td>
                                                            {confirm_by && confirm_by.email ? confirm_by.email : '-'}
                                                        </td>
                                                        <td>{confirm_at ? humanizeTime(confirm_at) : '-'}</td>
                                                        <td>
                                                            <StatusStyle status={status} />
                                                        </td>
                                                        <td>
                                                            {summary_file_url && (
                                                                <div>
                                                                    <a href={summary_file_url} download>
                                                                        {summary_file_url.substring(
                                                                            summary_file_url.lastIndexOf('/') + 1
                                                                        )}
                                                                    </a>
                                                                </div>
                                                            )}
                                                            {total_cashback_file_url && (
                                                                <div>
                                                                    <a href={total_cashback_file_url} download>
                                                                        {total_cashback_file_url.substring(
                                                                            total_cashback_file_url.lastIndexOf('/') + 1
                                                                        )}
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                {status === 'done' && !confirm_at && (
                                                                    <button
                                                                        type="button"
                                                                        style={style}
                                                                        className="btn btn-primary text-center"
                                                                        onClick={() =>
                                                                            showConfirm(
                                                                                CASHBACK_RULE_HISTORY_ACTION.CONFIRM,
                                                                                _id
                                                                            )
                                                                        }
                                                                    >
                                                                        Approve
                                                                    </button>
                                                                )}
                                                                {status === 'failed' && (
                                                                    <button
                                                                        type="button"
                                                                        style={style}
                                                                        className="btn btn-info text-center"
                                                                        onClick={() =>
                                                                            showConfirm(
                                                                                CASHBACK_RULE_HISTORY_ACTION.RETRY,
                                                                                _id
                                                                            )
                                                                        }
                                                                    >
                                                                        Retry
                                                                    </button>
                                                                )}
                                                                {
                                                                    ((status === 'done' && !confirm_at) || status === 'failed') && <button
                                                                        type="button"
                                                                        style={style}
                                                                        className="btn btn-danger text-center ml-2"
                                                                        onClick={() =>
                                                                            showConfirm(
                                                                                CASHBACK_RULE_HISTORY_ACTION.REJECT,
                                                                                _id
                                                                            )
                                                                        }
                                                                    >
                                                                        Reject
                                                                    </button>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                )}
                            </Fragment>
                        </Fragment>
                    )}
                </PFTable>
            }
        </div>
    )
}

CashbackRuleHistoryTable.propTypes = {
    loading: PropTypes.bool,
    cashbackRules: PropTypes.array.isRequired,
    refetch: PropTypes.func,
}

export default CashbackRuleHistoryTable
