import React, {Component} from 'react'
import PropTypes from "prop-types"

class NoteDeposit extends Component {

    render() {
        const {note} = this.props
        return (
            <td>{note || '--'}</td>
        )
    }
}

NoteDeposit.propTypes = {
    note: PropTypes.string.isRequired,
}

export default NoteDeposit