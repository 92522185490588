import React from 'react'
import GlobalDepositPage from './GlobalDepositPage'

const GlobalDepositContainer = () => {

    return (
        <div className="GlobalDepositContainer">
            <GlobalDepositPage />
        </div>
    )
}

export default GlobalDepositContainer