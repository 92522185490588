import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

class AccountNameColumn extends Component {
    render() {
        const {item} = this.props
        const {name, _id, email} = item

        const getUserName = () => {
            const userName = email ? email.split('@')[0] : null
            return name || userName
        }

        return (
            <td className='AccountNameColumn'>
                <Link to={`/a/accounts/${_id}`}>{getUserName()}</Link>
            </td>
        )
    }
}

AccountNameColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default AccountNameColumn
