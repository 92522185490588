import React, {Component} from 'react'

class TableHeader extends Component {
    render() {
        return (
            <thead className='TableHeader'>
                <tr>
                    <th scope="col" className="colKey">Key</th>
                    <th scope="col">Value</th>
                    <th scope="col" className="colActions">Actions</th>
                </tr>
            </thead>
        )
    }
}

export default TableHeader
