import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import getHistory from "../../../store/getHistory";
import { createAffiliate, genAffiliateLink, getAffiliateById, getAffiliatesProfit } from "../../../services/api/AffiliateServices";
import { toaster } from "../../shared/PFToast";
import AffiliateSupplier from "./AffiliateSuppiler";
import AffiliateProfitTable from "./table/AffiliateProfitTable";
class AffiliateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            affiliateForm: {
                id: "",
                name: "",
                email: "",
                code: "",
                link: "",
                kol_profit_supplier: 'ALL',
                kol_profit: 0,
                kol_profit_days: 0,
                seller_discount_supplier: 'ALL',
                seller_discount: '',
                seller_discount_days: 0
            },
            params: {
                page: 1,
                pages: 1,
                total: 0,
                limit: 20,
                total_profit: 0
            },
            profits: [],
            id: this.props.match.params.id,
            loadingGenerate: false,
        };
    }

    _onNavigateList = () => {
        const history = getHistory();
        history.push("/a/affiliates");
    };

    _onGenerateLink = async (values) => {
        if (values.name) {
            this.setState({ loadingGenerate: true });
            const { success, data, message } = await genAffiliateLink({ name: values.name });
            if (success) {
                this.setState({
                    affiliateForm: { ...values, link: data.link },
                });
            } else {
                toaster({
                    type: "error",
                    message,
                });
            }
            this.setState({ loadingGenerate: false });
        }

    };

    async componentDidMount() {
        if (this.state.id) {
            const { success, data, message } = await getAffiliateById(this.state.id);
            if (success) {
                this.setState({
                    affiliateForm: {
                        id: data._id,
                        name: data.name,
                        email: data.email,
                        code: data.code,
                        link: data.link,
                        kol_profit: data.kol_profit,
                        kol_profit_days: data.kol_profit_days,
                        seller_discount: data.seller_discount,
                        seller_discount_days: data.seller_discount_days,
                        kol_profit_supplier: data.kol_profit_supplier,
                        seller_discount_supplier: data.seller_discount_supplier
                    },
                });
                this._fetchProfitData(data.code, this.state.params)
            } else {
                toaster({
                    type: "error",
                    message,
                });
            }
        } else {
            let code = "";
            while (1) {
                code = this._generateRandomString(6);
                if (this._checkCode(code)) break;
            }
            this.setState({
                affiliateForm: {
                    ...this.state.affiliateForm,
                    code,
                },
            });
        }
    }

    _onChangeProfitPage = (page) => {
        this.setState({
            params: {
                ...this.state.params,
                page
            }
        }, () => this._fetchProfitData(this.state.affiliateForm.code, this.state.params))
    }

    _fetchProfitData = async (code, params) => {
        const { data, success } = await getAffiliatesProfit(code, params)
        if (success) {
            this.setState({
                profits: data.affiliates,
                params: {
                    ...params,
                    pages: data.pages,
                    total: data.total,
                    totalProfit: data.total_profit
                }
            })
        }
    }

    _checkCode = (code) => {
        return /([0-9].*[a-z])|([a-z].*[0-9])/.test(code);
    };
    _generateRandomString = (length) => {
        let result = "";
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    render() {
        const { affiliateForm, profits, id, params } = this.state;
        return (
            <div className="affiliate-detail">
                <Link to="/a/affiliates">
                    <i className="fa fa-chevron-left mr-2"></i>Affiliates
                </Link>
                <h3>{id ? "Update" : "Create"} Affiliate</h3>
                <Formik
                    enableReinitialize={true}
                    initialValues={affiliateForm}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().trim().required("Name is required"),
                        email: Yup.string()
                            .trim()
                            .required("Email is required")
                            .email("Email is invalid")
                        // .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Email is invalid"),
                    })}
                    onSubmit={async (form, { setSubmitting }) => {
                        const { success, message } = await createAffiliate(form);
                        if (success) {
                            toaster({
                                type: "success",
                                message: `${id ? "Update" : "Create"} affiliate successfully`,
                            });
                            this._onNavigateList();
                        } else {
                            toaster({
                                type: "error",
                                message,
                            });
                        }
                        setSubmitting(false);
                    }}
                    render={({ errors, touched, isSubmitting, values, setFieldValue }) => {
                        return (
                            <>
                                <Form>
                                    <div className="card">
                                        <div className="form-group row">
                                            <div className="col-sm-5">
                                                <label>
                                                    Name<span className="text-danger">*</span>
                                                </label>
                                                <Field
                                                    name="name"
                                                    type="text"
                                                    maxLength="50"
                                                    className={`form-control${errors.name && touched.name ? " invalid-input" : ""
                                                        }`}
                                                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                                                    onBlur={(e) => {
                                                        setFieldValue(e.target.name, e.target.value.trim());
                                                        this._onGenerateLink(values);
                                                    }}
                                                />
                                                <ErrorMessage name="name" component="div" className="error-message" />
                                            </div>
                                            <div className="col-sm-5">
                                                <label>
                                                    Email<span className="text-danger">*</span>
                                                </label>
                                                <Field
                                                    name="email"
                                                    type="text"
                                                    maxLength="50"
                                                    className={`form-control${errors.email && touched.email ? " invalid-input" : ""
                                                        }`}
                                                    onBlur={(e) => {
                                                        setFieldValue(e.target.name, e.target.value.trim());
                                                    }}
                                                />
                                                <ErrorMessage name="email" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-5">
                                                <label>Code</label>
                                                <Field name="code" type="text" className="form-control" disabled />
                                            </div>
                                            <div className="col-sm-5">
                                                <label>Link</label>
                                                <Field name="link" type="text" className="form-control" disabled />
                                            </div>
                                        </div>

                                        <hr />

                                        <AffiliateSupplier
                                            errors={errors}
                                            touched={touched}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                        />

                                        <hr />
                                        <AffiliateProfitTable id={id} code={affiliateForm.code} params={params} profits={profits} onChangeProfitPage={this._onChangeProfitPage} />
                                    </div>
                                    <div className="row mt-2 ml-0 mr-0 justify-content-end">
                                        <button type="submit" disabled={isSubmitting} className="btn btn-primary mr-2">
                                            {isSubmitting && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                                            {id ? "Update" : "Create"}
                                        </button>
                                        <button
                                            type="button"
                                            disabled={isSubmitting}
                                            className="btn btn-outline-secondary"
                                            onClick={this._onNavigateList}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Form>
                            </>
                        );
                    }}
                />
            </div>
        );
    }
}

export default AffiliateForm;
