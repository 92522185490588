import React, {Component} from 'react'
import WeightRulesTable from "./WeightRulesTable"

// import PropTypes from 'prop-types'

class WeightRulesContainer extends Component {
    render() {
        return (
            <div className="WeightRulesContainer">
                <div className="SectionInner position-relative">
                    <div className='col-7 p-0'>
                        <h6 className="mb-0 fs-18">Weight Table</h6>
                        <div className="text-muted fs-14">Charge incremental shipping rates based on product weight.</div>
                    </div>
                    <WeightRulesTable {...this.props}/>
                </div>
            </div>
        )
    }
}

// WeigthRulesContainer.defaultProps = {}

// WeigthRulesContainer.propTypes = {}

export default WeightRulesContainer
