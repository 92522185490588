import React, {Component} from 'react'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'

class TablePagination extends Component {
    _handlePageChange = page => {
        const {selected} = page
        const {handlePageChange} = this.props
        return handlePageChange(selected + 1)
    }

    render() {
        const {pages, page} = this.props
        if (pages <= 1) return null

        return (
            <div className='TablePagination'>
                <ReactPaginate
                    pageCount={pages}
                    forcePage={page - 1}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this._handlePageChange}
                    containerClassName='pagination'
                    pageClassName='page-item'
                    previousClassName='Previous page-item'
                    nextClassName='Next page-item'
                    pageLinkClassName='page-link'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    subContainerClassName='pages pagination'
                    activeClassName='active'
                />
            </div>
        )
    }
}

TablePagination.propTypes = {
    handlePageChange: PropTypes.func.isRequired,
    pages: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
}

export default TablePagination
