import React from 'react'
import DocTitleByStore from '../../../dashboard/shared/DocTitleByStore'
import DailyTopupReportPage from './DailyTopupReportPage'

const DailyTopupReportContainer = () => {

    return (
        <div className="DailyTopupReportContainer">
            <DocTitleByStore title="Daily Topup Report" />
            <DailyTopupReportPage />
        </div>
    )
}

export default DailyTopupReportContainer