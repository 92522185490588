import React, { Fragment } from "react"
import PFPagePagination from "../../../shared/PFPagePagination"
import PFTable from "../../../shared/PFTable"
import TableLoading from "../../../shared/TableLoading"

const ProductTable = (props) => {
    const { checkedProductIds, setCheckedProductIds, loading, page, pages, products, handleChangePage } = props

    return (
        <div className="ProductMockupTable table-responsive">
            {
                <PFTable source={products}>
                    {(table) => (
                        <>
                            <thead className="ProductMockupTableHead thead-light">
                                <tr>
                                    <th>#</th>
                                    <th>Product Line</th>
                                    <th>Type</th>
                                    <th>Category</th>
                                </tr>
                            </thead>
                            <ProductTableBody
                                {...table}
                                {...props}
                                checkedProductIds={checkedProductIds}
                                setCheckedProductIds={setCheckedProductIds}
                            />
                        </>
                    )}
                </PFTable>
            }
            {loading ? (
                <TableLoading columnQuantity={4} />
            ) : (
                <PFPagePagination page={page} pages={pages} onChangePage={handleChangePage} />
            )}
        </div>
    )
}

const ProductTableBody = (props) => {
    const { loading, products, checkedProductIds, page, pageSize, setCheckedProductIds } = props
    const handleChangeCheckbox = (event, id) => {
        const checkedProducts = [...checkedProductIds]
        if (event.target.checked) {
            checkedProducts.push(id)
        } else {
            checkedProducts.splice(checkedProductIds.indexOf(id), 1)
        }
        setCheckedProductIds(checkedProducts)
    }
    return (
        <Fragment>
            {loading ? (
                <TableLoading columnQuantity={4} />
            ) : Array.isArray(products) && products.length === 0 ? (
                <tbody>
                    <tr className="text-center">
                        <td colSpan="4">
                            <span className="text-muted">No results!</span>
                        </td>
                    </tr>
                </tbody>
            ) : (
                <tbody className="ProductTableBody">
                    {Array.isArray(products) &&
                        products.map((product, index) => {
                            const { _id } = product
                            const category = Object.assign({}, product.category)
                            const { title } = category
                            return (
                                <tr key={`ProductMockupList_${index}`}>
                                    <td className="d-flex align-items-center ">
                                        <span className="mr-2 WidthSpanStt">{(page - 1) * pageSize + index + 1}</span>
                                        <input
                                            type="checkbox"
                                            checked={checkedProductIds.includes(_id)}
                                            disabled={!checkedProductIds.includes(_id) && checkedProductIds.length === 6}
                                            onChange={(e) => {
                                                handleChangeCheckbox(e, _id)
                                            }}
                                        />
                                    </td>
                                    <td>{product.title}</td>
                                    <td>{product.product_type}</td>
                                    <td>{title}</td>
                                </tr>
                            )
                        })}
                </tbody>
            )}
        </Fragment>
    )
}

export default ProductTable
