import api from './manager'

export const getOverdueShipment = (data) => {
    return api.makeAuthRequest({
        url: `/overdue-shipment-reports/search`,
        method: 'POST',
        data,
    })
}

export const getDetailOverdueShipment = (params) => {
    return api.makeAuthRequest({
        url: `/overdue-shipment-reports/detail`,
        method: 'GET',
        params
    })
}

export const getFileExportDetailOverdueShipment = (params) => {
    return api.makeAuthRequest({
        url: `/overdue-shipment-reports/detail/export`,
        method: 'GET',
        params
    })
}

export const exportDetailOverdueShipment = (fileId) => {
    return api.makeAuthRequest({
        url: `/overdue-shipment-reports/detail/export/${fileId}`,
        method: 'GET',
    })
}