import React, {Component} from 'react'

class DailyTopupReportTableHead extends Component {
    render() {
        return (
            <thead className="DailyTopupReportTableHead thead-light">
                <tr>
                    <th className="table-col-10">#</th>
                    <th className="table-col-20">Date</th>
                    <th className="table-col-20">Created at</th>
                    <th className="table-col-20">Status</th>
                    <th className="table-col-20">Report data</th>
                    <th className="table-col-10">Action</th>
                </tr>
            </thead>
        )
    }
}

export default DailyTopupReportTableHead
