import React, {Component} from 'react'
import Select from 'react-select'

const options = [{value: 'active', label: 'active'}, {value: 'inactive', label: 'inactive'}]
class StatusSelect extends Component {
    handleChange = value => {
        this.props.onChange('status', value)
    }

    handleBlur = () => {
        this.props.onBlur('status', true)
    }

    render() {
        return (
            <div style={{margin: '1rem 0'}}>
                <span>Status</span>
                <Select
                    id='color'
                    options={options}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                />
                {!!this.props.error && this.props.touched && (
                    <div style={{color: 'red', marginTop: '.5rem'}}>{this.props.error}</div>
                )}
            </div>
        )
    }
}

export default StatusSelect
