import React, {Component} from 'react'
import WithrawalApprove from '../../actions/WithrawalApprove'
import WithrawalReject from '../../actions/WithrawalReject'

class WithdrawalActions extends Component {

    render() {
        return (
            <td className='WithrawalActions text-center d-flex justify-content-center'>
                <WithrawalApprove {...this.props}/>
                <WithrawalReject {...this.props}/>
            </td>
        )
    }
}

export default WithdrawalActions
