import React, {useState} from 'react'
import Clipboard from 'react-clipboard.js'
import iconCopy from '../../../../statics/assets/icons/icon_copy.svg'
import paypal from '../../../../statics/assets/icons/paypal.svg'
import payoneer from '../../../../statics/assets/icons/payoneer.svg'
import PropTypes from 'prop-types'

const TableRowAccount = (props) => {

    const {email, gateway} = props
    const [copyText, setCopyText] = useState('Copy email')

    let timeout = null

    const handleCopy = () => {
        setCopyText('Copied!')
        timeout = setTimeout(() => {
            setCopyText('Copy email')
        }, 2000)
    }

    const mapGateway = (gateway) => {

        if (!gateway) {
            return null
        }

        let output = ''
        switch (gateway) {
            case 'paypal':
                output = <img alt='' src={paypal}/>
                break
            case 'payoneer':
                output = <img alt='' src={payoneer}/>
                break
            default:
                output = gateway
        }

        return output
    }

    return (

        <td>
            <div className="d-flex align-items-center">
                <p className="mb-0 mr-1">{email}</p>
                <Clipboard component="div" data-clipboard-text={email}
                           className="PayoutCopyEmail fs-14"
                           onSuccess={handleCopy}>
                    <div className="Tooltip3 Top">
                        <img className='cursor-pointer ml-1' alt='' src={iconCopy}/>
                        <div className="TooltipContent">
                            <div className="ContentInner">
                                <small className="text-monospace">{copyText}</small>
                            </div>
                        </div>
                    </div>
                </Clipboard>
            </div>
            <span className="text-muted">
                {mapGateway(gateway)}
            </span>
        </td>
    )

}

TableRowAccount.propTypes = {
    email: PropTypes.string.isRequired,
    gateway: PropTypes.string.isRequired,
}

export default TableRowAccount
