import React, {Component} from 'react'
import PropTypes from "prop-types"
import MigrationDetailModal from './MigrationDetailModal'

class MigrationDetail extends Component{
    constructor(props) {
        super(props);
        this.state = {
            provider: props.provider,
            showModal: false,
        }
    }

    _handleUpdateProvider = (provider) => {
        this.setState({
            provider
        })
    }

    _handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    render() {
        const {provider, showModal} = this.state

        return (
            <div className={`MigrationDetailContainer ${this.props.storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                <div className='card'>
                    <div className='card-header bg-secondary text-white font-weight-bold'>Migration</div>

                    <div className='card-body'>
                        <div className='d-flex align-items-baseline'>
                            <span className="mr-2">Cloud provider:</span> {provider}

                            <button className='btn btn-primary btn-sm ml-3' onClick={this._handleToggleModal}>
                                <i className='far fa-edit'/>
                            </button>
                        </div>
                    </div>
                </div>
                <MigrationDetailModal
                    showModal={showModal}
                    provider={provider}
                    storeId={this.props.storeId}
                    onUpdateProvider={this._handleUpdateProvider}
                    onToggleModal={this._handleToggleModal}
                />
            </div>
        );
    }
}

MigrationDetail.propTypes = {
    provider: PropTypes.string,
    storeId: PropTypes.string.isRequired,
}

export default MigrationDetail
