import React, {Component} from 'react'
import PropTypes from "prop-types"

class DepositsHeader extends Component {
    render() {
        const {deposits} = this.props
        return (
            <div className="DepositsHeader mb-4">
                <h2 className='mb-0 mt-1'>Top up request</h2>
                <div className="DepositsTotal">
                    <span>There are total: </span>
                    <span className="TotalNumber"><b>{deposits.total || 0} deposits</b></span>
                </div>
            </div>
        )
    }
}

DepositsHeader.propTypes = {
    deposits: PropTypes.object,
}

export default DepositsHeader