export const PLAN = [
    {
        title: 'Fast',
        value: 'priority',
    },
    {
        title: 'Express shipping',
        value: 'express',
    },
    {
        title: 'Giao hàng tiết kiệm',
        value: 'ghtk'
    },
    {
        title: 'Non-track',
        value: 'non-track'
    },
    {
        title: 'Track',
        value: 'track',
    },
    {
        title: 'Fast shipping',
        value: 'fast',
    },
    {
        title: 'Standard',
        value: 'standard',
    },
]
