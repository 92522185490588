import React from "react"
import payoneer from "../../../../../statics/assets/icons/payoneer.png"
import bank from "../../../../../statics/assets/icons/bank-transfer.png"
import ping_pong from "../../../../../statics/assets/icons/pingpong.svg"
import paypal from "../../../../../statics/assets/icons/paypal.svg"
import mass_payment_paypal from "../../../../../statics/assets/icons/paypal-mass.svg"
import goods_services_paypal from "../../../../../statics/assets/icons/paypal-goodsservices.svg"
import lianlian from "../../../../../statics/assets/icons/lianlian.svg"
import shopify from "../../../../../statics/assets/icons/shopify-full.svg"

const GatewayDeposit = (props) => {

    const { gateway } = props

    const _renderGateway = (gateway) => {
        let output = <></>
        switch (gateway) {
            case "payoneer":
                output = <img src={payoneer} alt="payoneer" />
                break
            case "bank":
                output = <img src={bank} alt="bank" />
                break
            case "ping_pong":
                output = <img src={ping_pong} alt="ping_pong" />
                break
            case "paypal":
                output = <img src={paypal} alt="paypal" />
                break
            case "mass_payment_paypal":
                output = <img src={mass_payment_paypal} alt="mass_payment_paypal" />
                break
            case "goods_services_paypal":
                output = <img src={goods_services_paypal} alt="goods_services_paypal" />
                break
            case "lianlian":
                output = <img src={lianlian} alt="lianlian" />
                break
            case "shopify":
                output = <img src={shopify} alt="Shopify" />
                break
            default: break
        }
        return output
    }

    return (
        <>
            {
                gateway ?
                    <td>
                        <div className='d-flex align-items-center justify-content-center align-items-center'>
                            {_renderGateway(gateway)}
                        </div>
                    </td>
                    :
                    <td className='text-center'>-</td>
            }
        </>
    )
}

export default GatewayDeposit