import React, {Component} from 'react'
import DocTitleByStore from '../../../dashboard/shared/DocTitleByStore'
import DailyProfitReportPage from './DailyProfitReportPage'
import getEnv from '../../../../helpers/common/getEnv'

class BalanceReportContainer extends Component {

    render() {

        const isSupportMpMode = getEnv('isEnableMpMode')
        if (isSupportMpMode) return null

        return (
            <div className="ReportAccountingContainer">
                <DocTitleByStore title="Daily Profit Report"/>
                <DailyProfitReportPage/>
            </div>
        )
    }
}

export default BalanceReportContainer
