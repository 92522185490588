export const getStatus = (status) => {
    let result = '--'
    switch(status) {
        case 'pending':
            result = <i className="badge badge-secondary">{status}</i>
            break;
        case 'processing':
            result = <i className="badge badge-info">{status}</i>
            break;
        case 'cancelled':
            result = <i className="badge badge-warning">{status}</i>
            break;
        case 'fail':
            result = <i className="badge badge-danger">{status}</i>
            break;
        case 'done':
            result = <i className="badge badge-success">{status}</i>
            break;
        default:
    }
    return result;
}