import React, {Component} from 'react'
import PropTypes from 'prop-types'
import 'react-quill/dist/quill.snow.css'
import InputContent from '../../../../../shared/components/form/InputContent'

class InputNotificationContent extends Component {
    render() {
        const {content, message, setEditorRefs} = this.props

        return (
            <div className="InputNotificationContent form-group">
                <InputContent
                    content={content}
                    editorContent={this.editorContent}
                    changeInput={this._changeInput}
                    error={message}
                    setEditorRefs={setEditorRefs}
                />
            </div>
        )
    }
}

InputNotificationContent.modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{list: 'ordered'}, {list: 'bullet'}],
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        ['link'],
        [{color: []}, {background: []}],
        ['clean'],
    ],
}

InputNotificationContent.propTypes = {
    onChangeNotificationData: PropTypes.func.isRequired,
    content: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
}

export default InputNotificationContent

