import {getLocalData, setLocalData} from "../../services/StorageServices"
import {getCurrentStore} from "../../services/CurrentStoreService"

const _prefix = "FiltersState"

/**
 *
 * @param {string} page
 * @returns {string}
 */
const getFiltersStorageKey = (page) => {
    const currentStore = getCurrentStore()
    return `${currentStore}.${page}.${_prefix}`
}

/**
 *
 * @param {string} page
 * @return {Object}
 */
export const getFiltersStorage = (page) => {
    const storageKey = getFiltersStorageKey(page)
    return getLocalData(storageKey, {})
}

/**
 * @param {string} page
 * @param {Object} value
 * */
export const setFilterStorage = (page, value) => {
    const storageKey = getFiltersStorageKey(page)
    const filtersValue = getLocalData(storageKey, {})

    setLocalData(storageKey, {...filtersValue, ...value})
}

export const resetFiltersStorage = (page) => {
    const storageKey = getFiltersStorageKey(page)

    setLocalData(storageKey, {})
}
