import React, {Component} from 'react'
import {getAffiliateCategories} from '../../../services/api/ProductLinesServices'
import AffiliateTable from './AffiliateTable'
import PFPagePagination from '../../shared/PFPagePagination'
import Link from 'react-router-dom/Link'
import TableFilter from '../../shared/TableFilter'

class AffiliateCategory extends Component {

    state = {
        affiliateCategory: [],
        error: '',
        loading: true,
        params: {
            limit: 20,
            name: '',
            page: 1,
            pages: 1
        }
    }

    componentDidMount() {
        this._getAffiliateCategories()
    }

    _getAffiliateCategories = async () => {
        const {params} = this.state

        try {
            const {success, data, message} = await getAffiliateCategories(params)

            if (!success) throw new Error(message)

            const {affiliateCategory = [], page, pages, limit} = data

            this.setState({
                affiliateCategory,
                params: {
                    page, pages, limit
                },
                loading: false
            })

        } catch (e) {
            this.setState({
                error: e.message,
                loading: false
            })
        }

    }

    _handlePageChange = page => {
        this.setState(
            {
                params: {
                    ...this.state.params,
                    page: page
                }
            },
            () => this._getAffiliateCategories()
        )
    }

    _onSubmitFilter = ({limit, value, field}) => {
        const fieldMap = {
            'Affiliate Title': 'name'
        }

        const parsedField = fieldMap[field]
        this.setState(
            () => ({
                params: {
                    ...{
                        limit: 10,
                        page: 1
                    },
                    ...{
                        [parsedField]: value,
                        limit,
                        page: 1
                    }
                }
            }),
            () => {
                this._getAffiliateCategories()
            }
        )
    }

    render() {
        const {affiliateCategory, params, total} = this.state
        const {page, limit, pages} = params

        return (
            <div className='AffiliateCategory'>
                <h2>Affiliate Category</h2>
                <div className='CategoriesInner SectionInner'>
                    <TableFilter
                        total={total}
                        defaultLimit={limit}
                        onSubmitForm={this._onSubmitFilter}
                        searchField={['Affiliate Title']}
                        placeholders={{
                            'Affiliate Title': 'Search title'
                        }}
                    />

                    <div className="AffiliateButton">
                        <Link to='affiliate-category/create' className='btn btn-primary'>
                            Create new category
                        </Link>
                    </div>

                    <AffiliateTable onGetAffiliateCategories={this._getAffiliateCategories}
                                    affiliateCategory={affiliateCategory}/>

                    <PFPagePagination page={page} pages={pages} onChangePage={this._handlePageChange}/>
                </div>
            </div>
        )
    }
}

export default AffiliateCategory
