import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SettingInput from '../../setting/shared/SettingInput'

class SitesSettings extends Component {
    _onChangeSettingInput = (key) => (value) => {
        this.props.onChangeSetting(key, value)
    }

    render() {
        const {settings, disabled} = this.props

        return (
            <div className="SitesSettings">
                <SettingInput
                    itemId='admin_host'
                    label="Admin site"
                    value={settings.admin_host}
                    disabled={disabled}
                    onChange={this._onChangeSettingInput('admin_host')}
                />
                <SettingInput
                    itemId='seller_host'
                    label="Seller site"
                    value={settings.seller_host}
                    disabled={disabled}
                    onChange={this._onChangeSettingInput('seller_host')}
                />
            </div>
        )
    }
}

SitesSettings.propTypes = {
    settings: PropTypes.object.isRequired,
    onChangeSetting: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
}

export default SitesSettings
