import React, {Component} from 'react'
import {unmountComponentAtNode} from 'react-dom'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import PropTypes from 'prop-types'

class ModalConfirmDelete extends Component {
    state = {
        isOpen: true,
        title: 'Error',
        message: 'Something went wrong!'
    }

    componentWillUnmount() {
        unmountComponentAtNode(this.props.el)
    }

    _toggleModal = () => {
        this.setState(prevState => ({isOpen: !prevState.isOpen}))
    }

    render() {
        return (
            <Modal modalClassName="modal-container" className="modal-amazon" toggle={this._toggleModal}
                   isOpen={this.state.isOpen}>
                <ModalHeader toggle={this._toggleModal}>{this.props.title}</ModalHeader>
                <ModalBody>
                    {this.props.message}
                </ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        color="primary"
                        onClick={this._toggleModal}
                    >{this.props.confirmLabel}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

ModalConfirmDelete.defaultProps = {
    confirmLabel: 'Yes',
    title: 'Error',
    message: 'Something went wrong!'
}

ModalConfirmDelete.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    confirmLabel: PropTypes.string
}

export default ModalConfirmDelete
