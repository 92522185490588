import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import ListItemProductLine from '../product-line/ListItemProductLine'
import PFPagePagination from '../../../../shared/PFPagePagination'

class ManagerListItemProductLine extends Component {

    state = {
        isOpenModal: false,
        filter: 'title',
        valueInput: '',
        productLineSelected: this.props.productLineSelected || [],
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.productLineSelected) !== JSON.stringify(this.props.productLineSelected)) {
            this.setState({
                productLineSelected: this.props.productLineSelected,
            })
        }
    }

    _timer = null

    _toggleModal = (e) => {
        e.preventDefault()
        const {isOpenModal} = this.state
        this.setState({
            isOpenModal: !isOpenModal,
        })
    }

    _onChangeSelect = (e) => {
        const {value} = e.target
        const {onHandleChangeInput} = this.props
        this.setState({
            filter: value,
            valueInput: '',
        })
        onHandleChangeInput(value, '')
    }

    _handleChangeInput = (e) => {
        const {filter} = this.state
        const {onHandleChangeInput} = this.props
        const value = e.target.value
        this.setState({
            valueInput: value,
        })

        const valueFormat = value.trim()

        if (this._timer) clearTimeout(this._timer)

        this._timer = setTimeout(() => {
            // if (filter === 'type') return onHandleChangeInput('type', valueFormat)
            onHandleChangeInput(filter, valueFormat)
        }, 1000)
    }

    _selectedProductLine = (params, action) => {
        const {productLineSelected} = this.state

        if ('add' === action) {
            this.setState({
                productLineSelected: [...productLineSelected, params],
            })
        } else if ('remove' === action) {
            const selectedProductLine = productLineSelected.filter(product => product.sku !== params.sku)
            this.setState({
                productLineSelected: selectedProductLine,
            })
        }
    }

    _handleChangeCheckboxAll = (e) => {
        const {checked} = e.target
        const {productLines} = this.props
        const {productLineSelected} = this.state

        if (checked) {
            const handleSelectedAll = productLines.filter(item => {
                return !productLineSelected.some(i => i.sku === item.sku)
            })
            return this.setState({
                productLineSelected: Array.from(new Set(handleSelectedAll.concat(productLineSelected))),
            })
        } else {
            const handleSelectedAll = productLineSelected.filter(item => {
                return !productLines.some(i => i.sku === item.sku)
            })
            this.setState({
                productLineSelected: handleSelectedAll,
            })
        }
    }

    _addNewProductLine = (e) => {
        e.preventDefault()
        const {productLineSelected} = this.state
        const {onChangeProductLineSelected} = this.props
        this.setState({
            isOpenModal: false,
        }, () => onChangeProductLineSelected(productLineSelected))
    }

    render() {
        const {isOpenModal, filter, valueInput, productLineSelected} = this.state
        const {productLines, onChangePage, pages, page} = this.props

        const listSkus = (Array.isArray(productLines) && productLines.length > 0)
            ? productLines.map(item => item.sku)
            : []

        const listSkuSelected = (Array.isArray(productLineSelected) && productLineSelected.length > 0) ? productLineSelected.map(item => item.sku) : []
        const isChecked = listSkus.every(i => listSkuSelected.includes(i))

        return (
            <div className="MangerListItemProductLine">
                <button className='btn btn-primary btn-sm' onClick={this._toggleModal}>Manage products
                </button>

                <Modal className='modal-dialog-centered modal-lg' isOpen={isOpenModal} toggle={this._toggleModal}>
                    <ModalHeader toggle={this._toggleModal}>List product line</ModalHeader>
                    <ModalBody>
                        <div className='InputSearchProductLine d-flex position-relative mb-3'>
                            <select
                                value={filter}
                                className='SelectTypeProductLine'
                                onChange={this._onChangeSelect}
                            >
                                <option value='title'>Title</option>
                                <option value="type">Type</option>
                                <option value="sku_prefix">SKU</option>
                            </select>
                            <input value={valueInput} type="text" className="form-control SearchInput"
                                   placeholder="Search products line"
                                   onChange={this._handleChangeInput}/>
                            <i className="fas fa-search SearchIcon position-absolute"/>
                        </div>
                        <div className='ListItemProductLine table-responsive'>
                            <table className="table table-striped table-hover">
                                <thead className="thead-light">
                                <tr>
                                    <th className='table-col-10 ColAllSelection'>
                                        <div className='custom-control custom-checkbox'>
                                            <input type='checkbox' className='custom-control-input'
                                                   id='ItemProductLineAllSelection'
                                                   checked={isChecked} onChange={this._handleChangeCheckboxAll}/>
                                            <label htmlFor='ItemProductLineAllSelection'
                                                   className='custom-control-label'/>
                                        </div>
                                    </th>
                                    <th className='table-col-30'>SKU</th>
                                    <th className='table-col-50'>Title</th>
                                    <th className='table-col-10'>Type</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Array.isArray(productLines) && productLines.length > 0 && productLines.map((item, index) => {
                                        return (
                                            <ListItemProductLine key={`ListItemProductLine_${index}`}
                                                                 productLineSelected={productLineSelected}
                                                                 onSelectedProductLine={this._selectedProductLine}
                                                                 product={item}/>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        <PFPagePagination page={page} pages={pages} onChangePage={onChangePage}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={this._toggleModal}>Cancel</Button>{' '}
                        <Button color="primary" onClick={this._addNewProductLine}>Add products line</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

ManagerListItemProductLine.propTypes = {
    productLines: PropTypes.array.isRequired,
    onHandleChangeInput: PropTypes.func.isRequired,
    onChangeProductLineSelected: PropTypes.func.isRequired,
    productLineSelected: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
}

export default ManagerListItemProductLine
