import React, {useEffect, useRef} from 'react'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import BackNavigationButton from '../../shared/BackNavigationButton'
import {
    bulkUnLinkStoreGroup,
    deleteGroupConfig,
    getGroupClusterDetail,
    getListGroupConfigs,
    searchItemGroupConfigs,
    syncGroupConfig,
    updateListGroupConfigs
} from '../../../services/api/ClustersServices'
import GroupInfomation from './GroupInfomation'
import {getListStores} from '../../../services/api/StoreServices'
import TableConfig from './table-config/TableConfig'
import StoreTableContainer from './stores/StoreTableContainer'
import StoreTablePagination from './stores/StoreTablePagination'
import ListJobjs from './jobs/ListJobjs'
import {toaster} from '../../shared/PFToast'

const GroupDetailContainer = (props) => {
    const {match} = props
    const [group, setGroup] = React.useState({})
    const [page, setPage] = React.useState(1)
    const [limit, setLimit] = React.useState(10)
    const [totalPage, setTotalPage] = React.useState(1)
    const [loading, setLoading] = React.useState(false)
    const [listStores, setListStores] = React.useState([])
    const [groupConfigs, setGroupConfigs] = React.useState([])
    const [params, setParams] = React.useState({})
    const groupId = match.params.id
    const inputRef = useRef(null)
    const [isSelectAll, setIsSelectAll] = React.useState(false)
    const [listStoresSelected, setListStoresSelected] = React.useState([])

    const handlePageChange = (page) => {
        setPage(page)
    }

    const _handleChangeFilter = (e) => {
        const {name, value} = e.target
        setParams({
            ...params,
            [name]: value
        })
    }

    const _handleChangeResourceKind = (resource_kind) => {
        setParams({
            ...params,
            resource_kind,
            resourceId: '',
        })
    }

    const _getListStores = async () => {
        setLoading(true)
        try {
            if (!params.status) delete params.status
            if (!params.planId) delete params.planId
            if (!params.mode) delete params.mode
            if (!params.disable_storefront) delete params.disable_storefront
            if (!params.resource_kind) delete params.resource_kind
            if (!params.resourceId) delete params.resourceId
            const req = {
                limit: limit,
                page: page,
                groupId,
                ...params
            }
            const {success, data, message} = await getListStores(req)

            if (!success) {
                throw new Error(message)
            }

            const {total, totalPage, stores} = Object.assign({}, data)
            setTotalPage(totalPage)
            setListStores(stores)
        } catch (e) {
        } finally {
            setLoading(false)
        }
    }

    const getGroupDetails = async () => {
        try {
            const {data, success, message} = await getGroupClusterDetail(groupId)

            if (!success) {
                throw new Error(message)
            }
            getGroupConfigs(data.cluster)
            setGroup(data)
        } catch (e) {}
    }

    const getGroupConfigs = async (clusterId) => {
        try {
            const {data, success, message} = await getListGroupConfigs(clusterId, groupId)
            if (!success) {
                throw new Error(message)
            }
            const {config_map} = data
            setGroupConfigs(config_map)
        } catch (error) {
            console.log(error)
        }
    }

    const updateGroupConfigs = async (dataConfig,action) => {
        try {
            let data = {}
            let message = ''
            let success = false

            switch (action) {
                case 'delete':
                    ({data, success, message} = await deleteGroupConfig(group.cluster, groupId, dataConfig))
                    break
                case 'update':
                    ({data, success, message} = await updateListGroupConfigs(group.cluster, groupId, dataConfig))
                    break
                case 'sync':
                    ({data, success, message} = await syncGroupConfig(group.cluster, groupId))
                    break
                default:
                    break
            }
            if (!success) {
                throw new Error(message)
            }
            if (inputRef.current) {
                inputRef.current.value = ''
            }
            setGroupConfigs(data.config_map)
        } catch (error) {
            console.error(error)
            toaster({type: 'error', message: error.message})
        }
    }

    const searchGroupConfigs = async (txtSearch) => {
        try {
            const payload = {
                type: 'config_map',
                key: txtSearch,
            }
            const {data, success, message} = await searchItemGroupConfigs(group.cluster, groupId, payload)
            if (!success) {
                throw new Error(message)
            }
            const config_map = data.reduce((acc, item) => {
                acc[item.key] = item.value
                return acc
            }, {})
            setGroupConfigs(config_map)
        } catch (error) {
            console.error(error)
            toaster({type: 'error', message: error.message})
        }
    }

    const _onChangeSelectAll = (checked) => {
        console.log(checked)
        if (checked) {
            const ids = listStores.filter(item => item.status === 'suspended').map((item) => item._id);
            setListStoresSelected(ids)
        } else {
            setListStoresSelected([])
        }
        setIsSelectAll(checked)
    };

    const _onSelectedStore = (id, checked) => {
        if (checked) {
            const newIds = [...listStoresSelected, id]
            setIsSelectAll(newIds.length === listStores.filter(item => item.status === 'suspended').length)
            setListStoresSelected(newIds)
        } else {
            const newIds = [...listStoresSelected]
            newIds.splice(listStoresSelected.indexOf(id), 1);
            setIsSelectAll(newIds.length === listStores.filter(item => item.status === 'suspended').length)
            setListStoresSelected(newIds)
        }
    }

    const bulkUnLinkStore = async () => {
        try {
            const payload = {
                storeIds: listStoresSelected
            }
            const {success, message} = await bulkUnLinkStoreGroup(groupId, payload)
            if (!success) {
                return toaster({
                    type: 'error',
                    message: message,
                })
            }
            toaster({
                type: 'success',
                message: `Unlink stores successfully.`,
            })
            _getListStores()
        } catch (e) {

        }
    }

    useEffect(() => {
        getGroupDetails()
    }, [])

    useEffect(() => {
        _getListStores()
    }, [page, params.mode, params.planId, params.disable_storefront, params.resourceId, params.status])

    return (
        <div className="GroupPageContainer">
            <DocTitleByStore title={group.namespace || ''} />
            <BackNavigationButton to={`/a/groups`} text="Groups" />
            <div className="d-flex justify-content-between align-items-center">
                <h2>{group.namespace || ''}</h2>
            </div>
            <GroupInfomation group={group} />
            <TableConfig
                inputRef={inputRef}
                group={group}
                searchGroupConfigs={searchGroupConfigs}
                updateGroupConfigs={updateGroupConfigs}
                configs={groupConfigs}
            />
            <ListJobjs group={group} groupId={groupId} />
            <h2>Stores</h2>
            <div className="SectionInner">
                <StoreTableContainer
                    items={listStores}
                    params={params}
                    page={page}
                    limit={limit}
                    loading={loading}
                    groupId={groupId}
                    getListStores={_getListStores}
                    handleChangeFilter={_handleChangeFilter}
                    handleChangeResourceKind={_handleChangeResourceKind}
                    isSelectAll={isSelectAll}
                    listStoresSelected={listStoresSelected}
                    handleChangeSelectAll={_onChangeSelectAll}
                    handleSelected={_onSelectedStore}
                    bulkUnLinkStore={bulkUnLinkStore}
                />
            </div>
            <StoreTablePagination page={listStores} pages={totalPage} handlePageChange={handlePageChange} />
        </div>
    )
}

export default GroupDetailContainer

