import React from 'react'
import PropTypes from 'prop-types'
import {getPlans} from '../../../../../services/api/PlanServices'

class PlanFilterSelect extends React.Component {
    state = {
        plans: []
    }

    componentDidMount() {
        this._fetchPlans()
    }

    _fetchPlans = async () => {
        try {
            const {success, data, message} = await getPlans()
            if (!success) throw new Error(message)

            this.setState({plans: data})
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const {onChange, value} = this.props
        const {plans} = this.state

        return (
            <div className='PlanSelect mr-3'>
                <p className="mb-0">Plan</p>
                <select name='planId' value={value} onChange={onChange}>
                    <option value=''>All</option>
                    {
                        plans.map(plan => {
                            return <option key={plan._id} value={plan._id}>{plan.title}</option>
                        })
                    }
                </select>
            </div>
        )
    }
}

PlanFilterSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value   : PropTypes.string.isRequired
}

export default PlanFilterSelect
