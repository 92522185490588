import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, Form } from 'reactstrap';
import humanizeTime from '../../../../helpers/time/humanizeTime';
import { getAffiliateWithdrawal } from '../../../../services/api/AffiliateServices';
import PFPagePagination from '../../../shared/PFPagePagination';
import { toaster } from '../../../shared/PFToast';
import moment from 'moment';
import formatThousandNumber from '../../../../helpers/common/formatThousandNumber';

const WithdrawHistoryModal = (props) => {
    const { code, openWidthdrawHistory, toggleHistory } = props

    const [loading, setLoading] = useState(false)
    const [params, setParams] = useState(initialParams)
    const [listHistory, setListHistory] = useState([])
    const [pages, setPages] = useState(0)

    useEffect(() => {
        setParams(initialParams)
    }, [toggleHistory])

    useEffect(() => {
        if (openWidthdrawHistory) {
            fetchHistory(params)
        }
    }, [openWidthdrawHistory])

    const fetchHistory = async (request) => {
        try {
            if (code) {
                setLoading(true)
                const { data, success, message } = await getAffiliateWithdrawal(code, request)
                if (success) {
                    setPages(data.pages)
                    setListHistory(data.withdrawals)
                } else {
                    toaster({
                        type: 'error',
                        message
                    })
                }
                setLoading(false)
            }
        } catch (e) {
            console.error(e)
        }
    }

    const handleChangePage = (page) => {
        const newParams = {
            ...params,
            page
        }
        setParams(newParams)
        fetchHistory(newParams)
    }

    const validateDate = (date) => {
        const momentTime = moment(date)
        return humanizeTime(momentTime)
    }

    return (
        <Modal className='widthdrawal-modal' centered size='lg' isOpen={openWidthdrawHistory} toggle={toggleHistory} backdrop="static" >
            <ModalHeader toggle={toggleHistory}>Withdrawal history</ModalHeader>
            <ModalBody>
                <div className='table-responsive history-table'>
                    <table className='table'>
                        <thead className='thead-light'>
                            <tr>
                                <th>Created</th>
                                <th>User</th>
                                <th>Amount</th>
                                <th>Note</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? <RenderLoading /> :
                                    listHistory.length > 0 ? listHistory.map((history) => (<tr key={history._id}>
                                        <td>{validateDate(history.created)}</td>
                                        <td>{history.owner}</td>
                                        <td>${formatThousandNumber(history.amount, 2)}</td>
                                        <td>{history.note}</td>
                                    </tr>
                                    )) : <tr className='text-muted text-center'>
                                        <td colSpan='4'>No result.</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
                {
                    pages > 1 && <PFPagePagination
                        page={params.page}
                        pages={pages}
                        onChangePage={handleChangePage}
                    />
                }
            </ModalBody>
        </Modal>
    )
}

const RenderLoading = () => {
    return (
        <tr>
            <td className='text-center' colSpan='4'><i className='fas fa-circle-notch fa-spin'></i></td>
        </tr>
    )
}

const initialParams = {
    page: 1,
    limit: 20,
}

export default WithdrawHistoryModal