import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {exportContestAllStoreGoogleSheet} from '../../../../services/api/PlanServices'
import {toaster} from '../../../shared/PFToast'

class ExportModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            link: '',
            name: '',
            error: ''
        }
    }

    _handleChange = (e) => {
        const {name, value} = e.target

        this.setState({
            error: '',
            [name]: value
        })
    }

    _handleCloseModal = () => {
        this.setState({
            loading: false,
            link: '',
            name: '',
            error: ''
        }, () => {
            const {onCloseModal} = this.props

            if (typeof onCloseModal === 'function') {
                onCloseModal()
            }
        })
    }

    _handleClick = async (e) => {
        e.preventDefault()

        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {link, name} = this.state
            const payload = {
                link: link,
                name: name
            }

            const {success, message} = await exportContestAllStoreGoogleSheet({...payload})

            if (!success) {
                return this.setState({
                    loading: false,
                    error: message
                })
            }

            this.setState({
                loading: false,
                error: ''
            }, () => {
                this._handleCloseModal()

                toaster({
                    type: 'success',
                    message: 'Export successfully!'
                })
            })
        } catch (err) {
            this.setState({
                loading: false,
                error: err.message
            })
        }
    }

    render() {
        const {isOpen} = this.props
        const {loading, error, link} = this.state
        const canExport = !loading && !error && link.trim() !== ''

        return (
            <Modal isOpen={isOpen} toggle={this._handleCloseModal} autoFocus={false}>
                <ModalHeader>Link to Google Sheet</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input autoFocus name='name' onChange={this._handleChange}/>
                    </FormGroup>

                    <FormGroup>
                        <Label>Link</Label>
                        <Input name='link' onChange={this._handleChange}/>
                    </FormGroup>

                    <div className='jumbotron p-3 mt-3 mb-0'>
                        <div>Share your Google Sheet to this account:</div>
                        <strong>exporter@platform126.iam.gserviceaccount.com</strong>
                    </div>

                    {!!error && <div className='mt-3 text-danger'>{error}</div>}
                </ModalBody>
                <ModalFooter>
                    <Button className='btn-default' onClick={this._handleCloseModal}>Cancel</Button>
                    <Button
                        color='primary'
                        disabled={!canExport}
                        onClick={this._handleClick}
                    >
                        {loading && <i className='fa fa-spin fa-spinner mr-1'/>}
                        Export
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

ExportModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired
}

export default ExportModal
