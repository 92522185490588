import React from 'react'
import {Input} from 'reactstrap'

const VariantDimension = (props) => {
    const {dimensions} = props

    return (
        <div className="VariantDimension mt-3">
            <div className="SectionInner">
                {dimensions.map((dimension, index) => {
                    const {front = {}, back = {}, sleeve = {}, hood = {}} = dimension
                    return (
                        <div className="DimensionItem mb-3" key={`dimension_${index}`}>
                            <div className="row align-items-center mb-3">
                                <div className="col-2">
                                    <label>Front:</label>
                                </div>

                                <div className="col-10 d-flex align-items-center">
                                    <Input value={front.width} />
                                    <span className="mx-2">x</span>
                                    <Input value={front.height} />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-2">
                                    <label>Back:</label>
                                </div>

                                <div className="col-10 d-flex align-items-center">
                                    <Input value={back.width} />
                                    <span className="mx-2">x</span>
                                    <Input value={back.width} />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-2">
                                    <label>Sleeve:</label>
                                </div>
                                <div className="col-10 d-flex align-items-center">
                                    <Input value={sleeve.width} />
                                    <span className="mx-2">x</span>
                                    <Input value={sleeve.width} />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-2">
                                    <label>Hood:</label>
                                </div>
                                <div className="col-10 d-flex align-items-center">
                                    <Input value={hood.width} />
                                    <span className="mx-2">x</span>
                                    <Input value={hood.width} />
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default VariantDimension

