import React from "react";
import { Field, ErrorMessage } from "formik";

const AffiliateSupplier = (props) => {
    const { errors, touched, values, setFieldValue } = props;
    return (
        <div className="affiliate-profit">
            <div className="kol-profit">
                <h3>Profit for KOL</h3>
                <div className="row form-group">
                    <div className="col-sm-3">
                        <label>Supplier</label>
                        <select
                            className="form-control"
                            name="kol_profit_supplier"
                            value={values.kol_profit_supplier}
                            onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                        >
                            <option value="ALL">All</option>
                            <option value="VN">Vietnam</option>
                        </select>
                    </div>
                    <div className="col-sm-3">
                        <label>Profit</label>
                        <div className="input-group">
                            <Field
                                type="number"
                                className="form-control"
                                name="kol_profit"
                                value={values.kol_profit}
                                onBlur={(e) => {
                                    if (!e.target.value) setFieldValue(e.target.name, 0);
                                }}
                                onKeyPress={(e) => (!/^[0-9]*\.?[0-9]*$/.test(e.key) || e.target.value.length > 5) && e.preventDefault()}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <label>Apply time</label>
                        <div className="input-group">
                            <Field
                                type="number"
                                className="form-control"
                                name="kol_profit_days"
                                value={values.kol_profit_days}
                                onKeyPress={(e) => (!/[0-9]/.test(e.key) || e.target.value.length > 4) && e.preventDefault()}
                                onBlur={(e) => {
                                    if (!e.target.value) setFieldValue(e.target.name, 0);
                                }}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">days</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="seller-profit">
                <h3>Profit for seller</h3>
                <div className="row form-group">
                    <div className="col-sm-3">
                        <label>Supplier</label>
                        <select
                            className="form-control"
                            name="seller_discount_supplier"
                            value={values.seller_discount_supplier}
                            onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                        >
                            <option value="ALL">All</option>
                            <option value="VN">Vietnam</option>
                        </select>
                    </div>
                    <div className="col-sm-3">
                        {/* <label>Discount base cost</label> */}
                        <label>Profit</label>
                        {/* <div className="input-group"> */}
                        <Field
                            type="text"
                            className="form-control"
                            name="seller_discount"
                            value={values.seller_discount}
                            // onKeyPress={(e) => (!/^[0-9]*\.?[0-9]*$/.test(e.key) || e.target.value.length > 5) && e.preventDefault()}
                            onBlur={(e) => {
                                if (!e.target.value) setFieldValue(e.target.name, e.target.value.trim());
                            }}
                        />
                        {/* <div className="input-group-append">
                                <span className="input-group-text">%</span>
                            </div> */}
                        {/* </div> */}
                    </div>
                    <div className="col-sm-3">
                        <label>Apply time</label>
                        <div className="input-group">
                            <Field
                                type="number"
                                className="form-control"
                                name="seller_discount_days"
                                value={values.seller_discount_days}
                                onKeyPress={(e) => (!/[0-9]/.test(e.key) || e.target.value.length > 4) && e.preventDefault()}
                                onBlur={(e) => {
                                    if (!e.target.value) setFieldValue(e.target.name, 0);
                                }}
                            />
                            <div className="input-group-append">
                                <span className="input-group-text">days</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AffiliateSupplier;
