import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {rejectProductTicket} from "../../../../services/api/SellerServices"
import {toaster} from "../../../shared/PFToast"
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'

class ProductActionReject extends Component {

    state = {
        isOpenModal: false,
        reason: '',
        error : ''
    }

    _validate = ()=>{
      let {reason, error} = this.state
      if(!reason){
        error = 'This is a required field.'
      }
      this.setState({
        error
      })
      return error?true:false
    }
    _handleReject = async (reason) => {
        if(this._validate()) return
        try {
            const {backoffice_master_url} = window.currentStore
            const backofficeMasterUrl = backoffice_master_url + '/api/seller'

            const {ticketId, onGetProduct} = this.props
            const {success, message} = await rejectProductTicket(ticketId, reason, backofficeMasterUrl)
            if (!success) throw new Error(message)

            toaster({
                type: 'success',
                message: 'Product rejected successfully',
                duration: 5000
            })

            this.setState({
                isOpenModal: false
            })

            onGetProduct()

            window.location.pathname ='a/product-approval'

        } catch (e) {

            toaster({
                type: 'error',
                message: e.message,
                duration: 5000
            })
            this.setState({
                isOpenModal: false
            })
        }
    }

    _toggleModal = () => {
        const {isOpenModal} = this.state
        this.setState({
            isOpenModal: !isOpenModal
        })
    }

    _handleChange = (event) => {
        this.setState({reason: event.target.value});
    }

    render() {
        const {isOpenModal, reason, error} = this.state
        const {approvalStatus, productTitle} = this.props
        return (
            <>
                <button disabled={approvalStatus === 'approved' || approvalStatus === 'rejected'}
                        className='btn btn-danger' onClick={this._toggleModal}>Reject
                </button>
                <Modal isOpen={isOpenModal} className='modal-dialog-centered' toggle={this._toggleModal}>
                    <ModalHeader toggle={this._toggleModal}>Confirm reject</ModalHeader>
                    <ModalBody>
                        <p>Are you sure you want to reject <b>{productTitle}?</b></p>
                        <div className='d-flex flex-column'>
                            <label>Message reject:</label>
                            <textarea className='form-control' style={{height: 150}} value={reason}
                                      onChange={this._handleChange}/>
                            <p style={{color:'red'}}>{error}</p>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={this._toggleModal}>Close</Button>{' '}
                        <Button disabled={!reason} color="danger" onClick={() => this._handleReject(reason)}>Ok</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

ProductActionReject.propTypes = {
    productTitle: PropTypes.string.isRequired,
    ticketId: PropTypes.string.isRequired,
    approvalStatus: PropTypes.string.isRequired,
    onGetProduct: PropTypes.func.isRequired,
}

export default ProductActionReject
