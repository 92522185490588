import React, {Fragment} from 'react'
import ShippingTableRow from './ShippingTableRow'
import {randomID} from '../../../../../helpers/common/randomID'

const ShippingTable = (props) => {
    const {variants, attributes, fulfillment_location = ''} = props

    if (!variants.length) return null
    const {shipping_prices} = variants[0]

    return (
        <div className="ShippingTable table-responsive">
            <table className="table w-100">
                <thead className="thead-light">
                    <tr>
                        {!!attributes.length &&
                            attributes.map((attribute) => {
                                const _id = randomID()
                                return (
                                    <th key={_id} className={`text-capitalize ${attribute.name}`}>
                                        {attribute.name}
                                    </th>
                                )
                            })}
                        <th>SKU</th>
                        {!!shipping_prices.length &&
                            shipping_prices.map((item) => (
                                <Fragment key={`shipping_price_${item.to_zone}`}>
                                    {fulfillment_location.toLowerCase() === 'china' && item.to_zone === 'US' ? (
                                        <Fragment>
                                            <th className="Zone text-capitalize text-center fs-14">Post Service US</th>
                                            <th className="Zone text-capitalize text-center fs-14">Fast Shipping US (added fee)</th>
                                            <th className="Zone text-capitalize text-center fs-14">Express Shipping US (added fee)</th>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <th className="Zone text-capitalize text-center fs-14">{item.to_zone} Fee</th>
                                            <th className="Zone text-capitalize text-center fs-14">
                                                {item.to_zone} Additional Fee
                                            </th>
                                        </Fragment>
                                    )}
                                </Fragment>
                            ))}
                    </tr>
                </thead>
                <ShippingTableRow variants={variants} {...props} />
            </table>
        </div>
    )
}

export default ShippingTable

