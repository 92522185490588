import React, {Component} from 'react'
import ClustersPage from './ClustersPage'
import getEnv from '../../../helpers/common/getEnv'

class ClustersContainer extends Component {
    render() {
        const isSupportMpMode = getEnv('isEnableMpMode')
        if (isSupportMpMode) return null

        return (
            <div className='ClustersContainer'>
                <ClustersPage />
            </div>
        )
    }
}

export default ClustersContainer
