import React, {useState} from 'react'
import {exportCSV} from '../../../../../services/api/StoreServices'
import moment from 'moment'

const ExportCsv = (props) => {
    const {params} = props
    const [loading, setLoading] = useState(false)

    const exportToCSV = async () => {
        try {
            setLoading(true)
            const req = Object.entries(params).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})
            if (req.startLastSuspendAt) {
                req.startLastSuspendAt = moment(req.startLastSuspendAt).format('DD/MM/YYYY')
            }
            if (req.endLastSuspendAt) {
                req.endLastSuspendAt = moment(req.endLastSuspendAt).format('DD/MM/YYYY')
            }
            if (req.searchValue) delete req.searchValue
            if (req.searchFieldSelected) delete req.searchFieldSelected

            const data = await exportCSV(req)
            setLoading(false)

            let csvContent = 'data:text/csv;charset=utf-8,' + data;
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', 'export.csv');
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
        } catch (e) {
            setLoading(false)
            console.log(e.message)
        }
    }

    return (
        <div className='StatisticStoreBilling'>
            <button className='btn btn-outline-secondary ml-3' onClick={exportToCSV} disabled={loading}>
                {loading ? 'Exporting...' : 'Export CSV'}
            </button>
        </div>
    )
}

export default ExportCsv
