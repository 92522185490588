import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import ListCountriesContainer from "./list-countries/ListCountriesContainer"
import Modal from "reactstrap/es/Modal"
import InputSearchCountries from "./InputSearchCountries"
import ModalHeader from "reactstrap/es/ModalHeader"
import ModalBody from "reactstrap/es/ModalBody"

class ManageCountriesContainer extends Component {
    state = {
        showModal: false,
        search: "",
    }

    _handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        })
    }

    _updateSearchState = (value) => {
        this.setState({
            search: value.trim(),
        })
    }

    render() {
        const {showModal, search} = this.state
        const {selectedCountries, zoneCountries, onUpdateSelectedCountries, onSelectRestOfWorld, restOfWorld} = this.props

        const {countries, restOfWorldAvailable} = zoneCountries

        return (
            <div className="ManageCountriesContainer">
                <button type="button" className="btn btn-primary btn-sm" onClick={this._handleToggleModal}>Manage
                </button>

                {
                    countries !== undefined &&
                    <Modal isOpen={showModal} className="modal-dialog-centered CountriesModal modal-lg">
                        <ModalHeader>Manage countries and regions</ModalHeader>

                        <ModalBody>
                            <InputSearchCountries search={search} onUpdateSearchState={this._updateSearchState}/>

                            <ListCountriesContainer selectedCountries={selectedCountries} countries={countries}
                                                    restOfWorld={restOfWorld}
                                                    search={search}
                                                    restOfWorldAvailable={restOfWorldAvailable}
                                                    onUpdateTempSelectedCountries={this._updateTempSelectedCountries}
                                                    onToggleModal={this._handleToggleModal}
                                                    onUpdateSelectedCountries={onUpdateSelectedCountries}
                                                    onSelectRestOfWorld={onSelectRestOfWorld}/>
                        </ModalBody>
                    </Modal>
                }
            </div>
        )
    }
}

// ManageCountriesContainer.defaultProps = {}
//
// ManageCountriesContainer.propTypes = {}

export default ManageCountriesContainer
