import React, {Component} from 'react'
import ProductAttributesList from './ProductAttributes/ProductAttributesList'
import {updateAttributesList} from '../../../services/api/ProductLinesServices'
import PropTypes from 'prop-types'
import {toaster} from '../../shared/PFToast'

class ProductAttributes extends Component {

    state = {
        loading: false
    }


    _updateAttributesList = async (e) => {
        e.preventDefault()

        this.setState({loading: true})

        const {productLineId, attributes, shouldWarning} = this.props

        try {

            const newAttributes = attributes.map(item => {
                return {
                    ...item,
                    value_type: item.value_type === "Pack" ? "Label" : item.value_type
                }
            })
            if(shouldWarning){
                toaster({
                    type: 'warning',
                    message: 'Make sure to fill new sku for all of newly updated variants',
                    duration: 1000 * 10
                })
            }

            const {success, message} = await updateAttributesList(productLineId, newAttributes)

            if (!success) throw new Error(message)

            this.setState({loading: false})

            toaster({
                type: 'success',
                message: 'Saving attributes successfully!',
                duration: 5000
            })

            this.props.onFetchListProductLine()

        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
                duration: 5000
            })
            this.setState({loading: false})
        }
    }

    _handleCreateAttribute = () => {
        this.props.onHandleCreateAttribute()
    }

    _handleChangeAttributesState = (index, field, value) => {
        this.props.onHandleChangeAttributesState(index, field, value)
    }

    _handleRemoveAttribute = (index) => {
        this.props.onHandleRemoveAttribute(index)
    }

    render() {
        const {attributes} = this.props
        const {loading} = this.state

        return (
            <div className="ProductAttributes mt-4">
                {
                    (Array.isArray(attributes) && attributes.length > 0) &&
                    <div className="SectionInner">
                        <h3 className="SectionHeading mb-3">Attributes</h3>

                        <ProductAttributesList attributes={attributes}
                                               onChangeAttributesState={this._handleChangeAttributesState}
                                               onRemoveAttribute={this._handleRemoveAttribute} {...this.props}/>
                        <div className="ProductAttributesActions">
                            <div className="d-flex">
                                <button className="btn btn-success mr-auto" onClick={this._handleCreateAttribute}>Add
                                    attribute
                                </button>
                                <button type="button" className="btn btn-primary" onClick={this._updateAttributesList}>
                                    {
                                        loading ? 'Saving...' : 'Save attributes'
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

ProductAttributes.propTypes = {
    productLineId: PropTypes.string.isRequired
}

export default ProductAttributes
