import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import ReactCountryFlag from 'react-country-flag'
import {deleteShippingZone} from '../../../services/api/ShippingSettingsServices'
import {confirmModal} from '../../shared/PFConfirmModalV2'
import {toaster} from '../../shared/PFToast'

class SettingShippingZoneItem extends Component {

    _handleClickDelete = () => {
        const {name} = this.props

        confirmModal({
            confirmText: 'Delete',
            cancelText: 'Cancel',
            confirmColor: 'danger',
            title: 'Delete Zone',
            message: <span>Are you sure you want to delete zone <b>{name}</b>?</span>,
            onOk: this._deleteShippingZone,
        })
    }

    _deleteShippingZone = async () => {
        const {zoneId, onGetShippingProfilesDetail} = this.props

        try {
            const {success, message} = await deleteShippingZone(zoneId)

            if (!success) throw new Error(message)

            toaster({message: 'Deleted shipping zone successfully!', type: 'success', duration: 5000})

            onGetShippingProfilesDetail()

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            toaster({message: message ? message : e.message, type: 'error', duration: 5000})
        }
    }

    render() {
        const {countries, rest_of_world, zoneId, name, rates, readOnly, profilesId} = this.props
        const countriesName = countries.map(country => country.name)

        return (
            <div className="ZoneItem pt-3 mt-3 border-top">
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-wrap align-items-baseline">
                        {
                            countries.length === 1 ?
                                <ReactCountryFlag svg code={countries[0].code}/>
                                :
                                <i className="fas fa-globe Icon"/>
                        }

                        <h6 className="ml-3 mb-0 Title">{name}</h6>

                        {
                            rest_of_world ?
                                <span className="text-muted ml-3">({countries.length} countries and regions.)</span>
                                :
                                <span className="text-muted ml-3">
                                    {
                                        countriesName.length < 5 ?
                                            countriesName.join(', ')
                                            :
                                            `(${countriesName.length} countries and regions.)`
                                    }
                                </span>
                        }
                    </div>
                    {
                        !readOnly &&
                        <div className='ActionZoneItem d-flex align-items-center'>
                            <Link className='ActionZoneItemEdit fs-14' to={`/a/settings/settings-shipping/${profilesId}/zones-edit/${zoneId}`}>Edit</Link>
                            <span className='cursor-pointer text-danger fs-14' onClick={this._handleClickDelete}>Delete</span>
                        </div>
                    }

                </div>

                {
                    rates.length <= 0 ?
                        <div className="text-muted NoRateText py-2">You need to add at least one shipping rate to accept
                            orders from
                            customers in this shipping zone.</div>
                        :
                        <ul className="ListShippingRates">
                            {
                                rates.map(rate => {
                                    const {title} = rate
                                    return (
                                        <li key={`${name}_${title}`}>
                                            <div>{title}</div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                }
            </div>
        )
    }
}

SettingShippingZoneItem.defaultProps = {
    readOnly: false,
    profilesId: '',
}

SettingShippingZoneItem.propTypes = {
    countries: PropTypes.array.isRequired,
    rest_of_world: PropTypes.bool.isRequired,
    rates: PropTypes.array.isRequired,
    zoneId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    profilesId: PropTypes.string,
    onGetShippingProfilesDetail: PropTypes.func,
}

export default SettingShippingZoneItem
