import React, {Component} from 'react'
import {Input} from 'reactstrap'
import {Link} from 'react-router-dom'
import TableContainer from './TableContainer'
import ResourceModal from './modal/ResourceModal'
import DeleteModal from './DeleteModal'

import {getListResources} from '../../../services/api/ResourceService'

class ResourcePage extends Component {
    state = {
        modal: {
            resource: {
                open: false,
                data: {},
            },
            remove: {
                open: false,
                data: {},
            }
        },
        items: [],
        loading: true,
        params: {
            page: 1,
            limit: 30,
        },
        total: 0,
        totalPage: 1,
        err: '',
        search_term: '',
    }

    componentDidMount() {
        this._getListResource()
    }

    _getListResource = async () => {
        try {
            const {params, search_term} = this.state
            const requestData = {search_term, ...params}
            const {success, data, message} = await getListResources(requestData)
            const {total, totalPage, resources} = Object.assign({}, data)

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            this.setState({
                items: resources,
                totalPage: totalPage,
                total: total,
                loading: false,
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _handlePageChange = page => {
        const {params} = this.state
        this.setState(
            {
                params: {...params, page},
            },
            () => this._getListResource()
        )
    }

    _handleSelectChange = (name, value) =>
        this.setState(
            {
                [name]: value,
            },
            () => this._getListClusters()
        )

    _handleInputChange = async e => {
        const value = e.target.value
        const timeOut = 500
        this.setState(
            {
                search_term: value,
                loading: true,
                totalPage: 1,
            },
            () => {
                clearInterval(this.typingTimer)
                this.typingTimer = setTimeout(this._getListResource, timeOut)
            }
        )
    }

    _onClickAddResource = () => {
        const {modal} = this.state
        this.setState({
            modal: {
                ...modal,
                resource: {
                    open: true,
                    data: {},
                }
            }
        })
    }

    _onClickDelele = (resource) => () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                remove: {
                    open: true,
                    data: resource,
                }
            }
        }))
    }

    _onClickEdit = (resource) => () => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                resource: {
                    open: true,
                    data: resource,
                }
            }
        }))
    }

    _onCloseModal = () => {
        this.setState(({modal}) => ({
            modal: {
                resource: {
                    ...modal.resource,
                    open: false,
                    data: {}
                },
                remove: {
                    ...modal.remove,
                    open: false,
                    data: {}
                }
            }
        }))
    }

    _onChangeResource = () => {
        const {modal} = this.state
        this._getListResource()
        this.setState({
            modal: {
                resource: {...modal.resource, open: false, data: {}},
                remove: {...modal.remove, open: false, data: {}},
            }
        })
    }

    _onAddNewResource = (newResource) => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                resource: {...modal.resource, open: false, data: {}}}
        }), () => this._getListResource())
    }

    _onRemoveResource = () => {
        const {modal, items} = this.state
        const {data} = modal.remove
        const newItems = items.filter((item) => item._id !== data._id)
        const remove = {data: {}, open: false}
        this.setState({
            items: newItems,
            modal: {
                ...modal,
                remove,
            }
        })
    }

    render() {
        const {resource, remove} = this.state.modal
        const {params, totalPage, items, loading, err} = this.state
        const {page, limit} = params
        const newItems = items.map(item => {
            return {
                ...item,
                onClickDelele: this._onClickDelele(item),
                onClickEdit: this._onClickEdit(item),
                onClickDelete: this._onClickDelele(item)
            }
        })

        return (
            <div className='ClustersPage'>
                <h2>Resources</h2>
                <div className='ClustersPageInner SectionInner'>
                    <div className='TopBar'>
                        <div className='Action'>
                                <button className="btn btn-primary" onClick={this._onClickAddResource}>
                                    <i className='fas fa-plus' /> Create Resource
                                </button>
                        </div>
                        <div className='SearchBar'>
                            <Input
                                onChange={this._handleInputChange}
                                type='text'
                                className='SearchInput'
                                placeholder='Enter name or kind'
                            />
                            <i className='fas fa-search SearchIcon' />
                        </div>

                    </div>
                    <br/>
                    {!!err && <span className='text-warning'>{err}</span>}
                    <TableContainer
                        page={page}
                        limit={limit}
                        totalPage={totalPage}
                        items={newItems}
                        loading={loading}
                        handlePageChange={this._handlePageChange}
                    />
                      <ResourceModal
                        onAddResouce={this._addNewPlan}
                        open={resource.open}
                        resource={resource.data}
                        onClose={this._onCloseModal}
                        onAddResource={this._onAddNewResource}
                        onChangeResource={this._onChangeResource}
                    />
                </div>
                <DeleteModal
                    open={remove.open}
                    resource={remove.data}
                    onClose={this._onCloseModal}
                    onOk={this._onRemoveResource}
                />
            </div>
        )
    }
}

export default ResourcePage
