import { debounce } from 'lodash'
import React, { useState } from 'react'
import { DATABASE_JOB_STATUS, DATABASE_JOB_TYPE } from '../constants'
import DateCustomFilter from '../../shared/DateCustomFilter'
import moment, {isMoment} from 'moment'
import classNames from 'classnames'
import {DateRangePicker} from 'react-dates'

const DatabaseJobsFilters = (props) => {
    const [selectFilter, setSelectFilter] = useState('store_slug')
    const {query, total, setQuery} = props
    const [focusedInput, setFocusedInput] = useState(null)
    const [focusedInputSuspend, setFocusedInputSuspend] = useState(null)

    const onChangeSelect = (event) => {
        const { name, value } = event.target
        setQuery({
            ...query,
            page: 1,
            [name]: value,
        })
    }

    const onChangeSearch = (event) => {
        const { name, value } = event.target
        setQuery((prev) => ({
            ...prev,
            page: 1,
            [name]: value ? value.trim() : '',
        }))
    }

    const onChangeSelectStore = (event) => {
        const { value } = event.target
        setSelectFilter(value)
        setQuery({
            ...query,
            store_slug: '',
            store_mongodb_cluster: '',
        })
    }

    const handleDateChange = ({startDate, endDate}) => {
        if (!startDate && !endDate) {
            const params = {
                ...query,
                created_at_end: '',
                created_at_start: '',
            }
            setQuery(params)
        } else {
            const params = {
                ...query,
                created_at_end: !!endDate ? moment(endDate).format('DD/MM/YYYY') : '',
                created_at_start: !!startDate ? moment(startDate).format('DD/MM/YYYY') : '',
            }
            setQuery(params)
        }
    }

    const handleDateChangeSuppend = ({startDate, endDate}) => {
        if (!startDate && !endDate) {
            const params = {
                ...query,
                last_suspend_at_end: '',
                last_suspend_at_start: '',
            }
            setQuery(params)
        } else {
            const params = {
                ...query,
                last_suspend_at_end: !!endDate ? moment(endDate).format('DD/MM/YYYY') : '',
                last_suspend_at_start: !!startDate ? moment(startDate).format('DD/MM/YYYY') : '',
            }
            setQuery(params)
        }
    }

    const onFocusChange = (focusedInput) => setFocusedInput(focusedInput)

    const onFocusChangeSuspend = (focusedInputSuspend) => setFocusedInputSuspend(focusedInputSuspend)

    return (
        <div className="GlobalDepositTableFilter mb-3">
            <div className="d-flex flex-wrap">
                <div className="form-group SearchInput d-flex align-items-center mr-3">
                    <div className="input-group-prepend">
                        <select
                            className="form-control"
                            style={{minWidth: '210px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                            value={selectFilter}
                            onChange={onChangeSelectStore}
                        >
                            <option value="store_slug">Store slug</option>
                            <option value="store_mongodb_cluster">Store mongodb cluster</option>
                        </select>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        name={selectFilter}
                        value={query[selectFilter]}
                        style={{minWidth: '120px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                        placeholder="Enter search..."
                        onChange={onChangeSearch}
                    />
                </div>
                <div className="TableLength mr-3">
                    Type
                    <select className="ml-2" name="type" value={query.type} onChange={onChangeSelect}>
                        {
                            DATABASE_JOB_TYPE.map((type) => (
                                <option value={type.value} key={type.value}>{type.label}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="TableLength mr-3">
                    Status
                    <select className="ml-2" name="status" value={query.status} onChange={onChangeSelect}>
                        {
                            DATABASE_JOB_STATUS.map((status) => (
                                <option value={status.value} key={status.value}>{status.label}</option>
                            ))
                        }
                    </select>
                </div>
                <div className="TableLength mr-3">
                    <div className="DateCustomFilter flex-nowrap d-flex">
                        <span className="d-inline-flex align-items-center mr-3 cursor-pointer">
                            Created at
                        </span>

                        <DateRangePicker
                            small
                            noBorder
                            showClearDates
                            enableOutsideDays
                            reopenPickerOnClearDates
                            minimumNights={0}
                            numberOfMonths={2}
                            startDate={query?.created_at_start ? moment(query?.created_at_start, 'DD/MM/YYYY') : ''}
                            startDateId='DatabaseJobsInputDate_from'
                            endDate={query?.created_at_end ? moment(query?.created_at_end, 'DD/MM/YYYY') : ''}
                            endDateId='DatabaseJobsInputDate_to'
                            onDatesChange={handleDateChange}
                            focusedInput={focusedInput}
                            onFocusChange={onFocusChange}
                            displayFormat="DD/MM/YYYY"
                        />
                    </div>
                </div>
                <div className="TableLength">
                    <div className="DateCustomFilter flex-nowrap d-flex">
                        <span className="d-inline-flex align-items-center mr-3 cursor-pointer">
                            Last suspend at
                        </span>

                        <DateRangePicker
                            small
                            noBorder
                            showClearDates
                            enableOutsideDays
                            reopenPickerOnClearDates
                            minimumNights={0}
                            numberOfMonths={2}
                            startDate={query?.last_suspend_at_start ? moment(query?.last_suspend_at_start, 'DD/MM/YYYY') : ''}
                            startDateId="_date_filter_from_suspend"
                            endDate={query?.last_suspend_at_end ? moment(query?.last_suspend_at_end, 'DD/MM/YYYY') : ''}
                            endDateId="_date_filter_to_suspend"
                            onDatesChange={handleDateChangeSuppend}
                            focusedInput={focusedInputSuspend}
                            onFocusChange={onFocusChangeSuspend}
                            displayFormat="DD/MM/YYYY"
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="TableLength mr-3">
                    Show
                    <select className="ml-2" name="limit" value={query.limit} onChange={onChangeSelect}>
                        <option value="10">10 rows</option>
                        <option value="20">20 rows</option>
                        <option value="50">50 rows</option>
                        <option value="100">100 rows</option>
                    </select>
                </div>
                <strong className="Entity text-right" style={{minWidth: '120px'}}>
                    {total} items
                </strong>
            </div>
        </div>
    )
}

export default DatabaseJobsFilters
