import React, {Component} from 'react'
import DetailReportInputDate from './filter/DetailReportInputDate'
import DetailReportInputStore from './filter/DetailReportInputStore'
import ExportActionContainer from './export/ExportActionContainer'

class DetailsReportPage extends Component {

    state = {
        storeID: '',
        detailsReportData: [],
        openingBalance: 0,
        formDate: {
            from: null,
            to: null
        },
        params: {
            page: 1,
            limit: 10,
            pages: 1
        },
        total_record: 0,
        current_balance: 0,
        error: '',
        loading: false,
        loadingExport: false,
        errorGetStore: ''
    }

    _handleChange = (property, value) => {
        this.setState({[property]: value})
    }

    render() {
        const {storeID} = this.state

        return (
            <div className='DetailReportPage'>
                <h2>Detailed Report</h2>

                <div className='DetailReportPageInner SectionInner'>
                    <div className='form-group AccountingReportInput d-flex flex-wrap align-items-center mb-4'>
                        <DetailReportInputStore onChange={this._handleChange}/>

                        <DetailReportInputDate onChange={this._handleChange}/>

                        <ExportActionContainer dateRange={this.state.formDate} storeID={storeID}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default DetailsReportPage
