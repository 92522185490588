import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getTotalActiveStoresByCluster} from "../../../../services/api/ClustersServices"

class TotalStoreColumn extends Component {
    state = {
        total: 0,
    }

    componentDidMount() {
        this._fetchTotalActiveStores()
    }

    _fetchTotalActiveStores = async () => {
        const {_id: clusterId} = this.props.item

        try {
            const {success, data, message} = await getTotalActiveStoresByCluster(clusterId)

            if (!success) throw new Error(message)

            this.setState({
                total: data > 0 ? parseInt(data, 10) : 0,
            })
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const {total} = this.state

        return (
            <td className="TotalStoreColumn">
                <span>{total || '--'}</span>
            </td>
        )
    }
}

TotalStoreColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default TotalStoreColumn
