import React, {useState} from 'react'
import CatalogAttributesList from './CatalogAttributesList'
import {toaster} from '../../../shared/PFToast'
import {updateProductCatalogAttribute} from '../../../../services/api/ProductLinesServices'

const CatalogAttributesContainer = (props) => {
    const {productId, attributes} = props
    const [loading, setLoading] = useState(false)

    const _updateAttributesList = async () => {
        try {
            setLoading(true)
            const {success, message} = await updateProductCatalogAttribute(productId, attributes)
            if (!success) throw new Error(message)
            toaster({
                type: 'success',
                message: 'Update Attributes successfully!',
            })
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="CatalogAttributesContainer mt-4">
            {Array.isArray(attributes) && attributes.length > 0 && (
                <div className="SectionInner">
                    <h3 className="SectionHeading mb-3">Attributes</h3>

                    <CatalogAttributesList attributes={attributes} {...props} />
                    <div className="ProductAttributesActions">
                        <div className="d-flex justify-content-end">
                            <button
                                type="button"
                                className="btn btn-primary"
                                disabled={loading}
                                onClick={_updateAttributesList}
                            >
                                {loading ? 'Saving...' : 'Save attributes'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CatalogAttributesContainer

