import React, {Component} from 'react'

// import PropTypes from 'prop-types'

class CostData extends Component {
    state = {
        cost: this.props.cost,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.cost !== this.props.cost) {
            this.setState({
                cost: this.props.cost,
            })
        }
    }

    _handChangeInput = e => {
        const {value} = e.target

        const {rowIndex, onUpdatePriceData} = this.props

        this.setState({
            cost: value,
        })

        onUpdatePriceData(rowIndex, "cost", Number(value))
    }

    _handlePressInput = e => {
        if (e.key === "Enter") {
            e.preventDefault()
        }
    }

    render() {
        const {cost} = this.state

        return (
            <td className="CostData">
                <div className="input-group">
                    <div className="input-group-prepend">
                        <div className="input-group-text">$</div>
                    </div>
                    <input type="number" step="any" className="form-control" value={cost} min={0}
                           onChange={this._handChangeInput} onKeyPress={this._handlePressInput}
                           required/>
                </div>
            </td>
        )
    }
}

// CostData.defaultProps = {}

// CostData.propTypes = {}

export default CostData
