import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ServiceListRow from "./ServiceListRow"

const serviceList = [
    {
        name: 'Order Management Service',
        url: '/order',
        completed: false,
    },
    {
        name: 'Product Management Service',
        url: '/product',
        completed: false,
    },
    {
        name: 'Google Shopping Integration',
        url: '/google-shopping',
        completed: false,
    },
    {
        name: 'Statistics Service',
        url: '/statistics',
        completed: false,
    },
    {
        name: 'Artwork Management Service',
        url: '/artwork',
        completed: false,
    },
    {
        name: 'Importer Service',
        url: '/importer',
        completed: false,
    },
    {
        name: 'Shipping Management Service',
        url: '/shipping',
        completed: false,
    },
    {
        name: 'Billing Management Service',
        url: '/billing',
        completed: false,
    },
    {
        name: 'Ebay Integration Service',
        url: '/ebay',
        completed: false,
    },
    {
        name: 'Woocommerce Integration Service',
        url: '/woocommerce',
        completed: false,
    },
    {
        name: 'Store Setting Service',
        url: '/setting',
        completed: false,
    },
]


class ServiceListBody extends Component {
    state = {
        services: [].concat([], serviceList),
        current: 0,
        result:{},
    }

    componentDidMount() {
        this._initializePing()
    }

    _setServiceNeedPing = index => {
        this.setState(({services}) => ({
            services: services.map((service, _index) => {
                if (index === _index) {
                    return Object.assign({}, service, {
                        needPing: true
                    })
                }

                return service
            }),
            current: index,
        }))
    }

    _initializePing = () => {
        const {current} = this.state
        console.log('Init', current)
        this._setServiceNeedPing(current)
    }


    _setResult = async (index, result) => {
        return new Promise(resolve => {
            this.setState(({services}) => ({
                services: services.map((service, _index) => {
                    if (index === _index) {
                        return Object.assign({}, service, {
                            completed: true,
                            needPing: false,
                            result
                        })
                    }

                    return service
                })
            }), resolve)
        })
    }

    _nextService = () => {
        const {current, services} = this.state
        const nextIndex = current + 1

        if (nextIndex >= services.length) {
            return true
        }

        this._setServiceNeedPing(nextIndex)
    }

    _handleComplete = async (index, result) => {
        await this._setResult(index, result)
        this.setState({result:result})
        this._nextService()
    }



    render() {
        const {services,result} = this.state
        const {storeDetails} = this.props
        return (
            <tbody className="ServiceListBody">
            {
                services.map((service, index) => {
                    return (
                        <ServiceListRow
                            onCompleted={this._handleComplete}
                            key={service.url}
                            service={service}
                            index={index}
                            result={result}
                            storeDetails={storeDetails}/>
                    )
                })
            }
            </tbody>
        )
    }
}

ServiceListBody.propTypes = {
    storeDetails: PropTypes.object.isRequired
}

export default ServiceListBody
