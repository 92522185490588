import React, {Component, Fragment} from 'react'
import PFTablePlaceholder from '../../../shared/PFTablePlaceholder'
import PropTypes from 'prop-types'
import moment from 'moment'
import humanizeTime from '../../../../helpers/time/humanizeTime'
import EditAffiliateCategory from '../action/EditAffiliateCategory'
import DeleteAffiliateCategory from '../action/DeleteAffiliateCategory'

class AffiliateTableBody extends Component {

    _validateDate = time => {
        if (!time) return ''
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }

    render() {
        const {affiliateCategory, error, loading, onGetAffiliateCategories} = this.props

        return (
            <tbody className='TableLoading'>
            {
                loading ?
                    <PFTablePlaceholder columnQuantity={5}/>
                    :
                    error ?
                        <tr>
                            <td colSpan={5}>
                                <div className='text-danger'>
                                    <b>ERROR:</b> {error}
                                </div>
                            </td>
                        </tr>
                        :
                        Array.isArray(affiliateCategory) && affiliateCategory.length === 0
                            ?
                            <tr>
                                <td colSpan={5}><i>No results</i></td>
                            </tr>
                            :
                            <Fragment>
                                {
                                    Array.isArray(affiliateCategory) && affiliateCategory.map((item, index) => {
                                        const {_id = '', name, percentage = '', updated = ''} = item

                                        return (
                                            <tr key={`AffiliateTableColumn_${index}`}>
                                                <td>{_id}</td>
                                                <td>{name}</td>
                                                <td className='text-right'>{(percentage || percentage === 0) ? `${percentage}%` : '-'}</td>
                                                <td className='pl-4'>{this._validateDate(updated) || '-'}</td>
                                                <td className='d-flex flex-wrap align-items-center'>
                                                    <EditAffiliateCategory
                                                        onGetAffiliateCategories={onGetAffiliateCategories}
                                                        percentage={percentage} name={name}
                                                        id={_id}/>
                                                    <DeleteAffiliateCategory
                                                        onGetAffiliateCategories={onGetAffiliateCategories} name={name}
                                                        id={_id}/>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </Fragment>
            }
            </tbody>
        )
    }
}

AffiliateTableBody.propTypes = {
    affiliateCategory: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired
}

export default AffiliateTableBody
