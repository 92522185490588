import React, {Component, Fragment} from 'react'

const _tableColumns = ['#', 'Name', 'Slug', 'Created']

class TableHeader extends Component {
    render() {
        return (
            <thead className="thead-light TableHeader">
                <tr>
                    {_tableColumns.map((column) => (
                        <Fragment key={column}>
                            <th scope="col">{column}</th>
                        </Fragment>
                    ))}
                </tr>
            </thead>
        )
    }
}

export default TableHeader

