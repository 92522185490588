import React from 'react'

const TableHead = () => {
    return (
        <thead className="thead-light">
            <tr>
                <th>#</th>
                <th>Affiliate</th>
                <th>Code</th>
                <th>Link</th>
                <th>Created</th>
                <th>Actions</th>
            </tr>
        </thead>
    )
}

export default TableHead
