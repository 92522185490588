import React, {Component} from 'react'
import ReturnPage from '../shared/ReturnPage'
import ProductMockUpJsonEditor from '../product-mockup/shared/ProductMockUpJsonEditor'
import {addNewProductMockup} from '../../services/api/ProductLinesServices'
import {toaster} from '../shared/PFToast'
import getHistory from '../../store/getHistory'
import classNames from 'classnames'

class ProductMockupNewPage extends Component {

    state = {
        loading: true,
        preview: {},
        meta: {},
        preview_meta: {},
        side_infos: {},
        title: '',
        product_type: '',
        errorObj: {},
        size_chart: {},
        wrap_type: '',
        preview_v2: {}
    }

    _scrollErrorInput = () => {
        const scrollableDiv = document.getElementsByClassName('invalid-feedback')
        scrollableDiv[0].scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
    }

    _validateTitle = (e) => {
        e.preventDefault()
        const {title, product_type, wrap_type} = this.state
        const titleFormat = title.trim()
        const productTypeFormat = product_type.trim()

        let errorKey = {}

        if (!titleFormat) {
            errorKey.title = 'This field is required.'
        }

        if (!productTypeFormat) {
            errorKey.product_type = 'This field is required.'
        }

        if (!wrap_type) {
            errorKey.wrap_type = 'This field is required.'
        }

        this.setState(({errorObj}) => ({
            errorObj: errorKey, ...errorObj
        }))

        if (Object.keys(errorKey).length > 0) return setTimeout(this._scrollErrorInput, 150)

        this._addNewMockUpDetail()
    }

    _addNewMockUpDetail = async () => {
        const {preview, meta, preview_meta, side_infos, title, product_type, size_chart, wrap_type, preview_v2} = this.state
        const params = {preview, meta, preview_meta, side_infos, title, product_type, size_chart, wrap_type, preview_v2}

        try {
            const {data, success, message} = await addNewProductMockup(params)

            if (!success) throw new Error(message)

            toaster({
                type: 'success',
                message: 'Created product mockup successfully!',
                duration: 5000
            })

            const history = getHistory()
            history.push(`/a/product-mockup/${data._id}`)

        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
                duration: 5000
            })
        }
    }

    _handleChangeInput = (e) => {
        e.preventDefault()

        const {name, value} = e.target

        let {errorObj} = this.state
        if (errorObj[name]) {
            delete errorObj[name]
        }

        this.setState({
            [name]: value
        })
    }

    _changeJSON = (property, data) => {
        this.setState({
            [property]: data
        })
    }


    render() {
        const {
            title,
            meta,
            preview_meta,
            preview,
            side_infos,
            product_type,
            errorObj,
            size_chart,
            wrap_type,
            preview_v2
        } = this.state

        return (
            <div className="ProductMockupNewPage">
                <ReturnPage url="/a/product-mockup" title="Product mockup"/>
                <h2>Add new product mockup</h2>
                <div className="ProductMockupDetailInner mt-4">

                    <div className="form-group">
                        <label htmlFor="TitleProductMockup" className="font-weight-bold">Title</label>
                        <input type="text"
                               className={classNames('form-control', {'is-invalid': errorObj.title})}
                               id="TitleProductMockup" name="title"
                               placeholder="Title product mockup ..."
                               value={title}
                               onChange={this._handleChangeInput}/>
                        {
                            errorObj.title &&
                            <div className="invalid-feedback">
                                {errorObj.title}
                            </div>
                        }

                    </div>

                    <div className="form-group">
                        <label className="font-weight-bold">Wrap type</label>
                        <select
                            value={wrap_type}
                            name="wrap_type"
                            className={classNames('form-control', {'is-invalid': errorObj.wrap_type})}
                            onChange={this._handleChangeInput}
                        >
                            <option disabled value="">Select wrap type ...</option>
                            <option value="flat">Flat</option>
                            <option value="curve">Curve</option>
                        </select>
                        {
                            errorObj.wrap_type &&
                            <div className="invalid-feedback">
                                {errorObj.wrap_type}
                            </div>
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="ProductTypeMockup" className="font-weight-bold">Product type</label>
                        <input type="text"
                               className={classNames('form-control', {'is-invalid': errorObj.product_type})}
                               id="ProductTypeMockup" name="product_type"
                               placeholder="Product type ..."
                               value={product_type}
                               onChange={this._handleChangeInput}/>
                        {
                            errorObj.product_type &&
                            <div className="invalid-feedback">
                                {errorObj.product_type}
                            </div>
                        }

                    </div>

                    <ProductMockUpJsonEditor title="Size chart " json={size_chart} property={'size_chart'}
                                             onChangeJSON={this._changeJSON}/>

                    <ProductMockUpJsonEditor title="Side infos" json={side_infos} property={'side_infos'}
                                             onChangeJSON={this._changeJSON}/>

                    <ProductMockUpJsonEditor title="Meta" json={meta} property={'meta'}
                                             onChangeJSON={this._changeJSON}/>

                    <ProductMockUpJsonEditor title="Preview" json={preview} property={'preview'}
                                             onChangeJSON={this._changeJSON}/>

                    <ProductMockUpJsonEditor title="Preview meta" json={preview_meta} property={'preview_meta'}
                                             onChangeJSON={this._changeJSON}/>

                    <ProductMockUpJsonEditor title='Preview V2' json={preview_v2} property={'preview_v2'}
                                             onChangeJSON={this._changeJSON}/>

                    <div className="Action d-flex justify-content-end mt-3">
                        <button className="btn btn-primary" onClick={this._validateTitle}>Add new product mockup
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductMockupNewPage
