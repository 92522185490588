import React from 'react'
import PropTypes from 'prop-types'
import {deleteOldDatabase} from '../../../services/api/StoreServices'
import ModalCommon from '../../setting-webhook/ModalCommon'

class ButtonDeleteOldDatabase extends React.Component {
    state = {
        isLoading: false,
        isShow: false,
    }

    _handleDeleteOldDatabase = async () => {
        this.setState((prev) => ({...prev, isShow: true}))
    }

    handleClickOk = async () => {
        try {
            this.setState((prev) => ({...prev, isLoading: true, isShow: false}))
            const {store} = this.props
            const {_id: storeId} = store

            const {success, message} = await deleteOldDatabase(storeId)
            if (!success){
                throw new Error(message)
            } else {
                alert(`The change has been successfully added to the queue.`)
            }
        } catch (error) {
            alert(error.message)
        }
        this.setState((prev) => ({...prev, isLoading: false}))
    }

    handleClose = () => {
        this.setState((prev) => ({...prev, isShow: false}))
    }

    render() {
        const {isLoading, isShow} = this.state

        return (
            <>
                <div className="ButtonDeleteOldDatabase d-inline-block">
                    <button
                        className="btn btn-danger btn-sm"
                        onClick={this._handleDeleteOldDatabase}
                        disabled={isLoading}
                        style={{ marginLeft: 10 }}
                    >
                        {isLoading && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                        Delete Old Db
                    </button>
                </div>
                <ModalCommon
                    isOpen={isShow}
                    title="Confirm Delete"
                    onClose={this.handleClose}
                    onSubmit={this.handleClickOk}
                >
                    This action will delete previous database of this store. Are you sure?
                </ModalCommon>
            </>
        )
    }
}

ButtonDeleteOldDatabase.propTypes = {
    isRestore: PropTypes.bool.isRequired,
    changeStoreDetail: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
}

export default ButtonDeleteOldDatabase
