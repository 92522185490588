import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import convertNameToSlug from '../utils/nameToSlug'
import PlanDetails from './plan-modal/PlanDetails'
import PlanFeatures from './plan-modal/PlanFeatures'
import {editPlan, createPlan} from '../../../services/api/PlanServices'
import defaultPlan from '../utils/defaultPlan'
import { toaster } from '../../shared/PFToast'

const LIMIT_FEATURE = 10

class PlanModal extends Component {
    state = {
        plan: {},
        loading: false,
        values: [],
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.open && this.props.open) this.setState({
            plan: {...defaultPlan, ...this.props.plan},
        })
    }

    _onSave = () => {
        const {plan, values} = this.state
        if (!plan.title || !plan.price || isNaN(+plan.price)) return null
        
        const supportFeatures = values.filter(v => v.is_supported)

        if (supportFeatures.length > LIMIT_FEATURE) {
            return toaster({
                type: 'error',
                message: `Maximum ${LIMIT_FEATURE} features`,
            })
        }
        const slug = convertNameToSlug(plan.title)

        this.setState(({plan}) => ({
            plan: {...plan, ...{slug, values}}
        }), () => this._savePlan())
    }

    _savePlan = async () => {
        const {plan} = this.state
        const {_id, ...planDetails} = plan

        this.setState({
            loading: true,
        })

        try {
            const work = (_id) ? editPlan(_id, planDetails) : createPlan(plan)
            const {success, data, message} = await work
            this.setState({
                loading: false,
            })

            if (success) {
                const {plan} = data
                if (!_id) return this.props.onAddPlan(plan)
                return this.props.onChangePlan()
            }

            alert(message)
        } catch (e) {
            this.setState({
                loading: false,
            })
            alert(e.message || e)
        }
    }

    _onToggle = () => {
        this.props.onClose()
    }

    _changePlan = (key, value) => {
        this.setState(({plan}) => ({
            plan: {...plan, [key]: value}
        }))
    }

    _changeFeatureValues = (values) => {
        this.setState({values})
    }

    render() {
        const {open, features} = this.props
        const {plan, loading} = this.state
        

        return (
            <Modal size='lg' isOpen={open} toggle={this._onToggle} className="PlanModal">
                <ModalHeader toggle={this._onToggle}>Plan</ModalHeader>
                <ModalBody>
                    <div className="PlanModal">
                        <PlanDetails plan={plan} onChangePlan={this._changePlan}/>
                        <PlanFeatures features={features} plan={plan} onChangePlan={this._changePlan}
                                      onChangeFeatureValues={this._changeFeatureValues}/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-outline-primary" onClick={this._onToggle}>Cancel</button>
                    <button className="btn btn-primary" disabled={loading} onClick={this._onSave}>Save</button>
                </ModalFooter>
            </Modal>
        )
    }
}

PlanModal.propTypes = {
    onChangeFeature: PropTypes.func.isRequired,
    features: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAddPlan: PropTypes.func.isRequired,
    onChangePlan: PropTypes.func.isRequired,
}

export default PlanModal
