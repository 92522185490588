import React, {Component} from 'react'
import {settings} from '../../../statics/settings'
import {Link, Redirect, Route, Switch} from 'react-router-dom'
import SettingGeneralContainer from '../../setting-general/components/SettingGeneralContainer'
import SettingMailContainer from '../../setting-mail/components/SettingMailContainer'
import SettingStorageContainer from '../../setting-storage/components/SettingStorageContainer'
import GlobalSettingsContainer from "../../setting-global/components/GlobalSettingsContainer"
import WebhookSettingsContainer from '../../setting-webhook'
import SettingDeliveryContainer from '../../setting-delivery/components/SettingDeliveryContainer'
import SettingDeliveryProfile from '../../setting-delivery/components/SettingDeliveryProfile'

class SettingPage extends Component {
    _getMenuTitle = () => {
        const {pathname} = this.props.location
        const currentMenu = settings.find(setting => setting.location === pathname)

        if (currentMenu) return currentMenu.title

        return 'Setting'
    }

    _renderBackButton = () => {
        const {pathname} = this.props.location
        if (pathname === '/a/settings/delivery-profile') {
            return <Link className="nav-link BackButton" to="/a/settings/delivery">
                <i className="fas fa-chevron-left" />
                <span>Delivery</span>
            </Link>
        }
        return <Link className="nav-link BackButton" to="/a/settings">
            <i className="fas fa-chevron-left" />
            <span>Settings</span>
        </Link>
    }

    _renderButton = () => {
        const {pathname} = this.props.location
        switch (pathname) {
            case '/a/settings/delivery':
                return (
                    <Link id="btn-create" className="btn btn-primary" to="/a/settings/delivery-profile">
                        Create delivery
                    </Link>
                )
            default:
                return null
        }
    }

    render() {
        return (
            <div className="SettingPage">
                <div className="container-fluid">
                    <div className="BackWrapper">
                        {this._renderBackButton()}
                    </div>
                    <div id="SettingMenuTitle" className="d-flex align-items-center justify-content-between MenuTitle">
                        {this._getMenuTitle()}
                        {this._renderButton()}
                    </div>
                    <Switch>
                        <Route exact path="/a/settings/general" component={SettingGeneralContainer}/>
                        <Route exact path="/a/settings/mail" component={SettingMailContainer}/>
                        <Route exact path="/a/settings/storage" component={SettingStorageContainer}/>
                        <Route exact path="/a/settings/global" component={GlobalSettingsContainer} />
                        <Route exact path="/a/settings/webhooks" component={WebhookSettingsContainer} />
                        <Route exact path="/a/settings/delivery" component={SettingDeliveryContainer} />
                        <Route exact path="/a/settings/delivery-profile" component={SettingDeliveryProfile} />
                        <Redirect to="/a/settings" />
                    </Switch>
                </div>
            </div>
        )
    }
}

export default SettingPage
