import React, {Component} from 'react'
import BalanceReportInputDate from './filter/BalanceReportInputDate'
import moment from 'moment'
import ExportActionContainer from './export/ExportActionContainer'
import ExportTypeSelector from './filter/ExportTypeSelector'

class BalanceReportPage extends Component {
    state = {
        formDate: {
            from: moment().startOf('month').utc().toISOString(),
            to: moment().startOf('day').utc().toISOString(),
        },
        type: 'all'
    }

    _handleChange = (propety, value) => {
        this.setState({[propety]: value})
    }

    render() {
        return (
            <div className='BalanceReportPage'>
                <h1 className='PageTitle'>All Store Report</h1>

                <div className='BalanceReportPageInner SectionInner'>
                    <div className='form-group AccountingReportInput d-flex flex-wrap align-items-center mb-4'>
                        <BalanceReportInputDate onChange={this._handleChange}/>

                        <ExportTypeSelector onChange={this._handleChange}/>

                        <ExportActionContainer dateRange={this.state.formDate} exportType={this.state.type}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default BalanceReportPage
