import React, {Component} from 'react'
import PropTypes from 'prop-types'
import IndexColumn from './columns/IndexColumn'
import BranchColumn from './columns/BranchColumn'
import CommitSHAColumn from './columns/CommitSHAColumn'
import CommitMessageColumn from './columns/CommitMessageColumn'
import RegistryURLColumn from './columns/RegistryURLColumn'
import GroupColumn from './columns/GroupColumn'
import NameColumn from './columns/NameColumn'
import CreatedColumn from './columns/CreatedColumn'

class RenderTableRow extends Component {
    render() {
        const {item, page, limit, index} = this.props
        const tableIndex = (page - 1) * limit + 1 + index

        return (
            <tr className='RenderTableRow'>
                <IndexColumn tableIndex={tableIndex} />
                <NameColumn item={item} />
                <GroupColumn item={item} />
                <BranchColumn item={item} />
                <CommitSHAColumn item={item} />
                <CommitMessageColumn item={item} />
                <RegistryURLColumn item={item} />
                <CreatedColumn item={item} />
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

export default RenderTableRow
