import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Card, CardBody, CardTitle} from 'reactstrap'

class RevenueBox extends Component {
    render() {
        const {formattedData} = this.props
        const {totalRevenue, averageOrderValue} = formattedData
        return (
            <div className='RevenueBox Box'>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>Revenue</h5>
                        </CardTitle>
                        <div className='card-item'>
                            <span>Revenue</span>
                            <span>${totalRevenue || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Average Order Value</span>
                            <span>${averageOrderValue || 0}</span>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

RevenueBox.propTypes = {
    formattedData: PropTypes.object.isRequired,
}

export default RevenueBox
