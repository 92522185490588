import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {setAccessToken, setRefreshToken, setRoles} from '../../../services/AuthServices'
import getHistory from "../../../store/getHistory"

class AuthPage extends Component {
    componentDidMount() {
        const {refreshToken, accessToken, roles} = this.props

        setRefreshToken(refreshToken)
        setAccessToken(accessToken)
        setRoles(roles)

        const history = getHistory()
        history.push('/a')
    }

    render() {
        return (
            <div className="AuthPage">
                Loading...
            </div>
        )
    }
}

AuthPage.propTypes = {
    refreshToken: PropTypes.string.isRequired,
    accessToken: PropTypes.string.isRequired,
}

export default AuthPage
