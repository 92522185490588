import React, {Component} from 'react'

// import PropTypes from 'prop-types'

class InputRateSubTitle extends Component {
    state = {
        subtitle: this.props.subtitle,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.subtitle !== this.props.subtitle) {
            this.setState({
                subtitle: this.props.subtitle,
            })
        }
    }

    _handleChangeInput = e => {
        const {value} = e.target

        this.setState({
            subtitle: value,
        })

        this.props.onUpdateRatesData("subtitle", value)
    }

    render() {
        const {subtitle} = this.state

        return (
            <div className="InputRateSubTitle">
                <label htmlFor="createRateSubTitle">Subtitle (optional)</label>
                <input type="text" className="form-control" id="createRateSubTitle" onChange={this._handleChangeInput} value={subtitle}/>
            </div>
        )
    }
}

// InputRateSubTitle.defaultProps = {}

// InputRateSubTitle.propTypes = {}

export default InputRateSubTitle
