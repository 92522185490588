import React, {Component} from 'react'
import {getDnsRecord, updateDnsRecord, createDnsRecord} from '../../../../services/api/ClustersServices'
import DnsRecordTable from './table/DnsRecordTable'

class DNSrecord extends Component {
    state = {
        records: '',
        Crecord: {
            type: '',
            name: '',
            content: '',
        },
        Arecord: {
            type: '',
            name: '',
            content: '',
        },
        modal: false,
        error: '',
        reloadLoading: false,
        createLoading: false,
    }

    componentDidMount() {
        this._fetchDnsRecord()
    }

    _fetchDnsRecord = async () => {
        const {clusterId} = this.props
        try {
            this.setState({
                fetchLoading: true,
            })
            const {data, success, message} = await getDnsRecord(clusterId)

            if (!success) {
                return this.setState({
                    error: message,
                })
            }

            let Are = {}
            let Cre = {}
            if (data[0] && data[0].length > 0) {
                Cre = data[0][0]
            }
            if (data[1] && data[1].length > 0) {
                Are = data[1][0]
            }

            return this.setState({
                error: '',

                Crecord: Cre,
                Arecord: Are,
            })
        } catch (e) {
            return this.setState({
                error: e.message,
            })
        }
    }

    _createDnsRecord = async () => {
        try {
            this.setState({
                createLoading: true,
            })
            const {clusterId} = this.props
            const {success, message} = await createDnsRecord(clusterId)
            if (!success) {
                return this.setState({
                    error: message,
                    createLoading: false,
                })
            }

            this.setState({
                createLoading: false,
            })
            return await this._fetchDnsRecord()
        } catch (error) {
            return this.setState({
                error: error.message,
                createLoading: true,
            })
        }
    }

    _reloadDnsRecord = async () => {
        const {clusterId} = this.props
        try {
            this.setState({
                reloadLoading: true,
            })

            const {success, message} = await updateDnsRecord(clusterId)

            if (!success) {
                return this.setState({
                    reloadLoading: false,
                    error: message,
                })
            }
            return this.setState({reloadLoading: false}, () => {
                this._fetchDnsRecord()
            })
        } catch (e) {
            return this.setState({
                error: e.message,
                reloadLoading: false,
            })
        }
    }

    render() {
        const {Crecord, Arecord, reloadLoading, createLoading, error} = this.state

        return (
            <div className='DnsRecordFormContainer'>
                {!!error && <span className='text-warning'>{error}</span>}
                <DnsRecordTable Arecord={Arecord} Crecord={Crecord} />
                <div className='d-flex justify-content-end'>
                    {createLoading ? (
                        <button className='btn btn-primary disabled mr-2' type='submit'>
                            Creating...
                        </button>
                    ) : (
                        <button className='btn btn-primary mr-2' onClick={this._createDnsRecord}>
                            Create Record
                        </button>
                    )}

                    {reloadLoading ? (
                        <button className='btn btn-primary disabled' type='submit'>
                            Reloading...
                        </button>
                    ) : (
                        <button className='btn btn-primary' onClick={this._reloadDnsRecord}>
                            Reload
                        </button>
                    )}
                </div>
            </div>
        )
    }
}

export default DNSrecord
