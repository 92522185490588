import React from 'react'

class TableHeader extends React.Component {
    render() {
        const headers = ['#', 'Key', 'Value', 'Actions']
        return (
            <thead className='thead-light'>
                <tr>
                    {headers.map((item, index) => (
                        <th scope='col' key={index}>{item}</th>
                    ))}
                </tr>
            </thead>
        )
    }
}

export default TableHeader
