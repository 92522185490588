import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import ReactCountryFlag from 'react-country-flag'
import className from 'classnames'


class CountryItem extends Component {
    state = {
        isChecked: this.props.isChecked,
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.restSelected !== this.props.restSelected && this.props.restSelected) {
            this.setState({
                isChecked: false,
            })
        }

        if (prevProps.isChecked !== this.props.isChecked) {
            this.setState({
                isChecked: this.props.isChecked,
            })
        }
    }

    _handleInputChange = e => {
        const {checked} = e.target
        const {onUpdateTempSelectedCountries, country, restSelected, onUncheckInputRest, onUnCheckGroupCountries} = this.props

        if (restSelected && checked) {
            onUncheckInputRest()
        }

        if (!checked) {
            onUnCheckGroupCountries()
        }

        this.setState({
            isChecked: checked,
        })

        const action = checked ? "add" : "remove"
        onUpdateTempSelectedCountries(country, action)
    }

    render() {
        const {country, isAvailable} = this.props
        const {isChecked} = this.state

        return (
            <li className={className("CountryItem border-top", {Disabled: !isAvailable && !this.props.isChecked})}>
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" id={`${country.name}_${country.code}`} className="custom-control-input"
                           onChange={this._handleInputChange} checked={isChecked || (!isAvailable && !this.props.isChecked)}/>

                    <label htmlFor={`${country.name}_${country.code}`}
                           className="custom-control-label d-inline-flex align-items-center">
                        <ReactCountryFlag code={country.code}/>
                        <span className="ml-3">{country.name}</span>
                    </label>
                </div>
            </li>
        )
    }
}

// CountryItem.defaultProps = {}

// CountryItem.propTypes = {}

export default CountryItem
