import createAPIServices from "./createApiServices"

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/auth' : 'https://pro.merch8.com/api/auth'
const api = createAPIServices({baseUrl})

export const logoutCurrentUser = () => {
    return api.makeAuthRequest({
        url: '/logout',
        method: 'POST'
    })
}
