import React from 'react'
import {Link} from 'react-router-dom'
import TemplateForm from '../../components/TemplateForm'

const CreateAmazonPage = () => {
    return (
        <div className="CreateAmazonPage">
            <Link className="nav-link BackButton pl-0" to="/a/amazon">
                <i className="fas fa-chevron-left mr-2"/>
                <span> Amazon Template</span>
            </Link>
            <h1 className="PageTitle">Create Amazon Template</h1>
            <div className="CreateAmazonPageInner">
                <TemplateForm/>
            </div>
        </div>
    )
}

export default CreateAmazonPage
