import React, {Component} from 'react'
import {CustomInput, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap'
import PropTypes from 'prop-types'
import productVariantsAttributes from '../../../../statics/product-statics/product-variant-attributes'
import ListVariantImages from './variant-image/ListVariantImages'
import className from 'classnames'
import {updateDefaultVariant} from '../../../../services/api/ProductServices'
import FeatureImage from '../../../shared/FeatureImage'

class VariantTable extends Component {
    constructor(props) {
        super(props)
        const columns = this._mapColumns()

        this.state = {
            selectedVariants: {},
            columns,
            showImageModal: false,
            filter: {},
            variants: [],
            bulkEditOpen: false
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.variants) !== JSON.stringify(this.props.variants)) {
            const mapVariantIds = this.props.variants
                .filter((variant) => variant._id)
                .reduce((accumulator, variant) => ({
                    ...accumulator,
                    [variant._id]: false
                }), {})
            this.setState({
                variants: [...this.props.variants],
                selectedVariants: mapVariantIds
            })
        }

        if (JSON.stringify(prevProps.variants) !== JSON.stringify(this.props.variants) || (JSON.stringify(prevProps.attributes) !== JSON.stringify(this.props.attributes))) {
            this.setState({
                columns: this._mapColumns()
            })
        }
    }

    _handleCloseImageModal = () => {
        this.setState({
            showImageModal: false
        })
    }

    _handleClickOpenImageModal = e => {
        const variantId = e.target.getAttribute('data-id')

        this.setState({
            showImageModal: variantId
        })
    }

    _mapColumns = () => {
        const {attributes} = this.props
        const [thumb, ...restAttributes] = productVariantsAttributes
        return [
            thumb,
            ...attributes.map((attribute) => ({
                type: 'attribute',
                title: attribute.name,
                name: attribute._id
            })),
            ...restAttributes
        ]
    }

    _checkVariant = (variantId) => (e) => {
        const {checked} = e.target

        this.setState(state => ({
            selectedVariants: {
                ...state.selectedVariants,
                [variantId]: checked
            }
        }))
    }

    _toggleCheckAllVariants = (e) => {
        const {checked} = e.target

        this.setState(state => {
            const selectedVariants = Object.keys(state.selectedVariants)
                .reduce((accumulator, key) => ({
                    ...accumulator,
                    [key]: checked
                }), {})
            return {
                selectedVariants
            }
        })
    }

    _changeInput = (variant, attribute, isNumber) => (e) => {
        const {variants} = this.props
        const {value} = e.target

        const newVariants = variants.map((item) => {
            if (item._id !== variant._id) return item

            return {
                ...item,
                [attribute]: (isNumber) ? parseFloat(value) : value
            }
        })

        this.props.onChange(newVariants)
    }

    _renderCell = (variant, attribute) => {
        switch (attribute.name) {
            case 'thumb':
                return (
                    <div className='VariantThumbnail' onClick={this._handleClickOpenImageModal} data-id={variant._id}>
                        <FeatureImage src={variant.image} className='border' width={40}/>
                    </div>
                )
            case 'select':
                const {selectedVariants} = this.state

                return <CustomInput
                    id={`check_${variant._id}`}
                    type="checkbox"
                    checked={selectedVariants[variant._id] || false}
                    onChange={this._checkVariant(variant._id)}
                />

            case 'retail_price':
                return
                // return (
                //     <div className="CurrencyCell d-flex align-items-baseline">
                //         {
                //             <input
                //                 className="Cell form-control"
                //                 type="number"
                //                 min={0}
                //                 step="any"
                //                 value={variant[attribute.name] || ''}
                //                 onChange={this._changeInput(variant, attribute.name)}
                //                 disabled
                //             />
                //         }
                //     </div>
                // )

            case 'weight':
                return (
                    <div className="WeightCell d-flex align-items-baseline">
                        {
                            <input
                                className="Cell form-control"
                                type="number"
                                min={0}
                                step="any"
                                value={variant.weight || 0}
                                onChange={this._changeInput(variant, attribute.name)}
                                disabled={true}
                            />
                        }
                    </div>
                )

            case 'sku':
                return (
                    <div className="WeightCell d-flex align-items-baseline">
                        {
                            <input
                                className="Cell form-control"
                                type="text"
                                value={variant[attribute.name] || ''}
                                onChange={this._changeInput(variant, attribute.name)}
                                disabled
                            />
                        }
                    </div>
                )
            case 'base_cost':
                return (
                    <div className="BaseCostCell Cell CellDisabled">
                        {variant.base_cost}
                    </div>
                )

            default:
                if (attribute.type === 'attribute') {
                    const {attributes} = this.props
                    const option = variant.options.find(option => {
                        return option.attribute === attribute.name
                    })

                    if (!option) {
                        return null
                    }

                    const productAttribute = attributes.find(attr => attr._id === attribute.name)
                    if (!productAttribute) {
                        return null
                    }

                    const item = productAttribute.values.find(attr => attr.slug === option.slug)
                    if (!item) {
                        return null
                    }
                    return <div>{item.name}</div>
                }
                return <div>{variant[attribute.name] || ''}</div>
        }
    }

    _checkBulkPrice = (variant) => (e) => {
        const {checked: value} = e.target
        return this.props.onCheckBulkEdit(variant, value)
    }

    _onChangeFilterChange = (name) => (e) => {
        const {value} = e.target
        const {filter} = this.state
        this.props.onSubmitVariantsFilter(name, value)
        this.setState({
            filter: {...filter, [name]: value}
        })
    }

    _filterVariants = () => {
        const {variants, attributes} = this.props
        const {filter} = this.state

        const attributeIndex = {}
        for (const key in filter) {
            attributeIndex[key] = attributes.findIndex((attribute) => attribute.name === key)
        }

        const filteredVariants = variants.filter((variant) => {
            return Object.keys(filter).every((key) => {
                const filterValue = filter[key]
                if (!filterValue) return true

                const {options} = variant
                const filterIndex = attributeIndex[key]
                return options[filterIndex].slug === filterValue
            })
        })

        this.setState({
            variants: filteredVariants
        })
    }

    _toggleBulkEditMenu = () => {
        const {bulkEditOpen} = this.state
        this.setState({
            bulkEditOpen: !bulkEditOpen
        })
    }

    _changeDefaultVariant = async () => {
        try {
            const {selectedVariant} = this.props

            if (selectedVariant.length !== 1) {
                return
            }

            const variantId = selectedVariant[0]

            const {product} = this.props
            const {success, message} = await updateDefaultVariant(product._id, variantId)

            if (!success) {
                throw new Error(message)
            }

            this.props.onGetVariants()

        } catch (e) {
            window.alert(e.message)
        } finally {
            this.props.onCancelBulkUpdate()
        }
    }

    _onClickEditPrice = () => {
        this.props.toggleBulkPrice('retail_price')
    }

    _handleClickEditWeight = () => {
        this._toggleBulkEditMenu()
        this.props.toggleBulkPrice('weight')
    }

    _renderBulkEdit = () => {
        const {selectedVariant, editable} = this.props

        return (
            <div className="VariantsBulkEdit">
                <UncontrolledDropdown>
                    <DropdownToggle tag='span' className='btn btn-sm btn-outline-secondary'>
                        Actions
                    </DropdownToggle>

                    <DropdownMenu>
                        <DropdownItem onClick={this._onClickEditPrice}>Change price</DropdownItem>
                        <DropdownItem onClick={this._handleClickEditWeight}>Change weight</DropdownItem>
                        <DropdownItem divider/>

                        <DropdownItem disabled={selectedVariant.length !== 1 || !editable}
                                      onClick={this._changeDefaultVariant}>Mark as default</DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        )
    }

    _renderFilters = () => {
        const {attributes} = this.props
        const {filter} = this.state

        return <tr>
            <th/>
            <th>Filter</th>
            {attributes.map((attribute, i) => {
                const value = filter[attribute.name] || ''
                return (
                    <th key={`attribute_filter_${attribute.name || i}`}>
                        <select className="form-control" value={value}
                                onChange={this._onChangeFilterChange(attribute.name)}>
                            {this._renderAttributeOptions(attribute)}
                        </select>
                    </th>
                )
            })}
            <th/>
            <th/>
            <th/>
        </tr>
    }

    _renderAttributeOptions = (attribute) => {
        const {values} = attribute

        const options = values ? [{slug: '', name: 'All'}, ...values] : [{slug: '', value: 'All'}]
        return options.map((value, i) => <option value={value.slug} key={`attribute_option_${attribute.name}_${i}`}>
            {value.name}
        </option>)
    }

    render() {
        const {onGetVariants, variantPage, selectedVariant, variants, editable} = this.props
        const {selectedVariants, columns, showImageModal, bulkEditOpen} = this.state
        const selectedVariantMap = selectedVariant.reduce((result, variant) => ({
            ...result,
            [variant]: true
        }), {})
        const allVariantsSelected = variants.every((item) => selectedVariantMap[item._id])
        const isEditing = !!(selectedVariant.length)
        return (
            <div className="VariantTableWrapper merchize-payment">
                <div className="table-responsive">
                    <table className="table VariantTable">
                        <thead className="thead-light">
                        <tr>
                            <th className='w-1px'>
                                <div className="SelectAllVariants">
                                    <div className="SelectAllCheckbox">
                                        <CustomInput
                                            checked={allVariantsSelected || false} id={`bulk_price_ALL`} type="checkbox"
                                            onChange={this._checkBulkPrice('ALL')}
                                            value={allVariantsSelected || false}
                                            disabled={!editable}
                                        />
                                    </div>
                                    {(isEditing) && this._renderBulkEdit()}
                                </div>
                            </th>
                            {columns.map((item, id) => <th
                                key={id}
                                scope="col"
                                className={item.className || ''}
                            >
                                {(item.name === 'select') ? <CustomInput
                                    type="checkbox"
                                    id="all-variants"
                                    checked={Object.keys(selectedVariants).every(item => selectedVariants[item]) || false}
                                    onChange={this._toggleCheckAllVariants}
                                /> : <span>
                            {item.title}
                            </span>}
                            </th>)}
                        </tr>
                        </thead>
                        <tbody>
                        {this._renderFilters()}
                        {(!variants || !variants.length) ? null : variants.map((variant, index) => <tr
                            key={index}
                            className={className('Row', {isDefault: variant.is_default || false})}
                        >
                            <td>
                                <CustomInput
                                    id={`bulk_price_${index}`} type="checkbox"
                                    value={selectedVariantMap[variant._id] || false}
                                    onChange={this._checkBulkPrice(variant._id)}
                                    checked={selectedVariantMap[variant._id] || false}
                                    disabled={!editable}
                                />
                            </td>
                            {columns.map((attribute, idx) => <td
                                key={idx}
                            >
                                {this._renderCell(variant, attribute)}
                            </td>)}
                        </tr>)}
                        </tbody>
                    </table>
                </div>

                {
                    !!showImageModal &&
                    <ListVariantImages
                        {...this.props}
                        variantId={showImageModal}
                        onGetVariants={onGetVariants}
                        variantPage={variantPage}
                        onCloseImageModal={this._handleCloseImageModal}
                    />
                }

                {
                    bulkEditOpen && <div className="VariantsPopupBackground" onClick={this._toggleBulkEditMenu}/>
                }
            </div>
        )
    }
}

VariantTable.defaultProps = {
    variants: [],
    attributes: [],
    variantPage: 1
}

VariantTable.propTypes = {
    onChange: PropTypes.func.isRequired,
    variants: PropTypes.array,
    attributes: PropTypes.array,
    variantPage: PropTypes.number.isRequired,
    bulkEdit: PropTypes.bool.isRequired,
    selectedVariant: PropTypes.array.isRequired,
    onCheckBulkEdit: PropTypes.func.isRequired,
    toggleBulkPrice: PropTypes.func.isRequired,
    onSubmitVariantsFilter: PropTypes.func.isRequired,
    onCancelBulkUpdate: PropTypes.func.isRequired,
    onGetVariants: PropTypes.func.isRequired
}

export default VariantTable
