import React, {Component} from 'react'
import PropTypes from 'prop-types'
import JobRow from './JobRow'

const _tableColumns = [
    'Status',
    'Name',
    'Triggerer',
    'Pipelines',
    'Actions'
]


class JobTable extends Component {
    render() {
        const {items, loading, onClickStage, toggleReset, store } = this.props
        return (
            <table className='table'>
                <thead className='TableHeader thead-light'>
                    <tr>
                        {_tableColumns.map(column => (
                            <th scope='col' key={column}>
                                {column}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className='TableBody'>
                {!loading &&
                    items.map((item, index) => (
                        <JobRow item={{...item, index: index + 1}} key={item._id} onClickStage={onClickStage} toggleReset={toggleReset} store={store} />
                    ))}
                </tbody>
           </table>
        )
    }
}

JobTable.propTypes = {
    items: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    onClickStage: PropTypes.func.isRequired
}

export default JobTable
