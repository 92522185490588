import React, {useEffect, useState} from 'react'
import {Button, ModalHeader, ModalFooter, Modal, ModalBody} from 'reactstrap'
import {getImportLogs} from '../../../../../services/api/MappingRulesServices'
import TablePagination from './TablePagination'
import TableContainer from './TableContainer'
import ImportLogsFilters from './ImportLogsFiltes'
import moment from 'moment'

const ImportLogsModal = (props) => {
    const {isOpen, toggle, header} = props
    const [error, setError] = useState('')
    const [histories, setHistories] = useState([])
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [totalPage, setTotalPage] = useState(1)
    const [filter, setFilter] = useState({})

    const _handleInputChange = (e) => {
        const {value} = e.target
        setFilter({
            ...filter,
            fileName: value
        })
    }

    const _handleDateChange = ({from, to}) => {
        if (!from && !to) {
            setFilter({
                ...filter,
                fromDate: '',
                toDate: '',
            })
        } else {
            const fromDate = moment(from).format('YYYY-MM-DD')
            const toDate = moment(to).format('YYYY-MM-DD')
            setFilter({
                ...filter,
                fromDate,
                toDate,
            })
        }
    }

    const _handlePageChange = (page) => {
        setPage(page)
    }

    const _fetchHistories = async () => {
        try {
            setLoading(true)
            const {data, success, message} = await getImportLogs({...filter, page, limit})
            if (!success) {
                return setError(message)
            }
            const {histories, totalPages, total} = data
            setTotal(total)
            setTotalPage(totalPages)
            setHistories(histories)
        } catch (e) {
            console.log(e.message)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        _fetchHistories()
    }, [page, filter])

    return (
        <div>
            <Modal className="cashback-modal" isOpen={isOpen} toggle={toggle} centered size="lg" backdrop="static">
                <ModalHeader toggle={toggle}>{header}</ModalHeader>
                <ModalBody>
                    <ImportLogsFilters
                        handleInputChange={_handleInputChange}
                        handleDateChange={_handleDateChange}
                        query={{startDate: filter.fromDate || null, endDate: filter.toDate || null}}
                    />
                    <div style={{height: '300px', overflow: 'auto'}}>
                        <TableContainer items={histories} page={page} limit={limit} loading={loading} />
                    </div>
                    <TablePagination page={page} pages={totalPage} handlePageChange={_handlePageChange} />
                    {error && <div className='text-danger'>{error}</div>}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ImportLogsModal
