import {toaster} from '../../shared/PFToast'

export function toastSuccess(message, duration = 5000) {
    _toast(message, 'success', duration)
}

export function toastError(message, duration = 5000) {
    _toast(message, 'error', duration)
}

export function toastWaring(message, duration = 5000) {
    _toast(message, 'warning', duration)
}

const _toast = (message, type = 'success', duration = 5000) => {
    toaster({message, type, duration})
}
