import React, {Component} from 'react'
import PropTypes from 'prop-types'
import IndexColumn from './columns/IndexColumn'
import TypeColumn from './columns/TypeColumn'
import ProductLinesColumn from './columns/ProductLinesColumn'
import ActiveColumn from './columns/ActiveColumn'
import ImageColumn from './columns/ImageColumn'
import CurrencyColumn from './columns/CurrencyColumn'
import CategoryLinesColumn from './columns/CategoryLinesColumn'
import StoreModeProductLines from './columns/StoreModeProductLines'
import ProductMockupColumn from './columns/ProductMockupColumn'
import FulfillmentProductTypeColumn from './columns/FulfillmentProductTypeColumn'

class RenderTableRow extends Component {
    render() {
        const {item, page, limit, index} = this.props
        const tableIndex = limit * (page - 1) + 1 + index
        const {affiliate_category = {}} = item
        const {name = '_'} = affiliate_category !== null && affiliate_category

        return (
            <tr>
                <IndexColumn index={tableIndex}/>
                <ImageColumn item={item}/>
                <ProductLinesColumn item={item}/>
                <ProductMockupColumn item={item.product_mockup}/>
                <TypeColumn item={item}/>
                <FulfillmentProductTypeColumn item={item} />
                <ActiveColumn item={item}/>
                <CurrencyColumn item={item}/>
                <CategoryLinesColumn item={item}/>
                <td className="IndexColumn">
                    <span>{name || ''}</span>
                </td>
                <StoreModeProductLines item={item}/>
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired
}

export default RenderTableRow
