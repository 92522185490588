export default [{
    title: '',
    name: 'thumb',
    width: 10
}, {
    title: 'SKU',
    name: 'sku',
    width: 18
}, {
    title: 'Weight (gram)',
    name: 'weight',
    width: 18
}, {
    title: 'Base cost',
    name: 'base_cost',
    width: 10
},
// {
//     title: 'Price (USD)',
//     name: 'retail_price',
//     width: 18
// }
]
