import React, {Component} from 'react'
import StoreDepositsPage from './StoreDepositsPage'

class StoreDepositsContainer extends Component {
    render() {
        const {id} = this.props.match.params

        return (
            <div className="StoreDepositsContainer">
                <StoreDepositsPage storeId={id}/>
            </div>
        )
    }
}

export default StoreDepositsContainer
