import React, {Component} from 'react'
import PFBoxUploader from '../../../shared/PFBoxUploader'

class ProductGalleryUploader extends Component {

    render() {
        return (
            <div className='ProductGalleryUploader ProductApprovalGalleryUploader'>
                <PFBoxUploader
                    id='productGalleryUploader'
                    multiple
                    disabled={true}
                >
                    <div className='BoxUploader'>
                        <div className='Icon'>
                            <i className='ti-cloud-up'/>
                        </div>
                        <div className='Text'>
                            Upload images
                        </div>
                    </div>
                </PFBoxUploader>
            </div>
        )
    }
}

ProductGalleryUploader.propTypes = {
    // onFetchGallery: PropTypes.func.isRequired,
}

export default ProductGalleryUploader
