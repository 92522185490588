import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Link from 'react-router-dom/Link';

class ProductMockupColumn extends Component {
    render() {
        const {item} = this.props
        const {title,_id} = item

        return (
            <td className = "ProductMockupColumn">
                <Link to={`/a/product-mockup/${_id}`}>{title}</Link>
            </td>
        )
    }
}

ProductMockupColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ProductMockupColumn
