import React, { useState } from 'react'
import { DEPOSIT_STATUS } from '../constants'

const GlobalDepositTableFilter = (props) => {

    const {query, total, setQuery} = props
    const [selectFilter, setSelectFilter] = useState('store_slug')

    const onChangeSelect = (event) => {
        const { name, value } = event.target
        setQuery({
            ...query,
            page: 1,
            [name]: value,
        })
    }

    const onChangeSearch = (event) => {
        const { name, value } = event.target
        setQuery((prev) => ({
            ...prev,
            page: 1,
            [name]: value ? value.trim() : '',
        }))
    }

    const onChangeSelectStore = (event) => {
        const { value } = event.target
        setSelectFilter(value)
        setQuery({
            ...query,
            store_slug: '',
            store_domain: '',
        })
    }

    return (
        <div className="GlobalDepositTableFilter d-flex align-items-center justify-content-between mb-2">
            <div className="d-flex flex-wrap">
                <div className="TableLength mr-3">
                    Show
                    <select className="ml-2" name="limit" value={query.limit} onChange={onChangeSelect}>
                        <option value="10">10 rows</option>
                        <option value="20">20 rows</option>
                        <option value="50">50 rows</option>
                        <option value="100">100 rows</option>
                    </select>
                </div>
                <div className="form-group SearchInput d-flex align-items-center mr-3">
                    <div className="input-group-prepend">
                        <select
                            className="form-control"
                            style={{minWidth: '150px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                            value={selectFilter}
                            onChange={onChangeSelectStore}
                        >
                            <option value="store_slug">Store slug</option>
                            <option value="store_domain">Domain</option>
                            <option value="transaction_id">Transaction ID</option>
                        </select>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        name={selectFilter}
                        value={query[selectFilter]}
                        style={{minWidth: '120px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                        placeholder="Enter search..."
                        onChange={onChangeSearch}
                    />
                </div>
                <div className="TableLength">
                    Status
                    <select className="ml-2" name="status" value={query.status} onChange={onChangeSelect}>
                        {
                            DEPOSIT_STATUS.map((status) => (
                                <option value={status.value} key={status.value}>{status.label}</option>
                            ))
                        }
                    </select>
                </div>
            </div>
            <strong className="Entity text-right" style={{minWidth: '120px'}}>
                {total} items
            </strong>
        </div>
    )
}

export default GlobalDepositTableFilter
