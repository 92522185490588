import React, {useState} from 'react'
import BaseCostTableRow from './BaseCostTableRow'
import {randomID} from '../../../../../helpers/common/randomID'
import BaseCostScopeModal from './BaseCostScopeModal'

const BaseCostTable = (props) => {
    const {variants, attributes, catalog} = props
    const [openScope, setOpenScope] = useState({
        open: false,
        id: '',
        applied_stores: []
    })

    if (!variants.length) return null

    const _toggleChangeScope = (id = '', applied_stores = []) => {
        setOpenScope((prev) => ({...prev, open: !prev.open, id, applied_stores}))
    }

    return (
        <div className="BaseCostTable table-responsive">
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        {!!attributes.length &&
                            attributes.map((attribute) => {
                                const _id = randomID()
                                return (
                                    <th key={_id} className="text-capitalize">
                                        {attribute.name}
                                    </th>
                                )
                            })}
                        <th>SKU</th>
                        <th>Tier 1</th>
                        <th>Tier 2</th>
                        <th>Tier 3</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <BaseCostTableRow variants={variants} toggleChangeScope={_toggleChangeScope} {...props} />
            </table>
            {openScope.open && (
                <BaseCostScopeModal id={openScope.id} catalog={catalog} isOpen={openScope.open} appliedStores={openScope.applied_stores} toggle={_toggleChangeScope} />
            )}
        </div>
    )
}

export default BaseCostTable

