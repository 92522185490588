import React, { useEffect, useState } from "react"
import { getListGroups } from "../../../services/api/ClustersServices"
import { toaster } from "../../shared/PFToast"

const GroupFilterSelect = (props) => {

    const [groups, setGroups] = useState([])
    const { onChange, value, clusterId } = props

    useEffect(() => {
        if (clusterId) {
            fetchGroups(clusterId)
        }
    }, [clusterId])

    const fetchGroups = async (clusterId) => {
        try {
            const { data, success, message } = await getListGroups(clusterId)
            if (success) {
                setGroups(data.groups)
            } else {
                toaster({
                    type: "success",
                    message
                })
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="ResourceFilterSelect mr-3">
            <p className="mb-0">Group</p>
            <select name="groupId" onChange={onChange} value={value} disabled={!clusterId}>
                <option value="">All</option>
                {
                    groups.length > 0 && groups.map(group => (
                        <option key={group._id} value={group._id}>{group.namespace}</option>
                    ))
                }
            </select>
        </div>
    )
}

export default GroupFilterSelect