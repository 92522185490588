import React from 'react'
import IndexColumn from './columns/IndexColumn'
import KeyColumn from './columns/KeyColumn'
import ValueColumn from './columns/ValueColumn'
import ActionColumn from './columns/ActionColumn'

class TableBody extends React.Component {
    render() {
        const {configs, updateGroupConfigs, configArray, page} = this.props

        return (
            <tbody className="TableBody">
                {configArray.map((item, index) => {
                    return (
                        <tr key={item.key}>
                            <IndexColumn index={index + 1} />
                            <KeyColumn keyValue={item.key} />
                            <ValueColumn value={item.value} />
                            <ActionColumn
                                itemConfig={item}
                                updateGroupConfigs={updateGroupConfigs}
                                configs={configs}
                                {...this.props}
                            />
                        </tr>
                    )
                })}
            </tbody>
        )
    }
}

export default TableBody

