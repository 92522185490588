import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const TableRowAMInfo = (props) => {

    const {am_updated} = props
    const {account = {}, date = ''} = Object.assign({}, am_updated)
    const {email = ''} = Object.assign({}, account)
    return (
        <td>
            <p className="mb-0">{email && email}</p>
            <span className="text-muted text-uppercase">{date && moment(date).format('hh:mm a DD/MM/YYYY')}</span>
        </td>
    )

}

TableRowAMInfo.propTypes = {
    am_updated: PropTypes.object,
}

export default TableRowAMInfo
