import {createStoreAPI} from './createStoreAPIService'

const api = createStoreAPI({suffix: '/stores'})

export const getStoreSettings = ({storeId = '', page = 1, limit = 100}) =>
    api.makeAuthRequest({
        url: `/${storeId}/settings`,
        method: 'GET',
        params: {
            page,
            limit,
        },
    })

export const updateStoreSettings = ({storeId = '', keyObj = {}}) =>
    api.makeAuthRequest({
        url: `/${storeId}/settings`,
        method: 'POST',
        data: keyObj,
    })

export const removeStoreSettings = ({storeId = '', key = ''}) =>
    api.makeAuthRequest({
        url: `/${storeId}/settings`,
        method: 'DELETE',
        data: {
            key,
        },
    })

export const getImporterWorker = ({storeId = ''}) =>
    api.makeAuthRequest({
        url: `/${storeId}/settings/importer-worker`,
        method: 'GET',
    })

export const updateImporterWorker = ({storeId = '', data = {}}) =>
    api.makeAuthRequest({
        url: `/${storeId}/settings/importer-worker`,
        method: 'POST',
        data,
    })
