import React, {Component} from 'react'
import PropTypes from 'prop-types'
import DnsRecordRow from './DnsRecordRow'

class DnsRecordBody extends Component {
    render() {
        const {Arecord, Crecord} = this.props

        return (
            <tbody className='DnsRecordBody'>
                <DnsRecordRow key={1} record={Crecord} />
                <DnsRecordRow key={2} record={Arecord} />
            </tbody>
        )
    }
}

DnsRecordBody.propTypes = {
    Arecord: PropTypes.object.isRequired,
    Crecord: PropTypes.object.isRequired,
}

export default DnsRecordBody
