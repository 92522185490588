import React, {Component} from 'react'
import PropTypes from 'prop-types'

class DeleteColumn extends Component {
    render() {
        const {item} = this.props
        const {is_active} = item
        let noti =''
        if(is_active){
            noti ='true'
        }
        else{
            noti = 'false'
        }

        return (
            <td className='DeleteColumn'>
                {noti}
            </td>
        )
    }
}

DeleteColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default DeleteColumn
