import React from 'react'
import ProductVariantFilter from '../ProductVariantFilter'
import CreatableSelect from 'react-select/creatable'
import {randomID} from '../../../../../helpers/common/randomID'

const RelatedProductTableRow = (props) => {
    const {variants, attributes, filter, handleFilterVariants, tab, updateFilter} = props

    return (
        <tbody>
            <ProductVariantFilter
                tab={tab}
                attributes={attributes}
                filter={filter}
                updateFilter={updateFilter}
                handleFilterVariants={handleFilterVariants}
            />
            {!!variants.length &&
                variants.map((variant, index) => {
                    const {sku, attributes, applied_products = []} = variant
                    return (
                        <tr key={`related_product_${index}`}>
                            {!!attributes.length &&
                                attributes.map((attribute) => {
                                    const _id = randomID()
                                    return <td key={_id}>{attribute.value_text}</td>
                                })}
                            <td>{sku}</td>
                            <td>
                                <CreatableSelect
                                    isMulti={true}
                                    isClearable={false}
                                    isDisabled={true}
                                    placeholder="No related product"
                                    components={{
                                        IndicatorSeparator: () => null,
                                        DropdownIndicator: () => null,
                                    }}
                                    value={applied_products.map((product) => {
                                        return {
                                            label: product.title,
                                            value: product._id,
                                        }
                                    })}
                                />
                            </td>
                        </tr>
                    )
                })}
        </tbody>
    )
}

export default RelatedProductTableRow

