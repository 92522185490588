import React, { useState } from 'react'
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap'

const AllJobSelection = (props) => {
    const { selected, setSelected, setModal, data } = props

    const checkAllSelected = () => {
        return selected.length === data.length && data.length > 0
    }

    const handleChange = () => {
        const select = checkAllSelected()
        const ids = data.map(item => item._id)
        if (!select) {
            setSelected(ids)
        } else {
            setSelected([])
        }
    }

    const onClickDropdown = () => {
        setModal(true)
    }

    return (
        <th className='AllJobSelection w-1px'>
            <div className='d-flex'>
                <div className='custom-control custom-checkbox'>
                    <input type='checkbox' className='custom-control-input' id='AllOrderSelectionHead'
                        onChange={handleChange} />
                    <label htmlFor='AllOrderSelectionHead' className='custom-control-label' />
                </div>
                <div className='JobBulkMenu'>
                    <UncontrolledDropdown>
                        <DropdownToggle tag='span' className='cursor-pointer'>
                            <i className="fas fa-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu persist={true}>
                            <DropdownItem toggle={false} onClick={onClickDropdown}>
                                Cancel store transfer job
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>
        </th>
    )
}

export default AllJobSelection