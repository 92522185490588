import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import withStoreModeValidator from '../../../shared/withStoreModeValidator'

class FacebookPixelContainer extends Component {
    state = {
        id: this.props.facebookPixelId,
        idConversion: ''
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.facebookPixelId !== this.props.facebookPixelId) {
            this.setState({
                id: this.props.facebookPixelId
            })
        }
    }

    _handleChangeInput = e => {
        const {name, value} = e.target
        const {onChangeMeta} = this.props

        this.setState({
            id: value
        })

        const validateValue = value.trim()

        if (validateValue) {
            onChangeMeta(name, value)
        }
    }

    render() {
        const {fbPixelIdsCollection, editable, facebook_conversion} = this.props

        return (
            <div className="FacebookPixelContainer">
                <div className="SectionInner">
                    <h3 className="Title fs-20 mb-3">Facebook Pixel ID</h3>

                    <input type="text" name="facebook_pixel_id" value={this.state.id} className="form-control"
                           onChange={this._handleChangeInput} disabled={!editable}/>

                    {
                        (Array.isArray(fbPixelIdsCollection) && fbPixelIdsCollection.length > 0) &&
                        <div className="CollectionPixelIds pt-3 pb-3">
                            {
                                (fbPixelIdsCollection.length === 1 && !fbPixelIdsCollection[0].facebook_pixel_id) ?
                                    ''
                                    :
                                    <Fragment>
                                        <div className="font-weight-500 mb-2">Pixel IDs from Collections:</div>
                                        <ul className='mt-3 pt-3 pb-3 bg-light rounded'>
                                            {
                                                fbPixelIdsCollection.map((item, index) => {
                                                    const {facebook_pixel_id = ''} = item
                                                    if (!facebook_pixel_id) return
                                                    return <li key={`fbPixelIdsCollection_${index}`}>{facebook_pixel_id}</li>
                                                })
                                            }
                                        </ul>
                                    </Fragment>
                            }
                        </div>
                    }

                    <h3 className="Title fs-20 mt-3 mb-3">Facebook Conversion API</h3>

                    <input type="text" name="facebook_pixel_id" value={facebook_conversion} className="form-control"
                           onChange={this._handleChangeInput} disabled={!editable}/>

                    {
                        (Array.isArray(fbPixelIdsCollection) && fbPixelIdsCollection.length > 0) &&
                        <div className="CollectionPixelIds pt-3 pb-3">
                            {
                                (fbPixelIdsCollection.length === 1 && !fbPixelIdsCollection[0].facebook_conversion) ?
                                    ''
                                    :
                                    <Fragment>
                                        <div className="font-weight-500 mb-2">Conversion APIs from Collections:</div>
                                        <ul className="mt-3 pt-3 pb-3 bg-light rounded">
                                            {
                                                fbPixelIdsCollection.map((item, index) => {
                                                    const {facebook_conversion = ''} = item
                                                    if (!facebook_conversion) return
                                                    return <li
                                                        key={`facebook_conversion${index}`}>{facebook_conversion}</li>
                                                })
                                            }
                                        </ul>
                                    </Fragment>
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}

FacebookPixelContainer.defaultProps = {
    fbPixelIdsCollection: []
}

FacebookPixelContainer.propTypes = {
    facebookPixelId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    fbPixelIdsCollection: PropTypes.array,
    editable: PropTypes.bool.isRequired,
    onChangeMeta: PropTypes.func.isRequired
}

export default withStoreModeValidator({
    normal: 'all',
    marketplace: ['admin'],
    'merchize-payment': 'all'
})(FacebookPixelContainer)
