import React, {Component} from 'react'
import PropTypes from 'prop-types'
import IndexColumn from './columns/IndexColumn'
import StoreNameColumn from './columns/StoreNameColumn'
import DomainColumn from './columns/DomainColumn'
import StatusColumn from './columns/StatusColumn'
import ActivateDateColumn from './columns/ActivateDateColumn'
import OwnerEmailColumn from './columns/OwnerEmailColumn'
import ClusterColumn from "./columns/ClusterColumn"
import TagsColumn from "./columns/TagsColumn"
import SubscriptionColumn from "./columns/SubscriptionColumn"
import ModeColumn from "./columns/ModeColumn"
import DetailColumn from './columns/DetailColumn'

class RenderTableRow extends Component {
    render() {
        const {item, page, limit, index} = this.props
        const tableIndex = limit * (page - 1) + 1 + index

        return (
            <tr>
                <IndexColumn index={tableIndex}/>
                <StoreNameColumn item={item}/>
                <DomainColumn item={item}/>
                <ModeColumn item={item}/>
                <ClusterColumn item={item}/>
                <OwnerEmailColumn item={item}/>
                <SubscriptionColumn item={item}/>
                <TagsColumn item={item}/>
                <StatusColumn item={item}/>
                <ActivateDateColumn item={item}/>
                <DetailColumn item={item}/>
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

export default RenderTableRow
