import React, {Component} from 'react'
import DocTitle from '../../shared/DocTitle'
import ProductApprovalPage from "./ProductApprovalPage"

class ProductApprovalContainer extends Component {
    render() {

        return (
            <div className="ProductApproval">
                <DocTitle title="Products"/>
                <h2 className='mb-3'>Product Approval</h2>
                <ProductApprovalPage />
            </div>
        )
    }
}

export default ProductApprovalContainer
