import createAPIServices from './createApiServices'

// const baseUrl = process.env.NODE_ENV === 'production' ? '/api/manager' : 'https://manager-qa.merch8.com/api/manager/'
const baseUrl = process.env.NODE_ENV === 'production' ? '/api/manager' : 'https://admin-staging.merchize.com/api/manager'

const api = createAPIServices({baseUrl})

export const getListCreatableResources = () =>
    api.makeAuthRequest({
        url: `/resources/creatable-list`,
        method: 'GET'
})

export const getListResources = params =>
    api.makeAuthRequest({
        url: `/resources`,
        method: 'GET',
        params
})

export const getResourceDetails = (resourceId) =>
    api.makeAuthRequest({
        url: `/resources/${resourceId}`,
        method: 'GET'
})

export const getResourceMongoDb = (params) =>
    api.makeAuthRequest({
        url: `/resources/kind/mongodb`,
        method: 'GET',
        params
})

export const getListAssignedForResource = (resourceId, params) =>
    api.makeAuthRequest({
        url: `/resources/${resourceId}/list-assigned`,
        method: 'GET',
        params
})

export const getListResourceForStoreId = (storeId) =>
    api.makeAuthRequest({
        url: `/resources/store/${storeId}`,
        method: 'GET'
})

export const createNewResource = data =>
    api.makeAuthRequest({
        url: `/resources/create`,
        method: 'POST',
        data
})

export const editResource = (resourceId, resource) => {
    return api.makeAuthRequest({
        url: `/resources/${resourceId}`,
        method: 'put',
        data: resource
    })
}

export const deleteResourceTarget = (id) => {
    return api.makeAuthRequest({
        url: `/resources/release/${id}`,
        method: 'DELETE'
    })
}

export const deleteResource = (data) => {
    return api.makeAuthRequest({
        url: `/resources/delete`,
        method: 'DELETE',
        data
    })
}

export const checkAssign = (resourceId, data) => {
    return api.makeAuthRequest({
        url: `/resources/${resourceId}/check-assign`,
        method: 'POST',
        data
    })
}

export const assign = (resourceId, data) => {
    return api.makeAuthRequest({
        url: `/resources/${resourceId}/assign`,
        method: 'POST',
        data
    })
}


