export const settings = [
    {
        icon: 'far fa-envelope',
        title: 'Mail',
        location: '/a/settings/mail',
    }, {
        icon: 'fas fa-database',
        title: 'Storage',
        location: '/a/settings/storage',
    }, {
        icon: 'fas fa-globe',
        title: 'Global Settings',
        location: '/a/settings/global',
    },
    {
        icon: 'fas fa-globe',
        title: 'Webhook',
        location: '/a/settings/webhooks',
    },
    {
        icon: 'fas fa-box',
        title: 'Delivery',
        location: '/a/settings/delivery'
    }
]
export const settingsForAdmin = [
    {
        icon: 'ti-truck',
        title: 'Shipping Settings',
        location: '/a/settings-shipping',
        description: 'Shipping Settings (BP Only)'
    },
    {
        icon: 'fa fa-cog',
        title: 'General',
        location: '/a/settings/general'
    }
]
