import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ClusterProvider extends Component {
    state = {
        provider: this.props.provider,
    }

    _handleChangeSelect = e => {
        const {name, value} = e.target
        const {onUpdateCluster} = this.props

        this.setState({
            provider: value,
        }, onUpdateCluster(name, value))
    }

    render() {
        const {provider} = this.state

        return (
            <div className="ClusterProvider form-group">
                <label htmlFor="clusterProvider">Provider</label>
                <select name="provider" id="clusterProvider" className="form-control" value={provider}
                        onChange={this._handleChangeSelect}>
                    <option value="do">Digital Ocean</option>
                    <option value="gke">Google Cloud Platform</option>
                </select>
            </div>
        )
    }
}

ClusterProvider.propTypes = {
    provider: PropTypes.string.isRequired,
    onUpdateCluster: PropTypes.func.isRequired,
}

export default ClusterProvider
