import React, {Component, Fragment} from 'react'
import {getSettingProfilesShipping} from '../../../services/api/ShippingSettingsServices'
import SettingShippingListProduct from './SettingShippingListProduct'
import SettingShippingListProductItem from './SettingShippingListProductItem'
import PFPagePagination from '../../shared/PFPagePagination'
import SettingTableLoading from './SettingTableLoading'

class SettingShippingPage extends Component {

    state = {
        profiles: [],
        page: 1,
        pages: 1,
        limit: 10,
        error: '',
        loading: false,
    }

    componentDidMount() {
        this._getProfilesSettings()
    }

    _getProfilesSettings = async () => {
        const {limit, page} = this.state
        const sort = '-is_general, -created'
        const pageSize = limit
        const params = {pageSize, page, sort}
        this.setState({
            loading: true,
        })
        try {
            const {data, success, message} = await getSettingProfilesShipping(params)
            if (!success) throw new Error(message)

            const {rows, page, totalPages, pageSize} = data

            return this.setState({
                profiles: rows,
                page: page || 1,
                pages: totalPages || 1,
                limit: pageSize || 10,
                loading: false,
            })

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data

            this.setState({
                error: message ? message : e.message,
                loading: false,
            })
        }
    }

    _handleChangePage = (page) => {
        this.setState({
            page: page,
        }, () => this._getProfilesSettings())
    }

    render() {
        const {profiles, page, pages, error, loading} = this.state
        const profilesGeneral = (Array.isArray(profiles) && profiles.find(item => item.is_general === true)) || {}
        const profilesProduct = (Array.isArray(profiles) && profiles.filter(item => item.is_general === false)) || []

        return (
            <div className="SettingShippingPage">
                {
                    loading ?
                        <SettingTableLoading columnQuantity={3}/>
                        :
                        error ? <i className='text-danger mt-3'>{error}</i>
                            :
                            <Fragment>
                                {
                                    Object.keys(profilesGeneral).length > 0 &&
                                    <div className='GeneralShipping mt-4'>
                                        <div className='GeneralShippingTitle mb-2'>
                                            <h4 className='m-0'>General shipping rates</h4>
                                            <span className='text-muted fs-14 m-0'>Apply for product not in other
                                shipping profiles.</span>
                                        </div>
                                        <SettingShippingListProductItem
                                            onGetProfilesSettings={this._getProfilesSettings}
                                            profiles={profilesGeneral}/>
                                    </div>
                                }

                                {
                                    Object.keys(profilesProduct).length > 0 &&
                                    <div className='ProductShipping mt-4'>
                                        <div className='ProductShippingTitle mb-2'>
                                            <h4 className='m-0'>Shipping rates for Fragile products</h4>
                                            <span className='text-muted fs-14 m-0'>Apply for Omament.</span>
                                        </div>
                                        <SettingShippingListProduct onGetProfilesSettings={this._getProfilesSettings}
                                                                    profilesProduct={profilesProduct}/>
                                    </div>
                                }
                                <PFPagePagination page={page} pages={pages} onChangePage={this._handleChangePage}/>
                            </Fragment>
                }


            </div>
        )
    }
}

export default SettingShippingPage
