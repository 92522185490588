import React, {Component} from 'react'
import formatThousandNumber from "../../../../../helpers/common/formatThousandNumber"
import PropTypes from 'prop-types'

class WithdrawalAmount extends Component {
    render() {
        const {amount} = this.props
        const amountFormatted = formatThousandNumber(amount, 2)

        return (
            <td className="TransactionOutAmount text-right">
                ${amountFormatted}
            </td>
        )
    }
}

WithdrawalAmount.propTypes = {
    amount: PropTypes.number.isRequired,
}

export default WithdrawalAmount
