import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ObjectInspector from 'react-object-inspector'

class ClusterSettingValue extends Component {
    render() {
        const {settingValue} = this.props
        const valueFormatted = settingValue && typeof settingValue === 'object' ?
            <ObjectInspector name='value' data={settingValue}/> : settingValue

        return (
            <td className="ClusterSettingValue text-break">
                {valueFormatted}
            </td>
        )
    }
}

ClusterSettingValue.propTypes = {
    settingValue: PropTypes.string.isRequired,
}

export default ClusterSettingValue
