import React, {Component} from 'react'
import PropTypes from 'prop-types'

class KeyColumn extends Component {
    render() {
        const {item} = this.props
        const {key} = item
        return (
            <td className='KeyColumn'>
                <span>{key}</span>
            </td>
        )
    }
}

KeyColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default KeyColumn
