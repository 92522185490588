import React, {Component} from 'react'
import SelectedCountryItem from "./SelectedCountryItem"
// import PropTypes from 'prop-types'

class SelectedCountriesList extends Component {
    render() {
        const {selectedCountries, onRemoveSelectedCountry, countries} = this.props
        const availableCountries = countries.filter(country => country.available)

        return (
            <div className="SelectedCountriesList">
                {
                    selectedCountries === "rest" ?
                        <div>
                            Rest of world ({availableCountries.length} countries and regions)
                        </div>
                        :
                        <ul>
                            {
                                selectedCountries.map(countryObj => {
                                    return <SelectedCountryItem key={`${countryObj.name}_${countryObj.code}`}
                                                                country={countryObj}
                                                                onRemoveSelectedCountry={onRemoveSelectedCountry}/>
                                })
                            }
                        </ul>
                }

            </div>
        )
    }
}

// SelectedCountriesList.defaultProps = {}

// SelectedCountriesList.propTypes = {}

export default SelectedCountriesList
