import React, {Fragment} from 'react'
import moment from 'moment'
import FeatureImage from '../../../shared/FeatureImage'
import {confirmModal} from '../../../shared/PFConfirmModalV2'
import classNames from 'classnames'
import PayoutApprovalActionReject from '../actions/PayoutApprovalActionReject'
import PayoutApprovalActionTransfer from '../actions/PayoutApprovalActionTransfer'
import PayoutApprovalActionApproval from '../actions/PayoutApprovalActionApproval'
import TableRowAMInfo from './TableRowAMInfo'
import TableRowAccount from '../table/TableRowAccount'
import TableRowAccountant from './TableRowAccountant'
import PropTypes from 'prop-types'
import {getOnlyRoles} from '../../../../services/AuthServices'
import {CurrencyFormatNumber} from '../../../../helpers/currency/CurrencyNumberFormat'

const PayoutApprovalTableBody = (props) => {
    const {payouts, error, onFetchPayout, payoutID} = props
    const role = getOnlyRoles()

    const modalImage = image => (e) => {
        e.preventDefault()
        confirmModal({
            confirmText: '',
            cancelText: 'Close',
            className: 'ModalImagePayoutApproval',
            confirmColor: '',
            title: '',
            message: <img className={'w-100'} src={image} alt={`${image}`}/>
        })
    }


    return (
        <tbody className="PayoutApprovalTableBody TableLoading">
        {
            error
                ?
                <tr>
                    <td colSpan={11}>
                        <div className="text-danger">
                            {error}
                        </div>
                    </td>
                </tr>
                :
                Array.isArray(payouts) && payouts.length === 0
                    ?
                    <tr>
                        <td colSpan={11}><i>No result!</i></td>
                    </tr>
                    :
                    <Fragment>
                        {
                            Array.isArray(payouts) && payouts.map((payout, index) => {
                                const {
                                    note = '',
                                    store = {},
                                    amount = '',
                                    payout_id = '',
                                    status = '',
                                    account = {},
                                    image = '',
                                    accountant_updated = {},
                                    am_updated = {},
                                    _id = '',
                                    updatedAt = ''
                                } = payout

                                const amountFormat = CurrencyFormatNumber(amount)

                                let statusFormat = status
                                if (status === 'approved') statusFormat = 'AM Approved'
                                if (status === 'am_rejected') statusFormat = 'Rejected'
                                if (status === 'accountant_rejected') statusFormat = 'Rejected'

                                const {email = '', gateway = ''} = Object.assign({}, account)

                                return (
                                    <tr key={`PayoutApprovalTableBody_${index}`}>
                                        <td className='text-uppercase'>{moment(updatedAt).format('hh:mm a DD/MM/YYYY')}</td>
                                        <td>
                                            <p className="mb-0">{store.domain}</p>
                                            <span className="text-muted">{store.slug}</span>
                                        </td>
                                        <td className="text-dark">{payout_id}</td>
                                        <td className="text-center">{amountFormat}</td>

                                        <TableRowAccount email={email} gateway={gateway}/>

                                        <td>{note}</td>

                                        <TableRowAMInfo am_updated={am_updated}/>

                                        <TableRowAccountant accountant={accountant_updated}/>

                                        <td>
                                            <div className={classNames({'cursor-pointer': image})}
                                                 onClick={image && modalImage(image)}>
                                                <FeatureImage width={60} src={image || ''} className="border mr-3"/>
                                            </div>
                                        </td>
                                        <td className="text-capitalize">{statusFormat}</td>
                                        <td>
                                            <div className="d-flex flex-wrap">
                                                {
                                                    (status === 'approved' && role === 'accountant') &&
                                                    <PayoutApprovalActionTransfer payoutID={payoutID}
                                                                                  am_updated={am_updated}
                                                                                  onFetchPayout={onFetchPayout} ID={_id}
                                                                                  amountFormat={amountFormat}
                                                                                  payout={payout}/>
                                                }
                                                {
                                                    ((status === 'pending' && role !== 'accountant') || (status === 'approved' && role === 'accountant')) &&
                                                    <PayoutApprovalActionReject am_updated={am_updated}
                                                                                onFetchPayout={onFetchPayout}
                                                                                payout_id={payout_id} ID={_id}/>
                                                }
                                                {
                                                    (status === 'pending' && role !== 'accountant') &&
                                                    <PayoutApprovalActionApproval am_updated={am_updated}
                                                                                  onFetchPayout={onFetchPayout}
                                                                                  ID={_id}/>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </Fragment>
        }
        </tbody>
    )

}

PayoutApprovalTableBody.propTypes = {
    onFetchPayout: PropTypes.func.isRequired,
    payouts: PropTypes.array.isRequired,
    error: PropTypes.string.isRequired,
    payoutID: PropTypes.string.isRequired
}

export default PayoutApprovalTableBody
