import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from 'prop-types';


ModalCommon.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};
function ModalCommon(props) {
    const {
        isOpen, title, children, onSubmit = () => {}, onClose,
        submitText = 'Ok', submitColor = 'primary',
        cancelText = 'Cancel', hideSubmit = false,
        ...rest
    } = props
    const { additionalBtns } = rest

    return (

        <Modal className='modal-dialog-centered' isOpen={isOpen} toggle={onClose} size="lg" {...rest}>
            {title &&
                <ModalHeader>
                    {title}
                </ModalHeader>
            }
            <ModalBody style={{ overflow  : 'overlay', maxHeight : '80vh'}}>
                {children}
            </ModalBody>

            <ModalFooter>
                {!hideSubmit &&
                    <Button style={{ minWidth: '80px', paddingRight : '10px' }} color={submitColor} onClick={onSubmit}>
                        {submitText}
                    </Button>
                }
                {
                    additionalBtns && additionalBtns.length > 0 && (
                        additionalBtns.map((btn, index) => {
                            return (
                                <Button
                                    style={{minWidth: '80px', paddingRight: '10px'}}
                                    color={btn.color || 'primary'}
                                    onClick={btn.onClick}
                                    key={index}
                                >
                                    {btn.text}
                                </Button>
                            )
                        })
                    )
                }
                <Button style={{ minWidth: '80px' }} color="secondary" onClick={onClose}>
                    {cancelText}
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default ModalCommon;
