import React, {Component} from 'react'
import PropTypes from "prop-types";

class ValueColumn extends Component {

    render() {
        const {item} = this.props

        return (
            <td className="ValueColumn">
                {item.value}
            </td>
        );
    }

}

ValueColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ValueColumn