import React, {Component} from 'react'
import TableFilter from '../../shared/TableFilter'
import {exportCatalogFfmType, getProductCatalog} from '../../../services/api/ProductLinesServices'
import TableContainer from './TableContainer'
import PFPagePagination from '../../shared/PFPagePagination'
import CategoriesSelector from './CategoriesSelector'
import StoreSelect from '../../shipment-report/components/StoreSelect'
import pluralize from '../../../helpers/common/pluralize'
import {trimObjectValues} from '../../../helpers/common/cleanObject'
import ImportFfmProductType from './ImportFfmProductType'
import {toaster} from '../../shared/PFToast'
class Catalogs extends Component {
    state = {
        items: [],
        loading: false,
        params: {
            page: 1,
            limit: 10,
            title: '',
            // stores: [],
            category: '',
            available_for_campaign: false,
        },
        searchFieldSelected: 'Product title',
        total: 0,
        pages: 1,
        err: '',
        loadingExport: false,
        isOpenImport: false,
    }

    _inputFile = null

    componentDidMount() {
        this._getProductCatalog()
    }

    _getProductCatalog = async () => {
        const {params} = this.state

        try {
            this.setState({loading: true})
            if (!params.available_for_campaign) {
                delete params.available_for_campaign
            }
            const {success, data, message} = await getProductCatalog(trimObjectValues(params))
            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            const {total, pages, products} = Object.assign({}, data)

            this.setState({
                items: Array.isArray(products) ? products : [],
                pages: pages ? pages : 0,
                total: total ? total : 0,
                loading: false,
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _handleChangeFilter = (e) => {
        let {name, value} = e.target
        value = name === 'available_for_fulfill' ? this._parseBool(value) : value
        this.setState((prevState) => ({params: {...prevState.params, [name]: value}}), this._getProductCatalog)
    }

    _handleChangeCheckbox = (property) => (e) => {
        const value = e.target.checked
        this.setState((prevState) => ({params: {...prevState.params, [property]: value}}), this._getProductCatalog)
    }

    _parseBool = (value) => {
        return value === 'true'
    }

    _handlePageChange = (page) => {
        this.setState(
            {
                params: {...this.state.params, page},
            },
            () => this._getProductCatalog()
        )
    }

    _handleSelectCategories = (options = []) => {
        const categories = !options ? [] : options
        const firstSelect = categories[0] || {id: ''}
        const {id} = firstSelect

        this.setState(({params}) => {
            return {
                params: {
                    ...params,
                    page: 1,
                    category: id,
                    loading: true,
                },
            }
        }, this._getProductCatalog)
    }

    _handleChangeStore = (value) => {
        this.setState(({params}) => {
            return {
                params: {
                    ...params,
                    page: 1,
                    stores: value,
                    loading: true,
                },
            }
        }, this._getProductCatalog)
    }

    _onSubmitFilter = ({limit, value, field, page}) => {
        const fieldMap = {
            'Product title': 'title',
        }

        const parsedField = fieldMap[field]

        this.setState(
            ({params}) => ({
                params: {
                    ...{
                        limit,
                        page: '',
                        loading: '',
                    },
                    ...{
                        [parsedField]: value,
                        limit: parseInt(limit),
                        page: 1,
                        loading: false,
                    },
                },
                searchFieldSelected: field,
            }),
            () => {
                this._getProductCatalog()
            }
        )
    }

    _toggleImportFfmProductType = () => this.setState((prev) => ({...prev, isOpenImport: !prev.isOpenImport}))

    _exportFfmProductType = async () => {
        try {
            this.setState({loadingExport: true})
            const payload = {...this.state.params}
            delete payload.page
            delete payload.limit
            const response = await exportCatalogFfmType(payload)
            const url = window.URL.createObjectURL(new Blob([response]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `product_lines.csv`)
            document.body.appendChild(link)
            link.click()
        } catch (error) {
            toaster({type: 'error', message: error.message})
        } finally {
            this.setState({loadingExport: false})
        }
    }

    render() {
        const {items, params, pages, loading, err, total, searchFieldSelected, loadingExport, isOpenImport} = this.state
        const {page, limit, available_for_campaign} = params

        return (
            <div className="Catalogs">
                <div className="d-flex justify-content-between align-items-center">
                    <h2>Catalog</h2>
                    <div className="ListBtn">
                        <button
                            disabled={loadingExport}
                            className="btn btn-primary mr-3"
                            onClick={this._exportFfmProductType}
                        >
                            {loadingExport && <i className="fas fa-circle-notch fa-spin mr-2" />}
                            Export fulfillment product type
                        </button>
                        <button className="btn btn-primary" onClick={this._toggleImportFfmProductType}>
                            Import fulfillment product type
                        </button>
                    </div>
                    {isOpenImport && (
                        <ImportFfmProductType isOpen={isOpenImport} toggle={this._toggleImportFfmProductType} />
                    )}
                </div>

                <div className="CatalogsInner SectionInner">
                    <div className="mb-3">
                        <div className="align-items-center row mb-2">
                            <TableFilter
                                defaultLimit={limit}
                                onSubmitForm={this._onSubmitFilter}
                                searchField={['Product title']}
                                searchFieldSelected={searchFieldSelected}
                                placeholders={{
                                    'Product title': 'Search for product title',
                                }}
                            />
                            <div className="Filter d-flex align-items-center justify-content-between col">
                                <div className="d-flex align-items-center">
                                    <div className="FilterAttributes mr-3">
                                        <CategoriesSelector
                                            isClearable={true}
                                            multiSelect={false}
                                            className="CategoriesSelector"
                                            handleSelectCollection={this._handleSelectCategories}
                                        />
                                    </div>
                                    {/* <StoreSelect values={stores} onChangeStore={this._handleChangeStore} /> */}
                                </div>

                                <div className="TotalEntity ml-3">
                                    <strong className="Entity">{pluralize(total, 'item', 's')}</strong>
                                </div>
                            </div>
                        </div>
                        <div className="mb-1 d-flex align-items-center">
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="ProductLineAvailableCampaign"
                                    checked={available_for_campaign}
                                    onChange={this._handleChangeCheckbox('available_for_campaign')}
                                />
                                <label className="custom-control-label" htmlFor="ProductLineAvailableCampaign">
                                    Available for campaign
                                </label>
                            </div>
                        </div>
                    </div>
                    {!!err && <span className="text-warning">{err}</span>}
                    <TableContainer
                        totalItems={total}
                        items={items}
                        page={page}
                        limit={limit}
                        loading={loading}
                        refetchProductLine={this._getProductCatalog}
                    />
                    <PFPagePagination page={page} pages={pages} onChangePage={this._handlePageChange} />
                </div>
            </div>
        )
    }
}

export default Catalogs

