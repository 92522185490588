import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ProductVariantAttribute extends Component {
    render() {
        const {variant, attribute} = this.props
        const {options = []} = variant
        const optList = options.map((option) => option.name)
        const {values} = attribute
        const attList = values.map((value) => value.name)
        let finalvalue
        var i, j
        for (i = 0; i < optList.length; i++) {
            for (j = 0; j < attList.length; j++) {
                if (optList[i] === attList[j]) {
                    finalvalue = optList[i]
                }
            }
        }

        return <td className="ProductVariantAttribute">{finalvalue}</td>
    }
}

ProductVariantAttribute.propType = {
    variant: PropTypes.object.isRequired,
    attribute: PropTypes.object.isRequired,
}

export default ProductVariantAttribute

