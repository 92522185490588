import React from 'react'
import {randomID} from '../../../../../helpers/common/randomID'
import VariantTable from '../VariantTable'
import TableFilter from '../TableFilter'

const SellerShippingContainer = ({attributes, variants, currency, shippingKey, warehouse}) => {
    const [filterValues, setFilterValues] = React.useState([])
    if (!variants.length) return null
    const getSellerShippingHeaderConfigs = () => {
        let configs = []
        variants[0]?.attributes.forEach((attr) => {
            configs.push({
                ...attr,
                label: attr?.name,
            })
        })
        const sku = {
            key: 'sku',
            label: 'SKU',
        }

        const tiers = variants[0]?.tiers?.map((tier, index) => {
            return {
                key: `tier_${index + 1}`,
                label: `Tier ${index + 1}`,
            }
        })

        configs = [...configs, sku, ...tiers]
        return configs
    }

    const handleChangeFilter = ({key, value}) => {
        setFilterValues((prev) => {
            const index = prev.findIndex((item) => item.key === key)
            if (index === -1) {
                return [...prev, {key, value}]
            }
            prev[index].value = value
            return [...prev]
        })
    }

    const renderSellerShippingRow = (row) => {
        console.log('row', row)
        const pricingValue = row?.tiktok_warehouse_pricing
            ? row?.tiktok_warehouse_pricing[shippingKey]?.find((pricing) => pricing.warehouse === warehouse)
            : []

        return (
            <tr>
                {row?.attributes.map((attribute) => {
                    const randomId = randomID()
                    return (
                        <td key={randomId}>
                            <div className="d-flex align-items-center">
                                <span className="ml-2">{attribute.value_text}</span>
                            </div>
                        </td>
                    )
                })}
                <td>{row?.sku}</td>
                {Array.isArray(pricingValue?.base_cost)
                    ? pricingValue?.base_cost?.map((cost) => {
                          return <td>{cost?.price ? cost.price : '-'}</td>
                      })
                    : row.tiers.map((_) => <td>-</td>)}
            </tr>
        )
    }

    const renderFilter = () => {
        return attributes?.map((attribute) => {
            const {values = [], name} = attribute
            return (
                <TableFilter
                    options={values}
                    onChange={(event) =>
                        handleChangeFilter({
                            key: name,
                            value: event.target.value,
                        })
                    }
                    value={filterValues.find((item) => item.key === name)?.value}
                />
            )
        })
    }

    const renderSellerShippingHeader = (configs) => {
        return (
            <>
                {configs?.map((config) => {
                    const _id = randomID()
                    return (
                        <th key={_id} className="text-capitalize">
                            {config.label}
                        </th>
                    )
                })}
            </>
        )
    }

    return (
        <div>
            <div className="d-flex justify-content-end my-3">
                <div className="d-flex align-items-center">
                    <span>
                        Currency: <b>{currency}</b>
                    </span>
                </div>
            </div>
            <VariantTable
                renderHeader={renderSellerShippingHeader}
                headerConfigs={getSellerShippingHeaderConfigs()}
                renderRow={
                    filterValues && filterValues.length > 0
                        ? variants
                              .filter((variant) => {
                                  return filterValues.every((filter) => {
                                      if (filter?.value === '') return true
                                      return variant.attributes.some((attr) => {
                                          return attr.name === filter.key && attr.value_code === filter.value
                                      })
                                  })
                              })
                              .map(renderSellerShippingRow)
                        : variants.map(renderSellerShippingRow)
                }
                filter={renderFilter()}
            />
        </div>
    )
}

export default SellerShippingContainer
