import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ServiceListHead from "./ServiceListHead"
import ServiceListBody from "./ServiceListBody"

class ServiceListTable extends Component {
    render() {
        const {storeDetails} = this.props

        return (
            <table className="ServiceListTable table">
                <ServiceListHead/>
                <ServiceListBody storeDetails={storeDetails}/>
            </table>
        )
    }
}

ServiceListTable.propTypes = {
    storeDetails: PropTypes.object.isRequired,
}

export default ServiceListTable
