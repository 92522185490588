import React, {useState} from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {toaster} from '../../shared/PFToast'
import {bulkActionCashbackRecall} from '../../../services/api/BillingServices'
import pluralize from '../../../helpers/common/pluralize'

const BulkModalConfirm = (props) => {
    const {open, toggleModal, ids, bulkType, onAfterBulkAction} = props
    const [loading, setLoading] = useState(false)
    const bulkTypeText = bulkType === 'no_recall' ? 'no recall' : bulkType
    const onSubmit = async () => {
        try {
            setLoading(true)
            const {success, message} = await bulkActionCashbackRecall({recall_ids: ids, status: bulkType})
            if (success) {
                toaster({
                    type: 'success',
                    message: `Change ${pluralize(ids.length, 'order')} into ${bulkTypeText} successfully!`,
                })
                onAfterBulkAction()
                toggleModal()
            } else {
                toaster({
                    type: 'error',
                    message,
                })
            }
            setLoading(false)
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    return (
        <Modal isOpen={open} toggle={toggleModal} centered>
            <ModalHeader toggle={toggleModal}>Bulk {bulkTypeText}</ModalHeader>
            <ModalBody>
                Are you sure you want to change {ids && ids.length > 1 ? 'these orders' : 'this order'} into {bulkTypeText}?
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggleModal} disabled={loading}>
                    Cancel
                </Button>
                <Button className="text-capitalize" color="primary" disabled={loading} onClick={onSubmit}>
                    {loading && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                    {bulkTypeText}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default BulkModalConfirm
