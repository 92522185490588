import {Button, ModalHeader, ModalFooter, Modal, ModalBody} from 'reactstrap'
import {parse} from 'json2csv'

const csv_example = [
    {
        storeId: '(required)',
        resourceId: '(required if move_db)'
    }
]

const _downloadCSV = (data, name, hasFields = true) => {
    let fields = ['storeId', 'resourceId']
    let csv
    if (hasFields) {
        csv = parse(data, {fields})
    } else {
        csv = parse(data)
    }
    const blob = new Blob([csv], {type: 'text/csvcharset=utf-8'})
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', name)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const StoreActionModal = (props) => {
    const {isOpen, toggle, header, onConfirmFile, onUploadFile, fileName} = props

    return (
        <div>
            <Modal className="cashback-modal" isOpen={isOpen} toggle={toggle} centered size="lg" backdrop="static">
                <ModalHeader toggle={toggle}>{header}</ModalHeader>
                <ModalBody>
                    <h5>Please load .csv file</h5>
                    <p>
                        You can download example{' '}
                        <a
                            className="text-primary cursor-pointer"
                            onClick={() => _downloadCSV(csv_example, 'batch_action_example.csv', false)}
                        >
                            here
                        </a>
                        .
                    </p>
                    <label className="btn btn-sm btn-info cursor-pointer mb-0 ButtonImport" onClick={onUploadFile}>
                        <i className="ti-upload mr-2" />
                        <span className="float-none">Upload</span>
                    </label>
                    {fileName ? fileName : <></>}
                </ModalBody>
                <ModalFooter>
                    <Button color="default" onClick={toggle}>
                        Close
                    </Button>
                    <Button color="primary" onClick={onConfirmFile}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default StoreActionModal
