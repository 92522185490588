import React, {Component} from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import classNames from 'classnames'
import {updateAffiliateCategories} from '../../../../services/api/ProductLinesServices'
import {toaster} from '../../../shared/PFToast'
import NumberFormat from 'react-number-format'

const MIN_PERCENTAGE = 0
const MAX_PERCENTAGE = 100

class EditAffiliateCategory extends Component {

    state = {
        isOpenModal: false,
        name: this.props.name || '',
        percentage: this.props.percentage || 0,
        errObj: {}
    }

    _toggleModal = () => {
        const {isOpenModal} = this.state
        this.setState({
            isOpenModal: !isOpenModal,
            errObj: {},
            name: this.props.name || '',
            percentage: this.props.percentage || 0,
        })
    }

    _handleChangNumber = ({floatValue}) => {
        let {errObj} = this.state
        if (errObj['percentage']) {
            delete errObj['percentage']
        }
        this.setState({
            percentage: floatValue
        })
    }

    _handleChangeInput = (e) => {
        e.preventDefault()
        const {name, value} = e.target

        let {errObj} = this.state
        if (errObj[name]) {
            delete errObj[name]
        }

        if (name === 'percentage') {
            if (MIN_PERCENTAGE <= value && value <= MAX_PERCENTAGE) {
                return this.setState({
                    [name]: value,
                })
            }
            return
        }

        this.setState({
            [name]: value,
        })
    }

    _validate = () => {
        const {name, percentage} = this.state

        let errorKey = {}

        if (!name || !name.trim()) {
            errorKey.name = 'Name is required.'
        }
        if (!percentage && percentage !== 0) {
            errorKey.percentage = 'Percentage is required.'
        }

        this.setState(({errObj}) => ({
            errObj: {...errObj, ...errorKey}
        }))

        if (Object.keys(errorKey).length > 0) return

        this._handleUpdateAffiliate()
    }

    _handleUpdateAffiliate = async () => {

        const {id, onGetAffiliateCategories} = this.props
        const {name, percentage} = this.state
        const params = {id, name, percentage}

        try {
            const {success, message} = await updateAffiliateCategories(params)

            if (!success) throw new Error(message)

            toaster({
                type: 'success',
                message: `Updated affiliate ${name} category successfully!`,
                duration: 5000
            })

            this.setState({
                isOpenModal: false,
            })

            onGetAffiliateCategories()

        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
                duration: 5000
            })
        }
    }

    _withValueCap = (inputObj) => {
        const { value } = inputObj;
        if (value <= MAX_PERCENTAGE) return inputObj
    }

    render() {
        const {isOpenModal, name, errObj, percentage} = this.state

        return (
            <div className="EditAffiliateCategory">
                <button onClick={this._toggleModal} className="ViewButton btn btn-sm btn-primary mr-2 fs-14">
                    <span className="ti-eye mr-1"/>
                    Edit
                </button>
                <Modal className='modal-dialog-centered' isOpen={isOpenModal} toggle={this._toggleModal}>
                    <ModalHeader toggle={this._toggleModal}>Edit affiliate category</ModalHeader>
                    <ModalBody>

                        <div className="form-group">
                            <label htmlFor="TitleAffiliateCategoryNew">Name</label>
                            <input type="text"
                                   className={classNames('form-control', {'is-invalid': errObj.name})}
                                   id="TitleAffiliateCategoryNew" name="name"
                                   value={name}
                                   onChange={this._handleChangeInput}/>
                            {
                                errObj.name &&
                                <div className='invalid-feedback'>
                                    {errObj.name}
                                </div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="PercentageAffiliateCategoryNew">Percentage</label>
                            <NumberFormat
                                value={percentage || 0}
                                suffix='%'
                                name="percentages"
                                decimalScale={2}
                                isAllowed={this._withValueCap}
                                className={classNames('form-control', {'is-invalid': errObj.percentage})}
                                onValueChange={this._handleChangNumber}
                            />
                            {
                                errObj.percentage &&
                                <div className='invalid-feedback'>
                                    {errObj.percentage}
                                </div>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={this._toggleModal}>Cancel</Button>{' '}
                        <Button color="primary" onClick={this._validate}>Update</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

EditAffiliateCategory.propTypes = {}

export default EditAffiliateCategory
