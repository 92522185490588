import React, {Component} from 'react'
import ThemeModal from "./ThemeModal";
import PropTypes from "prop-types";

class StoreThemeName extends Component {
    state = {
        openStore: false
    }
    _buttonClick = () => {
        this.setState({
            openStore: true,
        })
    }

    _closeModal = () => {
        this.setState({
            openStore: false,
        })
    }

    render() {
        const {openStore} = this.state
        const {storeDetails, changeStoreDetail, theme_name} = this.props
        return (
            <>
                <li className='list-group-item text--right'>
                    Theme{' '}
                    <span className='ThemePrimary d-flex align-items-center'>
                            <div className='themeName'>
                               <code>{theme_name}</code>
                            </div>
                            <button className='Theme btn' onClick={this._buttonClick}>
                                <i className='far fa-edit'/>
                            </button>
                        </span>
                </li>
                <ThemeModal close={this._closeModal} openStore={openStore} theme_name={theme_name}
                            storeDetails={storeDetails} changeStoreDetail={changeStoreDetail}
                />
            </>

        )
    }
}

ThemeModal.propTypes = {
    theme_name: PropTypes.string.isRequired,
    changeStoreDetail: PropTypes.func.isRequired,
    storeDetails: PropTypes.object.isRequired
}

export default StoreThemeName
