import React, {Component} from 'react'
import JobPage from './JobPage'
import getEnv from '../../../helpers/common/getEnv'

class JobContainer extends Component {
    render() {
        const isSupportMpMode = getEnv('isEnableMpMode')
        if (isSupportMpMode) return null

        return (
            <div className='ClustersContainer'>
                <JobPage />
            </div>
        )
    }
}

export default JobContainer
