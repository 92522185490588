import React, {Component} from 'react'
import {Input, Label} from 'reactstrap'
import PropTypes from 'prop-types'

class ExportTypeSelector extends Component {
    _handleChangeSelect = e => {
        this.props.onChange('type', e.target.value)
    }

    render() {
        return (
            <div className='ExportTypeSelector mr-4'>
                <Label className='d-inline-flex align-items-baseline mb-0'>
                    <span className='flex-shrink-0 mr-2'>Export type:</span>
                    <Input type='select' onChange={this._handleChangeSelect} style={{minWidth: 200}}>
                        <option value='all'>Summary</option>
                        <option value='duplicated-top-up'>Duplicated top-up</option>
                        <option value='transaction-out'>Transaction out</option>
                    </Input>
                </Label>
            </div>
        )
    }
}

ExportTypeSelector.propTypes = {
    onChange: PropTypes.func.isRequired
}

export default ExportTypeSelector
