import React, {Component} from 'react'

// import PropTypes from 'prop-types'

class InputRateSubTitle extends Component {
    _handleChangeInput = e => {
        const {value} = e.target

        this.props.onUpdateRatesData("subtitle", value)
    }

    render() {
        return (
            <div className="InputRateSubTitle">
                <label htmlFor="createRateSubTitle">Subtitle (optional)</label>
                <input type="text" className="form-control" id="createRateSubTitle" onChange={this._handleChangeInput}/>
            </div>
        )
    }
}

// InputRateSubTitle.defaultProps = {}

// InputRateSubTitle.propTypes = {}

export default InputRateSubTitle
