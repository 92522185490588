import React, {Component} from 'react'
import SettingInput from '../../setting/shared/SettingInput'
import PropTypes from 'prop-types'

class MailSettings extends Component {
    _onChangeSettingInput = (key) => (value) => {
        this.props.onChangeSetting(key, value)
    }

    render() {
        const {settings, disabled} = this.props

        return (
            <div className="MailSettings">
                <SettingInput
                    itemId='smtp_host'
                    label="SMTP host"
                    value={settings.smtp_host}
                    disabled={disabled}
                    onChange={this._onChangeSettingInput('smtp_host')}
                />
                <SettingInput
                    itemId='smtp_port'
                    label="SMTP port"
                    disabled={disabled}
                    value={settings.smtp_port}
                    onChange={this._onChangeSettingInput('smtp_port')}
                />
                <SettingInput
                    itemId='smtp_user'
                    label="SMTP user"
                    value={settings.smtp_user}
                    disabled={disabled}
                    onChange={this._onChangeSettingInput('smtp_user')}
                />
                <SettingInput
                    itemId='smtp_pass'
                    label="SMTP password"
                    value={settings.smtp_pass}
                    type="password"
                    disabled={disabled}
                    onChange={this._onChangeSettingInput('smtp_pass')}
                />
            </div>
        )
    }
}

MailSettings.propTypes = {
    settings: PropTypes.object.isRequired,
    onChangeSetting: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
}

export default MailSettings
