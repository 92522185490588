import React, {Component} from 'react'
import PropTypes from 'prop-types'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import {getStoreSettings} from '../../../services/api/StoreSettingsServices'
import TablePagination from './TablePagination'

class TableContainer extends Component {
    state = {
        params: {
            page: 1,
            limit: 100,
        },
        totalPage: 1,
        storeSettings: [],
        err: '',
        loading: true,
    }

    componentDidMount() {
        this._getStoreSettings()
    }

    componentDidUpdate(prevProps) {
        const {message} = this.props
        console.log(message)
        if (prevProps.message !== message && message.includes('successfully')) {
            this._getStoreSettings()
        }
    }

    _getStoreSettings = async () => {
        const {storeId} = this.props
        const {params} = this.state
        const {page, limit} = params
        try {
            const {success, data, message} = await getStoreSettings({
                storeId,
                page,
                limit,
            })

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            const storeSettings = Array.isArray(data) ? data : []

            this.setState({
                storeSettings,
                loading: false,
            })
        } catch (e) {
            return this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _handlePageChange = page => {
        const {params} = this.state
        this.setState(
            {
                params: {...params, page},
            },
            () => this._getStoreSettings()
        )
    }

    render() {
        const {params, totalPage, storeSettings, loading, err} = this.state
        const {page, limit} = params
        return (
            <div className='TableContainer table-responsive '>
                {!!err && <p className='text-warning'>{err}</p>}
                <table className='table table-striped table-light'>
                    <TableHeader />
                    <TableBody storeSettings={storeSettings} page={page} limit={limit} loading={loading} />
                </table>
                <TablePagination page={page} pages={totalPage} handlePageChange={this._handlePageChange} />
            </div>
        )
    }
}

TableContainer.propTypes = {
    storeId: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
}

export default TableContainer
