import React, {useState} from 'react'
import {getListStores} from '../../../../services/api/StoreServices'
import AsyncSelect from 'react-select/async/dist/react-select.esm'
import PropTypes from 'prop-types'

const PayoutFilterStore = (props) => {
    const {onChange} = props
    const [filter, setFilter] = useState('slug')
    const [error, setError] = useState('')

    const handleInputChange = options => {
        const valueSelect = options.value
        onChange(valueSelect)
    }

    const fetchListStores = async (storeName = '') => {
        try {
            const params = {
                limit: 10,
                [filter]: storeName
            }

            const {data, success, message} = await getListStores(params)

            if (!success) throw new Error(message)

            const {stores} = data

            return stores.map(store => {
                return {
                    value: store._id,
                    label: store.slug + '--' + store.domain
                }
            })

        } catch (e) {
            setError(e.message)
        }
    }

    let timeout = null

    const handleLoadOptions = (inputValue) => {
        if (timeout) clearTimeout(timeout)
        return new Promise(resolve => {
            timeout = setTimeout(() => {
                resolve(fetchListStores(inputValue))
            }, 500)
        })
    }

    const onChangeSelect = (e) => {
        const {value} = e.target
        setFilter(value)
    }

    return (
        <div className="InputStore flex-wrap d-flex align-items-center InputGroup mr-4">
            <label className="mr-2 mb-0 font-weight-bold">Store: </label>
            <div className="SelectInputStore d-flex align-items-center border rounded">
                <select
                    value={filter}
                    className="border-0"
                    onChange={onChangeSelect}
                >
                    <option value="slug">Store ID</option>
                    <option value="domain">Domain</option>
                </select>
                <AsyncSelect noOptionsMessage={() => null} defaultOptions loadOptions={handleLoadOptions}
                             onChange={handleInputChange}/>
            </div>
            {
                error && <i className='text-danger mt-1'>{error}</i>
            }
        </div>
    )

}

PayoutFilterStore.propTypes = {
    onChange: PropTypes.func.isRequired,
}

export default PayoutFilterStore
