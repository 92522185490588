import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {CustomInput, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap'
import './style.scss'
import pluralize from '../../../../../helpers/common/pluralize'

class PFTableV2 extends Component {
    state = {
        allSelected: false,
        pageSelected: {},
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevData = prevProps.data
        const currentData = this.props.data

        if (prevData.length !== currentData.length || !prevData.every((item, index) => item._id === currentData[index]._id)) {
            this._clearSelected()
        }
    }

    _selectRow = e => {
        const {value: rowId, checked} = e.target

        this.setState((prevState) => {
            return {
                pageSelected: {
                    ...prevState.pageSelected,
                    [rowId]: checked,
                },
                allSelected: false,
            }
        })
    }

    _selectPage = e => {
        const {checked} = e.target
        const {data} = this.props

        this.setState({
            pageSelected: data.reduce((acc, item) => {
                return {...acc, [item._id]: checked}
            }, {}),
            allSelected: false,
        })
    }

    _selectAll = () => {
        this.setState((prevState) => ({allSelected: !prevState.allSelected}))
    }

    _clickMenuItem = (e) => {
        const itemIndex = e.target.value
        const {allSelected, pageSelected} = this.state
        const selectedIds = Object.keys(pageSelected).reduce((acc, id) => {
            return pageSelected[id] === true ? [...acc, id] : acc
        }, [])

        this.props.selections[itemIndex].onSelect(allSelected, selectedIds)
    }

    _clearSelected = () => {
        this.setState({
            allSelected: false,
            pageSelected: {},
        })
    }

    render() {
        const {pageSelected, allSelected} = this.state
        const {columns, data, selections, totalItems, loading, allowSelectAllPage, hasCheckbox = true} = this.props
        const numberItemsSelected = Object.values(pageSelected).filter(Boolean).length
        const isAllPageSelected = data.length > 0 && numberItemsSelected === data.length

        return (
            <div className='PFTableV2'>
                <table className='table'>
                    <thead className='thead-light'>
                    <tr>
                        {
                            selections !== undefined
                            &&
                            <th className='SelectorHead'>
                                <CustomInput
                                    id='allStoreSelector'
                                    type='checkbox'
                                    checked={isAllPageSelected}
                                    onChange={this._selectPage}
                                />
                            </th>
                        }

                        {
                            numberItemsSelected > 0
                                ?
                                <th colSpan={99}>
                                    <div className='BulkActionsManagement'>
                                        {
                                            (Array.isArray(selections) && selections.length > 0) &&
                                            <UncontrolledDropdown className='DropdownActions'>
                                                <DropdownToggle caret size='sm'>
                                                    Actions
                                                </DropdownToggle>
                                                <DropdownMenu persist={true}>
                                                    {
                                                        selections.map((selection, index) => {
                                                            const {key, text} = selection

                                                            return (
                                                                <DropdownItem
                                                                    key={key}
                                                                    toggle={false}
                                                                    onClick={this._clickMenuItem} value={index}
                                                                >
                                                                    {text}
                                                                </DropdownItem>
                                                            )
                                                        })
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        }

                                        {
                                            isAllPageSelected
                                                ?
                                                <div className='SelectedStoreItems'>
                                                    All items on this page are selected.
                                                    <div onClick={this._selectAll} className='LinkSelect'>
                                                        {
                                                            allowSelectAllPage &&
                                                            (allSelected ? 'Clear selection' : `Select all ${pluralize(totalItems, 'item', 's')} that match with current filter`)
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className='SelectedPageItems'>
                                                    {pluralize(numberItemsSelected, 'item', 's')} selected
                                                </div>
                                        }
                                    </div>
                                </th>
                                :
                                columns.map((col) => <th className={col.key === 'personalize' ? "text-center" : ""} key={col.key}>{col.title}</th>)
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        loading ?
                            [1, 2, 3].map((row, index) => (
                                <tr key={`placeholderRow_${index}`}>
                                    {
                                        hasCheckbox && <td>
                                            <div className='PlaceholderCell'/>
                                        </td>
                                    }
                                    {columns.map((col, index) => (
                                        <td key={`placeholderCell_${index}`}>
                                            <div className='PlaceholderCell'/>
                                        </td>
                                    ))}
                                </tr>
                            ))
                            :
                            data.length < 1 ?
                                <tr>
                                    <td colSpan={99}><i>No results</i></td>
                                </tr>
                                :
                                data.map((item, rowIndex) => {
                                    return (
                                        <tr key={item._id || rowIndex}>
                                            {
                                                selections !== undefined
                                                &&
                                                <td>
                                                    <CustomInput
                                                        id={`selector_${item._id}`}
                                                        type='checkbox'
                                                        value={item._id}
                                                        checked={pageSelected[item._id] || false}
                                                        onChange={this._selectRow}
                                                    />
                                                </td>
                                            }

                                            {columns.map(col => {
                                                const rawContent = item[col.dataIndex]

                                                return (
                                                    <td className={col.className} key={col.key}>
                                                        {
                                                            typeof col.render === 'function' ?
                                                                col.render(item)
                                                                :
                                                                rawContent
                                                        }
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

PFTableV2.defaultProps = {
    loading: false,
    allowSelectAllPage: true,
}

PFTableV2.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    selections: PropTypes.array,
    totalItems: PropTypes.number.isRequired,
    loading: PropTypes.bool,
    allowSelectAllPage: PropTypes.bool,
}

export default PFTableV2
