import React, {Component} from 'react'
import PropTypes from 'prop-types'

class IsSellerColumn extends Component {
    render() {
        const {item} = this.props
        const {IsSeller} = item

        return (
            <td className='IsSeller'>
                <span>{IsSeller ? 'true' : 'false'} </span>
            </td>
        )
    }
}

IsSellerColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default IsSellerColumn
