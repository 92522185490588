import React, {Component} from 'react'
import ProductAttributesActions from './ProductAttributes/ProductAttributesActions'
import ProductAttributesList from './ProductAttributes/ProductAttributesList'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {ATTRIBUTE_PRESELECTED} from '../constant'

class ProductAttributes extends Component {
    state = {
        attributes: this.props.attributes,
        originalattributes: [],
        error: '',
        loading: false
    }

    _handleCreateAttribute = () => {
        this.setState(({attributes}) => {
            return {
                attributes: [...attributes, {
                    name: '',
                    value_type: 'Size',
                    values: [],
                    hide_attribute_storefront: false,
                    is_active: true,
                    is_preselected: false
                }],
            }
        })
    }

    _handleChangeAttributesState = (index, field, value) => {
        this.setState(({attributes}) => {
            const attributesUpdated = attributes.map((att, idx) => {
                if (idx === index) {
                    return {
                        ...att,
                        [field]: value,
                        ...(field === 'value_type' && {is_preselected: ATTRIBUTE_PRESELECTED[value]}),
                    }
                }

                return att
            })

            return {
                attributes: attributesUpdated
            }
        })
    }

    _handleRemoveAttribute = (index) => {
        this.setState(({attributes}) => {
            const attributesUpdated = attributes.filter((att, idx) => idx !== index)

            return {
                attributes: attributesUpdated
            }
        })
    }

    _handleUpdateAttributes = () => {
        const {attributes} = this.state
        this.props.onHandleChangeAttribute(attributes, 'attributes')
    }

    _handleDiscardChangeState = () => {
        const {originalattributes} = this.state

        return this.setState({
            attributes: originalattributes
        })
    }

    render() {
        const {errorObj} = this.props
        const {attributes, loading, error} = this.state

        return (
            <div className="ProductAttributes mt-4">
                <div className={classNames('SectionInner', {'border-danger': errorObj.attributes})}>
                    <h3 className="SectionHeading mb-3">Attributes</h3>

                    <ProductAttributesList attributes={attributes}
                                           onChangeAttributesState={this._handleChangeAttributesState}
                                           onRemoveAttribute={this._handleRemoveAttribute}/>

                    <ProductAttributesActions onCreateAttribute={this._handleCreateAttribute}
                                              onUpdateAttributes={this._handleUpdateAttributes}
                                              handleDiscardChangeState={this._handleDiscardChangeState}
                                              loading={loading}
                                              error={error}/>
                </div>
                {
                    errorObj.attributes &&
                    <div className="invalid-feedback d-block">
                        {errorObj.attributes}
                    </div>
                }
            </div>
        )
    }
}

ProductAttributes.propTypes = {
    onHandleChangeAttribute: PropTypes.func.isRequired
}

export default ProductAttributes
