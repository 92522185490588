import React, {Component} from 'react'
import PropTypes from 'prop-types'
import DnsRecordType from "./data/DnsRecordType"
import DnsRecordName from "./data/DnsRecordName"
import DnsRecordContent from "./data/DnsRecordContent"

class DnsRecordRow extends Component {
    render() {
        const {record} = this.props

        return (
            <tr className="DnsRecordRow">
                <DnsRecordType record={record}/>
                <DnsRecordName record={record}/>
                <DnsRecordContent record={record}/>
            </tr>
        )
    }
}

DnsRecordRow.propTypes = {
    record: PropTypes.object.isRequired,
}

export default DnsRecordRow
