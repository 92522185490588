import createAPIServices from './createApiServices'

const _getDefaultBaseURL = (suffix = '') => {
    const prefixURL = process.env.NODE_ENV === 'production' ? '/api/manager' : 'https://admin-staging.merchize.com/api/manager'
    // const prefixURL = process.env.NODE_ENV === 'production' ? '/api/manager' : 'http://localhost:4200'

    return `${prefixURL}${suffix}`
}

const _getCurrentStore = () => {
    return Object.assign({}, window.currentStore)
}

const _create = (options = {}) => {
    const {suffix, getBaseURL} = Object.assign({}, options)

    const _getBaseURLByStore = () => {
        const currentStore = _getCurrentStore()
        const isProduction = process.env.NODE_ENV === 'production'

        return typeof getBaseURL === 'function' ? getBaseURL(currentStore, isProduction) : _getDefaultBaseURL(suffix)
    }

    const baseUrl = _getBaseURLByStore()

    return createAPIServices({baseUrl})
}

const _validateOptions = options => {
    const {suffix} = Object.assign({}, options)

    return {
        suffix: suffix || '',
    }
}

export const createStoreAPI = (options = {}) => {
    const opts = _validateOptions(options)

    return _create(opts)
}
