import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ProductAttributesActions extends Component {
    _handleClickAdd = () => {
        this.props.onCreateAttribute()
    }

    _handleClickUpdateAttributes = () => {
        this.props.onUpdateAttributes()
    }

    _handleClickDiscard = () => {
        const {handleDiscardChangeState} = this.props
        handleDiscardChangeState()
    }

    render() {
        const {loading, error} = this.props

        return (
            <div className="ProductAttributesActions">
                <div className="d-flex">
                    <span className="btn btn-success mr-auto cursor-pointer"
                          onClick={this._handleClickAdd}>Add attribute</span>

                    <button type="button" className="btn btn-secondary mr-3"
                            onClick={this._handleClickDiscard}>Remove all
                    </button>
                    <button type="button" className="btn btn-primary" onClick={this._handleClickUpdateAttributes}>
                        {
                            loading ? "Saving..." : "Save attributes"
                        }
                    </button>
                </div>

                {
                    !!error &&
                    <div className="text-danger text-right mt-3">{error}</div>
                }
            </div>
        )
    }
}

ProductAttributesActions.propTypes = {
    onCreateAttribute: PropTypes.func.isRequired,
    onUpdateAttributes: PropTypes.func.isRequired,
    handleDiscardChangeState: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
}

export default ProductAttributesActions
