import React, {Component} from 'react'
import PropTypes from 'prop-types'


class SettingTableLoading extends Component {
    render() {
        const {columnQuantity, columns} = this.props
        const mappedColumns = columns.length ? columns : new Array(columnQuantity).fill(null)
        return (
            <table className='SettingTableLoading TableLoading d-block'>
                <tbody>
                <tr>
                    {mappedColumns.map((column, i) => (
                        <td key={i}>
                            <span className='table-loading-placeholder Cell'/>
                        </td>
                    ))}
                </tr>
                <tr>
                    {mappedColumns.map((column, i) => (
                        <td key={i}>
                            <span className='table-loading-placeholder Cell'/>
                        </td>
                    ))}
                </tr>
                <tr>
                    {mappedColumns.map((column, i) => (
                        <td key={i}>
                            <span className='table-loading-placeholder Cell'/>
                        </td>
                    ))}
                </tr>
                </tbody>
            </table>
        )
    }
}

SettingTableLoading.defaultProps = {
    columns: [],
    columnQuantity: 0
}

SettingTableLoading.propTypes = {
    columns: PropTypes.array,
    columnQuantity: PropTypes.number
}
export default SettingTableLoading
