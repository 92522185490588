import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"
import * as AuthService from "../../../services/AuthServices"
import {setCurrentStore} from "../../../services/CurrentStoreService"

class NavbarTop extends Component {
    _handleClickLogout = e => {
        e.preventDefault()

        AuthService.logoutUser()
        setCurrentStore('', [])
    }

    _renderLogo = () => {
        const {store} = this.props
        const name = store.getSetting('name', '')
        const logoURL = store.getLinkAssets('logo_url', '')

        if (!!logoURL) {
            return <img src={logoURL} alt={name}/>
        } else {
            return <span className="SiteName">{name}</span>
        }
    }

    render() {
        const {onClick} = this.props

        return (
            <nav className="NavbarTop">
                <ul className="NavLeft">
                    <li>
                        <span className="SideBarToggle" onClick={onClick}>
                            <i className="ti-menu"/>
                        </span>
                    </li>
                    <li>
                        <Link to="/" className="LogoLink">
                            {this._renderLogo()}
                        </Link>
                    </li>
                </ul>

                <ul className="NavRight">
                    <li className="nav-item">
                        <Link onClick={this._handleClickLogout} to="/logout">Logout</Link>
                    </li>
                </ul>
            </nav>
        )
    }
}

NavbarTop.propTypes = {
    store: PropTypes.object.isRequired,
}

export default NavbarTop
