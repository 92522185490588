import React, { useEffect, useState } from "react"
import { getHighlightProducts, sortHighlightProduct } from "../../../../services/api/HighlightProductServices"
import AddHighlightProductModal from "./AddHighlightProductModal"
import SortableHighlightList from "./SortableHighlightList"
import { toaster } from '../../../shared/PFToast'

const HighlightProductContainer = () => {

    const [products, setProducts] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchHighlightList()
    }, [])

    const fetchHighlightList = async () => {
        try {
            setLoading(true)
            const { data, success, message } = await getHighlightProducts()
            setLoading(false)
            if (!success) {
                return toaster({
                    type: "error",
                    message
                })
            }
            setProducts(data.productLines)
        } catch (error) {
            console.error(error)
            setLoading(false)
        }
    }

    const onToggleModal = () => setIsOpen(!isOpen)

    return (
        <div className="HighlightProductContainer">
            <AddHighlightProductModal isOpen={isOpen} highlightProducts={products} toggleModal={onToggleModal} fetchHighlightList={fetchHighlightList} />
            <div className="d-flex justify-content-between align-items-center">
                <h2>Highlight List</h2>
                <div className="addbtn">
                    <button className="btn btn-success" disabled={products.length === 6} onClick={onToggleModal}>Add products</button>
                </div>
            </div>
            <div className="HighlightProductInner SectionInner">
                <SortableHighlightList products={products} fetchHighlightList={fetchHighlightList} loading={loading} setLoading={setLoading} />
            </div>
        </div>
    )
}

export default HighlightProductContainer
