import React, {useState} from 'react'
import Select from 'react-select'
import {Button, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {changeVariantScope} from '../../../../../services/api/ProductLinesServices'
import {toaster} from '../../../../shared/PFToast'
import StoreSelect from '../../../../shipment-report/components/StoreSelect'
import PubSub from '../../../helper/CatalogBroker'

const BaseCostScopeModal = (props) => {
    const {id, isOpen, toggle, appliedStores, catalog} = props

    const [loading, setLoading] = useState(false)
    const [enableSave, setEnableSave] = useState(false)
    const [selectedStore, setSelectedStore] = useState(null)

    const _handleChangeStore = (value) => {
        setSelectedStore(value)
        setEnableSave(true)
    }

    const _submit = async () => {
        try {
            setLoading(true)
            const payload = {
                applied_stores: selectedStore,
            }
            const {success, message} = await changeVariantScope(id, payload)
            if (!success) throw new Error(message)
            toaster({
                type: 'success',
                message: 'Change applied store successfully!',
            })
            PubSub.publish('UPDATED_CATALOG_DETAIL')
            toggle()
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } finally {
            setLoading(false)
        }
    }

    const _renderCustomVariantStores = (appliedStores) => {
        return appliedStores.length > 0
            ? appliedStores.map((store) => {
                  return {
                      label: `${store.slug} - ${store.domain}`,
                      value: store._id,
                  }
              })
            : []
    }

    const _onChange = (options) => {
        const values = options && options.length > 0 ? options.map(o => o.value) : []
        setSelectedStore(values)
        setEnableSave(true)
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered>
            <ModalHeader toggle={toggle}>Select store applied</ModalHeader>
            <ModalBody>
                <div className="form-group row">
                    <div className="col-sm-12">
                        <Label className="font-weight-bold">Applied store</Label>
                        {catalog.scope === 'custom' ? (
                            <Select
                                isMulti={true}
                                isClearable={true}
                                closeMenuOnSelect={false}
                                placeholder="Select stores"
                                onChange={_onChange}
                                options={_renderCustomVariantStores(catalog.applied_stores)}
                                defaultValue={_renderCustomVariantStores(appliedStores)}
                            />
                        ) : (
                            <StoreSelect
                                needMaxStore={false}
                                defaultValue={_renderCustomVariantStores(appliedStores)}
                                onChangeStore={_handleChangeStore}
                            />
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggle}>
                    Cancel
                </Button>{' '}
                <Button color="primary" onClick={_submit} disabled={!enableSave}>
                    {loading && <i className="fas fa-circle-notch fa-spin mr-1"></i>}
                    Save
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default BaseCostScopeModal

