import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

class StoreNameColumn extends Component {
    render() {
        const {item} = this.props
        const {storeDomain, storeId} = item

        return (
            <td className='StoreNameColumn'>
                {storeDomain || ''}
                <div>
                    <code>{storeId}</code>
                </div>
            </td>
        )
    }
}

StoreNameColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default StoreNameColumn
