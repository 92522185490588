export const CATALOG_VARIANT_TABS = [
    {
        title: 'Base cost',
        value: 'base_cost',
    },
    // {
    //     title: 'Seller shipping-VN',
    //     value: 'seller_shipping_vn',
    // },
    {
        title: 'Shipping',
        value: 'shipping',
    },
    {
        title: 'TikTok fee',
        value: 'tiktok_fee',
    },
    {
        title: 'Related product',
        value: 'related_product',
    },
    {
        title: 'Design',
        value: 'design',
    },
    {
        title: 'Color mapping',
        value: 'color_mapping',
    },
]
