import React from 'react'
import PropTypes from 'prop-types'
import {compareToOldDatabase} from '../../../services/api/StoreServices'
import ModalCommon from '../../setting-webhook/ModalCommon'

class ButtonDetailMoveDatabase extends React.Component {
    state = {
        isLoading: false,
        isShow: false,
    }

    handleOpenModal = async () => {
        try {
            this.setState((prev) => ({...prev, isLoading: true, isShow: true}))
            const {store} = this.props
            const {_id: storeId} = store

            const {success, message} = await compareToOldDatabase(storeId)
            if (!success) {
                throw new Error(message)
            }
        } catch (error) {
            alert(error.message)
        }
        this.setState((prev) => ({...prev, isLoading: false}))
    }

    handleClickOk = async () => {
        this.setState((prev) => ({...prev, isLoading: false}))
    }

    handleClose = () => {
        this.setState((prev) => ({...prev, isShow: false}))
    }


    render() {
        const {isLoading, isShow} = this.state

        return (
            <>
                <div className="ButtonMoveDatabase d-inline-block">
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={this.handleOpenModal}
                        disabled={isLoading}
                        style={{marginLeft: 10}}
                    >
                        {isLoading && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                        Moved Details
                    </button>
                </div>
                <ModalCommon
                    isOpen={isShow}
                    title="Move Detail"
                    onClose={this.handleClose}
                    onSubmit={this.handleClickOk}
                >
                    TODO MAKE TABLE, now check api request
                </ModalCommon>
            </>
        )
    }
}

ButtonDetailMoveDatabase.propTypes = {
    isRestore: PropTypes.bool.isRequired,
    changeStoreDetail: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired,
}

export default ButtonDetailMoveDatabase
