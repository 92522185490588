import React, {Fragment, useCallback, useEffect, useState} from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Progress} from 'reactstrap'
import {DESTINATION_TITLE} from '../constants'
import {exportDetailOverdueShipment, getDetailOverdueShipment, getFileExportDetailOverdueShipment} from '../../../services/api/OverdueShipmentServices'
import PFTablePlaceholder from '../../shared/PFTablePlaceholder'
import PFPagePagination from '../../shared/PFPagePagination'
import {toaster} from '../../shared/PFToast'
import pluralize from '../../../helpers/common/pluralize'
import { delay } from '../../../helpers/delay'
import commonDateTime2 from '../../../helpers/time/commonDateTime2'

const DelayShipmentModal = (props) => {
    const {packagedAt, delayShipment, toggle, platform, query} = props

    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    const [details, setDetails] = useState([])
    const [loadingExport, setLoadingExport] = useState(false)

    const _parseEvent = (event) => {
        if (!event) return ''
        return `(${event.replace('_', '-')})`
    }

    const _getDestination = (destination) => {
        const splitStr = destination.split('-')
        return {
            from: splitStr[0] || '',
            to: splitStr[1] || '',
        }
    }

    const _fetchDelayShipment = useCallback(async () => {
        try {
            setLoading(true)
            const {slug, stage, destination} = delayShipment
            const payload = {
                page,
                stage,
                limit: 50,
                store: slug,
                packaged_to: packagedAt.to,
                packaged_from: packagedAt.from,
                to: _getDestination(destination).to,
                from: _getDestination(destination).from,
                deduplicate: query.deduplicate,
                source: platform
            }

            const {success, data, message} = await getDetailOverdueShipment(payload)
            if (!success) {
                throw new Error(message)
            }
            const {details, pages, total} = data
            setTotal(total)
            setPages(pages)
            setDetails(details)
        } catch (error) {
        } finally {
            setLoading(false)
        }
    }, [page, delayShipment, packagedAt])

    const _handleChangePage = (page) => {
        setPage(page)
    }

    const _downloadCSV = (fileUrl, fileName) => {
        const link = document.createElement('a')
        link.setAttribute('href', fileUrl)
        link.setAttribute('download', fileName)
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const [progress, setProgress] = useState(0)

    const _handleExportCsv = async () => {
        try {
            setLoadingExport(true)
            const {slug, stage, destination} = delayShipment
            const payload = {
                stage,
                store: slug,
                packaged_to: packagedAt.to,
                packaged_from: packagedAt.from,
                to: _getDestination(destination).to,
                from: _getDestination(destination).from,
                deduplicate: query.deduplicate,
                source: platform
            }
            const { data } = await getFileExportDetailOverdueShipment(payload)
            if (!data) throw new Error('Something wrong, please try again!')

            let totalRequest = 0
            while (true) {
                await delay(5000)
                const { data: exportData } = await exportDetailOverdueShipment(data)

                if (!exportData) throw new Error('Something wrong, please try again!')

                const { exported, total, link } = exportData

                setProgress((exported / total) * 100)

                if(link) {
                    _downloadCSV(
                        link,
                        `Delay ship ${DESTINATION_TITLE[delayShipment.destination]} ${_parseEvent(
                            delayShipment.stage
                        )}_store ${slug}.csv`
                    )
                    break
                }

                totalRequest++

                if (totalRequest >= 100) throw new Error(totalRequest + '  Something wrong, please try again!')
            }
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        } finally {
            setLoadingExport(false)
        }
    }

    const _parseShipmentStatus = (status) => {
        if (!status) return '-'
        status = status.replace('_', ' ')
        return status.charAt(0).toUpperCase() + status.slice(1)
    }

    useEffect(() => {
        _fetchDelayShipment()
    }, [_fetchDelayShipment])

    return (
        <Modal isOpen={delayShipment.isOpen} toggle={toggle} className="DelayShipmentModal" size="xl" centered>
            <ModalHeader toggle={toggle}>
                Delay ship {DESTINATION_TITLE[delayShipment.destination]} {_parseEvent(delayShipment.stage)}
            </ModalHeader>
            <ModalBody>
                <div className="d-flex align-items-center justify-content-between mb-3" style={{gap: "1rem"}}>
                    <button className="btn btn-primary" onClick={_handleExportCsv} disabled={loadingExport}>
                        {loadingExport && <i className="fas fa-circle-notch fa-spin mr-2" />}
                        Export csv
                    </button>
                    <b>{pluralize(total, 'item', 's')}</b>
                </div>
                {loadingExport  &&
                    <Progress
                        animated
                        value={progress < 5 ? 5 : progress}
                        className="my-3"
                    />
                }
                <div className="table-responsive">
                    <table className="table table-border table-hover">
                        <thead className="thead-light">
                        <tr>
                            <th>Store</th>
                            <th>Order Number</th>
                            <th>Package Number</th>
                            <th>Supplier</th>
                            <th>Shipping Carrier</th>
                            <th>Shipment started</th>
                            <th>Actual Time</th>
                            <th>Shipment status</th>
                        </tr>
                        </thead>
                        <tbody className="TableLoading">
                        {loading ? (
                            <PFTablePlaceholder columnQuantity={8} />
                        ) : details.length === 0 ? (
                            <tr>
                                <td className="text-center" colSpan={8}>
                                    <span className="text-muted fs-14">No result!</span>
                                </td>
                            </tr>
                        ) : (
                            <Fragment>
                                {details.map((detail, index) => (
                                    <tr key={index}>
                                        <td>{delayShipment.slug}</td>
                                        <td>{detail.order_number}</td>
                                        <td>{detail.package_number}</td>
                                        <td>{detail.supplier_name || '-'}</td>
                                        <td>{detail.shipping_carrier || '-'}</td>
                                        <td>{commonDateTime2(detail.shipment_started)}</td>
                                        <td>{detail.actual_time || '-'}</td>
                                        <td>{_parseShipmentStatus(detail.shipment_status)}</td>
                                    </tr>
                                ))}
                            </Fragment>
                        )}
                        </tbody>
                    </table>
                </div>
                <PFPagePagination page={page} pages={pages} onChangePage={_handleChangePage} />
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default DelayShipmentModal
