import React, {Component} from 'react'
import PropTypes from 'prop-types'
import InputFeatureTags from './InputFeatureTags'
import StoreFeatureTags from './StoreFeatureTags'
import {updateFeatureTags} from '../../../../services/api/StoreServices'

class StoreFeatureTagsWrapper extends Component {
    state = {
        features: this.props.features,
        error: '',
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.features.length !== this.props.features.length && Array.isArray(this.props.features)) {
            this.setState({
                features: this.props.features,
            })
        }
    }

    _addFeature = (feature) => {
        this.setState(({features}) => {
            return {
                features: [...features, feature],
            }
        })
    }

    _reqRemoveFeature = async (tag) => {
        try {
            const {storeId} = this.props

            const remainFeatures = this._getRemainFeatures(tag)

            const {success, message} = await updateFeatureTags(storeId, remainFeatures)

            if (!success) {
                throw new Error(message)
            }

            this.setState(({features}) => {
                const filtered = features.filter(item => item !== tag)

                return {
                    features: filtered,
                }
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })
        }
    }

    _getRemainFeatures = (feature) => {
        const {features} = this.state
        return features.filter(item => item !== feature)
    }

    render() {
        const {features, error} = this.state

        return (
            <div className='StoreFeatureTagsWrapper'>
                <InputFeatureTags storeId={this.props.storeId} features={features} onAddFeature={this._addFeature}/>

                <StoreFeatureTags features={features} onRemoveFeature={this._reqRemoveFeature}/>

                {
                    error &&
                    <div><span className='font-weight-bold'>ERROR:</span> {error}</div>
                }
            </div>
        )
    }
}

StoreFeatureTagsWrapper.defaultProps = {
    features: [],
}

StoreFeatureTagsWrapper.propTypes = {
    storeId: PropTypes.string.isRequired,
    features: PropTypes.array.isRequired,
}

export default StoreFeatureTagsWrapper
