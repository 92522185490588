import moment from 'moment'
import PropTypes from 'prop-types'
import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'
import {CustomInput, UncontrolledTooltip} from 'reactstrap'
import humanizeTime from '../../../helpers/time/humanizeTime'
import PFTable from '../../shared/PFTable'
import TableLoading from '../../shared/TableLoading'
import {toaster} from '../../shared/PFToast'
import {updateCashbackRule} from '../../../services/api/BillingServices'
import iconCopy from '../../../statics/assets/icons/icon_copy.svg'

const CashbackRuleTable = (props) => {
    const {cashbackRules, setCashbackRules, loading} = props

    const setCashbackStatus = (listCashback, cashback, checked) => {
        const newCashback = listCashback.map((item) => {
            if (item._id === cashback._id) {
                return {
                    ...item,
                    status: checked,
                }
            } else {
                return {...item}
            }
        })
        return newCashback
    }

    const onUpdateRuleStatus = async (e, item) => {
        const checked = e.target.checked ? 'active' : 'inactive'
        try {
            const payload = {
                ...item,
                status: checked,
            }
            let newCashback = setCashbackStatus(cashbackRules, item, checked)
            setCashbackRules(newCashback)
            const {success, message} = await updateCashbackRule(item._id, payload)
            if (!success) {
                newCashback = setCashbackStatus(cashbackRules, item, !checked)
                setCashbackRules(newCashback)
                throw new Error(message)
            }
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }
    return (
        <div className="CashbackRuleTable table-responsive">
            {
                <PFTable source={cashbackRules}>
                    {() => (
                        <Fragment>
                            <thead className="ProductMockupTableHead thead-light">
                                <tr>
                                    <th className="table-col-20">Rule name</th>
                                    <th className="table-col-15">Store</th>
                                    <th className="table-col-20">Product type</th>
                                    <th className="table-col-10 text-right">Cashback type</th>
                                    <th className="table-col-10 text-right">Quantity</th>
                                    <th className="table-col-15">Apply time</th>
                                    <th className="table-col-20">Updated</th>
                                    <th className="table-col-10">Status</th>
                                    <th className="table-col-10 text-center">Action</th>
                                </tr>
                            </thead>
                            <Fragment>
                                {loading ? (
                                    <TableLoading columnQuantity={9} />
                                ) : Array.isArray(cashbackRules) && cashbackRules.length === 0 ? (
                                    <tbody>
                                        <tr>
                                            <td colSpan="9">
                                                <i className="text-muted">No results!</i>
                                            </td>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <tbody className="ProductMockupTableBody TableLoading">
                                        {Array.isArray(cashbackRules) &&
                                            cashbackRules.map((item, index) => {
                                                const {
                                                    _id,
                                                    name = '-',
                                                    store,
                                                    product_types = [],
                                                    apply_time_from,
                                                    apply_time_to,
                                                    fulfillment_quantity_value = 0,
                                                    fulfillment_quantity_type,
                                                    value = 0,
                                                    value_type,
                                                    updated_at,
                                                    cashback_type,
                                                    status,
                                                } = item
                                                return (
                                                    <tr key={`cashback_rule_${index}`}>
                                                        <td> {name}</td>
                                                        <td>
                                                            {store && (
                                                                <>
                                                                    <p className="mb-0">{store.slug}</p>
                                                                    <Link to={`/a/stores/${store._id}`}>
                                                                        {store.domain}
                                                                    </Link>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td>{product_types.join(', ')}</td>
                                                        <td className="text-right">
                                                            {`${(value_type === 'private_fee' || value_type === 'discount') ?
                                                                `${value_type}` :
                                                                `${value}${value_type === 'fixed' ? '$' : '%'}`
                                                            }/${cashback_type}`}
                                                            {/*{`${value}${*/}
                                                            {/*    value_type === 'fixed' ? '$' : '%'*/}
                                                            {/*}/${cashback_type}`}*/}
                                                        </td>
                                                        <td className="text-right">{`${fulfillment_quantity_value} ${fulfillment_quantity_type}`}</td>
                                                        <td>{`${moment(apply_time_from).format(
                                                            'DD/MM/YYYY'
                                                        )} - ${moment(apply_time_to).format('DD/MM/YYYY')}`}</td>
                                                        <td>{humanizeTime(updated_at)}</td>
                                                        <td className="text-center">
                                                            <CustomInput
                                                                id={`cashback_rule_switch_${index}`}
                                                                type="switch"
                                                                checked={status === 'active'}
                                                                disabled={status === 'inactive'}
                                                                onChange={(e) => onUpdateRuleStatus(e, item)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Link
                                                                className="text-primary mr-2"
                                                                to={`/a/form-cashback-rule/${_id}`}
                                                                id="ButtonEdit"
                                                            >
                                                                {status === 'active' ? (
                                                                    <i className="fa fa-edit" />
                                                                ) : (
                                                                    <i className="fa fa-eye" />
                                                                )}
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    target="ButtonEdit"
                                                                >
                                                                    {status === 'active' ? 'Edit' : 'View'}
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                            <Link
                                                                className="text-info"
                                                                to={`/a/form-cashback-rule/${_id}?action=duplicate`}
                                                                id="ButtonDuplicate"
                                                                target="_blank"
                                                            >
                                                                <i className="fa fa-copy" />
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    target="ButtonDuplicate"
                                                                >
                                                                    Duplicate
                                                                </UncontrolledTooltip>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                )}
                            </Fragment>
                        </Fragment>
                    )}
                </PFTable>
            }
        </div>
    )
}

CashbackRuleTable.propTypes = {
    loading: PropTypes.bool,
    cashbackRules: PropTypes.array,
}

export default CashbackRuleTable

