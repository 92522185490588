import React, {Component} from 'react'
import {Input} from 'reactstrap'
import JobTable from './shared/JobTable'
import {getListPipeline} from '../../../services/api/DeployService'
import JobPagination from './shared/JobPagination'
import StageDetails from './shared/StageDetails'
import ResetModal from './shared/ResetModal'

const REFRESH_TIME = 5000

class JobPage extends Component {
    state = {
        modal: {
            open: false,
            stage: {},
            pipeline: {},
            job: {}
        },
        items: [],
        loading: true,
        params: {
            page: 1,
            limit: 10,
        },
        modalReset: {
            openReset: false,
            item: {}
        },
        total: 0,
        totalPage: 1,
        err: '',
        search_term: '',
    }

    _refresh = null

    componentDidMount() {
        this._getListPipeline()
        this._setRefresh()
    }

    componentWillUnmount() {
        this._refresh && clearInterval(this._refresh)
    }

    _getListPipeline = async (loadingAnimation = true) => {
        try {
            this.setState({
                loading: loadingAnimation,
            })

            const {params, search_term} = this.state
            const { _id } = this.props.store || {}
            const requestData = {search_term, ...params, storeId: _id}
            const {success, data, message} = await getListPipeline(requestData)
            const {total, totalPage, jobs} = Object.assign({}, data)

            if (!success) {
                return this.setState({
                    items: [],
                    err: message,
                    loading: false,
                })
            }

            this.setState({
                items: jobs,
                totalPage: totalPage,
                total: total,
                loading: false,
                err: ''
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _handlePageChange = page => {
        const {params} = this.state
        this.setState(
            {
                params: {...params, page},
            },
            () => this._getListPipeline()
        )
    }

    _handleInputChange = async e => {
        const value = e.target.value
        const timeOut = 500
        this.setState(
            {
                search_term: value,
                loading: true,
                totalPage: 1,
            },
            () => {
                clearInterval(this.typingTimer)
                this.typingTimer = setTimeout(this._getListResource, timeOut)
            }
        )
    }

    _setRefresh = () => {
        this._refresh = setInterval(() => {
            this._getListPipeline(false)
        }, REFRESH_TIME)
    }

    _onClickStage = (job, pipeline, stage) => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                open: true,
                stage: stage,
                pipeline: pipeline,
                job: job
            }
        }))
    }

    _onCloseModal = () => {
        this.setState(({modal}) => ({
            modal: {
                open: false,
                stage: {},
                pipeline:{},
                job: {}
            }
        }))
    }

    _toggleReset = (item = {}) => {
        this.setState({
            modalReset: {
                openReset: !this.state.modalReset.openReset,
                item,
            }
        })
    }

    render() {
        const {params: { page, limit }, totalPage, items, loading, err} = this.state
        const { store } = this.props
        const { job, pipeline, stage, open } = this.state.modal
        const { item, openReset } = this.state.modalReset

        return (
            <div className='AccountsPage'>
                {!store && <h2>Jobs</h2>}
                <div className='AccountsPageInner SectionInner'>
                    {/* {!store && <div className='d-flex align-items-center flex-wrap justify-content-end mb-2'>
                        <div className='SearchBar'>
                            <Input
                                onChange={this._handleInputChange}
                                type='text'
                                className='SearchInput'
                                placeholder='search here'
                            />
                            <i className='fas fa-search SearchIcon' />
                        </div>

                    </div>}
                    {!store && <br/>} */}
                    {!!err && <span className='text-warning'>{err}</span>}
                    <div className='TableContainer table-responsive '>
                        <JobTable items={items} loading={loading} isLights={ store ? false : true } onClickStage={this._onClickStage} toggleReset={this._toggleReset} store={store} />
                        <JobPagination page={page} pages={totalPage} handlePageChange={this._handlePageChange} />
                    </div>
                </div>
                <StageDetails
                    open={open}
                    pipeline={pipeline}
                    job={job}
                    stage={stage}
                    onClose={this._onCloseModal}
                />
                <ResetModal item={item} open={openReset} toggleReset={this._toggleReset} />
            </div>
        )
    }
}

export default JobPage
