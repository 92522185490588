import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ProductVariantsAction extends Component {
    _handleClickUpdate = () => {
        const {handleUpdateVariants} = this.props
        handleUpdateVariants()
    }

    _handleClickDiscard = () => {
        const {handleDiscardChangeState} = this.props
        handleDiscardChangeState()
    }

    render() {
        const {loading, error} = this.props

        return (
            <div className="ProductVariantsAction">
                <div className="d-flex justify-content-end">
                    <button className="btn btn-secondary mr-3" onClick={this._handleClickDiscard}>Discard</button>
                    <button className="btn btn-primary" onClick={this._handleClickUpdate}>
                        {loading ? "Saving..." : "Save variants"}
                    </button>
                </div>

                {
                    !!error &&
                    <div className="text-danger text-right mt-3">{error}</div>
                }
            </div>
        )
    }
}

ProductVariantsAction.propTypes = {
    handleUpdateVariants: PropTypes.func.isRequired,
    handleDiscardChangeState: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default ProductVariantsAction
