import React, {Component} from 'react'
import PropTypes from 'prop-types'
import StoreFeatureTagsWrapper from './StoreFeatureTagsWrapper'

class StoreFeatureTagsContainer extends Component {
    render() {
        const {_id, features} = this.props.store

        return (
            <div className='StoreFeatureTagsContainer'>
                <div className='card'>
                    <div className='card-header font-weight-bold'>Feature tags</div>

                    <div className='card-body'>
                        <StoreFeatureTagsWrapper storeId={_id} features={features}/>
                    </div>
                </div>
            </div>
        )
    }
}

StoreFeatureTagsContainer.propTypes = {
    store: PropTypes.object.isRequired,
}

export default StoreFeatureTagsContainer
