import React, {Component} from 'react'

class ServiceListHead extends Component {
    render() {
        return (
            <thead className="ServiceListHead thead-light">
                <tr>
                    <th>Name</th>
                    <th>Message</th>
                    <th>Status</th>
                </tr>
            </thead>
        )
    }
}

export default ServiceListHead
