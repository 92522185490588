import React, {Component} from 'react'
import SettingSectionDetails from '../../setting/shared/SettingSectionDetails'
import S3Image from './S3Image'
import {getSettingsByCategory, saveSettingsByCategory} from '../../../services/api/SettingServices'
import S3ArtworkBucket from './S3ArtworkBucket'

class SettingStoragePage extends Component {
    state = {
        settings: {
            's3_image_bucket': '',
            's3_image_access_key': '',
            's3_image_secret_key': '',
            'artwork_s3_bucket': '',
            'artwork_s3_endpoint': '',
            'artwork_s3_access_key': '',
            'artwork_s3_secret_key': '',
        },
        loading: {
            fetchSettings: false,
            saveSettings: false,
        },
    }

    componentWillMount() {
        this._fetchSettings()
    }

    _onClickSaveButton = () => {
        this._saveSettings()
    }

    _onChangeSetting = (key, value) => {
        this.setState(({settings}) => ({
            settings: {
                ...settings,
                [key]: value,
            }
        }))
    }

    _fetchSettings = async () => {
        this.setState(({loading}) => ({
            loading: {
                ...loading,
                fetchSettings: true,
            }
        }))

        try {
            const {data, success, err} = await getSettingsByCategory('storage')

            if (success) return this.setState(({loading, settings}) => ({
                loading: {
                    ...loading,
                    fetchSettings: false,
                },
                settings: {
                    ...settings,
                    ...data
                }
            }))

            alert(err)
        } catch (e) {
            alert(e.message || e)
            this.setState(({loading}) => ({
                loading: {
                    ...loading,
                    fetchSettings: false,
                }
            }))
        }
    }

    _saveSettings = async () => {
        this.setState(({loading}) => ({
            loading: {
                ...loading,
                saveSettings: true,
            }
        }))

        try {
            const {settings} = this.state
            const {data, err} = await saveSettingsByCategory('storage', {...settings})

            this.setState(({loading}) => ({
                loading: {
                    ...loading,
                    saveSettings: false,
                },
                settings: {
                    ...settings,
                    ...data,
                }
            }))

            if (err) return alert(err)
        } catch (e) {
            alert(e.message || e)
            this.setState(({loading}) => ({
                loading: {
                    ...loading,
                    saveSettings: false,
                }
            }))
        }
    }

    render() {
        const {settings, loading} = this.state

        return (
            <div className="SettingS3ImagePage">

                <div className="Section">
                    <SettingSectionDetails title='S3 Image'/>
                    <div className="SectionSetting">
                        <div className="SectionInner">
                            <S3Image settings={settings} disabled={loading.fetchSettings}
                                     onChangeSetting={this._onChangeSetting}/>
                        </div>
                    </div>
                </div>

                <div className="Section">
                    <SettingSectionDetails title='S3 Artwork bucket'/>
                    <div className="SectionSetting">
                        <div className="SectionInner">
                            <S3ArtworkBucket settings={settings} disabled={loading.fetchSettings}
                                             onChangeSetting={this._onChangeSetting}/>
                        </div>
                    </div>
                </div>

                <div className="ButtonSave">
                    <button
                        className="btn btn-primary" disabled={loading.saveSettings || loading.fetchSettings}
                        onClick={this._onClickSaveButton}>
                        <span className="Label">
                            Save
                        </span>
                    </button>
                </div>

            </div>
        )
    }
}

export default SettingStoragePage
