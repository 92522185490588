import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/billing' : 'https://dev1-manager.merch8.com/api/billing'

const api = createAPIServices({baseUrl})

export const getPayoutApproval = (params) => {
    return api.makeAuthRequest({
        url: '/v2/withdrawal/search',
        method: 'POST',
        data: params
    })
}

export const actionPayoutApproval = (id) => {
    return api.makeAuthRequest({
        url: `/v2/withdrawal/${id}/approve`,
        method: 'POST',
    })
}

export const actionPayoutReject = (id, reason) => {
    return api.makeAuthRequest({
        url: `/v2/withdrawal/${id}/reject`,
        method: 'POST',
        data: {
            note: reason
        }
    })
}
export const actionPayoutTransfer = (id, params) => {
    return api.makeAuthRequest({
        url: `/v2/withdrawal/${id}/pay`,
        method: 'POST',
        data: params
    })
}

