import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Editor} from '@tinymce/tinymce-react'
import {TinyMceEditorToolbar, TinyMceEditorPlugins, APIKeyTinymceEditor, TinymceScriptSrc} from '../../../../helpers/TinyMceEditor'

const InputDescriptions = (props) => {
    const {description, errorObj, setEditorRefs, disabled = false} = props

    const setRefs = (editor) => {
        if (!setEditorRefs) return
        setEditorRefs("editorDescription", editor)
    }

    return (
        <div className={classNames('form-group ProductLineDescription', {'is-invalid': errorObj, disabled})}>
            <label className='font-weight-bold' htmlFor="ProductLineDescription">Product description</label>
            <Editor
                disabled={disabled}
                onInit={(evt, editor) => {setRefs(editor)}}
                initialValue={description}
                apiKey={APIKeyTinymceEditor}
                tinymceScriptSrc={TinymceScriptSrc}
                init={{
                    height: '480',
                    skin: 'small',
                    icons: 'small',
                    toolbar: TinyMceEditorToolbar,
                    plugins: TinyMceEditorPlugins
                }}
            />

            {
                errorObj &&
                <div className="invalid-feedback d-block">
                    {errorObj}
                </div>
            }
        </div>
    )
}

InputDescriptions.propTypes = {
    description: PropTypes.string,
    errorObj: PropTypes.object,
}

export default InputDescriptions
