import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Card, CardBody, CardTitle} from 'reactstrap'

class VisitBox extends Component {
    render() {
        const {formattedData} = this.props
        const {totalVisits, totalVisitors} = formattedData
        return (
            <div className=' Box'>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>Visitors</h5>
                        </CardTitle>
                        <div className='card-item'>
                            <span>Total Visits</span>
                            <span>{totalVisits || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Total Visitors</span>
                            <span>{totalVisitors || 0}</span>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

VisitBox.propTypes = {
    formattedData: PropTypes.object.isRequired,
}

export default VisitBox
