import createAPIServices from './createApiServices'
import getEnv from '../../helpers/common/getEnv'

const baseUrl = `${getEnv('baseSellerHost')}/api/seller`
const api = createAPIServices({baseUrl})

export const searchProductReviewTicket = (data) => {
    return api.makeAuthRequest({
        url: '/product-review/tickets/search',
        method: 'POST',
        data,
    })
}

export const approveProductTicket = (ticketId, baseUrl) => {
    const sellerApi = createAPIServices({baseUrl})

    return sellerApi.makeAuthRequest({
        url: `/product-review-tickets/${ticketId}/approve`,
        method: 'POST',
    })
}

export const rejectProductTicket = (ticketId, reason, baseUrl) => {
    const sellerApi = createAPIServices({baseUrl})

    return sellerApi.makeAuthRequest({
        url: `/product-review-tickets/${ticketId}/reject`,
        method: 'POST',
        data: {
            reason: reason,
        },
    })
}

export const getTicket = (ticketId, sellerHost) => {
    const sellerApi = createAPIServices({baseUrl: sellerHost})

    return sellerApi.makeAuthRequest({
        url: `/product-review-tickets/${ticketId}`,
        method: 'GET',
    })
}

export const bulkApproveTickets = (payload) => {
    return api.makeAuthRequest({
        url: '/product-review-tickets/approves',
        method: 'POST',
        data: payload
    })
}

export const bulkRejectTickets = (payload) => {
    return api.makeAuthRequest({
        url: '/product-review-tickets/rejects',
        method: 'POST',
        data: payload
    })
}

export const getListTemplate = (params) => {
    return api.makeAuthRequest({
        url: `/amazon/templates`,
        method: 'GET',
        params
    })
}

export const createTemplate = (template) => {
    return api.makeAuthRequest({
        url: `/amazon/templates`,
        method: 'POST',
        data: {template}
    })
}

export const editTemplate = (id) => {
    return api.makeAuthRequest({
        url: `/amazon/templates/${id}`,
        method: 'GET',
    })
}

export const updateTemplate = (id, template) => {
    return api.makeAuthRequest({
        url: `/amazon/templates/${id}`,
        method: 'PUT',
        data: {template}
    })
}

export const deleteTemplate = (id) => {
    return api.makeAuthRequest({
        url: `/amazon/templates/${id}`,
        method: 'DELETE',
    })
}

export const extractDataTemplate = (file) => {
    return api.makeAuthRequest({
        url: `/amazon/extract_template`,
        method: 'POST',
        data: file
    })
}

export const getListDelivery = (data) => {
    return api.makeAuthRequest({
        url: `/delivery-profiles/search`,
        method: 'POST',
        data
    })
}

export const getDeliveryDetail = (id) => {
    return api.makeAuthRequest({
        url: `/delivery-profiles/${id}`,
        method: 'GET',
    })
}

export const createDelivery = (data) => {
    return api.makeAuthRequest({
        url: `/delivery-profiles/create`,
        method: 'POST',
        data
    })
}

export const updateDelivery = (id, data) => {
    return api.makeAuthRequest({
        url: `/delivery-profiles/${id}`,
        method: 'PUT',
        data
    })
}

export const deleteDelivery = (id) => {
    return api.makeAuthRequest({
        url: `/delivery-profiles/${id}`,
        method: 'DELETE',
    })
}
