import React, { useState, useEffect } from 'react'
import BackNavigationButton from './components/BackNavigationButton'
import PaypalRefund from './components/PaypalRefund'
import FulfillmentRefund from './components/FulfillmentRefund'
import ProfitAdjustment from './components/ProfitAdjustment'
import { getDetailOrderRefund } from '../../../services/api/OrderRefundServices'
import { createProfitAdjustment } from '../../../services/api/profitAdjustmentServices'
import ModalConfirm from './components/ModalConfirm'

const DetailOrderRefund = (props) => {
    const [data, setData] = useState({})
    const [profit, setProfit] = useState({})
    const [errorProfit, setErrorProfit] = useState({})
    const [isDisabled, setIsDisabled] = useState(false)
    const [markProfitDone, setMarkProfitDone] = useState(false)
    const [isDisabledButtom, setsDisabledButtom] = useState(false)
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isCancel, setIsCancel] = useState(false)

    const orderRefund = props.match.params.id
    useEffect(() => {
        const callApiGetData = async () => {
            try {
                const { success, data = {} } = await getDetailOrderRefund(orderRefund)
                if (success) {
                    setData(data)
                    const { mark_profit_done = false } = data
                    setIsDisabled(mark_profit_done)
                    setMarkProfitDone(mark_profit_done)
                    setsDisabledButtom(mark_profit_done)
                }
            } catch (error) {
                alert(error.message)
            }
        }
        callApiGetData()
    }, [orderRefund])

    const _onClickAddProfit = () => {
        let profitNew = { ...profit }
        profitNew[Date.now()] = {
            value: '',
            note: ''
        }
        setIsDisabled(true)
        setProfit(profitNew)
    }



    const _onChangeProfits = (key, name, value) => {
        let profitNew = { ...profit }
        let errorProfitNew = { ...errorProfit }
        delete errorProfitNew[`${key}${name}`]
        profitNew[key][name] = value
        setErrorProfit(errorProfitNew)
        setProfit(profitNew)
    }

    const _validate = () => {
        let profitNew = { ...profit }
        let errorProfitNew = { ...errorProfit }

        for (let i in profitNew) {
            let { note = '' } = profitNew[i]
            note = note.trim()
            if (!profitNew[i].value) {
                errorProfitNew[`${i}value`] = "This value must be greater than 0"
            } else {
                if (Number(profitNew[i].value) > 0) {
                    delete errorProfitNew[`${i}value`]
                } else {
                    errorProfitNew[`${i}value`] = "This value must be greater than 0"
                }
            }
            if (!note) {
                errorProfitNew[`${i}note`] = "This field can't be blank"
            } else {
                delete errorProfitNew[`${i}note`]
            }
        }
        setErrorProfit(errorProfitNew)
        return Object.keys(errorProfitNew).length

    }

    const _getDetailOrderRefund = async () => {
        const { success, data = {} } = await getDetailOrderRefund(orderRefund)
        if (success) {
            setData(data)
            const { mark_profit_done = false } = data
            setIsDisabled(mark_profit_done)
            setMarkProfitDone(mark_profit_done)
            setsDisabledButtom(mark_profit_done)
        }
    }

    const _onClickSaver = async () => {
        if (_validate()) {
            setModal(false)
            return
        }
        let param = {}
        let index = ''
        for (let i in profit) {
            param = profit[i]
            index = i
        }
        setLoading(true)
        const { success, errors, message } = await createProfitAdjustment(orderRefund, {
            mark_profit_done: markProfitDone,
            param
        })
        if (!success) {
            if (errors && errors.length) {
                const errorProfitNew = { ...errorProfit }
                const error = errors[0]
                let nameError = ''
                let messageError = ''
                for (let i in error) {
                    nameError = i
                    messageError = [error[i]]
                }
                errorProfitNew[`${index}${nameError}`] = messageError
                setErrorProfit(errorProfitNew)
            } else {
                alert(message)
            }
        } else {
            setProfit({})
            await _getDetailOrderRefund()
        }
        setLoading(false)
        setModal(false)
    }

    const toggle = (status = false) => {
        setIsCancel(status)
        setModal(!modal)
    }

    const _onClickCancel = async () => {
        setLoading(true)
        setProfit({})
        await _getDetailOrderRefund()
        setErrorProfit({})
        setModal(false)
        setLoading(false)
        setIsCancel(false)
    }

    const { paypal_refunds = [], invoice_refund_fulfillment = [], profitAdjustment = [], status, order_number, store_slug } = data
    let profitAdjustmentValue =''
    if(Object.values(profit).length){
        profitAdjustmentValue = Object.values(profit)[0].value
    }
    return (
        <div className='DetailOrderRefund'>
            <BackNavigationButton
                status={status}
                order_number={order_number}
                store_slug={store_slug}
            />
            <div className=' container-fluid'>
                <PaypalRefund
                    payPalRefunds={paypal_refunds}
                />
                <FulfillmentRefund
                    invoiceRefund={invoice_refund_fulfillment}
                />
                <ProfitAdjustment
                    onClickAddProfit={_onClickAddProfit}
                    profits={profit}
                    onChangeProfits={_onChangeProfits}
                    errorProfit={errorProfit}
                    isDisabled={isDisabled}
                    profitAdjustment={profitAdjustment}
                />
                <div className='row'>
                    <div className='col-12'>
                        <div className="form-check">
                            <input disabled={isDisabledButtom} className="form-check-input" type="checkbox" checked={markProfitDone} onChange={(e) => setMarkProfitDone(e.target.checked)} value="" id="flexCheckDefault" />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Mark profit adjustment as done
                            </label>
                        </div>
                    </div>
                </div>
                {!isDisabledButtom && <div className='row mt-5'>
                    <div className='col-12 text-right'>
                        <button type="button" className="btn btn-outline-secondary mr-1" onClick={() => { toggle(true) }}>Cancel</button>
                        <button type="button" className="btn btn-primary ml-1" onClick={() => toggle(false)}>Save</button>
                    </div>
                </div>}
                <ModalConfirm
                    loading={loading}
                    modal={modal}
                    toggle={() => toggle(false)}
                    onClickApprove={!isCancel ? _onClickSaver : _onClickCancel}
                    modalHeader={!isCancel ? 'Confirm adjustment' : 'Cancel adjustment'}
                    modalBody={!isCancel ? `Arter this confirmation, profit of seller will be reduced by $${profitAdjustmentValue}` : 'The changes of profit will not be saved.'}
                />
            </div>
        </div>
    )
}

export default DetailOrderRefund
