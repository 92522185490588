import React, {Component} from 'react'
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import {unmountComponentAtNode} from 'react-dom'
import TableErrors from './TableErrors'
import {bulkRejectTickets} from '../../../../services/api/SellerServices'
import {toaster} from '../../../shared/PFToast'
import PropTypes from 'prop-types'
import {getStoreDetail} from '../../../../services/api/StoreServices'

class ModalConfirmRejection extends Component {
    state = {
        isOpen: true,
        reason: '',
        loading: false,
        error: null,
        actionResult: null,
    }

    componentWillUnmount() {
        unmountComponentAtNode(this.props.el)
    }

    _handleChangeInput = e => {
        this.setState({reason: e.target.value})
    }

    _toggleModal = () => {
        this.setState(prevState => ({isOpen: !prevState.isOpen}))
    }

    _requestReject = async e => {
        e.preventDefault()

        try {
            this.setState({loading: true})

            const selectedTickets = this.props.tickets.filter(ticket => this.props.selectedIDs.includes(ticket._id))

            // Get store's setting of selected ticket
            const responseStoresDetail = await Promise.all(selectedTickets.map(ticket => getStoreDetail(ticket.store._id)))
            const storeDetails = responseStoresDetail.map(res => res.data)

            const payload = {
                ticket: selectedTickets.map(ticket => {
                    const storeSettingsTicket = storeDetails.find(store => store._id === ticket.store._id)
                    const backofficeApiUrl = storeSettingsTicket !== undefined ? storeSettingsTicket.backoffice_api_url : ''

                    return {
                        ticket_id: ticket._id,
                        backoffice_api_url: backofficeApiUrl
                    }
                }),
                reason: this.state.reason,
            }

            const {data, success, message} = await bulkRejectTickets(payload)

            if (!success) {
                this.setState({loading: false, error: message, actionResult: false})
                return
            }

            const {totalError} = data
            if (totalError === 0) {
                this._toggleModal()
                toaster({
                    type: 'success',
                    message: 'Successfully!',
                })
            } else {
                this.setState({loading: false, actionResult: data})
            }

            this.props.onAfterBulkAction()
        } catch (e) {
            this.setState({loading: false, error: e.message, actionResult: false})
        }
    }

    _renderActionResult = () => {
        const {actionResult, error} = this.state
        if (error) return <div className='text-danger'>{error}</div>
        if (actionResult) return <TableErrors actionResult={actionResult}/>

        return null
    }

    render() {
        const {loading, actionResult} = this.state

        return (
            <Modal toggle={this._toggleModal} isOpen={this.state.isOpen}>
                <ModalHeader>Confirm rejection</ModalHeader>
                <ModalBody>
                    {
                        actionResult === null ?
                            <Input
                                type='textarea'
                                placeholder='Enter your reason...'
                                onChange={this._handleChangeInput}
                            />
                            :
                            this._renderActionResult()
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        type='button'
                        className='btn-default'
                        onClick={this._toggleModal}
                    >
                        Cancel
                    </Button>

                    {
                        actionResult === null &&
                        <Button
                            type='button'
                            color='danger'
                            disabled={this.state.reason.trim() === '' || loading}
                            onClick={this._requestReject}
                        >
                            Reject
                        </Button>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

ModalConfirmRejection.propTypes = {
    selectedIDs: PropTypes.array.isRequired,
    onAfterBulkAction: PropTypes.func.isRequired,
}

export default ModalConfirmRejection
