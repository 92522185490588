import React from 'react'

class ValueColumn extends React.Component {
    render() {
        const {value} = this.props
        return (
            <td>
                <span>{value || ''}</span>
            </td>
        )
    }
}

export default ValueColumn
