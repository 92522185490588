import React, {Component} from 'react'
import PropTypes from 'prop-types'

class RatePlanContainer extends Component {
    _handleChange = e => {
        const {name, value} = e.target

        this.props.onUpdateRatesData(name, value)
    }

    render() {
        return (
            <div className='RatePlanContainer'>
                <div className='SectionInner'>
                    <div className='border-bottom pb-3 mb-3'>
                        <h6 className='fs-18 mb-0'>Fulfillment by Merchize</h6>
                        <div className='text-muted fs-14'>This option allows you to match your shipping options to
                            Merchize shipping methods. Please read <a
                                href='http://docs.merchize.com/support/solutions/articles/48000965590-h%C6%B0%E1%BB%9Bng-d%E1%BA%ABn-%C4%91%E1%BB%93ng-b%E1%BB%99-fast-shipping-c%E1%BB%A7a-store-b%E1%BA%A1n-v%E1%BB%9Bi-fast-shipping-c%E1%BB%A7a-merchize'
                                target='_blank' rel='noreferrer noopener'>this
                                article</a> carefully to configure this section
                            properly.
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12 col-lg-6'>
                            <select disabled name='fulfillment_plan' className='form-control' onChange={this._handleChange}>
                                <option value='standard'>Standard shipping</option>
                                <option value='fast'>Fast shipping (US only)</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

RatePlanContainer.propTypes = {
    onUpdateRatesData: PropTypes.func.isRequired,
}

export default RatePlanContainer
