import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';


const ModalConfirm = ({ modal, toggle, modalHeader, modalBody, onClickApprove, loading }) => {
    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>{modalHeader}</ModalHeader>
            <ModalBody>
                {modalBody}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>Cancel</Button>
                <Button color="primary" disabled={loading} onClick={onClickApprove}>
                    {
                        loading && <Spinner color='white' size='sm' className='mr-2' />
                    }
                    Approve
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ModalConfirm
