import React, {useState} from 'react'
import PFBoxUploader from '../../shared/PFBoxUploader'
import {toaster} from '../../shared/PFToast'
import FeatureImage from '../../shared/FeatureImage'

const InputUploadBannerImage = ({imageKey, imageSrc, onUploadBanner, onRemoveBanner}) => {
    const [uploading, setUploading] = useState(false)
    const [removing, setRemoving] = useState(false)

    const handleChangeFiles = async (files) => {
        try {
            setUploading(true)
            await onUploadBanner(imageKey, files[0])
        } catch (e) {
            toaster({type: 'error', message: e.message})
        } finally {
            setUploading(false)
        }
    }

    const handleClickRemove = async () => {
        try {
            setRemoving(true)
            await onRemoveBanner(imageKey)
        } catch (e) {
            toaster({type: 'error', message: e.message})
        } finally {
            setRemoving(false)
        }
    }

    return (
        <div>
            {
                typeof imageSrc === 'string' && imageSrc !== ''
                    ?
                    <div>
                        <div className="border rounded p-2 text-center">
                            <FeatureImage src={imageSrc} className="w-100" />
                            {/*<img src={imageSrc} alt="banner-image" style={{maxHeight: 300}} />*/}
                        </div>
                        <div className="text-right">
                            <small className="text-danger cursor-pointer"
                                   onClick={handleClickRemove}>{removing ? 'Removing...' : 'Remove'}</small>
                        </div>
                    </div>
                    :
                    <PFBoxUploader
                        id={imageKey}
                        onChange={handleChangeFiles}
                    >
                        {
                            uploading
                                ?
                                'Uploading...'
                                :
                                <div className="bg-light px-3 py-2 border rounded">
                                    <span className="mr-2"><i className="fa fa-upload" aria-hidden="true" /></span>Choose
                                    file
                                </div>
                        }
                    </PFBoxUploader>
            }
        </div>
    )
}

export default InputUploadBannerImage
