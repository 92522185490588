import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Card, CardBody, CardTitle} from 'reactstrap'

class ImporterBox extends Component {
    render() {
        const {formattedMetricData} = this.props
        const importer = Object.keys(formattedMetricData).length ? formattedMetricData.importer : {}
        const {total, completed, deleted, error, not_importable, pending, processing} = importer
        return (
            <div className='ImporterBox Box'>
                <Card>
                    <CardBody>
                        <CardTitle>
                            <h5>Importer</h5>
                        </CardTitle>
                        <div className='card-item'>
                            <span>Total</span>
                            <span>{total || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Completed</span>
                            <span>{completed || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Deleted</span>
                            <span>{deleted || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Error</span>
                            <span>{error || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Not_importable</span>
                            <span>{not_importable || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Pending</span>
                            <span>{pending || 0}</span>
                        </div>
                        <div className='card-item'>
                            <span>Processing</span>
                            <span>{processing || 0}</span>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

ImporterBox.propTypes = {
    formattedMetricData: PropTypes.object.isRequired,
}
export default ImporterBox
