import React from 'react'
import PropTypes from 'prop-types'


class ModeFilterSelect extends React.Component {
    render() {
        const {onChange, value} = this.props

        return (
            <div className='StatusFilterSelect mr-3'>
                <p className="mb-0">Mode</p>

                <select name='mode' onChange={onChange} value={value}>
                    <option value=''>All</option>
                    <option value="normal">normal</option>
                    <option value="merchize-payment">merchize-payment</option>
                    <option value="marketplace">marketplace</option>
                </select>
            </div>
        )
    }
}

ModeFilterSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
}

export default ModeFilterSelect
