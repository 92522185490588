import React, {Component} from 'react'
import TransactionOutDate from './data/TransactionOutDate'
import TransactionOutID from './data/TransactionOutID'
import TransactionOutNote from './data/TransactionOutNote'
import TransactionOutStatus from './data/TransactionOutStatus'
import TransactionOutAmount from './data/TransactionOutAmount'
import TransactionOutActions from './data/TransactionOutActions'
import TransactionOutOwner from  './data/TransactionOutOwner'
import PropTypes from 'prop-types'

class StoreTransactionsRow extends Component{

    render() {
        const {transaction, store} = this.props
        const {created, note, amount, transaction_id = '', type, status, _id, owner} = transaction

        return (
            <tr className="TransactionOutRow">
                <TransactionOutDate created={created}/>
                <TransactionOutID transactionID={transaction_id}/>
                <TransactionOutNote note={note}/>
                <TransactionOutStatus status={status}/>
                <TransactionOutAmount amount={amount} type={type}/>
                <TransactionOutOwner owner={owner} />
                <TransactionOutActions transactionId={_id} status={status} store={store}
                                       onFetchTransactions={this.props.onFetchTransactions}/>
            </tr>
        )
    }
}

StoreTransactionsRow.propTypes = {
    transaction: PropTypes.object.isRequired,
}

export default StoreTransactionsRow