import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {confirmModal} from "../../../shared/PFConfirmModalV2"
import {changTakeDownProduct} from "../../../../services/api/ProductApprovalServices"
import {toaster} from "../../../shared/PFToast"

class ProductActionTakedown extends Component {

    _handleClickTakedown = e => {
        const {productTitle, isTakenDown} = this.props
        e.preventDefault()

        confirmModal({
            confirmText: 'Ok',
            cancelText: 'Close',
            confirmColor: isTakenDown ? 'primary' : 'danger',
            title: isTakenDown ? 'Confirm reactivate' : 'Confirm takedown',
            message: isTakenDown ? <span>Are you sure you want to reactivate <b>{productTitle}?</b></span> : <span>Are you sure you want to takedown <b>{productTitle}?</b></span>,
            onOk: this._handleTakeDownProduct
        })
    }

    _handleTakeDownProduct = async () => {
        const {isTakenDown, onGetProduct} = this.props
        try {
            const {productId} = this.props
            const {success, message} = await changTakeDownProduct(productId, isTakenDown)
            if (!success) throw new Error(message)

            toaster({
                type: 'success',
                message: isTakenDown ? 'Product reactivate successfully' : 'Product taken down successfully',
                duration: 5000
            })

            onGetProduct()

        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
                duration: 5000
            })
        }
    }

    render() {
        const {isTakenDown} = this.props
        return (
            <div className='d-flex align-content-end justify-content-end'>
                {
                    isTakenDown ?
                        <button className='btn btn-primary' onClick={this._handleClickTakedown}>Reactivate</button>
                        :
                        <button className='btn btn-danger' onClick={this._handleClickTakedown}>Takedown</button>
                }

            </div>

        )
    }
}

ProductActionTakedown.propTypes = {
    productTitle: PropTypes.string.isRequired,
    onGetProduct: PropTypes.func.isRequired
}

export default ProductActionTakedown
