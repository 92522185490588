import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalBody, ModalHeader, PopoverBody, Table, UncontrolledPopover} from 'reactstrap'
import moment from 'moment'

class TransactionDeposit extends Component {
    constructor() {
        super()
        this.state = {
            isOpen: false,
        }
        this._handleClickTransactionId = this._handleClickTransactionId.bind(this)
    }

    _toggleModal = () => {
        const {isOpen} = this.state

        this.setState({
            isOpen: !isOpen,
        })
    }

    _handleClickTransactionId(e) {
        e.preventDefault()
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }))
    }

    render() {
        const {deposit} = this.props
        const {transaction_id, _id, status, approvedTimes, lastApproval, approvedStoreId, transaction_notes} = deposit
        const dateApproved = moment(lastApproval).format('DD/MM/YYYY, HH:mm:ss')
        const isApprovedTimes = isNaN(approvedTimes)

        return (
            <td>
                {status === 'pending' && !isApprovedTimes && approvedTimes > 0 && (
                    <>
                        <i id={'Tooltip-' + _id} className="mr-2 fs-14 text-danger fa fa-exclamation-triangle" />

                        <UncontrolledPopover
                            className="shadow"
                            placement="top"
                            target={'Tooltip-' + _id}
                            trigger="hover"
                        >
                            <PopoverBody>
                                This Transaction ID has been approved
                                <br />
                                <b>
                                    {approvedTimes} {approvedTimes > 1 ? 'times' : 'time'}.
                                </b>{' '}
                                Last approval on{' '}
                                <b>
                                    {approvedStoreId} {dateApproved}
                                </b>
                            </PopoverBody>
                        </UncontrolledPopover>
                    </>
                )}
                <a href="_" onClick={this._handleClickTransactionId}>
                    {transaction_id || '--'}
                </a>

                <Modal isOpen={this.state.isOpen} toggle={this._toggleModal} size="lg" className="Modal" centered>
                    <ModalHeader toggle={this._toggleModal}>Transaction note</ModalHeader>
                    <ModalBody style={{overflow: 'auto'}}>
                        <Table>
                            <thead>
                                <tr>
                                    <th scope="col" className="table-col-15">
                                        Created at
                                    </th>
                                    <th scope="col">Created by</th>
                                    <th scope="col">Note</th>
                                </tr>
                            </thead>

                            <tbody>
                                {transaction_notes && transaction_notes.length > 0 ? (
                                    transaction_notes.map((transaction_note, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{moment(transaction_note.created_at).format('DD/MM/YYYY, HH:mm:ss')}</td>
                                                <td>{transaction_note.created_by}</td>
                                                <td style={{whiteSpace: 'pre-line'}}>{transaction_note.note}</td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={3} className="text-center">
                                            <span className="text-muted">No results!</span>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </ModalBody>
                </Modal>
            </td>
        )
    }
}

TransactionDeposit.propTypes = {
    deposit: PropTypes.object.isRequired,
}

export default TransactionDeposit

