import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/manager' : 'https://manager-qa.merch8.com/api/manager'

const api = createAPIServices({baseUrl})

export const isInitializeInvoice = storeId => {
    return api.makeAuthRequest({
        url: `/stores/${storeId}/is-initialized`,
        method: 'GET',
    })
}

export const initializeInvoice = storeId => {
    return api.makeAuthRequest({
        url: `/stores/${storeId}/initialize-invoice`,
        method: 'POST',
    })
}

export const changeStoreCluster = (storeId, clusterId) => {
    return api.makeAuthRequest({
        url: `/stores/${storeId}/cluster`,
        method: "POST",
        data: {
            cluster: clusterId,
        },
    })
}

export const changeStoreProvider = (storeId, provider) => {
    return api.makeAuthRequest({
        url: `/stores/${storeId}/provider`,
        method: "POST",
        data: {
            provider: provider,
        },
    })
}
