import React, {Component} from 'react'
import SelectedCountryItem from "./SelectedCountryItem"

// import PropTypes from 'prop-types'

class SelectedCountriesList extends Component {
    render() {
        const {selectedCountries, onRemoveSelectedCountry, countries, prevSelectedCountries, restOfWorld} = this.props
        const countriesAvailable = countries.filter(countryObj => countryObj.available)

        const totalROW = restOfWorld ? countriesAvailable.length : countriesAvailable.length + prevSelectedCountries.length

        return (
            <div className="SelectedCountriesList mt-3">
                {
                    selectedCountries === "rest" ?
                        <div>
                            Rest of world ({totalROW} countries and regions)
                        </div>
                        :
                        <ul>
                            {
                                selectedCountries.map(countryObj => {
                                    return <SelectedCountryItem key={`${countryObj.name}_${countryObj.code}`}
                                                                country={countryObj}
                                                                onRemoveSelectedCountry={onRemoveSelectedCountry}/>
                                })
                            }
                        </ul>
                }
            </div>
        )
    }
}

// SelectedCountriesList.defaultProps = {}

// SelectedCountriesList.propTypes = {}

export default SelectedCountriesList
