import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

class ProductListMode extends Component {
    _mapStatus = () => {
        const {status} = this.props

        if (!status) {
            return null
        }

        let output = ''
        switch (status) {
            case 'marketplace':
                output = <span className="badge badge-info badge-pill">{status}</span>
                break
            case 'merchize-payment':
                output = <span className="badge badge-secondary badge-pill">{status}</span>
                break
            default:
                output = status
        }

        return output
    }

    render() {
        const status = this._mapStatus()

        return (
            <Fragment>
                {status}
            </Fragment>
        )
    }
}

ProductListMode.propTypes = {
    status: PropTypes.string.isRequired,
}

export default ProductListMode
