import React, {Component} from 'react'
import PropTypes from 'prop-types'
import RenderTableRow from './RenderTableRow'
import PFTablePlaceholder from '../../shared/PFTablePlaceholder'

class TableBody extends Component {
    render() {
        const {items, page, limit, loading} = this.props

        return (
            <tbody className="TableBody">
                {loading ? (
                    <PFTablePlaceholder columnQuantity={5} />
                ) : items.length > 0 ? (
                    items.map((item, index) => (
                        <RenderTableRow key={index} index={index} item={item} page={page} limit={limit} />
                    ))
                ) : (
                    <tr className="text-center">
                        <td colSpan={5} className="text-muted fs-14">
                            No result!
                        </td>
                    </tr>
                )}
            </tbody>
        )
    }
}

TableBody.propTypes = {
    items: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default TableBody

