import React, {Component} from 'react'
import PropTypes from "prop-types"
import {approveDeposit} from "../../../../../services/api/BillingServices"
import {Modal, ModalHeader, ModalBody, ModalFooter, Spinner} from 'reactstrap'
import classNames from "classnames"
import moment from "moment"

class StatusDepositApproved extends Component {

    state = {
        isApprove: false,
        isOpen: false,
        err: '',
        loading: false,
        param: {force: true}
    }

    _toggleModal = () => {
        const {isOpen, isApprove} = this.state
        const {fetchDeposits} = this.props

        if (isApprove) {
            fetchDeposits()
        }
        this.setState({
            isOpen: !isOpen,
            loading: false,
            err: '',
            isApprove: false
        })
    }

    _changeDepositStatus = async () => {
        const {deposit, fetchDeposits} = this.props
        const {_id, approvedTimes} = deposit

        this.setState({
            loading: true,
            isApprove: false
        })
        const {param} = this.state
        let payload = {_id}

        try {
            if (approvedTimes > 0) {
                payload = {_id, param}
            }
            const {success, data, message} = await approveDeposit(payload)

            if (!success) {
                return (
                    this.setState({
                        isApprove: !success,
                        err: message,
                        loading: true
                    })
                )
            }

            if (data.data !== undefined && data.data.isApproved) {
                return (
                    this.setState({
                        err: data.data.message,
                        isApprove: true,
                        loading: true,
                        isOpen: true
                    })
                )
            }
            this.setState({
                loading: false
            })

            fetchDeposits()

        } catch (e) {
            return this.setState({
                err: e.message,
            })
        }
    }


    render() {
        const {isOpen, err, loading, isApprove} = this.state
        const {deposit} = this.props
        const {approvedTimes, lastApproval, approvedStoreId} = deposit
        const dateApproved = moment(lastApproval).format('DD/MM/YYYY, HH:mm:ss')
        const isApprovedTimes = isNaN(approvedTimes)

        return (
            <>
                <button type="button" onClick={this._toggleModal}
                        className="btn btn-sm btn-success mr-1 d-flex align-items-center fs-14"><i
                    className='fa fa-check mt-1 mr-1'/>Approve
                </button>
                <Modal isOpen={isOpen} toggle={this._toggleModal} className='Modal' centered>
                    <ModalHeader toggle={this._toggleModal}>Approve topup request</ModalHeader>
                    <ModalBody>

                        Are you sure to approve this topup request?
                        {
                            approvedTimes > 0 && !isApprovedTimes &&
                            <div className="alert alert-warning mt-3 mb-2" role="alert">
                                This Transaction ID has been approved<br/>
                                <b>{approvedTimes} {approvedTimes > 1 ? 'times' : 'time'}.</b> Last approval
                                on <b>{approvedStoreId} {dateApproved}</b>
                            </div>
                        }
                        {
                            err && <div className='bg-light p-3 mt-3 mb-1 rounded'>
                                <h6>Result:</h6>
                                <div className='text-danger'>{err}</div>
                            </div>
                        }

                    </ModalBody>
                    <ModalFooter className='border-0'>
                        <button className='btn btn-default'
                                onClick={this._toggleModal}>{(isApprove) ? 'Close' : 'Cancel'}</button>
                        {
                            !isApprove
                            && <button className={classNames('btn btn-primary', {"Disabled": loading})}
                                       onClick={this._changeDepositStatus}>
                                {
                                    loading && <Spinner color='white' size='sm' className='mr-2'/>
                                }
                                Confirm
                            </button>
                        }

                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

StatusDepositApproved.propTypes = {
    fetchDeposits: PropTypes.func.isRequired,
    deposit: PropTypes.object.isRequired,
}

export default StatusDepositApproved
