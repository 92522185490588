import {toastWaring} from './ToastHelper'

export const validateInput = (key, action, error = '') => {
    const classInput = document.getElementsByClassName(key)
    if (!classInput.length) return
    const input = classInput[0]
    if (action === 'add') {
        if (error === 'empty') {
            toastWaring('Please input required value')
        }
        input.classList.add('error')
        input.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
    } else {
        input.classList.remove('error')
    }
}
