import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ClusterSettingIndex from "./data/ClusterSettingIndex"
import ClusterSettingKey from "./data/ClusterSettingKey"
import ClusterSettingValue from "./data/ClusterSettingValue"
import ClusterSettingActions from "./data/ClusterSettingActions"

class ClusterSettingsRow extends Component {
    render() {
        const {index, setting, onFetchClusterSettings, clusterId} = this.props
        const {key, value} = setting

        return (
            <tr className="ClusterSettingsRow">
                <ClusterSettingIndex index={index}/>
                <ClusterSettingKey settingKey={key}/>
                <ClusterSettingValue settingValue={value}/>
                <ClusterSettingActions settingKey={key} settingValue={value} clusterId={clusterId}
                                       onFetchClusterSettings={onFetchClusterSettings}/>
            </tr>
        )
    }
}

ClusterSettingsRow.propTypes = {
    setting: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onFetchClusterSettings: PropTypes.func.isRequired,
    clusterId: PropTypes.string.isRequired,
}

export default ClusterSettingsRow
