import React, {Component} from 'react'

class TableHeader extends Component {
    render() {
        return (
            <thead className="TableHeader thead-light">
                <tr>
                    <th className="table-col-5 text-center" scope="col">#</th>
                    <th className="text-center" scope="col">Thumbnail</th>
                    <th scope="col">Product Title</th>
                    <th scope="col">Categories</th>
                    <th scope="col">Status</th>
                </tr>
            </thead>
        )
    }
}

export default TableHeader

