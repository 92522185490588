import React, {useEffect, useState} from 'react'
import {RenderHead, RenderRow} from './FormTable'
import PropTypes from 'prop-types'
import PFPagePagination from '../../shared/PFPagePagination'
import {DEFAULT_DISABLE_FIELDS} from '../statics/amazon'

const FormDetailItem = (props) => {
    const {items = [], updateItem, isUploadTemp, setUploadTemp} = props
    const PAGE_SIZE = 20
    const [page, setPage] = useState(1)
    const [showItems, setShowItems] = useState([])
    const _filterData = (items) => items.filter(i => !DEFAULT_DISABLE_FIELDS.includes(i.field_name))
    const totalPage = _filterData(items).length / PAGE_SIZE

    const _handlePageChange = (page) => {
        document.getElementById('template_item_header').scrollIntoView({behavior: 'smooth'})
        setPage(page)
    }

    useEffect(() => {
        setShowItems(_filterData(items))
        setPage(1)
    }, [items])

    return (
        <React.Fragment>
            <table className="FormDetailItem SectionInner w-100">
                <thead className="TableHeader">
                <RenderHead/>
                </thead>
                <tbody className="TableBody">
                {showItems && showItems.map((item, index) => {
                    if (index >= (page - 1) * PAGE_SIZE && index < page * PAGE_SIZE) {
                        return <RenderRow key={'FormDetailItem' + index} index={index}
                                          item={item}
                                          items={items}
                                          updateItem={updateItem}
                                          isUploadTemp={isUploadTemp} setUploadTemp={setUploadTemp}
                        />
                    } else return null
                })}
                </tbody>
            </table>
            {<PFPagePagination page={page} pages={totalPage} onChangePage={_handlePageChange}/>}
        </React.Fragment>
    )
}

FormDetailItem.propTypes = {
    items: PropTypes.array.isRequired,
    updateItem: PropTypes.func.isRequired,
    isUploadTemp: PropTypes.bool.isRequired,
    setUploadTemp: PropTypes.func.isRequired
}
export default FormDetailItem
