import classNames from 'classnames'
import DOMPurify from 'dompurify'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated/dist/react-select.esm'
import {
    deleteProductLine,
    deleteProductLineCategory,
    updateProductLine,
} from '../../../services/api/ProductLinesServices'
import getHistory from '../../../store/getHistory'
import AffiliateCategorySelect from '../../product-lines/components/AffiliateCategorySelect'
import CategoriesSelector from '../../product-lines/components/CategoriesSelector'
import storeModes from '../../product-lines/constants/storeModes'
import {confirmModal} from '../../shared/PFConfirmModalV2'
import {toaster} from '../../shared/PFToast'
import InputDescriptions from '../../shared/components/form/InputDescriptions'
import InputEditor from '../../shared/components/form/InputEditor'
import InputNumberFormat from '../../shared/components/form/InputNumberFormat'
import PFInputMulti from '../../shared/PFInputMulti'
import {Button} from 'reactstrap'
import ColorMappingModal from './ColorMappingModal'

const animatedComponents = makeAnimated()

class ProductEditor extends Component {
    constructor(props) {
        super(props)
        this.editorDescription = React.createRef()
        this.editorSizeChart = React.createRef()

        this.state = {
            saving: false,
            errorObj: '',
            store: this.props.store,
            isOpenColorMapping: false,
        }
    }

    _handleChangeCheckbox = (property) => (e) => {
        const value = e.target.checked
        this.props.onHandleChange(value, property)
    }

    _handleChangeSelect = (property) => (e) => {
        e.preventDefault()
        let {errorObj} = this.state
        if (errorObj[property]) {
            delete errorObj[property]
        }
        const value = e.target.value
        this.props.onHandleChangeInput(value, property)
    }

    _handleChangeInput = (value, name) => {
        let {errorObj} = this.state
        if (errorObj[name]) {
            delete errorObj[name]
        }
        this.props.onHandleChangeInput(value, name)
    }

    _deleteCategory = async () => {
        const {productLineId} = this.props
        return deleteProductLineCategory(productLineId)
    }

    _scrollErrorInput = () => {
        const scrollableDiv = document.getElementsByClassName('invalid-feedback')
        scrollableDiv[0].scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
    }

    _setEditorRefs = (key, editor) => {
        this[key] = editor
    }

    _validate = (e) => {
        e.preventDefault()
        const description = this.editorDescription.getContent()
        const {store} = this.state
        const {
            title = '',
            product_type = '',
            available_for_store_modes,
            currency,
            est_process_time_to,
            est_process_time_from,
            affiliateCategory,
            available_for_campaign,
        } = this.props
        const titleFormat = title.trim()
        const cleanDescription = DOMPurify.sanitize(description, {ALLOWED_TAGS: ['']})
        const desFormat = cleanDescription.replace(/&nbsp;/g, ' ').trim()
        const productTypeFormat = product_type.trim()

        let errorKey = {}

        if (store === 'marketplace') {
            if (!affiliateCategory || !Object.keys(affiliateCategory).length) {
                errorKey.affiliateCategory = 'Affiliate is required.'
            }
        }
        if (!titleFormat) {
            errorKey.title = 'Title is required.'
        }
        if (!est_process_time_to && est_process_time_to !== 0) {
            errorKey.est_process_time_to = 'Processing time to is required.'
        }
        if (est_process_time_to < est_process_time_from) {
            errorKey.est_process_time_to = 'Processing time from must be greater than process time to.'
        }
        if (!est_process_time_from && est_process_time_from !== 0) {
            errorKey.est_process_time_from = 'Processing time from is required.'
        }
        if (!desFormat) {
            errorKey.description = 'Description is required.'
        }
        if (!productTypeFormat && available_for_campaign) {
            errorKey.product_type = 'Product type is required.'
        }
        if (!currency) {
            errorKey.currency = 'Currency is required.'
        }
        if (!available_for_store_modes && Object.keys(available_for_store_modes).length === 0) {
            errorKey.available_for_store_modes = 'Store modes is required.'
        }
        this.setState({
            errorObj: errorKey,
        })
        if (Object.keys(errorKey).length > 0) return setTimeout(this._scrollErrorInput, 150)

        this._handleUpdateProduct()
    }

    _handleUpdateProduct = async () => {
        if (this.state.saving) return

        this.setState({
            saving: true,
        })

        const description = this.editorDescription.getContent()
        /*const size_chart = this.editorSizeChart.getContent()*/

        const {
            productLineId,
            title,
            product_type,
            categoryId,
            sku_prefix,
            est_process_time_from,
            is_popular,
            available_for_store_modes,
            currency,
            affiliateCategory,
            est_process_time_to,
            available_for_campaign,
            support_preview_mockup,
            ffm_mapping_product_types,
        } = this.props

        const {_id: idAffiliate = ''} = Object.assign({}, affiliateCategory)
        const {value = ''} = Object.assign({}, available_for_store_modes)

        const arrModes = Array.isArray(available_for_store_modes)
            ? available_for_store_modes.map((mode) => mode.value)
            : value.split()

        const product = {
            title: title,
            product_type: product_type,
            available_for_store_modes: arrModes,
            is_popular: is_popular,
            affiliate_category: idAffiliate,
            currency: currency,
            sku_prefix: sku_prefix,
            description: description,
            /*size_chart: size_chart,*/
            est_process_time_from: est_process_time_from,
            est_process_time_to: est_process_time_to,
            available_for_campaign,
            support_preview_mockup,
            ffm_mapping_product_types,
        }

        if (categoryId) Object.assign(product, {category: categoryId})

        this.props
            .onUpdate()
            .then(() => {
                return new Promise(async (resolve, reject) => {
                    try {
                        const {success, message} = await updateProductLine(productLineId, product)
                        resolve({success, message})
                    } catch (err) {
                        reject(err)
                    }
                })
            })
            .then((res) => {
                const {success, message} = res
                if (!success) {
                    throw new Error(message)
                }
                this.setState({
                    saving: false,
                })
                toaster({message: 'Saving product successfully!', type: 'success', duration: 5000})
                this.props.onFetchListProductLine()
            })
            .catch((err) => {
                if (err.type === 'variant-invalid') {
                    const element = document.getElementById('variants-section')
                    element.scrollIntoView({behavior: 'smooth', block: 'start'})
                    return
                }
                toaster({message: err.message, type: 'error', duration: 5000})
            })
            .finally(() => {
                this.setState({
                    saving: false,
                })
            })


    }

    _clickDeleteProduct = () => {
        const {title} = this.props
        confirmModal({
            confirmText: 'Delete product line',
            confirmColor: 'danger',
            title: 'Remove product line',
            message: (
                <span>
                    Are you sure want to delete <strong>{title}</strong>? This action cannot be reversed.
                </span>
            ),
            onOk: this._handleDeleteProduct,
        })
    }

    _handleDeleteProduct = async () => {
        const {productLineId} = this.props
        try {
            const {success, message} = await deleteProductLine(productLineId)

            if (!success) throw new Error(message)
            const history = getHistory()
            history.push('/a/product-lines')
        } catch (e) {
            toaster({message: e.message, type: 'error', duration: 5000})
        }
    }

    _handleSelectCategories = (options = []) => {
        this.props.onHandleSelectCategories(options)
    }

    _handleChangeStoreMode = (options = {}) => {
        let {errorObj} = this.state
        if (errorObj['available_for_store_modes']) {
            delete errorObj['available_for_store_modes']
        }
        this.setState({
            store: options.value || '',
        })
        this.props.onHandleChange(options, 'available_for_store_modes')
    }

    _handleSelectAffiliateCategory = (options = []) => {
        let affiliateCategory = options ? options[0] : undefined
        if (Object.keys(affiliateCategory).length) {
            affiliateCategory = Object.assign(
                {...affiliateCategory},
                {
                    ...affiliateCategory,
                    value: affiliateCategory._id,
                },
            )
        }

        let {errorObj} = this.state
        delete errorObj['affiliateCategory']
        this.setState({
            errorObj,
        })
        this.props.onHandleChange(affiliateCategory, 'affiliateCategory')
    }

    _handleChangeFfmProductType = (event) => {
        const {name, value} = event
        this.props.onHandleChange(value, name)
    }

    _toggleColorMapping = () => this.setState((prevState) => ({isOpenColorMapping: !prevState.isOpenColorMapping}))

    render() {
        const {
            product_type,
            title,
            sku_prefix,
            category,
            is_popular,
            description,
            /*size_chart, size_chart_image,*/
            est_process_time_from,
            productLineId,
            available_for_store_modes,
            available_for_campaign,
            currency,
            affiliateCategory,
            est_process_time_to,
            support_preview_mockup,
            ffm_mapping_product_types,
            colorAttribute,
        } = this.props
        const {saving, errorObj, store, isOpenColorMapping} = this.state

        return (
            <div className="ProductEditor">
                <div className="d-flex align-items-center">
                    <h1 className="Title mr-4 ">{title}</h1>
                    <div className="Tooltip3 Bottom">
                        <Link to={`/a/product-lines/create?ref=${productLineId}`} className="text-secondary fs-20">
                            <i className="far fa-clone" />
                        </Link>

                        <div className="TooltipContent">
                            <div className="ContentInner" style={{minWidth: 195}}>
                                Duplicate the product line
                            </div>
                        </div>
                    </div>
                </div>

                <div className="SectionInner">
                    <form>
                        <InputEditor
                            label="Title"
                            name="title"
                            value={title || ''}
                            error={errorObj.title}
                            onChange={this._handleChangeInput}
                        />

                        <div className="form-group">
                            <label className="font-weight-bold">Mode</label>
                            <Select
                                className={classNames({
                                    'border border-danger rounded': errorObj.available_for_store_modes,
                                })}
                                components={animatedComponents}
                                value={available_for_store_modes}
                                options={storeModes}
                                onChange={this._handleChangeStoreMode}
                            />
                            {errorObj.available_for_store_modes && (
                                <div className="invalid-feedback d-block">{errorObj.available_for_store_modes}</div>
                            )}
                        </div>

                        <div className="form-group row">
                            <div className="col-12 col-sm-6">
                                <InputEditor
                                    label="SKU prefix"
                                    name="sku_prefix"
                                    value={sku_prefix || ''}
                                    error={errorObj.sku_prefix}
                                    onChange={this._handleChangeInput}
                                />
                            </div>
                            <div className="col-12 col-sm-6">
                                <label className="font-weight-bold">Processing time (business day)</label>
                                <div className="ProcessingTime d-flex">
                                    <InputNumberFormat
                                        label="From"
                                        value={est_process_time_from}
                                        name="est_process_time_from"
                                        placeholder="BDs"
                                        error={errorObj.est_process_time_from}
                                        onChange={this._handleChangeInput}
                                    />
                                    <InputNumberFormat
                                        label="To"
                                        value={est_process_time_to}
                                        name="est_process_time_to"
                                        placeholder="BDs"
                                        error={errorObj.est_process_time_to}
                                        onChange={this._handleChangeInput}
                                    />
                                </div>
                            </div>
                        </div>

                        <InputEditor
                            label="Product Type"
                            value={product_type || ''}
                            name="product_type"
                            error={errorObj.product_type}
                            onChange={this._handleChangeInput}
                        />

                        <div className="form-group">
                            <div className="d-flex align-items-center justify-content-between">
                                <label htmlFor="FfmProductType" className="font-weight-bold">
                                    Fulfillment Product type
                                </label>
                                <span className="text-primary cursor-pointer" onClick={this._toggleColorMapping}>
                                    Color mapping
                                </span>
                            </div>
                            <PFInputMulti
                                errors={errorObj}
                                id="FfmProductType"
                                splitSpace={true}
                                name="ffm_mapping_product_types"
                                defaultValue={ffm_mapping_product_types}
                                onChange={this._handleChangeFfmProductType}
                                placeholder="Enter Fulfillment Product type and press enter..."
                            />
                        </div>
                        {isOpenColorMapping && (
                            <ColorMappingModal
                                colorAttribute={colorAttribute}
                                isOpen={isOpenColorMapping}
                                toggle={this._toggleColorMapping}
                                refetch={this.props.onFetchListProductLine}
                            />
                        )}

                        <div className="form-group">
                            <CategoriesSelector
                                multiSelect={false}
                                defaultSelectedOptions={{value: '', label: 'Un categorised'}}
                                defaultValue={
                                    category && {
                                        ...category,
                                        id: category._id,
                                        value: category.title,
                                        label: category.title,
                                    }
                                }
                                handleSelectCollection={this._handleSelectCategories}
                            />
                        </div>

                        <div className="form-group">
                            <label className="font-weight-bold">Currency</label>
                            <select
                                disabled
                                value={currency}
                                className={classNames('form-control', {
                                    'border border-danger rounded': errorObj.currency,
                                })}
                                onChange={this._handleChangeSelect('currency')}
                            >
                                <option value="" disabled>
                                    --Choose Type--
                                </option>
                                <option value="USD">USD</option>
                                <option value="VND">VND</option>
                            </select>
                            {errorObj.currency && <div className="invalid-feedback d-block">{errorObj.currency}</div>}
                        </div>

                        {store === 'marketplace' && (
                            <div className="form-group">
                                <AffiliateCategorySelect
                                    multiSelect={false}
                                    className={errorObj.affiliateCategory ? 'error' : ''}
                                    handleSelectCollection={this._handleSelectAffiliateCategory}
                                    defaultValue={affiliateCategory}
                                />
                                {errorObj.affiliateCategory && (
                                    <div className="invalid-feedback d-block">{errorObj.affiliateCategory}</div>
                                )}
                            </div>
                        )}

                        <div className="form-group d-flex align-items-center">
                            <div className="custom-control custom-checkbox mr-3">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="ProductLinePopular"
                                    checked={is_popular}
                                    onChange={this._handleChangeCheckbox('is_popular')}
                                />
                                <label className="custom-control-label" htmlFor="ProductLinePopular">
                                    Product line popular
                                </label>
                            </div>
                            <div className="custom-control custom-checkbox mr-3">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="ProductLineAvailableCampaign"
                                    checked={available_for_campaign}
                                    onChange={this._handleChangeCheckbox('available_for_campaign')}
                                />
                                <label className="custom-control-label" htmlFor="ProductLineAvailableCampaign">
                                    Available for campaign
                                </label>
                            </div>
                            <div className="custom-control custom-checkbox mr-3">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="ProductLineSupportPreviewMockup"
                                    checked={support_preview_mockup}
                                    onChange={this._handleChangeCheckbox('support_preview_mockup')}
                                />
                                <label className="custom-control-label" htmlFor="ProductLineSupportPreviewMockup">
                                    Support preview mockup
                                </label>
                            </div>
                        </div>

                        <InputDescriptions
                            setEditorRefs={this._setEditorRefs}
                            errorObj={errorObj.description}
                            description={description}
                        />

                        {/*<InputSizeChartImage size_chart_image={size_chart_image} errorObj={errorObj.size_chart_image} productLineId={productLineId} onChange={this._handleChangeInput}/>

                        <InputSizeChart setEditorRefs={this._setEditorRefs} errorObj={errorObj.size_chart} size_chart={size_chart}/>*/}

                        <div className="ProductAction d-flex justify-content-between">
                            <button type="button" className="btn btn-danger" onClick={this._clickDeleteProduct}>
                                Delete product line
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                disabled={saving}
                                onClick={this._validate}
                            >
                                {saving ? 'Saving...' : 'Save product line'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

ProductEditor.propTypes = {
    productLineId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    sku_prefix: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    product_type: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    is_popular: PropTypes.bool.isRequired,
    affiliateCategory: PropTypes.object.isRequired,
    available_for_campaign: PropTypes.bool.isRequired,
    support_preview_mockup: PropTypes.bool,
}

export default ProductEditor
