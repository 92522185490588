import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'


class TransactionsButton extends Component {
    render() {
        const {store} = this.props
        if (!store.hasOwnProperty('_id')) return null
        const {_id} = store

        return (
            <div className='TransactionsButton'>
                <Link
                    to={{
                        pathname: `/a/stores/${_id}/transactions`,
                        state: {store},
                    }}
                    className='ml-3'
                >
                    <button className='btn btn-secondary'>Transactions out</button>
                </Link>
            </div>
        )
    }
}

TransactionsButton.propTypes = {
    store: PropTypes.object.isRequired,
}

export default TransactionsButton
