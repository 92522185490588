import React, {Component} from 'react'
import PropTypes from 'prop-types'
import StoreDeployment from "./StoreDeployment"

class StoreDeploymentContainer extends Component {
    _getStoreId = () => {
        const {match} = this.props
        const {id} = Object.assign({}, match.params)

        return id
    }

    render() {
        const storeId = this._getStoreId()

        return (
            <div className="StoreDeploymentContainer">
                <StoreDeployment storeId={storeId}/>
            </div>
        )
    }
}

StoreDeploymentContainer.propTypes = {
    match: PropTypes.object.isRequired,
}

export default StoreDeploymentContainer
