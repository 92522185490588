import React from 'react'
import TikTokFeeTableRow from './TikTokFeeTableRow'
import {randomID} from '../../../../../helpers/common/randomID'

const TikTokFeeTable = (props) => {
    const {variants, attributes} = props

    if (!variants.length) return null

    return (
        <div className="BaseCostTable table-responsive">
            <table className="table">
                <thead className="thead-light">
                    <tr>
                        {!!attributes.length &&
                            attributes.map((attribute) => {
                                const _id = randomID()
                                return (
                                    <th key={_id} className="text-capitalize">
                                        {attribute.name}
                                    </th>
                                )
                            })}
                        <th>SKU</th>
                        <th>TikTok Shipping Warehouse</th>
                        <th>Seller Shipping Warehouse</th>
                    </tr>
                </thead>
                <TikTokFeeTableRow variants={variants} {...props} />
            </table>
        </div>
    )
}

export default TikTokFeeTable

