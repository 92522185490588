import React, {Component} from 'react'
import ServiceListPage from './ServiceListPage'
import PropsTypes from 'prop-types'

class ServiceListPageContainer extends Component {

    render() {
        const {storeDetails} = this.props

        return (
            <div className={`ServiceListPageContainer ${this.props.storeDetails.is_backup_and_drop_db ? 'disable' : ''}`}>
                <ServiceListPage storeDetails={storeDetails}/>
            </div>
        )
    }
}

ServiceListPageContainer.propTypes = {
    storeDetails: PropsTypes.object.isRequired
}

export default ServiceListPageContainer
