import React, {Component} from 'react'
import DesignFormatContext from '../context/DesignFormatContext'
import {Tooltip} from 'reactstrap'
import DesignFormatConst from '../DesignFormatConst'

class DesignFormatImage extends Component {
    state = {
        text: '',
        refsList: {
            dimensionRef: React.createRef()
        },
        disabled: true
    }

    componentDidMount() {
    }

    _onTooltipText = () => {
        this.setState({
            showText: !this.state.showText
        })
    }

    _onTooltipLabel = () => {
        this.setState({
            showLabel: !this.state.showLabel
        })
    }

    render() {
        const {showText, showLabel} = this.state
        const itemData = this.props.value
        if (!itemData.default_file_name && itemData.default_value && itemData.default_value.includes('/')) {
            itemData.default_file_name = itemData.default_value.split('/').reverse()[0]
        }
        return (
            <>
                <div className="mb-3 w-100 d-md-flex align-items-center">
                    <div className="panel-title position-relative">
                        <div>
                            <label className="m-0">Label</label>
                            <i className="ti-info-alt ml-2 cursor-pointer" id="TooltipImageText"/>
                            <Tooltip placement="top" isOpen={showText} target="TooltipImageText"
                                     toggle={this._onTooltipText}>
                                {DesignFormatConst.MESSAGES_LABEL}
                            </Tooltip>
                        </div>
                    </div>
                    <div className="panel-input">
                        <input
                            className={`form-control`}
                            placeholder="Your photo here"
                            defaultValue={itemData.name || ''}
                            disabled={this.state.disabled}
                            required
                        />
                    </div>
                </div>
                <div className="w-100 d-md-flex align-items-center">
                    <div className="panel-title position-relative">
                        <div>
                            <label className="m-0">Placeholder Image</label>
                            <i className="ti-info-alt ml-2 cursor-pointer" id="TooltipImageLabel"/>
                            <Tooltip placement="top" isOpen={showLabel} target="TooltipImageLabel"
                                     toggle={this._onTooltipLabel}>
                                {DesignFormatConst.MESSAGES_PLACEHOLDER_IMAGE}
                            </Tooltip>
                        </div>
                    </div>
                    <div className="panel-input">
                        <input
                            className={`form-control`}
                            defaultValue={itemData.default_file_name || 'No file chosen'}
                            disabled={this.state.disabled}
                        />
                    </div>
                </div>
                <div className="mb-3 w-100 d-md-flex align-items-center">
                    <div className="panel-title"></div>
                    <div className="panel-input">
                        <span
                            className="panel-extension">Allowed file extensions: {(itemData.allow_extensions || []).join(', ').toUpperCase()}</span>
                    </div>
                </div>
                <div className="mb-3 w-100 d-md-flex align-items-center">
                    <div className="panel-title">
                        <span>Max dimensions</span>
                    </div>
                    <div className="panel-input">
                        <div
                            className={`w-100 d-flex align-items-center justify-content-between`}>
                            <div>
                                <input
                                    className={`form-control`}
                                    ref={this.state.refsList.dimensionRef}
                                    defaultValue={itemData.dimension.width || ''}
                                    disabled={this.state.disabled}
                                />
                            </div>
                            <span className="px-3">X</span>
                            <div>
                                <input
                                    className={`form-control`}
                                    defaultValue={itemData.dimension.height || ''}
                                    disabled={this.state.disabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3 w-100 d-md-flex">
                    <div className="panel-title">
                        <span>Note for buyer</span>
                    </div>
                    <div className="panel-input">
                    <textarea
                        className="w-100 form-control input-area"
                        resize='none'
                        defaultValue={itemData.note || ''}
                        disabled={this.state.disabled}
                    />
                    </div>
                </div>
            </>
        )
    }
}

DesignFormatImage.contextType = DesignFormatContext

export default DesignFormatImage
