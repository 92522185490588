import React from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import NumberFormat from 'react-number-format'

const STATS = {
    refund: "Refunded",
    partial_refund: 'Partially refunded'
}

const TableRow = ({ orderRefund = [] }) => {
    const item = orderRefund.map(item => {

        let { total_amount_plf_refund = 0, total_profit_adjustment_amount = 0, created, mark_profit_done = false } = item
        total_amount_plf_refund = Math.abs(total_amount_plf_refund)
        let profitAdjustment
        if (mark_profit_done) {
            if (total_profit_adjustment_amount > 0) {
                profitAdjustment = <NumberFormat value={total_profit_adjustment_amount} className="foo" displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value, props) => <div {...props}>{value}</div>} />
            } else {
                profitAdjustment = '-'
            }
        } else {
            if (total_profit_adjustment_amount <= 0) {
                profitAdjustment = ''
            } else {
                profitAdjustment = <NumberFormat value={total_profit_adjustment_amount} className="foo" displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value, props) => <div {...props}>{value}</div>} />
            }
        }
        return <tr key={item._id}>
            <td>
                <Link to={`/a/order/refund-order/${item._id}`}>{item.order_number}</Link>
                <div> {created ? moment(created).format('MMMM DD HH:mm A') : ''}</div>
            </td>
            <td>
                {item.store_slug}
            </td>
            <td>
                <NumberFormat value={total_amount_plf_refund} className="foo" displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value, props) => <div {...props}>{value}</div>} />
            </td>
            <td>
                <NumberFormat value={item.total_refunded_fulfillment_amount} className="foo" displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={(value, props) => <div {...props}>{value}</div>} />
            </td>
            <td>
                <span className='text-danger'>
                    {profitAdjustment}
                </span>
            </td>
            <td>
                <span>{
                    _.get(STATS, item.status, '')
                }</span>
            </td>
        </tr>
    })
    return (
        <tbody className='TableLoading'>
            {item}
        </tbody>
    )
}

export default TableRow
