import React, {Component} from 'react'
import FirstItemPriceInput from "./FirstItemPriceInput"
import AdditionalItemPriceInput from "./AdditionalItemPriceInput"

// import PropTypes from 'prop-types'

class PerItemsRuleContainer extends Component {
    state = {
        hasRule: false,
    }

    _perItemData = []

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.perItemRule.length !== this.props.perItemRule.length) {
            this._perItemData = this.props.perItemRule

            if (this.props.perItemRule.length) {
                this.setState({
                    hasRule: true,
                })
            }
        }
    }

    _handleAddRule = () => {
        this._perItemData = [{first_item: "", additional_item: ""}]

        this.setState({
            hasRule: true,
        })
    }

    _updatePerItemData = (field, value) => {
        this._perItemData = [
            {
                ...this._perItemData[0],
                [field]: value,
            },
        ]

        this.props.onUpdateRateRules("item_based", this._perItemData)
    }

    _removePerItemData = () => {
        this._perItemData = []

        this.setState({
            hasRule: false,
        })

        this.props.onUpdateRateRules("item_based", this._perItemData)
    }

    render() {
        const {hasRule} = this.state

        return (
            <div className="PerItemsRuleContainer">
                <div className="SectionInner">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='col-7 p-0'>
                            <h6 className="mb-0 fs-18">Per Item / Additional Item</h6>
                            <div className="text-muted fs-14">Set a shipping price per item and each additional item.
                            </div>
                        </div>

                        <div className="Actions">
                            {
                                !hasRule ?
                                    <span className="btn btn-primary ml-3 cursor-pointer btn-sm"
                                          onClick={this._handleAddRule}>Add rule</span>
                                    :
                                    <span className="btn border cursor-pointer btn-sm" onClick={this._removePerItemData}>Remove rule</span>
                            }
                        </div>
                    </div>

                    {
                        this._perItemData.length > 0 &&
                        <div className="border-top mt-4 pt-4">
                            <table className="table table-borderless">
                                <thead className="thead-light">
                                <tr>
                                    <th className="table-col-50 font-weight-normal">First Item</th>
                                    <th className="table-col-50 font-weight-normal">Each Additional Item</th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <td>
                                        <FirstItemPriceInput firstItem={this._perItemData[0].first_item}
                                                             onUpdatePerItemData={this._updatePerItemData}/>
                                    </td>
                                    <td>
                                        <AdditionalItemPriceInput additionalItem={this._perItemData[0].additional_item}
                                                                  onUpdatePerItemData={this._updatePerItemData}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

// PerItemsRuleContainer.defaultProps = {}

// PerItemsRuleContainer.propTypes = {}

export default PerItemsRuleContainer
