import React, {Component} from 'react'
import PropTypes from 'prop-types'
import 'react-dates/lib/css/_datepicker.css'
import 'react-dates/initialize'
import {DateRangePicker} from 'react-dates'
import moment, {isMoment} from 'moment'
import classNames from 'classnames'

class CustomTimeRangeFilter extends Component {
    state = {
        focusedInput: null,
        from: null,
        to: null,
    }

    componentDidUpdate(prevProps) {
        const {type} = this.props
        const {type: prevType} = prevProps

        if (prevType && prevType === 'absolute' && prevType !== type) {
            this._reset()
        }
    }

    _reset = () => {
        this.setState({
            focusedInput: null,
            from: null,
            to: null,
        })
    }

    _handleToggle = e => {
        e.preventDefault()
        const type = 'absolute'
        const value = ''
        this.setState(
            ({focusedInput}) => ({
                focusedInput: focusedInput ? null : 'startDate',
            }),
            () => this.props.handleTimeRangeChange(type, value)
        )
    }

    _onFocusChange = focusedInput => {
        this.setState({
            focusedInput,
        })
    }

    _formatDate = date => (isMoment(date) ? moment(date).format('YYYY-MM-DDTHH:mm') : '')

    _handleTimeRangeChange = ({startDate, endDate}) => {
        const from = this._formatDate(startDate)
        const to = this._formatDate(endDate)
        const type = 'absolute'
        const value = `${from}|${to}`
        return this.setState(
            {
                from: startDate,
                to: endDate,
            },
            () => this.props.handleTimeRangeChange(type, value)
        )
    }

    _isOutsideRangeDate = date => {
        const tomorrow = moment().add(1, 'day')
        const startDate = moment('2018-11-05', 'YYYY-MM-DD')

        if (startDate.isAfter(date)) return true

        return !date.isBefore(tomorrow) || date.isSame(tomorrow, 'day')
    }

    _renderPreview = () => {
        const {from, to} = this.state
        if (!from && !to) return null

        const fromText = from ? from.format('DD/MM/YYYY') : ''
        const toText = to ? to.format('DD/MM/YYYY') : ''
        let text = `: ${fromText} - ${toText}`

        if (fromText === toText) {
            text = `: ${fromText}`
        }

        return <span className='Preview'>{text}</span>
    }

    _isActive = () => {
        const {from, to} = this.state
        const {type} = this.props
        if (type !== 'absolute') return false

        return from || to
    }

    render() {
        const {focusedInput, from, to} = this.state
        const isOpenEditor = !!focusedInput
        const Preview = this._renderPreview()
        const isActive = this._isActive()

        return (
            <li
                onClick={this._handleToggle}
                className={classNames('CustomTimeRangeFilter', {
                    isOpenEditor,
                    active: isActive,
                })}>
                <div className='CustomFilterInner d-flex'>
                    <div className='nav-link'>
                        <span className='CustomLabel'>Custom</span>
                        {Preview}
                    </div>

                    <DateRangePicker
                        small
                        noBorder
                        showClearDates
                        enableOutsideDays
                        reopenPickerOnClearDates
                        minimumNights={0}
                        numberOfMonths={1}
                        isOutsideRange={this._isOutsideRangeDate}
                        startDate={from}
                        startDateId='_statistic_filter_from'
                        endDate={to}
                        endDateId='_statistic_filter_to'
                        onDatesChange={this._handleTimeRangeChange}
                        focusedInput={focusedInput}
                        onFocusChange={this._onFocusChange}
                    />
                </div>
            </li>
        )
    }
}

CustomTimeRangeFilter.propTypes = {
    type: PropTypes.string.isRequired,
    handleTimeRangeChange: PropTypes.func.isRequired,
}

export default CustomTimeRangeFilter
