import React, {useEffect} from 'react'
import TableLoading from '../../shared/TableLoading'
import humanizeTime from '../../../helpers/time/humanizeTime'
import {Link} from 'react-router-dom'
import {confirmModal} from '../../shared/PFConfirmModalV2'
import {toaster} from '../../shared/PFToast'
import {deleteDelivery} from '../../../services/api/SellerServices'
import {states} from '../../../statics/states'
import {SCOPE, SHIPPING_PLANS} from '../constants'

const SettingDeliveryTable = (props) => {
    const {loading, items, refetch} = props

    const _handleDeleteDelivery = async (id) => {
        try {
            const {success, message} = await deleteDelivery(id)
            if (!success) throw new Error(message)
            toaster({
                type: 'success',
                message: 'Delete delivery profile successfully',
            })
            refetch()
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    const _confirmDelete = (id) => {
        confirmModal({
            confirmText: 'Confirm',
            confirmColor: 'danger',
            title: 'Delete delivery profile',
            message: <span>Are you sure to delete this profile? All orders applied to this profile will be updated to default setting of max delivery 13 BDs once it's deleted</span>,
            onOk: () => _handleDeleteDelivery(id)
        })
    }

    const _mapShippingPlan = (planId) => {
        const plan = SHIPPING_PLANS.find((p) => p.value === planId)
        return plan ? plan.name : '-'
    }

    const _mapScope = (scopeId) => {
        const plan = SCOPE.find((p) => p.value === scopeId)
        return plan ? plan.name : '-'
    }

    useEffect(() => {
        const el = document.getElementById('SettingMenuTitle')
        if (el) el.style.borderBottom = 'none'

        return () => {
            el.style.borderBottom = '1px solid #dfe3e8'
        }
    }, [])

    return (
        <div className="SettingDeliveryTable table-responsive mt-3">
            <table className="table table-striped ProfilesTable">
                <thead className="thead-light">
                    <tr>
                        <th>Fulfillment Location</th>
                        <th>Destination</th>
                        <th>Scope</th>
                        <th>Shipping Plan</th>
                        <th>Min Delivery</th>
                        <th>Max Delivery</th>
                        <th>Created</th>
                        <th className="text-center">Actions</th>
                    </tr>
                </thead>
                {loading ? (
                    <TableLoading columnQuantity={8} />
                ) : (
                    <tbody>
                        {items.length > 0 ? (
                            items.map((item, index) => {
                                const {
                                    _id,
                                    ffm_location,
                                    destination,
                                    scope,
                                    shipping_plan,
                                    min_delivery,
                                    max_delivery,
                                    created,
                                } = item
                                return (
                                    <tr key={index}>
                                        <td className="text-uppercase">{ffm_location}</td>
                                        <td className="text-uppercase">{destination}</td>
                                        <td className="DeliveryState">{_mapScope(scope)}</td>
                                        <td className="text-capitalize">{_mapShippingPlan(shipping_plan)}</td>
                                        <td>{min_delivery || '-'}</td>
                                        <td>{max_delivery || '-'}</td>
                                        <td>{humanizeTime(created)}</td>
                                        <td className="SettingDeliveryTableActions text-center">
                                            <Link
                                                className="text-primary mr-3"
                                                to={`/a/settings/delivery-profile?id=${_id}`}
                                            >
                                                <i className="fa fa-edit" />
                                            </Link>
                                            <i
                                                className="fa fa-trash text-danger cursor-pointer"
                                                onClick={() => _confirmDelete(_id)}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        ) : (
                            <tr className="text-center">
                                <td colSpan="7">
                                    <span className="text-mute">No results</span>
                                </td>
                            </tr>
                        )}
                    </tbody>
                )}
            </table>
        </div>
    )
}

export default SettingDeliveryTable

