import React, { Component } from 'react'
import PropTypes from 'prop-types'
import humanizeTime from "../../../../helpers/time/humanizeTime"
import { deleteClusterEnv } from "../../../../services/api/ClustersServices"

class EnvItem extends Component {
    state = {
        deleting: false,
    }

    _handleClickDelete = async e => {
        e.preventDefault()

        if (this.state.deleting) return true

        const r = window.confirm('Are you sure want to delete this env?')
        if (!r) return false

        this.setState({
            deleting: true
        })

        const { clusterId, env } = this.props
        const { key } = env

        try {
            const { success, message } = await deleteClusterEnv(clusterId, key)

            if (!success) {
                throw new Error(message)
            }

            return this.setState({
                deleting: false
            }, () => {
                this.props.onRefresh()
            })
        } catch (e) {
            this.setState({
                deleting: false,
            })

            window.alert(e.message)
        }
    }

    render() {
        const { index, env } = this.props
        const { _id, key, value, created } = env
        const { deleting } = this.state

        return (
            <tr className="EnvItem" id={`Env-${_id}`}>
                <td>{index + 1}</td>
                <td>{key}</td>
                <td>{value}</td>
                <td className='col-2'>{humanizeTime(created)}</td>
                <td>
                    <div className="Actions">
                        <button disabled={deleting}
                            onClick={this._handleClickDelete}
                            className="Delete btn btn-link">
                            <i className="fas fa-times text-danger"></i>
                        </button>
                    </div>
                </td>
            </tr>
        )
    }
}

EnvItem.propTypes = {
    clusterId: PropTypes.string.isRequired,
    env: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    onRefresh: PropTypes.func.isRequired,
}

export default EnvItem
