import React from 'react'

class IndexColumn extends React.Component {
    render() {
        const {index} = this.props
        return (
            <td>
                <span>{index || ''}</span>
            </td>
        )
    }
}

export default IndexColumn
