import React, {Component} from 'react'
import PropTypes from 'prop-types'
import JSONEditorShared from '../../../shared/JSONEditorShared'

class ResourceCreator extends Component {

    _onChangeMetadata = (value) => {
        this.props.onChangeResource("metadata", JSON.parse(value))
    }

    _onChangeSpecs = (value) => {
        this.props.onChangeResource("specs", JSON.parse(value))
    }

    _onChangeInput = (key) => (e) => {
        const {value} = e.target
        this.props.onChangeResource(key, value)
    }

    render() {
        const {resource, kinds = []} = this.props
        return (
            <div className="PlanDetails">

                <div className="row">
                    <div className="col-6">
                        <label htmlFor="plan-title">Name</label>
                        <input id="plan-title" className="form-control" value={resource.name || ''}
                               onChange={this._onChangeInput('name')}/>
                    </div>
                    <div className="col-6">
                        <label >Kind</label>
                        <select disabled={resource._id ? true: false} value={resource.kind} id="resource-kind" className="custom-select"
                                onChange={this._onChangeInput('kind')}>
                            {kinds.map((item) => <option key={item} value={item}>{item}</option>)}
                        </select>
                        <br/>
                    </div>

                    <div className="col-4">
                        <label htmlFor="plan-title">Capacity</label>
                        <input id="plan-title" className="form-control" value={resource.capacity || 0}
                               onChange={this._onChangeInput('capacity')}/>
                        <br/>
                    </div>
                    <div className="col-4">
                        <label >Status</label>
                        <select value={resource.status || 'active'} id="resource-status" className="custom-select"
                                onChange={this._onChangeInput('status')}>
                            {['active', 'inactive'].map((item) => <option key={item} value={item}>{item}</option>)}
                        </select>

                    </div>
                    <div className="col-12">
                        <label htmlFor="plan-title">Metadata</label>
                        <JSONEditorShared json={resource.metadata || {}} onChangeText={this._onChangeMetadata}/>
                        <br/>
                    </div>
                    <div className="col-12">
                        <label htmlFor="plan-title">Specs</label>
                        <JSONEditorShared json={resource.specs || {}} onChangeText={this._onChangeSpecs}/>
                    </div>
                </div>
            </div>
        )
    }
}

ResourceCreator.propTypes = {
    kinds: PropTypes.array.isRequired,
    resource: PropTypes.object.isRequired,
    onChangeResource: PropTypes.func.isRequired,
}

export default ResourceCreator
