import React, {Component} from 'react'
import ArtworksListBySide from './ArtworksListBySide'
import PropTypes from 'prop-types'
import {getArtWorks} from '../../../../../services/api/ProductApprovalServices'

class ArtworksListContainer extends Component {
    state = {
        artworks: [],
        error: '',
        loading: true
    }

    componentDidMount() {
        this._getArtworks()
    }

    _getArtworks = async () => {
        this.setState({
            loading: true
        })
        const {revisionId, productId} = this.props
        try {
            const {data, message, success} = await getArtWorks(productId, revisionId)
            if (!success) throw new Error(message)

            const artworksBySide = this._groupArtworksBySide(data.artworks)

            this.setState({
                error: '',
                artworks: artworksBySide,
                loading: false
            })

        } catch (e) {
            this.setState({
                error: e.message,
                loading: false
            })

        }
    }

    _groupArtworksBySide = (artworks) => {
        return artworks.reduce((acc, artwork) => {
            if (!acc[artwork.side]) {
                acc[artwork.side] = []
            }

            acc[artwork.side].push(artwork)

            return acc
        }, {})
    }

    render() {
        const {artworks, error} = this.state
        const {sides, slug, artwork_separated_by} = this.props

        return (
            <div className="ArtworksListContainer">
                {
                    error && <div className="text-danger">{error}</div>
                }

                <div className="row">
                    {
                        sides.map((side) => {
                            return (
                                <div key={`groupArtworksBy_${side}`}
                                     className={`GroupArtworksPosition col-12 col-md-3 mb-3 mb-md-0 ${side}`}>
                                    {
                                        Object(artworks).hasOwnProperty(side) &&
                                        <ArtworksListBySide slug={slug} artwork_separated_by={artwork_separated_by}
                                                            groupArtworks={artworks[side]}/>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

ArtworksListContainer.propTypes = {
    sides: PropTypes.array.isRequired,
    productId: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    artwork_separated_by: PropTypes.string.isRequired
}

export default ArtworksListContainer
