import React, {Component} from 'react'

class ClusterSettingsHead extends Component {
    render() {
        return (
            <thead className="ClusterSettingsHead thead-light">
                <tr>
                    <th>#</th>
                    <th>Key</th>
                    <th>Value</th>
                    <th className="text-center">Actions</th>
                </tr>
            </thead>
        )
    }
}

export default ClusterSettingsHead
