import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {saveCollectionByName, searchCollectionsByName} from '../../../../services/api/ProductServices'

class CollectionsEdit extends Component {
    state = {
        value: '',
        suggestion: {
            open: false,
            entity: [],
            loading: false,
            selected: null,
        },
        loading: false
    }

    timer = null

    _changeInput = (e) => {
        this.setState({
            value: e.target.value
        })

        if (this.timer) clearTimeout(this.timer)
        this.timer = setTimeout(this._searchCollections, 350)
    }

    _handlePressKey = (e) => {
        if (e.key === 'Enter' || e.key === 'ArrowDown' || e.key === 'ArrowUp') {
            e.preventDefault()
            if (e.key !== 'Enter') {
                this._switchSelection(e.key === 'ArrowDown')
            } else {
                this._submitEnterCollection()
            }
        }
    }

    _switchSelection = (direction) => {
        this.setState(state => ({
            suggestion: {
                ...state.suggestion,
                selected: (direction) ? this._validated(state.suggestion.selected + 1) : this._validated(state.suggestion.selected - 1),
            },
        }))
    }

    _validated = (value) => {
        const {entity} = this.state.suggestion
        if (!entity || !entity.length) return null

        if (value < 0) return entity.length - 1
        if (value >= entity.length) return 0
        return value
    }

    _toggleSuggestion = (action) => () => {
        this.setState(state => ({
            suggestion: {
                ...state.suggestion,
                open: (action) ? (action === 'open') : !state.suggestion.open,
            },
        }), () => {
            const {suggestion} = this.state

            if (suggestion.open && !suggestion.entity.length) {
                this._searchCollections()
            }
        })
    }

    _searchCollections = async () => {
        this.setState(state => ({
            ...state,
            suggestion: {
                ...state.suggestion,
                loading: true,
            },
            loading: true
        }))

        const {value} = this.state
        const {data, success} = await searchCollectionsByName(value)

        const {collections: selectedCollections} = this.props

        const collectionValidation = data.map(collection => {
            return {
                ...collection,
                is_selected: selectedCollections.some(selected => selected._id === collection._id),
            }
        })

        if (success) {
            this.setState(state => ({
                ...state,
                suggestion: {
                    ...state.suggestion,
                    loading: false,
                    entity: collectionValidation,
                    selected: -1,
                },
                loading: false
            }))
        } else {
            this.setState({
                suggestion: {
                    entity: [],
                    loading: false,
                    selected: null,
                },
                loading: false
            })
        }
    }

    _submitEnterCollection = () => {
        const {value, suggestion} = this.state

        if (suggestion.selected === null || suggestion.selected < 0) {
            this._addNewCollection(value)
        } else {
            this._addCollection(suggestion.entity[suggestion.selected])
        }
    }

    _addNewCollection = async (name) => {
        const {data, success} = await saveCollectionByName(name)

        if (success) {
            this._addCollection(data)
        }
    }

    _clickAddCollection = (collection) => () => {
        this._addCollection(collection)
    }

    _removeCollection = (collection) => () => {
        const {collections} = this.props
        const newCollection = collections.filter(item => item._id !== collection._id)
        this.props.onChange(newCollection)
    }

    _addCollection = (collection) => {
        const {collections} = this.props
        if (collections.find(item => item._id === collection._id)) return
        this.setState({
            value: '',
            suggestion: {
                open: false,
                entity: [],
                loading: false,
                selected: null,
            },
        })
        this.props.onChange([...collections, collection])
    }

    renderCollections = () => {
        const {suggestion} = this.state

        return suggestion.entity.map((collection, i) => {
            const isAutomated = collection.type === 'automated'

            return (
                <li
                    key={i}
                    className={classnames('Suggestion', {
                        'Disabled': isAutomated,
                        'Selected': collection.is_selected,
                    })}
                    onClick={isAutomated ? null : this._clickAddCollection(collection)}
                >
                    {isAutomated ? <div className="d-flex justify-content-between align-items-center">
                        <div className="text-muted">{collection.name}</div>
                        <small className="text-muted font-italic">Automated</small>
                    </div> : collection.name}
                </li>
            )
        })
    }

    render() {
        const {value, suggestion} = this.state
        const {loading} = suggestion
        const {collections, editable} = this.props

        return <div className="CollectionsEdit">
            <form
                className="form-group Form"
                onSubmit={this.submit}
            >
                <label htmlFor="collection_edit">Collections:</label>
                <div className="d-flex position-relative align-items-center">
                    <input
                        className="form-control"
                        id="collection_edit"
                        value={value}
                        onFocus={this._toggleSuggestion('open')}
                        onChange={this._changeInput}
                        onKeyDown={this._handlePressKey}
                        autoComplete="off"
                        disabled={!editable}
                    />
                </div>
            </form>

            {suggestion.open ? <div>
                <div className="CollectionSuggestion">
                    <div className="card shadow Card">
                        <div className="card-body Wrapper">
                            {
                                loading ? (
                                    <ul className="List">
                                        <li className="Suggestion">Loading...</li>
                                    </ul>
                                ) : (
                                    <ul className="List">
                                        {(suggestion.entity.length) ? this.renderCollections() : <li className="Suggestion">
                                            No collections available
                                        </li>}
                                    </ul>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="Background" onClick={this._toggleSuggestion('close')}/>
            </div> : null}

            <ul className="Collections">
                {collections.map((collection, index) => <li
                    key={index}
                    className="Collection"
                >
                    <span>{collection.name}</span>
                    {
                        editable &&
                        <span className="CloseButton" onClick={this._removeCollection(collection)}>
                            <i className="fas fa-times"/>
                        </span>
                    }
                </li>)}
            </ul>
        </div>
    }
}

CollectionsEdit.propTypes = {
    collections: PropTypes.array.isRequired,
    editable: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default CollectionsEdit
