import React from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import VariantDimension from '../design/VariantDimension'

const VariantDimensionModal = (props) => {
    const {isOpen, dimensions, variantTitle, toggle} = props

    return (
        <Modal isOpen={isOpen} toggle={toggle} size="lg" centered backdrop="static">
            <ModalHeader toggle={toggle}>{variantTitle} Dimension</ModalHeader>
            <ModalBody>
                {dimensions.length > 0 ? (
                    <VariantDimension dimensions={dimensions} />
                ) : (
                    <div className="text-center text-muted">No data dimensions!</div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggle}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default VariantDimensionModal

