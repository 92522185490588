import React, {Component} from 'react'
import RateEditPage from './RateEditPage'
import {isAdmin, isSuperAdmin} from '../../../../services/AuthServices'

// import PropTypes from 'prop-types'

class RateEditPageContainer extends Component {
    render() {
        const rateId = this.props.match.params.rateId
        const zoneId = this.props.match.params.zoneId
        const profilesId = this.props.match.params.profilesId

        const isSupperAdmin = isSuperAdmin()
        const roleAdmin = isAdmin()

        if (isSupperAdmin || roleAdmin) return (
            <div className="RateEditPageContainer mt-4">
                <div className="row">
                    <div className="col-md-8 col-12 m-auto">
                        <RateEditPage profilesId={profilesId} rateId={rateId} zoneId={zoneId}/>
                    </div>
                </div>
            </div>
        )

        return null
    }
}

// RateEditPageContainer.defaultProps = {}

// RateEditPageContainer.propTypes = {}

export default RateEditPageContainer
