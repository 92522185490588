import React, {Component} from 'react'
import PropTypes from 'prop-types'

class StoreTagItem extends Component {
    _handleClickRemove = e => {
        const {tag, _handleRemoveStoreTag} = this.props
        _handleRemoveStoreTag(tag)
    }

    render() {
        const {tag} = this.props

        return (
            <li className="StoreTagItem d-inline-block mr-2 mb-2">
                <span className="btn btn-secondary px-2 py-1">{tag} {!["pre-suspend"].includes(tag) && <i className="ml-2 fas fa-times cursor-pointer"
                                                                       onClick={this._handleClickRemove}/>}</span>
            </li>
        )
    }
}

StoreTagItem.propTypes = {
    tag: PropTypes.string,
    _handleRemoveStoreTag: PropTypes.func.isRequired,
}

export default StoreTagItem
