import React, {Component} from 'react'
import PropTypes from 'prop-types'
import RenderTableRow from './RenderTableRow'

class TableBody extends Component {
    render() {
        const {items, page, limit, loading} = this.props

        return (
            <tbody className='TableBody'>
                {!loading &&
                    items.map((item, index) => (
                        <RenderTableRow item={item} key={item._id} page={page} limit={limit} index={index} />
                    ))}
            </tbody>
        )
    }
}

TableBody.propTypes = {
    items: PropTypes.array.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default TableBody
