import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class ServiceStatus extends Component {
    render() {
        const {services} = this.props

        return (
            <div className="ServiceStatus">
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <td>#</td>
                        <td>Name</td>
                        <td>Ready replicas</td>
                        <td>Replicas</td>
                        <td>Status</td>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        services.map((service, index) => {
                            const {name, isReady, readyReplicas, replicas} = service

                            return (
                                <tr key={name}>
                                    <td>{index + 1}</td>
                                    <td>{name}</td>
                                    <td>{readyReplicas || 0}</td>
                                    <td>{replicas || 0}</td>
                                    <td className={classNames({isReady})}>{isReady ? 'Ready' : 'Unready'}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

ServiceStatus.propTypes = {
    services: PropTypes.array.isRequired,
}

export default ServiceStatus
