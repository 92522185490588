import React from 'react'
import SettingGeneralPage from './SettingGeneralPage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import {isSuperAdmin} from '../../../services/AuthServices'

const SettingGeneralContainer = () => {

    // const checkRole = isSuperAdmin()
    //
    // if (!checkRole) return null

    return (
        <div className="SettingsContainer">
            <DocTitleByStore title="General settings" />
            <SettingGeneralPage />
        </div>
    )
}

export default SettingGeneralContainer

