import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import ResourceCreator from './ResourceCreator'
import {editResource, createNewResource} from '../../../../services/api/ResourceService'
import {getListCreatableResources} from '../../../../services/api/ResourceService'

class ResourceModal extends Component {
    state = {
        resource: {
            name: '',
            kind: '',
            status: 'active',
            capacity: 0,
            metadata: {},
            specs: {},
        },
        kinds: [],
        loading: false
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.open && this.props.open) this.setState({
            resource: {
                name: '',
                kind: 'mongodb',
                status: 'active',
                capacity: 0,
                metadata: {},
                specs: {},
                ...this.props.resource
            },
        }, () => this._getListCreableResource())
    }

    _getListCreableResource = async () => {
        try {
            let response = await getListCreatableResources()
            const {success, data, message} = response

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false
                })
            }

            this.setState({
                kinds: data || [],
                loading: false,
            })

        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false
            })
        }
    }

    _onSave = () => {
        const { resource } = this.state
        if (!resource.name || !resource.kind || !resource.metadata || !resource.specs || !resource.status){
            alert('Missing params.')
            return null
        }
        const validCapacity = parseInt(resource.capacity || 0)

        this.setState(({resource}) => ({
            resource: {...resource, capacity: validCapacity}
        }), () => this._saveResource())
    }

    _saveResource = async () => {
        const {resource} = this.state
        const {_id, ...resourceDetails} = resource
        this.setState({
            loading: true,
        })

        try {
            const work = (_id) ? editResource(_id, resourceDetails) : createNewResource({...resource, used: 0})
            const {success, data, message} = await work
            this.setState({
                loading: false,
            })

            if (success) {
                if (!_id) return this.props.onAddResource(data)
                return this.props.onChangeResource()
            }

            alert(message)
        } catch (e) {
            this.setState({
                loading: false,
            })
            alert(e.message || e)
        }
    }

    _changeResource = (key, value) => {
        this.setState(({resource}) => ({
            resource: {...resource, [key]: value}
        }))
    }

    _onToggle = () => {
        this.props.onClose()
    }


    render() {
        const {open} = this.props
        const {resource, loading, kinds} = this.state

        return (
            <Modal size='lg' isOpen={open} toggle={this._onToggle} className="PlanModal">
                <ModalHeader toggle={this._onToggle}>Resource</ModalHeader>
                <ModalBody>
                    <div className="PlanModal">
                        <ResourceCreator kinds={kinds} resource={resource} onChangeResource={this._changeResource}/>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-outline-primary" onClick={this._onToggle}>Cancel</button>
                    <button className="btn btn-primary" disabled={loading} onClick={this._onSave}>Save</button>
                </ModalFooter>
            </Modal>
        )
    }
}

ResourceModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onAddResource: PropTypes.func.isRequired,
    onChangeResource: PropTypes.func.isRequired,
}

export default ResourceModal
