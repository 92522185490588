import React, {Component} from 'react'
import ZoneRatesList from "./ZoneRatesList"
import {Link} from "react-router-dom"
import PropTypes from 'prop-types'

class ZoneRatesContainer extends Component {
    render() {
        const {rates} = this.props

        return (
            <div className="ZoneRatesContainer">
                <div className="SectionInner">
                    <div className="d-flex justify-content-between align-items-center pb-3 border-bottom">
                        <div>
                            <h6 className="mb-0">Rates</h6>
                            <div className="fs-14 text-muted">Add or remove rates in this zone.</div>
                        </div>

                        {
                            rates.length < 1
                            &&
                            <Link to={`/a/settings/shipping-zones/${this.props.profilesId}/rates/${this.props.zoneId}/new`}
                                  className="btn btn-primary btn-sm">Add shipping rate</Link>
                        }

                    </div>

                    <ZoneRatesList {...this.props}/>
                </div>
            </div>
        )
    }
}

// ZoneRatesContainer.defaultProps = {}

ZoneRatesContainer.propTypes = {
    rates: PropTypes.array.isRequired
}

export default ZoneRatesContainer
