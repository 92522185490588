import React, {Component} from 'react'
import PropTypes from 'prop-types'

class DnsRecordContent extends Component {
    render() {
        const {content} = this.props.record
        return (
            <td className="DnsRecordContent">
                {content}
            </td>
        )
    }
}

DnsRecordContent.propTypes = {
    record: PropTypes.object.isRequired,
}

export default DnsRecordContent
