import moment from 'moment'

export const MAX_STORE_QUERY = 20

export const PLATFORM_OPTIONS = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'TikTok',
        value: 'tiktok',
    },
]

export const DEFAULT_FILTER = {
    page: 1,
    limit: 50,
    source: PLATFORM_OPTIONS.find((item) => item.value === 'all').value,
    packaged_at: {
        from: moment().subtract(60, 'days').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
    },
    stores: [],
    deduplicate: false
}

export const DESTINATION = {
    VN_US: 'vn-us',
    VN_EU: 'vn-eu',
    VN_ROW: 'vn-row',
    US_US: 'us-us',
    US_ROW: 'us-row',
    EU_EU: 'eu-eu',
    EU_ROW: 'eu-row',
    AU_AU: 'au-au',
    CN: 'cn-ww',
}

export const DESTINATION_TITLE = {
    'vn-us': 'from VN to US',
    'vn-eu': 'from VN to EU',
    'vn-row': 'from VN to ROW',
    'us-us': 'from US to US',
    'us-row': 'from US to ROW',
    'eu-eu': 'from EU to EU',
    'eu-row': 'from EU to ROW',
    'au-au': 'from AU to AU',
    'cn-ww': 'from CN',
}

export const SHIPMENT_STAGE = {
    PRE_TRANSIT_5BDS: 'pre_transit_5bds',
    PRE_TRANSIT: 'pre_transit',
    IN_TRANSIT: 'in_transit',
    NOT_DELIVERED: 'not_delivered',
    DELAY_DELIVERED: 'delay_delivered',
    DELAY_DELIVERED_5BDS: 'delay_delivered_5bds',
    DELAY_DELIVERED_8BDS: 'delay_delivered_8bds',
    DELAY_DELIVERED_10BDS: 'delay_delivered_10bds',
    DELAY_DELIVERED_13BDS: 'delay_delivered_13bds',
    DELAY_DELIVERED_16BDS: 'delay_delivered_16bds',
    AT_POST_OFFICE: 'at_post_office',
}

export const WARNING_PERCENTAGE = 1

export const DEFAULT_RULE_HEADER = [
    {
        from: 'vn',
        to: 'us',
        columns: [
            {
                key: 'pre_transit_5bds',
                value: 5,
            },
            {
                key: 'pre_transit',
                value: 7,
            },
            {
                key: 'in_transit',
                value: 4,
            },
            {
                key: 'not_delivered',
                value: 11,
            },
            {
                key: 'delay_delivered_13bds',
                value: 26,
            },
            {
                key: 'delay_delivered_16bds',
                value: 28,
            },
            {
                key: 'at_post_office',
            },
        ],
    },
    {
        from: 'vn',
        to: 'eu',
        columns: [
            {
                key: 'pre_transit',
                value: 10,
            },
            {
                key: 'in_transit',
                value: 5,
            },
            {
                key: 'delay_delivered',
                value: 21,
            },
        ],
    },
    {
        from: 'vn',
        to: 'row',
        columns: [
            {
                key: 'pre_transit',
                value: 6,
            },
            {
                key: 'in_transit',
                value: 15,
            },
            {
                key: 'delay_delivered',
                value: 21,
            },
        ],
    },
    {
        from: 'us',
        to: 'us',
        columns: [
            {
                key: 'pre_transit_not_update_3ds',
                value: 3,
            },
            {
                key: 'in_transit',
                value: 4,
            },
            {
                key: 'delay_delivered_5bds',
                value: 5,
            },
            {
                key: 'delay_delivered_8bds',
                value: 8,
            },
            {
                key: 'delay_delivered_10bds',
                value: 10,
            },
            {
                key: 'at_post_office',
            },
        ],
    },
    {
        from: 'us',
        to: 'row',
        columns: [
            {
                key: 'delay_delivered',
                value: 20,
            },
        ],
    },
    {
        from: 'eu',
        to: 'eu',
        columns: [
            {
                key: 'in_transit',
                value: 3,
            },
            {
                key: 'delay_delivered',
                value: 8,
            },
        ],
    },
    {
        from: 'eu',
        to: 'row',
        columns: [
            {
                key: 'delay_delivered',
                value: 20,
            },
        ],
    },
    {
        from: 'au',
        to: 'au',
        columns: [
            {
                key: 'in_transit',
                value: 3,
            },
            {
                key: 'delay_delivered',
                value: 8,
            },
        ],
    },
    {
        from: 'cn',
        to: 'ww',
        columns: [
            {
                key: 'delay_delivered',
                value: 10,
            },
        ],
    },
]

