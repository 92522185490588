import React,{Component} from 'react'
import EditProductLine from './EditProductLine'
import PropTypes from 'prop-types'

class EditProductLineContainer extends Component{

    render(){
        const {match} = this.props
        const productLineId = match.params.id
        return(
            <div className="EditProductLineContainer">
                <EditProductLine productLineId={productLineId}/>
            </div>
        )
    }
}

EditProductLineContainer.propTypes = {
    match: PropTypes.object.isRequired,
}
export default EditProductLineContainer
