import React from 'react'
import PropTypes from 'prop-types'
import {bulkUnLinkStoreGroup} from '../../../../../services/api/ClustersServices'
import {toaster} from '../../../../shared/PFToast'

const ActionsColumn = (props) => {
    const {item, groupId, getListStores} = props
    const {status} = item

    const unLinkStore = async () => {
        try {
            const {success, message} = await bulkUnLinkStoreGroup(groupId, {
                storeIds: [item._id]
            })
            if (!success) {
                return toaster({
                    type: 'error',
                    message: message,
                })
            }
            toaster({
                type: 'success',
                message: `Unlink store successfully.`,
            })
            getListStores()
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <td className='OwnerEmailColumn text-danger'>
            {status === 'suspended' && (
                <i className='fas fa-unlink cursor-pointer' onClick={unLinkStore}></i>
            )}
        </td>
    )
}

ActionsColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ActionsColumn
