import React, {Component} from 'react'
import ProductAttributeNewValue from "./ProductAttributeItem/ProductAttributeNewValue"
import PropTypes from 'prop-types'
import SettingCheckbox from '../../../setting/shared/SettingCheckbox'

class ProductAttributeItem extends Component {
    state = {
        toggleNewValue: false,
    }

    _handleClickListValues = (e) => {
        this.setState({
            toggleNewValue: !this.state.toggleNewValue,
        })
    }

    _handleRemoveAttributeValue = (e) => {
        const removeValue = e.target.getAttribute("data-value")
        const {attribute, onChangeAttributesState, attributeIndex} = this.props
        const {values} = attribute
        const newValues = values.filter(value => value.value !== removeValue)

        onChangeAttributesState(attributeIndex, "values", newValues)
    }

    _handleRemoveAttribute = () => {
        const {attributeIndex, onRemoveAttribute} = this.props
        onRemoveAttribute(attributeIndex)
    }

    _changeSettings = (key, id) => value => {
        this.props.onChangeAttributesState(id, key, value)
    }

    render() {
        const {toggleNewValue} = this.state
        const {attribute, onChangeAttributesState, attributeIndex} = this.props
        const {name, value_type, values, is_active = true, hide_attribute_storefront = false} = attribute

        return (
            <li className="ProductAttributeItem mb-4">
                <div className="card">
                    <div className="card-header d-flex">
                        <h6 className="mb-0 font-weight-bold">{name || ""}</h6>

                        <div className="ml-auto d-flex align-items-center">

                            <SettingCheckbox
                                label="Active attribute"
                                itemId={`attribute_enabled_${attributeIndex}`}
                                checked={is_active}
                                onChange={this._changeSettings('is_active', attributeIndex)}
                            />
                            <div className='mr-3'/>
                            {
                                value_type === 'Color' &&
                                <SettingCheckbox
                                    label="Hide attribute storefront"
                                    itemId={`hide_attribute_storefront${attributeIndex}`}
                                    checked={hide_attribute_storefront}
                                    onChange={this._changeSettings('hide_attribute_storefront', attributeIndex)}
                                />
                            }

                            <div className="remove ml-3">
                                <i className="fas fa-caret-down"/>
                                <span className="text-danger ml-1 cursor-pointer"
                                      onClick={this._handleRemoveAttribute}>Remove</span>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-4">
                                <label>Name</label>
                                <input type="text" className="form-control" placeholder="Name" value={name || ""}
                                       onChange={({target: {value}}) => onChangeAttributesState(attributeIndex, "name", value)}/>
                            </div>

                            <div className="col-4">
                                <label>Type</label>
                                <select className="form-control" value={value_type || ""} placeholder = "Type"
                                        onChange={({target: {value}}) => onChangeAttributesState(attributeIndex, "value_type", value)}>
                                    <option value='' disabled>--Choose Type--</option>
                                    <option value='Size'>Size</option>
                                    <option value='Color'>Color</option>
                                    <option value='Label'>Label</option>
                                    <option value='Pack'>Pack</option>
                                </select>
                            </div>

                            <div className="col-4">
                                <label>Values</label>
                                <div className="AttributeValues">
                                    <div className="border rounded p-2">
                                        <ul className="ListAttributes">
                                            {
                                                values.map((value, index) => {
                                                    return (
                                                        <li className="Item d-inline-block mr-2 mb-2"
                                                            key={index}>
                                                            <span className="Label badge badge-secondary p-2">
                                                                <span className="Name pr-2">{value.name}</span>
                                                                <span className="Value pl-2">{value.value}</span>
                                                                <span className="ml-2 px-1 cursor-pointer"><i
                                                                    className="fas fa-times" data-value={value.value}
                                                                    onClick={this._handleRemoveAttributeValue}/></span>
                                                            </span>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>

                                        <small className="form-text text-muted cursor-pointer"
                                               onClick={this._handleClickListValues}>Add more +
                                        </small>
                                    </div>
                                    <ul>

                                    </ul>
                                </div>
                            </div>
                        </div>

                        {
                            toggleNewValue &&
                            <ProductAttributeNewValue onChangeAttributesState={onChangeAttributesState} values={values}
                                                      attributeIndex={attributeIndex}/>
                        }
                    </div>
                </div>
            </li>
        )
    }
}

ProductAttributeItem.propTypes = {
    attribute: PropTypes.object.isRequired,
    onChangeAttributesState: PropTypes.func.isRequired,
    onRemoveAttribute: PropTypes.func.isRequired,
    attributeIndex: PropTypes.number.isRequired,
}

export default ProductAttributeItem
