import React, {Component} from 'react'

// import PropTypes from 'prop-types'

class ManageRatesActionsContainer extends Component {
    render() {
        const {loading} = this.props

        return (
            <div className="ManageRatesActionsContainer">
                <div className="d-flex justify-content-end">
                    <button
                        className="btn btn-primary d-inline-flex align-items-center justify-content-center CreateRate"
                        type="submit" onClick={this._handleClickSubmit}
                        disabled={loading}>
                        {
                            loading ?
                                <span className="spin m-0"/>
                                :
                                "Add shipping rate"
                        }
                    </button>
                </div>
            </div>
        )
    }
}

// ManageRatesActionsContainer.defaultProps = {}

// ManageRatesActionsContainer.propTypes = {}

export default ManageRatesActionsContainer
