import React, {useEffect, useState} from 'react'
import PayoutFilterStore from './filter/PayoutFilterStore'
import PayoutFilterLimit from './filter/PayoutFilterLimit'
import PayoutFilterStatus from './filter/PayoutFilterStatus'
import PayoutApprovalTableHead from './table/PayoutApprovalTableHead'
import PayoutApprovalTableBody from './table/PayoutApprovalTableBody'
import {getPayoutApproval} from '../../../services/api/BillingPayoutServices'
import TableLoading from '../../shared/TableLoading'
import PFPagePagination from '../../shared/PFPagePagination'
import PayoutFilterID from './filter/PayoutFilterID'
import PropTypes from 'prop-types'

const PayoutApprovalPage = (props) => {
    const {payoutID} = props
    const [store_id, setStoreID] = useState('')
    const [limit, setLimit] = useState(20)
    const [status, setStatus] = useState(  '')
    const [payout_id, setPayoutID] = useState(payoutID || '')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [payouts, setPayouts] = useState(1)
    const [reload, setReload] = useState(Date.now())

    useEffect(() => {

        const fetchPayout = async () => {
            setLoading(true)
            setError('')
            const params = {limit, status, page}

            if (store_id) Object.assign(params, {store_id: store_id})
            if (payout_id) Object.assign(params, {payout_id: payout_id})

            try {
                const {data, success, message} = await getPayoutApproval(params)

                if (!success) throw new Error(message)

                const {withdrawals = [], pages = 1} = Object.assign({}, data)

                setPayouts(withdrawals)
                setPages(pages)

            } catch (e) {
                setError(e.message)
            } finally {
                setLoading(false)
            }
        }
        fetchPayout()
    }, [status, store_id, page, limit, reload, payout_id])

    const handChange = (value) => {
        setStoreID(value)
    }
    const handChangeLimit = (value) => {
        setLimit(value)
    }
    const handChangeStatus = (value) => {
        setStatus(value)
    }

    const handleChangePage = (page) => {
        setPage(page)
    }
    const handleChangePayoutID = (page) => {
        setPayoutID(page)
    }

    const reloadPayoutApproval = () => {
        setReload(Date.now())
    }

    return (
        <div className="PayoutApprovalPage">
            <h2>Payout Approval</h2>
            <div className="SectionInner">
                <div className="PayoutFilter d-flex align-items-center flex-wrap">
                    <PayoutFilterLimit limit={limit} onChange={handChangeLimit}/>
                    <PayoutFilterStore onChange={handChange}/>
                    <PayoutFilterStatus status={status} onChange={handChangeStatus}/>
                    <PayoutFilterID payoutID={payout_id} onChange={handleChangePayoutID}/>
                </div>

                <div className="PayoutApprovalTable mt-4">
                    <div className="TableContainer table-responsive ">
                        <table className="table table-striped table-light">
                            <PayoutApprovalTableHead/>
                            {
                                loading ?
                                    <TableLoading columnQuantity={11}/>
                                    :
                                    <PayoutApprovalTableBody payoutID={payoutID} onFetchPayout={reloadPayoutApproval} error={error}
                                                             payouts={payouts}/>
                            }

                        </table>
                    </div>
                    <PFPagePagination page={page} pages={pages} onChangePage={handleChangePage}/>
                </div>
            </div>
        </div>
    )

}

PayoutApprovalPage.propTypes = {
    payoutID: PropTypes.string.isRequired,
}

export default PayoutApprovalPage
