import React, {Component} from 'react'
import PropTypes from 'prop-types'

class CategoryLinesColumn extends Component {
    render() {
        const {item} = this.props
        const category = Object.assign({}, item.category)
        const {title} = category
        return (

            <td className="ProductLinesColumn">
                {title}
            </td>
        )
    }
}

CategoryLinesColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default CategoryLinesColumn
