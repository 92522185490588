import React, {Component} from 'react'
import PropTypes from 'prop-types'
import PFConfirmModal from '../../../../shared/PFConfirmModal'
import {rejectDeposit} from '../../../../../services/api/BillingServices'

class StatusDepositRejected extends Component {
    state = {
        isReject: false,
        rejected_note: '',
        invalid_note: '',
    }

    _changeDepositStatus = async () => {
        const {rejected_note} = this.state
        if (!rejected_note) {
            this.setState({invalid_note: 'This field is required'})
            return {
                data: null,
                error: '',
            }
        }
        const {deposit, fetchDeposits} = this.props
        const {_id} = deposit
        const {success, message} = await rejectDeposit(_id, {rejected_note})
        if (!success) {
            this.setState({
                isReject: true,
            })
            throw new Error(message)
        }
        return {
            data: 'Success',
            onSuccess: fetchDeposits,
            hideAfterSuccess: true,
        }
    }

    _loadingDeposit = () => {
        const {isReject} = this.state
        const {fetchDeposits} = this.props
        if (isReject) {
            return fetchDeposits()
        }
        return null
    }

    _onChangeNoteReject = (e) => {
        const {value} = e.target
        if (value) {
            this.setState({rejected_note: value.trim(), invalid_note: ''})
        } else {
            this.setState((prev) => {
                return {...prev, invalid_note: 'This field is required'}
            })
        }
    }

    _renderRejectTopup = () => {
        const {invalid_note} = this.state
        return (
            <div className="RejectTopUpRequest">
                <p>
                    Are you certain you want to reject this transaction? If so, please provide the reasons in the input
                    field below.
                </p>
                <div className="form-group">
                    <label htmlFor="note">
                        Reasons for rejection<span className="text-danger">*</span>
                    </label>
                    <textarea
                        maxLength={2000}
                        className={`form-control ${invalid_note ? 'invalid-input' : ''}`}
                        onChange={this._onChangeNoteReject}
                    ></textarea>
                    {invalid_note && <span className="error-message">{invalid_note}</span>}
                </div>
            </div>
        )
    }

    render() {
        return (
            <PFConfirmModal
                title="Confirmation Required"
                description={() => this._renderRejectTopup()}
                textOk="Reject"
                textCancel="Cancel"
                onAfterClose={this._loadingDeposit}
                centered={true}
            >
                {(confirm) => {
                    return (
                        <button
                            type="button"
                            onClick={confirm(this._changeDepositStatus)}
                            className="btn btn-sm btn-danger d-flex align-items-center fs-14"
                        >
                            <i className="fa fa-times mt-1 mr-1" />
                            Reject
                        </button>
                    )
                }}
            </PFConfirmModal>
        )
    }
}

StatusDepositRejected.propTypes = {
    fetchDeposits: PropTypes.func.isRequired,
    deposit: PropTypes.object.isRequired,
}

export default StatusDepositRejected
