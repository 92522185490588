import React, { Component } from 'react'
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types'
import { getListStores } from "../../../../services/api/StoreServices"

class InputSearchStore extends Component {
    state = {
        filter: 'slug'
    }

    _handleInputChange = options => {

        const valueSelect = !options ? '' : options.value

        const { filter } = this.state

        if (filter === 'domain') {
            return (
                this.props.onChange('store_domain', valueSelect),
                this.props.onChange('slug', '')
            )
        }
        if (filter === 'slug') {
            return (
                this.props.onChange('slug', valueSelect),
                this.props.onChange('store_domain', '')
            )
        }
        this.props.onChange('store_domain', valueSelect)
    }

    _timer = null

    _fetchListStores = async (storeName) => {
        const { filter } = this.state
        try {
            const params = {
                limit: 10,
                [filter]: storeName,
            }

            const { data, success, message } = await getListStores(params)

            if (!success) throw new Error(message)

            const { stores } = data

            return stores.map(store => {
                return {
                    value: (filter === 'domain' && store.domain) || (filter === 'slug' && store.slug),
                    label: store.slug + '--' + store.domain,
                }
            })

        } catch (e) {
            this.setState({
                error: e.message || e,
                loadingGetStore: false
            })
        }
    }

    _handleLoadOptions = (inputValue) => {
        if (this._timer) clearTimeout(this._timer)

        return new Promise(resolve => {
            this._timer = setTimeout(() => {
                resolve(this._fetchListStores(inputValue))
            }, 500)
        })
    }

    _onChangeSelect = (e) => {
        const { value } = e.target
        this.setState({
            filter: value
        })
    }

    _onChangeOrderNumber = (value) => {
        this.props.onChange('order_code', value)
    }

    _handleChangeSelect = (e) => {
        const { name, value } = e.target
        this.props.onChange(name, value)
    }


    render() {
        const { filter } = this.state

        return (
            <div className='Filter d-flex flex-wrap align-items-center'>
                <div className='d-flex align-items-center mt-2 mr-3'>
                    <label className='mr-2 pt-2'>Show:</label>
                    <div className='PFFilterOptions'>
                        <select className='form-control' name='limit' onChange={this._handleChangeSelect}>
                            <option value="10">10 rows</option>
                            <option value="20">20 rows</option>
                            <option value="50">50 rows</option>
                            <option value="100">100 rows</option>
                        </select>
                    </div>
                </div>
                <div className='InputStore d-flex flex-wrap align-items-center InputGroup pt-2 m-0 mr-3'>
                    <label className='mr-2 m-0'>Store: </label>
                    <div className='SelectInputStore d-flex align-items-center border rounded'>
                        <select
                            value={filter}
                            className='border-0'
                            onChange={this._onChangeSelect}
                        >
                            <option value='slug'>Store ID</option>
                        </select>
                        <AsyncSelect isClearable noOptionsMessage={() => null} loadOptions={this._handleLoadOptions}
                            onChange={this._handleInputChange} />
                    </div>
                </div>
                <div className='InputStore d-flex align-items-center InputGroup mt-2 mr-3'>
                    <label className='mr-2' style={{ minWidth: '110px' }}>Order number: </label>
                    <input className='form-control' onChange={(e) => { this._onChangeOrderNumber(e.target.value) }} placeholder='Order number' type="text" id="fname" name="fname" />
                </div>
                <div className='d-flex align-items-center mt-2 mr-3'>
                    <label className='mr-2 pt-1'>Status:</label>
                    <div className='PFFilterOptions'>
                        <select className='form-control' name='status' onChange={this._handleChangeSelect}>
                            <option value="all">All</option>
                            <option value="whitelist">Whitelist</option>
                            <option value="trademark">Trademark</option>
                        </select>
                    </div>

                </div>
                <div className='d-flex align-items-center mt-2'>
                    <label className='mr-2 pt-1'>Read/unread:</label>
                    <div className='PFFilterOptions'>
                        <select className='form-control' name='is_read' onChange={this._handleChangeSelect}>
                            <option value="all">All</option>
                            <option value="read">Read</option>
                            <option value="unread">Unread</option>
                        </select>
                    </div>

                </div>
            </div >
        )
    }
}

InputSearchStore.propTypes = {
    onChange: PropTypes.func.isRequired
}


export default InputSearchStore
