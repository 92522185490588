import React, {Component} from 'react'
import PropTypes from 'prop-types'

class IndexColumn extends Component {
    render() {
        const {item} = this.props
        const {name} = item
        return (
            <td className='HostColumn'>
                <span>{name}</span>
            </td>
        )
    }
}

IndexColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default IndexColumn
