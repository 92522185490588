import React, {Component} from 'react'
import PropTypes from 'prop-types'

class TypeColumn extends Component {
    render() {
        const {item} = this.props
        const {product_type} = item

        return (
            <td className='TypeColumn'>
                {product_type}
            </td>
        )
    }
}

TypeColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default TypeColumn
