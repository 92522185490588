import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import StatusStyle from '../../shared/StatusStyle'
import {Badge} from 'reactstrap'
import noImage from '../../../statics/assets/images/no-image.png'
class RenderTableRow extends Component {
    render() {
        const {item, page, limit, index} = this.props
        const tableIndex = limit * (page - 1) + 1 + index
        const {_id, title = '', thumbnail_link, categories = [], status} = item

        return (
            <tr>
                <td className="text-center">{tableIndex}</td>
                <td className="ImageColumn text-center">
                    <img
                        className="ProductLineImageView border"
                        style={{width: '100px', height: '100px', objectFit: 'contain'}}
                        src={thumbnail_link || noImage}
                        alt=""
                    />
                </td>
                <td>
                    <Link to={`/a/catalog/${_id}`}>{title}</Link>
                </td>
                <td>
                    {categories.length > 0
                        ? categories.map((category) => (
                              <Badge pill className="mr-1">
                                  {category.name}
                              </Badge>
                          ))
                        : '-'}
                </td>
                <td>
                    <StatusStyle status={status} />
                </td>
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

export default RenderTableRow

