import React, {useState, Fragment} from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner} from 'reactstrap'
import {actionPayoutReject} from '../../../../services/api/BillingPayoutServices'
import {toaster} from '../../../shared/PFToast'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'

const PayoutApprovalActionReject = (props) => {

    const {ID, onFetchPayout, payout_id, am_updated} = props

    const {account = {}} = Object.assign({}, am_updated)
    const {email = ''} = Object.assign({}, account)

    const [modal, setModal] = useState(false)
    const [reason, setReason] = useState('')
    const [errReason, setErrReason] = useState('')
    const [loading, setLoading] = useState(false)

    const toggle = () => {
        setModal(!modal)
        setReason('')
        setErrReason('')
    }

    const handChangeInput = (e) => {
        const value = e.target.value
        setReason(value)
        setErrReason('')
    }

    const handleReject = async () => {
        const formatReason = reason.trim()
        if (!reason || !formatReason) {
            return (
                setErrReason('Reason is required.')
            )
        }
        setLoading(true)
        try {
            const {success, message} = await actionPayoutReject(ID, reason)

            if (!success) throw new Error(message)

            setReason('')
            onFetchPayout()
            toaster({message: 'Rejected successfully!', type: 'success', duration: 5000})

        } catch (e) {
            toaster({message: e.message, type: 'error', duration: 5000})
        } finally {
            setLoading(false)
            setModal(false)
        }
    }


    return (
        <Fragment>
            <button onClick={toggle} className="btn btn-sm btn-danger mr-1">Reject</button>
            <Modal isOpen={modal} toggle={toggle}
                   className="ModalCancelOrder modal-dialog-centered">
                <ModalHeader>Reject request - {payout_id}</ModalHeader>
                <ModalBody>
                    <div className="InfoAM mb-2">
                        {
                            email && <p className="mb-0"><b>Supporter:</b> {email}</p>
                        }
                    </div>
                    <label htmlFor={`PayoutActionReject${ID}`}><b>Reason</b> <span
                        className="text-danger">*</span></label>
                    <textarea onChange={handChangeInput}
                              placeholder="Reject reason ..."
                              value={reason}
                              className={classNames('form-control', {'is-invalid': errReason})}
                              id={`PayoutActionReject${ID}`}
                              rows="3"/>
                    {errReason && <i className="text-danger fs-14">{errReason}</i>}
                </ModalBody>
                <ModalFooter>
                    <Button color="default" onClick={toggle}>Cancel</Button>
                    <Button color="primary" onClick={handleReject}>
                        {
                            loading && <Spinner color="white" size="sm" className="mr-2"/>
                        }
                        OK
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>

    )

}

PayoutApprovalActionReject.propTypes = {
    onFetchPayout: PropTypes.func.isRequired,
    ID: PropTypes.string.isRequired,
    payout_id: PropTypes.string.isRequired
}

export default PayoutApprovalActionReject
