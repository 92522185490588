import { Component } from 'react'
import DesignFormatContext from '../context/DesignFormatContext'

class DesignFormatBackground extends Component {
  state = {
    value: {
      default_value: '',
    },
    type: 'background',
    uploading: false,
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      ...{
        indexKey: this.props.indexKey,
        value: this.props.value,
      },
    })
  }

  render() {
    return null
  }
}

DesignFormatBackground.contextType = DesignFormatContext

export default DesignFormatBackground
