import React, {Component} from 'react'
import CreateAffiliatePage from './CreateAffiliatePage'

class CreateAffiliateContainer extends Component {
    render() {
        return (
            <div className='CreateCategoryContainer col-xl-6 col-lg-8 col-12 m-auto'>
                <CreateAffiliatePage />
            </div>
        )
    }
}

export default CreateAffiliateContainer
