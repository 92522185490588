import React, {Component} from 'react'
import {getCatalogCategories} from '../../../services/api/CategoriesServices'
import PFPagePagination from '../../shared/PFPagePagination'
import TableFilter from '../../shared/TableFilter'
import TableContainer from './TableContainer'
import {deleteEmptyObjectkeys} from '../../../helpers/common/cleanObject'

class CatalogCategories extends Component {
    state = {
        items: [],
        loading: true,
        params: {
            page: 1,
            limit: 10,
            title: '',
        },
        total: 0,
        pages: 1,
        err: '',
    }

    componentDidMount() {
        this._getCategories()
    }

    _getCategories = async () => {
        const {params} = this.state

        try {
            const {success, data, message} = await getCatalogCategories(deleteEmptyObjectkeys(params))
            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            const {total, pages, totalPage, categories} = Object.assign({}, data)

            this.setState({
                items: Array.isArray(categories) ? categories : [],
                total,
                pages,
                totalPage,
                loading: false,
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _handlePageChange = (page) => {
        this.setState(
            {
                params: {...this.state.params, page},
            },
            () => this._getCategories()
        )
    }

    _onSubmitFilter = ({limit, value, field, page}) => {
        const fieldMap = {
            'Category Title': 'title',
        }

        const parsedField = fieldMap[field]

        this.setState(
            ({params}) => ({
                params: {
                    ...{
                        limit: '',
                        page: '',
                        loading: '',
                    },
                    ...{
                        [parsedField]: value,
                        limit: parseInt(limit),
                        page: 1,
                        loading: false,
                    },
                },
            }),
            () => {
                this._getCategories()
            }
        )
    }

    render() {
        const {items, params, pages, loading, err, total} = this.state
        const {page, limit} = params

        return (
            <div className="CatalogCategories">
                <h2>Catalog Category</h2>
                <div className="CatalogCategoriesInner SectionInner">
                    <TableFilter
                        total={total}
                        defaultLimit={limit}
                        onSubmitForm={this._onSubmitFilter}
                        searchField={['Category Title']}
                        placeholders={{
                            'Category Title': 'Search title',
                        }}
                    />
                    {!!err && <span className="text-warning">{err}</span>}
                    <TableContainer items={items} page={page} limit={limit} loading={loading} />
                    <PFPagePagination page={page} pages={pages} onChangePage={this._handlePageChange} />
                </div>
            </div>
        )
    }
}

export default CatalogCategories
