import React, {Component} from 'react'

// import PropTypes from 'prop-types'

class ZoneActionsContainer extends Component {

    render() {
        const {error, loading} = this.props

        return (
            <div className="ZoneActionsContainer d-flex mt-4 justify-content-end">
                {
                    !!error && <div className="text-danger">{error}</div>
                }

                <button type="submit" className="btn btn-primary CreateZone" disabled={loading}>
                    {
                        loading ? <span className="spin m-0"/> : "Save"
                    }
                </button>
            </div>
        )
    }
}

// ZoneActionsContainer.defaultProps = {}

// ZoneActionsContainer.propTypes = {}

export default ZoneActionsContainer
