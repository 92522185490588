import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ClusterIP extends Component {
    state = {
        ip: this.props.ip,
    }

    _handleChangeInput = e => {
        const {name, value} = e.target
        const {onUpdateCluster} = this.props

        this.setState({
            ip: value,
        }, onUpdateCluster(name, value))
    }

    render() {
        const {ip} = this.state

        return (
            <div className="ClusterIP form-group">
                <label htmlFor="clusterIP">IP</label>
                <input type="text" className="form-control" id="clusterIP" name="ip" value={ip}
                       onChange={this._handleChangeInput}/>
            </div>
        )
    }
}

ClusterIP.propTypes = {
    ip: PropTypes.string.isRequired,
    onUpdateCluster: PropTypes.func.isRequired,
}

export default ClusterIP
