import React, {Component} from 'react'
import PropTypes from 'prop-types'
import StatusStyle from '../../../../shared/StatusStyle'

class TransactionOutStatus extends Component {
    render() {
        return (
            <td className='TransactionOutStatus'>
                <StatusStyle status={this.props.status}/>
            </td>
        )
    }
}

TransactionOutStatus.propTypes = {
    status: PropTypes.string.isRequired,
}

export default TransactionOutStatus
