import React, {Component, Fragment} from 'react'
import {createProductLines, getProductLineDetails} from '../../../services/api/ProductLinesServices'
import ProductAttributes from './ProductAttributes'
import CategoriesSelector from '../../product-lines/components/CategoriesSelector'
import AffiliateCategorySelect from '../../product-lines/components/AffiliateCategorySelect'
import storeModes from '../../product-lines/constants/storeModes'
import getHistory from '../../../store/getHistory'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import {toaster} from '../../shared/PFToast'
import classNames from 'classnames'
import InputEditor from '../../shared/components/form/InputEditor'
import InputNumberFormat from '../../shared/components/form/InputNumberFormat'
import InputDescriptions from '../../shared/components/form/InputDescriptions'
import DOMPurify from 'dompurify'
import PFInputMulti from '../../shared/PFInputMulti'
import { Button } from 'reactstrap'
import ColorMappingModal from './ColorMappingModal'

const animatedComponents = makeAnimated()

class CreateNewProductLine extends Component {
    constructor(props) {
        super(props)
        this.editorDescription = React.createRef()
        this.editorSizeChart = React.createRef()
        this.state = {
            title: '',
            product_type: '',
            categoryId: '',
            category: null,
            currency: '',
            available_for_store_modes: [],
            attributes: [],
            error: '',
            saving: false,
            modal: false,
            loading: false,
            is_popular: false,
            available_for_campaign: false,
            support_preview_mockup: false,
            affiliateCategory: {},
            errorObj: {},
            est_process_time_to: '',
            est_process_time_from: '',
            sku_prefix: '',
            description: '',
            /*size_chart: '',
            size_chart_image: '',*/
            store: '',
            loadingDuplicate: false,
            ffm_mapping_product_types: [],
            isOpenColorMapping: false,
        }
    }

    async componentDidMount() {
        const {duplicateProductId = ''} = this.props
        if (duplicateProductId) {
            this.setState({
                loadingDuplicate: true,
            })
            const productDetails = await getProductLineDetails(duplicateProductId)
            return this._loadProductTemplate(productDetails)
        }
    }

    _loadProductTemplate = (productDetails) => {
        const {data = {}} = productDetails

        const {
            attributes = [],
            images = [],
            variants = [],
            title,
            description = '',
            /* size_chart = '',
            size_chart_image = '', */
            sku_prefix,
            est_process_time_from,
            product_type,
            category,
            is_popular,
            available_for_store_modes,
            currency,
            affiliate_category = {},
            est_process_time_to,
            available_for_campaign,
            support_preview_mockup,
        } = Object.assign({}, data)

        const currentModes = available_for_store_modes.map((mode) => ({value: mode, label: mode}))

        let affiliateCategory = undefined

        if (Object.keys(affiliate_category).length) {
            affiliateCategory = Object.assign(
                {...affiliate_category},
                {
                    label: affiliate_category.name,
                    value: affiliate_category._id,
                }
            )
        }

        this.setState(
            {
                attributes: attributes,
                images: images,
                description: description,
                /*size_chart: size_chart,
            size_chart_image: size_chart_image,*/
                listVariants: variants,
                originalListVariants: variants,
                error: '',
                loading: false,
                title: title,
                est_process_time_from: est_process_time_from,
                est_process_time_to: est_process_time_to,
                store: available_for_store_modes.join(),
                product_type: product_type,
                is_popular: is_popular,
                categoryId: (category && category._id) || '',
                category: category || null,
                available_for_store_modes: currentModes,
                affiliateCategory,
                currency: currency,
                sku_prefix: sku_prefix,
                available_for_campaign: available_for_campaign,
                support_preview_mockup,
                loadingDuplicate: false,
            },
            () => {
                window.scrollTo(0, 0)
            }
        )
    }

    _scrollErrorInput = () => {
        const scrollableDiv = document.getElementsByClassName('invalid-feedback')
        scrollableDiv[0].scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'})
    }

    _setEditorRefs = (key, editor) => {
        this[key] = editor
    }

    _validate = (e) => {
        e.preventDefault()
        const {
            title = '',
            product_type = '',
            available_for_store_modes,
            attributes,
            currency,
            affiliateCategory,
            est_process_time_to,
            est_process_time_from,
            store,
            available_for_campaign,
            ffm_mapping_product_types = [],
        } = this.state

        const description = this.editorDescription.getContent()

        const titleFormat = title.trim()
        const cleanDescription = DOMPurify.sanitize(description, {ALLOWED_TAGS: ['']})
        const desFormat = cleanDescription.replace(/&nbsp;/g, ' ').trim()
        const productTypeFormat = product_type.trim()

        let errorKey = {}

        if (store === 'marketplace') {
            if (!affiliateCategory || !Object.keys(affiliateCategory).length) {
                errorKey.affiliateCategory = 'Affiliate is required.'
            }
        }
        if (!titleFormat) {
            errorKey.title = 'Title is required.'
        }
        if (!desFormat) {
            errorKey.description = 'Description is required.'
        }
        if (!est_process_time_to && est_process_time_to !== 0) {
            errorKey.est_process_time_to = 'Processing time to is required.'
        }
        if (est_process_time_to < est_process_time_from) {
            errorKey.est_process_time_to = 'Processing time from must be greater than process time to.'
        }
        if (!est_process_time_from && est_process_time_from !== 0) {
            errorKey.est_process_time_from = 'Processing time from is required.'
        }
        if (available_for_campaign && !productTypeFormat) {
            errorKey.product_type = 'This field is required.'
        }
        if (!currency) {
            errorKey.currency = 'Currency is required.'
        }
        if (!Array.isArray(available_for_store_modes) || available_for_store_modes.length === 0) {
            errorKey.available_for_store_modes = 'Store modes is required.'
        }
        if (!Array.isArray(attributes) || attributes.length === 0) {
            errorKey.attributes = 'Attributes is required.'
        }
        // if (!Array.isArray(ffm_mapping_product_types) || ffm_mapping_product_types.length === 0) {
        //     errorKey.ffm_mapping_product_types = 'Fulfillment product type is required.'
        // }

        this.setState({
            errorObj: errorKey,
        })

        if (Object.keys(errorKey).length > 0) return setTimeout(this._scrollErrorInput, 150)

        this._handSubmit()
    }

    /*_uploadImage = async (productId, file) => {
        try {
            const formData = new FormData()
            formData.append('size_chart_image', file)
            const {success, message, data} = await uploadProductLineSizeChartImage(productId, formData)
            if (!success) throw new Error(message)
            return data
        } catch (e) {
            return false
        }
    }*/
    _handSubmit = async () => {
        if (this.state.loading) return
        const description = this.editorDescription.getContent()
        /*const size_chart = this.editorSizeChart.getContent()*/
        const {
            title,
            product_type,
            attributes,
            available_for_store_modes,
            est_process_time_to,
            est_process_time_from,
            categoryId,
            is_popular,
            currency,
            affiliateCategory,
            sku_prefix,
            available_for_campaign,
            support_preview_mockup,
            ffm_mapping_product_types,
            /*size_chart_image*/
        } = this.state
        this.setState({
            loading: true,
        })

        const {_id: idAffiliate = ''} = Object.assign({}, affiliateCategory)

        const arrModes = Array.isArray(available_for_store_modes) && available_for_store_modes.map((mode) => mode.value)

        const newAttributes = attributes.map((item) => {
            return {
                ...item,
                value_type: item.value_type === 'Pack' ? 'Label' : item.value_type,
            }
        })

        const product = {
            title: title,
            product_type: product_type,
            attributes: newAttributes,
            available_for_store_modes: arrModes,
            affiliate_category: idAffiliate,
            is_popular: is_popular,
            currency: currency,
            available_for_campaign: available_for_campaign,
            support_preview_mockup,
            est_process_time_to: est_process_time_to,
            est_process_time_from: est_process_time_from,
            sku_prefix: sku_prefix,
            description: description,
            ffm_mapping_product_types,
            //size_chart: size_chart,
        }

        if (categoryId) Object.assign(product, {category: categoryId})

        try {
            const {success, data, message} = await createProductLines(product)

            if (!success) throw new Error(message)

            this.setState({
                loading: false,
                title: '',
                product_type: '',
                categoryId: '',
                available_for_store_modes: [],
                attributes: [],
                is_popular: false,
            })
            const productId = data._id
            /*const uploaded = await this._uploadImage(productId, size_chart_image)
            if (!uploaded){
                throw new Error("Upload image size chart fail")
            }*/

            toaster({
                type: 'success',
                message: `Created product line ${title} successfully!`,
                duration: 5000,
            })

            const history = getHistory()
            history.push(`/a/product-lines/${productId}`)
        } catch (e) {
            this.setState({
                loading: false,
            })
            toaster({message: e.message, type: 'error', duration: 5000})
        }
    }

    _handleChange = (value, property) => {
        let {errorObj} = this.state
        if (errorObj[property]) {
            delete errorObj[property]
        }
        this.setState({
            ...this.state,
            [property]: value,
        })
    }

    _handleChangeInput = (property) => (e) => {
        const value = e.target.value
        this._handleChange(value, property)
    }

    _handleChangeCheckbox = (property) => (e) => {
        const value = e.target.checked
        this._handleChange(value, property)
    }

    _handleChangeStoreMode = (options) => {
        let {errorObj} = this.state
        if (errorObj['available_for_store_modes']) {
            delete errorObj['available_for_store_modes']
        }
        const {value = ''} = Object.assign({}, options)
        let currency = value === 'marketplace' ? 'VND' : 'USD'
        let currentModes = []
        currentModes.push(options)

        this.setState({
            available_for_store_modes: currentModes,
            currency: currency,
            store: value,
        })

        if (errorObj['currency']) {
            delete errorObj['currency']
        }
    }

    _handleSelectCategories = (options = []) => {
        const categories = !options ? [] : options
        const firstSelect = categories[0] || {id: ''}
        const {id} = firstSelect
        this.setState(() => {
            return {
                categoryId: id,
            }
        })
    }

    _handleSelectAffiliateCategory = (options = []) => {
        let affiliateCategory = options ? options[0] : undefined
        if (Object.keys(affiliateCategory).length) {
            affiliateCategory = Object.assign(
                {...affiliateCategory},
                {
                    ...affiliateCategory,
                    value: affiliateCategory._id,
                }
            )
        }
        let {errorObj} = this.state
        delete errorObj['affiliateCategory']
        this.setState({
            affiliateCategory,
            errorObj,
        })
    }

    _handleChangeFfmProductType = (event) => {
        const {name, value} = event
        this.setState({
            [name]: value,
        })
    }

    _onChangeColorMapping = (attributes) => this.setState({attributes})

    _toggleColorMapping = () => this.setState(prevState => ({isOpenColorMapping: !prevState.isOpenColorMapping}))

    render() {
        const {
            title,
            product_type,
            loading,
            is_popular,
            errorObj,
            currency,
            est_process_time_to,
            attributes,
            loadingDuplicate,
            available_for_campaign,
            support_preview_mockup,
            category,
            available_for_store_modes,
            est_process_time_from,
            sku_prefix,
            description,
            store,
            affiliateCategory,
            ffm_mapping_product_types,
            isOpenColorMapping,
            /*size_chart, size_chart_image*/
        } = this.state

        if (loadingDuplicate) return null

        return (
            <Fragment>
                <InputEditor
                    label="Title"
                    name="title"
                    value={title || ''}
                    error={errorObj.title}
                    onChange={this._handleChange}
                />
                <div className="form-group">
                    <label className="font-weight-bold">Mode</label>
                    <Select
                        className={classNames({'border border-danger rounded': errorObj.available_for_store_modes})}
                        components={animatedComponents}
                        options={storeModes}
                        value={available_for_store_modes}
                        onChange={this._handleChangeStoreMode}
                    />
                    {errorObj.available_for_store_modes && (
                        <div className="invalid-feedback d-block">{errorObj.available_for_store_modes}</div>
                    )}
                </div>

                <div className="form-group row">
                    <div className="col-12 col-sm-6">
                        <InputEditor
                            label="SKU prefix"
                            name="sku_prefix"
                            value={sku_prefix || ''}
                            error={errorObj.sku_prefix}
                            onChange={this._handleChange}
                        />
                    </div>
                    <div className="col-12 col-sm-6">
                        <label className="font-weight-bold">Processing time (business day)</label>
                        <div className="ProcessingTime d-flex">
                            <InputNumberFormat
                                label="From"
                                placeholder="BDs"
                                value={est_process_time_from}
                                name="est_process_time_from"
                                error={errorObj.est_process_time_from}
                                onChange={this._handleChange}
                            />
                            <InputNumberFormat
                                label="To"
                                value={est_process_time_to}
                                placeholder="BDs"
                                name="est_process_time_to"
                                error={errorObj.est_process_time_to}
                                onChange={this._handleChange}
                            />
                        </div>
                    </div>
                </div>

                <InputEditor
                    label="Product Type"
                    value={product_type || ''}
                    name="product_type"
                    error={errorObj.product_type}
                    onChange={this._handleChange}
                />

                <div className="form-group">
                    <div className="d-flex align-items-center justify-content-between">
                        <label htmlFor="FfmProductType" className="font-weight-bold">
                            Fulfillment Product type
                        </label>
                        <span className="text-primary cursor-pointer" onClick={this._toggleColorMapping}>
                            Color mapping
                        </span>
                    </div>

                    <PFInputMulti
                        errors={errorObj}
                        id="FfmProductType"
                        splitSpace={true}
                        name="ffm_mapping_product_types"
                        defaultValue={ffm_mapping_product_types}
                        onChange={this._handleChangeFfmProductType}
                        placeholder="Enter Fulfillment Product type and press enter..."
                    />
                </div>

                {
                    isOpenColorMapping && <ColorMappingModal attributes={attributes} isOpen={isOpenColorMapping} toggle={this._toggleColorMapping} onChangeMapping={this._onChangeColorMapping} />
                }

                <div className="form-group">
                    <CategoriesSelector
                        multiSelect={false}
                        defaultSelectedOptions={{value: '', label: 'Un categorised'}}
                        defaultValue={
                            category && {
                                ...category,
                                id: category._id,
                                value: category.title,
                                label: category.title,
                            }
                        }
                        handleSelectCollection={this._handleSelectCategories}
                    />
                </div>

                {store === 'marketplace' && (
                    <div className="form-group">
                        <AffiliateCategorySelect
                            multiSelect={false}
                            className={errorObj.affiliateCategory ? 'error' : ''}
                            handleSelectCollection={this._handleSelectAffiliateCategory}
                            defaultValue={affiliateCategory}
                            err={errorObj.affiliateCategory}
                        />
                        {errorObj.affiliateCategory && (
                            <div className="invalid-feedback d-block">{errorObj.affiliateCategory}</div>
                        )}
                    </div>
                )}

                <div className="form-group">
                    <label className="font-weight-bold">Currency</label>
                    <select
                        value={currency}
                        className={classNames('form-control', {'border border-danger rounded': errorObj.currency})}
                        onChange={this._handleChangeInput('currency')}
                    >
                        <option value="" disabled>
                            --Choose Type--
                        </option>
                        <option value="USD">USD</option>
                        <option value="VND">VND</option>
                    </select>
                    {errorObj.currency && <div className="invalid-feedback d-block">{errorObj.currency}</div>}
                </div>

                <div className="form-group d-flex align-items-center flex-wrap">
                    <div className="custom-control custom-checkbox mr-3">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ProductLinePopular"
                            checked={is_popular}
                            onChange={this._handleChangeCheckbox('is_popular')}
                        />
                        <label className="custom-control-label" htmlFor="ProductLinePopular">
                            Product line popular
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox mr-3">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ProductLineAvailableCampaign"
                            checked={available_for_campaign}
                            onChange={this._handleChangeCheckbox('available_for_campaign')}
                        />
                        <label className="custom-control-label" htmlFor="ProductLineAvailableCampaign">
                            Available for campaign
                        </label>
                    </div>
                    <div className="custom-control custom-checkbox mr-3">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="ProductLineSupportPreviewMockup"
                            checked={support_preview_mockup}
                            onChange={this._handleChangeCheckbox('support_preview_mockup')}
                        />
                        <label className="custom-control-label" htmlFor="ProductLineSupportPreviewMockup">
                            Support preview mockup
                        </label>
                    </div>
                </div>

                <InputDescriptions
                    setEditorRefs={this._setEditorRefs}
                    errorObj={errorObj.description}
                    description={description}
                />

                {/*<InputSizeChartImage errorObj={errorObj.size_chart_image} onChange={this._handleChange}/>
ffm_mapping_names
                <InputSizeChart setEditorRefs={this._setEditorRefs} errorObj={errorObj.size_chart} size_chart={size_chart}/>*/}

                <ProductAttributes
                    attributes={attributes}
                    errorObj={errorObj}
                    onHandleChangeAttribute={this._handleChange}
                />

                <div className="form-group mt-3 text-right">
                    <div className="form-group">
                        <button onClick={this._validate} disabled={loading} className="btn btn-primary mr-2">
                            Create product line
                        </button>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default CreateNewProductLine

