import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {_convertPasteValueToArray} from '../../../helper'
import classNames from 'classnames'

class ProductVariantNewSKU extends Component {
    _handleChangeInput = (value) => {
        const {variantIndex, handleListVariantsState} = this.props
        handleListVariantsState(variantIndex, 'new_sku', value)
    }

    _onPaste = (e) => {
        e.preventDefault()
        const value = _convertPasteValueToArray(e)
        if (value?.length > 1) {
            this.props.onPasteListVariants(_convertPasteValueToArray(e), 'new_sku', false)
        } else {
            this._handleChangeInput(value[0])
        }
    }

    render() {
        const {new_sku = '', error} = this.props
        return (
            <td className="ProductVariantSKU">
                <input
                    onPaste={this._onPaste}
                    type="text"
                    className={classNames('form-control', {'is-invalid': error})}
                    value={new_sku || ''}
                    onChange={(e) => this._handleChangeInput(e.target.value)}
                />
                {error && <i className="text-danger fs-14">{error}</i>}
            </td>
        )
    }
}

ProductVariantNewSKU.propTypes = {
    sku: PropTypes.string.isRequired
}

export default ProductVariantNewSKU

