import React from 'react'
import ModalCommon from '../../setting-webhook/ModalCommon'
import PFTableV3 from '../../shared/components/table/v3'
import {renewDbStats} from '../../../services/api/StoreServices'
import JsonInput from '../../shared/components/form/JsonInput'
import moment from 'moment'

class ButtonViewStoreStats extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isShow: false,
            isModalStatisticStoreOpen: false,
            storeStats: this.props.store.db_stats,
        }
    }

    reloadStats = async () => {
        try {
            this.setState((prev) => ({...prev, isLoading: true, isShow: true}))
            const {store} = this.props
            const {_id: storeId} = store

            const {success, message, data} = await renewDbStats(storeId)
            if (!success) {
                throw new Error(message)
            }
            this.setState((prev) => ({...prev, storeStats: data}))
        } catch (error) {
            alert(error.message)
        }
        this.setState((prev) => ({...prev, isLoading: false, isShow: true}))
    }

    handleClose = () => {
        this.setState((prev) => ({...prev, isShow: false}))
    }

    _onCloseStatisticStoreModal = () => {
        this.setState({isModalStatisticStoreOpen: false})
    }

    _onOpenStatisticStoreModal = async () => {
        try {
            this.setState((prev) => ({...prev, isModalStatisticStoreOpen: true}))
        } catch (error) {
            alert(error)
        }
    }

    _columns = (cols) => {
        const headers = []
        cols.forEach((col) => {
            if (col === '_id') {
                headers.push({
                    title: 'ID',
                    dataIndex: '_id',
                    key: '_id',
                    render: (data) => {
                        return (
                            <>
                                <span>{data._id}</span> <br />
                            </>
                        )
                    },
                })
            } else {
                headers.push({
                    title: col.toUpperCase(),
                    dataIndex: col,
                    key: col,
                    render: (data) => {
                        return (
                            <>
                                <span>{data[col]}</span> <br />
                            </>
                        )
                    },
                })
            }
        })
        return headers
    }

    additionalBtns = [
        {
            text: 'Renew',
            onClick: this.reloadStats,
        },
    ]

    render() {
        const {isLoading, storeStats, isModalStatisticStoreOpen} = this.state

        return (
            <>
                <div className="ButtonRollbackDatabase d-inline-block">
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={this._onOpenStatisticStoreModal}
                        disabled={isLoading}
                        style={{marginLeft: 10}}
                    >
                        {isLoading && <i className="fas fa-circle-notch fa-spin mr-2"></i>}
                        View Statistics
                    </button>
                </div>
                <ModalCommon
                    title={`Database Statistics ${storeStats ? moment(storeStats.statsTime).format('DD-MM-YYYY h:mm:ss a'): ''}`}
                    isOpen={isModalStatisticStoreOpen}
                    onClose={this._onCloseStatisticStoreModal}
                    hideSubmit={true}
                    cancelText="Close"
                    additionalBtns={this.additionalBtns}
                    size="lg"
                >
                    {storeStats && Object.keys(storeStats).length > 0 ? (
                        <>
                            {isLoading ? <></> : <JsonInput placeholder={storeStats.stats} viewOnly={true} confirmGood={false} />}
                            <PFTableV3
                                data={Object.values(storeStats.collectionStats)}
                                loading={isLoading}
                                columns={this._columns(storeStats.collectionColumns)}
                                hasCheckbox={false}
                                allowSelectAll={false}
                                onSubmit={() => {}}
                            />
                        </>
                    ) : (
                        <>Not found</>
                    )}
                </ModalCommon>
            </>
        )
    }
}

export default ButtonViewStoreStats
