import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ProductGalleryInvalidImages extends Component {
    render() {
        const {images} = this.props

        if (!images.length) return null

        return (
            <div className='ProductGalleryInvalidImages'>
                <h6>Invalid files:</h6>
                <ul>
                    {
                        images.map((image, index) => (
                            <li key={`invalidGalleryImage_${index}`}>
                                <div className='row'>
                                    <div className='col-auto'>{image.file.name}</div>
                                    <div className='col-auto'>{image.message}</div>
                                </div>
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
    }
}

ProductGalleryInvalidImages.propTypes = {
    images: PropTypes.array.isRequired,
}

export default ProductGalleryInvalidImages
