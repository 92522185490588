import React, { useEffect } from 'react'
import Select from 'react-select'
import { updateAllProductCatalogVariantMockup } from '../../../../../services/api/ProductLinesServices'
import { toaster } from '../../../../shared/PFToast'
import PubSub from '../../../helper/CatalogBroker'

const DesignVariantFilter = (props) => {
    const {attributes, handleFilterVariants, filter, mockups, variants, tab, updateFilter} = props

    const _onFilterVariant = (e) => {
        const {name, value} = e.target
        const newFilter = {...filter}
        if (value) {
            newFilter[name] = value
        } else {
            delete newFilter[name]
        }
        handleFilterVariants(newFilter)
    }

    const _handleChange = async (option) => {
        try {
            const mockup = option.value
            const variant_ids = variants.map((v) => v._id)
            const payload = {
                mockup,
                variant_ids,
            }
            const {success, message} = await updateAllProductCatalogVariantMockup(payload)
            if (!success) throw new Error(message)
            toaster({
                type: 'success',
                message: 'Update all variant template mockup successfully!',
            })
            PubSub.publish('UPDATED_CATALOG_DETAIL')
        } catch (error) {
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    useEffect(() => {
        updateFilter({})
    }, [tab, attributes, updateFilter])

    return (
        <tr className="ProductVariantsRow">
            {attributes.map((attribute) => {
                const {values = [], type, name} = attribute
                return (
                    <td key={type}>
                        <select name={name} value={filter[name] || ''} className="form-control" onChange={_onFilterVariant}>
                            <option value="">All</option>
                            {values.map((v) => {
                                return (
                                    <option key={v.code} value={v.code}>
                                        {v.text}
                                    </option>
                                )
                            })}
                        </select>
                    </td>
                )
            })}
            <td>
                <Select
                    isMulti={false}
                    cacheOptions
                    options={mockups.map((mockup) => {
                        return {
                            label: mockup.title,
                            value: mockup._id,
                        }
                    })}
                    onChange={_handleChange}
                    defaultOptions={true}
                    placeholder="Select template mockup"
                />
            </td>
        </tr>
    )
}

export default DesignVariantFilter

