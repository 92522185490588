import React, {Component} from 'react'
import AnalyticsPage from './AnalyticsPage'
import getEnv from '../../../helpers/common/getEnv'

class AnalyticsPageContainer extends Component {

    render() {
        const isSupportMpMode = getEnv('isEnableMpMode')
        if (isSupportMpMode) return null

        return (
            <div className='AnalyticsPageContainer'>
                <AnalyticsPage />
            </div>
        )
    }
}

export default AnalyticsPageContainer
