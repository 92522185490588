import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import StoreDetails from './StoreDetails'
import StoreSettings from './StoreSettings'

class StoreSettingsPage extends Component {
    render() {
        const {storeId} = this.props
        return (
            <div className='StoreSettingsPage SectionInner'>
                <Link className='nav-link BackButton' to={`/a/stores/${storeId}/`}>
                    <i className='fas fa-chevron-left' />
                    <span>Store Details</span>
                </Link>
                <StoreDetails storeId={storeId} />
                <StoreSettings storeId={storeId} />
            </div>
        )
    }
}

StoreSettingsPage.propTypes = {
    storeId: PropTypes.string.isRequired,
}

export default StoreSettingsPage
