import React from 'react'

const PayoutApprovalTableHead = () => {

    return (
        <thead className="PayoutApprovalTableHead thead-light">
            <tr>
                <th className='table-col-5'>Time</th>
                <th className='table-col-10'>Store</th>
                <th className='table-col-10'>Payout ID</th>
                <th className='table-col-5 text-center'>Value</th>
                <th className='table-col-10'>Account</th>
                <th className='table-col-15'>Note</th>
                <th className='table-col-10'>Supporter</th>
                <th className='table-col-10'>Accountant</th>
                <th className='table-col-5'>Payout Proof</th>
                <th className='table-col-5'>Status</th>
                <th className='table-col-15' >Action</th>
            </tr>
        </thead>
    )

}

export default PayoutApprovalTableHead
