import createAPIServices from './createApiServices'
import getEnv from '../../helpers/common/getEnv'

const baseUrl = `${getEnv('baseSellerHost')}/api/product-line`
// const baseUrl = `https://seller-staging.merchize.com/api/product-line`

const api = createAPIServices({baseUrl})

export const getProductLines = (params) => {
    const defaultParams = {
        limit: 20,
        page: 1
    }

    const newParams = {...defaultParams, ...params}


    return api.makeAuthRequest({
        url: 'manager/product-lines/search/v4',
        method: 'POST',
        data: newParams
    })

}

export const createProductLines = data => {
    return api.makeAuthRequest({
        url: '/product-lines',
        method: 'POST',
        data
    })
}


export const uploadProductLineImages = (id, data) => {
    return api.makeAuthRequest({
        url: `/product-lines/${id}/images`,
        method: 'POST',
        data
    })
}

export const uploadProductLineSizeChartImage = (id, data) => {
    return api.makeAuthRequest({
        url: `/product-lines/${id}/size-chart-image`,
        method: 'POST',
        data
    })
}

export const getProductLineDetails = (ProductLineId = '') => {
    return api.makeAuthRequest({
        url: `product-lines/${ProductLineId}/v2`,
        method: 'GET'
    })
}

export const deleteImageProductLine = (ProductLineId = '', imageId = '') => {
    return api.makeAuthRequest({
        url: `product-lines/${ProductLineId}/image/${imageId}`,
        method: 'DELETE'
    })
}

export const setAttributeProductLineStoreFront = (value, attributeId = '') => {
    return api.makeAuthRequest({
        url: `/product-line-attribute/${attributeId}/hide_attribute_storefront`,
        method: 'POST',
        data: {
            hide_attribute_storefront: value
        }
    })
}


export const setAttributeProductLine = (value, attributeId = '') => {
    return api.makeAuthRequest({
        url: `/product-line-attribute/${attributeId}/status`,
        method: 'POST',
        data: {
            is_active: value
        }
    })
}

export const updateAttributesList = (ProductLineId = '', attributes = []) => {
    return api.makeAuthRequest({
        url: `/v2/product-lines/${ProductLineId}/attributes`,
        method: 'POST',
        data: {
            attributes: attributes
        }
    })
}

export const updateProductLineVariants = (productLineId, data) => {
    return api.makeAuthRequest({
        url: `/product-lines/${productLineId}/variants`,
        method: 'POST',
        data
    })
}

export const deleteProductLine = (productLineId) => {
    return api.makeAuthRequest({
        url: `product-lines/${productLineId}`,
        method: 'DELETE'
    })
}
export const deleteProductLineCategory = (productLineId) => {
    return api.makeAuthRequest({
        url: `/product-lines/${productLineId}/category`,
        method: 'DELETE'
    })
}

export const updateProductLine = (productLineId, data) => {
    return api.makeAuthRequest({
        url: `/product-lines/${productLineId}`,
        method: 'POST',
        data
    })
}


export const updateProductLineColor = (productLineId, color) => {
    return api.makeAuthRequest({
        url: `/repair/product-color/${productLineId}`,
        method: 'POST',
        data: color
    })
}

export const addNewProductMockup = (params = {}) => {
    return api.makeAuthRequest({
        url: '/manager/mockup',
        method: 'POST',
        data: params
    })
}

export const getProductMockup = (params = {}) => {
    return api.makeAuthRequest({
        url: '/manager/mockup/search',
        method: 'POST',
        data: params
    })
}

export const getAffiliateCategories = (params) => {

    const defaultParams = {
        limit: 20,
        page: 1,
    }

    const newParams = {...defaultParams, ...params}

    return api.makeAuthRequest({
        url: '/manager/affiliate-category/search',
        method: 'POST',
        data: newParams
    })
}

export const createAffiliateCategories = (params) => {
    return api.makeAuthRequest({
        url: '/manager/affiliate-category',
        method: 'POST',
        data: params
    })
}

export const deleteAffiliateCategories = (id) => {
    return api.makeAuthRequest({
        url: `manager/affiliate-category/${id}`,
        method: 'DELETE',
    })
}

export const updateAffiliateCategories = (params) => {
    return api.makeAuthRequest({
        url: `manager/affiliate-category/${params.id}`,
        method: 'PUT',
        data: params
    })
}

export const getProductMockupDetail = (id = '') => {
    return api.makeAuthRequest({
        url: `/manager/mockup/${id}`,
        method: 'GET'
    })
}

export const deleteProductMockupDetail = (id = '') => {
    return api.makeAuthRequest({
        url: `/manager/mockup/${id}`,
        method: 'DELETE'
    })
}

export const saveProductMockupDetail = (params) => {
    return api.makeAuthRequest({
        url: `/manager/mockup/${params.id}`,
        method: 'POST',
        data: params
    })
}

export const getUsageStatusOfAffiliateCategory = (id = '') => {
    return api.makeAuthRequest({
        url: `manager/affiliate-category/${id}/is-in-use`,
        method: 'GET'
    })
}

export const exportProductMockup = (params)=> {
    return api.makeAuthRequest({
        url :'manager/export/mockup',
        method:'POST',
        data: params,
        headers:'application/json'
    })
}

export const importProductMockup = (data) =>{
    return api.makeAuthRequest({
        url:'manager/import/mockup',
        method:'POST',
        data
    })
}

export const syncMockupDetail = (id) => {
    return api.makeAuthRequest({
        url:'private/migrate-url-to-s3',
        method:'PUT',
        data: {
            product_line_mockup_id: id
        }
    })
}

export const toggleAvailableForFulfill = (data) => {
    return api.makeAuthRequest({
        url: `/product-lines/bulk-toggle-avaiable-for-fulfill`,
        method: 'POST',
        data
    })
}

export const updateFfmColorAttribute = (attributeId, data) => {
    return api.makeAuthRequest({
        url: `product-lines/attribute-value-color/${attributeId}`,
        method: 'PUT',
        data
    })
}

export const importProductLineMapping = (data) => {
    return api.makeAuthRequest({
        url: `product-lines/actions/import-csv`,
        method: 'POST',
        data
    })
}

export const exportProductLine = (data) => {
    return api.makeAuthRequest({
        url: `product-lines/actions/export-csv`,
        method: 'POST',
        data
    })
}

export const getProductCatalog = (data) => {
    return api.makeAuthRequest({
        url: `manager/catalog-products/search`,
        method: 'POST',
        data
    })
}

export const getProductCatalogDetail = (id) => {
    return api.makeAuthRequest({
        url: `manager/catalog-products/${id}`,
        method: 'GET',
    }) 
}

export const updateProductCatalog = (productId, data) => {
    return api.makeAuthRequest({
        url: `manager/catalog-products/${productId}`,
        method: 'POST',
        data
    })
}

export const updateProductCatalogAttribute = (productId, data) => {
    return api.makeAuthRequest({
        url: `manager/catalog-products/${productId}/attributes`,
        method: 'POST',
        data
    })
}

export const exportProductLineVariant = (data) => {
    return api.makeAuthRequest({
        url: `manager/product-lines/export-variants`,
        method: 'POST',
        data
    })
}

export const importNewSku = (data) => {
    return api.makeAuthRequest({
        url: `manager/product-lines/import/variant-skus`,
        method: 'POST',
        data
    })
}

export const getVariantMockup = (data) => {
    return api.makeAuthRequest({
        url: `manager/mockup/search`,
        method: 'POST',
        data
    })
}

export const updateCatalogColorMapping = (productId, data) => {
    return api.makeAuthRequest({
        url: `manager/catalog-products/${productId}/color-mapping`,
        method: 'POST',
        data
    })
}

export const updateAllProductCatalogVariantMockup = (data) => {
    return api.makeAuthRequest({
        url: `manager/catalog-product-variants/bulk/mockup`,
        method: 'POST',
        data
    })
}

export const changeVariantScope = (variantId, data) => {
    return api.makeAuthRequest({
        url: `manager/catalog-product-variants/${variantId}`,
        method: 'POST',
        data
    })
}

export const getListPrivatePrice = (catalogID, plan) => {
    return api.makeAuthRequest({
        url: `manager/catalog-product/private-prices/shipping?product_id=${catalogID}&plan=${plan}`,
        method: 'GET'
    })
}

export const getListPrivatePriceBaseCost = (catalogID) => {
    return api.makeAuthRequest({
        url: `manager/catalog-product/private-prices/base-cost?product_id=${catalogID}`,
        method: 'GET'
    })
}

export const getListAppliedStores = (catalogID) => {
    return api.makeAuthRequest({
        url: `manager/catalog-products/${catalogID}/applied-stores`,
        method: 'GET'
    })
}

export const updatePrivateBaseCost = (payload) => {
    return api.makeAuthRequest({
        url: `manager/catalog-product/private-prices/base-cost`,
        method: 'POST',
        data: payload
    })
}

export const updatePrivateShipping = (payload) => {
    return api.makeAuthRequest({
        url: `manager/catalog-product/private-prices/shipping`,
        method: 'POST',
        data: payload
    })
}

export const importCatalogFfmType = (data) => {
    return api.makeAuthRequest({
        url: `/manager/catalog-products/fulfillment-types/import`,
        method: 'POST',
        data
    })
}

export const exportCatalogFfmType = (data) => {
    return api.makeAuthRequest({
        url: `/manager/catalog-products/fulfillment-types/export`,
        method: 'POST',
        data
    })
}
