import React, {Component} from 'react'
import {Button} from 'reactstrap'
import {CSVLink} from 'react-csv'
import {exportContestDetailStore} from '../../../../services/api/PlanServices'
import DetailReportInputStore from '../../../contest-report/components/DetailReport/filter/DetailReportInputStore'

class ContestDetailReportPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            error: '',
            dataExport: '',
            storeID: ''
        }
    }

    _exportContestAllStore = async () => {
        this.setState({
            loading: true,
            error: ''
        })

        try {
            const {storeID} = this.state
            const data = await exportContestDetailStore(storeID)

            await this.setState({
                loading: false,
                dataExport: data
            })

            await this.csvLink.link.click()
        } catch (e) {
            this.setState({
                loading: false,
                error: e.message
            })
        }
    }

    _handleChange = (property, value) => {
        this.setState({[property]: value})
    }

    render() {
        const {error, dataExport, loading, storeID} = this.state

        return (
            <div className='ContestDetailReportPage mt-3'>
                <div className='DetailReportPageInner SectionInner'>
                    <h3>Detailed Report</h3>
                    <div className='form-group AccountingReportInput d-flex flex-wrap align-items-center mb-4'>
                        <DetailReportInputStore onChange={this._handleChange}/>
                        <div>
                            <Button
                                color='primary'
                                onClick={this._exportContestAllStore}
                                disabled={storeID === ''}
                            >
                                {loading && <i className='fa fa-spin fa-spinner mr-1'/>}
                                {loading ? 'Exporting' : 'Export'}
                            </Button>
                            <CSVLink
                                data={dataExport}
                                filename={`${storeID}-detail-contest.csv`}
                                className="hidden"
                                ref={(r) => this.csvLink = r}
                                target="_blank"/>
                            {
                                error && <div className="text-danger mt-3">{error}</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContestDetailReportPage
