import React from 'react'
import PayoutApprovalPage from './PayoutApprovalPage'

const PayoutApprovalContainer = (props) => {

    const objPayoutPrams = props.match.params
    const {payout_id = ''} = Object.assign({}, objPayoutPrams)
    
    return (
        <div className='PayoutApprovalContainer'>
            <PayoutApprovalPage payoutID={payout_id}/>
        </div>
    )

}

export default PayoutApprovalContainer
