import React, {Component} from 'react'
import {Input} from 'reactstrap'
import JobTable from './JobTable'
import {getListPipelineGroup} from '../../../../services/api/ClustersServices'
import JobPagination from './JobPagination'
import StageDetails from './StageDetails'
import ResetModal from './ResetModal'
import ContentFile from './ContentFile'

const REFRESH_TIME = 5000

class ListJobjs extends Component {
    state = {
        modal: {
            open: false,
            stage: {},
            pipeline: {},
            job: {}
        },
        modalContentFile: {
            open: false,
            content: ''
        },
        items: [],
        loading: true,
        params: {
            page: 1,
            limit: 10,
        },
        modalReset: {
            openReset: false,
            item: {}
        },
        total: 0,
        totalPage: 1,
        err: '',
    }

    _refresh = null

    componentDidMount() {
        this._getListPipeline()
        this._setRefresh()
    }

    componentWillUnmount() {
        this._refresh && clearInterval(this._refresh)
    }

    _getListPipeline = async (loadingAnimation = true) => {
        try {
            this.setState({
                loading: loadingAnimation,
            })

            const {params} = this.state
            const requestData = {...params}
            const {success, data, message} = await getListPipelineGroup(this.props.groupId, requestData)
            const {total, totalPage, jobs} = Object.assign({}, data)

            if (!success) {
                return this.setState({
                    items: [],
                    err: message,
                    loading: false,
                })
            }

            this.setState({
                items: jobs,
                totalPage: totalPage,
                total: total,
                loading: false,
                err: ''
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _handlePageChange = page => {
        const {params} = this.state
        this.setState(
            {
                params: {...params, page},
            },
            () => this._getListPipeline()
        )
    }

    _setRefresh = () => {
        this._refresh = setInterval(() => {
            this._getListPipeline(false)
        }, REFRESH_TIME)
    }

    _onClickStage = (job, pipeline, stage) => {
        this.setState(({modal}) => ({
            modal: {
                ...modal,
                open: true,
                stage: stage,
                pipeline: pipeline,
                job: job
            }
        }))
    }

    _onCloseModal = () => {
        this.setState(({modal}) => ({
            modal: {
                open: false,
                stage: {},
                pipeline:{},
                job: {}
            }
        }))
    }

    _onClickContentFile = (contentFile) => {
        this.setState(() => ({
            modalContentFile: {
                open: true,
                content: contentFile
            }
        }))
    }

    _onCloseModalContentFile = () => {
        this.setState(() => ({
            modalContentFile: {
                open: false,
                content: ''
            }
        }))
    }

    _toggleReset = (item = {}) => {
        this.setState({
            modalReset: {
                openReset: !this.state.modalReset.openReset,
                item,
            }
        })
    }

    render() {
        const {params: { page, limit }, totalPage, items, loading, err, modalContentFile} = this.state
        const { store } = this.props
        const { job, pipeline, stage, open } = this.state.modal
        const { open: openContentFile, content } = modalContentFile
        const { item, openReset } = this.state.modalReset

        return (
            <div className='AccountsPage'>
                {!store && <h2>Jobs</h2>}
                <div className='AccountsPageInner SectionInner'>
                    {/* {!store && <div className='d-flex align-items-center flex-wrap justify-content-end mb-2'>
                        <div className='SearchBar'>
                            <Input
                                onChange={this._handleInputChange}
                                type='text'
                                className='SearchInput'
                                placeholder='search here'
                            />
                            <i className='fas fa-search SearchIcon' />
                        </div>

                    </div>}
                    {!store && <br/>} */}
                    {!!err && <span className='text-warning'>{err}</span>}
                    <div className='TableContainer table-responsive '>
                        <JobTable items={items} loading={loading} isLights={ store ? false : true } onClickContentFile={this._onClickContentFile} onClickStage={this._onClickStage} toggleReset={this._toggleReset} store={store} />
                        <JobPagination page={page} pages={totalPage} handlePageChange={this._handlePageChange} />
                    </div>
                </div>
                <ContentFile
                    open={openContentFile}
                    onClose={this._onCloseModalContentFile}
                    content={content}
                />
                <StageDetails
                    open={open}
                    pipeline={pipeline}
                    job={job}
                    stage={stage}
                    onClose={this._onCloseModal}
                />
                <ResetModal item={item} open={openReset} toggleReset={this._toggleReset} />
            </div>
        )
    }
}

export default ListJobjs
