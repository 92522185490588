import React from 'react'
import { Link } from "react-router-dom"
import classNames from 'classnames'
import _ from 'lodash'

const STAUS_OERDER_REUND = {
    partial_refund: "Partially refunded",
    refund: "Refunded"
}

const BackNavigationButton = ({ status, order_number, store_slug }) => {

    return (
        <div className={classNames("BackNavigationButton BackWrapper")}>
            <Link className="nav-link BackButton d-flex" to={'/a/order/refund-order'}>
                <i className="fas fa-chevron-left mt-1 mr-2" />
                <div><span className='text-dark m-0 font-weight-bold'>{store_slug} - </span><span className='m-0 text-dark font-weight-bold'>#{order_number}</span> <span className='bg-danger text-white StatusOrderReund'>{_.get(STAUS_OERDER_REUND, status, '')}</span></div>
            </Link>
        </div>
    )
}

export default BackNavigationButton
