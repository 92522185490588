import React from 'react'
import CreateAmazonPage from './CreateAmazonPage'
import {TemplateFormProvider} from '../../states/TemplateFormContext'

const CreateAmazonContainer = () => {
    return (
        <TemplateFormProvider>
            <div className="CreateAmazonContainer container-fluid">
                <CreateAmazonPage/>
            </div>
        </TemplateFormProvider>
    )
}

export default CreateAmazonContainer
