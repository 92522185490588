import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import StoreTransferLogContainer from './StoreTransferLogContainer';

const ModalStoreTransferLog = (props) => {
    const { storeId, modalStoreTransferLog, toggleStoreTransferLog } = props
    return (
        <Modal isOpen={modalStoreTransferLog} toggle={toggleStoreTransferLog} size="xl">
            <ModalHeader toggle={toggleStoreTransferLog}>Store transfer logs</ModalHeader>
            <ModalBody>
                <StoreTransferLogContainer storeId={storeId} />
            </ModalBody>
        </Modal>
    )

}

export default ModalStoreTransferLog