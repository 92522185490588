import { Parser } from "json2csv"
import moment from "moment"
import React, { Component } from "react"
import { Link } from "react-router-dom"
import humanizeTime from "../../../helpers/time/humanizeTime"
import { fetchCashbackHistory, fetchCashbackLog } from "../../../services/api/StoreServices"
import PFPagePagination from "../../shared/PFPagePagination"
import { toaster } from "../../shared/PFToast"
import StatusStyle from "../../shared/StatusStyle"

class CashbackHistoryContainer extends Component {
    state = {
        histories: [],
        page: 1,
        pages: 1,
        limit: 20,
        loading: false,
    }

    componentDidMount() {
        const { page } = this.state
        this._fetchHistory(page)
    }

    _fetchHistory = async (page) => {
        try {
            const params = {
                page,
                limit: this.state.limit,
            }
            this.setState({ loading: true })
            const { success, data, message } = await fetchCashbackHistory(params)
            if (success) {
                this.setState({
                    page: data.page,
                    pages: data.pages,
                    histories: data.historyCashback,
                })
            } else {
                toaster({
                    type: "error",
                    message: message,
                    duration: 5000,
                })
            }
        } catch (error) {
            toaster({
                type: "error",
                message: error.message,
                duration: 5000,
            })
        } finally {
            this.setState({ loading: false })
        }
    }

    _handlePageChange = (page) => {
        this._fetchHistory(page)
    }

    _validateDate = (time) => {
        if (!time) return ""
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }

    _getCashbackFor = (time, separate = "/") => {
        const currentCashback = new Date(time)
        return currentCashback ? `${currentCashback.getMonth() + 1}${separate}${currentCashback.getFullYear()}` : ""
    }

    _downloadCashbackLog = async (id, cashbackFor) => {
        try {
            const res = await fetchCashbackLog(id)
            const url = window.URL.createObjectURL(new Blob([res]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", `log_cashback_${cashbackFor}.csv`)
            document.body.appendChild(link)
            link.click()
        } catch (error) {}
    }

    render() {
        const { histories, page, pages, limit, loading } = this.state

        return (
            <div className="CashbackHistoryContainer">
                <Link to={`/a/stores`}>
                    <i className="fas fa-chevron-left mr-2" />
                    Stores
                </Link>
                <div className="d-flex flex-wrap mb-3">
                    <h2 className="mb-0">Cashback history</h2>
                </div>
                <div className="SectionInner">
                    <div className="CashbackHistoryTable table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Transaction time</th>
                                    <th>Cashback for</th>
                                    <th>Created user</th>
                                    <th>Status</th>
                                    <th>Attach file</th>
                                    <th>Error log</th>
                                </tr>
                            </thead>
                            <tbody>
                                {histories.map((history, index) => (
                                    <tr key={`history_${index}`}>
                                        <td>{(page - 1) * limit + (index + 1)}</td>
                                        <td>{this._validateDate(history.created)}</td>
                                        <td>{this._getCashbackFor(history.cashback_for)}</td>
                                        <td>{history.creator.name}</td>
                                        <td>{<StatusStyle status={history.status} />}</td>
                                        <td>
                                            <a
                                                href={history.file_url}
                                                target="_blank"
                                            >{history.original_filename || `Cashback_${this._getCashbackFor(history.cashback_for, "_")}.csv`}</a>
                                        </td>
                                        <td>
                                            {history.status === "error" && (
                                                <a
                                                    className="cashback-log"
                                                    target="_blank"
                                                    onClick={() =>
                                                        this._downloadCashbackLog(
                                                            history._id,
                                                            this._getCashbackFor(history.cashback_for, "_")
                                                        )
                                                    }
                                                >
                                                    {`Log_cashback_${this._getCashbackFor(
                                                        history.cashback_for,
                                                        "_"
                                                    )}.csv`}
                                                </a>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <PFPagePagination page={page} pages={pages} onChangePage={this._handlePageChange} />
                </div>
            </div>
        )
    }
}

export default CashbackHistoryContainer
