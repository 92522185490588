import React, {Component} from 'react'
import PropTypes from 'prop-types'
import PFTableV2 from '../../shared/components/table/v2'
import {Link} from 'react-router-dom'
import FeatureImage from '../../shared/FeatureImage'
import formatThousandNumber from '../../../helpers/common/formatThousandNumber'
import StatusStyle from '../../shared/StatusStyle'
import ProductListMode from './ProductListMode'
import ModalConfirmRejection from './bulk-actions/ModalConfirmRejection'
import ReactDOM from 'react-dom'
import ModalConfirmApproval from './bulk-actions/ModalConfirmApproval'
import {revisionIsPersonalizedByDesignImage} from '../../../helpers/product_personalize'

class ProductTableList extends Component {
    tableRef = React.createRef()

    _columns = [
        {
            title: 'Store',
            dataIndex: 'store',
            key: 'store',
            render: data => {
                return (
                    <div>
                        <p className='mb-1'>{data.store.domain}</p>
                        <span className='text-muted'>{data.store.slug}</span>
                    </div>
                )
            },
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: data => {
                const featureImage = revisionIsPersonalizedByDesignImage(data.revision)
                    ? data.revision.design_image
                    : data.revision.variant_image

                return (
                    <div className='d-flex align-items-center'>
                        <FeatureImage width={60} src={featureImage || ''} className='border mr-3' />
                        <div className='content'>
                            <p className='mb-0'><Link
                                to={`/a/product-approval/products?ticketID=${data._id}&productsID=${data.revision.product_id}&storeID=${data.store._id}&revisionID=${data.revision._id}`}
                            >
                                {data.revision.title}
                            </Link></p>
                            {
                                data.is_active &&
                                <div className='VerticalBar d-inline-block mr-3'>
                                    <div className='Tooltip3 Top'>
                                        <a
                                            href={data.url} target="_blank" rel="noopener noreferrer"
                                            className="text-secondary">
                                            <i className="fas fa-external-link-alt fs-14"/>
                                        </a>

                                        <div className='TooltipContent'>
                                            <div className='ContentInner' style={{minWidth: 230}}>
                                                View the product on storefront
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                data.retail_price &&
                                <span className='font-weight-bold'>${formatThousandNumber(data.retail_price, 2)}</span>
                            }
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Personalize',
            dataIndex: 'personalize',
            key: 'personalize',
            render: data => {
                const checkDesign_Image = revisionIsPersonalizedByDesignImage(data.revision)
                return <div className={'text-center'}>
                            <i className={checkDesign_Image ? 'fas fa-check text-success' : 'fas fa-times text-danger'}></i>
                        </div>
            }
        },
        {
            title: 'Store mode',
            dataIndex: 'mode',
            key: 'status',
            render: data => {
                return <ProductListMode status={data.store.mode}/>
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: data => {
                return <StatusStyle status={data.approve_status}/>
            },
        },
    ]
    _selections = [
        {
            key: 'bulk_approve',
            text: 'Approve',
            onSelect: async (allPagesSelected, selectedIDs) => {
                let el = document.createElement('div')
                ReactDOM.render(
                    <ModalConfirmApproval
                        selectedIDs={selectedIDs}
                        el={el}
                        tickets={this.props.products}
                        onAfterBulkAction={this._handleAfterBulkAction}
                    />, el,
                )
            },
        },
        {
            key: 'bulk_reject',
            text: 'Reject',
            onSelect: (allPagesSelected, selectedIDs) => {
                let el = document.createElement('div')
                ReactDOM.render(
                    <ModalConfirmRejection
                        selectedIDs={selectedIDs}
                        el={el}
                        tickets={this.props.products}
                        onAfterBulkAction={this._handleAfterBulkAction}
                    />, el)
            },
        },
    ]

    _handleAfterBulkAction = () => {
        this.tableRef.current._clearSelected()

        this.props.onGetProducts()
    }

    render() {
        const {products, loading, totalProduct} = this.props

        return (
            <div className='ProductApprovalTableList table-responsive'>
                <PFTableV2
                    columns={this._columns}
                    data={products}
                    loading={loading}
                    totalItems={totalProduct}
                    ref={this.tableRef}
                    selections={this._selections}
                    allowSelectAllPage={false}
                />
            </div>
        )
    }
}

ProductTableList.propTypes = {
    products: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    totalProduct: PropTypes.number.isRequired,
}

export default ProductTableList
