import React, { Component } from 'react'
import PropTypes from 'prop-types'
import IndexColumn from './columns/IndexColumn'
import StatusColumn from './columns/StatusColumn'
import IPColumn from './columns/IPColumn'
import NameColumn from './columns/NameColumn'
import HostColumn from './columns/HostColumn'
import ProviderColumn from './columns/ProviderColumn'
import CreatedColumn from './columns/CreatedColumn'
import ZoneColumn from './columns/ZoneColumn'
import TotalStoreColumn from './columns/TotalStoreColumn'

class RenderTableRow extends Component {
    render() {
        const { item } = this.props

        return (
            <tr className="RenderTableRow">
                <IndexColumn item={item} />
                <NameColumn item={item} />
                <TotalStoreColumn item={item} />
                <IPColumn item={item} />
                <ZoneColumn item={item} />
                <HostColumn item={item} />
                <ProviderColumn item={item} />
                <StatusColumn item={item} />
                <CreatedColumn item={item} />
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

export default RenderTableRow
