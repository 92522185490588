import React from 'react'

const CatalogPrivateShippingCostFilter = (props) => {
    const {attributes, handleFilterVariants, filter, variants, stores, isAdd, addStore, valueAttributes, handleFilterStores} = props

    const _onFilterVariant = (e) => {
        const {name, value} = e.target
        const newFilter = {...filter}
        if (value) {
            newFilter[name] = value
        } else {
            delete newFilter[name]
        }
        handleFilterVariants(newFilter)
    }

    const _handleFilterStores = (e) => {
        const {value} = e.target
        handleFilterStores(value)
    }

    return (
        <tr className="ProductVariantsRow">
            <td valign="top">
                <select className="form-control" onChange={_handleFilterStores}>
                    <option value="">All</option>
                    {stores.map(itemStore => {
                        const actionType = itemStore?.action_type || ''
                        if(actionType !== 'delete' && itemStore?._id !== 'default_id') {
                            return (
                                <option key={`optionStoreFilter${itemStore._id}`} value={itemStore._id}>
                                    {itemStore.name}
                                </option>
                            )
                        } else {
                            return null
                        }
                    })}
                </select>
                {!isAdd && <div className="mt-2"><a href="" onClick={addStore}>Add store</a></div> }
            </td>
            {attributes.map((attribute) => {
                const {type, name} = attribute
                return (
                    <td valign="top" key={`optionFilterAttribute${type}`}>
                        <select name={name} className="form-control" onChange={_onFilterVariant}>
                            <option value="">All</option>
                            {valueAttributes.filter(item => item.name === name).map((itemV) => {
                                return (
                                    <option key={`optionFilterAttributeValue${itemV.value_code}`} value={itemV.value_code}>
                                        {itemV.value_text}
                                    </option>
                                )
                            })}
                        </select>
                    </td>
                )
            })}
            {variants[0].shipping_prices.map(itemShipping => {
                const {to_zone, ...res} = itemShipping
                return Object.keys(res).map((key) => {
                    return <td key={`shippingFilter${key}`} className="pricingPrefix"></td>
                })
            })}
        </tr>
    )
}

export default CatalogPrivateShippingCostFilter

