import React, {Component} from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'

class ClusterStatus extends Component {
    state = {
        status: this.props.status,
    }

    _handleClickButton = e => {
        e.preventDefault()
        const selectedStatus = e.target.getAttribute("data-status")
        const {onUpdateCluster} = this.props

        this.setState({
            status: selectedStatus,
        }, onUpdateCluster("status", selectedStatus))
    }

    render() {
        const {status} = this.state

        return (
            <div className="ClusterStatus form-group">
                <div className="mb-2">Status</div>

                <button className={className("btn btn-outline-secondary rounded-0", {active: "inactive" === status})}
                        data-status="inactive" onClick={this._handleClickButton}>Inactive
                </button>
                <button className={className("btn btn-outline-secondary rounded-0", {active: "active" === status})}
                        data-status="active" onClick={this._handleClickButton}>Active
                </button>
            </div>
        )
    }
}

ClusterStatus.propTypes = {
    status: PropTypes.string.isRequired,
    onUpdateCluster: PropTypes.func.isRequired,
}

export default ClusterStatus
