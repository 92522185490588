import React, {Component} from 'react'
import ZoneEditPage from './ZoneEditPage'
import {isAdmin, isSuperAdmin} from '../../../../services/AuthServices'

class ZoneEditPageContainer extends Component {
    render() {
        const zoneId = this.props.match.params.zoneId
        const profilesId = this.props.match.params.profilesId
        const isSupperAdmin = isSuperAdmin()
        const roleAdmin = isAdmin()

        if (isSupperAdmin || roleAdmin) return (
            <div className="SettingPage ZoneEditPageContainer">
                <div className="row">
                    <div className="col-md-8 col-12 m-auto">
                        <ZoneEditPage profilesId={profilesId} zoneId={zoneId}/>
                    </div>
                </div>
            </div>
        )
        return null
    }
}

export default ZoneEditPageContainer
