import React, {Component} from 'react'
import PropTypes from 'prop-types'
import SettingInput from '../../setting/shared/SettingInput'

class S3ArtworkBucket extends Component {
    _onChangeSettingInput = (key) => (value) => {
        this.props.onChangeSetting(key, value)
    }

    render() {
        const {settings, disabled} = this.props

        return (
            <div className="S3ArtworkBucket">
                <SettingInput
                    itemId='artwork_s3_bucket' disabled={disabled}
                    label="Artwork S3 Bucket" value={settings.artwork_s3_bucket}
                    onChange={this._onChangeSettingInput('artwork_s3_bucket')}/>
                <SettingInput
                    itemId='artwork_s3_endpoint' disabled={disabled}
                    label="Artwork S3 endpoint" value={settings.artwork_s3_endpoint}
                    onChange={this._onChangeSettingInput('artwork_s3_endpoint')}/>
                <SettingInput
                    itemId='artwork_s3_access_key' disabled={disabled}
                    label="Artwork S3 access key" value={settings.artwork_s3_access_key}
                    onChange={this._onChangeSettingInput('artwork_s3_access_key')}/>
                <SettingInput
                    itemId='artwork_s3_secret_key' disabled={disabled}
                    label="Artwork S3 secret key" value={settings.artwork_s3_secret_key}
                    onChange={this._onChangeSettingInput('artwork_s3_secret_key')}/>
            </div>
        )
    }
}

S3ArtworkBucket.propTypes = {
    settings: PropTypes.object.isRequired,
    onChangeSetting: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
}

export default S3ArtworkBucket
