import React, {Component} from 'react'
import ProductAttributeItem from "./ProductAttributeItem"
import PropTypes from 'prop-types'

class ProductAttributesList extends Component {
    render() {
        const {attributes, ...rest} = this.props
        const isRemoveAttributes = Array.isArray(attributes) && attributes.length > 1

        return (
            <div className="ProductAttributesList">
                <ul>
                    {
                        attributes.map((att, index) => {
                            return (
                                <ProductAttributeItem isRemoveAttributes={isRemoveAttributes} key={index} attribute={att} attributeIndex={index} {...rest}/>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
}

ProductAttributesList.propTypes = {
    attributes: PropTypes.array.isRequired,
}

export default ProductAttributesList
