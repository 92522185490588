import React, {Component} from 'react'
import {Modal, ModalBody, ModalHeader, Spinner} from 'reactstrap'
import PropTypes from 'prop-types'

class PFConfirmModal extends Component {
    state = {
        open: false,
        callback: null,
        loading: false,
        error: '',
        result: null,
    }

    _show = callback => event => {
        event.preventDefault()

        this.setState({
            open: true,
            callback: () => callback(event),
        })
    }

    _hide = () => {
        this.setState({
            open: false,
            callback: null,
            error: '',
            result: null,
            loading: false,
        }, this.props.onAfterClose)
    }

    _confirm = () => {
        this.setState({
            loading: true,
            error: '',
        })

        const doAction = this.state.callback()

        doAction.then(res => {
            const {data, onSuccess, hideAfterSuccess = false} = res

            this.setState({loading: false, result: data, error: ''}, () => {
                if (typeof onSuccess === 'function') onSuccess()
                if (hideAfterSuccess) this._hide()
            })
        }).catch(err => {
            this.setState({loading: false, error: err.message})
        })

        // this._hide()
    }

    render() {
        const {open, loading, error, result} = this.state
        const {title, description, textOk, textCancel, size, centered = false} = this.props
        return (
            <>
                {this.props.children(this._show)}

                <Modal isOpen={open} toggle={this._hide} className='PFConfirmModal' size={size} centered={centered}>
                    <ModalHeader toggle={this._hide}>{title}</ModalHeader>

                    <ModalBody>
                        {
                            typeof description === 'function'
                                ?
                                description()
                                :
                                description
                        }

                        {
                            (result || error) &&
                            <div className='Result mt-3'>
                                <div className='bg-light p-3'>
                                    <h6>Result:</h6>
                                    {
                                        error ?
                                            <div className='text-danger'>{error}</div>
                                            :
                                            <div>{result}</div>
                                    }
                                </div>
                            </div>
                        }

                        <div className='d-flex justify-content-end mt-3'>
                            <button className='btn btn-default'
                                    onClick={this._hide}>{result || error ? 'Close' : textCancel}</button>
                            {
                                (!result && !error) &&
                                <button className='btn btn-primary ml-3'
                                        onClick={this._confirm} disabled={loading}>
                                    {
                                        loading && <Spinner color='white' size='sm' className='mr-2'/>
                                    }
                                    {textOk}
                                </button>
                            }
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

PFConfirmModal.defaultProps = {
    hideAfterSuccess: false,
    textOk: 'OK',
    textCancel: 'Close',
    size: ''
}

PFConfirmModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.any,
    hideAfterSuccess: PropTypes.bool,
    onAfterClose: PropTypes.func,
    textOk: PropTypes.string,
    textCancel: PropTypes.string,
    size: PropTypes.string,
    valueButton: PropTypes.string,
}

export default PFConfirmModal
