import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ImageColumn extends Component {
    render() {
        const {item} = this.props
        const {images} = item
        const image = (Array.isArray(images) && images.length > 0) && images[images.length - 1]
        return (
            <td className='ImageColumn'>
                <img className='ProductLineImageView border' src={image} alt={image} />
            </td>
        )
    }
}

ImageColumn.propTypes = {
    item: PropTypes.object.isRequired,
}
ImageColumn.defaultProps = {
    image: '',
}
ImageColumn.propTypes = {
    image: PropTypes.string.isRequired,
}
export default ImageColumn
