import React, {useRef, useState} from 'react'
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import {createStoreBatchesAction} from '../../../../../services/api/StoreServices'
import {toaster} from '../../../../shared/PFToast'
import StoreActionModal from './StoreActionModal'

const dropDownOptions = [
    {
        action: 'move_database',
        label: 'Move database'
    },
    {
        action: 'rollback_database',
        label: 'Rollback moved database'
    },
    {
        action: 'delete_old_database',
        label: 'Delete old moved database'
    },
    {
        action: 'backup_database',
        label: 'Backup & drop database'
    },
    {
        action: 'restore_database',
        label: 'Restore database'
    },
    {
        action: 'activate_store',
        label: 'Activate store'
    },
    {
        action: 'suspend_store',
        label: 'Suspend store'
    }
]

const StoreActionButton = () => {
    const [isDropdownOpen, setDropdownOpen] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false)
    const [uploadType, setUploadType] = useState({})
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState('')
    const inputRef = useRef()

    const toggleDropdown = () => setDropdownOpen(!isDropdownOpen)

    const handleToggleModal = ({action, label}) => {
        setUploadType((prev) => ({...prev, action, label}))
        setModalOpen(!isModalOpen)
    }

    const onChangeFile = async (event) => {
        setFile(event.target.files[0])
        setFileName(event.target.files[0].name)
    }

    const onConfirmFile = async () => {
        const {action} = uploadType
        try {
            const formData = new FormData()
            formData.append('batches_data', file)
            formData.append('type', action)

            const {success, message, data} = await createStoreBatchesAction(formData)
            if (!success) {
                return toaster({
                    type: 'error',
                    message
                })
            }

            if (['backup_database', 'restore_database'].includes(action)) {
                toaster({
                    type: 'success',
                    className: 'FullWidthToast',
                    message: (
                        <div>
                            <p>Create batch job with type {action} successfully!</p>
                            <pre>info:<code>{JSON.stringify(data, null, 2)}</code></pre>
                        </div>
                    ),
                    duration: 10000
                })

            } else {
                toaster({
                    type: 'success',
                    message: `Create batch job with type ${action} successfully!`
                })
            }

            clear()
        } catch (e) {
            toaster({
                type: 'error',
                message: `Create batch job with type ${action} error: ${e}!`
            })
        }
    }

    const onUploadFile = () => {
        inputRef.current.click()
        inputRef.current.value = ''
    }

    const clear = () => {
        setFile(null)
        setFileName('')
        setModalOpen(!isModalOpen)
        inputRef.current.value = ''
    }

    return (
        <div className="ActionsContainer mr-3">
            <Dropdown color="default" isOpen={isDropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret>Batch Actions</DropdownToggle>
                <DropdownMenu>
                    {dropDownOptions.map((item) => {
                        const {action, label} = item
                        return (
                            <DropdownItem key={action} onClick={() => handleToggleModal({action, label})}>
                                {label}
                            </DropdownItem>
                        )
                    })}
                </DropdownMenu>
                <input type="file" accept=".csv" onChange={onChangeFile} hidden ref={inputRef} />
            </Dropdown>
            <StoreActionModal
                isOpen={isModalOpen}
                header={uploadType.label}
                fileName={fileName}
                toggle={handleToggleModal}
                onConfirmFile={onConfirmFile}
                onUploadFile={onUploadFile}
            />
        </div>
    )
}
// onSelectFile={() => onSelectFile(uploadType)}

export default StoreActionButton
