import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/shipping' : `${process.env.REACT_APP_BASE_URL}/api/shipping/`
const api = createAPIServices({baseUrl})

export const getSettingProfilesShipping = (params) => {
    return api.makeAuthRequest({
        url: '/profiles',
        method: 'GET',
        params,
    })
}

export const createSettingProfilesShipping = (formatName, skus) => {
    return api.makeAuthRequest({
        url: '/profiles',
        method: 'POST',
        data: {
            name: formatName,
            skus: skus,
        },
    })
}

export const deleteSettingProfilesShipping = (Id) => {
    return api.makeAuthRequest({
        url: `/profiles/${Id}`,
        method: 'DELETE',
    })
}

export const getSettingProfilesShippingDetail = (Id) => {
    return api.makeAuthRequest({
        url: `/profiles/${Id}`,
        method: 'GET',
    })
}

export const updateSettingProfilesShipping = (formatName, Id) => {
    return api.makeAuthRequest({
        url: `/profiles/${Id}`,
        method: 'PUT',
        data: {
            name: formatName,
        },
    })
}

export const getSearchProductLine = (params) => {
    return api.makeAuthRequest({
        url: '/products/get-sku-prefixes',
        method: 'POST',
        data: params,
    })
}

export const createShippingZone = (zone) => {
    return api.makeAuthRequest({
        url: '/shipping-zones',
        method: 'POST',
        data: zone,
    })
}

export const getShippingZoneDetails = (zoneId) => {
    return api.makeAuthRequest({
        url: `/shipping-zones/${zoneId}`,
        method: 'GET',
    })
}

export const updateShippingZone = (zoneId, data) => {
    return api.makeAuthRequest({
        url: `/shipping-zones/${zoneId}`,
        method: 'POST',
        data: data,
    })
}

export const deleteShippingZone = (zoneId) => {
    return api.makeAuthRequest({
        url: `/shipping-zones/${zoneId}`,
        method: 'DELETE',
    })
}

export const getShippingZoneCountries = (data) => {
    return api.makeAuthRequest({
        url: '/shipping-zones/countries/search',
        method: 'POST',
        data: {
            profile: data,
        },
    })
}

export const createShippingRate = ({zoneId, data}) => {
    return api.makeAuthRequest({
        url: `/shipping-zones/${zoneId}/rates`,
        method: 'POST',
        data: data,
    })
}

export const getShippingRateDetails = (zoneId, rateId) => {
    return api.makeAuthRequest({
        url: `/shipping-zones/${zoneId}/rates/${rateId}`,
        method: 'GET',
    })
}

export const updateShippingRate = (zoneId, rateId, data) => {
    return api.makeAuthRequest({
        url: `/shipping-zones/${zoneId}/rates/${rateId}`,
        method: 'POST',
        data: data,
    })
}

export const deleteShippingRate = (zoneId, rateId) => {
    return api.makeAuthRequest({
        url: `/shipping-zones/${zoneId}/rates/${rateId}`,
        method: 'DELETE',
    })
}


export const deleteProductLineShipping = (profileId, sku) => {
    return api.makeAuthRequest({
        url: `/profile/${profileId}/product-lines/${sku}`,
        method: 'DELETE',
    })
}

export const updateProductLineShipping = (selected, id) => {

    return api.makeAuthRequest({
        url: '/product-lines',
        method: 'POST',
        data: {
            skus: selected,
            profile: id,
        },
    })
}
