import React from 'react'

const FulfillmentProductTypeColumn = (props) => {
    const {item} = props
    const {ffm_mapping_product_types = []} = item

    return (
        <td className="FulfillmentProductTypeColumn d-flex align-items-center flex-wrap">
            {ffm_mapping_product_types.length
                ? ffm_mapping_product_types.map((type) => <div className="badge px-2 mr-2 mb-2">{type}</div>)
                : '-'}
        </td>
    )
}

export default FulfillmentProductTypeColumn

