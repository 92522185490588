import createApiServices from './createApiServices'

const createAPIRequest = () => {
    const currentStore = Object.assign({}, window.currentStore)
    if (!currentStore) throw new Error(`Can't get current store`)
    const {backoffice_api_url} = currentStore
    const baseUrl = backoffice_api_url + '/product'
    return createApiServices({baseUrl})
}

export const getProduct = (productID, revisionId) => {
    const api = createAPIRequest(window.currentStore)
    return api.makeAuthRequest({
        url: `/products/${productID}/revisions/${revisionId}/details`,
        method: 'GET'
    })
}

export const searchVariantProductRevision = (productID, revisionId, payload = {}) => {
    const api = createAPIRequest(window.currentStore)
    return api.makeAuthRequest({
        url: `/products/${productID}/revisions/${revisionId}/variants/search`,
        method: 'POST',
        data: payload
    })
}


export const getArtWorks = (productID, revisionId) => {
    const api = createAPIRequest(window.currentStore)
    return api.makeAuthRequest({
        url: `/products/${productID}/revisions/${revisionId}/artworks`,
        method: 'GET'
    })
}

export const getProductGallery = (productId, revisionId) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/revisions/${revisionId}/gallery`,
        method: 'GET'
    })
}

export const searchVariants = (productId, optionSlugs, queryOption) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/variants/search`,
        method: 'POST',
        data: {
            options: optionSlugs,
            ...queryOption
        }
    })
}

export const changTakeDownProduct = (productId, isTakenDown) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}`,
        method: 'PATCH',
        data: {
            is_taken_down: !isTakenDown
        }
    })
}

export const getVariantImagesProductRevision = (productId, revisionId, variantId) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/revisions/${revisionId}/variants/${variantId}/images`,
        method: 'GET'
    })
}
