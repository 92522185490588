import createApiServices from './createApiServices'

const createAPIRequest = () => {
    const currentStore = Object.assign({}, window.currentStore)
    if (!currentStore) throw new Error(`Can't get current store`)
    const { backoffice_api_url } = currentStore
    const baseUrl = backoffice_api_url + '/product'
    return createApiServices({ baseUrl })
}

export const changeVariants = (productId, variants) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/variants`,
        method: 'POST',
        data: {
            variants,
        },
    })
}

export const searchVariants = (productId, optionSlugs, queryOption) => {
    const api = createAPIRequest()
    return api.makeAuthRequest({
        url: `/products/${productId}/variants/search`,
        method: 'POST',
        data: {
            options: optionSlugs,
            ...queryOption,
        }
    })
}
