import React, {Component, createRef} from 'react'
import PropTypes from 'prop-types'
import DateCustomFilter from './DateCustomFilter'
import PubSub from '../shared/brokers/FilterBroker'
import pluralize from '../../helpers/common/pluralize'
class TableFilter extends Component {
    constructor(props) {
        super(props)
        const {defaultLimit, searchValue, searchFieldSelected, searchField} = this.props

        this.state = {
            entityPerPage: defaultLimit,
            search: searchValue || '',
            searchField: searchField.length ? (searchFieldSelected ? searchFieldSelected : searchField[0]) : null,
        }

        this.timer = null
        this._input = createRef()
        this._subscription = null
    }

    componentDidMount() {
        this._subscription = PubSub.subscribe('RESET_FILTER', () => {
            this.setState({
                search: ''
            })
        })
    }

    componentWillUnmount() {
        this._subscription = null
    }

    _onChangeSelectEntity = (e) => {
        const {value} = e.target

        this.setState(
            {
                entityPerPage: value,
            },
            () =>
                this.props.onSubmitForm({
                    limit: value,
                    value: this.state.search,
                    field: this.state.searchField,
                })
        )
    }

    _onChangeInputFilter = (e) => {
        const {value} = e.target
        this.setState(
            {
                search: value,
            },
            () => {
                if (this.timer) clearTimeout(this.timer)
                this.timer = setTimeout(this._submitForm, 350)
            }
        )
    }

    _onChangeSelectSearchField = (e) => {
        const {value} = e.target
        this._input.focus()
        this.setState(
            {
                searchField: value,
                search: '',
            },
            () => {
                if (this.timer) clearTimeout(this.timer)
                this.timer = setTimeout(this._submitForm, 350)
            }
        )
    }

    _submitForm = () => {
        const {entityPerPage, search, searchField} = this.state

        this.props.onSubmitForm({
            limit: entityPerPage,
            value: search,
            field: searchField,
        })
    }

    _onSubmitForm = (e) => {
        e.preventDefault()
        if (this.timer) clearTimeout(this.timer)

        this._submitForm()
    }

    render() {
        const {
            placeholder,
            searchField,
            placeholders,
            placeholderDateFilter,
            dateFilterOn,
            handleDateChange,
            query,
            customInput,
            defaultLimit,
            total,
            searchFieldSelected,
        } = this.props

        const {search} = this.state
        const parseSelectedField = searchField.length ? (searchFieldSelected ? searchFieldSelected : searchField[0]) : null

        return (
            <div className="TableFilter row">
                <div className={`TableLength col ${customInput ? 'TableLengthCustom' : ''}`}>
                    Show
                    <select value={defaultLimit} onChange={this._onChangeSelectEntity}>
                        <option value="10">10 rows</option>
                        <option value="20">20 rows</option>
                        <option value="50">50 rows</option>
                        <option value="100">100 rows</option>
                    </select>{' '}
                    entries
                </div>

                <div className="Filter col">
                    <form className="input-group d-flex flex-nowrap align-items-center justify-content-end" onSubmit={this._onSubmitForm}>
                        {dateFilterOn ? (
                            <div className="date-filter-group col ">
                                <DateCustomFilter
                                    query={query}
                                    placeholderDateFilter={placeholderDateFilter}
                                    onChange={handleDateChange}
                                />
                            </div>
                        ) : null}

                        <div className="input-group-prepend SelectWrapper">
                            {searchField.length > 0 ? (
                                <select value={parseSelectedField} onChange={this._onChangeSelectSearchField}>
                                    {searchField.map((item, i) => (
                                        <option key={i} value={item}>
                                            {item}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <span className="input-group-text">
                                    <i className="ti ti-search" />
                                </span>
                            )}
                        </div>

                        <div className={`InputWrapper position-relative mr-3 ${customInput ? 'InputWrapperCustom' : ''}`}>
                            <i className="ti-search position-absolute d-none" />
                            <input
                                value={search}
                                onChange={this._onChangeInputFilter}
                                className="form-control InputSearch"
                                placeholder={searchField.length ? placeholders[parseSelectedField] : placeholder}
                                ref={(input) => (this._input = input)}
                            />
                        </div>

                        {
                            total ? <div className="Total font-weight-bold">{pluralize(total, 'item', 's')}</div> : null
                        }

                    </form>
                </div>
            </div>
        )
    }
}

TableFilter.defaultProps = {
    defaultLimit: 10,
    placeholder: '',
    searchField: [],
    placeholders: {},
}

TableFilter.propsTypes = {
    total: PropTypes.number.isRequired,
    defaultLimit: PropTypes.number,
    onSubmitForm: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    placeholders: PropTypes.object,
    searchField: PropTypes.array,
    collectionId: PropTypes.string.isRequired,
}

export default TableFilter

