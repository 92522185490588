import React, {Component} from 'react'
import PropTypes from 'prop-types'

class TransactionOutNote extends Component {
    render() {
        const {note} = this.props

        return (
            <td className="TransactionOutNote">
                {note}
            </td>
        )
    }
}

TransactionOutNote.propTypes = {
    note: PropTypes.string.isRequired,
}

export default TransactionOutNote
