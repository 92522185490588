import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import SelectedCountriesList from "./SelectedCountriesList"

class SelectedCountriesContainer extends Component {
    render() {
        return (
            <div className="SelectedCountriesContainer">
                <SelectedCountriesList {...this.props}/>
            </div>
        )
    }
}

// SelectedCountriesContainer.defaultProps = {}

// SelectedCountriesContainer.propTypes = {}

export default SelectedCountriesContainer
