import React, {Component} from 'react'
import PropTypes from 'prop-types'

class CommitSHAColumn extends Component {
    render() {
        const {item} = this.props
        const {commit_sha} = item
        return (
            <td className='CommitSHAColumn'>
                <span>{commit_sha}</span>
            </td>
        )
    }
}

CommitSHAColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default CommitSHAColumn
