import React, {Component} from 'react'
import ZoneName from './details/ZoneName'
import ZoneCountriesContainer from './countries/ZoneCountriesContainer'
import ZoneActionsContainer from './actions/ZoneActionsContainer'
import {createShippingZone, getSettingProfilesShippingDetail} from '../../../../services/api/ShippingSettingsServices'
import getHistory from '../../../../store/getHistory'
import PropTypes from 'prop-types'
import {toaster} from '../../../shared/PFToast'
import ReturnPage from '../../../shared/ReturnPage'

class ZoneNewPage extends Component {
    state = {
        loading: false,
        error: '',
        nameProfile: ''
    }

    _zoneData = {}

    componentDidMount() {
        this._getShippingProfilesDetail()
    }

    _getShippingProfilesDetail = async () => {
        const {profilesId} = this.props

        try {
            const {data, message, success} = await getSettingProfilesShippingDetail(profilesId)

            if (!success) throw new Error(message)

            return this.setState({
                nameProfile: data.name || ''
            })

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            toaster({message: message ? message : e.message, type: 'error', duration: 5000})
        }
    }

    _updateZoneData = (field, data) => {
        this._zoneData[field] = data
    }

    _handleSubmitForm = e => {
        e.preventDefault()

        this._createShippingZone()
    }

    _createShippingZone = async () => {
        const {profilesId} = this.props
        this.setState({
            loading: true
        })

        this._zoneData['profile'] = profilesId

        try {
            const {data, success, message} = await createShippingZone(this._zoneData)

            if (!success) {
                return this.setState({
                    error: message,
                    loading: false
                })
            }

            this.setState({
                error: '',
                loading: false
            })

            const newShippingZone = Object.assign({}, data)
            const history = getHistory()
            history.push(`/a/settings/settings-shipping/${profilesId}/zones-edit/${newShippingZone._id}`)

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            return this.setState({
                error: message ? message : e.message,
                loading: false
            })
        }
    }

    render() {
        const {nameProfile} = this.state
        const {profilesId} = this.props

        return (
            <div className="ZoneNewPage">
                <ReturnPage url={`/a/settings-shipping/profiles-edit/${profilesId}`}
                            title={`Profile: ${nameProfile}`}/>
                <form onSubmit={this._handleSubmitForm}>
                    <h1 className="PageTitle">Add shipping zone</h1>

                    <div className="mb-3">
                        <ZoneName onUpdateZoneData={this._updateZoneData}/>
                    </div>

                    <div className="mb-3">
                        <ZoneCountriesContainer profilesId={profilesId} onUpdateZoneData={this._updateZoneData}/>
                    </div>

                    <ZoneActionsContainer loading={this.state.loading} error={this.state.error}/>
                </form>
            </div>
        )
    }
}

ZoneNewPage.propTypes = {
    profilesId: PropTypes.string.isRequired
}

export default ZoneNewPage
