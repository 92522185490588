import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import NumberFormat from 'react-number-format'

const InputNumberFormat = (props) => {
    const {value, error, name, label, placeholder} = props

    const handleChange = ({floatValue}) => {
        props.onChange(floatValue, name)
    }

    return (
        <Fragment>
            <div>
                <div className='d-flex align-items-center'>
                {
                    label && <label className="mr-2 mb-0">{label}:</label>
                }
                <NumberFormat
                    allowNegative={false}
                    value={value}
                    id={name}
                    placeholder={placeholder}
                    className={classNames('form-control', {'is-invalid': error})}
                    decimalScale={2}
                    onValueChange={handleChange}
                />
                </div>
                {
                    error &&
                    <div className="invalid-feedback d-block">
                        {error}
                    </div>
                }
            </div>

        </Fragment>
    )
}

InputNumberFormat.defaultProps = {
    name: '',
    value: '',
    error: '',
    placeholder: ''
}

InputNumberFormat.propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string
}

export default InputNumberFormat
