import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {updateCategory} from '../../../services/api/CategoriesServices'
import * as Yup from 'yup'
import {ErrorMessage, Field, Form, Formik} from 'formik'

const _parseCategory = ({title}) => {
    const category = {}

    const _title = (title && title.trim()) || ''

    if (title) {
        category.title = _title
    }

    return category
}

class PlanModal extends Component {
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (!prevProps.open && this.props.open) this.setState({
    //         category: {...this.props.category},
    //     })
    // }

    _saveCategory = async (category) => {
        const {category: {_id}} = this.props
        const vCategory = _parseCategory(category)

        try {
            const {success, message} = await updateCategory(_id, vCategory)

            if (success) {
                return this.props.onEditCategory({_id, ...vCategory})
            }

            alert(message)
        } catch (e) {
            alert(e.message || e)
        }
    }

    _onToggle = () => {
        this.props.onClose()
    }

    render() {
        const {open, category} = this.props
        const {title} = category

        return (
            <Modal size='lg' isOpen={open} toggle={this._onToggle} className="EditModal">
                <Formik
                    initialValues={{
                        title: title,
                    }}
                    validationSchema={Yup.object().shape({
                        title: Yup.string().trim().required('Title is required'),
                    })}
                    onSubmit={async (fields, {setSubmitting}) => {
                        const payload = {
                            ...fields
                        }
                        await this._saveCategory(payload)
                        setSubmitting(false)
                    }}
                    render={({errors, touched, isSubmitting}) => {

                        return (
                            <Form>
                                <ModalHeader toggle={this._onToggle}>Plan</ModalHeader>
                                <ModalBody>
                                    <div className='form-group'>
                                        <span>Title</span>
                                        <Field
                                            name='title'
                                            type='text'
                                            className={`form-control${errors.title && touched.title ? ' is-invalid' : ''}`}
                                        />
                                        <ErrorMessage name='title' component='div' className='invalid-feedback'/>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <button className="btn btn-primary" type='submit' disabled={isSubmitting || Object.keys(errors).length}>Save
                                    </button>
                                </ModalFooter>
                            </Form>
                        )
                    }}
                />
            </Modal>
        )
    }
}

PlanModal.propTypes = {
    open: PropTypes.bool.isRequired,
    category: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onEditCategory: PropTypes.func.isRequired,
}

export default PlanModal
