import createAPIServices from './createApiServices'

const baseUrl = '/'

const api = createAPIServices({baseUrl})

export const makeRequest = (args) => {
    const vArgs = Object.assign({}, args)

    return api.makeAuthRequest(vArgs)
}
