import React, {Component} from 'react'
import ResourceDetailPage from './ResourceDetailPage'

class ResourceDetailContainer extends Component {
    _getResourceId = () => {
        const {match} = this.props
        const {id} = Object.assign({}, match.params)

        return id
    }

    render() {
        const resourceId = this._getResourceId()
        return (
            <div className='StoreDetailPageContainer'>
                <ResourceDetailPage resourceId={resourceId} />
            </div>
        )
    }
}

export default ResourceDetailContainer
