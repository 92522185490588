import React from 'react'
import EditAmazonPage from './EditAmazonPage'
import {TemplateFormProvider} from '../../states/TemplateFormContext'

const EditAmazonContainer = (props) => {
    const templateId = props.match.params.id
    return (
        <TemplateFormProvider>
            <div className="EditAmazonContainer container-fluid">
                <EditAmazonPage templateId={templateId}/>
            </div>
        </TemplateFormProvider>
    )
}

export default EditAmazonContainer
