import React, {Component} from 'react'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import PropTypes from "prop-types";

class TableContainer extends Component {
    render() {
        return (
            <div className='TableContainer table-responsive '>

                <table className='table table-striped table-light'>
                    <TableHeader/>
                    <TableBody {...this.props} />
                </table>


            </div>
        )
    }
}

TableContainer.propTypes = {
    items: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default TableContainer
