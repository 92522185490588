import React, {Component} from 'react'
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types'
import {getListStores} from "../../../../services/api/StoreServices"

class InputSearchStore extends Component {

    state = {
        filter: 'domain'
    }

    _handleInputChange = options => {

        const valueSelect = !options ? '' : options.value

        const {filter} = this.state

        if (filter === 'domain') {
            return (
                this.props.onChange('store_domain', valueSelect),
                    this.props.onChange('store_slug', '')
            )
        }
        if (filter === 'slug') {
            return (
                this.props.onChange('store_slug', valueSelect),
                    this.props.onChange('store_domain', '')
            )
        }
        this.props.onChange('store_domain', valueSelect)
    }

    _timer = null

    _fetchListStores = async (storeName) => {
        const {filter} = this.state
        try {
            const params = {
                limit: 10,
                [filter]: storeName,
            }

            const {data, success, message} = await getListStores(params)

            if (!success) throw new Error(message)

            const {stores} = data

            return stores.map(store => {
                return {
                    value: (filter === 'domain' && store.domain) || (filter === 'slug' && store.slug),
                    label: store.slug + '--' + store.domain,
                }
            })

        } catch (e) {
            this.setState({
                error: e.message || e,
                loadingGetStore: false
            })
        }
    }

    _handleLoadOptions = (inputValue) => {
        if (this._timer) clearTimeout(this._timer)

        return new Promise(resolve => {
            this._timer = setTimeout(() => {
                resolve(this._fetchListStores(inputValue))
            }, 500)
        })
    }

    _onChangeSelect = (e) => {
        const {value} = e.target
        this.setState({
            filter: value
        })
    }


    render() {
        const {filter} = this.state

        return (
            <div className='InputStore flex-wrap d-flex align-items-center InputGroup mr-4 m-0'>
                <label className='mr-2 m-0'>Store: </label>
                <div className='SelectInputStore d-flex align-items-center border rounded'>
                    <select
                        value={filter}
                        className='border-0'
                        onChange={this._onChangeSelect}
                    >
                        <option value='slug'>Store ID</option>
                        <option value='domain'>Domain</option>

                    </select>
                    <AsyncSelect isClearable noOptionsMessage={() => null} loadOptions={this._handleLoadOptions}
                                 onChange={this._handleInputChange}/>
                </div>
            </div>
        )
    }
}

InputSearchStore.propTypes = {
    onChange: PropTypes.func.isRequired
}


export default InputSearchStore
