import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getSearchProductLine} from '../../../../../services/api/ShippingSettingsServices'
import ListSelectedItemProductLine from '../product-line/ListSelectedItemProductLine'
import ManagerListItemProductLine from '../product-line/ManagerListItemProductLine'

class SettingSearchProductLine extends Component {

    state = {
        title: '',
        type: '',
        sku_prefix: '',
        error: '',
        productLines: [],
        productLineSelected: this.props.skus || [],
        page: 1,
        pages: 1
    }

    componentDidMount() {
        this._fetchListProductLine()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.skus) !== JSON.stringify(this.props.skus)) {
            this.setState({
                productLineSelected: this.props.skus
            })
        }
    }

    _fetchListProductLine = async () => {
        const {title, type, page, sku_prefix} = this.state

        const params = {
            limit: 10,
            title: title,
            type: type,
            page: page,
            sku_prefix
        }

        try {
            const {data, success, message} = await getSearchProductLine(params)

            if (!success) throw new Error(message)

            const {productLines, page, pages} = data

            this.setState({
                productLines: productLines || [],
                page: page,
                pages: pages
            })

        } catch (e) {
            const response = e.response || {}
            const {data = {}} = response
            const {message = ''} = data
            this.setState({
                error: message ? message : e.message
            })
        }
    }

    _handleChangeInput = (key, value) => {
        if (key === 'title') {
            this.setState({
                type: '',
                sku_prefix: ''
            })
        }
        if (key === 'type') {
            this.setState({
                title: '',
                sku_prefix: ''
            })
        }
        if (key === 'sku_prefix') {
            this.setState({
                title: '',
                type: ''
            })
        }
        this.setState({
            [key]: value,
            page: 1
        }, () => this._fetchListProductLine())
    }

    _handleChangePage = (page) => {
        this.setState({
            page: page
        }, () => this._fetchListProductLine())
    }

    _changeProductLineSelected = (params) => {
        this.setState({
            productLineSelected: params
        }, () => this.props.onChange())
    }


    render() {
        const {productLines, error, productLineSelected, page, pages} = this.state
        const {profilesId, skus} = this.props

        return (
            <div className="ProductLinesShipping mb-3 p-3 bg-light rounded">

                <div className="d-flex align-items-center justify-content-between">
                    <h6 className="ItemProductLineTitle m-0">Product line</h6>
                    {
                        error ?
                            <p className="text-danger">{error}</p>
                            :

                            <ManagerListItemProductLine productLineSelected={productLineSelected}
                                                        page={page}
                                                        profilesId={profilesId}
                                                        pages={pages}
                                                        skus={skus}
                                                        onFetchListProductLine={this._fetchListProductLine}
                                                        onChangeProductLineSelected={this._changeProductLineSelected}
                                                        onHandleChangeInput={this._handleChangeInput}
                                                        onChangePage={this._handleChangePage}
                                                        productLines={productLines}/>
                    }
                </div>


                <ListSelectedItemProductLine profilesId={profilesId}
                                             onChangeProductLineSelected={this._changeProductLineSelected}
                                             productLineSelected={productLineSelected}/>

            </div>
        )
    }
}

SettingSearchProductLine.defaultProps = {
    skus: []
}

SettingSearchProductLine.propTypes = {
    onChange: PropTypes.func.isRequired,
    skus: PropTypes.array.isRequired,
    profilesId: PropTypes.string.isRequired
}


export default SettingSearchProductLine
