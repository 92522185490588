import React, {Component} from 'react'
import StoreTableHeader from './StoreTableHeader'
import StoreTableBody from './StoreTableBody'
import ModeFilterSelect from './filters/ModeFilterSelect'
import PlanFilterSelect from './filters/PlanFilterSelect'
import StorefrontStatusFilterSelect from './filters/StorefrontStatusFilterSelect'
import ResourceKindFilterSelect from './filters/ResourceKindFilterSelect'
import ResourceFilterSelect from './filters/ResourceFilterSelect'
import StatusFilterSelect from './filters/StatusFilterSelect'

class StoreTableContainer extends Component {
    render() {
        const {items, params, handleChangeFilter, handleChangeResourceKind, bulkUnLinkStore, listStoresSelected} = this.props
        const {
            planId,
            mode,
            disable_storefront,
            resourceId,
            resource_kind,
            status
        } = params
        const noResult = items.length === 0 || typeof items === "undefined"

        return (
            <div className='TableContainer table-responsive '>
                <div className="Filter d-flex align-items-end justify-content-between mb-3">
                    <div className="FilterAttributes d-flex flex-wrap">
                        <StatusFilterSelect onChange={handleChangeFilter} value={status || ''} />
                        <ModeFilterSelect onChange={handleChangeFilter} value={mode || ''} />
                        <PlanFilterSelect onChange={handleChangeFilter} value={planId || ''} />
                        <StorefrontStatusFilterSelect
                            onChange={handleChangeFilter}
                            value={disable_storefront || ''}
                        />
                        <ResourceKindFilterSelect
                            onChange={handleChangeResourceKind}
                            value={resource_kind || ''}
                        />
                        <ResourceFilterSelect
                            onChange={handleChangeFilter}
                            value={resourceId || ''}
                            resourceKind={resource_kind}
                        />
                    </div>
                    <div>
                        <button className='btn btn-primary btn-sm' disabled={listStoresSelected.length === 0} onClick={bulkUnLinkStore}>Unlink stores</button>
                    </div>
                </div>
                <table className='table'>
                    <StoreTableHeader
                        {...this.props}
                    />
                    <StoreTableBody {...this.props} />
                </table>
                {noResult === true && (
                    <div className='EmptyTable d-flex justify-content-center'>
                        <span className='text-muted'>No results</span>
                    </div>
                )}
            </div>
        )
    }
}

export default StoreTableContainer
