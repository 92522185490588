import React, { useEffect, useState } from 'react'

const JobSelection = (props) => {
    const { selected, setSelected, job } = props
    const [checked, setChecked] = useState(false)

    useEffect(() => {
        setChecked(checkSelect())
    }, [selected])

    const checkSelect = () => {
        return selected.includes(job?._id)
    }

    const handleChange = (e) => {
        const { value } = e.target
        const select = checkSelect()
        if (!select) {
            setSelected([...selected, value])
        } else {
            setSelected(selected.filter(s => s !== value))
        }
    }

    return (
        <td className='CollectionColSelection'>
            <div className='custom-control custom-checkbox'>
                <input type='checkbox' className='custom-control-input'
                    id={`CollectionColSelection_${job?._id}`} checked={checked}
                    value={job?._id}
                    onChange={(e) => handleChange(e)} />
                <label htmlFor={`CollectionColSelection_${job?._id}`} className='custom-control-label' />
            </div>
        </td>
    )
}

export default JobSelection