import {useEffect, useState} from 'react'
import {
    getCurrentStore,
    getCurrentStoreSettings,
    getStoreRoles,
    getStoreScopes, subscribeChangeStore, unsubscribeChangeStore,
} from '../../services/CurrentStoreService'

function useStoreSettings() {
    const [state, setState] = useState({
        storeId: getCurrentStore(),
        settings: getCurrentStoreSettings(),
        scopes: getStoreScopes(),
        roles: getStoreRoles(),
    })

    const _getSetting = (key, defaultValue = null) => {
        const {settings} = state

        if (!settings || !Array.isArray(settings)) return defaultValue

        const setting = settings.find(setting => setting.key === key)
        if (!setting) return defaultValue

        const {value} = Object.assign({}, setting)

        return value || defaultValue
    }

    useEffect(() => {
        const _token = subscribeChangeStore(setState({
            storeId: getCurrentStore(),
            settings: getCurrentStoreSettings(),
            scopes: getStoreScopes(),
            roles: getStoreRoles(),
        }))

        return unsubscribeChangeStore(_token)
    }, [])

    return {
        ...state,
        getSetting: _getSetting,
    }
}

export default useStoreSettings
