import React, {Component} from 'react'
import AffiliateTableHead from './table/AffiliateTableHead'
import AffiliateTableBody from './table/AffiliateTableBody'
import PropTypes from "prop-types"
import PFTable from '../../shared/PFTable'

class AffiliateTable extends Component {

    render() {
        const {affiliateCategory} = this.props
        return (
            <div className="AffiliateTable table-responsive mt-3">
                {
                    <PFTable source={affiliateCategory}>
                        {
                            table => (
                                <>
                                    <AffiliateTableHead {...table} {...this.props}/>
                                    <AffiliateTableBody {...table} {...this.props}/>
                                </>
                            )
                        }
                    </PFTable>
                }
            </div>
        )
    }
}

AffiliateTable.propTypes = {
    affiliateCategory: PropTypes.array.isRequired,
}

export default AffiliateTable
