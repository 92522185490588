import React, {Component} from 'react'
import TransactionOutCreationModal from './TransactionOutCreationModal'
import PropTypes from "prop-types"

class TransactionOutCreationContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false
        }
    }

    _handleToggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }


    render() {
        const {isOpen} = this.state

        return (
            <div className="TransactionOutCreation">
                <div>
                    <span className="text-primary cursor-pointer"
                          onClick={this._handleToggleModal}>Create transaction</span>
                </div>

                {isOpen && (
                    <TransactionOutCreationModal isOpen={isOpen} onToggleModal={this._handleToggleModal} store={this.props.store}/>
                )}
            </div>
        )
    }

}

TransactionOutCreationContainer.propTypes = {
    store: PropTypes.object.isRequired,
}

export default TransactionOutCreationContainer