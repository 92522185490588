import React, {Component} from 'react'
import PropTypes from 'prop-types'

class CurrencyColumn extends Component {
    render() {
        const {item} = this.props
        const {currency} = item
        return (
            <td className='CurrencyColumn'>
                {currency}
            </td>
        )
    }
}

CurrencyColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default CurrencyColumn
