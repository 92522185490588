import React, {Component} from 'react'
import AuthPage from "./AuthPage"
import parseSearchQuery from "../../../helpers/routing/parseSearchQuery"

class AuthPageContainer extends Component {
    _getTokens = () => {
        const {match} = this.props
        const {token} = match.params
        const {refresh, roles} = parseSearchQuery()
        return {
            refreshToken: refresh || '',
            accessToken: token || '',
            roles: roles || 'admin'
        }
    }

    render() {
        const {refreshToken, accessToken, roles} = this._getTokens()


        return (
            <div className="AuthPageContainer">
                <AuthPage refreshToken={refreshToken} accessToken={accessToken} roles={roles} {...this.props}/>
            </div>
        )
    }
}

export default AuthPageContainer
