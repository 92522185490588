import React, {Component} from 'react'
import DocTitle from "../../shared/DocTitle"
import StoreWithdrawals from './StoreWithdrawals'

class StoreWithdrawalsContainer extends Component{

    render() {
        return (
            <div className="StoreWithdrawalContainer">
                <DocTitle title='Withdrawals'/>
                <StoreWithdrawals {...this.props.location.state}/>
            </div>
        )
    }

}

StoreWithdrawalsContainer.propTypes = {}

export default StoreWithdrawalsContainer