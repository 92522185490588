import React, {Component} from 'react'
import makeAnimated from 'react-select/animated'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import {
    getCategories
} from '../../../services/api/CategoriesServices'

class CategoriesSelector extends Component {
    state = {
        selectedCategories: [],
        selectedOptions: null,
        options: [],
        err: '',
        params: {title: ''},
        input: '',
        message: '',
    }

    _timeout = null

    // componentDidMount() {
    //     this._getCategories()
    // }

    _getCategories = () => {
        return new Promise(async resolve => {
             if(this._timeout !== null) clearTimeout(this._timeout)

             this._timeout = setTimeout(async () => {
            const {params: {title}} = this.state

            try {
                const {success, data, message} = await getCategories({title})
                if (!success) {
                    return this.setState({
                        err: message,
                    })
                }
                const {categories} = data
                const options = (this.props.defaultSelectedOptions && title === '' && [
                    this.props.defaultSelectedOptions,
                    ...categories.map(category => {
                        return {
                            ...category,
                            id: category._id,
                            value: category.title,
                            label: category.title,
                        }
                    })]) || categories.map(category => {
                        return {
                            ...category,
                            id: category._id,
                            value: category.title,
                            label: category.title,
                        }
                    }
                )

                // const options = categories.map(category => {
                //         return {
                //             ...category,
                //             id: category._id,
                //             value: category.title,
                //             label: category.title,
                //         }
                //     }
                // )

                this.setState(
                    () => ({
                        options,
                    }),
                    () => {
                        // console.log(options)
                        resolve(this.state.options)
                    },
                )
            } catch (e) {
                this.setState({
                    err: e.message || e,
                })
            }
            // resolve([{value: '', label: 'All'}])
            }, 550)
        })
    }

    _handleChange = options => {
        // console.log(options)
        const _selectedCategories = (options !== null) ? ((this.props.multiSelect && options.map(option => {
            return option.id
        })) || [options]) : []
        // console.log(_selectedCategories)
        this.setState(
            {
                selectedCategories: _selectedCategories,
                selectedOptions: options,
            },
            () => {
                this.props.handleSelectCollection(_selectedCategories)
            },
        )
    }

    _handleInputChange = input => {
        this.setState(
            {
                params: {title: input},
                input: input,
            },
            () => {
                // this._getCategories()
            },
        )

        return input
    }

    _getNoOptionsMessage = () => {
        return 'There are no categorys!'
    }

    _getLoadingMessage = () => {
        return 'Loading categorys...'
    }

    render() {
        const {selectedOptions, message, input, err} = this.state
        const {isHideTitle, className} = this.props
        // console.log('default', this.props.defaultValue)
        return (
            <div className={`CategoriesSelector Categories col ${className}`}>
                <div className='form-group mb-0 d-flex'>
                    {!isHideTitle && <span className='font-weight-bold'>Category Select</span>}
                    <AsyncSelect
                        className={'selectProductLine col'}
                        cacheOptions
                        components={makeAnimated()}
                        onChange={this._handleChange}
                        onInputChange={this._handleInputChange}
                        inputValue={input}
                        defaultOptions={true}
                        noOptionsMessage={this._getNoOptionsMessage}
                        loadingMessage={this._getLoadingMessage}
                        loadOptions={this._getCategories}
                        value={selectedOptions || (this.props.defaultValue || this.props.defaultSelectedOptions)}
                        placeholder='Search categories'
                        isMulti={this.props.multiSelect}
                    />
                    {err && (
                        <p className='text-danger'>{err}</p>
                    )}
                    {message &&
                    <p className='text-primary'>{message}</p>
                    }
                </div>
            </div>
        )
    }
}

CategoriesSelector.propTypes = {
    handleSelectCollection: PropTypes.func,
    multiSelect: PropTypes.bool,
    defaultSelectedOptions: PropTypes.object,
    defaultValue: PropTypes.object,
}

export default CategoriesSelector
