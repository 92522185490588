import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ClusterName extends Component {
    state = {
        name: this.props.name,
    }

    _handleChangeInput = e => {
        const {value, name} = e.target
        const {onUpdateCluster} = this.props

        this.setState({
            name: value,
        }, onUpdateCluster(name, value))
    }

    render() {
        const {name} = this.state

        return (
            <div className="ClusterName form-group">
                <label htmlFor="clusterName">Name</label>
                <input type="text" className="form-control" id="clusterName" name="name" value={name}
                       onChange={this._handleChangeInput}/>
            </div>
        )
    }
}

ClusterName.propTypes = {
    name: PropTypes.string.isRequired,
    onUpdateCluster: PropTypes.func.isRequired,
}

export default ClusterName
