import React, {Component} from 'react'
import Catalogs from './Catalogs'

class CatalogsContainer extends Component {
    render() {
        return (
            <div className='CatalogsContainer'>
                <Catalogs />
            </div>
        )
    }
}

export default CatalogsContainer
