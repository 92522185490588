import React, {useEffect, useState} from 'react'
import {Input} from 'reactstrap/es'
import {randomID} from '../../../helpers/common/randomID'
import {
    getListAppliedStores,
    getListPrivatePrice,
    updatePrivateShipping
} from '../../../services/api/ProductLinesServices'
import {toaster} from '../../shared/PFToast'
import CatalogPrivateShippingCostFilter from './CatalogPrivateShippingCostFilter'
import BackNavigationButton from '../../shared/BackNavigationButton'
import {Button} from 'reactstrap'
import getHistory from '../../../store/getHistory'
import trashIcon from '../../../statics/assets/icons/x.svg'
import {PLAN} from './../constants/index'
import Select from 'react-select'

const CatalogPrivateShippingCostContainer = (props) => {
    const {match} = props
    const catalogId = match.params.catalogId
    const [filter, setFilter] = useState({})
    const [filterStore, setFilterStore] = useState('')
    const [attributes, setAttributes] = useState([])
    const [variants, setVariants] = useState([])
    const [originVariants, setOriginVariants] = useState([])
    const [listStores, setListStores] = useState([])
    const [originStores, setOriginStores] = useState([])
    const [stores, setStores] = useState([])
    const [allStores, setAllStores] = useState([])
    const [isAdd, setIsAdd] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [plan, setPlan] = useState('standard')
    const [valueAttributes, setValueAttributes] = useState([])
    const [isDisableSave, setIsDisableSave] = useState(true)

    const _onChangePlan = (e) => {
        const {value} = e.target
        setIsAdd(false)
        setPlan(value)
        _fetchListPrivate(value)
    }

    const _fetchListStore = async () => {
        try {
            const {success, message, data} = await getListAppliedStores(catalogId)

            if (!success) throw new Error(message)

            setAllStores(data)
        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
            })
        }
    }

    const _addStore = (e) => {
        e.preventDefault()
        setIsAdd(true)
        console.log(originVariants, variants)
        const shipping_prices = variants.length > 0 ? variants[0].shipping_prices : []
        const _shippingPrice = shipping_prices.map((shipping) => {
            const {to_zone, ...res} = shipping
            let objShipping = {}
            Object.keys(res).map((key) => {
                objShipping[key] = null
            })
            return {
                to_zone,
                ...objShipping
            }
        })
        const newStore = {
            prices: variants.map(itemVariant => {
                return {
                    ...itemVariant,
                    shipping_prices: _shippingPrice
                }
            }),
            action_type: "update",
            store: {}
        }
        const newStoreOrigin = {
            prices: originVariants.map(itemVariant => {
                return {
                    ...itemVariant,
                    shipping_prices: _shippingPrice
                }
            }),
            action_type: "update",
            store: {}
        }
        setListStores([newStore, ...listStores])
        setOriginStores([newStoreOrigin, ...originStores])

    }

    const _fetchListPrivate = async (value = null) => {
        try {
            setLoading(true)
            const {success, message, data} = await getListPrivatePrice(catalogId, value || plan)
            if (!success) throw new Error(message)
            setListStores(data)
            setOriginStores(data)
            if(data.length > 0) {
                setVariants(data[0].prices)
                setOriginVariants(data[0].prices)
                if(data[0].prices.length > 0) {
                    const _attributes = data[0].prices[0].attributes
                    setAttributes(_attributes)
                    let valueAttibutes = []
                    let _variants = data[0].prices
                    _variants.map(item => {
                        const _attibutes = item.attributes
                        _attibutes.map(itemA => {
                            if(valueAttibutes.find(itemFind => itemFind.value_text === itemA.value_text && itemFind.value_code === itemA.value_code) === undefined) {
                                valueAttibutes.push(itemA)
                            }
                        })
                    })
                    setValueAttributes(valueAttibutes)
                }
            }
            setLoading(false)
            setStores(data.map(item => item.store))
        } catch (e) {
            setLoading(false)
            toaster({
                type: 'error',
                message: e.message,
            })
        }
    }

    const _handleCancel = () => {
        const history = getHistory();
        history.push(`/a/catalog/${catalogId}`);
    }

    const _handleUpdate = async () => {
        const storesUpdated = originStores.filter(item => item.action_type !== undefined)
        setIsDisableSave(true)
        if(storesUpdated.length > 0) {
            const _stores = storesUpdated.map(itemStore => {
                if(itemStore.action_type === 'delete') {
                    return {
                        action_type: itemStore.action_type,
                        store_id: itemStore.store._id,
                    }
                } else {
                    return {
                        action_type: itemStore.action_type,
                        store_id: itemStore.store._id,
                        prices: itemStore.prices.filter(item => item.variant_type !== undefined).map(itemPrice => {
                            return {
                                variant_id: itemPrice.variant_id,
                                shipping_prices: itemPrice.shipping_prices
                            }
                        })
                    }
                }
            })
            const validateNumberPrice = _stores.filter(item => {
                let prices = []
                if(!!item.prices) {
                    prices = item.prices.filter(itemPrice => {
                        let validPrices = []
                        itemPrice.shipping_prices.map(itemShipping => {
                            const {to_zone, ...res} = itemShipping
                            Object.keys(res).map((key) => {
                                if(!!res[key] && res[key] < 0) {
                                    validPrices.push(key)
                                }
                            })
                        })
                        return item.action_type !== 'delete' && validPrices.length > 0
                    })
                }
                return item.action_type !== 'delete' && prices.length > 0
            })
            if(validateNumberPrice.length > 0) {
                return toaster({
                    type: 'error',
                    message: 'Invalid price, please enter value greater than 0'
                })
            }
            const payload = {
                product_id: catalogId,
                plan,
                private_prices: _stores
            }
            try {
                setLoadingUpdate(true)
                const {success, message} = await updatePrivateShipping(payload)
                if (!success) throw new Error(message)
                _fetchListPrivate()
                toaster({
                    type: 'success',
                    message: 'Update price successfully!',
                })
                setLoadingUpdate(false)
            } catch (e) {
                setLoadingUpdate(false)
                toaster({
                    type: 'error',
                    message: e.message,
                })
            }
        }
    }

    useEffect(() => {
        _fetchListPrivate()
        _fetchListStore()
    }, [])

    return (
        <div className="CatalogPrivateBaseCostContainer VariantBaseCostPricing BaseCostShipping">
            <BackNavigationButton to={`/a/catalog/${catalogId}`} text="Catalog" />
            <div className="d-flex justify-content-between align-items-center"><h2>Setting exclusive pricing</h2></div>
            <div className="SectionInner">
                <div className="TableContainer">
                    <div className="d-flex align-items-center mb-3">
                        <label className="mb-0 mr-3">Plan</label>
                        <select style={{width: '200px'}} value={plan} className="form-control" onChange={_onChangePlan}>
                            {PLAN.map((v) => {
                                return (
                                    <option key={`optionPlan${v.value}`} value={v.value}>
                                        {v.title}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    {loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="text-success spinner-border Spinner" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="overflow-auto" style={{height: '540px'}}>
                                <table className="table">
                                    <TableHead
                                        attributes={attributes}
                                        variants={variants}
                                        listStores={listStores}
                                        isAdd={isAdd}
                                        addStore={_addStore}
                                        filter={filter}
                                        stores={stores}
                                        originStores={originStores}
                                        filterStore={filterStore}
                                        setFilter={setFilter}
                                        setListStores={setListStores}
                                        setFilterStore={setFilterStore}
                                        valueAttributes={valueAttributes}
                                    />
                                    <TableBody
                                        isAdd={isAdd}
                                        setIsAdd={setIsAdd}
                                        addStore={_addStore}
                                        filter={filter}
                                        listStores={listStores}
                                        originStores={originStores}
                                        setListStores={setListStores}
                                        setOriginStores={setOriginStores}
                                        attributes={attributes}
                                        variants={variants}
                                        setVariants={setVariants}
                                        setFilter={setFilter}
                                        stores={stores}
                                        allStores={allStores}
                                        originVariants={originVariants}
                                        valueAttributes={valueAttributes}
                                        catalogId={catalogId}
                                        plan={plan}
                                        fetchListPrivate={_fetchListPrivate}
                                        setIsDisableSave={setIsDisableSave}
                                        filterStore={filterStore}
                                        setFilterStore={setFilterStore}
                                        setStores={setStores}
                                    />
                                </table>
                            </div>
                            <div className="d-flex align-items-center justify-content-between stickyBottom">
                                <div className="Noted">
                                    <span className="text-danger">*</span>Noted: If the private pricing is empty, the system will take the public shipping fee from Catalog for store instead.
                                </div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <Button disabled={loadingUpdate} onClick={_handleCancel} className="mr-3">Close</Button>
                                    <Button disabled={isAdd || isDisableSave || loadingUpdate} onClick={_handleUpdate} color="primary">Save</Button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

const TableHead = (props) => {
    const {
        attributes,
        listStores,
        valueAttributes,
        isAdd,
        addStore,
        filter,
        stores,
        filterStore,
        originStores,
        setFilter,
        setListStores,
        setFilterStore,
        variants = []
    } = props
    const shipping_prices = variants.length > 0 ? variants[0].shipping_prices : []

    const _handleFilterVariants = (filter) => {
        const _stores = _filterVariants(filterStore, originStores, filter)
        setFilter(filter)
        setListStores(_stores)
        //setOriginStores(_stores)
    }

    const _handleFilterStores = (id) => {
        setFilterStore(id)
        const _stores = _filterVariants(id, originStores, filter)
        setListStores(_stores)
        //setOriginStores(_stores)
    }

    const _filterVariants = (filterStore, stores, filter) => {
        let _stores = [...stores]
        if(filterStore) {
            _stores = stores.filter(item => item?.store?._id === filterStore)
        }
        const _result = _stores.map(item => {
            const _variants = item.prices.filter((variant) => {
                let matchCount = 0
                for (let key in filter) {
                    const condition = variant.attributes.some((option) => option.value_code === filter[key])
                    if (condition) {
                        matchCount++
                    }
                }
                return matchCount === Object.keys(filter).length
            })
            return {
                ...item,
                prices: _variants
            }
        })
        return _result
    }

    return (
        <thead className="thead-light">
        <tr>
            <th rowSpan={2}>Store</th>
            {!!attributes.length &&
                attributes.map((attribute) => {
                    const _id = randomID()
                    return (
                        <th rowSpan={2} key={`attributeName${_id}`} className={`text-capitalize ${attribute.name}`}>
                            {attribute.name}
                        </th>
                    )
                })}
            {!!shipping_prices.length &&
                shipping_prices.map((shipping) => {
                    const {to_zone, ...res} = shipping
                    const _id = randomID()
                    return (
                        <th colSpan={Object.keys(res).length} className="text-center" key={`headZone${_id}`}>
                            {to_zone}
                        </th>
                    )
                })}
        </tr>
        <tr>
            {!!shipping_prices.length &&
                shipping_prices.map((shipping) => {
                    const {to_zone, ...res} = shipping
                    return Object.keys(res).map((key) => {
                        return <th key={`shippingPrice${key}`} className="text-center">
                            {key === 'first_item' ? '1st item' : key === 'additional_item' ? 'Additional item' : key}
                        </th>
                    })
                })}
        </tr>
        {(listStores.length > 0 && valueAttributes.length > 0) && <CatalogPrivateShippingCostFilter
            valueAttributes={valueAttributes}
            isAdd={isAdd}
            addStore={addStore}
            variants={variants}
            attributes={attributes}
            filter={filter}
            stores={stores}
            listStores={listStores}
            handleFilterVariants={_handleFilterVariants}
            handleFilterStores={_handleFilterStores}
        />}
        </thead>
    )
}

const TableBody = (props) => {
    const {
        attributes,
        filter,
        variants,
        setFilter,
        listStores,
        stores,
        allStores,
        setListStores,
        isAdd,
        setIsAdd,
        addStore,
        valueAttributes,
        setOriginStores,
        originStores,
        setIsDisableSave,
        filterStore,
        setFilterStore,
        setStores
    } = props

    const deleteStore = (storeID) => {
        // const _stores = [{
        //     action_type: 'delete',
        //     store_id: storeID,
        // }]
        // const payload = {
        //     product_id: catalogId,
        //     plan,
        //     private_prices: _stores
        // }
        // try {
        //     //setLoadingUpdate(true)
        //     const {success, message} = await updatePrivateShipping(payload)
        //     if (!success) throw new Error(message)
        //     fetchListPrivate()
        //     toaster({
        //         type: 'success',
        //         message: 'Delete store successfully!',
        //     })
        //     //setLoadingUpdate(false)
        // } catch (e) {
        //     //setLoadingUpdate(false)
        //     toaster({
        //         type: 'error',
        //         message: e.message,
        //     })
        // }
        setIsDisableSave(false)
        const _listStore = listStores.map((itemStore) => {
            if(itemStore.store._id === storeID) {
                return {
                    ...itemStore,
                    action_type: 'delete',
                }
            } else {
                return itemStore
            }
        })
        const _listStoreOrigin = originStores.map((itemStore) => {
            if(itemStore.store._id === storeID) {
                return {
                    ...itemStore,
                    action_type: 'delete',
                }
            } else {
                return itemStore
            }
        })
        setStores(_listStoreOrigin.filter(item => item?.action_type !== 'delete').map(item => item.store))
        setListStores(_listStore)
        setOriginStores(_listStoreOrigin)
    }

    const _removeAddNew = () => {
        setIsAdd(false)
        const _storesAdd = listStores.filter(item => Object.keys(item.store).length > 0)
        const _storesAddOrigin = originStores.filter(item => Object.keys(item.store).length > 0)
        setListStores(_storesAdd)
        setOriginStores(_storesAddOrigin)
    }

    const _handleSelectStore = (e, index) => {
        if(!!e) {
            setIsDisableSave(false)
            const storeID = e.value
            const listIDStore = listStores.filter(item => item.action_type !== 'delete').map(item => item.store._id)
            if(listIDStore.includes(storeID)) {
                return toaster({
                    type: 'error',
                    message: 'Pricing for this store is already existed'
                })
            }
            const storeInfo = allStores.find(item => item._id === storeID)
            const _listStore = listStores.map((itemStore, indexStore) => {
                if(indexStore === index) {
                    return {
                        ...itemStore,
                        store: {
                            _id: storeID,
                            name: storeInfo !== undefined ? storeInfo.name : ''
                        }
                    }
                } else {
                    return itemStore
                }
            })
            const _listStoreOrigin = originStores.map((itemStore, indexStore) => {
                if(indexStore === index) {
                    return {
                        ...itemStore,
                        store: {
                            _id: storeID,
                            name: storeInfo !== undefined ? storeInfo.name : ''
                        }
                    }
                } else {
                    return itemStore
                }
            })
            const uniqueStore = [...new Map(_listStore.map((m) => [m.store._id, m])).values()];
            setStores(_listStoreOrigin.filter(item => item?.action_type !== 'delete').map(item => item.store))
            setIsAdd(false)
            setListStores(_listStore)
            setOriginStores(_listStoreOrigin)
        }
    }

    const _handleChangeInput = (variantId, storeID, name, value, zone, type, event) => {
        setIsDisableSave(false)
        const _listStores = [...listStores]
        const _stores = _listStores.map((item) => {
            if(storeID === item.store._id && item?.action_type !== 'delete') {
                return {
                    ...item,
                    action_type: type,
                    prices: item.prices.map((itemPrice) => {
                        if (itemPrice.variant_id === variantId) {
                            const shippingPrice = itemPrice.shipping_prices
                            return {
                                ...itemPrice,
                                variant_type: 'update',
                                shipping_prices: shippingPrice.map(itemShippingPrice => {
                                    if(itemShippingPrice.to_zone === zone) {
                                        return {
                                            ...itemShippingPrice,
                                            [name]: !!value ? parseFloat(value) : null
                                        }
                                    } else {
                                        return itemShippingPrice
                                    }
                                })
                            }
                        } else {
                            return itemPrice
                        }
                    })
                }
            } else {
                return item
            }
        })
        const _storesOrigin = originStores.map((item) => {
            if(storeID === item.store._id && item?.action_type !== 'delete') {
                return {
                    ...item,
                    action_type: type,
                    prices: item.prices.map((itemPrice) => {
                        if (itemPrice.variant_id === variantId) {
                            const shippingPrice = itemPrice.shipping_prices
                            return {
                                ...itemPrice,
                                variant_type: 'update',
                                shipping_prices: shippingPrice.map(itemShippingPrice => {
                                    if(itemShippingPrice.to_zone === zone) {
                                        return {
                                            ...itemShippingPrice,
                                            [name]: !!value ? parseFloat(value) : null
                                        }
                                    } else {
                                        return itemShippingPrice
                                    }
                                })
                            }
                        } else {
                            return itemPrice
                        }
                    })
                }
            } else {
                return item
            }
        })
        const __stores = _stores.filter(item => item.action_type !== 'delete' || item.store._id !== storeID)
        const __storesOrigin = _storesOrigin.filter(item => item.action_type !== 'delete' || item.store._id !== storeID)
        if(parseFloat(value) < 0) {
            event.target.classList.add('error')
        } else {
            event.target.classList.remove('error')
        }
        setListStores(__stores)
        setOriginStores(__storesOrigin)
    }

    return (
        <tbody>

        {listStores.length > 0 && listStores.map((itemStore, indexStore) => {
            const _variants = itemStore.prices
            const _store = itemStore.store
            return _variants.map((itemVariant, indexVariant) => {
                const variant_id = itemVariant.variant_id
                const priceVariant = itemStore.prices.find(item => item.variant_id === variant_id)
                const _attributes = itemVariant?.attributes || []
                const _shippingPrices = priceVariant !== undefined ? priceVariant.shipping_prices : []
                const actionType = itemStore?.action_type || ''
                if(actionType !== 'delete' && itemStore.store?._id !== 'default_id') {
                    return <tr key={`rowStore${itemVariant?.variant_id}`}>
                        {indexVariant === 0 && (
                            <>
                                <td rowSpan={itemStore.store._id !== undefined ? _variants.length : 1}>
                                    {
                                        Object.keys(itemStore.store).length > 0 ?
                                            <>
                                                {_store?.name || ''}
                                                <img onClick={() => deleteStore(itemStore.store._id)} className="cursor-pointer" src={trashIcon} alt="trashIcon"/>
                                            </> :
                                            <>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    onChange={(e) => _handleSelectStore(e, indexStore)}
                                                    options={allStores.map(item => {
                                                        return {
                                                            label: item.name,
                                                            value: item._id
                                                        }
                                                    })}
                                                />
                                                <img onClick={_removeAddNew} className="cursor-pointer ml-1" src={trashIcon} alt="trashIcon"/>
                                            </>
                                    }
                                </td>
                                {itemStore.store._id === undefined && (
                                    <>
                                        {_attributes.map(itemAttribute => {
                                            return <td key={`colAttribute${itemAttribute.value_code}`}></td>
                                        })}
                                        {_shippingPrices.map(itemShipping => {
                                            const {to_zone, ...res} = itemShipping
                                            return Object.keys(res).map((key) => {
                                                if(itemStore.store._id !== undefined) {
                                                    return <td key={key} className="pricingPrefix"></td>
                                                } else {
                                                    return <td key={key} className="pricingPrefix text-right"></td>
                                                }
                                            })
                                        })}
                                    </>
                                )}
                            </>
                        )}
                        {itemStore.store._id !== undefined && (
                            <>
                                {_attributes.map(itemAttribute => {
                                    return <td key={`colAttributeValue${itemAttribute.value_code}`}>{itemStore.store._id !== undefined && itemAttribute.value_text}</td>
                                })}
                                {!!_shippingPrices && _shippingPrices.map(itemShipping => {
                                    const {to_zone, ...res} = itemShipping
                                    return Object.keys(res).map((key) => {
                                        if(itemStore.store._id !== undefined) {
                                            return <td className="pricingPrefix">
                                                <span className="prefix">$</span>
                                                <Input placeholder="-" type="number" value={(res[key] !== null && res[key] !== undefined) ? res[key] : '-'} onChange={(e) => _handleChangeInput(variant_id, itemStore.store._id || '', key, e.target.value, to_zone, 'update', e)}/>
                                            </td>
                                        } else {
                                            return <td className="pricingPrefix text-right">-</td>
                                        }
                                    })
                                })}
                            </>
                        )}
                    </tr>
                } else {
                    return null
                }
            })
        })}
        </tbody>
    )
}

export default CatalogPrivateShippingCostContainer

