import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createWithdrawal } from '../../../../services/api/AffiliateServices';
import { toaster } from '../../../shared/PFToast';

const initialForm = {
    amount: '',
    note: '',
}

const WithdrawRequestModal = (props) => {

    const { code, openWidthdrawRequest, toggleRequest, setWidthdrawSuccess, available } = props
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState(initialForm)
    const [error, setError] = useState(initialForm)

    useEffect(() => {
        setForm(initialForm)
        setError(initialForm)
        setWidthdrawSuccess(false)
    }, [toggleRequest])

    const onSubmit = async () => {
        try {
            const errorObj = validateForm(form, error, validateField)
            setError(errorObj)
            if (Object.keys(errorObj).length !== 0) {
                return
            }
            if (form.amount > available) {
                toaster({
                    type: 'error',
                    message: 'Balance is not enough'
                })
                return
            }
            const req = { ...form, code }
            setLoading(true)
            const { success, message } = await createWithdrawal(req)
            if (success) {
                toaster({
                    type: 'success',
                    message: 'Request withdrawal successfully.'
                })
                setWidthdrawSuccess(true)
                toggleRequest()
            } else {
                toaster({
                    type: 'error',
                    message
                })
            }
            setLoading(false)
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const onChangeAmount = (amount) => {
        const { floatValue } = amount
        const message = validateField('amount', floatValue)
        setError({ ...error, amount: message })
        setForm({ ...form, amount: floatValue })
    }

    const onChangeForm = e => {
        const { name, value } = e.target
        const message = validateField(name, value)
        setError({ ...error, [name]: message })
        setForm({ ...form, [name]: value })
    }

    return (
        <Modal className='widthdrawal-modal' centered isOpen={openWidthdrawRequest} toggle={toggleRequest} backdrop="static" >
            <ModalHeader toggle={toggleRequest}>Withdrawal request</ModalHeader>
            <ModalBody>
                <form>
                    <div className='col-sm-12 form-group'>
                        <label>Amount<span className='text-danger'>*</span></label>
                        <div className='input-group'>
                            <NumberFormat
                                name="amount"
                                decimalScale={2}
                                thousandSeparator={true}
                                className={`form-control${error.amount ? ' invalid-input' : ''}`}
                                onValueChange={onChangeAmount}
                            />
                            <div className='input-group-append'>
                                <span className='input-group-text'>$</span>
                            </div>
                        </div>
                        <p className='error-message'>{error.amount}</p>
                    </div>
                    <div className='col-sm-12 form-group'>
                        <label>Note<span className='text-danger'>*</span></label>
                        <textarea
                            rows={3}
                            name='note'
                            className={`form-control${error.note ? ' invalid-input' : ''}`}
                            onChange={onChangeForm}
                            onBlur={(e) => {
                                if (!e.target.value) e.target.value = e.target.value.trim()
                            }}
                        ></textarea>
                        <p className='error-message'>{error.note}</p>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color='default' disabled={loading} onClick={toggleRequest}>
                    Cancel
                </Button>
                <Button color='primary' disabled={loading} onClick={onSubmit}>
                    {
                        loading && <i className='fas fa-circle-notch fa-spin mr-2'></i>
                    }
                    Submit
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const validateField = (name, value) => {
    let errorMsg = null
    switch (name) {
        case 'amount':
            if (!value) errorMsg = 'This field is required'
            if (value == 0) errorMsg = 'Amount must greater than 0'
            break
        case 'note':
            if (!value) errorMsg = 'This field is required'
            break
        default: break
    }
    return errorMsg
}


const validateForm = (form, formError, validateFunc) => {
    const errorObj = {};
    Object.keys(formError).map(x => {
        const message = validateFunc(x, form[x])
        if (message) errorObj[x] = message
    })
    return errorObj
}

export default WithdrawRequestModal