import React, {Component} from 'react'
import PropTypes from 'prop-types'
// import StatusStyle from '../../shared/StatusStyle'
import humanizeTime from '../../../helpers/time/humanizeTime'

class RenderTableRow extends Component {
    render() {
        const {item, page, limit, index} = this.props
        const tableIndex = limit * (page - 1) + 1 + index
        const {slug, name, description, created_at, status} = item

        return (
            <tr>
               <td>{tableIndex}</td>
               <td>{name}</td>
               <td>{slug}</td>
               {/* <td>{description}</td> */}
               <td>{humanizeTime(created_at)}</td>
               {/* <td><StatusStyle status={status} /></td> */}
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

export default RenderTableRow
