import React, {Component} from 'react'
import JobPage from '../../../cicd/components/JobPage'
import getEnv from '../../../../helpers/common/getEnv'

class JobContainer extends Component {
    render() {
        const isSupportMpMode = getEnv('isEnableMpMode')
        if (isSupportMpMode) return null

        return (
            <div className='StoreFeatureTagsContainer'>
                <div className='card'>
                    <div className='card-header font-weight-bold'>Pipelines</div>

                    <div className='card-body'>
                        <JobPage store={this.props.storeDetails}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default JobContainer
