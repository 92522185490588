import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ManageRatesActionsContainer extends Component {

    render() {
        const {loading} = this.props

        return (
            <div className="ManageRatesActionsContainer">
                <div className="d-flex justify-content-between">
                    <button
                        className="btn btn-primary d-inline-flex justify-content-center align-items-center SaveRate ml-auto"
                        type="submit" disabled={loading}>
                        {
                            loading ?
                                <span className="spin m-0"/>
                                :
                                "Save changes"
                        }
                    </button>
                </div>
            </div>
        )
    }
}

// ManageRatesActionsContainer.defaultProps = {}

ManageRatesActionsContainer.propTypes = {
    loading: PropTypes.bool.isRequired,
}

export default ManageRatesActionsContainer
