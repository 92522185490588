import React, {Component} from 'react'
import FromData from "./table-data/FromData"
import CostData from "./table-data/CostData"
import PriceRulesActions from "./table-data/PriceRulesActions"

// import PropTypes from 'prop-types'

class PriceRulesRow extends Component {
    render() {
        const {onRemoveRow, rowIndex, onUpdatePriceData, rowData} = this.props
        const {cost, from} = rowData

        return (
            <tr className="PriceRulesRow">
                <FromData rowIndex={rowIndex} from={from} onUpdatePriceData={onUpdatePriceData}/>
                <CostData onUpdatePriceData={onUpdatePriceData} rowIndex={rowIndex} cost={cost}/>
                <PriceRulesActions onRemoveRow={onRemoveRow} rowIndex={rowIndex}/>
            </tr>
        )
    }
}

// PriceRulesRow.defaultProps = {}

// PriceRulesRow.propTypes = {}

export default PriceRulesRow
