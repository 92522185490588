import React from 'react'
import PropTypes from 'prop-types'
import {SortableHandle} from "react-sortable-hoc"

const DragHandleVariantImage = SortableHandle((props) => {
    return (
        <img src={props.value} alt="Variant thumbnail"/>
    )
})

DragHandleVariantImage.propTypes = {
    value: PropTypes.string.isRequired,
}

export default DragHandleVariantImage
