import React, {Component} from 'react'
import ProductVariantPrice from './ProductVariantsData/ProductVariantPrice'
import ProductVariantAttribute from './ProductVariantsData/ProductVariantAttribute'
import ProductVariantSKU from './ProductVariantsData/ProductVariantSKU'
import PropTypes from 'prop-types'
import ProductVariantAction from './ProductVariantsData/ProductVariantAction'
import ProductVariantWeight from './ProductVariantsData/ProductVariantWeight'
import ProductVariantNewSKU from './ProductVariantsData/ProductVariantNewSKU'

class ProductVariantsRow extends Component {
    render() {
        const {
            variant,
            attributes,
            handleListVariantsState,
            variantIndex,
            totalVariants,
            onPasteListVariants,
            store,
            currency,
        } = this.props
        const {
            sku = '',
            weight = 0,
            weightError = '',
            priceError = '',
            baseCostError = '',
            base_cost = '',
            retail_price = '',
            new_sku = '',
            newSkuError = '',
            duplicateSkuError = '',
        } = variant

        return (
            <tr className="ProductVariantsRow">
                {Array.isArray(attributes) &&
                    attributes.map((att) => {
                        return <ProductVariantAttribute key={att._id} variant={variant} attribute={att} />
                    })}
                <ProductVariantSKU
                    onPasteListVariants={onPasteListVariants}
                    handleListVariantsState={handleListVariantsState}
                    sku={sku}
                    error={duplicateSkuError}
                    variantIndex={variantIndex}
                />
                <ProductVariantNewSKU
                    onPasteListVariants={onPasteListVariants}
                    handleListVariantsState={handleListVariantsState}
                    new_sku={new_sku}
                    error={newSkuError}
                    variantIndex={variantIndex}
                />
                {store === 'marketplace' && (
                    <ProductVariantWeight
                        error={weightError}
                        onPasteListVariants={onPasteListVariants}
                        handleListVariantsState={handleListVariantsState}
                        weight={weight}
                        variantIndex={variantIndex}
                    />
                )}

                <ProductVariantPrice
                    currency={currency}
                    onPasteListVariants={onPasteListVariants}
                    price={base_cost}
                    name="base_cost"
                    error={baseCostError}
                    variantIndex={variantIndex}
                    handleListVariantsState={handleListVariantsState}
                    totalVariants={totalVariants}
                />

                <ProductVariantPrice
                    currency={currency}
                    onPasteListVariants={onPasteListVariants}
                    price={retail_price}
                    name="retail_price"
                    error={priceError}
                    variantIndex={variantIndex}
                    handleListVariantsState={handleListVariantsState}
                    totalVariants={totalVariants}
                />

                <ProductVariantAction
                    handleListVariantsState={handleListVariantsState}
                    variantIndex={variantIndex}
                    variant={variant}
                />
            </tr>
        )
    }
}

ProductVariantsRow.propTypes = {
    variant: PropTypes.object.isRequired,
    attributes: PropTypes.array.isRequired,
    handleListVariantsState: PropTypes.func.isRequired,
    variantIndex: PropTypes.number.isRequired,
    totalVariants: PropTypes.number.isRequired,
}

export default ProductVariantsRow

