import React, {Fragment, useState} from 'react'
import {actionPayoutTransfer} from '../../../../services/api/BillingPayoutServices'
import {toaster} from '../../../shared/PFToast'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from 'reactstrap'
import InputEditorV2 from '../../../shared/components/form/InputEditorV2'
import Clipboard from 'react-clipboard.js'
import iconDelete from '../../../../statics/assets/icons/iconDelete.svg'
import PaperClip from '../../../../statics/assets/icons/PaperClip.svg'
import NotificationValid from '../../../shared/components/notification/NotificationValid'
import PropTypes from 'prop-types'
import getHistory from '../../../../store/getHistory'
import {NumberToWord} from '../../../../helpers/currency/NumberToWord'

const PayoutApprovalActionTransfer = (props) => {
    const {ID, onFetchPayout, payout, payoutID, amountFormat, am_updated, amount = 0} = props

    const {account: accountAM = {}} = Object.assign({}, am_updated)
    const {email: emailAM = ''} = Object.assign({}, accountAM)

    const {account = {}, payout_id = ''} = Object.assign({}, payout)
    const {gateway = '', email = ''} = Object.assign({}, account)

    const [modal, setModal] = useState(false)
    const [transaction_photo, setPhoto] = useState(null)
    const [transaction_id, setTransaction] = useState('')
    const [loading, setLoading] = useState(false)
    const [path, setPath] = useState('')
    const [nameImage, setNameImage] = useState('')

    const [error, setError] = useState({})

    const [copyValue, setCopyValue] = useState('Copy value')
    const [copyEmail, setCopyEmail] = useState('Copy email')
    const [copyContent, setCopyContent] = useState('Copy content')

    let timeout = null

    const handleCopyValue = () => {
        setCopyValue('Copied!')
        timeout = setTimeout(() => {
            setCopyValue('Copy value')
        }, 2000)
    }
    const handleCopyEmail = () => {
        setCopyEmail('Copied!')
        timeout = setTimeout(() => {
            setCopyEmail('Copy email')
        }, 2000)
    }

    const handleCopyContent = () => {
        setCopyContent('Copied!')
        timeout = setTimeout(() => {
            setCopyContent('Copy content')
        }, 2000)
    }

    const toggle = () => {
        setModal(!modal)
        setError({})
        setTransaction('')
        setPhoto(null)
        setPath('')
        setNameImage('')
    }

    const handleChangeInput = (value) => {
        setTransaction(value)
        if (error['transaction_id']) {
            delete error['transaction_id']
        }
    }

    const validateSubmit = () => {
        let errorKey = {}
        const transactionFormat = transaction_id.trim()
        if (!transaction_id || !transactionFormat) errorKey.transaction_id = 'Transaction ID is required.'
        if (transaction_photo === null) errorKey.transaction_photo = 'Must be attached file.'

        const fileName = document.getElementById(`${payout_id}`).value
        const idxDot = fileName.lastIndexOf('.') + 1
        const extFile = fileName.substr(idxDot, fileName.length).toLowerCase()

        if (transaction_photo !== null && (extFile !== 'jpg' && extFile !== 'jpeg' && extFile !== 'png')) {
            errorKey.transaction_photo = 'Invalid image. (.jpg, .jpeg, .png)'
        }

        setError(errorKey)
        if (Object.keys(errorKey).length > 0) return

        handleTransfer()
    }

    const handleTransfer = async () => {

        setLoading(true)

        const postData = new FormData()
        postData.append('transaction_photo', transaction_photo)
        postData.append('transaction_id', transaction_id)

        try {
            const {success, message} = await actionPayoutTransfer(ID, postData)

            if (!success) throw new Error(message)

            setError('')
            onFetchPayout()

            if (payoutID) {
                const history = getHistory()
                history.push('/a/payout-approval')
            }

            toaster({message: 'Transfer successfully!', type: 'success', duration: 5000})

        } catch (e) {

            toaster({message: e.message, type: 'error', duration: 5000})

        } finally {
            setLoading(false)
            setModal(false)
            setTransaction('')
        }
    }

    const handleChangeFile = (e) => {
        const {files} = e.target
        const path = URL.createObjectURL(files[0])
        const name = (files[0]['name'])
        setPath(path)
        setNameImage(name)
        setPhoto(files[0])
        if (error['transaction_photo']) {
            delete error['transaction_photo']
        }
    }

    const removeImage = () => {
        setPath('')
        setNameImage('')
        setPhoto(null)
        if (error['transaction_photo']) {
            delete error['transaction_photo']
        }
    }

    return (
        <Fragment>
            <button onClick={toggle} className="btn btn-sm btn-primary mr-1">Transfer money</button>
            <Modal size="lg" isOpen={modal} toggle={toggle}
                   className="ModalActionTransfer modal-dialog-centered">
                <ModalHeader>Transfer money - {payout_id}</ModalHeader>
                <ModalBody>
                    <div className="InfoAM mb-3">
                        {
                            emailAM && <p className="mb-0"><b>Supporter:</b> {emailAM}</p>
                        }
                    </div>
                    <div className="row flex-wrap">
                        <div className="col-6">
                            <InputEditorV2 label="Gateway:" name="gateway" value={gateway} disabled={true}
                                           onChange={handleChangeInput}/>
                        </div>
                        <div className="col-6 PayoutTranferInput">
                            <InputEditorV2 label="Email owner:" name="email" value={email} disabled={true}
                                           onChange={handleChangeInput}/>
                            <Clipboard component="div" data-clipboard-text={email}
                                       className="PayoutTranferCopy fs-14"
                                       onSuccess={handleCopyEmail}>
                                <div className="Tooltip3 Top">
                                    <i className="ti-files cursor-pointer"/>
                                    <div className="TooltipContent">
                                        <div className="ContentInner">
                                            <small className="text-monospace">{copyEmail}</small>
                                        </div>
                                    </div>
                                </div>
                            </Clipboard>
                        </div>
                        <div className="col-6 PayoutTranferInput">
                            <InputEditorV2 label="Value:" name="value" placeholder={amountFormat} disabled={true}
                                           onChange={handleChangeInput}/>
                            {
                                amountFormat &&
                                <p className="TxtToWorkConfirm ml-1">{NumberToWord(amountFormat.replace('$', ''))}
                                    <span className="text-capitalize ml-1">US Dollars</span></p>
                            }
                            <Clipboard component="div" data-clipboard-text={amountFormat}
                                       className="PayoutTranferCopy fs-14"
                                       onSuccess={handleCopyValue}>
                                <div className="Tooltip3 Top">
                                    <i className="ti-files cursor-pointer"/>
                                    <div className="TooltipContent">
                                        <div className="ContentInner">
                                            <small className="text-monospace">{copyValue}</small>
                                        </div>
                                    </div>
                                </div>
                            </Clipboard>
                        </div>

                        <div className="col-6 PayoutTranferInput">
                            <InputEditorV2 label="Content:" disabled={true}
                                           placeholder={`Payout for ${payout_id}`} onChange={handleChangeInput}/>
                            <Clipboard component="div" data-clipboard-text={`Send money for ${payout_id}`}
                                       className="PayoutTranferCopy fs-14"
                                       onSuccess={handleCopyContent}>
                                <div className="Tooltip3 Top">
                                    <i className="ti-files cursor-pointer"/>
                                    <div className="TooltipContent">
                                        <div className="ContentInner">
                                            <small className="text-monospace">{copyContent}</small>
                                        </div>
                                    </div>
                                </div>
                            </Clipboard>
                        </div>
                        <div className="col-12">
                            <InputEditorV2 label="Transaction ID:" name="setTransaction" value={transaction_id}
                                           error={error.transaction_id}
                                           onChange={handleChangeInput}/>
                        </div>
                        <div className="col-12 AttachFile">
                            <input
                                type="file"
                                accept="image/png, image/jpeg, image/jpg"
                                multiple={false}
                                onChange={handleChangeFile}
                                style={{width: 100}}
                                id={payout_id}
                                className="d-none"
                            />
                            <label htmlFor={payout_id} id={payout_id}
                                   className={`d-block mb-0 cursor-pointer ${error.transaction_photo ? 'text-danger' : 'text-primary'}`}>
                                Attach file
                            </label>
                            {
                                nameImage &&
                                <div className="ImageFileTransfer mt-2 text-primary">
                                    <img src={PaperClip} alt="PaperClip" className="mr-2"/>
                                    <a target="_blank" rel="noopener noreferrer"
                                       href={path}>{nameImage}</a>
                                    <img src={iconDelete} alt="iconDelete" onClick={removeImage}
                                         className="cursor-pointer iconDelete"/>
                                </div>
                            }
                            {
                                error.transaction_photo &&
                                <NotificationValid content={error.transaction_photo}/>
                            }
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="default" onClick={toggle}>Cancel</Button>
                    <Button color="primary" onClick={validateSubmit}>
                        {
                            loading && <Spinner color="white" size="sm" className="mr-2"/>
                        }
                        OK
                    </Button>
                </ModalFooter>
            </Modal>
        </Fragment>

    )
}

PayoutApprovalActionTransfer.propTypes = {
    onFetchPayout: PropTypes.func.isRequired,
    payout: PropTypes.string.isRequired,
    ID: PropTypes.string.isRequired,
    payoutID: PropTypes.string.isRequired
}

export default PayoutApprovalActionTransfer
