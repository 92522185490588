import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ProductImagesAdder from "./ProductImages/ProductImagesAdder"
import ProductImagesList from "./ProductImages/ProductImagesList"
import ExportProductLineModal from './ExportProductLineModal'

class ProductImages extends Component {

    state = {
        isOpenExport: false,
    }

    _reloadImages = () => {
        return this.props.onFetchListProductLine()
    }

    _toggleExportProductLine = () => this.setState(prev => ({isOpenExport: !prev.isOpenExport}))

    render() {

        const {isOpenExport} = this.state
        const {images, productLineId} = this.props

        return (
            <div className='ProductImages mt-3'>
                <ExportProductLineModal isOpen={isOpenExport} toggle={this._toggleExportProductLine} productLineId={productLineId} />
                <div className="d-flex align-items-center justify-content-between">
                    <h3 className="SectionHeading">Images</h3>
                    <button className="btn btn-primary mb-2" onClick={this._toggleExportProductLine}>
                        Export product line
                    </button>
                </div>
                <div className="SectionInner">
                    <ProductImagesAdder productLineId={productLineId} reloadImages={this._reloadImages} />
                    <ProductImagesList productLineId={productLineId} images={images} reloadImages={this._reloadImages}/>
                </div>
            </div>
        )
    }
}

ProductImages.propTypes = {
    productLineId: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired
}

export default ProductImages
