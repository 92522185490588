import React, { useState } from 'react'
import ObjectInspector from 'react-object-inspector'
import JsonInputCpn from './JsonInputCpn'
import ModalCommon from './ModalCommon'
import TableSkeleton from './TableSkeleton'
import { UncontrolledTooltip } from 'reactstrap'

const headers = [
    {
        title: 'Name',
        styles: { minWidth: '100px' }
    },
    {
        title: 'Description',
        styles: { minWidth: '300px' }
    },
    {
        title: 'Headers',
        styles: { minWidth: '300px' }
    },
    {
        title: 'Payload',
        styles: { minWidth: '300px' }
    },
    {
        title: 'Actions',
        styles: { textAlign: 'center' }
    }
]

const initDetail = {
    visible: false,
    data: null
}
const TableEvents = (props) => {
    const { loading, data, onDelete, onClickEdit } = props
    const [detail, setDetail] = useState(initDetail)

    return (
        <>
            <thead className="thead-light">
                <tr>
                    {headers.map((header) => (
                        <th
                            style={{ fontSize: '20px', padding: '20px 12px', ...header.styles }}
                            className={header.classes}
                            key={header.title}
                        >
                            {header.title}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className="CollectionTableBody TableLoading">
                {loading ? (
                    <TableSkeleton columnQuantity={headers.length} />
                ) : data.length === 0 ? (
                    <tr className="text-center">
                        <td colSpan={4}>
                            <i>No results</i>
                        </td>
                    </tr>
                ) : (
                    data.map((event) => (
                        <tr event={event} key={`event_item_${event._id}`}>
                            <td /* style={{ textDecoration: 'underline', color: '#4242ff' }} */>
                                {event.name}
                            </td>
                            <td style={{ width: '300px' }}>
                                {event.description}
                            </td>
                            <td
                                onClick={() => {
                                    setDetail({
                                        visible: true,
                                        data: JSON.parse(event.headers || '{}'),
                                    })
                                }}
                            >
                                <div style={{ pointerEvents: 'none' }}><ObjectInspector data={JSON.parse(event.headers || '{}')} /></div>
                            </td>
                            <td
                                onClick={() => {
                                    setDetail({
                                        visible: true,
                                        data: JSON.parse(event.payload || '{}'),
                                    })
                                }}
                            >
                                <div style={{ pointerEvents: 'none' }}><ObjectInspector data={JSON.parse(event.payload || '{}')} /></div>
                            </td>
                            <td className="w-25 text-center d-flex">
                                <i
                                    id="ButtonWebhookEventEdit"
                                    style={ { cursor: 'pointer', padding: '6px', margin: '0 6px' }}
                                    className={'fa fa-edit text-primary'}
                                    onClick={() => onClickEdit(event)}
                                />

                                <i
                                    id="ButtonWebhookEventDelete"
                                    style={{ cursor: 'pointer', padding: '6px', margin: '0 6px' }}
                                    onClick={() => onDelete(event._id)}
                                    className={'fa fa-trash text-danger'}
                                />
                                <UncontrolledTooltip target="ButtonWebhookEventEdit">Edit</UncontrolledTooltip>
                                <UncontrolledTooltip target="ButtonWebhookEventDelete">Delete</UncontrolledTooltip>
                            </td>
                        </tr>
                    ))
                )}
            </tbody>
            {detail.data && detail.visible && (
                <ModalCommon
                    isOpen={detail.visible}
                    hideSubmit={true}
                    onSubmit={() =>
                        setDetail(initDetail)
                    }
                    onClose={() =>
                        setDetail(initDetail)
                    }
                >
                    <JsonInputCpn placeholder={detail.data} viewOnly={true} />
                </ModalCommon>
            )}
        </>
    )
}

export default TableEvents

