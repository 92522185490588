export default (number, round = 0) => {
    const vNumber = Number(number)

    if (!vNumber) return 0

    return vNumber.toLocaleString(undefined, {
        minimumFractionDigits: round,
        maximumFractionDigits: round,
    })
}
