import React from 'react'
import SettingStoragePage from './SettingStoragePage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import {isSuperAdmin} from '../../../services/AuthServices'

const SettingStorageContainer = () => {

    const checkRole = isSuperAdmin()

    if (!checkRole) return null

    return (
        <div className="SettingS3ImageContainer">
            <DocTitleByStore title="Storage settings"/>
            <SettingStoragePage/>
        </div>
    )
}

export default SettingStorageContainer
