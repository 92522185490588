import React, {Component} from 'react'

class DnsRecordHead extends Component {
    render() {
        return (
            <thead className="DnsRecordHead thead-light">
                <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Content</th>
                </tr>
            </thead>
        )
    }
}

export default DnsRecordHead
