import React, {Component} from 'react'
import PropTypes from 'prop-types'

class PFBoxUploader extends Component {
    state = {
        dragging: false,
    }

    dropRef = React.createRef()

    componentDidMount() {
        const div = this.dropRef.current
        this.dragCounter = 0

        div.addEventListener('dragenter', this._handleDragIn)
        div.addEventListener('dragleave', this._handleDragOut)
        div.addEventListener('dragover', this._handleDrag)
        div.addEventListener('drop', this._handleDrop)
    }

    componentWillUnmount() {
        const div = this.dropRef.current

        div.removeEventListener('dragenter', this._handleDragIn)
        div.removeEventListener('dragleave', this._handleDragOut)
        div.removeEventListener('dragover', this._handleDrag)
        div.removeEventListener('drop', this._handleDrop)
    }

    _handleDragIn = e => {
        e.preventDefault()
        e.stopPropagation()

        this.dragCounter++

        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({dragging: true})
        }
    }

    _handleDragOut = e => {
        e.preventDefault()
        e.stopPropagation()

        this.dragCounter--

        if (this.dragCounter > 0) return

        this.setState({dragging: false})
    }

    _handleDrag = e => {
        e.preventDefault()
        e.stopPropagation()
    }

    _handleDrop = e => {
        e.preventDefault()
        e.stopPropagation()

        this.setState({dragging: false}, () => {
            this.props.onChange(e.dataTransfer.files)
        })
    }

    _handleChangeFile = e => {
        const {files} = e.target

        this.props.onChange(files)
    }

    render() {
        const {multiple, id, children,disabled} = this.props

        return (
            <div className='PFBoxUploader' ref={this.dropRef}>
                <input
                    disabled={disabled}
                    type='file'
                    accept='image/png, image/jpeg, image/jpg, image/gif'
                    multiple={multiple}
                    onChange={this._handleChangeFile}
                    style={{width: 100}}
                    id={id}
                    className='d-none'
                />
                <label htmlFor={id} className='d-block mb-0 cursor-pointer'>
                    {
                        children || 'Upload'
                    }
                </label>
            </div>
        )
    }
}

PFBoxUploader.defaultProps = {
    multiple: false,
    disabled: false
    // listType: 'picture'
}

PFBoxUploader.propTypes = {
    // listType: PropTypes.oneOf(['picture', 'picture-card']),
    id: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
}

export default PFBoxUploader
