import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PFTableV2 from '../../shared/components/table/v2'
import { UncontrolledTooltip } from 'reactstrap'
import ConfirmTrademarkModal from './ConfirmTrademarkModal'
import ConfirmWhitelistModal from './ConfirmWhitelistModal'
import ReactDOM from 'react-dom'
import { bulkMarkAsRead } from '../../../services/api/TrademarkSevices'
import { toaster } from '../../shared/PFToast'
import ConfirmRead from './ConfirmRead'
import ConfirmUnread from './ConfirmUnread'

class TrademarkTableList extends Component {

    state = {
        whitelist: {
            id: '',
            open: false,
        },
        trademark: {
            id: '',
            open: false,
        },
        read: {
            id: '',
            open: false,
        },
        unread: {
            id: '',
            open: false,
        }
    }

    tableRef = React.createRef()

    _columns = [
        {
            title: 'Store',
            dataIndex: 'slug',
            key: 'store',
            render: data => {
                return (
                    <>
                        <span>{data.store_slug}</span> <br />
                        <a href={data.store_domain} target="_blank">{data.store_domain}</a>
                    </>
                )
            }
        },
        {
            title: 'Items trademark',
            dataIndex: 'itemTrademark',
            key: 'itemTrademark',
            className: 'ItemTrademark',
            render: data => {
                return (
                    <div className='row item-trademark align-items-center'>
                        <div className='col-3 item-trademark-image'>
                            <img src={this.filterDataByType(data.library_elements, "IMAGE")[0]?.url} alt="" />
                        </div>
                        <div className='col-9 item-trademark-text'>
                            {
                                this.filterDataByType(data.library_elements).map(item => (
                                    <p key={item.id} className="mb-0"><span className='font-weight-bold'>{item?.parent_id ? "Text image" : "Text title"}: </span>{item.content}</p>
                                ))
                            }
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'Evidence',
            dataIndex: 'evidence',
            key: 'evidence',
            className: 'ItemTrademark',
            render: data => {
                return (
                    <div className='item-trademark d-flex align-items-center'>
                        <div className='item-trademark-text ml-2'>
                            {
                                this.filterDataByType(data.trademarked_library_elements).map(item => (
                                    <p key={item.id} className="mb-0"><span className='font-weight-bold'>{item?.parent_id ? "Text image" : "Text title"}: </span>{item.trademarked_content}</p>
                                ))
                            }
                            {
                                this.filterDataByType(data.library_elements, "IMAGE")[0]?.trademarked_content && <p className="mb-0"><span className='font-weight-bold'>Logo image: </span>{this.filterDataByType(data.library_elements, "IMAGE")[0]?.trademarked_content}</p>
                            }
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
            className: 'OrderList',
            render: data => {
                return (
                    <span>{data.related_orders.join(" ")}</span>
                )
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: data => {
                return (
                    <span>{data.is_whitelist ? "Whitelist" : "Trademark"}</span>
                )
            }
        },
        {
            title: 'Action',
            render: data => {
                return (
                    <div key={data._id} className='d-flex align-items-center actions'>
                        {
                            !data.is_read && <div className='btn-mark-as-unread'>
                                <a id={`mark-as-unread-${data._id}`} onClick={() => this._markAsRead([data._id], true)}><i className='fa fa-envelope text-primary mr-2' /></a>
                                <UncontrolledTooltip placement="top" target={`mark-as-unread-${data._id}`}>
                                    Mark as unread
                                </UncontrolledTooltip>
                            </div>
                        }
                        {
                            data.is_read && <div className='btn-mark-as-read'>
                                <a id={`mark-as-read-${data._id}`} onClick={() => this._markAsRead([data._id], false)}><i className='fa fa-envelope-open text-primary mr-2' /></a>
                                <UncontrolledTooltip placement="top" target={`mark-as-read-${data._id}`}>
                                    Mark as read
                                </UncontrolledTooltip>
                            </div>
                        }
                        {
                            !data.is_whitelist && <div className='btn-check'>
                                <a id={`check-${data._id}`} onClick={() => this._toggleWhitelist([data._id])}><i className='fa fa-check text-success mr-2' /></a>
                                <UncontrolledTooltip placement="top" target={`check-${data._id}`}>
                                    Whitelist
                                </UncontrolledTooltip>
                            </div>
                        }
                        {
                            data.is_whitelist && <div className='btn-trade-mark'>
                                <a id={`trade-mark-${data._id}`} onClick={() => this._toggleTrademark([data._id])}><i className='fas fa-trademark mr-2' /></a>
                                <UncontrolledTooltip placement="top" target={`trade-mark-${data._id}`}>
                                    Trademark
                                </UncontrolledTooltip>
                            </div>
                        }
                    </div>

                )
            }
        }
    ]
    _selections = [
        {
            key: 'bulk_mark_as_unread',
            text: 'Mark as unread',
            onSelect: async (allPagesSelected, selectedIDs) => {
                let el = document.createElement('div')
                this._toggleUnread(selectedIDs)
            },
        },
        {
            key: 'bulk_mark_as_read',
            text: 'Mark as read',
            onSelect: async (allPagesSelected, selectedIDs) => {
                let el = document.createElement('div')
                this._toggleRead(selectedIDs)
            },
        },
        {
            key: 'bulk_whitelist',
            text: 'Mark as whitelist',
            onSelect: async (allPagesSelected, selectedIDs) => {
                let el = document.createElement('div')
                this._toggleWhitelist(selectedIDs)
            },
        },
        {
            key: 'bulk_trademark',
            text: 'Mark as trademark',
            onSelect: (allPagesSelected, selectedIDs) => {
                let el = document.createElement('div')
                this._toggleTrademark(selectedIDs)
            },
        },
    ]

    filterDataByType = (listItem = [], type = "TEXT") => {
        return listItem.filter(item => item.type === type)
    }

    _markAsRead = async (ids, enable) => {
        try {
            const { success, message } = await bulkMarkAsRead({ orderItemIds: ids, enable })
            if (success) {
                toaster({
                    type: 'success',
                    message: `Mark product as read successfully!`
                })
                this._onAfterBulkAction()
            } else {
                toaster({
                    type: 'error',
                    message
                })
            }
        } catch (e) {
            console.error(e)
        }
    }

    _onAfterBulkAction = () => {
        this.tableRef.current._clearSelected()
        this.props.onReloadData()
    }

    _toggleWhitelist = (id = '') => {
        this.setState({
            whitelist: {
                id,
                open: !this.state.whitelist.open
            },
        })
    }

    _toggleTrademark = (id = '') => {
        this.setState({
            trademark: {
                id,
                open: !this.state.trademark.open,
            }
        })
    }

    _toggleRead = (id = '') => {
        this.setState({
            read: {
                id,
                open: !this.state.read.open,
            }
        })
    }

    _toggleUnread = (id = '') => {
        this.setState({
            unread: {
                id,
                open: !this.state.unread.open,
            }
        })
    }

    render() {
        const { read, unread, trademark, whitelist } = this.state
        const { orders, loading, totalOrder } = this.props

        return (
            <div className='ProductApprovalTableList table-responsive'>
                <PFTableV2 totalItems={totalOrder}
                    data={orders}
                    columns={this._columns}
                    selections={this._selections}
                    loading={loading}
                    ref={this.tableRef}
                    allowSelectAllPage={false}
                    hasCheckbox={false}
                />
                <ConfirmRead ids={read.id} open={read.open} toggleModal={this._toggleRead} onAfterBulkAction={this._onAfterBulkAction} />
                <ConfirmUnread ids={unread.id} open={unread.open} toggleModal={this._toggleUnread} onAfterBulkAction={this._onAfterBulkAction} />
                <ConfirmTrademarkModal ids={trademark.id} open={trademark.open} toggleTrademark={this._toggleTrademark} onAfterBulkAction={this._onAfterBulkAction} />
                <ConfirmWhitelistModal ids={whitelist.id} open={whitelist.open} toggleWhitelist={this._toggleWhitelist} onAfterBulkAction={this._onAfterBulkAction} />
            </div>
        )
    }
}

TrademarkTableList.propTypes = {
    orders: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    totalOrder: PropTypes.number.isRequired,
    onReloadData: PropTypes.func
}

export default TrademarkTableList
