import React, {Component} from 'react'
import {getAccountDetail} from './../../../../../services/api/StoreAccountServices'
import PropTypes from 'prop-types'

class TransactionOutOwner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            error: ''
        }
    }


    componentDidMount() {
        this._fetchAccountDetail()
    }

    _fetchAccountDetail = async () => {
        try {
            const {owner} = this.props

            const {data, success, message} = await getAccountDetail(owner)

            if(!success) {
                this.setState({
                    error: message
                })
                return
            }

            const accountDetail = Object.assign({}, data[0])
            const {name, email} = accountDetail

            this.setState({
                name,
                email
            })
        } catch (e) {
            this.setState({
                error: e.message
            })
        }
    }

    render() {
        const {name, email} = this.state

        return (
            <td className="TransactionOutOwner text-right">
                {name} ({email})
            </td>
        )
    }
}

TransactionOutOwner.propTypes = {
    owner: PropTypes.string.isRequired,
}

export default TransactionOutOwner
