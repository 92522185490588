import React, {Component} from 'react'

class AffiliateTableHead extends Component {
    render() {
        return (
            <thead className='AffiliateTableHead thead-light'>
            <tr>
                <th className='table-col-20'>ID</th>
                <th className='table-col-30'>Title</th>
                <th className='table-col-10 text-right'>Percentage</th>
                <th className='table-col-20 pl-4'>Update</th>
                <th className='table-col-20'>Actions</th>
            </tr>
            </thead>
        )
    }
}

export default AffiliateTableHead
