import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ModalBody from 'reactstrap/es/ModalBody'
import ModalFooter from 'reactstrap/es/ModalFooter'
import Modal from 'reactstrap/es/Modal'
import {changeStoreProvider} from '../../../../services/api/InitializeStoreInvoiceService'

class MigrationDetailModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedProvider: props.provider,
            loading: false,
            error: '',
        }
    }

    _onToggleModal = () => {
        const {provider} = this.props
        this.setState({
            selectedProvider: provider,
        })
        this.props.onToggleModal()
    }

    _handleUpdateSelectedProvider = (e) => {
        this.setState({
            selectedProvider: e.target.value,
        })
    }

    _handleClickSave = e => {
        e.preventDefault()
        this._handleSaveProvider()
    }

    _handleSaveProvider = async () => {
        const {storeId, onToggleModal, onUpdateProvider} = this.props
        const {selectedProvider} = this.state

        this.setState({
            loading: true,
        })

        try {
            const {success, message} = await changeStoreProvider(storeId, selectedProvider)

            if (!success) {
                return this.setState({
                    error: message,
                    loading: false,
                })
            }

            onUpdateProvider(selectedProvider)

            return this.setState(
                {
                    loading: false,
                    error: '',
                },
                () => {
                    onToggleModal()
                },
            )
        } catch (e) {
            return this.setState({
                loading: false,
                error: e.message,
            })
        }
    }

    render() {
        const {showModal, onToggleModal} = this.props
        const {selectedProvider, loading, error} = this.state

        return (
            <Modal isOpen={showModal} toggle={onToggleModal}>
                <ModalBody>
                    <label className='mb-2'>Provider</label>

                    <select
                        className="form-control"
                        value={selectedProvider}
                        onChange={this._handleUpdateSelectedProvider}
                    >
                        <option value='' disabled>-- Choose a provider --</option>
                        <option value="eks">eks</option>
                        <option value="gke">gke</option>
                    </select>

                    {error && <div className='text-danger mt-3'>{error}</div>}
                </ModalBody>
                <ModalFooter>
                    <button className='btn border' onClick={this._onToggleModal}>
                        Cancel
                    </button>
                    {loading ? (
                        <button className='btn btn-primary ml-3 disabled'>Saving...</button>
                    ) : (
                        <button
                            className="btn btn-primary ml-2"
                            onClick={this._handleClickSave}>
                            Save
                        </button>
                    )}
                </ModalFooter>
            </Modal>
        )
    }
}

MigrationDetailModal.propTypes = {
    provider: PropTypes.string,
    showModal: PropTypes.bool.isRequired,
    storeId: PropTypes.string.isRequired,
    onUpdateProvider: PropTypes.func.isRequired,
    onToggleModal: PropTypes.func.isRequired,
}

export default MigrationDetailModal
