import React, { Component } from "react"
import SettingSectionDetails from "../../setting/shared/SettingSectionDetails"
import CdnSettings from "./CdnSettings"
import SitesSettings from "./SitesSettings"
import {
    getSettingsByCategory,
    removeBannerImage,
    saveSettingsByCategory,
    uploadBannerImage,
} from "../../../services/api/SettingServices"
import SettingBanner from "./SettingBanner"
import { toaster } from "../../shared/PFToast"
import { isSuperAdmin } from "../../../services/AuthServices"
import SettingExchangeRate from "./SettingExchangeRate"

class SettingGeneralPage extends Component {
    state = {
        settings: {
            product_images_cdn: "",
            cdn_prefix_image: "",
            cdn_prefix_assets: "",
            admin_host: "",
            seller_host: "",
            banner_dashboard_url: "",
            banner_image_desktop: "",
            banner_image_tablet: "",
            banner_image_mobile: "",
            exchange_rate: 0,
            exchange_apply_time: "",
            exchange_notification_time: "",
        },
        validateExchageDate: {
            exchange_rate: 0,
            exchange_apply_time: "",
            exchange_notification_time: "",
        },
        loading: {
            fetchSettings: false,
            saveSettings: false,
        },
        err: {
            exchange_rate: "",
            exchange_apply_time: "",
        },
    }

    componentWillMount() {
        this._fetchSettings()
    }

    _onClickSaveButton = () => {
        this._saveSettings()
    }

    _onChangeSetting = (key, value) => {
        this.setState(({ settings }) => ({
            settings: {
                ...settings,
                [key]: key === "exchange_rate" ? value.floatValue : value,
            },
        }))
        const message = validateField(key, value)
        this.setState(({ err }) => ({
            err: {
                ...err,
                [key]: message,
            },
        }))
    }

    convertDataExchange = (data) => {
        const { exchange_rate, exchange_apply_time, exchange_notification_time } = data
        return {
            exchange_rate,
            exchange_apply_time: exchange_apply_time ? new Date(exchange_apply_time) : null,
            exchange_notification_time: exchange_notification_time ? new Date(exchange_notification_time) : null,
        }
    }

    _fetchSettings = async () => {
        this.setState(({ loading }) => ({
            loading: {
                ...loading,
                fetchSettings: true,
            },
        }))

        try {
            const { data, success, err } = await getSettingsByCategory("general")

            if (success)
                return this.setState(({ loading, settings }) => ({
                    loading: {
                        ...loading,
                        fetchSettings: false,
                    },
                    settings: {
                        ...settings,
                        ...data,
                        ...this.convertDataExchange(data),
                    },
                    validateExchageDate: this.convertDataExchange(data)
                }))

            alert(err)
        } catch (e) {
            alert(e.message || e)
            this.setState(({ loading }) => ({
                loading: {
                    ...loading,
                    fetchSettings: false,
                },
            }))
        }
    }

    checkExchangeDate = (state) => {
        const { settings, validateExchageDate } = state
        const now = Date.now()
        const newExchangeRate = settings.exchange_rate
        const currExchangeRate = validateExchageDate.exchange_rate
        const newApplyTime = new Date(settings.exchange_apply_time).getTime()
        const newNotificationTime = new Date(settings.exchange_notification_time).getTime()
        const currApplyTime = new Date(validateExchageDate.exchange_apply_time).getTime()
        const currNotificationTime = new Date(validateExchageDate.exchange_notification_time).getTime()
        if (((newExchangeRate !== currExchangeRate || newApplyTime !== currApplyTime) && newApplyTime < now) || (settings.exchange_notification_time && (newNotificationTime !== currNotificationTime) && newNotificationTime < now)) {
            throw new Error("Apply time or notification time must be future date")
        }
        return true
    }

    callApiSave = async (settings) => {
        const exchangeNotificationTime = settings.exchange_notification_time ? new Date(settings.exchange_notification_time).toISOString() : null
        const payloadPlainSettings = {
            product_images_cdn: settings.product_images_cdn,
            cdn_prefix_image: settings.cdn_prefix_image,
            cdn_prefix_assets: settings.cdn_prefix_assets,
            admin_host: settings.admin_host,
            seller_host: settings.seller_host,
            banner_dashboard_url: settings.banner_dashboard_url,
            exchange_rate: settings.exchange_rate,
            exchange_apply_time: new Date(settings.exchange_apply_time).toISOString(),
            exchange_notification_time: exchangeNotificationTime
        }
        return saveSettingsByCategory("general", payloadPlainSettings)
    }

    _saveSettings = async () => {
        const errorObj = validateForm(this.state.settings, this.state.err, validateField)
        this.setState({ err: errorObj })
        if (Object.keys(errorObj).length !== 0) {
            return
        }
        this.setState(({ loading }) => ({
            loading: {
                ...loading,
                saveSettings: true,
            },
        }))

        try {
            const { settings } = this.state
            this.checkExchangeDate(this.state)
            const { data, err } = await this.callApiSave(settings)
            this.setState(({ loading }) => ({
                loading: {
                    ...loading,
                    saveSettings: false,
                },
                settings: {
                    ...settings,
                    ...data,
                },
            }))

            if (err) throw new Error(err)

            toaster({ type: "success", message: "Updated successfully!" })
        } catch (e) {
            toaster({ type: "error", message: e.message })

            this.setState(({ loading }) => ({
                loading: {
                    ...loading,
                    saveSettings: false,
                },
            }))
        }
    }

    _handleUploadBanner = async (type, image) => {
        try {
            const formData = new FormData()
            formData.append("field", type)
            formData.append("image", image)
            const { data, success, message } = await uploadBannerImage(formData)
            if (!success) {
                toaster({ type: "error", message })
                return
            }

            toaster({ type: "success", message: "Updated successfully!" })
            this._onChangeSetting(type, data)
        } catch (e) {
            toaster({ type: "error", message: e.message })
        }
    }

    _handleRemoveBanner = async (type) => {
        try {
            const { success, message } = await removeBannerImage(type)
            if (!success) {
                toaster({ type: "error", message })
                return
            }

            toaster({ type: "success", message: "Updated successfully!" })
            this._onChangeSetting(type, "")
        } catch (e) {
            toaster({ type: "error", message: e.message })
        }
    }

    render() {
        const { settings, loading, err } = this.state

        return (
            <div className="SettingGeneralPage">
                {isSuperAdmin() && (
                    <>
                        <div className="Section">
                            <SettingSectionDetails title="CDN" />
                            <div className="SectionSetting">
                                <div className="SectionInner">
                                    <CdnSettings
                                        settings={settings}
                                        onChangeSetting={this._onChangeSetting}
                                        disabled={loading.fetchSettings}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="Section">
                            <SettingSectionDetails title="Sites" />
                            <div className="SectionSetting">
                                <div className="SectionInner">
                                    <SitesSettings
                                        settings={settings}
                                        onChangeSetting={this._onChangeSetting}
                                        disabled={loading.fetchSettings}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div className="Section">
                    <SettingExchangeRate
                        error={err}
                        exchangeRate={settings.exchange_rate}
                        exchangeApplyTime={settings.exchange_apply_time}
                        exchangeNotificationTime={settings.exchange_notification_time}
                        onChangeSetting={this._onChangeSetting}
                    />
                    <SettingBanner
                        bannerDashboardUrl={settings.banner_dashboard_url}
                        bannerImageDesktop={settings.banner_image_desktop}
                        bannerImageTablet={settings.banner_image_tablet}
                        bannerImageMobile={settings.banner_image_mobile}
                        onChangeSetting={this._onChangeSetting}
                        onUploadBanner={this._handleUploadBanner}
                        onRemoveBanner={this._handleRemoveBanner}
                    />
                </div>

                <div className="ButtonSave">
                    <button
                        className="btn btn-primary"
                        onClick={this._onClickSaveButton}
                        disabled={loading.saveSettings || loading.fetchSettings}
                    >
                        <span className="Label">Save</span>
                    </button>
                </div>
            </div>
        )
    }
}

const validateField = (name, value) => {
    let errorMsg = null
    switch (name) {
        case "exchange_rate":
            if (!value) errorMsg = "This field is required"
            // if (value == 0) errorMsg = 'Amount must greater than 0'
            break
        case "exchange_apply_time":
            if (!value) errorMsg = "This field is required"
            break
        default:
            break
    }
    return errorMsg
}

const validateForm = (form, formError, validateFunc) => {
    const errorObj = {}
    Object.keys(formError).map((x) => {
        const message = validateFunc(x, form[x])
        if (message) errorObj[x] = message
    })
    return errorObj
}

export default SettingGeneralPage
