import React, {Component} from 'react'
import PropTypes from 'prop-types'
import StoreTransactionsRow from './StoreTransactionsRow'
import PubSub from '../../StoreBroker'
import {searchTransactionsOut} from '../../../../services/api/BillingServices'
import PFPagePagination from '../../../shared/PFPagePagination'

class StoreTransactionsTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            transactions: null,
            pages: 1,
            filters: {
                page: 1,
                limit: 10,
            },
            error: '',
        }
    }

    _subscription = null

    componentDidMount() {
        this._fetchListTransactionOut()

        this._subscription = PubSub.subscribe('BILLING_FETCH_TRANSACTION_OUT', () => {
            this._fetchListTransactionOut()
        })
    }

    componentWillUnmount() {
        this._subscription && PubSub.unsubscribe(this._subscription)
    }

    _handleChangePage = (page) => {
        this.setState(({filters}) => {
            return {
                filters: {
                    ...filters,
                    page,
                },
            }
        }, this._fetchListTransactionOut)
    }

    _fetchListTransactionOut = async () => {
        try {
            const {filters} = this.state
            const {page, limit} = filters

            const params = {
                page,
                limit,
            }

            const {data, success, message} = await searchTransactionsOut(params)

            if (!success) {
                this.setState({
                    error: message,
                })
                return
            }

            const {transactions, pages} = data

            this.setState({
                transactions,
                pages,
                error: '',
            })
        } catch (e) {
            this.setState({
                error: e.message,
            })
        }
    }

    render() {
        const {transactions, filters, pages, error} = this.state
        const {page} = filters

        return (
            <div className="TransactionOutTableContainer">
                {(error !== '') && (
                    <div className="alert alert-danger fade show">{error}</div>
                )}
                <table className="table table-striped table-hover">
                    <thead className="thead-light">
                    <tr>
                        <th className="table-col-20">Created</th>
                        <th className="table-col-30">Transaction ID</th>
                        <th>Note</th>
                        <th className='table-col-10'>Status</th>
                        <th className="table-col-10 text-right">Amount</th>
                        <th className="table-col-10 text-right">Account</th>
                        <th className='Actions text-right'>Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        Array.isArray(transactions) &&
                        <>
                            {
                                transactions.length === 0 ?
                                    <tr>
                                        <td colSpan={10}><em>No results</em></td>
                                    </tr>
                                    :
                                    transactions.map(transaction => {
                                        return <StoreTransactionsRow
                                            key={transaction._id}
                                            store={this.props.store}
                                            transaction={transaction}
                                            onFetchTransactions={this._fetchListTransactionOut}
                                        />
                                    })
                            }
                        </>
                    }
                    </tbody>

                </table>

                <PFPagePagination page={page} pages={pages} onChangePage={this._handleChangePage}/>
            </div>
        )
    }

}

StoreTransactionsTable.propTypes = {
    store: PropTypes.object.isRequired,
}

export default StoreTransactionsTable
