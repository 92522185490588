import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const InputEditor = (props) => {
    const {label, error, value, name, onChange, readOnly} = props

    const handleChangeInput = (e) => {
        e.preventDefault()
        const value = e.target.value
        onChange(value, name)
    }

    return (
        <div className="form-group">
            <label className='font-weight-bold'>{label}</label>
            <input type="text" className={classNames('form-control', {'is-invalid': error})}
                   value={value}
                   onChange={handleChangeInput}
                   readOnly={readOnly}
            />
            {
                error &&
                <div className="invalid-feedback">
                    {error}
                </div>
            }
        </div>
    )
}

InputEditor.defaultProps = {
    label: '',
    name: '',
    value: '',
    error: ''
}

InputEditor.propTypes = {
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func
}
export default InputEditor
