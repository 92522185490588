export const createOption = (label) => ({
    label,
    value: label,
})

export const createOptionsFromArray = (product_types = []) => {
    return product_types.map((type) => {
        return {
            label: type,
            value: type,
        }
    })
}

export const createDefaultOptionStore = (store) => {
    if (store._id) {
        return {
            value: store._id,
            label: store.slug + ' - ' + store.domain,
        }
    } else {
        return {
            value: '',
            label: '',
        }
    }
}