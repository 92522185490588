export default [
    'platform126/clustering',
    'platform126/manufacturer',
    'platform126/monitoring',
    'platform126/fulfillment',
    'platform126/manager',
    'platform126/recommendation',
    'platform126/backoffice',
    'platform126/storefront',
]
