import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import _ from 'lodash'
import moment from 'moment/moment'
import classNames from 'classnames'

class StageDetails extends Component {
    state = {}

    _onToggle = () => {
        this.props.onClose()
    }

    render() {
        const {pipeline, stage, open, job} = this.props
        return (
            <Modal size='lg' isOpen={open} toggle={this._onToggle} className="PlanModal">
                <ModalHeader toggle={this._onToggle}>Stage Details</ModalHeader>
                <ModalBody>
                    <div className='StoresPage'>
                    <ul className='list-group list-group-flush'>
                            <li className='list-group-item'>
                                <span className='font-weight-bold'>Job: </span>{_.get(job, 'name', 'deployment')}
                            </li>
                            <li className='list-group-item'>
                                <span className='font-weight-bold'>Triggerer: </span>{_.get(job, 'trigger_by', 'system')}
                            </li>
                            <li className='list-group-item'>
                                <span className='font-weight-bold'>Pipeline: </span> {_.get(pipeline, 'name')}
                            </li>
                            <li className='list-group-item'>
                                <span className='font-weight-bold'>Stage: </span> {_.get(stage, 'name')}
                            </li>
                            <li className='list-group-item'>
                                <span className='font-weight-bold'>Error: </span> {JSON.stringify(_.get(pipeline, 'last_error.reason', {}))}
                            </li>
                        </ul>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

StageDetails.propTypes = {
    pipeline: PropTypes.object.isRequired,
    stage: PropTypes.object.isRequired,
}

export default StageDetails
