import React, {Component} from 'react'
import PropTypes from 'prop-types'
import BackNavigationButton from '../../../shared/BackNavigationButton'
import {getResourceDetails} from '../../../../services/api/ResourceService'
import ResourceDetail from './ResourceDetail'
import ResourceAssignedList from './ResourceAssignedList'

class ResourceDetailPage extends Component {
    state = {
        resourceDetails: {},
        err: '',
        loading: true,
    }

    componentDidMount() {
        this._getResourceDetails()
    }

    _getResourceDetails = async () => {
        try {
            const {resourceId} = this.props
            const {success, data, message} = await getResourceDetails(resourceId)

            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }

            const resourceDetails = Object.assign({}, data)
            this.setState({
                resourceDetails,
                loading: false,
                err: '',
            })
        } catch (e) {
            this.setState({
                err: e.message || e,
                loading: false,
            })
        }
    }

    _onUpdatedAssignedList = () => {
        this._getResourceDetails()
    }

    render() {
        const {resourceDetails} = this.state
        return (
            <div className="StoreDetailPage">
                <BackNavigationButton to="/a/resource-settings" text="resources" />
                <ResourceDetail resource={resourceDetails}></ResourceDetail>
                <ResourceAssignedList
                    resourceId={this.props.resourceId}
                    onUpdated={this._onUpdatedAssignedList}
                    dbKind={resourceDetails?.kind}
                ></ResourceAssignedList>
            </div>
        )
    }
}

ResourceDetailPage.propTypes = {
    resourceId: PropTypes.string.isRequired,
}

export default ResourceDetailPage
