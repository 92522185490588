import React, {Component} from 'react'
import PropTypes from 'prop-types'
class PlanFeatures extends Component {
    state = {
        values: [],
    }

    componentDidMount() {
        this._mapFeaturesToFeatureValues()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.features.length && this.props.features.length) return this._mapFeaturesToFeatureValues()
        if (prevProps.plan._id !== this.props.plan._id) return this._mapFeaturesToFeatureValues()
    }

    _mapFeaturesToFeatureValues = () => {
        const {features, plan} = this.props
        const values = features
            .map((feature) => {
                return {feature: feature._id, text_value: '', is_supported: false, featureDetails: feature}
            })
            .map((value) => {
                if (!plan.features) return value

                const existValue = plan.features.find(
                    (feature) => feature.feature && feature.feature._id === value.feature
                )
                if (!existValue) return value
                return {
                    ...value,
                    ...{is_supported: existValue.is_supported, text_value: existValue.text_value},
                }
            })

        this.setState({values}, () => this.props.onChangeFeatureValues(this.state.values))
    }

    _onChangeValueCheckbox = (featureValue, key) => (e) => {
        const {checked} = e.target

        return this._changeFeatureValue(featureValue, key, checked)
    }

    _onChangeValueInput = (featureValue, key) => (e) => {
        const {value} = e.target
        return this._changeFeatureValue(featureValue, key, value)
    }

    _changeFeatureValue = (featureValue, key, value) => {
        const values = this.state.values.map((currentFeatureValue) => {
            if (currentFeatureValue.feature === featureValue.feature) return {...currentFeatureValue, ...{[key]: value}}
            return currentFeatureValue
        })

        this.setState({values}, () => this.props.onChangeFeatureValues(this.state.values))
    }

    render() {
        const {values} = this.state

        return (
            <div className="PlanFeatures">
                <div className="Title">Plan features</div>
                <div className="FeaturesTable">
                    <table className="FeaturesWrapper">
                        <tbody>
                            {values.map((value) => (
                                <tr className="Feature" key={value.feature}>
                                    <td className="FeatureTitle">{value.featureDetails.name}</td>
                                    <td className="FeatureValue">
                                        <div className="TextValue">
                                            <label htmlFor={`text-value-${value.feature}`}>Text Value</label>
                                            <input
                                                id={`text-value-${value.feature}`}
                                                value={value.text_value || ''}
                                                onChange={this._onChangeValueInput(value, 'text_value')}
                                            />
                                        </div>
                                    </td>
                                    <td className="FeatureSupport">
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                onChange={this._onChangeValueCheckbox(value, 'is_supported')}
                                                checked={value.is_supported}
                                                id={`supported_${value.feature}`}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={`supported_${value.feature}`}
                                            >
                                                Support
                                            </label>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

PlanFeatures.propTypes = {
    features: PropTypes.array.isRequired,
    plan: PropTypes.object.isRequired,
    onChangePlan: PropTypes.func.isRequired,
    onChangeFeatureValues: PropTypes.func.isRequired,
}

export default PlanFeatures

