import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ProductGalleryShowMore extends Component {
    _handleClick = e => {
        e.preventDefault()

        this.props.onClickShowAll()
    }

    _getContent = () => {
        const {showAll, gallery} = this.props

        if (gallery.length < 9 || showAll === true) return null

        return (
            <>
                <div className='Text'>Show more</div>
                <div className='Icon mt-n1 text-success'><i className='ti-angle-double-down'/></div>
            </>
        )
    }

    render() {
        const content = this._getContent()

        return (
            <div className='ProductGalleryShowMore text-center mt-4'>
                <div className='Content d-inline-block cursor-pointer' onClick={this._handleClick}>
                    {content}
                </div>
            </div>
        )
    }
}

ProductGalleryShowMore.propTypes = {
    showAll: PropTypes.bool.isRequired,
    gallery: PropTypes.array.isRequired,
    onClickShowAll: PropTypes.func.isRequired,
}

export default ProductGalleryShowMore
