import React, {Component} from 'react'
import ModalHeader from "reactstrap/es/ModalHeader"
import ModalBody from "reactstrap/es/ModalBody"
import ModalFooter from "reactstrap/es/ModalFooter"
import Modal from "reactstrap/es/Modal"
import {
    changeVariantImageSides
} from "../../../../../services/api/ProductServices"
import SortableContainerVariantImage from "./SortableContainerVariantImage"
import arrayMove from 'array-move'
import {getVariantImagesProductRevision} from '../../../../../services/api/ProductApprovalServices'


class ListVariantImages extends Component {
    state = {
        listImages: [],
        selectedSide: "",
        loading: false,
        error: "",
    }

    _error = null

    componentDidMount() {
        this._fetchListVariantImage()
    }


    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(({listImages}) => {
            return {
                listImages: arrayMove(listImages, oldIndex, newIndex),
            }
        })
    }

    _handleUpdateError = (newError) => {
        this.setState({
            error: newError,
        })
    }

    _fetchListVariantImage = async () => {
        const {variantId, revisionId, productId} = this.props

        try {
            const {data, success, message} = await getVariantImagesProductRevision(productId, revisionId, variantId)

            if (!success) {
                this._error = message

                this.setState({
                    error: message,
                    loading: false,
                })

                return false
            }

            this.setState({
                listImages: data,
                loading: false,
                error: "",
            })

            return true

        } catch (e) {
            this._error = e.message

            this.setState({
                error: e.message,
                loading: false,
            })

            return false
        }
    }

    _handSaveVariantImage = async () => {
        try {
            this.setState({
                loading: true,
            })

            const {variantId} = this.props
            const {listImages} = this.state
            const sides = listImages.map(image => image.side)

            const {success, message} = await changeVariantImageSides(variantId, sides)

            if (!success) {
                this._error = message

                this.setState({
                    error: message,
                    loading: false,
                })

                return false
            }

            this.setState({
                loading: false,
                error: "",
            })

            return true

        } catch (e) {
            this._error = e.message

            this.setState({
                error: e.message,
                loading: false,
            })

            return false
        }
    }

    _handleClickSave = async e => {
        e.preventDefault()

        const isSaved = await this._handSaveVariantImage()
        const {variantPage} = this.props

        if (isSaved) {
            this.props.onGetVariants(variantPage)
            this.props.onCloseImageModal()
        }

    }

    render() {
        const {variantId, onCloseImageModal} = this.props
        const {listImages, loading, error} = this.state

        return (
            <Modal isOpen={!!variantId} className="ListVariantImages modal-dialog-centered">
                <ModalHeader toggle={onCloseImageModal}>
                    Upload variant image
                    <div className="text-muted fs-14">Maximum upload file size: 4MB</div>
                </ModalHeader>

                <ModalBody>
                    <div className="overflow-auto">
                        <SortableContainerVariantImage images={listImages} variantId={variantId}
                                                       onSortEnd={this.onSortEnd} axis="xy"
                                                       useDragHandle
                                                       onFetchListVariantImage={this._fetchListVariantImage}
                                                       onUpdateError={this._handleUpdateError}/>
                    </div>
                </ModalBody>

                <ModalFooter>
                    {
                        error &&
                        <div className="text-danger mr-5">{error}</div>
                    }

                    {
                        loading ?
                            <button className="btn btn-primary ml-auto SaveVariants" disabled>Saving...</button>
                            :
                            <button className="btn btn-primary ml-auto SaveVariants" disabled>Save changes</button>
                    }
                </ModalFooter>
            </Modal>
        )
    }
}

export default ListVariantImages
