export const HEADER_ITEMS = ['Amazon label name', 'Amazon field name', 'Type', 'Value', 'Show for Seller edit']

export const MARKETPLACE_OPTIONS = [
    {name: 'Select Marketplace', value: ''},
    {name: 'Amazon.com', value: 'Amazon.com'},
    {name: 'Amazon.ca', value: 'Amazon.ca'},
    {name: 'Amazon.co.uk', value: 'Amazon.co.uk'},
    {name: 'Amazon.de', value: 'Amazon.de'},
    {name: 'Amazon.fr', value: 'Amazon.fr'},
    {name: 'Amazon.it', value: 'Amazon.it'},
    {name: 'Amazon.es', value: 'Amazon.es'},
    {name: 'Amazon.in', value: 'Amazon.in'},
    {name: 'Amazon.co.jp', value: 'Amazon.co.jp'},
    {name: 'Amazon.com.au', value: 'Amazon.com.au'},
    {name: 'Amazon.cn', value: 'Amazon.cn'},
    {name: 'Amazon.com.br', value: 'Amazon.com.br'},
    {name: 'Amazon.com.mx', value: 'Amazon.com.mx'},
    {name: 'Amazon.com.tr', value: 'Amazon.com.tr'},
    {name: 'Amazon.ae', value: 'Amazon.ae'},
    {name: 'Amazon.sg', value: 'Amazon.sg'}
]

export const DEFAULT_DISABLE_FIELDS = [
    'item_sku',
    'standard_price',
    'main_image_url',
    'other_image_url1',
    'other_image_url2',
    'other_image_url3',
    'other_image_url4',
    'other_image_url5',
    'other_image_url6',
    'other_image_url7',
    'other_image_url8',
    'other_image_url9',
    'parent_child',
    'parent_sku',
    'relationship_type',
    'color_name',
    'color_map',
    'size_name',
    'currency'
]

export const ITEM_TYPE_TEXT = 1
export const ITEM_TYPE_TEXTAREA = 2
