import React from 'react'

const InputNotificationType = (props) => {

    const {formData, onChangeNotificationData} = props

    return (
        <div className="NotificationTypeField">
            <label htmlFor="createNotificationContent">Type</label>
            <div className="form-group d-flex mb-0">
                <div className="custom-control custom-radio mb-3 mr-3">
                    <input
                        id="order"
                        type="radio"
                        className="custom-control-input"
                        name="notificationType"
                        checked={formData.notification.type === 'order'}
                        onChange={() => onChangeNotificationData('type', 'order')}
                    />
                    <label className="custom-control-label" htmlFor="order">
                        Order
                    </label>
                </div>
                <div className="custom-control custom-radio mb-3 mr-3">
                    <input
                        id="billing"
                        type="radio"
                        className="custom-control-input"
                        name="notificationType"
                        checked={formData.notification.type === 'billing'}
                        onChange={() => onChangeNotificationData('type', 'billing')}
                    />
                    <label className="custom-control-label" htmlFor="billing">
                        Billing
                    </label>
                </div>
                <div className="custom-control custom-radio mb-3">
                    <input
                        id="news"
                        type="radio"
                        className="custom-control-input"
                        name="notificationType"
                        checked={formData.notification.type === 'news'}
                        onChange={() => onChangeNotificationData('type', 'news')}
                    />
                    <label className="custom-control-label" htmlFor="news">
                        News
                    </label>
                </div>
            </div>
        </div>
    )
}

export default InputNotificationType

