import React, {Component} from 'react'
import DNSrecord from "./DNSrecord"
class DNSrecordContainer extends Component {

    render() {
        const {clusterId} = this.props
        return (
            <div className="DNSrecordContainer">
                <h3>DNS Record</h3>
                <DNSrecord clusterId={clusterId}/>
            </div>
        )
    }
}

export default DNSrecordContainer
