import React, {Component} from 'react'
import ProductImagesItem from "./ProductImagesItem"
import PropTypes from 'prop-types'

class ProductImagesList extends Component {
    render() {
        const {images, ...rest} = this.props

        return (
            <div className='ProductImagesList'>
                {
                    images.map(image => {
                        return <ProductImagesItem {...rest} key={image} image={image}/>
                    })
                }
            </div>
        )
    }
}

ProductImagesList.propTypes = {
    images: PropTypes.array.isRequired
}

export default ProductImagesList
