import React, {Component} from 'react'
import DesignFormatContext from './context/DesignFormatContext'
import PropTypes from "prop-types"
import DesignFormatImage from './design-items/DesignFormatImage'
import DesignFormatBackground from './design-items/DesignFormatBackground'
import DesignFormatText from './design-items/DesignFormatText'
import PubSub from '../DesignFormatBroker'

class DesignFormatPanel extends Component {
    state = {
        item: {},
        isCollapsed: false
    }

    componentDidMount() {
        this.setState({
            item: this.props.item,
        })

        this._collapseEvent = PubSub.subscribe('DESIGN_FORMAT_PANEL_COLLAPSE_ALL', this._unselectItem)
    }

    componentWillUnmount() {
        this._collapseEvent && PubSub.unsubscribe(this._collapseEvent)
    }

    _unselectItem = () => {
        this._setCollapsed(false)
    }

    _setCollapsed = (isCollapsed = true) => {
        this.setState({
            isCollapsed: isCollapsed
        })
    }

    _toggleElement = (collapseAll = true) => {
        const isCollapsed = !this.state.isCollapsed
        if(collapseAll && isCollapsed) {
            PubSub.publish('DESIGN_FORMAT_PANEL_COLLAPSE_ALL')
        }
        this._setCollapsed(isCollapsed)
    }

    render() {
        const {item, isFocus, index, isCollapsed} = this.state
        const panelClass = `type-${item.type}` + (isCollapsed ? ' active' : '')

        let itemTemplate

        if (item.type === 'image') {
            itemTemplate = (
                <DesignFormatImage
                    key={index}
                    name={item.type}
                    indexKey={index}
                    isFocus={isFocus}
                    item={item}
                    value={item}
                />
            )
        } else if (item.type === 'background') {
            itemTemplate = (
                <DesignFormatBackground
                    key={index}
                    name={item.type}
                    indexKey={index}
                    value={item}
                />
            )
        } else {
            itemTemplate = (
                <DesignFormatText
                    key={index}
                    indexKey={index}
                    isFocus={isFocus}
                    item={item}
                    value={{...this.props.item}}
                />
            )
        }

        return (
            <div className={'design-panel py-2 pl-2 pr-2 bg-white pn-disabled ' + panelClass} id={item.type !== 'background' ? `element_${this.props.item.sortId}` : null}>
                <div className="d-flex align-items-center w-100" onClick={this._toggleElement}>
                    {item.type === 'background' ?
                        (
                        <>
                            <div className="table-col-5 pl-0">
                                <h6 className="mb-0">Background</h6>
                            </div>
                            <div className="table-col-85"></div>
                        </>
                        ) : item.type === 'image' ? (
                        <>
                            <div className="cursor-pointer" style={{width: 40}}>
                                <div className="frame-title w-100 d-flex align-items-center justify-content-center">
                                <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.99994 5.00006C6.99994 6.10455 6.10455 6.99994 5.00006 6.99994C3.89539 6.99994 3 6.10455 3 5.00006C3 3.89539 3.89539 3 5.00006 3C6.10455 3 6.99994 3.89539 6.99994 5.00006Z" fill="#A9AFAB"/>
                                    <path d="M21 0H3C1.34601 0 0 1.34601 0 3V15C0 15.056 0.013916 15.1089 0.0170288 15.165C0.00292969 15.2849 0.00897216 15.4059 0.0529175 15.52C0.299927 16.9261 1.52399 18 3 18H21C22.654 18 24 16.654 24 15V3C24 1.34601 22.654 0 21 0ZM3 2.00006H21C21.551 2.00006 21.9999 2.44904 21.9999 3V10.9391L16.7401 5.67902C16.056 4.99493 14.9431 4.99493 14.2601 5.67902L9.50006 10.439L8.23993 9.17908C7.55603 8.495 6.44293 8.495 5.75995 9.17908L2.00006 12.939V3C2.00006 2.44904 2.44904 2.00006 3 2.00006Z" fill="#A9AFAB"/>
                                </svg>
                                </div>
                            </div>
                            <div className="table-col-75 pl-0 font-weight-bold cursor-pointer" >
                                <h6 className="text-title w-100 mb-0">{this.props.item.name || ''}</h6>
                            </div>
                        </>
                        ) : (
                        <>
                            <div className="cursor-pointer" style={{width: 40}}>
                                <div className="frame-title w-100 d-flex align-items-center justify-content-center">
                                    <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.703125 4.21875C1.09144 4.21875 1.40625 3.90394 1.40625 3.51562V1.40625H8.4375V16.2656C8.4375 16.6533 8.12208 16.9687 7.73438 16.9687H6.32812C5.93981 16.9687 5.625 17.2836 5.625 17.6719C5.625 18.0602 5.93981 18.375 6.32812 18.375H14.7656C15.1539 18.375 15.4688 18.0602 15.4688 17.6719C15.4688 17.2836 15.1539 16.9687 14.7656 16.9687H13.3594C12.9717 16.9687 12.6562 16.6533 12.6562 16.2656V1.40625H19.6875V3.51562C19.6875 3.90394 20.0023 4.21875 20.3906 4.21875C20.7789 4.21875 21.0938 3.90394 21.0938 3.51562V0.703125C21.0938 0.314812 20.7789 0 20.3906 0H0.703125C0.314812 0 0 0.314812 0 0.703125V3.51562C0 3.90394 0.314812 4.21875 0.703125 4.21875Z" fill="#A9AFAB"/>
                                        <path d="M0.703125 24.0001C1.09144 24.0001 1.40625 23.6852 1.40625 23.2969V22.5938H19.6875V23.2969C19.6875 23.6852 20.0023 24.0001 20.3906 24.0001C20.7789 24.0001 21.0938 23.6852 21.0938 23.2969V20.4844C21.0938 20.0961 20.7789 19.7813 20.3906 19.7813C20.0023 19.7813 19.6875 20.0961 19.6875 20.4844V21.1876H1.40625V20.4844C1.40625 20.0961 1.09144 19.7813 0.703125 19.7813C0.314812 19.7813 0 20.0961 0 20.4844V23.2969C0 23.6852 0.314812 24.0001 0.703125 24.0001Z" fill="#A9AFAB"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="table-col-75 pl-0 font-weight-bold cursor-pointer" >
                                <h6 className="text-title w-100 mb-0">{this.props.item.name || ''}</h6>
                            </div>
                        </>
                        )
                    }
                </div>
                <div className="wrap-item">
                    { itemTemplate }
                </div>
            </div>
        )
    }
}

DesignFormatPanel.contextType = DesignFormatContext

DesignFormatPanel.propTypes = {
    item: PropTypes.object.isRequired
}

export default DesignFormatPanel
