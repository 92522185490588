import React, {Component} from 'react'

class ProductGalleryTitle extends Component {
    render() {
        return (
            <div className='ProductGalleryTitle'>
                <h5 className='Title'>Product gallery</h5>

                <div className='text-muted mb-4'>Maximum upload file size: 4MB</div>
            </div>
        )
    }
}


export default ProductGalleryTitle
