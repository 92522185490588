import React, {Component} from 'react'

// import PropTypes from 'prop-types'

class InputRateTitle extends Component {
    _handleChangeInput = e => {
        const {value} = e.target

        this.props.onUpdateRatesData("title", value)
    }

    render() {
        return (
            <div className="InputRateTitle">
                <label htmlFor="createRateTitle">Title</label>
                <input type="text" name="title" className="form-control" id="createRateTitle"
                       onChange={this._handleChangeInput} required/>
            </div>
        )
    }
}

// InputRateTitle.defaultProps = {}

// InputRateTitle.propTypes = {}

export default InputRateTitle
