import { useState } from "react";
import { ModalTitle } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody} from 'reactstrap'
import { importProductMockup } from "../../../../services/api/ProductLinesServices";
import { toaster } from "../../../shared/PFToast"

function ButtonImportMockup (props) {
    const {reloadMockups} = props
    const [error,setError] = useState('')
    const [loading , setLoading] = useState(false)
    const [isModalOpen,setIsModelOpen] = useState(false)
    const _handleImportMockup = (e)=>{
        const {files} = e.target
        if(files && files.length){
            const formData = new FormData()
            formData.append('file', files[0])
             _importProductMockup(formData)
        }
    }

    const _importProductMockup = async (file)=>{
        try {
            setLoading(true)
            
            const data = await importProductMockup(file)
            if(data.success){
                reloadMockups()
                toaster({ message: 'Export successfully', type: 'success', duration: 5000 })
                setIsModelOpen(false)
            }
            setLoading(false)
            setError(data.message)
        } catch (error) {
            setLoading(false)
            setError(error.message)
        }
    }
    return ( 
        <div className='addbtn ml-2'>
            <button onClick={()=>setIsModelOpen(!isModalOpen)}  className='btn btn-primary ml-2'>
                Import
            </button>
            <Modal size='lg' isOpen={isModalOpen} >
                <ModalHeader toggle={() => {
                    setError('')
                    setIsModelOpen(!isModalOpen)}}>
                    <ModalTitle>Import Product Mockup:</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex align-items-center justify-content-between flex-grow-1">
                        <p>Select the product mockup file you want to import (*CSV)</p>
                        {
                            loading
                            ?
                            <label htmlFor="importMockup" className="addbtn btn-sm btn-info cursor-pointer"><i className="ti-import mr-2"></i>Import...</label>

                            : 
                            <div className="d-flex ml-2 align-items-center">
                                <input type="file" name="file" onChange={ _handleImportMockup}
                                    id="importMockup" hidden />
                                <label htmlFor="importMockup" className="addbtn btn-sm btn-info cursor-pointer"><i className="ti-import mr-2"></i>Import</label>
                            </div>
                               
                        }
                    </div>
                    {
                        error && <p className='text-danger mb-0'>{error}</p>
                    }
                </ModalBody>

            </Modal>
        </div>
     );
}

export default  ButtonImportMockup;