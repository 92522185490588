import React, {Component} from 'react'
import ContestReportPage from './ContestReportPage'
import ContestDetailReportPage from '../DetailReport/ContestDetailReportPage'
import getEnv from '../../../../helpers/common/getEnv'

class ContestReportContainer extends Component {

    render() {
        const isSupportMpMode = getEnv('isEnableMpMode')
        if (isSupportMpMode) return null

        return (
            <div className='ContestReportContainer'>
                <h2>Contest Report</h2>
                <ContestReportPage/>
                <ContestDetailReportPage/>
            </div>
        )
    }
}

export default ContestReportContainer
