import React, {Component} from 'react'
import WeightRulesRow from "./WeightRulesRow"

// import PropTypes from 'prop-types'

class WeightRulesTable extends Component {
    state = {
        numberRows: 0,
    }

    _weightData = []

    _addNewRow = e => {
        e.preventDefault()

        if (this._weightData.length <= 0) {
            this._weightData = [{from: 0, cost: ""}]
        } else {
            this._weightData = [...this._weightData, {from: "", cost: ""}]
        }

        this.setState({
            numberRows: this.state.numberRows + 1,
        })
    }

    _removeRow = (rowIndex) => {
        this._weightData = this._weightData.filter((data, index) => index !== rowIndex)

        this.props.onUpdateRateRules("weight_based", this._weightData)

        this.setState({
            numberRows: this.state.numberRows - 1,
        })
    }

    _updateWeightData = (rowIndex, field, data) => {
        this._weightData[rowIndex][field] = data
        this.props.onUpdateRateRules("weight_based", this._weightData)
    }

    _removeWeightData = () => {
        this._weightData = []

        this.setState({
            numberRows: 0,
        })

        this.props.onUpdateRateRules("weight_based", this._weightData)
    }

    render() {
        const {numberRows} = this.state

        return (
            <div className="WeightRulesTable">
                <div className="position-absolute Actions">
                    {
                        numberRows === 0 ?
                            <span className="btn btn-primary cursor-pointer ml-3" onClick={this._addNewRow}>Add
                                rule</span>
                            :
                            <span className="btn border cursor-pointer" onClick={this._removeWeightData}>Remove
                                rule</span>
                    }
                </div>

                {
                    numberRows > 0 &&
                    <div className="border-top mt-4 pt-4">
                        <table className="table table-borderless table-striped">
                            <thead className="thead-light">
                            <tr>
                                <th className="table-col-40 font-weight-normal">From</th>
                                <th className="table-col-40 font-weight-normal">Ship cost</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>

                            <tbody>
                            {
                                this._weightData.map((rowData, index) => {
                                    return <WeightRulesRow key={`weightRulesData_${index}`} rowIndex={index}
                                                           rowData={rowData}
                                                           onRemoveRow={this._removeRow}
                                                           onUpdateWeightData={this._updateWeightData}/>
                                })
                            }
                            </tbody>
                        </table>

                        <span className="text-primary cursor-pointer ml-2"
                              onClick={this._addNewRow}><u>+ Add row</u></span>
                    </div>
                }
            </div>
        )
    }
}

// WeightRulesTable.defaultProps = {}

// WeightRulesTable.propTypes = {}

export default WeightRulesTable
