import React from 'react'

const TableHead = () => {
    return (
        <thead className="thead-light">
            <tr>
                <th className="table-col-10">ID</th>
                <th className="table-col-10">Type</th>
                <th className="table-col-10">Store</th>
                <th className="table-col-15">Store mongodb cluster</th>
                <th className="table-col-5">Status</th>
                <th className="table-col-10">Created at</th>
                <th className="table-col-10">Last suspend at</th>
                <th className="table-col-10">Updated at</th>
                <th className="">Meta</th>
                <th className="table-col-10">Trigger by</th>
                <th>Action</th>
            </tr>
        </thead>
    )
}

export default TableHead
