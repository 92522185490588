import React, {Fragment} from 'react'
import PFTablePlaceholder from '../../shared/PFTablePlaceholder'
import TransactionDeposit from '../../store-deposits/components/table/column/TransactionDeposit'
import GatewayDeposit from '../../store-deposits/components/table/column/GatewayDeposit'
import AmountDeposit from '../../store-deposits/components/table/column/AmountDeposit'
import RequestDeposit from '../../store-deposits/components/table/column/RequestDeposit'
import NoteDeposit from '../../store-deposits/components/table/column/NoteDeposit'
import RejectionNoteDeposit from '../../store-deposits/components/table/column/RejectionNoteDeposit'
import CreatedDeposit from '../../store-deposits/components/table/column/CreatedDeposit'
import StatusDeposit from '../../store-deposits/components/table/column/StatusDeposit'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import ActionDeposit from './ActionDeposit'

const TableBody = (props) => {
    const {items, loading, fetchDeposits} = props

    return (
        <tbody className="TableLoading">
            {loading ? (
                <PFTablePlaceholder columnQuantity={10} />
            ) : items.length === 0 ? (
                <tr>
                    <td colSpan={10}>
                        <i>No results</i>
                    </td>
                </tr>
            ) : (
                <Fragment>
                    {items.map((deposit, index) => (
                        <tr
                            key={index}
                            className={classNames({
                                DepositDuplicate:
                                    deposit.status === 'pending' && deposit.approvedTimes && deposit.approvedTimes > 0,
                            })}
                        >
                            <StoreDeposit store={deposit.store} />
                            <TransactionDeposit deposit={deposit} />
                            <GatewayDeposit gateway={deposit.gateway} />
                            <AmountDeposit amount={deposit.amount} amountVn={deposit.amount_vn} />
                            <RequestDeposit deposit={deposit} />
                            <NoteDeposit note={deposit.note} />
                            <RejectionNoteDeposit rejected_note={deposit.rejected_note} />
                            <CreatedDeposit created={deposit.created} />
                            <StatusDeposit deposit={deposit} />
                            <ActionDeposit fetchDeposits={fetchDeposits} deposit={deposit} baseUrl={deposit.store.backoffice_api_url} />
                        </tr>
                    ))}
                </Fragment>
            )}
        </tbody>
    )
}

const StoreDeposit = (props) => {
    const {store} = props
    return (
        <td>
            {store && (
                <>
                    <p className="mb-0">{store.slug}</p>
                    <Link to={`/a/stores/${store._id}`}>{store.domain}</Link>
                </>
            )}
        </td>
    )
}

export default TableBody

