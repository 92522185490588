import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {getImporterWorker, updateImporterWorker} from '../../../../services/api/StoreSettingsServices'

class ImporterWorker extends Component {
    state = {
        total: null,
        loading: false,
        err: '',
    }

    componentDidMount() {
        this._getImporterWorker()
    }

    _getImporterWorker = async () => {
        try {
            this.setState({
                err: '',
            })
            const {storeId} = this.props
            const {success, message, data} = await getImporterWorker({storeId})
            if (!success) {
                return this.setState({
                    err: message,
                })
            }
            this.setState({
                total: Number(data),
            })
        } catch (error) {
            return this.setState({
                err: error.message || error,
            })
        }
    }

    _updateImporterWorker = async () => {
        try {
            this.setState({
                loading: true,
                err: '',
            })
            const {storeId} = this.props
            const {total} = this.state
            const {success, message} = await updateImporterWorker({storeId, data: {total}})
            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }
            this.setState({
                loading: false,
                err: '',
            })
        } catch (error) {
            return this.setState({
                err: error.message || error,
                loading: false,
            })
        }
    }

    _handleChange = type => () => {
        const {total} = this.state
        switch (type) {
            case 'minus':
                return this.setState(prevState => ({total: total > 1 ? (prevState.total -= 1) : 1}))
            case 'plus':
                return this.setState(prevState => ({total: total < 5 ? (prevState.total += 1) : 5}))
            default:
        }
    }

    render() {
        const {total, loading, err} = this.state
        return (
            <div className={`ImporterWorker ${this.props.storeDetails?.is_backup_and_drop_db ? 'disable' : ''}`}>
                <div className='list-group'>
                    <div className='list-group-item'>
                        <h5>Importer Worker</h5>
                    </div>
                    <div className='list-group-item'>
                        {!!err && <span className='text-danger'>{err}</span>}
                        <div>
                            Total:
                            <button className='btn btn-sm adjust-button' onClick={this._handleChange('minus')}>
                                <i className='ti-angle-left' />
                            </button>
                            {total}
                            <button className='btn btn-sm adjust-button' onClick={this._handleChange('plus')}>
                                <i className='ti-angle-right' />
                            </button>
                        </div>
                        <div>
                            <button
                                className='btn btn-primary mt-1'
                                onClick={this._updateImporterWorker}
                                disabled={loading}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ImporterWorker.propTypes = {
    storeId: PropTypes.string.isRequired,
}

export default ImporterWorker
