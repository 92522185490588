import React, { Component, Fragment } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import { exportEmailAccount } from "../../../../services/api/StoreAccountServices"
import { toaster } from "../../../shared/PFToast"

class ButtonExportAccounts extends Component {
    state = {
        isModalOpen: false,
        store: 'all',
        loading: false,
        error: ''
    }

    _toggleModal = () => {
        const { isModalOpen } = this.state
        this.setState({
            isModalOpen: !isModalOpen,
            error: '',
            store: 'all',
            loading: false,
        })
    }

    _handleChangeRadio = key => () => {
        this.setState({
            store: key
        })
    }

    _exportEmailAccount = async () => {
        this.setState({
            loading: true,
            error: ''
        })
        try {
            const { store } = this.state
            const {params} = this.props

            let param = Object.assign({}, params && {email: params.trim() })

            if (store !== 'all') {
                param.exclude = ['admin', 'super-admin']
            }

            let data = await exportEmailAccount(param)
            let csvContents = "data:text/csv;charset=utf-8," + data
            const encodedUri = encodeURI(csvContents)
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "export_account_emails.csv");
            document.body.appendChild(link)
            link.click()

            this.setState({
                isModalOpen: false,
                error: ''
            })

            toaster({ message: 'Export successfully', type: 'success', duration: 5000 })
        } catch (e) {
            return this.setState({
                error: e.message,
                loading: false,
            })
        }
    }

    render() {
        const { isModalOpen, store, loading, error } = this.state
        const { items } = this.props;
        let isExport = true;
        if (items.length > 0) {
            isExport = false
        }

        return (
            <Fragment>
                <button disabled={isExport} className='btn btn-primary ml-2' onClick={this._toggleModal}>
                    Export
                </button>
                <Modal className='modal-dialog-centered' isOpen={isModalOpen} toggle={this._toggleModal}>
                    <ModalHeader toggle={this._toggleModal}>Email</ModalHeader>
                    <ModalBody>

                        <div className="SelectExportAccounts form-group bg-light p-3 rounded mb-0">

                            <div className="custom-control custom-radio mb-3">
                                <input type="radio" id="exportAccountAllStores" name="storeAll"
                                    checked={store === 'all'} className="custom-control-input"
                                    onChange={this._handleChangeRadio('all')} />
                                <label htmlFor="exportAccountAllStores" className="custom-control-label">
                                    <h5 className='m-0'>All</h5>
                                    <span className='fs-14 font-italic'>Exports all user emails</span>
                                </label>
                            </div>

                            <div className="custom-control custom-radio">
                                <input type="radio" id="exportAccountExcludesAdmins" checked={store === 'excludes'}
                                    name="ExcludesStore"
                                    className="custom-control-input" onChange={this._handleChangeRadio('excludes')} />
                                <label htmlFor="exportAccountExcludesAdmins" className="custom-control-label">
                                    <h5 className='m-0'>Excludes admins</h5>
                                    <span className='fs-14 font-italic'>Exports user emails which doens't have admin role</span>
                                </label>
                            </div>
                        </div>
                        {
                            error && <p className='text-danger mb-0'>{error}</p>
                        }
                    </ModalBody>
                    <ModalFooter className="d-flex">
                        <button className="btn btn-light border" onClick={this._toggleModal}>Close</button>
                        <button disabled={loading} className="btn btn-primary" onClick={this._exportEmailAccount}>
                            {
                                loading && <Spinner color='white' size='sm' className='mr-2' />
                            }
                            Export
                        </button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}

ButtonExportAccounts.propTypes = {}

export default ButtonExportAccounts
