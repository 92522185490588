import React, {Component} from 'react'
import AffiliateCategory from './AffiliateCategory'

class AffiliateCategoryContainer extends Component {
    render() {
        return (
            <div className='AffiliateCategoryContainer'>
                <AffiliateCategory/>
            </div>
        )
    }
}

export default AffiliateCategoryContainer
