import React from 'react'
import PropTypes from 'prop-types'
import {getListClusters} from '../../../services/api/ClustersServices'

class ClusterFilterSelect extends React.Component {
    state = {
        clusters: []
    }

    componentDidMount() {
        this._fetchClusters()
    }

    _fetchClusters = async () => {
        try {
            const {success, data, message} = await getListClusters({limit: 100})
            if (!success) throw new Error(message)

            this.setState({clusters: data.clusters})
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const {onChange, value} = this.props
        const {clusters} = this.state

        return (
            <div className='ClusterSelect mr-3'>
                <p className='mb-0'>Cluster</p>
                <select name='cluster' value={value} onChange={onChange}>
                    <option value=''>All</option>
                    {/* <option value="master-cluster">production-cluster-1</option> */}
                    {
                        clusters.map(cluster => {
                            return <option key={cluster._id} value={cluster._id}>{cluster.name}</option>
                        })
                    }
                </select>
            </div>
        )
    }
}

ClusterFilterSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
}

export default ClusterFilterSelect
