import {debounce} from 'lodash'
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react'
import {DateRangePicker} from 'react-dates'
import {deleteEmptyObjectkeys, trimObjectValues} from '../../../helpers/common/cleanObject'
import {getCashbackRuleHistory} from '../../../services/api/BillingServices'
import PFPagePagination from '../../shared/PFPagePagination'
import {toaster} from '../../shared/PFToast'
import ReturnPage from '../../shared/ReturnPage'
import {CASHBACK_HISTORY_STATUSES} from '../constants'
import CashbackRuleHistoryTable from '../tables/CashbackRuleHistoryTable'

const CashbackRuleHistoryContainer = () => {
    const [focusedInput, setFocusedInput] = useState(null)
    const [loading, setLoading] = useState(false)
    const [pages, setPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [query, setQuery] = useState({
        page: 1,
        limit: 10,
        email: '',
        status: '',
        sort: -1,
        from: null,
        to: null,
    })
    const [cashbackRules, setCashbackRules] = useState([])

    const fetchCashbackRules = useCallback(async () => {
        try {
            setLoading(true)
            const params = {
                ...trimObjectValues(query),
                from: query.from ? moment(query.from).format('MM-DD-YYYY') : '',
                to: query.to ? moment(query.to).format('MM-DD-YYYY') : '',
            }
            const {success, data, message} = await getCashbackRuleHistory(deleteEmptyObjectkeys(params))
            setLoading(false)
            if (!success) {
                throw new Error(message)
            }
            setPages(data.totalPages)
            setTotal(data.totalCashback)
            setCashbackRules(data.histories)
        } catch (error) {
            setLoading(false)
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }, [query])

    useEffect(() => {
        fetchCashbackRules()
    }, [fetchCashbackRules])

    const debouncedSetQuery = debounce((name, value) => {
        setQuery((prev) => ({
            ...prev,
            page: 1,
            [name]: value ? value.trim() : '',
        }))
    }, 500)

    const onChangeSearch = (e) => {
        const {name, value} = e.target
        debouncedSetQuery(name, value)
    }

    const onChangeSelect = (e) => {
        const {name, value} = e.target
        setQuery({
            ...query,
            page: 1,
            [name]: value,
        })
    }

    const handlePageChange = (page) => {
        setQuery({
            ...query,
            page,
        })
    }

    const onFocusChange = (focusedInput) => setFocusedInput(focusedInput)

    const isOutsideRangeDate = (date) => {
        const tomorrow = moment().add(1, 'day')
        const startDate = moment('2018-11-05', 'YYYY-MM-DD')

        if (startDate.isAfter(date)) return true

        return !date.isBefore(tomorrow) || date.isSame(tomorrow, 'day')
    }

    const handleTimeRangeChange = ({startDate, endDate}) => {
        return setQuery((prev) => ({
            ...prev,
            from: startDate,
            to: endDate,
        }))
    }

    return (
        <div className="CashbackRuleHistoryContainer">
            <ReturnPage url="/a/cashback-rule" title="Cashback Rule" />
            <div className="CashbackRuleHistory">
                <div className="d-flex justify-content-between align-items-center">
                    <h2>History cashback</h2>
                </div>

                <div className="SectionInner">
                    <div className="d-flex justify-content-between">
                        <div className="TableFilter d-flex flex-wrap">
                            <div className="TableLength mr-3">
                                Show
                                <select className="ml-2" name="limit" value={query.limit} onChange={onChangeSelect}>
                                    <option value="10">10 rows</option>
                                    <option value="20">20 rows</option>
                                    <option value="50">50 rows</option>
                                    <option value="100">100 rows</option>
                                </select>
                            </div>
                            <div className="form-group SearchInput d-flex align-items-center mr-3">
                                <label className="m-0 mr-2">User</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="Enter search user email..."
                                    onChange={onChangeSearch}
                                />
                            </div>

                            <div className="form-group d-flex align-items-center mr-3">
                                <label className="m-0 mr-2">Cashback day</label>
                                <DateRangePicker
                                    small
                                    showClearDates
                                    enableOutsideDays
                                    reopenPickerOnClearDates
                                    minimumNights={0}
                                    numberOfMonths={2}
                                    isOutsideRange={isOutsideRangeDate}
                                    startDate={query.from}
                                    startDateId="from"
                                    endDate={query.to}
                                    endDateId="to"
                                    onDatesChange={handleTimeRangeChange}
                                    focusedInput={focusedInput}
                                    onFocusChange={onFocusChange}
                                    displayFormat={'DD/MM/YYYY'}
                                />
                            </div>

                            <div className="TableLength">
                                Status
                                <select className="ml-2" name="status" value={query.status} onChange={onChangeSelect}>
                                    <option value="">All</option>
                                    {CASHBACK_HISTORY_STATUSES.map((item, index) => (
                                        <option key={`status_${index}`} value={item}>
                                            {item.charAt(0).toUpperCase() + item.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <strong className="Entity" style={{minWidth: '70px'}}>
                            {total} items
                        </strong>
                    </div>

                    <CashbackRuleHistoryTable cashbackRuleHistories={cashbackRules} loading={loading} refetch={fetchCashbackRules} />
                    <PFPagePagination page={query.page} pages={pages} onChangePage={handlePageChange} />
                </div>
            </div>
        </div>
    )
}

export default CashbackRuleHistoryContainer
