import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Input} from 'reactstrap'
import {updateAccountRoles} from '../../../services/api/StoreAccountServices'
import Select from 'react-select'

const options = [
    {
        label: 'Seller',
        value: 'seller',
    },
    {
        label: 'Topup approver',
        value: 'topup-approver',
    },
    {
        label: 'Accountant',
        value: 'accountant',
    },
    {
        label: 'Model creator',
        value: 'model-creator',
    },
    {
        label: 'Operator',
        value: 'operator',
    },
    {
        label: 'Admin',
        value: 'admin',
    },
    {
        label: 'Super admin',
        value: 'super-admin',
    },
]

const _getDefaultRoles = (rolesString) => {
    if (!rolesString) return []
    const filterValues = rolesString.replace(/\s/g, '').split(',')
    const filteredRoles = options.filter(item => filterValues.includes(item.value))
    return filteredRoles
}

class UpdateRolesModal extends Component {
    state = {
        err: '',
        roles: [],
    }

    componentDidUpdate(prevProps, prevState) {
        const {showModal, rolesString} = this.props
        if (rolesString && showModal !== prevProps.showModal) {
            this.setState({
                roles: _getDefaultRoles(rolesString)
            })
        }
    }

    _updateAccountRoles = async () => {
        const {roles} = this.state
        const {accountId} = this.props
        const roleArray = roles.map((r) => r.value)
        try {
            const {success, data, message} = await updateAccountRoles({
                accountId,
                roles: roleArray,
            })
            if (!success) {
                return this.setState({
                    err: message,
                })
            }
            this.setState({
                err: '',
                roles: [],
            })
            const {roles: res} = Object.assign({}, data)
            const {updateRoles} = this.props
            return updateRoles(res)
        } catch (e) {
            this.setState({
                err: e.message || e,
            })
        }
    }

    _closeModal = () => {
        const {closeModal} = this.props
        this.setState({
            err: '',
            roles: [],
        })
        return closeModal()
    }

    _handleChange = (options) => {
        const roles = options && options.length ? options : []
        this.setState({roles})
    }

    render() {
        const {roles = [], err} = this.state
        const {showModal} = this.props

        return (
            <div className="UpdateRolesModal">
                <Modal isOpen={showModal} size="lg" centered>
                    <ModalHeader>Update Roles</ModalHeader>
                    <ModalBody>
                        <label>Select roles</label>
                        <Select
                            id="roles"
                            isMulti={true}
                            isClear={true}
                            options={options}
                            onChange={this._handleChange}
                            value={roles}
                        />
                        {!!err && <span className="text-warning">{err}</span>}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" disabled={roles.length === 0} onClick={this._updateAccountRoles}>
                            Save
                        </Button>
                        <Button color="secondary" onClick={this._closeModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

UpdateRolesModal.propTypes = {
    accountId: PropTypes.string.isRequired,
    showModal: PropTypes.bool.isRequired,
    updateRoles: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
}

export default UpdateRolesModal

