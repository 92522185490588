import React, {Fragment} from 'react'
import {Modal, ModalBody, ModalHeader} from 'reactstrap'
import PFTable from '../../shared/PFTable'
import StatusStyle from '../../shared/StatusStyle'
import {Link} from 'react-router-dom'

const ModalCashbackHistoryDetail = (props) => {
    const {cashbackDetail, toggle} = props

    const generateFileName = (link) => {
        if (!link) return '-'
        return link.substring(link.lastIndexOf('/') + 1)
    }

    return (
        <Modal isOpen={cashbackDetail.isOpen} toggle={toggle} size="xl" centered>
            <ModalHeader toggle={toggle}>{cashbackDetail.calculationId} Detail</ModalHeader>
            <ModalBody>
                <div className="CashbackHistoryDetailTable table-responsive">
                    {
                        <PFTable source={cashbackDetail.data}>
                            {() => (
                                <Fragment>
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Store</th>
                                            <th>Domain</th>
                                            <th>Status</th>
                                            <th>File</th>
                                            <th>Error message</th>
                                        </tr>
                                    </thead>
                                    <Fragment>
                                        {Array.isArray(cashbackDetail.data) && cashbackDetail.data.length === 0 ? (
                                            <tbody>
                                                <tr>
                                                    <td colSpan="5">
                                                        <i className="text-muted">No results!</i>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                {Array.isArray(cashbackDetail.data) &&
                                                    cashbackDetail.data.map((item, index) => {
                                                        const {
                                                            store = {},
                                                            status,
                                                            cashback_detail_file_url,
                                                            error_message,
                                                        } = item
                                                        return (
                                                            <tr key={`cashback_rule_${index}`}>
                                                                <td>{store.slug}</td>
                                                                <td>
                                                                    <Link to={`/a/stores/${store._id}`}>
                                                                        {store.domain}
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <StatusStyle status={status} />
                                                                </td>
                                                                <td>
                                                                    <a href={cashback_detail_file_url} download>
                                                                        {generateFileName(cashback_detail_file_url)}
                                                                    </a>
                                                                </td>
                                                                <td>{error_message}</td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        )}
                                    </Fragment>
                                </Fragment>
                            )}
                        </PFTable>
                    }
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ModalCashbackHistoryDetail
