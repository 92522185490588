import React, {Component} from 'react'
import CreateClusterForm from './CreateClusterForm'
import {createNewCluster} from '../../../../services/api/ClustersServices'

class CreateClusterPage extends Component {
    state = {
        err: '',
        loading: '',
    }

    _createNewCluster = async payload => {
        const {name} = payload
        this.setState({
            err: '',
        })
        try {
            const {success, message} = await createNewCluster(payload)
            if (!success) {
                return this.setState({
                    err: message,
                })
            }
            alert(`Created Cluster ${name} sucessfully`)
        } catch (error) {
            this.setState({
                err: error.message || error,
            })
        }
    }

    render() {
        const {err} = this.state
        return (
            <div className='CreateClusterPage'>
                <div className='CreateClusterPageInner SectionInner'>
                    {!!err && <p className='text-warning'>{err} </p>}
                    <CreateClusterForm createNewCluster={this._createNewCluster} />
                </div>
            </div>
        )
    }
}

export default CreateClusterPage
