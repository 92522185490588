import React from 'react'
import SettingShippingPage from './SettingShippingPage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import {Link} from 'react-router-dom'
import ReturnPage from '../../shared/ReturnPage'
import {isAdmin, isSuperAdmin} from '../../../services/AuthServices'

const SettingShippingContainer = () => {

    const isSupperAdmin = isSuperAdmin()
    const roleAdmin = isAdmin()

    if (isSupperAdmin || roleAdmin) return (
        <div className="SettingPage SettingShippingContainer">
            <DocTitleByStore title="Shipping profiles"/>
            <div className="row">
                <div className="col-md-8 col-12 m-auto ">
                    <ReturnPage url="/a/settings" title="Settings"/>
                    <div className="MenuTitle align-items-center d-flex flex-wrap justify-content-between">
                        <div>
                            Shipping Profiles
                        </div>
                        <Link to={'/a/settings-shipping/profiles-new'}
                              className="btn btn-primary">Add shipping profiles</Link>
                    </div>

                    <SettingShippingPage/>
                </div>
            </div>
        </div>
    )

    return null
}

export default SettingShippingContainer
