import React, { useState, useEffect } from 'react'

function SearchGlobalSettings(props) {
    const [searchValue, setSearchValue] = useState('')
    const [typeSearch, setTypeSearch] = useState('key')
    const [searchValueDebounce, setSearchValueDebounce] = useState('')

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setSearchValueDebounce(searchValue)
        }, 750)
        return () => clearTimeout(delayDebounceFn)
    }, [searchValue])

    useEffect(()=>{
        props.onKeyChange(typeSearch,searchValueDebounce)
    },[searchValueDebounce, typeSearch])

    return (
        <div className='mt-3 ml-5  SearchProductMockup form-group d-flex  align-items-center'>
              <div className='Select m-0'>
                <select name='searchType' onChange={(e)=> {
                    setTypeSearch(e.target.value)
                }}>
                    <option value='key'> Key</option>
                    <option value='value'>Value</option>
                </select>
            </div>
            <input
                type='text'
                className='form-control'
                id=''
                name='key'
                placeholder='Enter search here...'
                onChange={(e) => {
                    setSearchValue(e.target.value)
                }}
            />
            <i className='fas fa-search SearchIcon' />
        </div>
    )
}

export default SearchGlobalSettings
