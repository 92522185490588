import React, { useEffect } from 'react';
import { FormGroup } from 'react-bootstrap';
import { Form, Input, Label } from 'reactstrap';
import JsonInputCpn from './JsonInputCpn';
import ModalCommon from './ModalCommon';

const placeholderHeaders = "{'Content-Type':'application/json','merchize-webhook-key': '{secretKey}'}"
const placeholderPayload = "{'event_type':'ORDER.XXXXX.XXXX','resource': {}}"

const initState = {
    name: '',
    description: '',
    headers: '',
    payload: ''
}

const TextErr = ({ children }) => {
    return (
        <span style={{ color: 'red', fontSize: '14px' }}>
            {children}
        </span>
    )
}

function ModalEditEvent(props) {
    const { onClose, isShow, onSubmit, dataEdit } = props

    const [formData, setFormData] = React.useState(initState)
    const [formDataErr, setFormDataErr] = React.useState(initState)

    useEffect(() => {
        setFormData({
            name: dataEdit.name,
            description: dataEdit.description,
            headers: dataEdit.headers,
            payload: dataEdit.payload
        })
    }, [dataEdit])

    const handleClickOk = () => {
        Object.keys(formData).map(key =>
            formData[key] = typeof formData[key] === 'string'
                ? formData[key]?.trim()
                : formData[key]
        );

        const isValid = !checkInputIsValid(formData).length
        if (!isValid) return

        const isValidJson = !checkInputIsValidJson({ headers: formData.headers, payload: formData.payload }).length
        if (!isValidJson) return


        const errFormData = checkErrFormData(formDataErr)
        if (errFormData) return
        onSubmit(formData)
    }

    const handleClose = () => {
        onClose()
        setFormData(initState)
        setFormDataErr(initState)
    }

    const checkErrFormData = (data) => Object.keys(data).find(key =>  (data[key] !== ''))

    // new
    const handleChangeValue = ({ target }) => {
        setFormDataErr({
            ...formDataErr,
            [target.name]: '',
        })
        setFormData({
            ...formData,
            [target.name]: target.value
        })
    }

    const checkInputIsValid = (data) => Object.keys(data).filter(key => {
        if (data[key] === '') {
            setFormDataErr((prev) => ({ ...prev, [key]: `${key} must not be empty` }))
            return key
        }
        else {
            setFormDataErr((prev) => ({ ...prev, [key]: '' }))
            return null
        }
    });
    const checkInputIsValidJson = data =>
        Object.keys(data).filter(key => {
            try {
                JSON.parse(data[key])
                setFormDataErr((prev) => ({ ...prev, [key]: '' }))
                return false
            } catch (error) {
                setFormDataErr((prev) => ({ ...prev, [key]: `${key} must be json` }))
                return key
            }
        })
    const handleChangeJsonValueHeaders = (e) => {
        const { error, json } = e
        if (!error) {
            setFormData(prev => ({ ...prev, headers: json }))
            setFormDataErr((prev) => ({ ...prev, headers: '' }))
        }else{
            setFormDataErr((prev) => ({ ...prev, headers: `headers must be json` }))
        }
    }

    const handleChangeJsonValuePayload = (e) => {
        const { error, json } = e
        if (!error) {
            setFormData(prev => ({ ...prev, payload: json }))
            setFormDataErr((prev) => ({ ...prev, payload: '' }))
        } else {
            setFormDataErr((prev) => ({ ...prev, payload: `payload must be json` }))
        }
    }

    const checkValueWhenBlur = ({ target }) => {
        if(!target.value.trim() ){
            return setFormDataErr((prev) => ({ ...prev, [target.name]: `${target.name} must not be empty` }))
        }
    }

    return (
        <ModalCommon
            isOpen={isShow}
            title='Edit webhook event'
            onClose={handleClose}
            onSubmit={handleClickOk}
        >
            <Form>
                <FormGroup>
                    <Label style={{ color: '#74606b', fontWeight: 'bold' }} for="name">
                        Name
                    </Label>
                    <Input
                        spellCheck="false" required
                        name="name"
                        placeholder="Enter event name"
                        value={formData.name}
                        onChange={handleChangeValue}
                        onBlur={checkValueWhenBlur}
                    />
                    {
                        formDataErr.name &&
                        <TextErr>
                            {formDataErr.name}
                        </TextErr>
                    }
                </FormGroup>
                <FormGroup style={{ marginTop: '10px' }}>
                    <Label style={{ color: '#74606b', fontWeight: 'bold' }} for="description">
                        Description
                    </Label>
                    <Input
                        spellCheck="false" required
                        name="description"
                        placeholder="Enter description name"
                        value={formData.description}
                        onChange={handleChangeValue}
                        onBlur={checkValueWhenBlur}
                    />
                    {
                        formDataErr.description &&
                        <TextErr>
                            {formDataErr.description}
                        </TextErr>
                    }
                </FormGroup>

                <FormGroup style={{ marginTop: '10px' }}>
                    <Label style={{ color: '#74606b', fontWeight: 'bold' }} for="headers">
                        Headers
                        <span style={{ opacity: '0.3', fontWeight: '400', marginLeft: '10px' }}>
                            {placeholderHeaders}
                        </span>
                    </Label>
                    <JsonInputCpn  
                        placeholder={JSON.parse(dataEdit.headers || null)}
                        id='headers'
                        style={{
                            body: { fontSize: '16px', border: '1px solid #ced4da', width: 'inherit', minHeight: '200px' },
                            container: { width: 'inherit', minHeight: '200px' },
                            outerBox: { width: 'inherit', minHeight: '200px' },
                            warningBox: { display: 'none' },
                            errorMessage: { color: 'deeppink', fontSize: '16px' }
                        }}
                        onChange={handleChangeJsonValueHeaders}
                    />
                    {
                        formDataErr.headers &&
                        <TextErr>
                            {formDataErr.headers}
                        </TextErr>
                    }
                </FormGroup>

                <FormGroup style={{ marginTop: '10px' }}>
                    <Label style={{ color: '#74606b', fontWeight: 'bold' }} for="payload">
                        Payload
                        <span style={{ opacity: '0.3', fontWeight: '400', marginLeft: '10px' }}>{placeholderPayload}</span>
                    </Label>
                    <JsonInputCpn
                        id='payload'
                        placeholder={JSON.parse(dataEdit.payload || null)}
                        style={{
                            body: { fontSize: '16px', border: '1px solid #ced4da', width: 'inherit', minHeight: '200px' },
                            container: { width: 'inherit', minHeight: '200px' },
                            outerBox: { width: 'inherit', minHeight: '200px' },
                            errorMessage: { color: 'deeppink', fontSize: '16px' },
                            warningBox: { display: 'none' },
                        }}
                        onChange={handleChangeJsonValuePayload}
                    />
                    {
                        formDataErr.payload &&
                        <TextErr>
                            {formDataErr.payload}
                        </TextErr>
                    }
                </FormGroup>
            </Form>
        </ModalCommon>
    );
}

export default ModalEditEvent;
