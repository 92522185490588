import React, {Fragment} from 'react'
import PFTablePlaceholder from '../../../shared/PFTablePlaceholder'
import {DESTINATION, SHIPMENT_STAGE, WARNING_PERCENTAGE} from '../../constants'
import {Link} from 'react-router-dom'
import formatThousandNumber from '../../../../helpers/common/formatThousandNumber'

const ShipmentReportTableBody = (props) => {
    const {loading, reports, toggle, platform} = props

    const _renderCell = (statistics, slug, destination, stage) => {
        const cell = statistics[destination]
        if (cell && cell[stage]) {
            const {count = 0, percentage = 0} = cell[stage]
            return count > 0 ? (
                <Fragment>
                    {stage === SHIPMENT_STAGE.AT_POST_OFFICE ? (
                        <div className={`text-center`}>
                            <p className="mb-0">{count}</p>
                            <span>{`(${formatThousandNumber(percentage, 2)}%)`}</span>
                        </div>
                    ) : (
                        <div
                            className={`text-center ${
                                percentage > WARNING_PERCENTAGE ? 'text-danger' : 'text-primary'
                            }`}
                            onClick={() => toggle(cell, slug, destination, stage)}
                        >
                            <p className="mb-0">{count}</p>
                            <span>{`(${formatThousandNumber(percentage, 2)}%)`}</span>
                        </div>
                    )}
                </Fragment>
            ) : (
                '-'
            )
        }
        return '-'
    }

    return (
        <tbody className="ShipmentReportTableBody TableLoading">
            {loading ? (
                <PFTablePlaceholder columnQuantity={27} />
            ) : reports.length === 0 ? (
                <tr>
                    <td colSpan={27}>
                        <i>No results</i>
                    </td>
                </tr>
            ) : (
                <Fragment>
                    {reports.map((report) => {
                        const {store, total, statistics = {}} = report
                        return (
                            <tr key={store.slug}>
                                <td>
                                    <Link to={`/a/stores/${store._id}`}>{store.domain || null}</Link>
                                    <div>
                                        <code>{store.slug}</code>
                                    </div>
                                </td>
                                <td>{total}</td>
                                <td>
                                    {_renderCell(statistics, store.slug, DESTINATION.VN_US, SHIPMENT_STAGE.PRE_TRANSIT_5BDS)}
                                </td>
                                <td>
                                    {_renderCell(statistics, store.slug, DESTINATION.VN_US, SHIPMENT_STAGE.PRE_TRANSIT)}
                                </td>
                                <td>
                                    {_renderCell(statistics, store.slug, DESTINATION.VN_US, SHIPMENT_STAGE.IN_TRANSIT)}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.VN_US,
                                        SHIPMENT_STAGE.NOT_DELIVERED
                                    )}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.VN_US,
                                        SHIPMENT_STAGE.DELAY_DELIVERED_13BDS
                                    )}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.VN_US,
                                        SHIPMENT_STAGE.DELAY_DELIVERED_16BDS
                                    )}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.VN_US,
                                        SHIPMENT_STAGE.AT_POST_OFFICE
                                    )}
                                </td>
                                <td>
                                    {_renderCell(statistics, store.slug, DESTINATION.VN_EU, SHIPMENT_STAGE.PRE_TRANSIT)}
                                </td>
                                <td>
                                    {_renderCell(statistics, store.slug, DESTINATION.VN_EU, SHIPMENT_STAGE.IN_TRANSIT)}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.VN_EU,
                                        SHIPMENT_STAGE.DELAY_DELIVERED
                                    )}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.VN_ROW,
                                        SHIPMENT_STAGE.PRE_TRANSIT
                                    )}
                                </td>
                                <td>
                                    {_renderCell(statistics, store.slug, DESTINATION.VN_ROW, SHIPMENT_STAGE.IN_TRANSIT)}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.VN_ROW,
                                        SHIPMENT_STAGE.DELAY_DELIVERED
                                    )}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.US_US,
                                        SHIPMENT_STAGE.PRE_TRANSIT
                                    )}
                                </td>
                                <td>
                                    {_renderCell(statistics, store.slug, DESTINATION.US_US, SHIPMENT_STAGE.IN_TRANSIT)}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.US_US,
                                        platform === 'tiktok' ? SHIPMENT_STAGE.DELAY_DELIVERED_5BDS : SHIPMENT_STAGE.DELAY_DELIVERED_8BDS
                                    )}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.US_US,
                                        SHIPMENT_STAGE.DELAY_DELIVERED_10BDS
                                    )}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.US_US,
                                        SHIPMENT_STAGE.AT_POST_OFFICE
                                    )}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.US_ROW,
                                        SHIPMENT_STAGE.DELAY_DELIVERED
                                    )}
                                </td>
                                <td>
                                    {_renderCell(statistics, store.slug, DESTINATION.EU_EU, SHIPMENT_STAGE.IN_TRANSIT)}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.EU_EU,
                                        SHIPMENT_STAGE.DELAY_DELIVERED
                                    )}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.EU_ROW,
                                        SHIPMENT_STAGE.DELAY_DELIVERED
                                    )}
                                </td>
                                <td>
                                    {_renderCell(statistics, store.slug, DESTINATION.AU_AU, SHIPMENT_STAGE.IN_TRANSIT)}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.AU_AU,
                                        SHIPMENT_STAGE.DELAY_DELIVERED
                                    )}
                                </td>
                                <td>
                                    {_renderCell(
                                        statistics,
                                        store.slug,
                                        DESTINATION.CN,
                                        SHIPMENT_STAGE.DELAY_DELIVERED
                                    )}
                                </td>
                            </tr>
                        )
                    })}
                </Fragment>
            )}
        </tbody>
    )
}

export default ShipmentReportTableBody

