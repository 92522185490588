import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

class StatusEdit extends Component {
    render() {
        const {product} = this.props

        return (
            <div className='StatusEdit'>
                <div className='ChangeStatus d-flex align-items-baseline'>
                    {product.created && (
                        <span className='CreatedAt'>
                            <span className='Title'>
                                Created at: {moment(product.created).format('MMMM Do YYYY, h:mm:ss A')}
                            </span>
                        </span>
                    )}
                </div>
            </div>
        )
    }
}

StatusEdit.propTypes = {
    product: PropTypes.object.isRequired,
}

export default StatusEdit
