import React, {Component} from 'react'
import PropTypes from 'prop-types'
import DragHandleVariantImage from "./DragHandleVariantImage"
import {removeVariantImageSide} from "../../../../../services/api/ProductServices"
import className from 'classnames'

class VariantImage extends Component {
    state = {
        loading: false,
    }

    _handleClickRemove = e => {
        e.preventDefault()

        this.setState({
            loading: true
        })

        this._handleRemoveVariantImage()
    }

    _handleRemoveVariantImage = async () => {
        const {variantId, side, onFetchListVariantImage, onUpdateError} = this.props

        try {
            const {success, message} = await removeVariantImageSide(variantId, side)

            if (!success) {
                onUpdateError(message)

                return this.setState({
                    loading: false,
                })
            }

            onFetchListVariantImage()

            // return this.setState({
            //     loading: "removed"
            // })
        } catch (e) {
            onUpdateError(e.message)

            return this.setState({
                loading: false,
            })
        }
    }

    render() {
        const {value} = this.props
        const {loading} = this.state

        return (
            <div className={className("VariantImage m-2", {removing: loading === true, removed: loading === "removed"})}>
                <div className="ImageWrapper border">
                    <DragHandleVariantImage value={value}/>
                    <i className="far fa-times-circle text-danger RemoveIcon cursor-pointer bg-white rounded-circle"
                       onClick={this._handleClickRemove}/>
                </div>
            </div>
        )
    }
}

VariantImage.propTypes = {
    value: PropTypes.string.isRequired,
    variantId: PropTypes.string.isRequired,
    onFetchListVariantImage: PropTypes.func.isRequired,
    side: PropTypes.string.isRequired,
    onUpdateError: PropTypes.func
}

export default VariantImage
