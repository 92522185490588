import React from 'react'
import PropTypes from 'prop-types'

class StatusFilterSelect extends React.Component {
    render() {
        const {onChange, value} = this.props

        return <div className='StatusFilterSelect col-2'>
            <p className="mb-0">Status</p>

            <select name='status' onChange={onChange} value={value} className="w-100">
                <option value=''>All</option>
                <option>active</option>
                <option>inactive</option>
            </select>
        </div>
    }
}

StatusFilterSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
}

export default StatusFilterSelect
