import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts'

const color = ['#ff3c7e', '#4CAF50', '#3F51B5', '#607D8B', '#795548', '#FF5722', '#673AB7']

class Chart extends Component {
    static jsfiddleUrl = 'https://jsfiddle.net/alidingling/xqjtetw0/'

    _renderLine = () => {
        const line = []
        const {data} = this.props
        if (!data.length) return []
        const sampleObj = data[0]
        delete sampleObj.date
        Object.keys(sampleObj).forEach((item, index) => {
            line.push(<Line key={index} type='monotone' dataKey={item} stroke={color[index]} dot={false} />)
        })
        return line
    }

    render() {
        const {data, name} = this.props
        return (
            data.length && (
                <div className='Chart'>
                    <div className='ChartName'>
                        <span> {name} </span>
                    </div>
                    <ResponsiveContainer width='99%' height={500}>
                        <LineChart
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 0,
                                bottom: 0,
                            }}
                            className='LineChart'>
                            <CartesianGrid strokeDasharray='3 3' />
                            <XAxis dataKey='date' />
                            <YAxis />
                            <Tooltip />
                            <Legend height={36} />
                            {this._renderLine()}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            )
        )
    }
}

Chart.propTypes = {
    data: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
}

export default Chart
