import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import {Link} from 'react-router-dom'
import humanizeTime from '../../../helpers/time/humanizeTime'
import hiddenReferrer from '../../../helpers/common/hiddenReferrer'
import { getResourceMongoDb } from '../../../services/api/ResourceService'
import {downloadBackupDb} from '../../../services/api/StoreServices'
import ButtonChangeStatus from './ButtonChangeStatus'
import ButtonChangeStoreFrontStatus from './ButtonChangeStoreFrontStatus'
import DomainModal from './DomainModal'
import StoreThemeName from "./StoreThemeName";
import ButtonRestoreDatabase from './ButtonRestoreDatabase'
import ButtonMoveStore from './ButtonMoveDatabase'
import ButtonDeleteOldDatabase from './ButtonDeleteOldDatabase'
import ButtonDetailMoveDatabase from './ButtonDetailMoveDatabase'
import ButtonRollbackDatabase from './ButtonRollbackDatabase'
import ButtonViewStoreStats from './ButtonViewStoreStats'
import SchedulerPriorityModal from './SchedularPriorityModal'
import ButtonBackupDropDatabase from './ButtonBackupDropDatabase'

class StoreDetails extends Component {
    state = {
        open: false,
        isModalMoveStoreOpen: false,
        openSchedularPriority: false,
        resourcesList: [],
    }

    _validateDate = time => {
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }

    _handleButtonClick = () => {
        this.setState({
            open: true,
        })
    }

    _onCloseModal = () => {
        this.setState({
            open: false,
        })
    }

    _onCloseMoveStoreModal = () => {
        this.setState({
            isModalMoveStoreOpen: false,
        })
    }

    _toggleSchedularPriority = () => this.setState((prevState) => ({openSchedularPriority: !prevState.openSchedularPriority}))

    _onOpenMoveStoreModal = async () => {
        try {
            const resourcesList = await getResourceMongoDb({
                storeId: this.props.storeDetails._id,
                kind: 'mongodb',
                active: true,
            })
            this.setState((prev) => ({
                ...prev,
                resourcesList: resourcesList.data,
                isModalMoveStoreOpen: true,
            }))

        } catch (error) {
            alert(error)
        }
    }

    downloadBackupDatabase = async () => {
        try {
            const path = this.props.storeDetails.backup_database_file_path
            const {data, message, success} = await downloadBackupDb(this.props.storeDetails._id, {
                destinationPath: path,
            })
            if (!success) {
                throw new Error(message)
            }

            const link = document.createElement('a')
            link.href = data
            link.download = path.split('/')[-1]
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        } catch (error) {
            alert(error)
        }
    }

    render() {
        const {open, isModalMoveStoreOpen, resourcesList, openSchedularPriority} = this.state
        const {changeStoreDetail, storeDetails} = this.props
        const {
            _id,
            bucket_image,
            bucket_product_image,
            db_name,
            updated,
            version,
            name,
            domain,
            created,
            activated_at,
            last_suspend_at,
            slug,
            switchTo,
            sub_domain = '',
            status,
            namespace,
            theme_name,
            disabled_storefront,
            is_activate,
            is_restore,
            is_backup_and_drop_db,
            backup_database_file_path,
            db_move_resource_changed,
            scheduler_priority = 0
        } = storeDetails

        const storefront = disabled_storefront ? 'disabled' : 'active'

        const activateDate = this._validateDate(activated_at)
        const createDate = this._validateDate(created)
        const updateDate = this._validateDate(updated)
        const lastSuppendAt = this._validateDate(last_suspend_at)
        const hiddenRefLink = hiddenReferrer(domain)
        const hiddenRefWitchTo = hiddenReferrer(switchTo)
        const storeId = _id || ''

        return (
            <div className='StoreDetails'>
                <div className='list-group'>
                    <li className='list-group-item text--right'>
                        <h5>Details</h5>
                        <Link to={`/a/stores/${storeId}/settings`}>
                            <span>
                                {' '}
                                Settings <i className='fas fa-cog'/>{' '}
                            </span>
                        </Link>
                    </li>
                    <li className='list-group-item text--right'>
                        Name <span>{name}</span>
                    </li>
                    <li className='list-group-item text--right'>
                        Domain{' '}
                        <span className='DomainPrimary'>
                            <div className='d-flex flex-column align-items-end'>
                                <a href={hiddenRefLink} rel='noopener noreferrer' target='_blank'>
                                    {domain}
                                </a>
                                {sub_domain && <code>{sub_domain}</code>}
                            </div>
                            <button className='Domain btn' onClick={this._handleButtonClick}>
                                <i className='far fa-edit'/>
                            </button>
                        </span>
                    </li>
                    <DomainModal open={open} onClose={this._onCloseModal} domain={domain}
                                 storeDetails={storeDetails} changeStoreDetail={changeStoreDetail}/>


                    <StoreThemeName open={open} storeDetails={storeDetails} theme_name={theme_name}
                                    changeStoreDetail={changeStoreDetail}/>
                    <li className='list-group-item text--right'>
                        Scheduler Priority
                        <span className='d-flex align-items-center SchedulerPriorityButton'>
                            <div className='d-flex flex-column align-items-end'>
                                <code className='mt-1'>{scheduler_priority}</code>
                            </div>
                            <button className='Domain btn' onClick={this._toggleSchedularPriority}>
                                <i className='far fa-edit'/>
                            </button>
                        </span>
                    </li>
                    {
                        openSchedularPriority && <SchedulerPriorityModal
                            open={openSchedularPriority}
                            toggle={this._toggleSchedularPriority}
                            storeDetails={storeDetails}
                            schedulerPriority={scheduler_priority}
                            changeStoreDetail={changeStoreDetail}
                        />
                    }
                    <li className='list-group-item text--right'>
                        Store ID{' '}
                        <span>
                            <a href={hiddenRefWitchTo} rel='noopener noreferrer' target='_blank'>
                                {slug}
                            </a>
                        </span>
                    </li>
                    <li className='list-group-item text--right'>
                        Namespace{' '}
                        <span>
                            <a target="_blank" rel="noopener noreferrer"
                               href={`https://console.cloud.google.com/kubernetes/workload?organizationId=816432422381&project=platform126&workload_list_tablesize=200&workload_list_tablequery=%255B%257B_22k_22_3A_22is_system_22_2C_22t_22_3A10_2C_22v_22_3A_22_5C_22false_5C_22_22_2C_22s_22_3Atrue%257D_2C%257B_22k_22_3A_22metadata%252Fnamespace_22_2C_22t_22_3A10_2C_22v_22_3A_22_5C_22${namespace}_5C_22_22%257D%255D`}>Google Cloud</a>
                            <i> </i><code>{namespace}</code>
                        </span>
                    </li>
                    <li className='list-group-item text--right'>
                        Database Name <span>{db_name}</span>
                    </li>
                    <li className='list-group-item text--right'>
                        Bucket Image <span>{bucket_image}</span>
                    </li>
                    <li className='list-group-item text--right'>
                        Bucket Product Image <span>{bucket_product_image}</span>
                    </li>
                    <li className='list-group-item text--right'>
                        Created <span>{createDate}</span>
                    </li>
                    {!!updateDate && (
                        <li className='list-group-item text--right'>
                            Updated <span>{updateDate}</span>
                        </li>
                    )}
                    {!!activateDate && (
                        <li className='list-group-item text--right'>
                            Activated <span>{activateDate}</span>
                        </li>
                    )}
                    {!!lastSuppendAt  && (
                        <li className='list-group-item text--right'>
                            Last suspend at  <span>{lastSuppendAt}</span>
                        </li>
                    )}
                    <li className='list-group-item text--right'>
                        Version <span>{version}</span>
                    </li>
                    {
                        backup_database_file_path && <li className='list-group-item text--right restoreDatabase'>
                            Backup database file path
                            <button
                                onClick={this.downloadBackupDatabase}
                                style={{ border: 'none', background: 'transparent', textDecoration: 'underline', color: '#0f9aee', cursor: 'pointer' }}
                            >
                                {backup_database_file_path}
                            </button>
                        </li>
                    }
                    <div className='list-group-item text--right'>
                        Status
                        {/* <Link to={`/a/stores/${storeId}/deploy?tab=status`}>Status </Link> */}
                        <div className='StatusContainer'>
                            <span className={classNames('Status badge badge-secondary', status)}>{status}</span>
                            <ButtonChangeStatus store={storeDetails} changeStoreDetail={changeStoreDetail} isActivate={is_activate} />
                        </div>
                    </div>
                    <div className='list-group-item text--right'>
                        Backup & Drop database
                        <div className='StatusContainer'>
                            <ButtonBackupDropDatabase store={storeDetails} changeStoreDetail={changeStoreDetail} is_backup_and_drop_db={is_backup_and_drop_db}/>
                        </div>
                    </div>
                    <div className='list-group-item text--right restoreDatabase'>
                        Restore database
                        <div className='StatusContainer'>
                            <ButtonRestoreDatabase store={storeDetails} changeStoreDetail={changeStoreDetail} isRestore={is_restore} />
                        </div>
                    </div>
                    <div className='list-group-item text--right'>
                        Storefront available
                        <div className='StorefrontStatusContainer'>
                            <span className={classNames('StorefrontStatus badge badge-secondary', storefront)}>
                                {disabled_storefront ? 'unavailable' : 'available'}</span>
                            <ButtonChangeStoreFrontStatus store={storeDetails} changeStoreDetail={changeStoreDetail}/>
                        </div>
                    </div>
                    <div className='list-group-item text--right'>
                        Move database
                        <div className='StatusContainer'>
                            <ButtonMoveStore
                                store={storeDetails}
                                isActivate={!(db_move_resource_changed && Object.keys(db_move_resource_changed).length > 0)}
                                isModalMoveStoreOpen={isModalMoveStoreOpen}
                                onOpenMoveStoreModal={this._onOpenMoveStoreModal}
                                onCloseMoveStoreModal={this._onCloseMoveStoreModal}
                                resourcesList={resourcesList}
                            />

                            {(db_move_resource_changed && Object.keys(db_move_resource_changed).length > 0) && (
                                <>
                                    <ButtonDetailMoveDatabase store={storeDetails} />
                                    <ButtonDeleteOldDatabase store={storeDetails} />
                                    <ButtonRollbackDatabase store={storeDetails} />
                                </>
                            )}
                        </div>
                    </div>
                    <div className='list-group-item text--right'>
                        Database Statistics
                        <div className='StorefrontStatusContainer'>
                            <ButtonViewStoreStats store={storeDetails} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

StoreDetails.propTypes = {
    storeDetails: PropTypes.object.isRequired,
    changeStoreDetail: PropTypes.func.isRequired
}

export default StoreDetails
