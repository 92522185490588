import React, {Component} from 'react'
import ModalConfig from './ModalConfig'

class ModalAddConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowModalAdd: false,
        }
    }
    _handleToggleModal = () => { 
        this.setState({
            isShowModalAdd: !this.state.isShowModalAdd,
        })
    }
    render() {
        return (
            <div>
                <ModalConfig {...this.props} handleToggleModal={this._handleToggleModal} isModalOpen={this.state.isShowModalAdd} />
                <button onClick={this._handleToggleModal} className='btn btn-primary'>Add config</button>
            </div>
        )
    }
}

export default ModalAddConfig

