import React, {Component} from 'react'
import TimeRangeFilter from './TimeRangeFilter'
import ConversionsBox from './boxes/ConversionsBox'
import RevenueBox from './boxes/RevenueBox'
import VisitBox from './boxes/VisitBox'
import {getAllAnalytics} from '../../../services/api/AnalyticServices'
import {getMetricAllStores} from '../../../services/api/MetricServices'
import formatThousandNumber from '../../../helpers/common/formatThousandNumber'
import ChartContainer from './charts/components/ChartContainer'
import OrderBox from './boxes/OrderBox'
import ImporterBox from './boxes/ImporterBox'
import ProductBox from './boxes/ProductBox'

class AnalyticsPage extends Component {
    state = {
        value: 'today',
        type: 'quick',
        formattedAnalyticData: {},
        formattedMetricData: {},
        err: '',
        loading: true,
    }

    componentDidMount() {
        this._getAllAnalytics()
        this._getMetricAllStores()
    }

    _getTimeRange = () => {
        const {type, value} = this.state
        if (!value) return ''
        return `${type}|${value}`
    }

    _formatAnalyticData = data => {
        if (typeof data !== 'object') return {}
        let formattedAnalyticData = {}
        Object.keys(data).forEach(item => {
            formattedAnalyticData = {
                ...formattedAnalyticData,
                [item]: formatThousandNumber(data[item]),
            }
        })
        return formattedAnalyticData
    }

    _handleTimeRangeChange = (type, value) => {
        this.setState(
            {
                type,
                value,
            },
            () => {
                this._getAllAnalytics()
                this._getMetricAllStores()
            }
        )
    }

    _getMetricAllStores = async () => {
        try {
            const timeRange = this._getTimeRange()
            if (!timeRange) return
            const {success, data, message} = await getMetricAllStores({timeRange})
            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }
            const formattedMetricData = this._formatMetricData(data)
            return this.setState({
                formattedMetricData,
                loading: false,
            })
        } catch (error) {
            this.setState({
                err: error.message || error,
                loading: false,
            })
        }
    }

    _formatMetricData = data => {
        if (typeof data !== 'object') return {}
        let formattedMetricData = {}
        Object.keys(data).forEach(items => {
            const index = data[items]
            let newIndex = {}
            Object.keys(index).forEach(field => {
                const {value} = index[field]
                newIndex = {
                    ...newIndex,
                    [field]: formatThousandNumber(value),
                }
            })
            formattedMetricData = {
                ...formattedMetricData,
                [items]: newIndex,
            }
        })
        return formattedMetricData
    }

    _getAllAnalytics = async () => {
        try {
            const timeRange = this._getTimeRange()
            if (!timeRange) return
            const {success, data, message} = await getAllAnalytics({timeRange})
            if (!success) {
                return this.setState({
                    err: message,
                    loading: false,
                })
            }
            const formattedAnalyticData = this._formatAnalyticData(data)
            return this.setState({
                formattedAnalyticData,
                loading: false,
            })
        } catch (error) {
            this.setState({
                err: error.message || error,
                loading: false,
            })
        }
    }

    render() {
        const {formattedAnalyticData, formattedMetricData, err, type, value} = this.state

        return (
            <div className='AnalyticsPage'>
                <h2>Analytics</h2>
                <div className='AnalyticsPageInner SectionInner'>
                    <TimeRangeFilter type={type} value={value} handleTimeRangeChange={this._handleTimeRangeChange} />
                    {!!err && <span className='text-warning'>{err}</span>}
                    <div className='BoxContainer'>
                        <div className='AnalyticBoxes'>
                            <ConversionsBox formattedData={formattedAnalyticData} />
                            <RevenueBox formattedData={formattedAnalyticData} />
                            <VisitBox formattedData={formattedAnalyticData} />
                        </div>
                        <div className='MetricBoxes'>
                            <OrderBox formattedMetricData={formattedMetricData} />
                            <ImporterBox formattedMetricData={formattedMetricData} />
                            <ProductBox formattedMetricData={formattedMetricData} />
                        </div>
                    </div>
                    <ChartContainer />
                </div>
            </div>
        )
    }
}

export default AnalyticsPage
