import React, {Component} from 'react'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import ModalAddConfig from './ModalAddConfig'
import {Input, Spinner} from 'reactstrap'
import {applyGroupConfigs, syncGroupConfig} from '../../../../services/api/ClustersServices'
import {debounce} from 'lodash'
import {toaster} from '../../../shared/PFToast'
import {confirmModal} from '../../../shared/PFConfirmModalV3'

class TableConfig extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingSaveButton: false,
            loadingSyncButton: false,
        }
    }

    _handleSync = async () => {
        try {
            const {group} = this.props

            this.setState({
                loadingSyncButton: true,
            })
            await syncGroupConfig(group.cluster, group._id)
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({
                loadingSyncButton: false,
            })
        }
    }

    _handleApply = async () => {
        try {
            const {group} = this.props

            this.setState({
                loadingSaveButton: true,
            })
            const data = {
                type: 'config_map',
            }
            const {success, message} = await applyGroupConfigs(group.cluster, group._id, data)
            if (!success) {
                throw new Error(message)
            }
            this.setState({
                loadingSaveButton: false,
            })
        } catch (error) {
            console.error(error)
            toaster({type: 'error', message: error.message})
        } finally {
            this.setState({
                loadingSaveButton: false,
            })
        }
    }

    _handleOnChangeSearch = (e) => {
        const {value} = e.target
        this.debounceSearch(value)
    }

    debounceSearch = debounce(async (txtSearch) => {
        const {searchGroupConfigs} = this.props
        this.setState({page: 1})
        searchGroupConfigs(txtSearch)
    }, 500)

    render() {
        const {configs, inputRef} = this.props
        const configArray = Object.keys(configs).map((key) => ({
            key: key,
            value: configs[key],
        }))

        return (
            <div className="TableConfig">
                <h2>Configs</h2>
                <div className="SectionInner">
                    <div className="Header">
                        <ModalAddConfig title="Add config group" {...this.props} />
                        <div className="HeaderRight">
                            <div className="SearchBar">
                                <Input
                                    onChange={this._handleOnChangeSearch}
                                    type="text"
                                    className="SearchInput"
                                    placeholder="Enter key to search"
                                    innerRef={inputRef}
                                />
                                <i className="fas fa-search SearchIcon" />
                            </div>
                            <button className='btn btn-primary'
                                    id='btnSyncConfig'
                                    disabled={this.state.loadingSyncButton}
                                    onClick={() => {
                                    confirmModal({
                                        display: 'popover',
                                        type: 'warning',
                                        id: 'btnSyncConfig',
                                        placement: 'top',
                                        offset: '-74, 0',
                                        message: 'Are you sure you want to sync',
                                        onOk: this._handleSync,
                                    })
                                }}>
                                {this.state.loadingSyncButton && <Spinner size="sm" />} <i className="fas fa-sync-alt" /><span> Sync</span>
                            </button>
                            <button
                                className="btn btn-success"
                                id="btnApplyConfig"
                                type="button"
                                disabled={this.state.loadingSaveButton}
                                onClick={() => {
                                    confirmModal({
                                        display: 'popover',
                                        type: 'warning',
                                        id: 'btnApplyConfig',
                                        placement: 'top',
                                        offset: '-74, 0',
                                        message: 'Are you sure you want to approve',
                                        onOk: this._handleApply,
                                    })
                                }}
                            >
                                {this.state.loadingSaveButton && <Spinner size="sm" />} Apply config
                            </button>
                        </div>
                    </div>
                    <div className="Body table-responsive">
                        <table className="table">
                            <TableHeader />
                            {configArray.length > 0 && (
                                <TableBody page={this.state.page - 1} configArray={configArray} {...this.props} />
                            )}
                        </table>
                        {configArray.length <= 0 && <div className="text-center text-muted">No results</div>}
                    </div>
                </div>
            </div>
        )
    }
}

export default TableConfig

