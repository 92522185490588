import createAPIServices from "./createApiServices"

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/paypal-webhook' : 'https://dev2-manager.merch8.com/api/paypal-webhook'
const api = createAPIServices({ baseUrl })


export const createProfitAdjustment = (id, body) => {
    return api.makeAuthRequest({
        url: `/profit-adjustment/${id}`,
        method: 'POST',
        data: body
    })
}
