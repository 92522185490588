import React, {Component} from 'react'
import PropTypes from 'prop-types'

class SettingInput extends Component {
    _changeInput = (e) => {
        const {onChange} = this.props
        onChange && onChange(e.target.value)
    }

    render() {
        const {label, value, type, smallText, itemId, disabled, min, suffix} = this.props
        const inputProps = {
            value,
            type,
            id: itemId,
            disabled,
            min,
        }

        return (
            <div className="SettingInput">
                <div className="form-group">
                    {(label) ? <label htmlFor={itemId}>{label}</label> : null}
                    {(suffix) ? <div className="input-group">
                        <input
                            className="form-control"
                            {...inputProps}
                            onChange={this._changeInput}
                            aria-describedby={`${itemId}_addon`}
                        />
                        <div className="input-group-append">
                            <span
                                className="input-group-text"
                                id={`${itemId}_addon`}
                            >
                                {suffix}
                            </span>
                        </div>
                    </div> : <input className="form-control" {...inputProps} onChange={this._changeInput}/>}
                </div>
                <small className="form-text text-muted">{smallText}</small>
            </div>
        )
    }
}

SettingInput.defaultProps = {
    label: '',
    value: '',
    onChange: () => {
    },
    type: 'text',
    smallText: '',
    placeholder: '',
    disabled: false,
    min: '',
}

SettingInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    smallText: PropTypes.string,
    placeholder: PropTypes.string,
    itemId: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    min: PropTypes.string,
}

export default SettingInput
