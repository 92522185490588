import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ClusterColumn extends Component {
    render() {
        const {cluster, group} = this.props.item

        if (!cluster || typeof cluster !== 'object') return (
            <td className="ClusterColumn"/>
        )

        const {name} = Object.assign({}, cluster)
        const {namespace} = Object.assign({}, group)

        return (
            <td className="ClusterColumn">
                <div>{name}</div>
                {
                    !!namespace &&
                    <code>{namespace}</code>
                }
            </td>
        )
    }
}

ClusterColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ClusterColumn
