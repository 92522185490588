import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Modal from 'reactstrap/es/Modal'
import ModalBody from 'reactstrap/es/ModalBody'
import ModalFooter from 'reactstrap/es/ModalFooter'
import className from 'classnames'
import ListClusters from './ListClusters'
import {changeStoreCluster} from '../../../../services/api/InitializeStoreInvoiceService'

class ClusterModal extends Component {
    state = {
        selectedCluster: this.props.currentCluster,
        loading: false,
        error: '',
    }


    _handleUpdateSelectedCluster = newCluster => {
        this.setState({
            selectedCluster: newCluster,
        })
    }

    _handleClickSave = e => {
        e.preventDefault()

        this._handleSaveCluster()
    }

    _handleSaveCluster = async () => {
        const {storeId, onUpdateCluster, onToggleModal} = this.props
        const {selectedCluster} = this.state
        const {value: clusterId} = selectedCluster
        this.setState({
            loading: true,
        })

        try {
            const {data, success, message} = await changeStoreCluster(storeId, clusterId)
            if (!success) {
                return this.setState({
                    error: message,
                    loading: false,
                })
            }

            onUpdateCluster(data)

            return this.setState(
                {
                    loading: false,
                    error: '',
                },
                () => {
                    onToggleModal()
                }
            )
        } catch (e) {
            return this.setState({
                loading: false,
                error: e.message,
            })
        }
    }

    render() {
        const {showModal, onToggleModal, currentCluster} = this.props
        const {loading, selectedCluster, error} = this.state
        //console.log(selectedCluster)
        return (
            <Modal isOpen={showModal} toggle={onToggleModal}>
                <ModalBody>
                    <label className='mb-2'>Change cluster</label>
                    <ListClusters
                        currentCluster={currentCluster}
                        onUpdateSelectedCluster={this._handleUpdateSelectedCluster}
                    />

                    {error && <div className='text-danger mt-3'>{error}</div>}
                </ModalBody>
                <ModalFooter>
                    <button className='btn border' onClick={onToggleModal}>
                        Cancel
                    </button>
                    {loading ? (
                        <button className='btn btn-primary ml-3 disabled'>Saving...</button>
                    ) : (
                        <button
                            className={className('btn btn-primary ml-3', {
                                disabled: Object.entries(selectedCluster).length <= 0,
                            })}
                            onClick={this._handleClickSave}>
                            Save
                        </button>
                    )}
                </ModalFooter>
            </Modal>
        )
    }
}

ClusterModal.propTypes = {
    currentCluster: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    onToggleModal: PropTypes.func.isRequired,
    storeId: PropTypes.string.isRequired,
    onUpdateCluster: PropTypes.func.isRequired,
}

export default ClusterModal
