import moment from 'moment'

export default time => {

    if (!time) return '-'

    const momentTime = moment.isMoment(time) ? time : moment(time)
    const now = moment()

    const oneHourAgo = now.subtract(1, 'hour')
    if (oneHourAgo.isBefore(momentTime)) {
        return momentTime.fromNow()
    }

    const oneWeekAgo = now.subtract(1, 'week')
    if (oneWeekAgo.isBefore(momentTime)) {
        const dayOfWeek = momentTime.format('dddd')
        const time = momentTime.format('h:mm a')

        return `${dayOfWeek} at ${time}`
    }

    const oneYearAgo = now.subtract(1, 'year')
    if (oneYearAgo.isBefore(momentTime)) {
        return momentTime.format('MMM D, h:mm a')
    }

    return momentTime.format('MMM D, YYYY')
}
