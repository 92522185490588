import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {changeStorePreferredLanguage} from "../../../services/api/StoreServices"

class StoreMode extends Component {
    state = {
        loading: false,
        language: ''
    }

    _handleClickChange = async e => {
        e.preventDefault()
        if (this.state.loading) return false
        const r = window.confirm('Are you sure to change store preferred language?')
        if (!r) return false
        this.setState({
            loading: true,
        })
        const {getStoreDetail} = this.props
        const {language} = this.state
        const {_id: storeId} = this.props.store
        try {
            const {success, message} = await changeStorePreferredLanguage(storeId, language || 'vi')

            if (!success) {
                throw new Error(message)
            }

            this.setState({
                loading: false
            }, () => {
                window.alert('Successfully!')
                getStoreDetail()
            })

        } catch (e) {
            this.setState({
                loading: false,
            }, () => {
                window.alert(e.message)
            })
        }
    }

    _handleChange = e => {
        const {value} = e.target
        this.setState({
            language: value
        })
    }

    render() {
        const {language, loading} = this.state
        const _language = language || this.props.store.preferred_language
        return (
            <div className="StorePreferredLanguage card">
                <div className="card-header">
                    Preferred Language
                </div>

                <div className="card-body">
                    <div className="form-group">
                        <select value={_language} onChange={this._handleChange} className="form-control">
                            <option disabled value="">Select store preferred language</option>
                            <option value="vi">Vietnamese</option>
                            <option value="en">English</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <button onClick={this._handleClickChange} disabled={loading}
                                className="btn btn-secondary">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

StoreMode.propTypes = {
    store: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default StoreMode
