import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class InputNotificationTitle extends Component {

    _changeInput = e => {
        const {name, value} = e.target
        this.props.onChangeNotificationData(name, value)
    }


    render() {
        const {formData, message} = this.props
        const {title} = formData.notification
        const validValue = title.trim()

        return (
            <div className="InputNotificationTitle form-group">
                <label htmlFor="createNotificationContent">Title</label>
                <input value={title}
                       className={classNames('form-control ', {'border-danger': message && validValue === ''})}
                       name='title' type='text'
                       onChange={this._changeInput}/>
                {
                    message && validValue === '' && <p className='text-danger font-italic fs-14'>{message}</p>
                }
            </div>
        )
    }
}

InputNotificationTitle.propTypes = {
    onChangeNotificationData: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    formData: PropTypes.object.isRequired,
}

export default InputNotificationTitle
