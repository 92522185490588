import React, {Component} from 'react'
import {Button} from 'reactstrap'
import ExportModal from './ExportModal'

class ContestReportPage extends Component {
    state = {
        isOpen: false
    }

    _toggleModal = () => {
        this.setState(prevState => ({isOpen: !prevState.isOpen}))
    }

    render() {
        const {isOpen} = this.state

        return (
            <div className='ContestReportPage'>
                <div className='ContestReportPageInner SectionInner'>
                    <h3>All Store</h3>
                    <Button color='primary' onClick={this._toggleModal}>
                        Export
                    </Button>
                </div>

                <ExportModal isOpen={isOpen} onCloseModal={this._toggleModal}/>
            </div>
        )
    }
}

export default ContestReportPage
