import React, {Component} from 'react'
import PropTypes from 'prop-types'
import DesignFormatContext from './context/DesignFormatContext'
import DesignFormatLayer from './DesignFormatLayer'
import DesignFormatPanel from './DesignFormatPanel'
import DesignFormatConst from './DesignFormatConst'
import {Tooltip} from 'reactstrap'

class DesignFormatContainer extends Component {
    childPanels = React.createRef()

    state = {
        show: false,
        type: '',
        productId: '',
        items: [],
        featureImage: '',
        prevFontCreated: null // trigger the child's componentDidUpdate via props
    }

    wrapPreview = React.createRef()
    preview = React.createRef()
    sortableContainerRef = React.createRef()

    componentDidMount() {
        const {type, product, productId} = this.props
        if (!product || !product.designs || product.designs.length === 0) {
            return
        }

        const design = product.designs[0]
        const items = design.elements
        const enableEdit = design.can_preview
        let image = product.image
        if (design.image) {
            image = design.image.replace('thumb.', 'regular.')
        }
        const featureImage = image

        const stateVal = {
            type,
            enableEdit,
            items,
            featureImage,
            productId
        }
        const frameSize = this._getDeviceSize()
        this.context.initConfig(productId, product, stateVal, frameSize)
        this.setState(stateVal)
    }

    _getDeviceSize = () => {
        if (this.wrapPreview.current) {
            return this.wrapPreview.current.offsetWidth
        }
        const deviceSize = window.innerWidth
        if (deviceSize < DesignFormatConst.DEVICE_MOBILE_SIZE) {
            return deviceSize - 70
        } else if (deviceSize < 1366) {
            return DesignFormatConst.DESIGN_FRAME_SIZE
        } else return deviceSize / 3
    }

    _onTooltipPreview = () => {
        this.setState({
            show: !this.state.show
        })
    }

    render() {
        const { show } = this.state
        const clonedElements = this.context.designs

        return (
            <div className="position-relative mt-4">
                <div className="design-container" id='ProductDesignContainer'>
                    <h5 className="Title">Design Format</h5>
                    <div className="text-muted mb-2">
                        Customize the elements such as texts and images for your product below
                    </div>
                    <div className="d-flex align-items-center">
                        <label className="wrap-checkmark m-0" htmlFor="chkEditMode">
                            <span className="text-preview">Allow preview</span>
                            <input
                                className="mr-2 custom-checkmark"
                                id="chkEditMode"
                                type="checkbox"
                                checked={this.state.enableEdit || true}
                                disabled={true}
                            />
                            <span className="checkmark"></span>
                        </label>
                        <i className="ti-info-alt ml-2 cursor-pointer" id="TooltipPreview"/>
                        <Tooltip placement="top" isOpen={show} target="TooltipPreview" toggle={this._onTooltipPreview}>
                            {DesignFormatConst.MESSAGES_PREVIEW}
                        </Tooltip>
                    </div>
                    <div className="wrap-panel w-auto d-flex justify-content-between">
                        <div className="panel col-md-6 p-0">
                            <div className="panel-elements" style={{height: this.context.frameSize - 50}}>
                                <div className="d-flex justify-content-end p-2 pt-0">
                                    <strong> </strong>
                                </div>
                                <div className="table" ref={this.sortableContainerRef}>
                                    {clonedElements && [...clonedElements].reverse().map((item, index) => {
                                        return (<DesignFormatPanel
                                                item={{...item, index}}
                                                index={index}
                                                childRef={this.childPanels}
                                                key={item.sortId}
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="preview d-flex justify-content-center col-md-6 p-0" ref={this.wrapPreview}>
                            {this.state.featureImage && (
                                <div id={'main-preview'} className={'position-relative w-100'}>
                                    <DesignFormatLayer
                                        featureImage={this.state.featureImage}
                                        ref={this.preview}
                                        key="frame.app"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

DesignFormatContainer.propTypes = {
    gallery: PropTypes.array.isRequired,
    productId: PropTypes.string
}
DesignFormatPanel.defaultProps = {
    productId: ''
}

DesignFormatContainer.contextType = DesignFormatContext

export default DesignFormatContainer
