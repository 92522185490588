import React, {Component} from 'react'

class ProductVariantAction extends Component {
    _handClickRemove = (is_deleted) => () => {
        if (is_deleted && !window.confirm('Are you sure to remove this variant?')) return
        const {handleListVariantsState, variantIndex} = this.props

        handleListVariantsState(variantIndex, 'is_deleted', is_deleted, true)
    }

    render() {
        const {variant} = this.props

        return (
            <td className="ProductVariantAction">
                <div className="d-flex justify-content-center">
                    {variant.is_deleted ? (
                        <button className="btn btn-primary" onClick={this._handClickRemove(false)}>
                            Revert
                        </button>
                    ) : (
                        <button className="btn btn-danger" onClick={this._handClickRemove(true)}>
                            <i className="ti-trash" />
                        </button>
                    )}
                </div>
                {/*<span className="text-danger ml-3 cursor-pointer" onClick={this._handClickRemove}><i className="ti-trash"/></span>*/}
            </td>
        )
    }
}

export default ProductVariantAction

