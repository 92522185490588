import React, {Component} from 'react'
import WithAuthentication from "./WithAuthentication"
import {Redirect} from "react-router-dom"

class EnsureLoggedIn extends Component {
    render() {
        return (
            <WithAuthentication>
                {
                    ({isAuthenticated}) => {
                        return isAuthenticated ? this.props.children : <Redirect to='/login'/>
                    }
                }
            </WithAuthentication>
        )
    }
}

export default EnsureLoggedIn
