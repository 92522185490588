import React, {Component} from 'react'
import CreateCategoryPage from './CreateCategoryPage'

class CreateCategoryContainer extends Component {
    render() {
        return (
            <div className='CreateCategoryContainer'>
                <CreateCategoryPage />
            </div>
        )
    }
}

export default CreateCategoryContainer
