import React, {Fragment} from 'react'
import SettingsPage from './SettingsPage'
import DocTitleByStore from '../../dashboard/shared/DocTitleByStore'
import {isAdmin, isSuperAdmin} from '../../../services/AuthServices'

const SettingsPageContainer = (props) => {

    const roleSupperAdmin = isSuperAdmin()
    const roleAdmin = isAdmin()

    if (roleSupperAdmin || roleAdmin) return (
        <Fragment>
            <DocTitleByStore title="Settings"/>
            <SettingsPage isSupperAdmin={roleSupperAdmin} {...props}/>
        </Fragment>
    )

    return null
}

export default SettingsPageContainer
