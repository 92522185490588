import React, {Component} from 'react'
import PropTypes from 'prop-types'

class IndexColumn extends Component {
    render() {
        const {item} = this.props
        const {index} = item

        return (
            <td className='Index'>
                <span>{index || '--'}</span>
            </td>
        )
    }
}

IndexColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default IndexColumn
