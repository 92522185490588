import createAPIServices from './createApiServices'

const baseUrl = process.env.NODE_ENV === 'production' ? '/api/manager' : 'https://admin-staging.merchize.com/api/manager/'

const api = createAPIServices({ baseUrl })

export const getOrders = data =>
    api.makeAuthRequest({
        url: `/trademarks/search`,
        method: 'POST',
        data
    })

export const addOrderToWhitelist = orderId =>
    api.makeAuthRequest({
        url: `/whitelist-order-trademark/${orderId}`,
        method: 'GET'
    })

export const addItemToWhitelist = itemId =>
    api.makeAuthRequest({
        url: `/whitelist-order-item/${itemId}`,
        method: 'GET',
    })

export const getRelevantOrders = id =>
    api.makeAuthRequest({
        url: '/get-order-with-element',
        method: 'GET',
        params: {
            order_item: id
        }
    })

export const bulkMarkWhitelist = (data) => {
    return api.makeAuthRequest({
        url: `/trademarks/bulkMarkWhitelist`,
        method: 'POST',
        data
    })
}

export const bulkMarkAsRead = (data) => {
    return api.makeAuthRequest({
        url: `/trademarks/bulkMarkRead`,
        method: 'POST',
        data
    })
}
