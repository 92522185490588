import React from 'react'
import {SortableElement, sortableHandle} from 'react-sortable-hoc'
import PropTypes from 'prop-types'

const DragHandle = sortableHandle((props) => {
    const {preview} = props
    if (!preview) return null

    return (
        <div className='Image'>
            <img src={preview} alt=''/>
        </div>
    )
})

const ProductGallerySortableItem = SortableElement(props => {
    const {preview} = props
    return (
        <div className='ProductGalleryImage'>
            <DragHandle preview={preview}/>
        </div>
    )
})

ProductGallerySortableItem.propTypes = {
    preview: PropTypes.string.isRequired,
}

export default ProductGallerySortableItem
