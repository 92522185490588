import React, {Component} from 'react'

// import PropTypes from 'prop-types'

class RatePreferContainer extends Component {
    state = {
        prefer: this.props.prefer
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.prefer !== this.props.prefer) {
            this.setState({
                prefer: this.props.prefer
            })
        }
    }

    _handleChangeSelect = e => {
        const {value} = e.target

        this.setState({
            prefer: value
        })

        this.props.onUpdateRatesData("prefer", value)
    }

    render() {
        const {prefer} = this.state

        return (
            <div className="RatePreferContainer">
                <div className="SectionInner">
                    <h6 className="mb-0 fs-18">Preferred Rules</h6>
                    <div className="text-muted fs-14">Preferred rule to apply when orders meet multiple rules.</div>

                    <div className="border-top mt-4 pt-4">
                        <div className='col-12 col-sm-6 p-0 '>
                            <select name="prefer" className="form-control" onChange={this._handleChangeSelect} value={prefer}>
                                <option value="max">Highest returned rule</option>
                                <option value="min">Lowest returned rule</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// RatePreferContainer.defaultProps = {}

// RatePreferContainer.propTypes = {}

export default RatePreferContainer
