import {createStoreAPI} from './createStoreAPIService'

const api = createStoreAPI({suffix: '/stores'})

export const getListStores = (params = {}) => {
    const defaultParams = {
        limit: 20,
        page: 1,
    }

    const newParams = {...defaultParams, ...params}

    return api.makeAuthRequest({
        url: '',
        method: 'GET',
        params: newParams,
    })
}

export const getListStoresV2 = (params = {}) => {
    const defaultParams = {
        limit: 20,
        page: 1,
    }

    const newParams = {...defaultParams, ...params}

    return api.makeAuthRequest({
        url: '/search',
        method: 'GET',
        params: newParams,
    })
}

export const backupDropDatabase = (payload) => {
    return api.makeAuthRequest({
        url: `/jobs/backup-database`,
        method: 'POST',
        data: payload,
    })
}

export const restoreDatabaseV2 = (storeId, payload) => {
    return api.makeAuthRequest({
        url: `/${storeId}/restore`,
        method: 'POST',
        data: payload,
    })
}

export const getStoreDetail = (storeId = '') => {
    return api.makeAuthRequest({
        url: `/${storeId}`,
        method: 'GET',
    })
}

export const getStoreHistories = (storeId = '') => {
    return api.makeAuthRequest({
        url: `/${storeId}/histories`,
        method: 'GET',
        params: {
            limit: 100,
        },
    })
}

export const activateStore = (storeId = '') => {
    return api.makeAuthRequest({
        url: `/${storeId}/jobs/active`,
        method: 'POST',
    })
}

export const restoreDatabase = (storeId = '') => {
    return api.makeAuthRequest({
        url: `/${storeId}/restore`,
        method: 'POST',
    })
}

export const suspendStore = (storeId = '') => {
    return api.makeAuthRequest({
        url: `/${storeId}/jobs/suspend`,
        method: 'POST',
    })
}

export const moveStore = (storeId = '', selectedResource) => {
    return api.makeAuthRequest({
        url: `/${storeId}/jobs/move-database`,
        method: 'POST',
        data: selectedResource,
    })
}

export const deleteOldDatabase = (storeId = '') => {
    return api.makeAuthRequest({
        url: `/${storeId}/jobs/delete-old-database`,
        method: 'POST',
    })
}

export const rollbackDatabase = (storeId = '') => {
    return api.makeAuthRequest({
        url: `/${storeId}/jobs/rollback-moved-database`,
        method: 'POST',
    })
}

export const compareToOldDatabase = (storeId = '') => {
    return api.makeAuthRequest({
        url: `/${storeId}/actions/compare-to-old-db`,
        method: 'POST',
    })
}

export const renewDbStats = (storeId = '') => {
    return api.makeAuthRequest({
        url: `/${storeId}/actions/renew-db-statistics`,
        method: 'POST',
    })
}

export const getDbStatistics = (storeId = '') => {
    return api.makeAuthRequest({
        url: `/${storeId}/actions/load-db-statistics`,
        method: 'POST',
    })
}

export const downloadBackupDb = (storeId = '', data) => {
    return api.makeAuthRequest({
        url: `/${storeId}/download-backup-db`,
        method: 'POST',
        data,
    })
}

export const createStoreBatchesAction = (data) => {
    return api.makeAuthRequest({
        url: `/batchActions`,
        method: 'POST',
        data,
    })
}

export const changeStoreOwner = ({ownerId = '', storeId = ''}) => {
    return api.makeAuthRequest({
        url: `/${storeId}/owner`,
        method: 'POST',
        data: {
            ownerId,
        },
    })
}

export const getAccount = (params = {}) => {
    return api.makeAuthRequest({
        url: '/accounts/by-email',
        method: 'GET',
        params: params,
    })
}

export const changeDomain = (storeId, newDomain) => {
    return api.makeAuthRequest({
        url: `/${storeId}/domain`,
        method: 'POST',
        data: {
            newDomain,
        },
    })
}

export const addStoreTags = (storeId, titles) => {
    return api.makeAuthRequest({
        url: `/${storeId}/tags`,
        method: 'POST',
        data: titles,
    })
}

export const updateStoreTags = (storeId, tags) => {
    return api.makeAuthRequest({
        url: `/${storeId}/tags-update`,
        method: 'POST',
        data: tags,
    })
}

export const updateStoreThemes = (storeId, theme) => {
    return api.makeAuthRequest({
        url: `/${storeId}/theme`,
        method: 'POST',
        data: {theme: theme},
    })
}

export const changeStoreNote = (storeId, note) => {
    return api.makeAuthRequest({
        url: `/${storeId}/note`,
        method: 'POST',
        data: note,
    })
}

export const changeStorefrontStatus = (storeId, status) => {
    return api.makeAuthRequest({
        url: `/${storeId}/storefront`,
        method: 'POST',
        data: {status},
    })
}

export const pushNotifications = (notification) => {
    return api.makeAuthRequest({
        url: '/notifications',
        method: 'POST',
        data: notification,
    })
}

export const addNewFeatureTags = (storeId, tags) => {
    return api.makeAuthRequest({
        url: `/${storeId}/feature-tags`,
        method: 'POST',
        data: {
            tags: tags,
        },
    })
}

export const updateFeatureTags = (storeId, tags) => {
    return api.makeAuthRequest({
        url: `/${storeId}/feature-tags-update`,
        method: 'POST',
        data: {
            tags: tags,
        },
    })
}

export const getStoreAccounts = (storeId, role) => {
    return api.makeAuthRequest({
        url: `/${storeId}/accounts`,
        method: 'GET',
        data: {
            role: role,
        },
    })
}

export const changeStoreMode = (storeId, mode) => {
    return api.makeAuthRequest({
        url: `/${storeId}/mode`,
        method: 'POST',
        data: {
            mode,
        },
    })
}

export const changeStorePreferredLanguage = (storeId, language) => {
    return api.makeAuthRequest({
        url: `/${storeId}/preferred-language`,
        method: 'POST',
        data: {
            language,
        },
    })
}

export const searchGlobalSettings = (limit = 20, page, key = '', value = '') => {
    const api = createStoreAPI()
    return api.makeAuthRequest({
        url: '/global-settings/search',
        method: 'POST',
        data: {
            limit,
            page,
            key,
            value,
        },
    })
}

export const createItemGlobalSetting = (key = '', value = '') => {
    const api = createStoreAPI()
    return api.makeAuthRequest({
        url: `/global-settings`,
        method: 'POST',
        data: {
            key: key,
            value: value,
        },
    })
}

export const deleteGlobalSetting = (settingId) => {
    const api = createStoreAPI()
    return api.makeAuthRequest({
        url: `/global-settings/${settingId}`,
        method: 'DELETE',
    })
}

export const editGlobalSetting = (settingId, value) => {
    const api = createStoreAPI()
    return api.makeAuthRequest({
        url: `/global-settings/${settingId}`,
        method: 'POST',
        data: {
            value,
        },
    })
}

export const importProductStoreToStores = (originalStoreId, destinationStoreId) => {
    const api = createStoreAPI()
    return api.makeAuthRequest({
        url: '/stores/product-transfer',
        method: 'POST',
        data: {
            originalStoreId: originalStoreId,
            destinationStoreId: destinationStoreId,
        },
    })
}

export const filterStoreTransferLog = (storeId, params) => {
    const api = createStoreAPI()
    Object.keys(params).forEach((key) => {
        if (params[key] === '') {
            delete params[key]
        }
    })
    return api.makeAuthRequest({
        url: `product-transfer-jobs-stores/${storeId}/histories`,
        method: 'GET',
        params,
    })
}

export const filterStoreTransferJob = (storeId, params) => {
    const api = createStoreAPI()
    Object.keys(params).forEach((key) => {
        if (params[key] === '') {
            delete params[key]
        }
    })
    return api.makeAuthRequest({
        url: `product-transfer-jobs-stores/${storeId}/jobs`,
        method: 'GET',
        params,
    })
}

export const cancelStoreTransferJob = (storeId, data) => {
    const api = createStoreAPI()
    return api.makeAuthRequest({
        url: `product-transfer-jobs-stores/${storeId}/bulkCancel`,
        method: 'POST',
        data,
    })
}

export const creatCashback = (data) => {
    const api = createStoreAPI()
    return api.makeAuthRequest({
        url: `cashback`,
        method: 'POST',
        data,
    })
}

export const fetchCashbackHistory = (params) => {
    const api = createStoreAPI()
    return api.makeAuthRequest({
        url: `cashback`,
        method: 'GET',
        params,
    })
}

export const fetchCashbackLog = (cashbackId) => {
    const api = createStoreAPI()
    return api.makeAuthRequest({
        url: `cashback/${cashbackId}/log`,
        method: 'GET',
    })
}

export const ignoreSuspend = (storeId) => {
    return api.makeAuthRequest({
        url: `${storeId}/ignore-automatic-suspension`,
        method: 'PUT',
    })
}

export const exportCSV = (params = {}) => {
    const defaultParams = {
        limit: 20,
        page: 1,
    }

    const newParams = {...defaultParams, ...params}

    return api.makeAuthRequest({
        url: '/exportCSV',
        method: 'POST',
        params: newParams,
    })
}

export const assignGroupToStore = (storeId, groupId) => {
    return api.makeAuthRequest({
        url: `/${storeId}/group`,
        method: 'POST',
        data: {
            group: groupId,
        },
    })
}
