import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {changeDomain} from '../../../services/api/DeployService'


class DomainModal extends Component {
    state = {
        domain:this.props.domain,
        err:'',
    }

    _onToggle = () => {
        this.props.onClose()
    }

    _handleChangeDomain = async (e) => {
        e.preventDefault()
        this.setState({
            err: '',
        })
        try {
            const {storeDetails,changeStoreDetail} = this.props
            const {_id:storeId} = storeDetails
            const {domain} = this.state

            const {success, message} = await changeDomain(storeId,domain)
            if (!success) {
                return this.setState({
                    err: message,
                })
            }

            changeStoreDetail({
                ...storeDetails,
                domain,
            })
            const {onClose} = this.props
            return onClose()
        } catch (e) {
            this.setState({
                err: e.message || e,
            })
        }
    }

    _handleTitleChange = event => {
        const value = event.target.value
        this.setState(
            {
                    domain: value,
                },

        )
    }

    render() {
        const {open} = this.props
        const {domain} = this.state
        return (
            <Modal isOpen={open} toggle={this._onToggle} className="DomainModal">
                <ModalHeader toggle={this._onToggle}>Domain</ModalHeader>
                <ModalBody>
                    <div className="DomainModal">
                        <input type="text" className="form-control" value={domain || ""}
                                onChange={this._handleTitleChange}
                                required
                                />
                    </div>
                </ModalBody>
                <ModalFooter>
                        <button className="btn btn-default" onClick={this._onToggle}>Cancel</button>
                        <button className="btn btn-primary" onClick={this._handleChangeDomain}>Save</button>
                </ModalFooter>
            </Modal>
        )
    }
}

DomainModal.propTypes = {
    open: PropTypes.bool.isRequired,
    domain: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    changeStoreDetail: PropTypes.func.isRequired,
    storeDetails: PropTypes.object.isRequired
}

export default DomainModal
