import React, {Component} from 'react'
import {SortableContainer} from 'react-sortable-hoc'
import ProductGalleryUploader from './ProductGalleryUploader'
import PropTypes from 'prop-types'
import ProductGalleryImage from './ProductGalleryImage'

class ProductGallerySortableContainer extends Component {
    render() {
        const {gallery, onAddImage, ...rest} = this.props

        return (
            <div className='ProductGallerySortableContainer'>
                {
                    gallery.map((image, index) => {
                        return (
                            <ProductGalleryImage
                                key={image.side}
                                {...rest}
                                index={index}
                                position={index}
                                image={image}
                            />
                        )
                    })
                }

                <ProductGalleryUploader/>
            </div>
        )
    }
}

ProductGallerySortableContainer.propTypes = {
    gallery: PropTypes.array.isRequired,
    showAll: PropTypes.bool.isRequired,
    productId: PropTypes.string.isRequired,
    onRemoveImage: PropTypes.func.isRequired,
}

export default SortableContainer(ProductGallerySortableContainer)
