import React, {Component, Fragment} from 'react'
import moment from 'moment'
import humanizeTime from '../../../../helpers/time/humanizeTime'
import TableLoading from '../../../shared/TableLoading'
import {Link} from 'react-router-dom'
import {confirmModal} from '../../../shared/PFConfirmModalV2'
import {deleteProductMockupDetail} from '../../../../services/api/ProductLinesServices'
import {toaster} from '../../../shared/PFToast'

class ProductMockupTableBody extends Component {

    constructor(props) {
        super(props)
        this.state = {
            checkedList : []
        }
    }
    _validateDate = time => {
        const momentTime = moment(time)
        return humanizeTime(momentTime)
    }

    _handleClickDelete = (title, id) => (e) => {
        e.preventDefault()
        confirmModal({
            confirmText: 'Delete',
            cancelText: 'Cancel',
            confirmColor: 'danger',
            title: 'Confirm delete',
            message: <span>Are you sure you want to delete <b>{title}?</b></span>,
            onOk: () => this._deleteMockUpDetail(id)
        })
    }

    _deleteMockUpDetail = async (id) => {
        try {
            const {success, message} = await deleteProductMockupDetail(id)

            if (!success) throw new Error(message)

            toaster({
                type: 'success',
                message: 'Deleted Product Mockup successfully!',
                duration: 5000
            })

            this.props.onGetProductMockUp()

        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
                duration: 5000
            })
        }
    }

    _handleChangeCheckbox = (e, id, title) => {
        let updatedList = [...this.props.checked];
        let updatedListTitle = [...this.props.titleExport];
        if (e.target.checked) {
            updatedList = [...this.props.checked, id]
            updatedListTitle = [...this.props.titleExport, title]
            if (!this.props.isSelectAll && updatedList.length === this.props.mockups.length) {
                this.props.changeSelectAll(true)
            }
        } else {
            if (this.props.isSelectAll) {
                this.props.changeSelectAll(false)
            }
            updatedList.splice(this.props.checked.indexOf(id), 1);
            updatedListTitle.splice(this.props.titleExport.indexOf(title), 1);
        }
        this.setState({
            checkedList: updatedList
        })
        this.props.getProductMockupExportIds(updatedList, updatedListTitle)
    }

    render() {
        const {mockups, loading, error ,checked} = this.props
        return (
            <Fragment>
                {
                    loading ?
                        <TableLoading columnQuantity={4}/>
                        :
                        error ?
                            <tbody>
                            <tr>
                                <td colSpan="4"><i className='text-danger fs-14'>{error}</i></td>
                            </tr>
                            </tbody>
                            :
                            Array.isArray(mockups) && mockups.length === 0 ?
                                <tbody>
                                <tr>
                                    <td colSpan="4"><i className='text-muted'>No results!</i></td>
                                </tr>
                                </tbody>
                                :
                                <tbody className='ProductMockupTableBody TableLoading'>
                                {
                                    Array.isArray(mockups) && mockups.map((item, index) => {
                                        const {title,product_type ,created, _id} = item
                                        const createDate = this._validateDate(created)
                                        return (
                                            <tr key={`ProductMockupList_${index}`}>
                                                <td className='d-flex align-items-center '>
                                                    <span className='mr-2 WidthSpanStt'>{index + 1}</span>
                                                    <input
                                                        
                                                        type="checkbox"
                                                        checked={checked.includes(_id)}
                                                        onChange={(e) => {
                                                            this._handleChangeCheckbox(e, _id, title)
                                                        }} />
                                                </td>

                                                <td><Link to={`/a/product-mockup/${_id}`}>{title || ''}</Link></td>
                                                <td>{product_type}</td>
                                                <td>{createDate}</td>
                                                <td className='text-center fs-14 text-danger cursor-pointer'
                                                    onClick={this._handleClickDelete(title, _id)}><i
                                                        className='ti-close' />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                }

            </Fragment>
        )
    }
}

export default ProductMockupTableBody
