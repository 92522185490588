import React, {useEffect, useState} from 'react'
import {
    getListAppliedStores,
    getListPrivatePrice, getListPrivatePriceBaseCost,
    getProductCatalogDetail, updatePrivateBaseCost
} from '../../../services/api/ProductLinesServices'
import {toaster} from '../../shared/PFToast'
import {randomID} from '../../../helpers/common/randomID'
import trashIcon from '../../../statics/assets/icons/x.svg'
import {Input} from 'reactstrap/es'
import CatalogPrivateBaseCostFilter from './CatalogPrivateBaseCostFilter'
import BackNavigationButton from '../../shared/BackNavigationButton'
import {Button} from 'reactstrap'
import getHistory from '../../../store/getHistory'
import Select from 'react-select';

const CatalogPrivateBaseCostContainer = (props) => {
    const {match} = props
    const catalogId = match.params.catalogId
    const [filter, setFilter] = useState({})
    const [attributes, setAttributes] = useState([])
    const [valueAttributes, setValueAttributes] = useState([])
    const [variants, setVariants] = useState([])
    const [originVariants, setOriginVariants] = useState([])
    const [listStores, setListStores] = useState([])
    const [originStores, setOriginStores] = useState([])
    const [allStores, setAllStores] = useState([])
    const [isAdd, setIsAdd] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [isDisableSave, setIsDisableSave] = useState(true)

    const _handleFilterVariants = (filter) => {
        const _stores = _filterVariants(originStores, filter)
        setFilter(filter)
        setListStores(_stores)
        // setOriginStores(_stores)
    }

    const _handleCancel = () => {
        const history = getHistory();
        history.push(`/a/catalog/${catalogId}`);
    }

    const _addStore = (e) => {
        e.preventDefault()
        setIsAdd(true)
        const newStore = {
            prices: variants.map(itemVariant => {
                const {price, ...res} = itemVariant
                return {
                    ...res
                }
            }),
            action_type: "update",
            store: {}
        }
        const newStoreOrigin = {
            prices: originVariants.map(itemVariant => {
                const {price, ...res} = itemVariant
                return {
                    ...res
                }
            }),
            action_type: "update",
            store: {}
        }
        setListStores([...listStores, newStore])
        setOriginStores([...originStores, newStoreOrigin])
    }

    const _filterVariants = (stores, filter) => {
        const _result = stores.map(item => {
            const _variants = item.prices.filter((variant) => {
                let matchCount = 0
                for (let key in filter) {
                    const condition = variant.attributes.some((option) => option.value_code === filter[key])
                    if (condition) {
                        matchCount++
                    }
                }
                return matchCount === Object.keys(filter).length
            })
            return {
                ...item,
                prices: _variants
            }
        })
        return _result
    }

    const _fetchListStore = async () => {
        try {
            const {success, message, data} = await getListAppliedStores(catalogId)

            if (!success) throw new Error(message)

            setAllStores(data)
        } catch (e) {
            toaster({
                type: 'error',
                message: e.message,
            })
        }
    }

    const _fetchListPrivate = async () => {
        try {
            setLoading(true)
            const {success, message, data} = await getListPrivatePriceBaseCost(catalogId)
            if (!success) throw new Error(message)
            setListStores(data)
            setOriginStores(data)
            if(data.length > 0) {
                setVariants(data[0].prices)
                setOriginVariants(data[0].prices)
                setFilter({})
                if(data[0].prices.length > 0) {
                    const _attributes = data[0].prices[0].attributes
                    const _variants = data[0].prices
                    let valueAttibutes = []
                    _variants.map(item => {
                        const _attibutes = item.attributes
                        _attibutes.map(itemA => {
                            if(valueAttibutes.find(itemFind => itemFind.value_text === itemA.value_text && itemFind.value_code === itemA.value_code) === undefined) {
                                valueAttibutes.push(itemA)
                            }
                        })
                    })
                    setValueAttributes(valueAttibutes)
                    setAttributes(_attributes)
                }
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            toaster({
                type: 'error',
                message: e.message,
            })
        }
    }

    const _handleChangeInput = (variantId, storeID, value, type, event) => {
        setIsDisableSave(false)
        const _stores = listStores.map((item) => {
            if(storeID === item.store._id && item?.action_type !== 'delete') {
                return {
                    ...item,
                    action_type: type,
                    prices: item.prices.map((itemPrice) => {
                        if (itemPrice.variant_id === variantId) {
                            return {
                                ...itemPrice,
                                variant_type: 'update',
                                price: !!value ? parseFloat(value) : null
                            }
                        } else {
                            return itemPrice
                        }
                    })
                }
            } else {
                return item
            }
        })
        const _storesOrigin = originStores.map((item) => {
            if(storeID === item.store._id && item?.action_type !== 'delete') {
                return {
                    ...item,
                    action_type: type,
                    prices: item.prices.map((itemPrice) => {
                        if (itemPrice.variant_id === variantId) {
                            return {
                                ...itemPrice,
                                variant_type: 'update',
                                price: !!value ? parseFloat(value) : null
                            }
                        } else {
                            return itemPrice
                        }
                    })
                }
            } else {
                return item
            }
        })
        if(parseFloat(value) <= 0) {
            event.target.classList.add('error')
        } else {
            event.target.classList.remove('error')
        }
        setListStores(_stores)
        setOriginStores(_storesOrigin)
    }

    const _handleUpdate = async () => {
        const storesUpdated = originStores.filter(item => item.action_type !== undefined)
        setIsDisableSave(true)
        if(storesUpdated.length > 0) {
            const _stores = storesUpdated.map(itemStore => {
                if(itemStore.action_type === 'delete') {
                    return {
                        action_type: itemStore.action_type,
                        store_id: itemStore.store._id,
                    }
                } else {
                    return {
                        action_type: itemStore.action_type,
                        store_id: itemStore.store._id,
                        prices: itemStore.prices.filter(item => item.variant_type !== undefined).map(itemPrice => {
                            return {
                                variant_id: itemPrice.variant_id,
                                price: itemPrice.price
                            }
                        })
                    }
                }
            })
            const validatePrivate = _stores.filter(item => !!item.prices && item.prices.length === 0 && item.action_type !== 'delete')
            if(validatePrivate.length > 0) {
                return toaster({
                    type: 'error',
                    message: 'Please fill in private price for at least 1 variant'
                })
            }
            const validateNumberPrice = _stores.filter(item => {
                let prices = []
                if(!!item.prices) {
                    prices = item.prices.filter(itemPrice => (!!itemPrice.price && itemPrice.price <= 0))
                }
                return item.action_type !== 'delete' && prices.length > 0
            })
            if(validateNumberPrice.length > 0) {
                return toaster({
                    type: 'error',
                    message: 'Invalid price, please enter value greater than 0'
                })
            }
            const payload = {
                product_id: catalogId,
                private_prices: _stores
            }
            try {
                setLoadingUpdate(true)
                const {success, message} = await updatePrivateBaseCost(payload)
                if (!success) throw new Error(message)
                _fetchListPrivate()
                toaster({
                    type: 'success',
                    message: 'Update price successfully!',
                })
                setLoadingUpdate(false)
            } catch (e) {
                setLoadingUpdate(false)
                toaster({
                    type: 'error',
                    message: e.message,
                })
            }
        }
    }

    const deleteStore = (storeID) => {
        // const _stores = [{
        //     action_type: 'delete',
        //     store_id: storeID,
        // }]
        // const payload = {
        //     product_id: catalogId,
        //     private_prices: _stores
        // }
        // try {
        //     setLoadingUpdate(true)
        //     const {success, message} = await updatePrivateBaseCost(payload)
        //     if (!success) throw new Error(message)
        //     _fetchListPrivate()
        //     toaster({
        //         type: 'success',
        //         message: 'Delete store successfully!',
        //     })
        //     setLoadingUpdate(false)
        // } catch (e) {
        //     setLoadingUpdate(false)
        //     toaster({
        //         type: 'error',
        //         message: e.message,
        //     })
        // }
        setIsDisableSave(false)
        const _listStore = listStores.map((itemStore, indexStore) => {
            if(itemStore.store._id === storeID) {
                return {
                    ...itemStore,
                    action_type: 'delete',
                }
            } else {
                return itemStore
            }
        })
        const _listStoreOrigin = originStores.map((itemStore, indexStore) => {
            if(itemStore.store._id === storeID) {
                return {
                    ...itemStore,
                    action_type: 'delete',
                }
            } else {
                return itemStore
            }
        })
        setListStores(_listStore)
        setOriginStores(_listStoreOrigin)
    }

    const _removeAddNew = () => {
        setIsAdd(false)
        const _storesAdd = listStores.filter(item => Object.keys(item.store).length > 0)
        const _storesAddOrigin = originStores.filter(item => Object.keys(item.store).length > 0)
        setListStores(_storesAdd)
        setOriginStores(_storesAddOrigin)
    }

    const _handleSelectStore = (e, index) => {
        if(!!e) {
            setIsDisableSave(false)
            const storeID = e.value
            const listIDStore = listStores.filter(item => item.action_type !== 'delete').map(item => item.store._id)
            if(listIDStore.includes(storeID)) {
                return toaster({
                    type: 'error',
                    message: 'Pricing for this store is already existed'
                })
            }
            const storeInfo = allStores.find(item => item._id === storeID)
            const _listStore = listStores.map((itemStore, indexStore) => {
                if(indexStore === index) {
                    return {
                        ...itemStore,
                        store: {
                            _id: storeID,
                            name: storeInfo !== undefined ? storeInfo.name : ''
                        }
                    }
                } else {
                    return itemStore
                }
            })
            const _listStoreOrigin = originStores.map((itemStore, indexStore) => {
                if(indexStore === index) {
                    return {
                        ...itemStore,
                        store: {
                            _id: storeID,
                            name: storeInfo !== undefined ? storeInfo.name : ''
                        }
                    }
                } else {
                    return itemStore
                }
            })

            console.log(_listStore, _listStoreOrigin)
            const uniqueStore = [...new Map(_listStore.map((m) => [m.store._id, m])).values()];
            setIsDisableSave(false)
            setIsAdd(false)
            setListStores(_listStore)
            setOriginStores(_listStoreOrigin)
        }
    }

    useEffect(() => {
        _fetchListStore()
        _fetchListPrivate()

    }, [])

    return (
        <div className="CatalogPrivateBaseCostContainer VariantBaseCostPricing">
            <BackNavigationButton to={`/a/catalog/${catalogId}`} text="Catalog" />
            <div className="d-flex justify-content-between align-items-center"><h2>Setting exclusive pricing</h2></div>
            <div className="SectionInner">
                <div className="TableContainer">
                    {loading ? (
                        <div className="d-flex justify-content-center">
                            <div className="text-success spinner-border Spinner" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="overflow-auto" style={{height: '558px'}}>
                                <table className="table">
                                    <thead className="thead-light">
                                    <tr>
                                        {!!attributes.length &&
                                            attributes.map((attribute) => {
                                                const _id = randomID()
                                                return (
                                                    <th key={`columnHeadingAttribute${_id}`} className="text-capitalize">
                                                        {attribute.name}
                                                    </th>
                                                )
                                            })}
                                        {
                                            !!listStores.length && listStores.map((itemStore, indexStore) => {
                                                const actionType = itemStore?.action_type || ''
                                                if(actionType !== 'delete' && itemStore.store?._id !== 'default_id') {
                                                    if(Object.keys(itemStore.store).length > 0) {
                                                        return <th key={`headingStore${indexStore}`}>
                                                            {itemStore.store.name || ''}
                                                            <img onClick={() => deleteStore(itemStore.store._id)} className="cursor-pointer" src={trashIcon} alt="trashIcon"/>
                                                        </th>
                                                    } else {
                                                        return <th key={`headingStore${indexStore}`}>
                                                            <Select
                                                                className="basic-single mr-1"
                                                                classNamePrefix="select"
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={(e) => _handleSelectStore(e, indexStore)}
                                                                options={allStores.map(item => {
                                                                    return {
                                                                        label: item.name,
                                                                        value: item._id
                                                                    }
                                                                })}
                                                            />
                                                            <img onClick={_removeAddNew} className="cursor-pointer ml-1" src={trashIcon} alt="trashIcon"/>
                                                        </th>
                                                    }
                                                } else {
                                                    return null
                                                }
                                            })
                                        }
                                        {!isAdd && <th className="action" style={{width: "105px"}}><a onClick={_addStore} href="">Add store</a></th>}

                                    </tr>
                                    {(listStores.length > 0 && valueAttributes.length > 0) && (
                                        <CatalogPrivateBaseCostFilter
                                            isAdd={isAdd}
                                            valueAttributes={valueAttributes}
                                            listStores={listStores}
                                            attributes={attributes}
                                            filter={filter}
                                            handleFilterVariants={_handleFilterVariants}
                                        />
                                    )}
                                    </thead>
                                    <tbody>
                                    {(!!listStores.length && !!listStores[0].prices.length) &&
                                        listStores[0].prices.map((variant, index) => {
                                            const {variant_id, attributes, tiers, status} = variant
                                            return (
                                                <tr key={`base_cost_${index}`}>
                                                    {!!attributes.length &&
                                                        attributes.map((attribute) => {
                                                            const _id = randomID()
                                                            return <td key={`columnAttributeValue${_id}`}>{attribute.value_text}</td>
                                                        })}
                                                    {listStores.map((itemStore, indexStore) => {
                                                        const priceVariant = itemStore.prices.find(item => item.variant_id === variant_id)
                                                        const actionType = itemStore?.action_type || ''
                                                        if(actionType !== 'delete' && itemStore.store?._id !== 'default_id') {
                                                            if(itemStore.store._id === undefined) {
                                                                return <td key={`priceValue${indexStore}`} className="pricingPrefix text-right"></td>
                                                            } else {
                                                                return <td key={`priceValue${indexStore}`} className="pricingPrefix">
                                                                    <span className="prefix">$</span>
                                                                    <Input placeholder="-" type="number" value={(priceVariant !== undefined && priceVariant.price !== null && priceVariant.price !== undefined) ? priceVariant.price : '-'} onChange={(e) => _handleChangeInput(variant_id, itemStore.store._id || '', e.target.value, 'update', e)}/>
                                                                </td>
                                                            }
                                                        } else {
                                                            return null
                                                        }
                                                    })}
                                                    {!isAdd && <td/>}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex align-items-center justify-content-end stickyBottom">
                                <Button disabled={loadingUpdate} onClick={_handleCancel} className="mr-3">Close</Button>
                                <Button disabled={isAdd || isDisableSave || loadingUpdate} onClick={_handleUpdate} color="primary">
                                    Save
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CatalogPrivateBaseCostContainer

