import React, {Component} from 'react'
import ServiceListModal from './ServiceListModal'
import PropTypes from 'prop-types'

class ServiceListPage extends Component {

    state={
        open: false,
    }

    _handleButtonClick = () => {
        this.setState({
            open:true,
        })
    }

    _onCloseModal = () => {
        this.setState({
            open:false,
        })
    }

    render() {
        const{open} = this.state
        const{storeDetails} = this.props
        return(
            <div className='ServiceListRecord'>
                <div className='list-group'>
                    <div className='list-group-item'>
                        <h5>Services List</h5>
                    </div>
                    <div className='RecordExist'>
                        <div className='list-group-item'>
                            <label>Press run services to get info about services</label>
                            <div>
                                <button className='btn btn-primary' onClick={this._handleButtonClick}>Run Services</button>
                                <ServiceListModal open={open} onClose={this._onCloseModal} storeDetails={storeDetails}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )

    }
}

ServiceListPage.propTypes = {
    storeDetails: PropTypes.object.isRequired
}

export default ServiceListPage
