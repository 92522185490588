import React, {Component} from 'react'
import PriceRulesRow from "./PriceRulesRow"

// import PropTypes from 'prop-types'

class PriceRulesTable extends Component {
    state = {
        numberRows: 0,
    }

    _priceData = []

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.priceTable.length !== this.props.priceTable.length) {
            this._priceData = this.props.priceTable

            this.setState({
                numberRows: this.props.priceTable.length,
            })
        }
    }

    _addNewRow = e => {
        e.preventDefault()

        if (this._priceData.length <= 0) {
            this._priceData = [{from: 0, cost: ""}]
        } else {
            this._priceData = [...this._priceData, {from: "", cost: ""}]
        }

        // this.props.onUpdateRateRules("price_based", this._priceData)

        this.setState({
            numberRows: this.state.numberRows + 1,
        })
    }

    _removeRow = (rowIndex) => {
        this._priceData = this._priceData.filter((data, index) => index !== rowIndex)

        this.props.onUpdateRateRules("price_based", this._priceData)

        this.setState({
            numberRows: this.state.numberRows - 1,
        })
    }

    _updatePriceData = (rowIndex, field, data) => {
        this._priceData[rowIndex][field] = data

        this.props.onUpdateRateRules("price_based", this._priceData)
    }

    _removeWeightData = () => {
        this._priceData = []

        this.setState({
            numberRows: 0,
        })

        this.props.onUpdateRateRules("price_based", this._priceData)
    }

    render() {
        const {numberRows} = this.state

        return (
            <div className="PriceRulesTable">
                <div className="position-absolute Actions">
                    {
                        numberRows === 0 ?
                            <span className="btn btn-primary cursor-pointer ml-3 btn-sm" onClick={this._addNewRow}>Add
                                rule</span>
                            :
                            <span className="btn border cursor-pointer btn-sm" onClick={this._removeWeightData}>Remove
                                rule</span>
                    }
                </div>

                {
                    numberRows > 0 &&
                    <div className="border-top mt-4 pt-4">
                        <table className="table table-borderless table-striped">
                            <thead className="thead-light">
                            <tr>
                                <th className="table-col-40">From</th>
                                <th className="table-col-40">Ship cost</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>

                            <tbody>
                            {
                                this._priceData.map((rowData, index) => {
                                    return <PriceRulesRow key={`priceRulesData_${index}`} rowIndex={index}
                                                          rowData={rowData}
                                                          onRemoveRow={this._removeRow}
                                                          onUpdatePriceData={this._updatePriceData}/>
                                })
                            }
                            </tbody>
                        </table>

                        <span className="text-primary ml-2 cursor-pointer" onClick={this._addNewRow}><u>+ Add row</u></span>
                    </div>
                }
            </div>
        )
    }
}

// PriceRulesTable.defaultProps = {}

// PriceRulesTable.propTypes = {}

export default PriceRulesTable
