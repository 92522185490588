import React from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';

const JsonInputCpn = ({style, ...rest }) => {
    return (
        <JSONInput
            colors={{ background: 'while', keys: 'rgb(136, 19, 145)', string: 'rgb(196, 26, 22)', number: 'orange', default: 'black' }}
            style={{
                body: { border: 'none', fontSize: '16px' },
                outerBox: { width: '100%' },
                container: { width: '100%' },
                ...style
            }}
            error={undefined}
            locale={locale}
            height='auto'
            {...rest}
        />
    );
}

export default JsonInputCpn;
