import React from 'react';

class KeyColumn extends React.Component { 
    
    render() {
        const {keyValue} = this.props
        
        return(
            <td>
                <span>{keyValue || ''}</span>
            </td>
        )
    }

}

export default KeyColumn;