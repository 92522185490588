import React from 'react'
import {DEFAULT_RULE_HEADER} from '../../constants'
import {UncontrolledTooltip} from 'reactstrap'


const ShipmentReportTableHead = (props) => {
    const {query, onChangeSort, platform} = props

    const _renderRuleKey = (key, value) => {
        if (key === 'pre_transit_5bds') return _renderSortHeadingPreTransit5Bds()
        if (key === 'pre_transit') return _renderSortHeadingPreTransit()
        if (key === 'delay_delivered_5bds') return 'Delay delivered (>= 5BDs)'
        if (key === 'delay_delivered_8bds') return 'Delay delivered (8-10BDs)'
        if (key === 'delay_delivered_10bds') return 'Delay delivered (>= 10BDs)'
        if (key === 'delay_delivered_13bds') return _renderSortHeading()
        if (key === 'delay_delivered_16bds') return 'Delay delivered (>= 16BDs)'
        if (key === 'pre_transit_not_update_3ds') {
            return platform === 'tiktok' ? 'Pre transit (not update >= 2BDs)' : 'Pre transit (not update > 3BDs)'
        }
        if (key === 'in_transit' && platform === 'tiktok') return 'In transit (not updated >= 3BDs)'
        if (key === 'at_post_office') return 'At post office'

        const suffix = `(${['in_transit'].includes(key) ? 'not updated ' : ''}>= ${value}BDs)`
        key = key.replace('_', ' ')
        const prefix = key.charAt(0).toUpperCase() + key.slice(1)
        return `${prefix} ${suffix}`
    }
    const isUsToUs = (rule) => { return rule.from === 'us' && rule.to === 'us' }

    const _sortColumn = (value, column = 'delay_delivered_13bds') => {
        let sort = 'desc'
        if (query[column] !== undefined && query[column].type === value) {
            const currentSort = query[column].sort
            sort = currentSort === 'asc' ? 'desc' : 'asc'
        }
        onChangeSort({
            type: value,
            sort
        }, column)
    }

    const _renderSortHeading = () => {
        return (
            <>
                <span
                    id="sortCount"
                >
                    <svg onClick={() => _sortColumn('number')} className="cursor-pointer" width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.24984 9.74984H18.7498C18.8982 9.74981 19.0431 9.7058 19.1664 9.62339C19.2897 9.54098 19.3858 9.42386 19.4426 9.28683C19.4994 9.14981 19.5142 8.99903 19.4853 8.85357C19.4564 8.7081 19.3849 8.57448 19.2801 8.46959L12.5301 1.71959C12.3894 1.57899 12.1987 1.5 11.9998 1.5C11.801 1.5 11.6102 1.57899 11.4696 1.71959L4.71959 8.46959C4.61473 8.57448 4.54333 8.7081 4.5144 8.85357C4.48548 8.99903 4.50033 9.14981 4.55708 9.28683C4.61383 9.42386 4.70994 9.54098 4.83325 9.62339C4.95655 9.7058 5.10153 9.74981 5.24984 9.74984ZM18.7498 14.2498H5.24984C5.10153 14.2499 4.95655 14.2939 4.83325 14.3763C4.70994 14.4587 4.61383 14.5758 4.55708 14.7128C4.50033 14.8499 4.48548 15.0006 4.5144 15.1461C4.54333 15.2916 4.61473 15.4252 4.71959 15.5301L11.4696 22.2801C11.6102 22.4207 11.801 22.4997 11.9998 22.4997C12.1987 22.4997 12.3894 22.4207 12.5301 22.2801L19.2801 15.5301C19.3849 15.4252 19.4564 15.2916 19.4853 15.1461C19.5142 15.0006 19.4994 14.8499 19.4426 14.7128C19.3858 14.5758 19.2897 14.4587 19.1664 14.3763C19.0431 14.2939 18.8982 14.2499 18.7498 14.2498Z" fill="#303238"/>
                    </svg>
                    <UncontrolledTooltip
                        placement="top"
                        target="sortCount"
                    >
                        Sort by number
                    </UncontrolledTooltip>
                </span>
                Delay delivered (>= 13BDs)
                <span
                    id="sortPercentage"
                >
                    <svg onClick={() => _sortColumn('rate')} className="cursor-pointer" width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.24984 9.74984H18.7498C18.8982 9.74981 19.0431 9.7058 19.1664 9.62339C19.2897 9.54098 19.3858 9.42386 19.4426 9.28683C19.4994 9.14981 19.5142 8.99903 19.4853 8.85357C19.4564 8.7081 19.3849 8.57448 19.2801 8.46959L12.5301 1.71959C12.3894 1.57899 12.1987 1.5 11.9998 1.5C11.801 1.5 11.6102 1.57899 11.4696 1.71959L4.71959 8.46959C4.61473 8.57448 4.54333 8.7081 4.5144 8.85357C4.48548 8.99903 4.50033 9.14981 4.55708 9.28683C4.61383 9.42386 4.70994 9.54098 4.83325 9.62339C4.95655 9.7058 5.10153 9.74981 5.24984 9.74984ZM18.7498 14.2498H5.24984C5.10153 14.2499 4.95655 14.2939 4.83325 14.3763C4.70994 14.4587 4.61383 14.5758 4.55708 14.7128C4.50033 14.8499 4.48548 15.0006 4.5144 15.1461C4.54333 15.2916 4.61473 15.4252 4.71959 15.5301L11.4696 22.2801C11.6102 22.4207 11.801 22.4997 11.9998 22.4997C12.1987 22.4997 12.3894 22.4207 12.5301 22.2801L19.2801 15.5301C19.3849 15.4252 19.4564 15.2916 19.4853 15.1461C19.5142 15.0006 19.4994 14.8499 19.4426 14.7128C19.3858 14.5758 19.2897 14.4587 19.1664 14.3763C19.0431 14.2939 18.8982 14.2499 18.7498 14.2498Z" fill="#303238"/>
                    </svg>
                    <UncontrolledTooltip
                        placement="top"
                        target="sortPercentage"
                    >
                        Sort by rate
                    </UncontrolledTooltip>
                </span>
            </>
        )
    }

    const _renderSortHeadingPreTransit = () => {
        return (
            <>
                <span
                    id="sortCount"
                >
                    <svg onClick={() => _sortColumn('number', 'pre_transit')} className="cursor-pointer" width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.24984 9.74984H18.7498C18.8982 9.74981 19.0431 9.7058 19.1664 9.62339C19.2897 9.54098 19.3858 9.42386 19.4426 9.28683C19.4994 9.14981 19.5142 8.99903 19.4853 8.85357C19.4564 8.7081 19.3849 8.57448 19.2801 8.46959L12.5301 1.71959C12.3894 1.57899 12.1987 1.5 11.9998 1.5C11.801 1.5 11.6102 1.57899 11.4696 1.71959L4.71959 8.46959C4.61473 8.57448 4.54333 8.7081 4.5144 8.85357C4.48548 8.99903 4.50033 9.14981 4.55708 9.28683C4.61383 9.42386 4.70994 9.54098 4.83325 9.62339C4.95655 9.7058 5.10153 9.74981 5.24984 9.74984ZM18.7498 14.2498H5.24984C5.10153 14.2499 4.95655 14.2939 4.83325 14.3763C4.70994 14.4587 4.61383 14.5758 4.55708 14.7128C4.50033 14.8499 4.48548 15.0006 4.5144 15.1461C4.54333 15.2916 4.61473 15.4252 4.71959 15.5301L11.4696 22.2801C11.6102 22.4207 11.801 22.4997 11.9998 22.4997C12.1987 22.4997 12.3894 22.4207 12.5301 22.2801L19.2801 15.5301C19.3849 15.4252 19.4564 15.2916 19.4853 15.1461C19.5142 15.0006 19.4994 14.8499 19.4426 14.7128C19.3858 14.5758 19.2897 14.4587 19.1664 14.3763C19.0431 14.2939 18.8982 14.2499 18.7498 14.2498Z" fill="#303238"/>
                    </svg>
                    <UncontrolledTooltip
                        placement="top"
                        target="sortCount"
                    >
                        Sort by number
                    </UncontrolledTooltip>
                </span>
                Pre transit (>= 7BDs)
                <span
                    id="sortPercentage"
                >
                    <svg onClick={() => _sortColumn('rate', 'pre_transit')} className="cursor-pointer" width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.24984 9.74984H18.7498C18.8982 9.74981 19.0431 9.7058 19.1664 9.62339C19.2897 9.54098 19.3858 9.42386 19.4426 9.28683C19.4994 9.14981 19.5142 8.99903 19.4853 8.85357C19.4564 8.7081 19.3849 8.57448 19.2801 8.46959L12.5301 1.71959C12.3894 1.57899 12.1987 1.5 11.9998 1.5C11.801 1.5 11.6102 1.57899 11.4696 1.71959L4.71959 8.46959C4.61473 8.57448 4.54333 8.7081 4.5144 8.85357C4.48548 8.99903 4.50033 9.14981 4.55708 9.28683C4.61383 9.42386 4.70994 9.54098 4.83325 9.62339C4.95655 9.7058 5.10153 9.74981 5.24984 9.74984ZM18.7498 14.2498H5.24984C5.10153 14.2499 4.95655 14.2939 4.83325 14.3763C4.70994 14.4587 4.61383 14.5758 4.55708 14.7128C4.50033 14.8499 4.48548 15.0006 4.5144 15.1461C4.54333 15.2916 4.61473 15.4252 4.71959 15.5301L11.4696 22.2801C11.6102 22.4207 11.801 22.4997 11.9998 22.4997C12.1987 22.4997 12.3894 22.4207 12.5301 22.2801L19.2801 15.5301C19.3849 15.4252 19.4564 15.2916 19.4853 15.1461C19.5142 15.0006 19.4994 14.8499 19.4426 14.7128C19.3858 14.5758 19.2897 14.4587 19.1664 14.3763C19.0431 14.2939 18.8982 14.2499 18.7498 14.2498Z" fill="#303238"/>
                    </svg>
                    <UncontrolledTooltip
                        placement="top"
                        target="sortPercentage"
                    >
                        Sort by rate
                    </UncontrolledTooltip>
                </span>
            </>
        )
    }

    const _renderSortHeadingPreTransit5Bds = () => {
        return (
            <>
                <span
                    id="sortCount"
                >
                    <svg onClick={() => _sortColumn('number', 'pre_transit_5bds')} className="cursor-pointer" width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.24984 9.74984H18.7498C18.8982 9.74981 19.0431 9.7058 19.1664 9.62339C19.2897 9.54098 19.3858 9.42386 19.4426 9.28683C19.4994 9.14981 19.5142 8.99903 19.4853 8.85357C19.4564 8.7081 19.3849 8.57448 19.2801 8.46959L12.5301 1.71959C12.3894 1.57899 12.1987 1.5 11.9998 1.5C11.801 1.5 11.6102 1.57899 11.4696 1.71959L4.71959 8.46959C4.61473 8.57448 4.54333 8.7081 4.5144 8.85357C4.48548 8.99903 4.50033 9.14981 4.55708 9.28683C4.61383 9.42386 4.70994 9.54098 4.83325 9.62339C4.95655 9.7058 5.10153 9.74981 5.24984 9.74984ZM18.7498 14.2498H5.24984C5.10153 14.2499 4.95655 14.2939 4.83325 14.3763C4.70994 14.4587 4.61383 14.5758 4.55708 14.7128C4.50033 14.8499 4.48548 15.0006 4.5144 15.1461C4.54333 15.2916 4.61473 15.4252 4.71959 15.5301L11.4696 22.2801C11.6102 22.4207 11.801 22.4997 11.9998 22.4997C12.1987 22.4997 12.3894 22.4207 12.5301 22.2801L19.2801 15.5301C19.3849 15.4252 19.4564 15.2916 19.4853 15.1461C19.5142 15.0006 19.4994 14.8499 19.4426 14.7128C19.3858 14.5758 19.2897 14.4587 19.1664 14.3763C19.0431 14.2939 18.8982 14.2499 18.7498 14.2498Z" fill="#303238"/>
                    </svg>
                    <UncontrolledTooltip
                        placement="top"
                        target="sortCount"
                    >
                        Sort by number
                    </UncontrolledTooltip>
                </span>
                Pre transit (5BDs -> 7BDs)
                <span
                    id="sortPercentage"
                >
                    <svg onClick={() => _sortColumn('rate', 'pre_transit_5bds')} className="cursor-pointer" width="12" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.24984 9.74984H18.7498C18.8982 9.74981 19.0431 9.7058 19.1664 9.62339C19.2897 9.54098 19.3858 9.42386 19.4426 9.28683C19.4994 9.14981 19.5142 8.99903 19.4853 8.85357C19.4564 8.7081 19.3849 8.57448 19.2801 8.46959L12.5301 1.71959C12.3894 1.57899 12.1987 1.5 11.9998 1.5C11.801 1.5 11.6102 1.57899 11.4696 1.71959L4.71959 8.46959C4.61473 8.57448 4.54333 8.7081 4.5144 8.85357C4.48548 8.99903 4.50033 9.14981 4.55708 9.28683C4.61383 9.42386 4.70994 9.54098 4.83325 9.62339C4.95655 9.7058 5.10153 9.74981 5.24984 9.74984ZM18.7498 14.2498H5.24984C5.10153 14.2499 4.95655 14.2939 4.83325 14.3763C4.70994 14.4587 4.61383 14.5758 4.55708 14.7128C4.50033 14.8499 4.48548 15.0006 4.5144 15.1461C4.54333 15.2916 4.61473 15.4252 4.71959 15.5301L11.4696 22.2801C11.6102 22.4207 11.801 22.4997 11.9998 22.4997C12.1987 22.4997 12.3894 22.4207 12.5301 22.2801L19.2801 15.5301C19.3849 15.4252 19.4564 15.2916 19.4853 15.1461C19.5142 15.0006 19.4994 14.8499 19.4426 14.7128C19.3858 14.5758 19.2897 14.4587 19.1664 14.3763C19.0431 14.2939 18.8982 14.2499 18.7498 14.2498Z" fill="#303238"/>
                    </svg>
                    <UncontrolledTooltip
                        placement="top"
                        target="sortPercentage"
                    >
                        Sort by rate
                    </UncontrolledTooltip>
                </span>
            </>
        )
    }



    return (
        <thead className="thead-light">
        <tr className="text-center font-weight-bold">
            <th rowSpan={2}>Store</th>
            <th rowSpan={2}>Total Order</th>
            {DEFAULT_RULE_HEADER.map((rule, index) => {
                const {columns = [], from, to} = rule
                let tempCols = columns
                if (platform === 'tiktok' && isUsToUs(rule)) {
                    tempCols = columns.filter(c => c.value !== 8)
                } else if(isUsToUs(rule)) {
                    tempCols = columns.filter(c => c.value !== 5)
                }
                return from === 'cn' ? (
                    <th key={`table_head_${index}`} className="text-uppercase" colSpan={tempCols.length || 0}>
                        {from}
                    </th>
                ) : (
                    <th key={`table_head_${index}`} className="text-uppercase" colSpan={tempCols.length || 0}>
                        {from} -&gt; {to}
                    </th>
                )
            })}
        </tr>
        <tr className="ShipmentRule text-center">
            {DEFAULT_RULE_HEADER.map((rule) => {
                const {columns = []} = rule
                let tempCols = columns
                if (platform === 'tiktok' && isUsToUs(rule)) {
                    tempCols = columns.filter(c => c.value !== 8)
                }else if(isUsToUs(rule)) {
                    tempCols = columns.filter(c => c.value !== 5)
                }
                return tempCols.map((col, index) => {
                    const {key = '', value = ''} = col
                    return <th key={`shipment_rule_${index}`}>{_renderRuleKey(key, value)}</th>
                })
            })}
        </tr>
        </thead>
    )
}

export default ShipmentReportTableHead

