import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ZoneColumn extends Component {
    render() {
        const {item} = this.props
        const {zone} = item

        return (
            <td className="ZoneColumn">
                {zone || '--'}
            </td>
        )
    }
}

ZoneColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default ZoneColumn
