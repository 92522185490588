import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import humanizeTime from '../../../../../helpers/time/humanizeTime'

class DetailColumn extends Component {
    render() {
        const {item = {}} = this.props
        const {scheduler_priority, namespace, db_name, bucket_image, bucket_product_image} = item
        const resources = item.resources || []
        const albItem = resources.find(item => item.kind === 'alb')
        const albName = !!albItem ? albItem.specs.address : ''
        const mongodbItem = resources.find(item => item.kind === 'mongodb')
        const mongodbName = mongodbItem !== undefined ? mongodbItem.name : ''

        const _validateDate = time => {
            const momentTime = moment(time)
            return humanizeTime(momentTime)
        }

        return (
            <td className='DetailColumn'>
                <div><label>Scheduler Priority</label>: {scheduler_priority || 0}</div>
                <div><label>Namespace</label>: {namespace || ''}</div>
                <div><label>Database Name</label>: {db_name || ''}</div>
                <div><label>Bucket Image</label>: {bucket_image || ''}</div>
                <div><label>Bucket Product Image</label>: {bucket_product_image || ''}</div>
                <div><label>Mongodb cluster</label>: {mongodbName || ''} {db_name || ''}</div>
                <div><label>Alb</label>: {albName}</div>
                <div><label>Last suspend at</label>: {_validateDate(item?.last_suspend_at)}</div>
            </td>
        )
    }
}

DetailColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default DetailColumn
