import React from 'react'

const ColorMappingTableRow = (props) => {
    const {colorAttribute, setColorAttribute} = props

    const _handleChangeColorCode = (event, index) => {
        const {value} = event.target
        setColorAttribute((prev) => {
            const updatedValues = [...prev.values]
            updatedValues[index] = {...updatedValues[index], code: value}
            return {...prev, values: updatedValues}
        })
    }

    return (
        <tbody>
            {!!colorAttribute.values.length &&
                colorAttribute.values.map((value, index) => {
                    const {text, code} = value
                    return (
                        <tr key={`Color_mapping_${index}`}>
                            <td>{text}</td>
                            <td>
                                <input
                                    value={code}
                                    className="form-control"
                                    onChange={(e) => _handleChangeColorCode(e, index)}
                                />
                            </td>
                        </tr>
                    )
                })}
        </tbody>
    )
}

export default ColorMappingTableRow

