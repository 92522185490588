import React, {Component} from 'react'
import {Button} from 'reactstrap'
import ExportModal from './ExportModal'

//import PropTypes from 'prop-types'

class ExportActionContainer extends Component {
    state = {
        isOpen: false,
    }

    _toggleModal = () => {
        this.setState(prevState => ({isOpen: !prevState.isOpen}))
    }

    _validateDateRange = () => {
        const {dateRange, storeID} = this.props
        if (!storeID) return false
        if (typeof dateRange !== 'object') return false

        return !(!dateRange.from || !dateRange.to)
    }

    render() {
        const canExport = this._validateDateRange()

        return (
            <div className='ExportReportAction'>
                <Button
                    color='primary'
                    disabled={!canExport}
                    onClick={this._toggleModal}
                >
                    Export
                </Button>

                <ExportModal {...this.props} isOpen={this.state.isOpen} onToggleModal={this._toggleModal}/>
            </div>
        )
    }
}

//ExportReportAction.defaultProps = {}

//ExportReportAction.propTypes = {}

export default ExportActionContainer
