import React from 'react'
import ModalConfig from '../ModalConfig'

class ActionColumn extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isShowModalEdit: false,
        }
    }

    _handleDeleteConfig = async () => {
        const result = window.confirm("Are you sure?")
        if (result) {
            const {itemConfig, updateGroupConfigs} = this.props
            const payload = {
                type: 'config_map',
                key: itemConfig.key,
            }
            await updateGroupConfigs(payload, 'delete')
            
        }
    }

    _handleToggleModal = () => {
        this.setState({
            isShowModalEdit: !this.state.isShowModalEdit,
        })
    }

    render() {
        const {itemConfig, updateGroupConfigs} = this.props
        return (
            <td>
                <ModalConfig
                    itemConfig={itemConfig}
                    updateGroupConfigs={updateGroupConfigs}
                    handleToggleModal={this._handleToggleModal}
                    isModalOpen={this.state.isShowModalEdit}
                    title="Update config group"
                    {...this.props}
                />
                <span>
                    <i onClick={this._handleToggleModal} className="fa fa-edit text-primary cursor-pointer" />
                    <i onClick={this._handleDeleteConfig} className='fas fa-times text-danger ml-3 cursor-pointer'></i>
                </span>
            </td>
        )
    }
}

export default ActionColumn


