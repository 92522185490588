import React, { Component } from "react";
import { getProductMockup } from "../../../services/api/ProductLinesServices";
import PFTable from "../../shared/PFTable";
import ProductMockupTableHead from "./table/ProductMockupTableHead";
import ProductMockupTableBody from "./table/ProductMockupTableBody";
import PFPagePagination from "../../shared/PFPagePagination";
import { getLocalData, setLocalData } from "../../../services/StorageServices";
import Link from "react-router-dom/Link";
import ButtonExportMockup from "./table/ButtonExportMockup";
import ButtonImportMockup from "./table/ButtonImportMockup";
import TableLoading from "../../shared/TableLoading";
import { debounce } from "lodash";

class ProductMockupPage extends Component {
    constructor(props) {
        super(props);
        const pageNumber = getLocalData("ProductMockupNumberPage") || 1;
        const pageLimit = getLocalData("ProductMockupLimitItem") || 10;
        this.state = {
            error: "",
            mockups: [],
            loading: false,
            page: pageNumber,
            pages: 1,
            total: 0,
            title: "",
            product_type: "",
            preview_v2: false,
            limit: pageLimit,
            productMockupExportIds: [],
            titleExport: [],
            isSelectAll: false,
        };
        this.timer = null;
    }

    componentDidMount() {
        this._getProductMockUp();
    }

    _getProductMockUp = async () => {
        const { page, title, limit, product_type, preview_v2 } = this.state;
        const params = { page, title, limit, product_type, preview_v2 };

        try {
            this.setState({
                loading: true,
            });
            const { success, data, message } = await getProductMockup(params);

            if (!success) throw new Error(message);

            const { mockups = [], page = 1, pages = 1, total = 0, limit = 10 } = data;

            this.setState({
                mockups: mockups,
                page: page,
                pages: pages,
                total: total,
                limit: limit,
                productMockupExportIds: [],
                titleExport: [],
                isSelectAll: false,
            });
            setLocalData("ProductMockupLimitItem", limit);
            setLocalData("ProductMockupNumberPage", page);
        } catch (e) {
            this.setState({
                error: e.message,
            });
        } finally {
            this.setState({
                loading: false,
            });
        }
    };

    _handleChangePage = (page) => {
        this.setState(
            {
                page: page,
            },
            () => this._getProductMockUp()
        );
    };

    _handleChangeInput = (e) => {
        e.preventDefault();
        const { name, value, checked } = e.target;
        this.setState({
            [name]: name === 'preview_v2' ? checked : value,
            page: 1,
        });

        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => this._getProductMockUp(), 650);
    };

    _onChangeSelectEntity = (e) => {
        const { value } = e.target;
        this.setState(
            {
                limit: value,
                page: 1,
            },
            () => this._getProductMockUp()
        );
    };

    _getProductMockupExportIds = (ids, title) => {
        this.setState({
            productMockupExportIds: ids,
            titleExport: title,
        });
    };

    _onChangeSelectAll = (checked) => {
        this.setState({
            isSelectAll: checked,
        });
        if (checked) {
            const id = this.state.mockups.map((item) => item._id);
            const title = this.state.mockups.map((item) => item.title);
            this.setState({
                productMockupExportIds: id,
                titleExport: title,
            });
        } else {
            this.setState({
                productMockupExportIds: [],
                titleExport: [],
            });
        }
    };
    _changeSelectAll = (checked) => {
        this.setState({
            isSelectAll: checked,
        });
    };
    _reloadMockups = () => {
        this._getProductMockUp();
    };
    render() {
        const {
            mockups,
            error,
            loading,
            page,
            pages,
            total,
            title,
            limit,
            productMockupExportIds,
            titleExport,
            isSelectAll,
            product_type,
            preview_v2,
        } = this.state;
        return (
            <div className="ProductMockupPage">
                <h2>Product Mockup</h2>
                <div className="ProductMockupInner SectionInner">
                    <div className="ProductMockupHeader d-flex align-items-center justify-content-between flex-wrap">
                        <div className="TableLength mr-3">
                            Show
                            <select className="ml-2" value={limit} onChange={this._onChangeSelectEntity}>
                                <option value="10">10 rows</option>
                                <option value="20">20 rows</option>
                                <option value="50">50 rows</option>
                                <option value="100">100 rows</option>
                            </select>
                        </div>
                        <div className="form-group SearchProductMockup d-flex  align-items-center">
                            <label className="m-0 mr-2">Title</label>
                            <input
                                type="text"
                                className="form-control"
                                id="SearchTitleProductMockup"
                                name="title"
                                placeholder="Enter search product mockup..."
                                value={title}
                                onChange={this._handleChangeInput}
                            />
                            <i className="fas fa-search SearchIcon" />
                        </div>
                        <div className=" d-flex SearchProductMockup ml-3 align-items-center">
                            <label className="m-0 LabelSearchMockup mr-2">Product Type</label>
                            <input
                                type="text"
                                className="form-control InputSearchProductMockup "
                                id="SearchProductTypeProductMockup"
                                name="product_type"
                                placeholder="Enter search product type..."
                                value={product_type}
                                onChange={this._handleChangeInput}
                            />
                            <i className="fas fa-search SearchIcon" />
                        </div>
                        <div className="ml-3 mb-1 d-flex align-items-center">
                            <div className="custom-control custom-checkbox">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="preview_v2"
                                    name="preview_v2"
                                    checked={preview_v2}
                                    onChange={this._handleChangeInput}
                                />
                                <label className="custom-control-label" htmlFor="preview_v2">
                                    Mockup v2
                                </label>
                            </div>
                        </div>
                        <div className="total font-weight-bold ml-auto">{total} items</div>
                        <ButtonImportMockup reloadMockups={this._reloadMockups} />
                        <ButtonExportMockup
                            onChangeSelectAll={this._onChangeSelectAll}
                            getProductMockupExportIds={this._getProductMockupExportIds}
                            titleExport={titleExport}
                            productMockupExportIds={productMockupExportIds}
                        />
                        <div className="addbtn ml-2">
                            <Link to="/a/product-mockup/new" className="btn btn-primary">
                                Create new product mockup
                            </Link>
                        </div>
                    </div>
                    <div className="ProductMockupTable table-responsive mt-4">
                        {
                            <PFTable source={mockups}>
                                {(table) => (
                                    <>
                                        <ProductMockupTableHead
                                            {...table}
                                            isSelectAll={isSelectAll}
                                            getProductMockupExportIds={this._getProductMockupExportIds}
                                            onChangeSelectAll={this._onChangeSelectAll}
                                        />
                                        <ProductMockupTableBody
                                            {...table}
                                            isSelectAll={isSelectAll}
                                            onGetProductMockUp={this._getProductMockUp}
                                            error={error}
                                            loading={loading}
                                            mockups={mockups}
                                            checked={productMockupExportIds}
                                            titleExport={titleExport}
                                            changeSelectAll={this._changeSelectAll}
                                            getProductMockupExportIds={this._getProductMockupExportIds}
                                        />
                                    </>
                                )}
                            </PFTable>
                        }
                        {loading ? (
                            <TableLoading columnQuantity={4} />
                        ) : (
                            <PFPagePagination page={page} pages={pages} onChangePage={this._handleChangePage} />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProductMockupPage;
