import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Index from './columns/IndexColumn'
import AccountName from './columns/AccountNameColumn'
import Email from './columns/EmailColumn'
import Status from './columns/StatusColumn'
import CreateDate from './columns/CreateDateColumn'
import Roles from './columns/RolesColumn'
import IsSeller from './columns/IsSellerColumn'
import Method from './columns/MethodColumn'

class RenderTableRow extends Component {
    render() {
        const {item, page, limit, index} = this.props
        const tableIndex = limit * (page - 1) + 1 + index
        return (
            <tr>
                <Index index={tableIndex} />
                <AccountName item={item} />
                <Email item={item} />
                <Method item={item} />
                <Roles item={item} />
                <IsSeller item={item} />
                <Status item={item} />
                <CreateDate item={item} />
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

export default RenderTableRow
