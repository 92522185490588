
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { bulkMarkWhitelist } from '../../../services/api/TrademarkSevices';
import { toaster } from '../../shared/PFToast'

const ConfirmWhitelistModal = (props) => {

    const [loading, setLoading] = useState(false)

    const { open, toggleWhitelist, ids, onAfterBulkAction } = props

    const onWhitelist = async () => {
        try {
            setLoading(true)
            const { success, message } = await bulkMarkWhitelist({ orderItemIds: ids, enable: true })
            if (success) {
                toaster({
                    type: 'success',
                    message: 'Whitelist product successfully!'
                })
                onAfterBulkAction()
                toggleWhitelist()
            } else {
                toaster({
                    type: 'error',
                    message
                })
            }
            setLoading(false)
        } catch (e) {
            console.error(e)
            setLoading(false)
        }
    }

    return (
        <Modal isOpen={open} toggle={toggleWhitelist} centered >
            <ModalHeader toggle={toggleWhitelist}>Whitelist product</ModalHeader>
            <ModalBody>
                <span>When you whitelist this product, system will be not validated it again. All
                    related orders which is not contain other violated product will be returned process.
                </span> <br />
                <span>
                    Please recheck product information below:
                </span>
                <ul className='list-dashed'>
                    <li>Product title</li>
                    <li>Link image</li>
                </ul>
                <span>Are you sure you want to whitelist this product?</span>
            </ModalBody>
            <ModalFooter>
                <Button color="default" onClick={toggleWhitelist} disabled={loading}>
                    Cancel
                </Button>
                <Button color="primary" disabled={loading} onClick={onWhitelist}>
                    {
                        loading && <i className="fas fa-circle-notch fa-spin mr-2"></i>
                    }
                    Whitelist
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmWhitelistModal