import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Editor} from '@tinymce/tinymce-react'
import {
    TinyMceEditorToolbar,
    TinyMceEditorPlugins,
    APIKeyTinymceEditor,
    TinymceScriptSrc,
} from '../../../../helpers/TinyMceEditor'
import DOMPurify from 'dompurify'

const InputContent = (props) => {
    const {content, error, setEditorRefs, disabled = false, changeInput} = props
    const [contentFormatted, setContentFormatted] = useState('')
    const setRefs = (editor) => {
        if (!setEditorRefs) return
        setEditorRefs('editorContent', editor)
    }
    useEffect(() => {
        const cleanContent = DOMPurify.sanitize(content)
        const contentFormat = cleanContent.replace(/&nbsp;/g, ' ').trim()
        setContentFormatted(contentFormat)
    }, [content])

    return (
        <div className={`form-group ProductLineDescription ${error && !contentFormatted && 'is-invalid'}`}>
            <label htmlFor="createNotificationContent" className="font-weight-500">
                Content
            </label>
            <Editor
                disabled={disabled}
                onChange={changeInput}
                onInit={(evt, editor) => {
                    setRefs(editor)
                }}
                // initialValue={content}
                apiKey={APIKeyTinymceEditor}
                tinymceScriptSrc={TinymceScriptSrc}
                init={{
                    height: '400',
                    skin: 'small',
                    icons: 'small',
                    toolbar: TinyMceEditorToolbar,
                    plugins: TinyMceEditorPlugins + ' paste',
                    // paste_as_text: true,
                    // paste_postprocess: (plugin, args) => {
                    //     const content = args.node.innerHTML;
                    //     const cleanContent = DOMPurify.sanitize(content, {
                    //         ALLOWED_ATTR: ['src', 'alt', 'title']
                    //     });
                    //     args.node.innerHTML = cleanContent;
                    // }
                }}
            />

            {error && !contentFormatted && <div className="text-danger font-italic fs-14">{error}</div>}
        </div>
    )
}

InputContent.propTypes = {
    content: PropTypes.string,
    error: PropTypes.string,
}

export default InputContent

