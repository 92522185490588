import React, {Component} from 'react'
import SettingPage from './SettingPage'
import {isSuperAdmin} from '../../../services/AuthServices'

class SettingContainer extends Component {
    render() {

        // const check = isSuperAdmin()
        // if (!check) return null

        return (
            <div className="SettingContainer">
                <SettingPage
                    {...this.props}
                />
            </div>
        )
    }
}

export default SettingContainer

