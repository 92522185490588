import React, {useCallback} from 'react'
import {useRef} from 'react'
import {useState} from 'react'
import PFTableV2 from '../../shared/components/table/v2'
import ReturnPage from '../../shared/ReturnPage'
import {exportListRecall, getListCashbackRecall} from '../../../services/api/BillingServices'
import {toaster} from '../../shared/PFToast'
import {useEffect} from 'react'
import humanizeTime from '../../../helpers/time/humanizeTime'
import StatusStyle from '../../shared/StatusStyle'
import {Link} from 'react-router-dom'
import {debounce} from 'lodash'
import BulkModalConfirm from './BulkModalConfirm'
import {downloadFile} from '../../../helpers/common/downloadFile'
import {deleteEmptyObjectkeys, trimObjectValues} from '../../../helpers/common/cleanObject'
import {DateRangePicker} from 'react-dates'
import moment from 'moment'
import PFPagePagination from '../../shared/PFPagePagination'

const CashbackRecallContainer = () => {
    const tableRef = useRef(null)
    const [focusedInput, setFocusedInput] = useState(null)
    const [loading, setLoading] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)
    const [recalls, setRecalls] = useState([])
    const [total, setTotal] = useState(0)
    const [pages, setPages] = useState(1)
    const [storeFilter, setStoreFilter] = useState('store_slug')
    const [query, setQuery] = useState({
        page: 1,
        limit: 10,
        order_number: '',
        store_slug: '',
        store_domain: '',
        product_type: '',
        status: '',
        refund_from: null,
        refund_to: null,
    })
    const [bulkRecall, setBulkRecall] = useState({
        open: false,
        ids: [],
        bulkType: 'recalled',
    })

    const columns = [
        {
            title: 'Order Number',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
            render: (data) => {
                return <span>{data.order_number}</span>
            },
        },
        {
            title: 'Rule name',
            dataIndex: 'ruleName',
            key: 'ruleName',
            render: (data) => {
                return <span>{data.rule_name || '-'}</span>
            },
        },
        {
            title: 'Store',
            dataIndex: 'store',
            key: 'store',
            render: (data) => {
                return (
                    <div>
                        <p className="mb-1">{data.store.slug}</p>
                        <Link to={`/a/stores/${data.store._id}`}>{data.store.domain}</Link>
                    </div>
                )
            },
        },
        {
            title: 'Product Type',
            dataIndex: 'productType',
            key: 'productType',
            render: (data) => {
                return <span>{data.product_type}</span>
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (data) => {
                return <div className="text-center">{data.quantity}</div>
            },
        },
        {
            title: 'Cashback',
            dataIndex: 'cashback',
            key: 'cashback',
            render: (data) => {
                return <div className="text-center">${data.cashback || 0}</div>
            },
        },
        {
            title: 'Cashback At',
            dataIndex: 'cashbackAt',
            key: 'cashbackAt',
            render: (data) => {
                return <span>{humanizeTime(data.cashback_at)}</span>
            },
        },
        {
            title: 'Refund At',
            dataIndex: 'refundAt',
            key: 'refundAt',
            render: (data) => {
                return <span>{humanizeTime(data.refund_at)}</span>
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (data) => {
                return <StatusStyle status={data.status} />
            },
        },
    ]

    const selections = [
        {
            key: 'bulk_recall',
            text: 'Recalled',
            onSelect: async (allPagesSelected, selectedIDs) => {
                toggleModal(selectedIDs, 'recalled')
            },
        },
        {
            key: 'bulk_no_recall',
            text: 'No recall',
            onSelect: async (allPagesSelected, selectedIDs) => {
                toggleModal(selectedIDs, 'no_recall')
            },
        },
    ]

    const initQueryParams = (query) => {
        const params = {
            ...trimObjectValues(query),
            refund_from: query.refund_from ? moment(query.refund_from).format('DD/MM/YYYY') : '',
            refund_to: query.refund_to ? moment(query.refund_to).format('DD/MM/YYYY') : '',
        }
        return deleteEmptyObjectkeys(params)
    }

    const fetchRecalls = useCallback(async () => {
        try {
            setLoading(true)
            const params = initQueryParams(query)
            const {success, data, message} = await getListCashbackRecall(params)
            setLoading(false)
            if (!success) {
                throw new Error(message)
            }
            setPages(data.pages)
            setTotal(data.total)
            setRecalls(data.cashback_recalls)
        } catch (error) {
            setLoading(false)
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }, [query])

    const debouncedSetQuery = debounce((name, value) => {
        setQuery((prev) => ({
            ...prev,
            page: 1,
            [name]: value ? value.trim() : '',
        }))
    }, 500)

    useEffect(() => {
        fetchRecalls()
    }, [fetchRecalls])

    const onChangeSearch = (e) => {
        const {name, value} = e.target
        debouncedSetQuery(name, value)
    }

    const handlePageChange = (page) => {
        setQuery({
            ...query,
            page,
        })
    }

    const onChangeSelect = (e) => {
        const {name, value} = e.target
        setQuery({
            ...query,
            page: 1,
            [name]: value,
        })
    }

    const onExportRecall = async () => {
        try {
            setLoadingExport(true)
            const params = initQueryParams(query)
            const data = await exportListRecall(params)
            if (!data) {
                throw new Error('Something wrong, please try again!')
            }
            setLoadingExport(false)
            downloadFile('recalls.csv', data, {type: 'text/csv'})
        } catch (error) {
            setLoadingExport(false)
            toaster({
                type: 'error',
                message: error.message,
            })
        }
    }

    const onChangeFilterStore = (event) => {
        const {value} = event.target
        setStoreFilter(value)
        setQuery({
            ...query,
            store_slug: '',
            store_domain: '',
        })
    }

    const onAfterBulkAction = () => {
        tableRef.current._clearSelected()
        setQuery((prev) => ({
            ...prev,
            page: 1,
        }))
    }

    const onFocusChange = (focusedInput) => setFocusedInput(focusedInput)

    const isOutsideRangeDate = (date) => {
        const tomorrow = moment().add(1, 'day')
        const startDate = moment('2018-11-05', 'YYYY-MM-DD')

        if (startDate.isAfter(date)) return true

        return !date.isBefore(tomorrow) || date.isSame(tomorrow, 'day')
    }

    const handleTimeRangeChange = ({startDate, endDate}) => {
        return setQuery((prev) => ({
            ...prev,
            refund_from: startDate,
            refund_to: endDate,
        }))
    }

    const toggleModal = (ids = [], bulkType) => setBulkRecall((prev) => ({ids, bulkType, open: !prev.open}))

    return (
        <div className="CashbackRecallContainer">
            <ReturnPage url="/a/cashback-rule" title="Cashback Rule" />
            <div className="CashbackRecall">
                <div className="d-flex justify-content-between align-items-center">
                    <h2>Recall list</h2>
                    <button className="btn btn-default" onClick={onExportRecall} disabled={loadingExport}>
                        {loadingExport && <i className="fas fa-circle-notch fa-spin mr-2"></i>} Export
                    </button>
                </div>
            </div>
            <div className="SectionInner">
                <div className="TableFilter d-flex flex-wrap mb-3">
                    <div className="TableLength mr-3">
                        Show
                        <select className="ml-2" name="limit" value={query.limit} onChange={onChangeSelect}>
                            <option value="10">10 rows</option>
                            <option value="20">20 rows</option>
                            <option value="50">50 rows</option>
                            <option value="100">100 rows</option>
                        </select>
                    </div>
                    <div className="form-group SearchInput LongSearch d-flex align-items-center mr-3">
                        <label className="m-0 mr-2" style={{minWidth: '110px'}}>
                            Order number
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="order_number"
                            name="order_number"
                            placeholder="Enter search order number..."
                            onChange={onChangeSearch}
                        />
                    </div>
                    <div className="form-group SearchInput d-flex align-items-center mr-3">
                        <label className="m-0 mr-2">Store</label>
                        <div className="input-group-prepend">
                            <select
                                className="form-control"
                                style={{minWidth: '120px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                value={storeFilter}
                                onChange={onChangeFilterStore}
                            >
                                <option value="store_slug">Store slug</option>
                                <option value="store_domain">Domain</option>
                            </select>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            name={storeFilter}
                            value={query[storeFilter]}
                            style={{minWidth: '120px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                            placeholder="Enter search store..."
                            onChange={onChangeSearch}
                        />
                    </div>
                    <div className="form-group SearchInput d-flex align-items-center mr-3" style={{minWidth: '360px'}}>
                        <label className="m-0 mr-2" style={{minWidth: '100px'}}>
                            Product type
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="product_type"
                            name="product_type"
                            placeholder="Enter search product type..."
                            onChange={onChangeSearch}
                        />
                    </div>
                    <div className="form-group d-flex align-items-center mr-3">
                        <label className="m-0 mr-2">Refund at</label>
                        <DateRangePicker
                            small
                            showClearDates
                            enableOutsideDays
                            reopenPickerOnClearDates
                            minimumNights={0}
                            numberOfMonths={2}
                            isOutsideRange={isOutsideRangeDate}
                            startDate={query.refund_from}
                            startDateId="refund_from"
                            endDate={query.refund_to}
                            endDateId="refund_to"
                            onDatesChange={handleTimeRangeChange}
                            focusedInput={focusedInput}
                            onFocusChange={onFocusChange}
                            displayFormat={'DD/MM/YYYY'}
                        />
                    </div>
                    <div className="TableLength">
                        Status
                        <select className="ml-2" name="status" value={query.status} onChange={onChangeSelect}>
                            <option value="">All</option>
                            <option value="recalled">Recalled</option>
                            <option value="no_recall">No recall</option>
                            <option value="pending">Pending</option>
                        </select>
                    </div>
                </div>
                <div className="table-responsive">
                    <PFTableV2
                        totalItems={total}
                        data={recalls}
                        columns={columns}
                        selections={selections}
                        loading={loading}
                        ref={tableRef}
                        allowSelectAllPage={false}
                        hasCheckbox={true}
                    />
                    <PFPagePagination page={query.page} pages={pages} onChangePage={handlePageChange} />
                    <BulkModalConfirm
                        ids={bulkRecall.ids}
                        open={bulkRecall.open}
                        bulkType={bulkRecall.bulkType}
                        toggleModal={toggleModal}
                        onAfterBulkAction={onAfterBulkAction}
                    />
                </div>
            </div>
        </div>
    )
}

export default CashbackRecallContainer
