import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ClusterMongodbURI extends Component {
    state = {
        mongodbURI: this.props.mongodbURI,
    }

    _handleChangeInput = e => {
        const {name, value} = e.target
        const {onUpdateCluster} = this.props

        this.setState({
            mongodbURI: value,
        }, onUpdateCluster(name, value))
    }

    render() {
        const {mongodbURI} = this.state

        return (
            <div className="ClusterMongodbURI form-group">
                <label htmlFor="clusterMongodbURI">Mongodb URI</label>
                <input type="text" className="form-control" id="clusterMongodbURI" name="mongodb_uri" value={mongodbURI}
                       onChange={this._handleChangeInput}/>
            </div>
        )
    }
}

ClusterMongodbURI.propTypes = {
    mongodbURI: PropTypes.string.isRequired,
    onUpdateCluster: PropTypes.func.isRequired,
}

export default ClusterMongodbURI
