import React, {Component} from 'react'
import PropTypes from 'prop-types'
import IndexColumn from './columns/IndexColumn'
import TitleColumn from './columns/TitleColumn'
import ActiveColumn from './columns/ActiveColumn'
import DeleteColumn from './columns/DeleteColumn'
import CreatedColumn from './columns/CreatedColumn'
import ActionColumn from './columns/ActionColumn'

class RenderTableRow extends Component {
    render() {
        const {item, page, limit, index} = this.props
        const tableIndex = limit * (page - 1) + 1 + index

        return (
            <tr>
                <IndexColumn index={tableIndex}/>
                <TitleColumn item={item}/>
                <ActiveColumn item={item}/>
                <DeleteColumn item={item}/>
                <CreatedColumn item={item}/>
                <ActionColumn item={item}/>
            </tr>
        )
    }
}

RenderTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
}

export default RenderTableRow
