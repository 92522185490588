import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ClusterSettingsRow from "./ClusterSettingsRow"

class ClusterSettingsBody extends Component {
    render() {
        const {settings, onFetchClusterSettings, clusterId} = this.props

        return (
            <tbody className="ClusterSettingsBody">
            {
                settings.map((setting, index) => {
                    return <ClusterSettingsRow key={setting._id} setting={setting} index={index + 1}
                                               onFetchClusterSettings={onFetchClusterSettings} clusterId={clusterId}/>
                })
            }
            </tbody>
        )
    }
}

ClusterSettingsBody.propTypes = {
    settings: PropTypes.array.isRequired,
    onFetchClusterSettings: PropTypes.func.isRequired,
    clusterId: PropTypes.string.isRequired,
}

export default ClusterSettingsBody
