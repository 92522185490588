import React, {useState} from 'react'
import {getListStores} from '../../../services/api/StoreServices'
import AsyncSelect from 'react-select/async'
import PropTypes from 'prop-types'
import {confirmModal} from '../../shared/PFConfirmModalV2'
import {toaster} from '../../shared/PFToast'
import {importProductStoreToStores} from '../../../services/api/StoreServices'
import ModalStoreTransferJob from './store-transfer-job/ModalStoreTransferJob'
import ModalStoreTransferLog from './store-transfer-log/ModalStoreTransferLog'

const StoreImportProducts = (props) => {
    const {storeDetails} = props
    const {_id = '', slug = ''} = Object.assign({}, storeDetails)
    const [filter, setFilter] = useState('slug')
    const [slugSelected, setSlugSelected] = useState('')
    const [storeIDSelect, setStoreIDSelect] = useState('')
    const [error, setError] = useState('')
    const [modalStoreTransferJob, setModalStoreTransferJob] = useState(false);
    const [modalStoreTransferLog, setModalStoreTransferLog] = useState(false);

    let _timer = null

    const _fetchListStores = async (storeName) => {
        try {
            const params = {
                limit: 10,
                [filter]: storeName
            }
            const {data, success, message} = await getListStores(params)

            if (!success) throw new Error(message)

            const {stores = []} = Object.assign({}, data)
            const formatStores = Array.isArray(stores) ? stores.filter(item => (item._id !== _id && item.mode !== 'marketplace')) : []
            return formatStores.map(store => ({
                value: store._id,
                label: store.slug + '--' + store.domain,
                slug: store.slug
            }))

        } catch (e) {
            setError(e.message)
        }
    }


    const _onChangeSelect = (e) => {
        const {value} = e.target
        setFilter(value)
    }

    const _handleInputChange = options => {
        const {value = '', slug = ''} = Object.assign({}, options)
        setSlugSelected(slug)
        setStoreIDSelect(value)
    }

    const _loadOptions = (inputValue) => {
        if (_timer) clearTimeout(_timer)

        return new Promise(resolve => {
            _timer = setTimeout(() => {
                resolve(_fetchListStores(inputValue))
            }, 500)
        })
    }

    const _handleClickImport = e => {
        e.preventDefault()
        confirmModal({
            confirmText: 'Import',
            cancelText: 'Cancel',
            confirmColor: 'primary',
            title: 'Import products',
            message: <p>All products will be imported from <b>{slugSelected}</b> into <b>{slug}</b></p>,
            onOk: _handleImport
        })
    }

    const _handleImport = async () => {
        try {
            const {success, message} = await importProductStoreToStores(storeIDSelect, _id)

            if (!success) throw new Error(message)

            toaster({message: 'Imported product successfully!', type: 'success', duration: 5000})

        } catch (e) {
            toaster({message: e.message, type: 'error', duration: 5000})
        }
    }

    const toggleStoreTransferJob = () => setModalStoreTransferJob(!modalStoreTransferJob);
    const toggleStoreTransferLog = () => setModalStoreTransferLog(!modalStoreTransferLog);
    return (
        <div className="StoreImportProducts">
            {/* <StoreTransferJobContainer /> */}
            <ModalStoreTransferJob storeId={storeDetails._id} modalStoreTransferJob={modalStoreTransferJob} toggleStoreTransferJob={toggleStoreTransferJob} />
            <ModalStoreTransferLog storeId={storeDetails._id} modalStoreTransferLog={modalStoreTransferLog} toggleStoreTransferLog={toggleStoreTransferLog} /> 
            <div className="card">
                <div className="card-header font-weight-bold d-flex justify-content-between">
                    <span className="mt-auto mb-auto">Import products</span>
                    <div className='btn-view-detail'>
                        <button type="button" className="btn btn-sm btn-primary" onClick={toggleStoreTransferJob}>Store transfer jobs</button>
                        <button type="button" className="btn btn-sm btn-primary ml-2" onClick={toggleStoreTransferLog}>Store transfer logs</button>
                    </div>                
                </div>
                <div className="card-body">
                    <div className="FormStore">
                        <label>Select a store</label>
                        {
                            error ?
                                <p className="text-danger">{error}</p>
                                :
                                <div className="SelectInputStore d-flex align-items-center border rounded">
                                    <select
                                        value={filter}
                                        className="border-0"
                                        onChange={_onChangeSelect}
                                    >
                                        <option value="slug">Store ID</option>
                                        <option value="domain">Domain</option>
                                    </select>
                                    <AsyncSelect
                                        noOptionsMessage={() => null}
                                        loadOptions={_loadOptions}
                                        defaultOptions
                                        onChange={_handleInputChange}
                                    />
                                </div>

                        }
                    </div>
                    {
                        !error &&
                        <div className="ButtonImportProducts text-right mt-3">
                            <button disabled={!slugSelected || !storeIDSelect} className="btn btn-sm btn-primary"
                                    onClick={_handleClickImport}>Import
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

StoreImportProducts.propTypes = {
    storeDetails: PropTypes.object.isRequired
}

export default StoreImportProducts
