import React from 'react'
import ReactDOM from 'react-dom'
import './statics/assets/css/libs/themify-icons.css'
import './scss/app.scss'
import App from './app/App'
import Router from 'react-router-dom/Router'
import getHistory from './store/getHistory'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'


Sentry.init({
    dsn: 'https://e9bef0a8ef9a48acd59b8a2e23af2c75@sentry-plf.mymerchize.com/11',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
})

ReactDOM.render(
    (
        <Router history={getHistory()}>
            <App />
        </Router>
    ),
    document.getElementById('root')
)
