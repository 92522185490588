import React, { Component } from 'react'
import { Spinner } from 'react-bootstrap'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'
import ModalFooter from 'reactstrap/lib/ModalFooter'
import { resetPipeline } from '../../../../services/api/DeployService'
import { toaster } from '../../../shared/PFToast'

class ResetModal extends Component {
    state = {
        loading: false,
    }
    _reset = async () => {
        try {
            const { item, toggleReset } = this.props
            this.setState({ loading: true })
            const { success, message } = await resetPipeline(item.job)
            if (success) {
                toaster({
                    type: 'success',
                    message: 'Reset pipeline successfully.'
                })
                toggleReset()
            } else {
                toaster({
                    type: 'error',
                    message
                })
            }
            this.setState({ loading: false })
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const { loading } = this.state
        const { open, toggleReset } = this.props
        return (
            <Modal size='md' centered isOpen={open} toggle={toggleReset} className="ResetModal">
                <ModalHeader toggle={this._onToggle}>Reset confirmation</ModalHeader>
                <ModalBody>
                    Are you sure you want to reset the pipelines?
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="default" onClick={toggleReset} disabled={loading}>Cancel</Button>
                    <Button type="button" color="primary" onClick={this._reset} disabled={loading}>
                        {
                            loading && <i className='fas fa-circle-notch fa-spin mr-1'></i>
                        }
                        Reset
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ResetModal
