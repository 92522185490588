import React, {Component} from 'react'
// import PropTypes from 'prop-types'
import ReactCountryFlag from 'react-country-flag'

class SelectedCountryItem extends Component {
    _handleClickRemove = () => {
        const {country, onRemoveSelectedCountry} = this.props

        onRemoveSelectedCountry(country.code)
    }

    render() {
        const {country} = this.props

        return (
            <li className="SelectedCountryItem d-flex justify-content-between align-items-center p-2">
                <div className="Country">
                    <ReactCountryFlag svg code={country.code}/>
                    <span className="ml-3">{country.name}</span>
                </div>

                <i className="ti-close RemoveCountryIcon cursor-pointer" onClick={this._handleClickRemove}/>
            </li>
        )
    }
}

// SelectedCountryItem.defaultProps = {}

// SelectedCountryItem.propTypes = {}

export default SelectedCountryItem
