import React from 'react'

const TableErrors = (props) => {
    const {totalError, totalSuccess, data} = props.actionResult
    if (totalError === 0) return null
    const errors = Array.isArray(data) ? data.filter(item => item.success === false) : []

    return (
        <div className='TableErrors'>
            <div className='mb-3'>Operation did not complete successfully. Please see the details bellow.</div>

            <div className='fs-14'>
                <div className='mb-2'>
                    <span className='font-weight-bold'><span className='text-success'>Success:</span> {totalSuccess}</span>
                    <span className='ml-3 font-weight-bold'><span className='text-danger'>Error:</span> {totalError}</span>
                </div>
                <div className='table-responsive'>
                    <table className='table table-striped'>
                        <thead>
                        <th>Title</th>
                        <th>Error</th>
                        </thead>
                        <tbody>
                        {
                            errors.map((error, index) => (
                                <tr key={index}>
                                    <td>
                                        <a
                                            href={`/a/product-approval/products?ticketID=${error._id}&productsID=${error.revision.product_id}&storeID=${error.store}&revisionID=${error.revision._id}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {error.revision.title}
                                        </a>
                                    </td>
                                    <td>{error.message}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TableErrors
