import React, {Component} from 'react'
import PropTypes from 'prop-types'
import StoreDetail from "./StoreDetail"
import parseSearchQuery from "../../../helpers/routing/parseSearchQuery"
import StoreDeployTab from "./tabs/StoreDeployTab"
import StoreStatusTab from "./tabs/StoreStatusTab"
import StoreSuspendTab from "./tabs/StoreSuspendTab"

class StoreDeployTabs extends Component {
    _getCurrentTab = () => {
        const {tab} = parseSearchQuery()

        const _tabs = ['deploy', 'suspend', 'status']
        if (_tabs.includes(tab)) {
            return tab
        }

        return 'deploy'
    }

    _renderTab = () => {
        const {store} = this.props
        const currentTab = this._getCurrentTab()

        const _maps = {
            deploy: <StoreDeployTab store={store}/>,
            suspend: <StoreSuspendTab store={store}/>,
            status: <StoreStatusTab store={store}/>
        }

        return _maps[currentTab] || null
    }

    render() {
        const {store} = this.props
        if (!store || !Object.keys(store).length) return null

        const currentTab = this._renderTab()

        return (
            <div className="StoreDeployTabs">
                <StoreDetail store={store}/>

                <div className="Tabs">
                    {currentTab}
                </div>
            </div>
        )
    }
}

StoreDeployTabs.propTypes = {
    store: PropTypes.object.isRequired,
}

export default StoreDeployTabs
