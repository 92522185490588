import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {updateSchedularPriority} from '../../../services/api/DeployService'

class SchedulerPriorityModal extends Component {
    state = {
        schedulerPriority: this.props.schedulerPriority,
        err: '',
    }

    _handleChangeDomain = async (e) => {
        e.preventDefault()
        this.setState({
            err: '',
        })
        try {
            const {storeDetails, changeStoreDetail, toggle} = this.props
            const {_id: storeId} = storeDetails
            const {schedulerPriority} = this.state
            const payload = {
                scheduler_priority: Number(schedulerPriority)
            }
            const {success, message} = await updateSchedularPriority(storeId, payload)
            if (!success) {
                return this.setState({
                    err: message,
                })
            }

            changeStoreDetail({
                ...storeDetails,
                scheduler_priority: schedulerPriority,
            })
            toggle()
        } catch (e) {
            this.setState({
                err: e.message || e,
            })
        }
    }

    _handleTitleChange = (event) => {
        const value = event.target.value
        this.setState({
            schedulerPriority: value,
        })
    }

    render() {
        const {open, toggle} = this.props
        const {schedulerPriority} = this.state
        return (
            <Modal isOpen={open} toggle={toggle} className="SchedulerPriorityModal">
                <ModalHeader toggle={toggle}>Schedular Priority</ModalHeader>
                <ModalBody>
                    <input
                        type="number"
                        className="form-control"
                        value={schedulerPriority || ''}
                        onChange={this._handleTitleChange}
                        required
                    />
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-default" onClick={toggle}>
                        Cancel
                    </button>
                    <button className="btn btn-primary" onClick={this._handleChangeDomain}>
                        Save
                    </button>
                </ModalFooter>
            </Modal>
        )
    }
}

SchedulerPriorityModal.propTypes = {
    open: PropTypes.bool.isRequired,
    schedulerPriority: PropTypes.string.isRequired,
    toggle: PropTypes.func.isRequired,
    changeStoreDetail: PropTypes.func.isRequired,
    storeDetails: PropTypes.object.isRequired,
}

export default SchedulerPriorityModal

