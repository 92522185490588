import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class StoreFeatureTag extends Component {
    state = {
        loading: false,
    }

    _handleClick = async e => {
        e.preventDefault()

        this.setState({
            loading: true,
        })

        const {tag, onRemoveFeature} = this.props

        await onRemoveFeature(tag)

        this.setState({
            loading: false,
        })
    }

    render() {
        return (
            <div className={classNames('StoreFeatureTag mr-2 mb-2', {'Disabled': this.state.loading})}>
                <div className='badge badge-secondary p-2'>
                    <span>{this.props.tag}</span>
                    <span className='Icon cursor-pointer ml-2' onClick={this._handleClick}><i className='fas fa-times'/></span>
                </div>
            </div>
        )
    }
}

StoreFeatureTag.propTypes = {
    tag: PropTypes.string.isRequired,
    onRemoveFeature: PropTypes.func.isRequired,
}

export default StoreFeatureTag
