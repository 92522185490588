import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import CreatableSelect from 'react-select/creatable'

const components = {
    DropdownIndicator: null,
}

const createOptions = (options) => {
    return options.map((option) => ({
        label: option.trim(),
        value: option.trim(),
    }))
}

const PFInputMulti = (props) => {
    const {defaultValue, splitSpace = false, isClearable, onChange, id, name, placeholder, errors, className} = props

    const [value, setValue] = useState([])
    const [inputValue, setInputValue] = useState('')

    const _handleParseProductTypes = (value) => {
        let columnArray = []
        if (splitSpace) {
            columnArray = value.split(/(\s+|,)/).filter((i) => i.trim().replace(/\s+/g, '').replace(/,/g, ''))
        } else {
            columnArray = value.split(',').filter((i) => i.trim().replace(/\s+/g, '').replace(/,/g, ''))
        }
        // Parse the column into an array
        const productTypes = columnArray.map((col) => col.trim())
        const optionProductTypes = createOptions(productTypes)
        return optionProductTypes
    }

    const _handleKeyDown = (event) => {
        if (!inputValue) {
            return
        }
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                const newValue = [...value, ..._handleParseProductTypes(inputValue)]
                const emitValue = newValue.map((item) => item.value)
                setInputValue('')
                setValue(newValue)
                onChange({name, value: emitValue})
                event.preventDefault()
                break
            default:
                break
        }
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: errors[name] ? '#dd3d4c' : provided.borderColor,
            boxShadow: errors[name] ? 'unset' : provided.boxShadow,
            '&:hover': {
                borderColor: errors[name] ? '#dd3d4c' : provided.borderColor,
                boxShadow: errors[name] ? 'unset' : provided.boxShadow,
            },
        }),
    }

    const _handleChange = (selectedOption) => {
        const newValue = selectedOption && selectedOption.length ? selectedOption : []
        setValue(newValue)
        onChange({name, value: selectedOption.map((option) => option.value)})
    }

    useEffect(() => {
        defaultValue.length !== 0 && setValue(createOptions(defaultValue))
    }, [defaultValue])

    return (
        <CreatableSelect
            id={id}
            isMulti
            value={value}
            menuIsOpen={false}
            className={className}
            isClearable={isClearable}
            inputValue={inputValue}
            components={components}
            placeholder={placeholder}
            styles={customStyles}
            onKeyDown={_handleKeyDown}
            onChange={_handleChange}
            onInputChange={(newValue) => setInputValue(newValue)}
        />
    )
}

PFInputMulti.defaultProps = {
    errors: {},
    id: '',
    name: '',
    className: 'null',
    defaultValue: [],
    isClearable: true,
    placeholder: 'Type something and press enter...',
}

PFInputMulti.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    errors: PropTypes.any,
    defaultValue: PropTypes.array.isRequired,
    isClearable: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

export default PFInputMulti

