import React, {Component} from 'react'
import PropTypes from 'prop-types'

class BranchColumn extends Component {
    render() {
        const {item} = this.props
        const {branch} = item
        return (
            <td className='BranchColumn'>
                <span>{branch}</span>
            </td>
        )
    }
}

BranchColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default BranchColumn
