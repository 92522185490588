import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom"

class IndexColumn extends Component {
    render() {
        const {item} = this.props
        const {name, _id} = item

        return (
            <td className='HostColumn'>
                <Link to={`/a/clusters/${_id}`}>
                    <span>{name}</span>
                </Link>
            </td>
        )
    }
}

IndexColumn.propTypes = {
    item: PropTypes.object.isRequired,
}

export default IndexColumn
